import { usePaginatedClient } from '../usePaginatedClient';
import { debounce } from 'lodash';

export const useEventOptions = () => {
  const { parsed, loading, updateFetch } = usePaginatedClient(
    'EventPagination',
    {
      initial: {
        page: 1,
        perPage: 25
      },
      itemsFragment: `
      _id
      title
    `,
      parseResult: (event) =>
        event.map(({ _id, title }) => ({ value: `${_id}`, label: `${title}` }))
    }
  );

  const onSearch = debounce((data: string) => {
    updateFetch((state) => ({
      ...state,
      filter: {
        ...state.filter,
        titleOReventID: data
      }
    }));
  }, 300);

  return {
    eventOptions: parsed,
    onSearch,
    loading
  };
};
