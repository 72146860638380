import { EnumReconheceWalletStatus } from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';

export function useWalletOptions(invoiceOrigin: string) {
  const data = useClient('ReconheceWalletFindMany', {
    variables: {
      filter: {
        allInvoiceOrigin: invoiceOrigin ? [invoiceOrigin] : undefined,
        status: EnumReconheceWalletStatus.Available
      },
      limit: 100
    },
    fetchOnMount: !!invoiceOrigin,
    appendToFragment: `#graphql POIdentifier`
  });

  const options = (data?.result || []).map((item) => ({
    value: item?._id || '',
    label: item?.POIdentifier || ''
  }));

  return {
    options,
    loading: data.loading || false,
    fetch: data.fetch
  };
}
