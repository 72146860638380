import { useClient } from '../../autogenerated/client/client';

export function useChannelFindMany() {
  const variables = { limit: 100 };

  const ChannelFindMany = useClient('ChannelFindMany', {
    variables,
    fetchOnMount: true
  });

  return { ...ChannelFindMany };
}
