import { Breadcrumb, TitleDescription } from '@digi-tim-19/components';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { routes } from 'config/routes';
import React from 'react';
import { Listar } from './Listar';

export const EventosModeracaoListar = () => {
  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo', link: routes.home.mount() },
            { title: 'Moderação Feed', link: routes.eventosModeracao.mount() }
          ]}
        />
      }
    >
      <React.Fragment>
        <TitleDescription
          iconType="issues-close"
          title="Moderação do Feed"
          description="Lista de publicações para moderação"
        />
        <Listar />
      </React.Fragment>
    </PageTemplate>
  );
};
