/**
 * Configurações de rotas
 */
import { registerRoutes } from '@digi-tim-19/components';
import { isDev, routesCorp } from '@digi-tim-19/utils';

declare global {
  interface Window {
    _APP_ROUTES_PREFIX_?: string;
  }
}

// o valor é injetado via scripts/server.js
export const ROUTES_PREFIX = isDev() ? '' : window._APP_ROUTES_PREFIX_ || '';

export const routes = registerRoutes(routesCorp, ROUTES_PREFIX, [
  '/',
  '/portal',
  '/interno',
  '/externo',
  '/login',
  '/open',
  '/open/unsubscribe'
]);
