import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';
import { CardConfig } from '@digi-tim-19/components';

interface Props {
  items: CardConfig[]
}

const Wrapper = styled.div`
  margin: 20px 0;
  width: 100%;
  height: 350px;
`;

const StoriesWrapper = styled.div`
  height: 350px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
`;

const Stories = styled.div`
  width: 206px !important;
  height: 340px !important;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  img {
    border-radius: 10px;
  }
`;

const WrapperStoriesTitle = styled.div`
  border-radius: 5px;
  color: white;
  position: absolute;
  bottom: 20px;
  width: 180px;
  text-align: center;
  background-color: rgba(0, 0, 0, .5);
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  padding: 5px 0;
`;

const StoriesTitleCard = styled.div`
  color: white;
  font-family: TimBold;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 16px;
  font-weight: bold !important;
`;

export const CarouselStories = (props: Props) => {
  return(
    <Wrapper>
      <Slider
        autoplay={true}
        dots={false}
        speed={500}
        infinite={true}
        slidesToShow={4}
        slidesToScroll={4}
        arrows={false}
        responsive={[
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
          {
            breakpoint: 950,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 470,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
        ]}
      >
        {
          props.items.map(el => {
            return(
              <StoriesWrapper>
                <Stories
                  onClick={() => el?.functionButton()}
                >
                  <img src={el.imgDesk} alt={el.title} width={206} height={340} />
                  <WrapperStoriesTitle>
                    <StoriesTitleCard>{el.title}</StoriesTitleCard>
                  </WrapperStoriesTitle>
              </Stories>
              </StoriesWrapper>
            );
          })
        }
      </Slider>
    </Wrapper>
  );
}