import React from 'react';
import { useHistory } from 'react-router';
import { Former, UserSession } from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import { routes } from '../../config/routes';

type TFormerLoginProps = {
  defaultCPF?: string;
  onCancel: () => void;
};

export const FormerLogin = (props: TFormerLoginProps) => {
  const UserLogin = useClient('UserLoginWithPassword');
  const history = useHistory();

  return (
    <Former
      initialValues={{ user: props.defaultCPF }}
      showLoadingState={UserLogin.loading}
      warningMessage={UserLogin.error || ''}
      config={{
        fields: [
          {
            name: 'user',
            label: 'CPF / CNPJ',
            mask: '111.111.111-11',
            required: true
          },
          {
            name: 'password',
            label: 'Senha',
            type: 'password',
            required: true
          }
        ],

        submitButton: { label: 'Acessar' },
        cancelButton: { label: 'Voltar', onClick: props.onCancel }
      }}
      onSubmit={async ({ data }) => {
        UserSession.clear();

        await UserLogin.fetch({
          variables: {
            user: data.user,
            password: data.password
          },
          fragment: `token { token, iat }`
        }).then((ctx) => {
          const token = ctx.result?.token?.token;
          if (!token) return;
          UserSession.setToken(token);
          history.push(routes.home.mount());
        });
      }}
    />
  );
};
