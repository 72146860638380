import React from 'react';
import {
  ProviderSelecionarPublico,
  Breadcrumb,
  TitleDescription
} from '@digi-tim-19/components';

import { routes } from '../../../config/routes';
import { Form } from '../Form';

import { NotificationsWrapper } from './stylesCadastrar';
import { PageTemplate } from 'components/Layout/PageTemplate';

const configPage = {
  pageName: 'Notificação',
  icon: 'bell',
  description: 'Cadastro e consulta de notificações.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Comunicação', link: routes.home.mount() },
    { title: 'Notificação', link: routes.notificacoesGerenciar.mount() },
    { title: 'Cadastrar', link: routes.notificacoesCadastrar.mount() }
  ]
};

export const CadastrarNotificacao = () => {
  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <NotificationsWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </NotificationsWrapper>
    </PageTemplate>
  );
};
