import React from 'react';
import { TableActions } from '@digi-tim-19/components';

export const columns = [
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    key: 'avatar',
    render: (href: string) => (
      <img
        src={href || '/defaultImages/default-documents.jpeg'}
        alt="Avatar do usuário"
        style={{ height: 50, width: 50, borderRadius: '50%' }}
      />
    )
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'CPF',
    dataIndex: 'CPF',
    key: 'CPF'
  },
  {
    title: 'Canal',
    dataIndex: 'channel',
    key: 'channel'
  },
  {
    title: 'Perfil',
    dataIndex: 'roleGroup',
    key: 'roleGroup'
  },
  {
    title: 'Aprovador',
    key: 'action',
    render: (text: DataKey, record: any) => <TableActions data={record} />
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
