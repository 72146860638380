import styled from 'styled-components';

export const OnTubeCadastroWrapper = styled.div`
  width: 100%;
`;

export const OnTubeCadastroFormer = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  padding: 20px 15px;
  width: 100%;

  .header {
    flex-direction: column;
    flex-wrap: wrap !important;
    & .type-switch {
      margin-top: 1rem;
      margin-left: 1rem;
      label {
        display: block;
      }
    }

    > .FieldWrapper {
      margin-left: 20px;
      margin-right: 0 !important;
      max-width: 25%;
    }

    @media (max-width: 1125px) {
      > .FieldWrapper {
        margin-left: 0;
        margin-right: 0 !important;
        max-width: 100%;

        .name-category {
          max-width: 100%;
        }
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .first-field {
    @media (max-width: 1125px) {
      display: block !important;

      & .type-switch {
        margin-bottom: 2rem;
      }
    }
  }

  .upload-field-form {
    @media (max-width: 768px) {
      .type-upload {
        margin-bottom: 45px;
      }

      .ant-upload-list-item {
        width: 300px !important;
      }
    }
  }

  .name-description {
    margin-bottom: 15px !important;
  }

  .name-notifyEmailsBeforeExpire {
    @media (max-width: 768px) {
      margin-bottom: 20px !important;
    }
  }

  .checkbox {
    margin-bottom: 30px !important;
  }

  .formFooter > div {
    justify-content: flex-start !important;
  }
`;

export const Divider = styled.hr`
  border: none;
  background: #ccc;
  height: 1px;
  width: 100%;
  margin: 20px 0px 0px 0px;

  @media (max-width: 768px) {
    margin: 0 !important;
  }
`;
