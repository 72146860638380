import React from 'react';
import { useHistory } from 'react-router';
import { ListInterno } from '@digi-tim-19/components';

import { SortFindManyPinInput } from '../../autogenerated/client/types';
import { MenuCategories } from '../../config/menuCategories';
import { getAccessButton } from '../../utils/material';
import { routes } from '../../config/routes';

import { CardFavoritos } from './CardFavoritos';
import { useFavoritos } from './useFavoritos';
import { EmptyContainer } from 'components/TablePagination/EmptyContainer';

export enum ListMode {
  card,
  list
}

export type KindFavoritos = MenuCategories | 'video' | null;

export const InnerPageData = (props: {
  mode: ListMode;
  userId: string;
  sort: SortFindManyPinInput;
  kind: KindFavoritos;
}) => {
  const history = useHistory();
  const { items, materials, incentiveCampaigns, isLoading } = useFavoritos(
    props.sort,
    props.kind || '',
    props.userId
  );

  if (
    !isLoading &&
    (!items || items.length === 0) &&
    (!materials || materials.length === 0) &&
    (!incentiveCampaigns || incentiveCampaigns.length === 0)
  ) {
    return <EmptyContainer message="Não há favoritos para exibir" />;
  }

  if (props.mode === ListMode.card) {
    return (
      <CardFavoritos
        itemsMaterials={materials}
        itemsCampaigns={incentiveCampaigns}
        isLoading={isLoading}
      />
    );
  } else {
    return items.map((item: any) => (
      <ListInterno
        key={item._id}
        material={item.material}
        campaign={item.campaign}
        defaultImage={'/defaultImages/default-card-thumb.png'}
        actionsMaterial={(material) => {
          return {
            pin: true,
            share: true,
            accessButton: getAccessButton(material, history, routes)
          };
        }}
        actionsCampaign={{
          accessButton: {
            label: 'Acessar',
            action: (campaign) => {
              history.push(routes.campanhasIncentivo.mount(campaign?._id));
            }
          },
          share: true,
          pin: true
        }}
      />
    ));
  }
};
