import { useClient } from 'autogenerated/client/client';
import {
  EnumFeedStatus,
  SortFindManyFeedInput
} from 'autogenerated/client/types';

export const useFeed = (eventId: string) => {
  const { result, refetch } = useClient('FeedFindMany', {
    variables: {
      filter: {
        eventId,
        approved: true,
        isNotDeleted: true,
        status: EnumFeedStatus.Approved
      },
      sort: SortFindManyFeedInput.CreatedatDesc,
      limit: 100000
    },
    fetchOnMount: true,
    appendToFragment: `
      owner {
        _id
        name
        region
        roleGroup
      }
      images {
        title
        signedUrl
      }
      videos {
        title
        signedUrl
      }
    `
  });

  return { result, refetch };
};
