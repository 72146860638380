import { useEffect } from 'react';
import { sortBy } from 'lodash';

import { useClient } from 'autogenerated/client/client';
import { SortFindManyChannelInput } from 'autogenerated/client/types';
import { usePlatformName } from 'hooks/user/usePlatform';

export const useChannelOptions = () => {
  const { platformName } = usePlatformName();
  const ChannelFind = useClient('ChannelFindMany');

  useEffect(() => {
    ChannelFind.fetch({
      fetchOnMount: true,
      variables: {
        sort: SortFindManyChannelInput.NameAsc,
        filter: {
          availableAtChannels: platformName ? [platformName] : undefined
        },
        limit: 200
      },
      appendToFragment: `
        _id
        parentId
        roleGroups {
          _id
          name
          parentId
        }
      `
    });
  }, [platformName]);

  const result = ChannelFind.result;

  if (ChannelFind.loading) return [];

  const options: any = [];

  function addChannelInArray(channel: any) {
    options.push({
      id: channel?._id,
      value: channel?._id,
      pId: channel?.parentId,
      title: channel?.name
    });
  }

  function getChannel(channels: any) {
    (channels || []).map((channel: any) => {
      const roleGroups = channel.roleGroups;

      addChannelInArray(channel);

      if (roleGroups && roleGroups.length) {
        getChannel(roleGroups);
      }
    });
  }
  getChannel(result!);

  return sortBy(options, 'label');
};
