import { LoadingIndicator } from '@digi-tim-19/components';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingContainer = () => {
  return (
    <Container>
      <LoadingIndicator />
    </Container>
  );
};
