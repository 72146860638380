import React, { useState } from 'react';
import styled from 'styled-components';
import { Button as AntButton, Popover, InputNumber } from 'antd';

import { TFilesLink } from './DocsEditor';

const ImagesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-height: 155px;
  overflow-y: auto;
`;

const SizeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
`;

const Button = styled(AntButton)`
  max-width: 200px;
`;

type TImageControlProps = {
  config: any;
  files: TFilesLink;
};

export const ImageExtensions = ['jpeg', 'jpg', 'png'];

export const ImageControl = ({ config, files }: TImageControlProps) => {
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [width, setWidth] = useState<number | undefined>(undefined);

  const imageFiles = files?.filter((file) =>
    ImageExtensions.includes(file.extension)
  );

  return (
    <Popover
      title="Selecione uma imagem"
      placement="bottom"
      trigger="click"
      content={
        <div>
          <p style={{ fontSize: 11 }}>
            Dimensões da imagem (largura x altura):
          </p>
          <SizeWrapper>
            <InputNumber
              placeholder="auto"
              min={0}
              value={width}
              onChange={(e) => setWidth(e)}
            />
            <span style={{ margin: 5 }}>x</span>
            <InputNumber
              placeholder="auto"
              min={0}
              value={height}
              onChange={(e) => setHeight(e)}
            />
          </SizeWrapper>

          <p style={{ fontSize: 11 }}>Carregue as imagens na seção "Upload"</p>
          <ImagesWrapper>
            {imageFiles?.map((file, index) => (
              <Button
                key={`${file.title}-${index}`}
                type="link"
                onClick={() => {
                  config.onChange(
                    file.src,
                    height ? `${height}px` : undefined,
                    width ? `${width}px` : undefined
                  );
                }}
                children={file.title}
              />
            ))}
          </ImagesWrapper>
        </div>
      }
    >
      <Button icon="file-image" />
    </Popover>
  );
};
