import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { Breadcrumb, TitleDescription, Table } from '@digi-tim-19/components';
import { routes } from '../../config/routes';
import { DatePicker } from 'antd';
import { colors } from '@digi-tim-19/theme';
import { formatDate } from '@digi-tim-19/utils/build';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumUserCreditOperationOp,
  SortFindManyUserCreditOperationInput
} from 'autogenerated/client/types';
const { RangePicker } = DatePicker;

const InformacoesExtratoWrapper = styled.div``;

const BoxSaldoDisponivel = styled.div`
  background: ${colors.white};
  width: 250px;
  height: auto;
  border-radius: 10px;
  border: 1px solid ${colors.gray1};
  padding: 20px;
  margin: 20px 0;
`;

const TituloSaldo = styled.div`
  color: ${colors.grayMedium2};
  font-family: TimBold;
  font-weight: bold;
  font-size: 17px;
`;

const ValorSaldo = styled.div`
  font-size: 30px;
`;

const PontosExpirar = styled.div`
  color: ${colors.red};
  font-size: 14px;
`;

const FiltersWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  .ant-calendar-picker {
    max-width: 374px;
    margin-bottom: 10px;
  }
`;

const DescriptionTableWrapper = styled.div``;
const DescriptionTableText = styled.div``;
const DescriptionTableDate = styled.div`
  font-size: 14px;
  max-width: 200px;
`;

const DescriptionPointsFilter = styled.div`
  font-size: 14px;
  margin-left: 5px;
  display: inline;
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: 75px;
`;

const columns = [
  {
    title: 'Data da operação',
    dataIndex: 'operationDate',
    key: 'operationDate',
    render: (text: any) => {
      return <p>{formatDate(text, 'DD/MM/YYYY')}</p>;
    }
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
    render: (text: string, record: any) => {
      const textDescription =
        text && text.length > 100 ? `${text.substring(0, 100)}...` : text;

      const operationMask = (op: EnumUserCreditOperationOp) => {
        if (op === EnumUserCreditOperationOp.Debit) return 'Débito';
        if (op === EnumUserCreditOperationOp.Reverse) return 'Estorno';
        if (op === EnumUserCreditOperationOp.DisabledExpired) return 'Expirado';

        return null;
      };

      return (
        <DescriptionTableWrapper>
          <DescriptionTableText>
            {operationMask(record.op!) || textDescription}
          </DescriptionTableText>
          <DescriptionTableDate>
            {record.op === EnumUserCreditOperationOp.Reverse
              ? `Justificativa: ${record.reason}`
              : formatDate(record.dateDescription, 'DD/MMM')}
          </DescriptionTableDate>
        </DescriptionTableWrapper>
      );
    }
  },
  {
    title: 'TIM Coins',
    dataIndex: 'dots',
    key: 'dots',
    render: (text: number, record: any) => {
      const isDebitOp = ['reverse', 'debit', 'disabledExpired'].includes(
        record?.op!
      );

      return (
        <p
          style={{
            color: isDebitOp ? colors.red : colors.green
          }}
        >
          {isDebitOp && '-'}
          {text}
        </p>
      );
    }
  },
  {
    title: 'Expirar em',
    dataIndex: 'expiresAt',
    key: 'expiresAt',
    render: (text: number) => (
      <PontosExpirar>{formatDate(text, 'DD/MM/YYYY')}</PontosExpirar>
    )
  }
];

export const InformacoesExtrato = () => {
  const [data, setData] = React.useState<any>([]);
  const [range, setRange] = React.useState<any>([]);
  const [page, setPage] = React.useState<number>(1);
  const UserCreditOperationPagination = useClient(
    'UserCreditOperationPagination'
  );
  const ReconheceUserBalance = useClient('ReconheceUserBalance', {
    fetchOnMount: true
  });
  const total = ReconheceUserBalance.result?.balance;
  const perPage = 20;

  useEffect(() => {
    UserCreditOperationPagination.fetch({
      variables: {
        page,
        perPage,
        sort: SortFindManyUserCreditOperationInput.IdDesc
      },
      appendToFragment
    }).then((ctx: any) => {
      const parseToTable = ctx.result?.items?.map((item: any) => ({
        operationDate: item?.createdAt,
        description:
          item?.creditOriginCampaignData?.campaignTitle ||
          getNameOp(item?.op || ''),
        dateDescription: item?.creditOriginCampaignData?.addedAt,
        dots: Math.trunc(item?.value || 0),
        expiresAt: item?.creditOriginCampaignData?.expiresAt,
        op: item?.op,
        reason: item?.reason
      }));

      setData(parseToTable);
    });
  }, [page]);

  const getNameOp = (op: string) => {
    switch (op) {
      case 'credit':
        return 'Crédito';
      case 'manualCredit':
        return 'Crédito manual';
      case 'reserve':
        return 'Reserva';
      case 'debit':
        return 'Débito';
      case 'release':
        return 'Liberação';
      case 'manualRevertReserved':
        return 'Liberação manual';
      case 'reverse':
      case 'chargeback':
        return 'Estorno';
      case 'expired':
        return 'Expirado';
      default:
        return 'Operação';
    }
  };

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Informações Extrato' }
          ]}
        />
      }
    >
      <Container>
        <TitleDescription
          iconType={'solution'}
          title={'Informações do extrato'}
          description={'Todos os seus débitos e créditos realizados.'}
        />

        <InformacoesExtratoWrapper>
          <BoxSaldoDisponivel>
            <TituloSaldo>Saldo disponível</TituloSaldo>
            <ValorSaldo>{total?.toFixed(2)}</ValorSaldo>
          </BoxSaldoDisponivel>

          <FiltersWrapper>
            <RangePicker
              getCalendarContainer={(triggerNode: any) =>
                triggerNode.parentNode
              }
              format="DD/MM/YYYY"
              placeholder={['PERÍODO', '']}
              value={range}
              onChange={(e) => {
                setRange(e);
              }}
            />

            <DescriptionPointsFilter>
              A pontuação conquistada tem validade de 12 meses a partir da
              disponibilização dos TIM Coins no extrato.
            </DescriptionPointsFilter>
          </FiltersWrapper>

          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: UserCreditOperationPagination.result?.count!,
              onChange: (e) => setPage(e)
            }}
          />
        </InformacoesExtratoWrapper>
      </Container>
    </PageTemplate>
  );
};

const appendToFragment = `
items {
  createdAt
  op
  value
  reason
  creditOriginCampaignData {
    campaignTitle
    points
    addedAt
    expiresAt
  }
}
`;
