import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  LoadingIndicator,
  TitleDescription
} from '@digi-tim-19/components';

import { PageTemplate } from 'components/Layout/PageTemplate';
import { useClient } from 'autogenerated/client/client';
import { routes } from 'config/routes';

const Iframe = styled.iframe`
  display: block;
  margin: auto;
  height: 550px;
  width: 100%;
  max-width: 950px;
  border: none;
`;

export const Chat = () => {
  const [linkMovidek, setLinkMovidesk] = useState('');
  const ConnectMovidesk = useClient('UserConnectMovidesk');

  useEffect(() => {
    ConnectMovidesk.fetch({ fetchOnMount: true }).then((ctx) =>
      setLinkMovidesk(ctx.result || '')
    );
  }, []);

  return (
    <PageTemplate>
      <>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Chat' }
          ]}
        />
        <TitleDescription
          iconType="message"
          title="Chat"
          description={
            'Ao clicar em ENTRAR COM OUTRO USUÁRIO, o sistema não permitirá que você siga com o atendimento. Para retomar o seu atendimento, orientamos que atualize a página.'
          }
        />

        {!!linkMovidek ? (
          <>
            <Iframe src={linkMovidek} />
            <Iframe
              src={
                'https://chat.movidesk.com/KbChatWidget/Index/637E94F677BB4AB7BF3E274D28D9058E'
              }
            />
          </>
        ) : (
          <LoadingIndicator medium />
        )}
      </>
    </PageTemplate>
  );
};
