import React from 'react';
import { useClient } from '../../autogenerated/client/client';
import { TreeNodeNormal } from 'antd/es/tree/Tree';
import { sortByStringArray } from '@digi-tim-19/utils';

export function useRegionalProfiles(
  defaultChecked?: string[],
  readonly?: boolean
) {
  const SelectRegionalProfiles = useClient('SelectRegionalProfiles', {
    fetchOnMount: true,
    appendToFragment: `sort options { value label region }`
  });

  return React.useMemo(() => {
    const items = SelectRegionalProfiles.result || [];
    const loading = SelectRegionalProfiles.loading;

    const byChannel: { [key: string]: TreeNodeNormal[] } = {};
    const nameToId: Record<string, string> = {};
    const idToName: Record<string, string> = {};

    items.sort((a, b) => (a?.sort || 1) - (b?.sort || 1));

    items.forEach(function (item) {
      if (!item) return;
      let roleGroupId = item?.roleGroupId || '';
      let roleGroupName = item?.roleGroupName || '';
      let channelName = item?.channelName || '';
      let options = (item?.options as OptionsEntity[]) || [];

      nameToId[channelName] = item.channelId;
      idToName[item.channelId] = item.channelName;

      byChannel[channelName] = byChannel[channelName] || [];
      const isDefault = defaultChecked?.some((el) => el.includes(roleGroupId));

      const option: any = {
        selectable: false,
        key: roleGroupId,
        title: roleGroupName,
        children: options.map(({ label, value }) => {
          const opt: any = { title: label, value, key: value };

          if (!readonly) {
            opt.disabled = isDefault;
          }

          return { ...opt };
        })
      };

      if (!readonly) {
        option.disabled = isDefault;
      }

      byChannel[channelName].push({ ...option });
    });

    const corporateSortBy = [
      'Corporate',
      'Large Account',
      'Regional LENE Corporate',
      'Regional SP Corporate',
      'Regional SULCONO Corporate',
      'Key e Vertical',
      'Governo',
      'Senior Account',
      'Wholesale'
    ];

    const channelsSort = sortByStringArray({
      data: Object.keys(byChannel),
      sortBy: corporateSortBy
    });

    const tabs = channelsSort.map((channel) => {
      const tree = [
        {
          key: 'selecionarTodos',
          title: 'Selecionar Todos',
          children: byChannel[channel]
        }
      ];

      return {
        title: channel,
        channelId: nameToId[channel],
        tree
      };
    });

    return {
      byChannel,
      tabs,
      loading,
      nameToId,
      idToName,
      channelNames: Object.keys(nameToId),
      channelIds: Object.keys(idToName)
    };
  }, [SelectRegionalProfiles.loading, SelectRegionalProfiles.result?.length]);
}

// export type ProfileTabs = { title: string; tree: TreeNode[] }[];
// export interface AudienceOptions {
//   roleGroupName: string;
//   roleGroupId: string;
//   channelId: string;
//   channelName: string;
//   options?: OptionsEntity[] | null;
// }

export interface OptionsEntity {
  value: string;
  label: string;
  region?: string;
}
