import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DatePicker, Divider } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  Button,
  Select,
  LoadingIndicator,
  TreeSelect
} from '@digi-tim-19/components';
import { clickDownload } from '@digi-tim-19/utils/build';

import { routes } from 'config/routes';
import { useChannelOptions } from 'hooks/channels/useChannelOptions';
import { FilterContainer, CleanButton } from 'components/PageFilter';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { useClient } from 'autogenerated/client/client';
import {
  SortFindManyIncentiveCampaignInput,
  ValidityEnum
} from 'autogenerated/client/types';

import * as S from './styles';

const { RangePicker } = DatePicker;

const configPage = {
  pageName: 'Download de campanha de incentivo',
  icon: 'download',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Incentivo', link: routes.campanhasIncentivoListar.mount() },
    { title: 'Relatórios', link: routes.downloadIncentivo.mount() }
  ]
};

export const DownloadIncentivo = () => {
  const channelOptions = useChannelOptions();

  const getIncentiveCampaigns = useClient('IncentiveCampaignFindMany');
  const downloUserPoints = useClient('DownloadUsersWithPoints');
  const downloadAcceptedTerms = useClient('DownloadAcceptedTerms');
  const DownloadReversalPoints = useClient('DownloadReversalPoints');
  const DownloadExtraCredit = useClient('DownloadExtraCredit');

  const [rangeSelected, setRangeSelected] = useState<any>();
  const [rangeFilter, setRangeFilter] = useState<any>();
  const [channelSelected, setChannelSelected] = useState();
  const [campaignIdSelected, setCampaignIdSelected] = useState();
  const [campaignsOptions, setCampaignsOptions] = useState<any[]>([]);

  const isLoading = downloadAcceptedTerms.loading || downloUserPoints.loading;

  useEffect(() => {
    const filterChannel = !!channelSelected
      ? [channelSelected || '']
      : undefined;

    getIncentiveCampaigns
      .fetch({
        appendToFragment: `
          campaignValidity {
            start
            end
          }
        `,
        variables: {
          filter: {
            validityEnum: ValidityEnum.Any,
            partAvailableAtRegionalProfiles: filterChannel
          },
          sort: SortFindManyIncentiveCampaignInput.IdDesc,
          limit: 200
        }
      })
      .then((ctx) => {
        if (ctx.result?.length) {
          let data = ctx.result;

          if (rangeSelected) data = filterRangeDate(data, rangeSelected);

          setCampaignsOptions(
            data.map((item) => ({
              label: item?.title,
              value: item?._id
            }))
          );
        } else {
          setCampaignsOptions([]);
        }
        setCampaignIdSelected(undefined);
      });
  }, [channelSelected, rangeSelected]);

  function downloadFile(ctx: any) {
    if (ctx.result && ctx.result.url) {
      clickDownload(ctx.result.url, true);
    } else console.log(ctx.errors);
  }

  const onClickDownloAdacceptedTerms = () => {
    if (campaignIdSelected) {
      downloadAcceptedTerms
        .fetch({
          variables: {
            incentiveCampaignId: campaignIdSelected!
          }
        })
        .then(downloadFile);
    }
  };

  const onClickDownloadRollback = () => {
    if (campaignIdSelected) {
      DownloadReversalPoints.fetch({
        variables: {
          campaignId: campaignIdSelected!
        }
      }).then(downloadFile);
    }
  };

  const onClickDownloadExtra = () => {
    if (campaignIdSelected) {
      DownloadExtraCredit.fetch({
        variables: {
          campaignId: campaignIdSelected!
        }
      }).then(downloadFile);
    }
  };

  function filterRangeDate(data: any[], rangeSelectedFilter: any) {
    const result = data.filter((item) => {
      return (
        moment(item?.campaignValidity?.start).isSameOrAfter(
          rangeSelectedFilter.start,
          'day'
        ) &&
        moment(item?.campaignValidity?.end).isSameOrBefore(
          rangeSelectedFilter.end,
          'day'
        )
      );
    });

    return result;
  }

  const onChangeRangePicker = (e: any) => {
    if (e.length === 0) {
      setRangeSelected(undefined);
    } else {
      setRangeSelected({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString()
      });
    }
    setRangeFilter(e);
  };

  const resetFilters = () => {
    setCampaignIdSelected(undefined);
    setChannelSelected(undefined);
    setRangeSelected(undefined);
    setRangeFilter(undefined);
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description=""
        />

        <S.DownloadSensitiveWrapper>
          <S.ItemDownload>
            <Button
              onClick={() => {
                downloUserPoints.fetch().then(downloadFile);
              }}
            >
              Download TIM Coins por usuário
            </Button>
            <span>Total de TIM Coins distribuídos no portal</span>
          </S.ItemDownload>

          <Divider />

          <FilterContainer>
            <RangePicker
              getCalendarContainer={(triggerNode: any) =>
                triggerNode.parentNode
              }
              format="DD/MM/YYYY"
              placeholder={['PERÍODO', '']}
              onChange={(e) => {
                onChangeRangePicker(e);
              }}
              value={rangeFilter}
            />
            <TreeSelect
              options={channelOptions}
              treeDefaultExpandAll={false}
              treeDefaultExpandedKeys={['corporate', 'residencial']}
              onChange={(e) => setChannelSelected(e)}
              value={channelSelected}
              placeholder="Canais"
              showSearch={true}
            />

            <CleanButton onClick={() => resetFilters()}>
              Limpar filtros
            </CleanButton>
          </FilterContainer>

          <Select
            options={campaignsOptions}
            onChange={(e) => setCampaignIdSelected(e)}
            placeholder="Campanha de incentivo"
            value={campaignIdSelected}
            showSearch={true}
            style={{
              maxWidth: 615,
              marginBottom: 20
            }}
          />

          <S.ItemDownload>
            <Button
              onClick={onClickDownloAdacceptedTerms}
              disabled={!campaignIdSelected}
            >
              Download termos de aceite
            </Button>
            <span>
              Selecione a campanha para download da base de Termos de Aceite de
              campanha
            </span>
          </S.ItemDownload>
          <S.ItemDownload>
            <Button
              onClick={onClickDownloadRollback}
              disabled={!campaignIdSelected}
            >
              Download de estornos
            </Button>
          </S.ItemDownload>
          <S.ItemDownload>
            <Button
              onClick={onClickDownloadExtra}
              disabled={!campaignIdSelected}
            >
              Download de upload extra
            </Button>
          </S.ItemDownload>

          {isLoading && (
            <S.LoadingWrapper>
              <LoadingIndicator />
            </S.LoadingWrapper>
          )}
        </S.DownloadSensitiveWrapper>
      </>
    </PageTemplate>
  );
};
