import styled from 'styled-components';
import { Input, Modal as AntdModal, Icon as AntdIcon } from 'antd';
import { Link } from 'react-router-dom';

const { Search: AntdSearch } = Input;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

export const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;
`;

export const Button = styled(Link)`
  border: none;
  background: red;
  display: flex;
  justify-content: center;

  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-family: TimBold;
  width: 100%;
  cursor: pointer;
  outline: none;
  margin-right: 15px;
  &:hover {
    background: white;
    color: red;
    outline: none !important;
  }
`;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;

  cursor: pointer;
`;

export const Modal = styled(AntdModal)`
  .ant-modal-body {
    max-height: 350px !important;
    padding: 16px 24px !important;
    overflow: auto;
  }
`;

export const Icon = styled(AntdIcon)`
  font-size: 23px;
  cursor: pointer;
`;

export const ModalQuestion = styled.p`
  margin-bottom: 5px;
  font-weight: 600;
`;

export const ModalAnswer = styled.p`
  margin-bottom: 10px;
  font-style: italic;
  font-size: 11pt;
`;
