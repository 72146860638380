import React from 'react';
import { LoadingIndicator, Table } from '@digi-tim-19/components';
import { Icon } from 'antd';
import { useClient } from 'autogenerated/client/client';
import { FieldTypeEnum, FilterSearch } from 'components/FilterSearch';
import { routes } from 'config/routes';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { usePaginatedFeed } from './hooks/usePaginatedFeed';

export const Listar = () => {
  const history = useHistory();
  const { result: events, loading: loadingEvents } = useClient(
    'EventFindMany',
    {
      fragment: `eventId title`,
      fetchOnMount: true
    }
  );
  const { posts, loading, pagination, filterUpdate } = usePaginatedFeed();

  const getOptionsEvents = (events: any = []) => {
    return events.map((event: any) => {
      return {
        value: event._id,
        label: event.title
      };
    });
  };

  const fields = React.useMemo(() => {
    const options = [
      {
        placeholder: 'EVENTOS',
        dataKey: 'events',
        typeField: FieldTypeEnum.MULTI_SELECT,
        options: getOptionsEvents(events || [])
      },
      {
        placeholder: 'CATEGORIA',
        dataKey: 'category',
        typeField: FieldTypeEnum.SELECT,
        options: [
          { label: 'IMAGEM', value: 'image' },
          { label: 'VÍDEO', value: 'video' },
          { label: 'TEXTO', value: 'text' }
        ]
      },
      {
        placeholder: 'STATUS',
        dataKey: 'status',
        typeField: FieldTypeEnum.SELECT,
        options: [
          { label: 'AGUARDANDO MODERAÇÃO', value: 'moderation' },
          { label: 'APROVADO', value: 'approved' },
          { label: 'REPROVADO', value: 'disapproved' }
        ]
      },
      {
        placeholder: 'CPF/MATRÍCULA',
        dataKey: 'subscription',
        typeField: FieldTypeEnum.TEXT,
        debounce: true
      }
    ];

    return options;
  }, [events]);

  const handleSearchFilter = (filter: any) => {
    filterUpdate(filter);
  };

  const status: any = {
    moderation: 'Aguardando Moderação',
    approved: 'Aprovado',
    disapproved: 'Reprovado'
  };

  return (
    <React.Fragment>
      {loadingEvents ? (
        <LoadingIndicator />
      ) : (
        <React.Fragment>
          <FilterContainer>
            <FilterSearch fields={fields} handleSearch={handleSearchFilter} />
          </FilterContainer>
          <Table
            columns={[
              {
                title: 'Título Evento',
                dataIndex: 'title',
                key: 'title',
                render: (_: any, post: any) => <p>{post?.event?.title}</p>
              },
              {
                title: 'Categoria',
                dataIndex: 'category',
                key: 'category',
                render: (text: string) => <p>{text}</p>
              },
              {
                title: 'Publicado por',
                dataIndex: 'author',
                key: 'author',
                render: (_: any, post: any) => <p>{post?.owner?.name}</p>
              },
              {
                title: 'CPF/MATRÍCULA',
                dataIndex: 'CPF',
                key: 'CPF',
                render: (_: any, post: any) => {
                  return (
                    <p>
                      {post?.owner?.CPF}
                      {post?.owner?.matricula && ` / ${post?.owner?.matricula}`}
                    </p>
                  );
                }
              },
              {
                title: 'Canal',
                dataIndex: 'channel',
                key: 'channel',
                render: (_: any, post: any) => <p>{post?.owner?.channel}</p>
              },
              {
                title: 'Cargo',
                dataIndex: 'role',
                key: 'role',
                render: (_: any, post: any) => <p>{post?.owner?.roleGroup}</p>
              },
              {
                title: 'Regional',
                dataIndex: 'region',
                key: 'region',
                render: (_: any, post: any) => <p>{post?.owner?.region}</p>
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (_: any, post: any) => {
                  return (
                    <React.Fragment>
                      {post?.status === 'approved' ? (
                        <p>{post?.status && status[post.status]}</p>
                      ) : (
                        <React.Fragment>
                          {post?.status === 'disapproved' ? (
                            <StatusDisapproved>
                              {post?.status && status[post.status]}
                            </StatusDisapproved>
                          ) : (
                            <StatusModeration>
                              {post?.status && status[post.status]}
                            </StatusModeration>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                }
              },
              {
                title: 'Ações',
                dataIndex: 'action',
                key: 'action',
                render: (_: any, record: any) => (
                  <React.Fragment>
                    <Icon
                      type="eye"
                      onClick={() => {
                        history.push(
                          routes.eventosModeracaoEditar.mount(record?._id)
                        );
                      }}
                    />
                  </React.Fragment>
                )
              }
            ]}
            loading={loading}
            dataSource={posts}
            pagination={pagination}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const FilterContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StatusModeration = styled.p`
  color: ${(props) => props.theme.orange};
`;

const StatusDisapproved = styled.p`
  color: ${(props) => props.theme.red};
`;
