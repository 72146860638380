import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  useCurrentUser
} from '@digi-tim-19/components';

import {
  SortFindManyMaterialInput,
  EnumMaterialKind,
  ValidityEnum,
  EnumMaterialStatus
} from 'autogenerated/client/types';
import { useClient } from 'autogenerated/client/client';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { routes } from 'config/routes';

import { columns } from './columns';

const configPage = {
  perPage: 10
};

export const Aprovacoes = () => {
  const history = useHistory();
  const { result: currentUser } = useCurrentUser();
  const MaterialPagination = useClient('MaterialPagination');

  const [page, setPage] = useState(1);

  useEffect(() => {
    MaterialPagination.fetch({
      appendToFragment,
      fetchOnMount: true,
      variables: {
        filter: {
          kind: EnumMaterialKind.Communication,
          approvalFlow: true,
          includeDrafts: true,
          validityEnum: ValidityEnum.Any,
          usersToApproveIN: currentUser?._id || undefined,
          OR: [
            { status: EnumMaterialStatus.Approved },
            { status: EnumMaterialStatus.Published },
            { status: EnumMaterialStatus.WaitingApproval }
          ]
        },
        perPage: configPage.perPage,
        page: page,
        sort: SortFindManyMaterialInput.CreatedatDesc
      }
    });
  }, [page, currentUser]);
  const resultMaterials = MaterialPagination.result?.items || [];
  const total = MaterialPagination.result?.count;

  const data = resultMaterials?.map((item) => {
    const itemKind = item?.communicationItemsConfig?.find(
      ({ kind }: any) => kind
    )?.kind;
    const kindName = itemKind === 'email' ? 'Informativo' : 'Notificação';

    return {
      name: item?.title,
      id: item?.numericId,
      type: kindName,
      status: item?.status?.label,
      hideOnRead: true,
      onEdit: () => {
        if (kindName === 'Informativo') {
          return history.push(routes.informativosEditar.mount(item?._id));
        }

        return history.push(routes.notificacoesEditar.mount(item?._id));
      }
    };
  });

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Aprovações' }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType="check-square"
          title="Aprovações"
          description=""
        />

        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: configPage.perPage,
            current: page,
            total: total!,
            onChange: (e) => setPage(e)
          }}
        />
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    title
    numericId
    approvalFlow
    kind
    kindName
    status {_id label}
    communicationItemsConfig {
      kind
    }
  }
`;
