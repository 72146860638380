import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

import timLogo from '../../../assets/images/tim_logo.png';

const BlueTimHeaderWrapper = styled.div`
  width: 100%;
  background-image: url(${timLogo});
  background-color: ${({ theme }) => theme.blue};
  background-size: 70px;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
`;

export const BlueTimHeader = (props: TBlueTimHeaderProps) => {
  const { className } = props;

  return (
    <BlueTimHeaderWrapper
      className={cx(className, 'ui-flex BlueTimHeader BlueTimHeader_Wrapper')}
    >
      {props.children}
    </BlueTimHeaderWrapper>
  );
};

export type TBlueTimHeaderProps = {
  className?: string;
  children?: React.ReactNode;
};
