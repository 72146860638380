import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';
import styled from 'styled-components';
import {
  Former,
  usePublico,
  LabelSelecionarPublico,
  SearchUser
} from '@digi-tim-19/components';
import { useDimensions } from '@digi-tim-19/utils';

import InputUpload from './InputUpload';
import { Divider, OnTubeCadastroFormer } from './Cadastrar/stylesCadastrar';
import { useMaterialCategoriesSelect } from '../../hooks/useMaterialCategories';
import {
  EnumFileKind,
  EnumMaterialKind
} from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';
import { Material, Notification } from '../../autogenerated/client/types';
import { routes } from '../../config/routes';
import { RegionalProfilesField } from '../../components/RegionalProfilesSelection/RegionalProfilesField';
import { validateSelectPublic } from '../../utils/validateSelectPublic';

const WrapperLabel = styled.div`
  margin-top: -30px;
  display: flex;
  flex: auto;
`;

const VideoMp4Kind = EnumFileKind.VideoMp4;
const VideoThumbKind = EnumFileKind.VideoThumb;
const VideoBannerKind = EnumFileKind.VideoBanner;

export const Form = (props: TFormMaterialEditProps) => {
  const { availableAtRegions, availableAtChannels, availableAtRoleGroups } =
    usePublico();
  const history = useHistory();
  const { isMobile } = useDimensions();

  const playlistOptions = useMaterialCategoriesSelect('video_playlist');

  const [publicSelected, setPublicSelected] = useState(false);

  const isUpdate = !!props.initialValues;

  const initialValues = {
    ...props.initialValues,
    allow_commentary_like:
      props.initialValues?.enableSocialInteraction || false,
    // send_notification: !!props.notification,
    text_notification:
      props && props.notification ? props.notification.title : '',
    playlist:
      (props.initialValues?.categories || []).find((el) => el)?._id || '',
    video:
      (props.initialValues?.files || []).find(
        (el) => el && el.kind === VideoMp4Kind
      )?._id || '',
    thumbnail:
      (props.initialValues?.files || []).find(
        (el) => el && el.kind === VideoThumbKind
      )?._id || '',
    detachedBanner:
      (props.initialValues?.files || []).find(
        (el) => el && el.kind === VideoBannerKind
      )?._id || '',
    notifyEmailsBeforeExpire:
      props.initialValues?.validity?.notifyEmailsBeforeExpire
  };

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  return (
    <OnTubeCadastroFormer>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              className: 'header first-field',
              list: [
                {
                  name: 'validity',
                  label: 'Data de Vigência',
                  type: 'rangePicker',
                  required: true,
                  extraProps: {
                    format: 'DD/MM/YYYY',
                    showTime: { format: 'HH:mm' },
                    noBeforeDate: !isUpdate
                  }
                },
                {
                  name: 'playlist',
                  label: 'Playlists',
                  required: true,
                  type: 'select',
                  options: playlistOptions?.sort(function (a: any, b: any) {
                    if (a.label > b.label) {
                      return 1;
                    }
                    if (a.label < b.label) {
                      return -1;
                    }
                    // a must be equal to b
                    return 0;
                  })
                },
                {
                  name: 'detachedVideo',
                  label: 'Vídeo em destaque',
                  required: false,
                  className: 'detached-video',
                  type: 'switch'
                }
              ]
            },

            {
              inline: true,
              className: 'header upload-field-form',
              list: [
                {
                  noWrapper: true,
                  custom: {
                    render: () => {
                      return (
                        <InputUpload
                          props={{
                            name: 'video',
                            type: 'upload',
                            required: true,
                            extraProps: {
                              classNames: 'upload-field-form',
                              kind: 'video_mp4',
                              CTA: 'IMPORTAR VÍDEO',
                              allowedExtensions: ['video/mp4']
                            }
                          }}
                        />
                      );
                    }
                  }
                },
                {
                  noWrapper: true,
                  custom: {
                    render: () => {
                      return (
                        <InputUpload
                          props={{
                            name: 'thumbnail',
                            type: 'upload',
                            extraProps: {
                              classNames: 'upload-field-form',
                              kind: 'video_thumb',
                              CTA: 'IMPORTAR THUMBNAIL',
                              allowedExtensions: ['image/png', 'image/jpeg']
                            }
                          }}
                        />
                      );
                    }
                  }
                },
                {
                  noWrapper: true,
                  custom: {
                    render: () => {
                      return (
                        <InputUpload
                          props={{
                            name: 'detachedBanner',
                            type: 'upload',
                            extraProps: {
                              classNames: 'upload-field-form',
                              kind: 'video_banner',
                              CTA: 'IMPORTAR BANNER DESTAQUE',
                              allowedExtensions: ['image/png', 'image/jpeg']
                            }
                          }}
                        />
                      );
                    }
                  }
                }
              ]
            },
            {
              custom: <Divider />
            },
            {
              list: [
                {
                  name: 'title',
                  label: 'Título',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value))
                      return 'Os caracteres #%&; não são permitidos';

                    if (value) {
                      return !(value.length > 110)
                        ? undefined
                        : 'Máximo de 110 caracteres';
                    }

                    return undefined;
                  }
                },
                {
                  name: 'description',
                  label: 'Descrição',
                  type: 'textarea',
                  extraProps: {
                    rows: 5
                  },
                  validate: (value: any) => {
                    const format = /[#%&]/;
                    if (format.test(value))
                      return 'Os caracteres #%& não são permitidos';

                    if (value) {
                      return !(value.length > 1200)
                        ? undefined
                        : 'Máximo de 1200 caracteres';
                    }

                    return undefined;
                  }
                },
                {
                  custom: <Divider />
                },
                {
                  name: 'tags',
                  label: 'Palavra-chave',
                  type: 'tags'
                },
                {
                  name: 'notifyEmailsBeforeExpire',
                  label:
                    'Escolha um ou mais usuários que devem ser avisados antes do término da vigência deste documento',
                  placeholder: isMobile
                    ? 'Notificar antes de expirar'
                    : 'Escolha um ou mais usuários que devem ser avisados antes do término da vigência deste documento',
                  extraProps: {
                    returnProp: 'email'
                  },
                  component: SearchUser
                },
                {
                  custom: <Divider />
                },
                {
                  className: 'formFooter',
                  inline: true,
                  list: [
                    {
                      custom: (
                        <RegionalProfilesField
                          fieldConfig={{
                            initialValue:
                              props.initialValues?.availableAtRegionalProfiles
                          }}
                        />
                      )
                    }
                  ]
                },
                publicSelected && {
                  custom: (
                    <WrapperLabel>
                      <LabelSelecionarPublico />
                    </WrapperLabel>
                  )
                },
                {
                  className: 'checkbox',
                  inline: true,
                  list: [
                    {
                      name: 'allow_commentary_like',
                      label: 'Permitir comentários e curtidas',
                      type: 'switch'
                    }
                  ]
                }
              ]
            }
          ],
          submitButton: {
            label: isUpdate ? 'Atualizar' : 'Cadastrar',
            onClick: (formerRef: any) => {
              setPublicSelected(validateSelectPublic(formerRef));
            }
          },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.videosListar.path)
          }
        }}
        onSubmit={async function ({ data }) {
          const fileIds = [
            data.video,
            data.thumbnail,
            data.detachedBanner
          ].filter(Boolean);
          const startValidity = data.validity[0] || data.validity.start;
          const endValidity = data.validity[1] || data.validity.end;

          const validity: any = {
            start: startValidity,
            end: endValidity,
            notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire || undefined
          };

          if (!data.availableAtRegionalProfiles) {
            return setPublicSelected(true);
          }

          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            availableAtRegionalProfiles: data.availableAtRegionalProfiles,
            validity,
            kind: EnumMaterialKind.Video,
            fileIds,
            detachedVideo: !!data.detachedVideo,
            categories: [data.playlist],
            title: data.title,
            description: data.description,
            tags: data.tags,
            enableSocialInteraction: data.allow_commentary_like
          };

          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then(async (ctx) => {
              if (!ctx.errors) {
                // if (data.send_notification) {
                //   if (props.notification) {
                //     await NotificationUpdateOne.fetch({
                //       variables: { record: notification, filter: { _id: props.notification._id } }
                //     });
                //   } else {
                //     await createMaterial
                //       .fetch({ variables: { record: recordNotification } });
                //   }
                // }
                history.push(routes.videosListar.mount());
              }
            });
          } else {
            await createMaterial
              .fetch({ variables: { record } })
              .then(async (ctx) => {
                if (ctx?.result) {
                  message.success('Vídeo cadastrado com sucesso!');
                  // if (data.send_notification) {
                  //   const { recordId } = ctx.result;
                  //   notification.parentId = recordId;

                  //   await createMaterial
                  //     .fetch({ variables: { record: recordNotification } });
                  //   history.push(routes.videosListar.mount());
                  // } else {
                  history.push(routes.videosListar.mount());
                } else {
                  message.error('Opss, seu vídeo não foi cadastrado!');
                }
              });
          }
        }}
      />
    </OnTubeCadastroFormer>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
  notification?: Partial<Notification>;
};
