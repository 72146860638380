import styled from 'styled-components';
import { Button } from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';

export const NotFound = styled.p`
  color: #6c757d;
  font-size: 18px;
  text-align: center;
  width: 100%;
`;

export const CampaignSortSelect = styled.div`
  margin-bottom: 60px;
  max-width: 200px;

  @media (max-width: 1125px) {
    max-width: 100%;
  }
`;

export const SectionTitle = styled.h3`
  color: ${colors.blue};
  font-size: 22px;
`;

export const CampaignBanner = styled.div`
  align-items: center;
  border-radius: 3px;
  display: flex;
  justify-content: center;

  img {
    height: auto;
    max-height: 400px;
    max-width: 1145px;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-gap: 5px;
  margin: 15px auto;

  @media (min-width: 768px) {
    max-width: 1145px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const SocialButtons = styled.div`
  margin: 10px 0;

  > div {
    justify-content: flex-start;
  }
`;

export const CampaignFAQ = styled.div`
  height: auto;
  padding: 15px;
  margin: 20px 0 30px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
`;

export const ContestButton = styled(Button)`
  background: #fe0326;
  font-size: 15px;
  font-weight: bold;
  margin: 1em 0;

  :hover,
  :focus {
    background: #fe0326;
    border: 2px solid ${(props) => props.theme.blue};
  }
`;
