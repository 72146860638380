import {
  SortFindManyMaterialInput,
  EnumMaterialStatus,
  ValidityEnum
} from 'autogenerated/client/types';

export const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];
export const status = [
  { label: 'EM EDIÇÃO', value: EnumMaterialStatus.Editing },
  { label: 'PUBLICADO', value: ValidityEnum.Current },
  { label: 'CANCELADO', value: EnumMaterialStatus.Cancelled },
  { label: 'APROVADO', value: EnumMaterialStatus.Approved },
  { label: 'AGUARDANDO APROVAÇÃO', value: EnumMaterialStatus.WaitingApproval },
  { label: 'REPROVADO', value: EnumMaterialStatus.Disapproved },
  { label: 'EXPIRADO', value: ValidityEnum.Expired }
];
export const statusFeminino = [
  { label: 'EM EDIÇÃO', value: EnumMaterialStatus.Editing },
  { label: 'PUBLICADA', value: ValidityEnum.Current },
  { label: 'CANCELADA', value: EnumMaterialStatus.Cancelled },
  { label: 'APROVADA', value: EnumMaterialStatus.Approved },
  { label: 'AGUARDANDO APROVAÇÃO', value: EnumMaterialStatus.WaitingApproval },
  { label: 'REPROVADA', value: EnumMaterialStatus.Disapproved },
  { label: 'EXPIRADA', value: ValidityEnum.Expired }
];
export const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
