import React from 'react';
import { TableActions } from '@digi-tim-19/components';

export const columns = [
  {
    title: 'Informativos',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Solicitante',
    dataIndex: 'user',
    key: 'user'
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Categoria do menu',
    dataIndex: 'category',
    key: 'category',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Cadastrada',
    dataIndex: 'cadastrada',
    key: 'cadastrada'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Data de Envio',
    dataIndex: 'sendDate',
    key: 'sendDate'
  },
  {
    title: 'Ações',
    key: 'action',
    render: (text: DataKey, record: any) => (
      <TableActions
        data={record}
        confirmationMessage={'Deseja excluir esse informativo?'}
      />
    )
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
}
