import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { GerenciarFormResearch } from './Form';
import { PageWrapper } from './styles';

export const GerenciarResearchCreate = () => {
  const configPage = {
    pageName: 'Cadastrar Pesquisa',
    icon: 'schedule',
    description: 'Cadastramento e consulta de pesquisas',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Pesquisas', link: routes.researchGerenciar.mount() },
      { title: 'Cadastrar' }
    ]
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <React.Fragment>
        <PageWrapper>
          <TitleDescription
            iconType={'schedule'}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        <ProviderSelecionarPublico>
          <GerenciarFormResearch />
        </ProviderSelecionarPublico>
      </React.Fragment>
    </PageTemplate>
  );
};
