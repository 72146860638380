import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Form } from '../FormManage';
import { OnTubeCadastroWrapper } from './stylesCadastrar';
import { routes } from '../../../config/routes';

export const OnTubeCadastrar = () => {
  return (
    <PageTemplate>
      <OnTubeCadastroWrapper>
        <Breadcrumb
          items={[
            { title: 'Home', link: '/' },
            { title: 'Gerenciar conteúdo', link: '#' },
            { title: 'Vídeos', link: routes.videosListar.mount() },
            { title: 'Cadastrar', link: '#' }
          ]}
        />
        <TitleDescription
          iconType="play-square"
          title="Vídeos"
          description="Cadastro e consulta de vídeo"
        />

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </OnTubeCadastroWrapper>
    </PageTemplate>
  );
};
