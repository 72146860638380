import React from 'react';
import { useHistory } from 'react-router';
import { StyleLogo } from './styles';
//import ImageLogo from '../images/Logo.png';
import ImageLogoWhite from '../images/pra_conectar_natal.png';
import ImageLogoResidential from '../images/pra_conectar_residential_natal.png';

export const Logo = ({ isResidential }: { isResidential?: boolean }) => {
  const history = useHistory();
  return (
    <StyleLogo
      src={!isResidential ? ImageLogoWhite : ImageLogoResidential}
      alt="logo"
      onClick={() => {
        history.push('/');
      }}
      width={140}
      height={100}
    />
  );
};
