import React from 'react';
import { notification } from 'antd';
import { Former } from '@digi-tim-19/components';

import { useClient } from '../../autogenerated/client/client';

type TFormerValidationProps = {
  onCancel: () => void;
  onSuccess: (CPF: string) => void;
};

export const FormerValidation = (props: TFormerValidationProps) => {
  const UserCheck = useClient('UserCorpCheck');

  return (
    <Former
      showLoadingState={UserCheck.loading}
      warningMessage={UserCheck.error || ''}
      config={{
        fields: [
          {
            name: 'CPF',
            label: 'CPF',
            mask: '111.111.111-11',
            required: true
          }
        ],
        submitButton: { label: 'Avançar' },
        cancelButton: { label: 'Voltar', onClick: props.onCancel }
      }}
      onSubmit={async ({ data }) => {
        await UserCheck.fetch({
          variables: { CPF: data.CPF },
          fragment: `CPF isTempPassword isSendRecoveryEmail`
        }).then(({ result }) => {
          if (result) {
            if (result.isSendRecoveryEmail) {
              notification.success({
                message: `Email para o cadastro de nova senha enviado com sucesso!`
              });

              return props.onCancel();
            }

            return props.onSuccess(result.CPF!);
          }
        });
      }}
    />
  );
};
