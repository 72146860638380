import { Button, Former, LoadingIndicator } from '@digi-tim-19/components';
import { useDimensions } from '@digi-tim-19/utils/build';
import { Popconfirm, Button as DefaultButton, message } from 'antd';
import { useClient } from 'autogenerated/client/client';
import { EnumFeedStatus } from 'autogenerated/client/types';
import { routes } from 'config/routes';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { useUpdatePost } from './hooks/useUpdatePost';
import { Post } from './Post';

interface Params {
  id: string | undefined;
}

export const Form = () => {
  const { isMobile } = useDimensions();
  const { id }: Params = useParams();
  const history = useHistory();
  const { updatePost, loading: loadingUpdate } = useUpdatePost();
  const [initialValues, setInitialValues] = React.useState<any>({});
  const { result: post, loading } = useClient('FeedFindById', {
    variables: { _id: id },
    fetchOnMount: true,
    fragment: `
      _id
      event {
        title
      }
      text
      images {
        signedUrl
      }
      videos {
        signedUrl
      }
      owner {
        _id
        name
        CPF
        region
        channel
        roleGroup
        matricula
      }
      justification
      status
      approved
      createdAt
    `
  });

  const parsed = (result: any = {}) => {
    return {
      eventTitle: result?.event?.title,
      name: result?.owner?.name,
      subscription: result?.owner?.matricula,
      roleGroup: result?.owner?.roleGroup,
      channel: result?.owner?.channel,
      region: result?.owner?.region,
      createdAt:
        result?.createdAt &&
        moment(result.createdAt).format('DD/MM/YYYY HH:mm:ss'),
      images: result?.images,
      videos: result?.videos,
      text: result?.text,
      justification: result?.justification,
      status: result?.status,
      approved: result?.approved
    };
  };

  React.useEffect(() => {
    if (post) {
      setInitialValues(parsed({ ...post }));
    }
  }, [post]);

  return (
    <React.Fragment>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <FormContainer>
          <Former
            initialValues={initialValues}
            config={{
              fields: [
                {
                  inline: true,
                  name: 'eventTitle',
                  label: 'Título da Campanha',
                  type: 'text',
                  disabled: true,
                  extraProps: {
                    readOnly: true
                  }
                },
                {
                  list: [
                    {
                      inline: !isMobile,
                      list: [
                        {
                          name: 'name',
                          label: 'Nome',
                          type: 'text',
                          disabled: true,
                          extraProps: {
                            readOnly: true
                          }
                        },
                        {
                          name: 'subscription',
                          label: 'Matrícula',
                          type: 'text',
                          disabled: true,
                          extraProps: {
                            readOnly: true
                          }
                        },
                        {
                          name: 'roleGroup',
                          label: 'Cargo',
                          type: 'text',
                          disabled: true,
                          extraProps: {
                            readOnly: true
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  inline: !isMobile,
                  list: [
                    {
                      name: 'channel',
                      label: 'Canal',
                      type: 'text',
                      disabled: true,
                      extraProps: {
                        readOnly: true
                      }
                    },
                    {
                      name: 'region',
                      label: 'Região',
                      type: 'text',
                      disabled: true,
                      extraProps: {
                        readOnly: true
                      }
                    },
                    {
                      name: 'createdAt',
                      label: 'Data de envio',
                      type: 'text',
                      disabled: true,
                      extraProps: {
                        readOnly: true
                      }
                    }
                  ]
                },
                {
                  inline: true,
                  custom: (
                    <Post
                      images={initialValues?.images}
                      videos={initialValues?.videos}
                      text={initialValues?.text}
                    />
                  )
                },
                {
                  inline: true,
                  list: [
                    {
                      name: 'approved',
                      label: 'Aprovar/Reprovar',
                      type: 'select',
                      defaultValue: false,
                      required: true,
                      disabled: loadingUpdate,
                      options: [
                        { label: 'Sim', value: true },
                        { label: 'Não', value: false }
                      ]
                    }
                  ]
                },
                {
                  inline: true,
                  list: [
                    {
                      name: 'justification',
                      label: 'Justificativa',
                      type: 'textarea',
                      extraProps: {
                        rows: 3
                      }
                    }
                  ]
                }
              ],
              customRenderFooter: (renderProps: any) => {
                const { form, values } = renderProps;
                return (
                  <React.Fragment>
                    <DefaultButton
                      disabled={loadingUpdate}
                      style={{ marginRight: '10px' }}
                      onClick={() =>
                        history.push(routes.eventosModeracao.mount())
                      }
                    >
                      VOLTAR
                    </DefaultButton>
                    {!values?.approved ? (
                      <Popconfirm
                        placement="topLeft"
                        title="Manter publicação Reprovada?"
                        onConfirm={() => {
                          form.change('approved', false);
                          form.change('status', EnumFeedStatus.Disapproved);
                          form.submit();
                        }}
                        onCancel={() => {
                          form.change('approved', true);
                          form.change('status', EnumFeedStatus.Approved);
                          form.submit();
                        }}
                        okText="SIM"
                        cancelText="NÃO"
                      >
                        <Button disabled={loadingUpdate}>SALVAR</Button>
                      </Popconfirm>
                    ) : (
                      <Button
                        disabled={loadingUpdate}
                        loading={loadingUpdate}
                        onClick={() => {
                          form.change('approved', true);
                          form.change('status', EnumFeedStatus.Approved);
                          form.submit();
                        }}
                      >
                        SALVAR
                      </Button>
                    )}
                  </React.Fragment>
                );
              }
            }}
            onSubmit={async ({ data }) => {
              await updatePost(id!, {
                status: data?.status,
                justification: data?.justification || null,
                approved: data?.approved
              });
              message.success('Editado com sucesso!');
              history.push(routes.eventosModeracao.mount());
            }}
          />
        </FormContainer>
      )}
    </React.Fragment>
  );
};

const FormContainer = styled.div`
  .ant-input-disabled {
    color: #3a3a3a !important;
  }
`;
