import { Input } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { FilterContainer } from 'components/PageFilter';

const { Search: AntdSearch } = Input;

export const NotificationsWrapper = styled.div`
  width: 100%;
`;

export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;
export const FilterWrapper = styled(FilterContainer as any)`
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

  .ant-calendar-range-picker-input {
    text-align: start;
  }
`;
export const Button = styled(Link)`
  border: none;
  background: red;
  display: flex;
  justify-content: center;

  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-family: TimBold;
  width: 100%;
  cursor: pointer;
  outline: none;
  margin-right: 15px;
  &:hover {
    background: white;
    color: red;
    outline: none !important;
  }
`;
