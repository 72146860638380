import React from 'react';
import { Picker } from 'emoji-mart';
import { Icon } from 'antd';
import styled from 'styled-components';

interface EmojiProps {
  disabled?: boolean;
  className?: string;
  textInput: string;
  setTextInput: (value: string) => void;
}

export const Emoji = (props: EmojiProps) => {
  const { textInput, setTextInput, disabled = false } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const ref = React.createRef<HTMLDivElement>();

  const handleOpenClose = (value: boolean) => {
    if (!value) {
      document.removeEventListener('click', handleClickOutside, true);
    }
    setOpen(value);
  };

  const handleClickOutside = (event: any) => {
    if (ref?.current && !ref?.current?.contains(event.target)) {
      handleOpenClose(false);
    }
  };

  React.useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [open, textInput]);

  return (
    <div className={props?.className || ''} ref={ref}>
      <Icon
        type="smile"
        style={{ color: 'rgba(0,0,0,.45)' }}
        onClick={() => {
          if (!disabled) handleOpenClose(!open);
        }}
      />
      <Container>
        <Picker
          set="emojione"
          style={{
            display: open ? 'block' : 'none',
            position: 'absolute',
            top: '35px',
            right: '-10px'
          }}
          include={['people']}
          showPreview={false}
          onSelect={(emoji) => {
            const selectedEmoji = (emoji as any).native;
            setTextInput(textInput + selectedEmoji);
          }}
          i18n={{ categories: { people: 'Emoticons' } }}
        />
      </Container>
    </div>
  );
};

const Container = styled.div`
  .emoji-mart {
    z-index: 10;
  }

  section .emoji-mart-bar {
    display: none !important;
  }

  .emoji-mart-emoji span:hover {
    cursor: pointer;
  }

  section .emoji-mart-scroll {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #c7c7c7;
    }
  }
`;
