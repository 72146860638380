import React from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  LoadingIndicator,
  TitleDescription,
  useClient
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { useRouteMatch } from 'react-router';
import { FormPlaylist } from '../FormPlaylist';

const DocumentsWrapper = styled.div`
  width: 100%;
`;

export const PlaylistEditar = () => {
  const { params }: any = useRouteMatch();
  const category = useClient('CategoryFindById', {
    variables: { _id: params?.id! },
    fragment: `
      availableAtChannels
      _id
      name
      createdAt
      updatedAt
    `,
    fetchOnMount: true
  });

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            {
              title: 'Playlists',
              link: routes.videosPlaylistListar.mount()
            },
            { title: 'Editar' }
          ]}
        />
      }
    >
      <DocumentsWrapper>
      <TitleDescription
          iconType="play-square"
          title="Gerenciamento de Playlists"
          description={'Edição de playlist.'}
        />
        {category?.result ? (
          <FormPlaylist values={category.result} />
        ) : (
          <LoadingIndicator />
        )}
      </DocumentsWrapper>
    </PageTemplate>
  );
};
