import React from 'react';
import { Button, Select, useClient } from '@digi-tim-19/components';
import { Icon, Modal, message, TreeSelect as AntdTreeSelect } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { downloadXLSX } from '../../utils/downloadXlsx';
import { EnumMaterialKind } from 'autogenerated/client/types';

interface Props {
  treeSelect?: boolean;
  kind: string;
  options: any[];
}

const fragment = `
  user {
    name
    region
    channels
    roleGroup
  }
  material {
    title
    menu
  }
  dateView
  dateDownload
`;

export const ModalReportMenuView = ({
  treeSelect = false,
  kind,
  options = []
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState<any>();
  const [dateView, setDateView] = React.useState<any>();
  const { loading, fetch } = useClient('ReportMenuView', { fragment });

  const handleGenerateMonths = () => {
    let options: any = [];

    for (let month = moment().month() + 1; month <= 12; month++) {
      options.push({
        label: `${month < 10 ? `0${month}` : month}/${moment().year() - 1}`,
        value: `${moment().year() - 1}-${month < 10 ? `0${month}` : month}-01`
      });
    }

    for (let month = 1; month <= moment().month() + 1; month++) {
      options.push({
        label: `${month < 10 ? `0${month}` : month}/${moment().year()}`,
        value: `${moment().year()}-${month < 10 ? `0${month}` : month}-01`
      });
    }

    return options;
  };

  const handleSerializeResult = (result: any[], kind: string) => {
    if (
      kind !== EnumMaterialKind.Communication &&
      kind !== EnumMaterialKind.Document
    ) {
      return result.map((el: any) => {
        return {
          dateView: el?.dateView || '',
          name: el?.user?.name,
          region: el?.user?.region,
          roleGroup: el?.user?.roleGroup,
          channels: el?.user?.channels,
          title: el?.material?.title,
          menu: el?.material?.menu
        };
      });
    }

    return result.map((el: any) => {
      return {
        dateView: el?.dateView || '',
        dateDownload: el?.dateDownload || '',
        download: el?.dateDownload ? 'Sim' : 'Não',
        name: el?.user?.name,
        region: el?.user?.region,
        roleGroup: el?.user?.roleGroup,
        channels: el?.user?.channels,
        title: el?.material?.title,
        menu: el?.material?.menu
      };
    });
  };

  const handleDownloadReport = async (category: string, dateView: string) => {
    const { result } = await fetch({
      variables: { date: dateView, menu: category, kind }
    });
    if (result && result?.length > 0) {
      let schema: any = {
        dateView: { label: 'Data de visualização' },
        name: { label: 'Nome' },
        region: { label: 'Regional' },
        roleGroup: { label: 'Diretoria' },
        channels: { label: 'Canal' },
        title: { label: 'Título' },
        menu: { label: 'Menu' }
      };

      if (
        kind === EnumMaterialKind.Communication ||
        kind === EnumMaterialKind.Document
      ) {
        schema = {
          dateView: { label: 'Data de visualização' },
          dateDownload: { label: 'Data de download' },
          download: { label: 'Download' },
          name: { label: 'Nome' },
          region: { label: 'Regional' },
          roleGroup: { label: 'Diretoria' },
          channels: { label: 'Canal' },
          title: { label: 'Título' },
          menu: { label: 'Menu' }
        };
      }

      downloadXLSX(
        'relatório-visualizações',
        schema,
        handleSerializeResult(result, kind)
      );
      setOpen(false);
    } else {
      message.info('Não há registros para download da planilha.');
    }
  };

  return (
    <React.Fragment>
      <Button
        loading={loading}
        disabled={loading}
        onClick={() => setOpen(true)}
      >
        {!loading && <Icon type="file-excel" />}
        Relatório Visualização
      </Button>
      <Modal
        title="Relatório de visualização"
        visible={open}
        okText="DOWNLOAD"
        cancelText="FECHAR"
        onOk={async () => await handleDownloadReport(category, dateView)}
        onCancel={() => {
          if (!loading) setOpen(false);
        }}
        okButtonProps={{
          disabled: !category || !dateView,
          loading: loading
        }}
      >
        <Message>
          Este relatório pode demorar alguns minutos para finalizar o
          processamento. Por favor, permanecer na tela até a conclusão do
          download.
        </Message>
        {treeSelect ? (
          <TreeSelect
            disabled={!options.length}
            treeDataSimpleMode
            treeData={options.map((option: any) => {
              return { ...option, selectable: true };
            })}
            placeholder="MENU"
            value={category}
            onChange={(e) => {
              setCategory(e);
            }}
          />
        ) : (
          <Select
            placeholder="MENU"
            options={options}
            value={category}
            onChange={(e) => {
              setCategory(e);
            }}
          />
        )}
        <Select
          style={{ marginTop: '10px' }}
          placeholder="MÊS/ANO"
          options={handleGenerateMonths()}
          value={dateView}
          onChange={(e) => {
            setDateView(e);
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

const Message = styled.div`
  width: 100%;
  font-size: 11pt;
  font-style: italic;
  margin: -10px 0px 10px 0px;
`;

const TreeSelect = styled(AntdTreeSelect)`
  color: ${(props) => props.theme.blue};
  width: 100%;
  margin-right: 15px;

  .ant-select-selection__placeholder,
  .ant-select-selection__rendered {
    text-transform: uppercase;
  }

  i {
    color: ${(props) => props.theme.warning};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: none;
    }
    &::after {
      content: '';
      margin: 0px 5px;
      width: 0;
      height: 0;
      border: solid ${(props) => props.theme.blue};
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform-origin: center center;
      transition: all 0.3s;
      transform: rotate(45deg);
    }
  }

  &.ant-select-open {
    text-transform: uppercase;
    i {
      &::after {
        transform: rotate(225deg);
        margin: -2px 5px;
      }
    }
  }
`;
