import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

import { TypeMenuItem } from '../../PageTemplate';

import { SubMenuMobileItem } from './SubMenuMobileItem';
import { colors } from '@digi-tim-19/theme';
import {
  clearTourClassName,
  TourSection,
  useTour
} from 'providers/TourProvider';

export type SubMenuConfig = {
  subMenuitem: TypeMenuItem;
  handleItemMenuClick?: Function;
  redirecionarLink?: Function;
  isResidential?: boolean;
};

const ItemMenu = styled.li<{
  isActive: string | null;
  isResidential?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: center;
  color: ${(props) =>
    !props?.isResidential ? 'white!important' : colors.blue};
  width: 100%;
  font-weight: 700;
  margin-bottom: 16px;
  cursor: pointer;
  font-family: TimBold;

  & p {
    margin: 0;
  }

  .itemMenu {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;

    & > div {
      display: flex;
      align-items: center;
      flex: 1;

      & span {
        font-size: 14px;
      }
    }

    ${({ isActive, isResidential }) =>
      isActive &&
      `
    background-color: ${!isResidential ? '#022042' : '#e90029'};
    color: #ffffff;
    border-radius: 12px;
  `}
  }
`;

export const SubMenuMobile = ({
  subMenuitem,
  handleItemMenuClick,
  redirecionarLink,
  isResidential = false
}: SubMenuConfig) => {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const { menuParentId } = useTour();

  function toggleSubmenu(itemId: string) {
    setActiveItem(activeItem === itemId ? null : itemId);
  }

  React.useEffect(() => {
    setActiveItem(menuParentId === subMenuitem._id ? subMenuitem._id : null);
  }, [menuParentId]);

  return (
    <ItemMenu isResidential={isResidential} isActive={activeItem}>
      {subMenuitem && (
        <div className="itemMenu">
          <TourSection
            selector={`tour-${clearTourClassName(subMenuitem._id)}`}
          />
          <div
            onClick={() => {
              handleItemMenuClick && handleItemMenuClick();
              toggleSubmenu(subMenuitem._id);
            }}
            className={`tour-${clearTourClassName(subMenuitem._id)}`}
          >
            <span>
              <Icon
                style={{ fontSize: '24px', paddingRight: '8px' }}
                type={subMenuitem.typeIcon}
              />
            </span>
            <li>{subMenuitem.text}</li>
          </div>
          {subMenuitem.children && subMenuitem.children.length > 0 && (
            <Icon
              onClick={() => toggleSubmenu(subMenuitem._id)}
              type={activeItem === subMenuitem._id ? 'up' : 'down'}
              style={{ padding: '8px 8px 8px 8px', fontSize: '18px' }}
            />
          )}
        </div>
      )}
      {Boolean(
        activeItem === subMenuitem._id &&
          subMenuitem?.children &&
          subMenuitem?.children.length > 0
      ) && (
        <div>
          <TourSection selector={`tour-submenu-content`} />
          <div className="tour-submenu-content">
            {subMenuitem?.children?.map((subItem: TypeMenuItem) => {
              return (
                <SubMenuMobileItem
                  key={subItem._id}
                  subMenuitem={subItem}
                  redirecionarLink={redirecionarLink}
                  isResidential={isResidential}
                />
              );
            })}
          </div>
        </div>
      )}
    </ItemMenu>
  );
};
