import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, DatePicker, Input, Modal, TimePicker } from 'antd';
import moment from 'moment';
import { RegionalProfilesField } from 'components/RegionalProfilesSelection/RegionalProfilesField';
import { orderBy } from 'lodash';
import { useInMemoPagination } from 'hooks/useInMemoPagination';
import { TablePagination } from 'components/TablePagination/TablePagination';

type SchedulleEntry = { _id: number; time: string; description: string };
const baseScheduleEntries: SchedulleEntry[] = [...Array(24).keys()].map(
  (_id) => ({
    _id,
    time: '',
    description: ''
  })
);

type FormatedSchedule = {
  _id: string;
  date: string;
  title: string;
  user: string;
  data: SchedulleEntry[];
};
interface IEventScheduleModal {
  userId: string;
  user: string;
  eventId: string;
  initialSchedules: any[];
  onChange: (schedules: FormatedSchedule[]) => void;
}

export const EventScheduleModal = (props: IEventScheduleModal) => {
  const [showModal, setShowModal] = useState<boolean>();
  const [modalData, setModalData] = useState<Partial<FormatedSchedule>>({});
  const [schedules, setSchedules] = useState(() =>
    formatSchedules(props.initialSchedules)
  );
  const { dataToRender, pagination } = useInMemoPagination({
    data: schedules || [],
    perPage: 10
  });

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      props.onChange(orderBy(schedules, 'date'));
    }
  }, [schedules]);

  const openModal = (data?: FormatedSchedule) => {
    if (data) {
      setModalData(data);
    } else {
      setModalData({ data: baseScheduleEntries });
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData({});
  };

  const handleAdd = (entry: FormatedSchedule) =>
    setSchedules((data) => orderBy(data.concat(entry), 'date'));

  const handleEdit = (entry: FormatedSchedule) =>
    setSchedules((data) =>
      orderBy(
        data.map((old) => (old._id === entry._id ? entry : old)),
        'date'
      )
    );

  const handleDelete = (entryId: string) =>
    setSchedules((data) =>
      orderBy(
        data.filter((el) => el._id !== entryId),
        'date'
      )
    );

  const enableOk =
    Boolean(modalData.title) &&
    Boolean(modalData.date) &&
    Boolean(modalData.data?.length);
  const defaultTitle = modalData.title;
  const defaultDate = modalData.date
    ? moment(modalData?.date, 'DD/MM/YYYY')
    : undefined;
  const defaultScheduleEntries = modalData.data ?? baseScheduleEntries;

  return (
    <ScheduleContainer>
      <Button onClick={() => openModal()}>Adicionar Agenda</Button>

      {showModal && (
        <ModalStyled
          width={'60%'}
          visible={showModal}
          onCancel={closeModal}
          okButtonProps={{ disabled: !enableOk }}
          onOk={() => {
            if (modalData._id) {
              handleEdit(modalData as FormatedSchedule);
            } else {
              const _id = `${Date.now()}_${props.eventId}_${props.userId}`;
              const newData = { _id, user: props.user, ...modalData };
              handleAdd(newData as FormatedSchedule);
            }
            closeModal();
          }}
        >
          <HeaderContainer>
            <RegionalProfilesField />

            <Input
              placeholder="Título da Agenda"
              defaultValue={defaultTitle}
              onChange={({ target }) =>
                setModalData((data) => ({ ...data, title: target.value }))
              }
            />
          </HeaderContainer>

          <Container>
            <div>
              <DatePicker
                placeholder="Selecionar data"
                format={'DD/MM/YYYY'}
                defaultValue={defaultDate}
                onChange={(date) =>
                  setModalData((data) => ({
                    ...data,
                    date: date?.format('DD/MM/YYYY') ?? undefined
                  }))
                }
              />
            </div>
            <ColumnsContainer>
              {defaultScheduleEntries.map((entry) => {
                const defaultTime = Boolean(entry.time)
                  ? moment(entry.time, 'HH:mm')
                  : undefined;

                const defaultDescription = Boolean(entry.description)
                  ? entry.description
                  : undefined;

                return (
                  <RowContainer key={entry._id}>
                    <TimePicker
                      style={{ width: '145px' }}
                      placeholder="Horário"
                      format="HH:mm"
                      defaultValue={defaultTime}
                      onChange={(time) => {
                        setModalData((el) => {
                          const data = el.data?.map((el) =>
                            el._id === entry._id
                              ? { ...el, time: time?.format('HH:mm') }
                              : el
                          );
                          return { ...el, data };
                        });
                      }}
                    />

                    <Input
                      placeholder="Descrição"
                      defaultValue={defaultDescription}
                      onChange={({ target }) => {
                        setModalData((el) => {
                          const data = el.data?.map((el) =>
                            el._id === entry._id
                              ? { ...el, description: target.value }
                              : el
                          );
                          return { ...el, data };
                        });
                      }}
                    />
                  </RowContainer>
                );
              })}
            </ColumnsContainer>
          </Container>
        </ModalStyled>
      )}

      {Boolean(dataToRender.length) && (
        <>
          <TablePagination
            dataSource={dataToRender}
            columns={[
              {
                title: 'Data da Agenda',
                width: '15%',
                dataIndex: 'date',
                key: 'date'
              },
              {
                title: 'Título',
                dataIndex: 'title',
                key: 'title'
              },
              {
                title: 'Solicitado por',
                dataIndex: 'user',
                key: 'user'
              },
              {
                title: '',
                dataIndex: 'title',
                key: 'actions',
                render: (_: any, record: any) => (
                  <ActionContainer>
                    <Button icon="edit" onClick={() => openModal(record)} />
                    <Button
                      icon="delete"
                      onClick={() => handleDelete(record._id)}
                    />
                  </ActionContainer>
                )
              }
            ]}
            pagination={pagination}
          />
        </>
      )}
    </ScheduleContainer>
  );
};

const formatSchedules = (data: any[]): FormatedSchedule[] =>
  data?.map((el) => ({
    _id: el?._id,
    title: el?.title,
    user: el?.user,
    date: el?.date,
    data: baseScheduleEntries.map((entry) => {
      const base = el.data[entry._id];
      return base ? { ...entry, ...base } : entry;
    })
  })) ?? [];

const ModalStyled = styled(Modal)`
  @media (max-width: 768px) {
    width: 90% !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  padding-right: 20px;
  @media (max-width: 470px) {
    flex-direction: column;
  }
`;

const ScheduleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-btn {
    width: 200px;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  width: 100px;
  height: 50px;
  align-items: center;

  .ant-btn {
    border: none;
    background-color: transparent;
    font-size: 24px;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  max-height: 300px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 10px;
`;

const RowContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-right: 10px;
  width: 100%;
  min-width: 500px;
`;
