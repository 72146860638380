"use strict";

var _defineProperty = require("/home/jenkins/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _slicedToArray = require("/home/jenkins/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var defaultState = {
  regions: [],
  locationType: [],
  sapCode: [],
  removedSapCodes: []
};
exports.SelecionarAbrangenciaContext = react_1.createContext({
  state: defaultState,
  setState: function setState() {
    return null;
  },
  stateEdit: defaultState,
  setStateEdit: function setStateEdit() {
    return null;
  },
  reset: function reset() {
    return null;
  },
  persist: function persist() {
    return null;
  }
});
var Provider = exports.SelecionarAbrangenciaContext.Provider;

exports.ProviderSelecionarAbrangencia = function (props) {
  var initialState = react_1.useMemo(function () {
    return props.initialState || defaultState;
  }, [props.initialState]);

  var _react_1$useState = react_1.useState(initialState),
      _react_1$useState2 = _slicedToArray(_react_1$useState, 2),
      stateEdit = _react_1$useState2[0],
      _setStateEdit = _react_1$useState2[1];

  var _react_1$useState3 = react_1.useState(initialState),
      _react_1$useState4 = _slicedToArray(_react_1$useState3, 2),
      state = _react_1$useState4[0],
      _setState = _react_1$useState4[1];

  function setState(key, value) {
    _setState(Object.assign(Object.assign({}, state), _defineProperty({}, key, value)));
  }

  function reset() {
    _setState(defaultState);
  }

  function setStateEdit(key, value) {
    _setStateEdit(Object.assign(Object.assign({}, stateEdit), _defineProperty({}, key, value)));
  }

  function persist() {
    _setState(stateEdit);
  }

  react_1.useEffect(function () {
    _setStateEdit(state);
  }, [JSON.stringify(state)]);
  return react_1.default.createElement(Provider, {
    value: {
      state: state,
      setState: setState,
      stateEdit: stateEdit,
      setStateEdit: setStateEdit,
      persist: persist,
      reset: reset
    }
  }, props.children);
};

function useSelecionarAbrangencia() {
  return react_1.useContext(exports.SelecionarAbrangenciaContext);
}

exports.useSelecionarAbrangencia = useSelecionarAbrangencia;