import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  EnumFileKind,
  TitleDescription
} from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';

import { PageTemplate } from 'components/Layout/PageTemplate';
import { useMaterial } from 'hooks/materials/useMaterial';
import { routes } from 'config/routes';

import { FConteudoNoticia } from './fragments';
import { ContentDate, ContentTitle, ContentDescription } from './styles';
import { Button } from 'antd';

const configPage = {
  pageName: 'Notícias',
  icon: 'read',
  description: 'Consulte as notícias cadastradas',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    {
      title: 'Notícias',
      link: routes.noticias.mount('para-voce', 'comunicacao')
    },
    { title: 'Conteúdo Notícias' }
  ]
};

export const ConteudoNoticia = () => {
  const { params }: any = useRouteMatch();
  const { result: material } = useMaterial(params?.id, FConteudoNoticia);
  const [showButton, setShowButton] = useState(false);

  const contentImage = material?.files!.find(
    (file) => file?.kind === 'any_image'
  )?.signedUrl;

  const attachment = material?.files!.find(
    (file) => file?.kind === EnumFileKind.AnyDoc
  );

  const setDateFormat = (date: any) => formatDate(date, 'DD/MM/YY');

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <ContentTitle>{material?.title}</ContentTitle>

        <ContentDate>
          Publicado em {setDateFormat(material?.publishedAt)}
          {!!material?.updatedAt &&
            `{editado em ${setDateFormat(material?.updatedAt)}}`}
        </ContentDate>

        {!!contentImage && (
          <img
            alt={material?.title || ''}
            src={contentImage}
            style={{ maxHeight: '500px' }}
          />
        )}

        <ContentDescription
          dangerouslySetInnerHTML={{
            __html: material?.description || ''
          }}
        />

        {attachment &&
          attachment?.extension &&
          ['pdf', 'png', 'jpg', 'jpeg', 'mp4'].includes(
            attachment.extension
          ) && (
            <React.Fragment>
              <Button
                style={{
                  margin: '10px 0px 20px 0px'
                }}
                onClick={() => setShowButton((prevState) => !prevState)}
              >
                {!showButton
                  ? 'Exibir PDF/Imagem/Vídeo'
                  : 'Ocultar PDF/Imagem/Vídeo'}
              </Button>
              {showButton && (
                <React.Fragment>
                  {attachment?.extension === 'pdf' && (
                    <iframe
                      title={attachment?.title || "Visualizar"}
                      src={attachment?.signedUrl || ''}
                      style={{ height: '700px', width: '100%' }}
                    />
                  )}
                  {attachment?.extension === 'mp4' && (
                    <video
                      style={{ width: '100%', height: '400px' }}
                      controls={true}
                    >
                      <source
                        src={attachment?.signedUrl || ''}
                        type="video/mp4"
                      />
                    </video>
                  )}
                  {attachment?.extension !== 'pdf' &&
                    attachment?.extension !== 'mp4' && (
                      <img
                        src={attachment?.signedUrl || ''}
                        style={{ maxHeight: '650px' }}
                      />
                    )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
      </>
    </PageTemplate>
  );
};
