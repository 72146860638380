import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  LoadingIndicator,
  ProviderSelecionarPublico,
  TitleDescription,
  useClient
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { GerenciarFormResearch } from './Form';
import { PageWrapper } from './styles';
import { useRouteMatch } from 'react-router';

const appendToFragment = `
  _id
  title
  questions
  ramification
  validity {
    start
    end
  }
  createdAt
  updatedAt
`;

export const GerenciarResearchEdit = () => {
  const { params }: any = useRouteMatch();
  const getData = useClient('ResearchFindById', {
    appendToFragment,
    variables: { _id: params?.id! },
    fetchOnMount: true
  });
  const configPage = {
    pageName: 'Editar Pesquisa',
    icon: 'schedule',
    description: 'Edição e consulta de pesquisas',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Pesquisas', link: routes.researchGerenciar.mount() },
      { title: 'Editar' }
    ]
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <React.Fragment>
        <PageWrapper>
          <TitleDescription
            iconType={'schedule'}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        {getData.loading ? (
          <LoadingIndicator />
        ) : (
          <React.Fragment>
            {getData?.result && (
              <ProviderSelecionarPublico>
                <GerenciarFormResearch initialValues={getData.result} />
              </ProviderSelecionarPublico>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
