import { Button } from '@digi-tim-19/components';
import { getRouterByMaterialCorp } from '@digi-tim-19/utils';
import { Icon } from 'antd';
import { Material } from 'autogenerated/client/types';
import { routes } from 'config/routes';
import React from 'react';
import styled from 'styled-components';

interface Props {
  data: {
    _id: string;
    title: string;
    material: Material;
  }[];
  onRemove: (id: string) => void;
}

const Container = styled.div`
  width: 100%;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EmptyMessage = styled.div`
  text-align: center;
  font-size: 11pt;
`;

export const MaterialsSelectedField = (props: Props) => {
  return (
    <Container>
      {props?.data.length === 0 ? (
        <EmptyMessage>
          Nenhum documento selecionado, o boletim selecionará automaticamente os
          documentos ou selecione alguns documentos para customizar o envio
          deste boletim.
        </EmptyMessage>
      ) : (
        <>
          {props.data.map((el) => {
            const routesMaterial = getRouterByMaterialCorp(el?.material || el, routes);
            const { routerAccess, routerDownload } = routesMaterial;

            return (
              <Item>
                <a
                  target="_blank"
                  href={routerDownload ? routerDownload : `${process.env.PUBLIC_URL}${routerAccess}`}
                >
                  <Icon
                    type="inbox"
                    style={{ marginRight: '5px' }}
                  />
                  {el.title}
                </a>
                <Button onClick={() => props.onRemove(el._id)}>
                  <Icon type="delete" />
                </Button>
              </Item>
            );
          })}
        </>
      )}
    </Container>
  );
};
