import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { FormUploadPontos } from '../Form/FormUploadPontos';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { UploadWrapper } from './stylesCadastrar';

export const UploadPontos = () => {
  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: 'home' },
            { title: 'Gerenciar conteúdo', link: '#' },
            { title: 'Incentivo', link: '' },
            { title: 'Upload de TIM Coins', link: '' }
          ]}
        />
      }
    >
      <>
        <UploadWrapper>
          <TitleDescription
            iconType=""
            title="Upload de TIM Coins"
            description="Upload da planilha de crédito de TIM Coins das campanhas."
          />
        </UploadWrapper>

        <ProviderSelecionarPublico>
          <FormUploadPontos />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
