import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Pagination } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  CardIncentiveCampaign
} from '@digi-tim-19/components';
import {
  EnumIncentiveCampaignStatus,
  ValidityEnum,
  SortFindManyIncentiveCampaignInput
} from '../../autogenerated/client/types';

import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { routes } from '../../config/routes';

import { FCampaignPagination as appendToFragment } from './fragments';

const configPage = {
  pageName: 'Campanhas encerradas',
  icon: 'trophy',
  description: 'Campanhas de incentivo de resultados',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Para você', link: routes.home.mount() },
    { title: 'Campanhas', link: routes.campanhasIncentivoEncerradas.mount() },
    { title: 'Encerradas', link: routes.campanhasIncentivoEncerradas.mount() }
  ],
  pageSize: 20,
  empty: 'Não temos campanhas no momento'
};

export const CampanhaIncentivoEncerradas = () => {
  const history = useHistory();
  const incentiveCampaignFind = useClient('IncentiveCampaignPagination');

  const [pageNumber, setPageNumber] = useState(1);
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    incentiveCampaignFind
      .fetch({
        appendToFragment,
        fetchOnMount: true,
        variables: {
          perPage: configPage.pageSize,
          page: pageNumber,
          sort: SortFindManyIncentiveCampaignInput.IdDesc,
          filter: {
            status: EnumIncentiveCampaignStatus.Closed,
            validityEnum: ValidityEnum.Any
          }
        }
      })
      .then((ctx) => {
        setItems(ctx.result?.items);
      });
  }, [pageNumber]);
  const isLoading = incentiveCampaignFind.loading;
  const total = incentiveCampaignFind.result?.count;

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <>
            <CardIncentiveCampaign
              defaultImage={'/defaultImages/default-card-thumb.png'}
              items={items}
              count={total as number}
              actions={{
                accessButton: {
                  label: 'Acessar',
                  action: (campaign) => {
                    history.push(
                      routes.campanhasIncentivo.mount(campaign?._id)
                    );
                  }
                },
                share: true,
                pin: true
              }}
            />
            <Pagination
              style={{ textAlign: 'center' }}
              pageSize={configPage.pageSize}
              current={pageNumber}
              total={total!}
              onChange={(page) => setPageNumber(page)}
            />
          </>
        )}
      </>
    </PageTemplate>
  );
};
