import { breadcrumbFragment } from 'utils/breadcrumbFragment';

const PageInfo = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
`;

const DocumentItem = `
  _id
  numericId
  title
  link
  createdAt
  updatedAt
  publishedAt
  kind
  html
  author { name }
  status { label }
  categories {
    _id
    name
    parentId
  }
  validity {
    start
    end
    notifyEmailsBeforeExpire
  }
  files {
    _id
    signedUrl
    downloadUrl
    extension
    kind
  }
  editor
  download
`;

export const CategoryFragment = `
  ${breadcrumbFragment}
  parentId
`;

export const DocumentFragment = `
  ${PageInfo}
  count
  items {
    ${DocumentItem}
    pinned
  }
`;

export const ListarFragment = `
  ${PageInfo}
  items {
    ${DocumentItem}
    updatedAt
  }
`;

export const EditarFragment = `
  ${DocumentItem}
  fileIds
  tags
  html
  editor
  files {
    _id
    signedUrl
    downloadUrl
    extension
    kind
  }

  history {
    items {
      author { name }
      date
      updates
    }
  }
`;
