import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';

export const columns = [
  {
    title: 'Notícias',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Solicitante',
    dataIndex: 'user',
    key: 'user'
  },
  {
    title: 'Cadastrada',
    dataIndex: 'cadastrada',
    key: 'cadastrada',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Publicada',
    dataIndex: 'publicada',
    key: 'publicada'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => <p style={{ color: colors.red }}>{text}</p>
  },
  {
    title: 'Ações',
    key: 'action',
    render: (text: DataKey, record: any) => <TableActions data={record} />
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
