const paginationInfo = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
`;

const FNoticiaDefault = `
  _id
  numericId
  title
  createdAt
  publishedAt
  updatedAt
  validity {
    start
    end
  }
  categories {
    _id
    name
    parentId
  }
`;

export const FNoticias = `
  ${paginationInfo}
  count
  items {
    ${FNoticiaDefault}
    description
    kind
    pinned
    files {
      _id
      signedUrl
      downloadUrl
      extension
      kind
    }
  }
`;

export const FConteudoNoticia = `
  ${FNoticiaDefault}
  description
  fileIds
  files {
    _id
    title
    kind
    signedUrl
    extension
  }
`;

export const FNoticiaEditar = `
  ${FConteudoNoticia}
`;

export const FNoticiaListar = `
  ${paginationInfo}
  items {
    ${FNoticiaDefault}
    validityEnum
    author {
      name
    }
    status {
      label
    }
  }
`;
