import React from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription,
  LoadingIndicator,
  ProviderXlsxJson
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { useIncentiveCampaign } from '../../../hooks/incentiveCampaign/useIncentiveCampaign';
import { routes } from '../../../config/routes';

import { Form } from '../Form/FormManage';

export const CampanhaIncentivoEditar = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const incentiveCampaign = useIncentiveCampaign(params?.id, appendToFragment);

  const configPage = {
    pageName: 'Campanhas e resultados',
    icon: 'trophy',
    description: 'Cadastramento de campanhas de incentivo e resultados.',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Incentivo', link: routes.campanhasIncentivoListar.mount() },
      {
        title: 'Campanhas e resultados',
        link: routes.campanhasIncentivoListar.mount()
      }
    ],
    routeList: routes.campanhasIncentivoListar.mount()
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {incentiveCampaign.loading ? (
          <LoadingIndicator />
        ) : (
          <ProviderSelecionarPublico>
            <ProviderXlsxJson>
              <Form initialValues={incentiveCampaign.result || {}} />
            </ProviderXlsxJson>
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  availableAtRegions
  availableAtChannels
  availableAtRoleGroups
  title
  videoLink
  enableSocialInteraction
  cardImage
  bannerContent
  contestationPeriod {
    start
    end
  }
  ranking
  results
  regulation
  presentation
  invoiceOrigin
  faq
  status {
    _id
  }
  validity {
    start
    end
  }
  campaignValidity {
    start
    end
  }
  file {
    title
    _id
    signedUrl
  }
  fileContent {
    name
    role
    points
    email
    position
    region
  }
  paymentFileId {
    title
    _id
    signedUrl
    createdAt
  }
`;
