export const breadcrumbFragment = `
  _id
  name
  routeName
  parentCategory {
    name
    parentCategory {
      name
      parentCategory {
        name
      }
    }
  }
`;
