import styled from 'styled-components';

const DownloadButton = styled.a`
  align-items: center;
  background: ${(props) => props.theme.blue};
  border: 2px solid ${(props) => props.theme.blue};
  border-radius: 5px;
  color: ${(props) => props.theme.white};
  display: flex;
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  text-transform: uppercase;
  transition: ease-in 0.2s;
  padding: 4px 15px;

  :hover,
  :focus {
    border-top: 2px solid ${(props) => props.theme.red};
    border-left: 2px solid ${(props) => props.theme.red};
    background: ${(props) => props.theme.red};
    color: ${(props) => props.theme.white};
  }
`;

export default DownloadButton;
