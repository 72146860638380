import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';

export const columns = [
  {
    title: 'Pequisas',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Vigência',
    dataIndex: 'validity',
    key: 'validity'
  },
  {
    title: 'Alterado',
    dataIndex: 'updatedAt',
    key: 'updatedAt'
  },
  {
    title: 'Ações',
    key: 'action',
    render: (text: DataKey, record: any) => <TableActions data={record} />
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
