import React from 'react';
import styled from 'styled-components';
import { makeField } from '@digi-tim-19/components';

const InputUploadWrapper = styled.div`
  .component-upload {
    margin-top: 0 !important;
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;

    .component-upload {
      width: 100%;
      margin-right: 0;

      .ant-upload {
        width: 100%;

        .ant-btn {
          width: 100%;
        }
      }
    }
  }
`;

export const InputUpload = (props: any) => {
  return (
    <InputUploadWrapper>
      {makeField({ field: props.props, key: props.name })}
    </InputUploadWrapper>
  );
};

export default InputUpload;
