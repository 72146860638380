import React from 'react';
import { useHistory } from 'react-router';
import { formatDate } from '@digi-tim-19/utils';
import { Breadcrumb, TitleDescription } from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { usePaginatedEvents } from '../hooks/usePaginatedEvents';
import { DocumentsListWrapper } from './styles';
import { columns } from './columns';
import { uniqBy } from 'lodash';
import { InputFilters } from '../../../components/InputFilters/InputFilters';
import { TablePagination } from 'components/TablePagination/TablePagination';

const configPage = {
  pageName: 'Eventos',
  icon: 'inbox',
  description: 'Consulta e cadastramento de componentes.',
  routeNew: routes.documentosCadastrar.mount(),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Componentes', link: routes.eventosComponentes.mount() }
  ],
  perPage: 70
};

export const EventosComponentes = () => {
  const history = useHistory();
  const { events, loading, pagination, filterUpdate } = usePaginatedEvents({
    showAll: true
  });

  const data = events.map((event: any) => {
    const validity = `${formatDate(event?.validity?.start, 'DD/MM/YY')}
      até ${formatDate(event?.validity?.end, 'DD/MM/YY')}`;
    const status = event?.status ? event?.status.label : 'Programado';

    return {
      key: event?._id,
      id: event?.eventId,
      title: event?.title,
      updatedAt: formatDate(event?.updatedAt, 'DD/MM/YY'),
      validity,
      status,
      author: event?.author?.name,
      hideOnRead: true,
      onEdit: () =>
        history.push(routes.eventosComponentesEditar.mount(event?._id))
    };
  });

  const userOption = () => {
    const data = events.map((event) => {
      return {
        label: `${event?.author?.name}`,
        value: `${event?.author?._id}`
      };
    });

    return uniqBy(data, 'value');
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <DocumentsListWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <InputFilters
          config={[
            {
              type: 'select',
              key: 'status',
              placeHolder: 'STATUS',
              options: [
                { label: 'EM EDIÇÃO', value: 'editing' },
                { label: 'PROGRAMADO', value: 'programming' },
                { label: 'EM ANDAMENTO', value: 'in_progress' },
                { label: 'ENCERRADO', value: 'closed' },
                { label: 'CANCELADO', value: 'cancelled' }
              ]
            },
            {
              type: 'select',
              key: 'createdBy',
              placeHolder: 'BUSCAR POR SOLICITANTE',
              options: userOption()
            },
            {
              type: 'search',
              key: 'titleOReventID',
              placeHolder: 'BUSCAR POR TÍTULO OU ID'
            }
          ]}
          onChange={filterUpdate}
        />

        <TablePagination
          loading={loading}
          dataSource={data}
          columns={columns}
          pagination={pagination}
          emptyMessage={'Não há eventos para exibir'}
        />
      </DocumentsListWrapper>
    </PageTemplate>
  );
};
