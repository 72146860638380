import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Breadcrumb, TitleDescription, Select } from '@digi-tim-19/components';

import { routes } from 'config/routes';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { FilterContainer, CleanButton } from 'components/PageFilter';
import { useChannelFindMany } from 'hooks/channels/channelFindMany';
import { useRegionFindMany } from 'hooks/regions/useRegionsFindMany';
import { useRoleGroupFindMany } from 'hooks/roleGroups/roleGroupFindMany';
import { useClient } from 'autogenerated/client/client';
import {
  SortFindManyCampaignContestationInput,
  ContestationStatusFilterEnum,
  EnumCampaignContestationResponseStatus
} from 'autogenerated/client/types';

import { Wrapper, Search } from './styles';
import { columns } from './columns';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { Input } from 'antd';

const configPage = {
  pageName: 'Contestação',
  icon: 'audit',
  description:
    'Solucionar as contestações solicitadas pelos usuários nas páginas internas das campanhas',
  routeNew: '',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Incentivo', link: routes.campanhasIncentivoListar.mount() },
    { title: 'Contestação', link: routes.contestacaoListar.mount() }
  ],
  perPage: 9,
  sort: SortFindManyCampaignContestationInput.IdDesc
};

const { pageName, icon, description, breadcrumb, perPage, sort } = configPage;

const appendToFragment = `
  pageInfo {
    itemCount
  }
  items {
    protocol
    _id
    indicator
    owner
    parentId
    isResultContest
    campaign {
      title
    }
    _id
    user {
      name
      channel
      region
      roleGroup
      matricula
    }
    response {
      status
    }
  }
`;

export const ContestacaoListar = () => {
  const history = useHistory();
  const ChannelFindMany = useChannelFindMany();
  const RoleGroupFindMany = useRoleGroupFindMany();
  const RegionFindMany = useRegionFindMany();
  const CampaignContestationPagination = useClient(
    'CampaignContestationPagination'
  );
  const [page, setPage] = useState(1);
  const [channel, setChannel] = useState();
  const [region, setRegion] = useState();
  const [role, setRole] = useState();
  const [search, setSearch] = useState<number>();
  const [status, setStatus] = useState<
    ContestationStatusFilterEnum | undefined
  >();
  const [matriculaFilter, setMatriculaFilter] = useState();

  const toSelect = (Materials: any = []) =>
    Materials?.map((Material: any) => ({
      label: Material?.name,
      value: Material?._id
    }));

  const regionOption = toSelect(RegionFindMany?.result);
  const channelOption = toSelect(ChannelFindMany?.result);
  const roleOption = toSelect(RoleGroupFindMany?.result);
  const statusOption = [
    {
      label: 'Procedente',
      value: ContestationStatusFilterEnum.Founded
    },
    {
      label: 'Improcedente',
      value: ContestationStatusFilterEnum.Unfounded
    },
    {
      label: 'Em Contestação',
      value: ContestationStatusFilterEnum.Empty
    }
  ];

  useEffect(() => {
    CampaignContestationPagination.fetch({
      appendToFragment,
      variables: {
        filter: {
          status,
          protocol: search,
          user:
            (role && { roleGroup: role }) ||
            (channel && { channel }) ||
            (region && { region }) ||
            (matriculaFilter && { matricula: matriculaFilter })
        },
        sort,
        perPage,
        page
      }
    });
  }, [page, role, region, channel, status, search, matriculaFilter]);
  const contestations = CampaignContestationPagination?.result?.items;
  const total = CampaignContestationPagination?.result?.pageInfo.itemCount;

  const getTranslation = (
    word: EnumCampaignContestationResponseStatus | null | undefined
  ) => {
    switch (word) {
      case 'unfounded':
        return 'Improcedente';
      case 'founded':
        return 'Procedente';
      default:
        return 'Em contestação';
    }
  };
  const data = contestations?.map((contestation) => {
    return {
      key: contestation?._id,
      title: contestation?.campaign?.title,
      tableStatus: contestation?.isResultContest ? 'Resultado' : 'Prévia',
      status: getTranslation(contestation?.response?.status),
      owner: contestation?.user?.name,
      region:
        contestation?.user?.region === 'THQ'
          ? 'BR'
          : contestation?.user?.region,
      channel: contestation?.user?.channel,
      role: contestation?.user?.roleGroup,
      matricula: contestation?.user?.matricula,
      protocol: contestation?.protocol,
      onRead: () =>
        history.push(routes.contestacaoEditar.mount(contestation?._id))
    };
  });

  const resetFilters = () => {
    setSearch(undefined);
    setPage(1);
    setRegion(undefined);
    setRole(undefined);
    setStatus(undefined);
    setChannel(undefined);
    setMatriculaFilter(undefined);
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={breadcrumb} />}>
      <Wrapper>
        <TitleDescription
          iconType={icon}
          title={pageName}
          description={description}
        />

        <FilterContainer>
          <Select
            placeholder="canal"
            options={channelOption}
            value={channel}
            onChange={(e) => {
              setPage(1);
              setChannel(e);
            }}
          />
          <Select
            placeholder="regional"
            options={regionOption}
            value={region}
            onChange={(e) => {
              setPage(1);
              setRegion(e);
            }}
          />
          <Select
            placeholder="cargo"
            options={roleOption}
            value={role}
            onChange={(e) => {
              setPage(1);
              setRole(e);
            }}
          />
          <Input
            placeholder="Filtrar por Matrícula"
            value={matriculaFilter}
            onChange={(e: any) => {
              setPage(1);
              setMatriculaFilter(
                e.target.value && String(e.target.value).trim() !== ''
                  ? e.target.value
                  : undefined
              );
            }}
            allowClear
          />
          <Select
            placeholder="status"
            options={statusOption}
            value={status}
            onChange={(e) => {
              setPage(1);
              setStatus(e);
            }}
          />
          <Search
            placeholder="Buscar pelo protocolo"
            value={search}
            onChange={(e) => {
              setPage(1);
              setSearch(Number(e.target.value));
            }}
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        <TablePagination
          loading={CampaignContestationPagination?.loading}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: perPage,
            current: page,
            total: total!,
            onChange: (e: any) => setPage(e)
          }}
        />
      </Wrapper>
    </PageTemplate>
  );
};
