import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DatePicker, Select, Input } from 'antd';

type FilterItemConfig =
  | {
      type: 'select';
      key: string;
      placeHolder: string;
      options: { label: string; value: string }[];
    }
  | { type: 'dateRange'; key: string; placeHolder: [string, string] }
  | { type: 'search'; key: string; placeHolder: string };

interface IInputFilters {
  actionButtons?: React.ReactNode;
  onChange: (filters: any) => void;
  config: FilterItemConfig[];
}

export const InputFilters = (props: IInputFilters) => {
  const [filters, setFilters] = useState<any>({});
  useEffect(() => {
    props.onChange(filters);
  }, [filters]);

  return (
    <FilterContainer>
      {props.actionButtons && props.actionButtons}

      {props.config.map((el) => {
        switch (el.type) {
          case 'select': {
            return (
              <Select
                placeholder={el.placeHolder}
                value={filters[el.key]}
                onChange={(data: string) => {
                  setFilters((state: any) => ({ ...state, [el.key]: data }));
                }}
              >
                {el.options.map((el) => (
                  <Select.Option key={el.value} value={el.value}>
                    {el.label}
                  </Select.Option>
                ))}
              </Select>
            );
          }
          case 'dateRange': {
            return (
              <DatePicker.RangePicker
                placeholder={el.placeHolder}
                value={
                  filters[el.key]
                    ? [filters[el.key].start, filters[el.key].end]
                    : undefined
                }
                onChange={([start, end]) => {
                  setFilters((state: any) => ({
                    ...state,
                    [el.key]: { start, end }
                  }));
                }}
              />
            );
          }
          case 'search': {
            return (
              <Input.Search
                placeholder={el.placeHolder}
                onChange={(evt) => {
                  setFilters((state: any) => ({
                    ...state,
                    [el.key]: evt.target.value
                  }));
                }}
              />
            );
          }
        }
      })}

      <CleanButton onClick={() => setFilters({})}>Limpar filtros</CleanButton>
    </FilterContainer>
  );
};

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;
  cursor: pointer;
`;

export const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;
  width: 100%;

  .ant-calendar-picker {
    max-width: 100% !important;
    width: 100% !important;

    .ant-calendar-range-picker-separator {
      display: none !important;
    }
  }
`;
