"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_final_form_1 = require("react-final-form");

function useEmailTemplateField() {
  var fieldName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'templateProps';
  return {
    HEADER: react_final_form_1.useField("".concat(fieldName, ".HEADER")),
    FOOTER: react_final_form_1.useField("".concat(fieldName, ".FOOTER")),
    TITLE_1: react_final_form_1.useField("".concat(fieldName, ".TITLE_1")),
    TITLE_2: react_final_form_1.useField("".concat(fieldName, ".TITLE_2")),
    TITLE_3: react_final_form_1.useField("".concat(fieldName, ".TITLE_3")),
    TEXT_1: react_final_form_1.useField("".concat(fieldName, ".TEXT_1")),
    TEXT_2: react_final_form_1.useField("".concat(fieldName, ".TEXT_2")),
    TEXT_3: react_final_form_1.useField("".concat(fieldName, ".TEXT_3")),
    TEXT_4: react_final_form_1.useField("".concat(fieldName, ".TEXT_4")),
    BUTTON_TITLE: react_final_form_1.useField("".concat(fieldName, ".BUTTON_TITLE")),
    BUTTON_LINK: react_final_form_1.useField("".concat(fieldName, ".BUTTON_LINK")),
    IMAGE_SRC_1: react_final_form_1.useField("".concat(fieldName, ".IMAGE_SRC_1")),
    IMAGE_SRC_2: react_final_form_1.useField("".concat(fieldName, ".IMAGE_SRC_2")),
    IMAGE_SRC_3: react_final_form_1.useField("".concat(fieldName, ".IMAGE_SRC_3")),
    IMAGE_SRC_4: react_final_form_1.useField("".concat(fieldName, ".IMAGE_SRC_4")),
    HIDE_BUTTON: react_final_form_1.useField("".concat(fieldName, ".HIDE_BUTTON")),
    FILE_ID_ATTACHMENT: react_final_form_1.useField("".concat(fieldName, ".FILE_ID_ATTACHMENT"))
  };
}

exports.useEmailTemplateField = useEmailTemplateField;