import React from 'react';
import { Button, message, Tooltip, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import styled from 'styled-components';

export interface AttachmentProps {
  imagesList?: UploadFile[];
  videosList?: UploadFile[];
}

interface UploadAttachmentInterface {
  disabled?: boolean;
  onChange?: (files: AttachmentProps) => void;
}

export const videoMimetypes: string[] = [
  'video/mpeg',
  'video/mp4',
  'video/quicktime',
  'video/msvideo',
  'video/x-sgi-movie',
  'video/x-ms-wmv'
];
export const imageMimetypes: string[] = [
  'image/gif',
  'image/jpeg',
  'image/png'
];
export const videoExtensions: string[] = [
  'mp4',
  'mpeg',
  'mpg',
  'mpe',
  'wmv',
  'mov',
  'qt',
  'avi',
  'movie'
];
export const imageExtensions: string[] = ['png', 'gif', 'jpeg', 'jpg', 'jpe'];

export const UploadAttachment = (props: UploadAttachmentInterface) => {
  const { disabled = false, onChange } = props;
  const [files, setFiles] = React.useState<UploadFile[] | undefined>([]);

  const onFileChange = (e: any) => {
    if (e && e?.fileList?.length > 0) {
      setFiles(e.fileList);
    }
  };

  const handleValidateFiles = (files: UploadFile[] = []) => {
    const maxSize = 104857600; //100mb
    let imagesList: UploadFile[] = [];
    let videosList: UploadFile[] = [];
    let error = undefined;

    files.map((file: UploadFile) => {
      error = Boolean(
        !file?.size ||
          file.size > maxSize ||
          (!videoMimetypes.includes(file.type) &&
            !imageMimetypes.includes(file.type))
      );
      if (imageMimetypes.includes(file.type)) {
        if (imagesList.length < 3) {
          imagesList.push(file);
        } else {
          message.error(`Por favor, envie no máximo 3 arquivos de imagem`);
        }
      } else if (videoMimetypes.includes(file.type)) {
        if (videosList.length < 2) {
          videosList.push(file);
        } else {
          message.error(`Por favor, envie no máximo 2 arquivos de vídeo`);
        }
      }
    });

    if (error) {
      message.error(
        'Por favor, selecione apenas arquivos válidos, imagem e/ou vídeo (com até 100mb).'
      );
    }

    if (onChange) {
      onChange({ imagesList, videosList });
    }
  };

  React.useEffect(() => {
    if (files) {
      handleValidateFiles(files);
    }
  }, [files]);

  return (
    <StyledUpload
      name="file"
      multiple={true}
      disabled={disabled}
      customRequest={() => {}}
      onChange={onFileChange}
      beforeUpload={() => false}
      showUploadList={false}
      fileList={[]}
    >
      <Tooltip title="Adicionar anexos">
        <Button icon={'upload'} disabled={disabled}></Button>
      </Tooltip>
    </StyledUpload>
  );
};

const StyledUpload = styled(Upload)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  @media (max-width: 500px) {
    margin-right: 0px;
  }
`;
