import * as React from 'react';
import { UseFieldConfig } from 'react-final-form';
import { Modal, Button, message } from 'antd';
import { useField } from '@digi-tim-19/components';
import { isArrayEmpty } from '@digi-tim-19/utils';
import { RegionalProfilesSelection } from './RegionalProfilesSelection';

export const RegionalProfilesField = (props: TRegionalProfilesFieldProps) => {
  const { name = 'availableAtRegionalProfiles', fieldConfig } = props;
  const field = useField<string[]>(name, fieldConfig);
  const [visible, setVisible] = React.useState(false);

  const isEmpty = React.useRef(!field.input.value?.length);
  if (isEmpty.current && field.input.value?.length) {
    isEmpty.current = false;
  }

  const initialValue = React.useMemo(() => {
    return field.input.value || [];
  }, [isEmpty.current]);

  return (
    <>
      <Button
        disabled={props?.readonly ? false : props?.disabled}
        type={'primary'}
        style={
          props?.disabled || props?.readonly ? { pointerEvents: 'auto' } : {}
        }
        onClick={() => {
          setVisible(!visible);
        }}
      >
        Selecionar Público
      </Button>
      <Modal
        width={'82vw'}
        closable={false}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          if (isArrayEmpty(field.input.value)) {
            message.error('Nenhum público foi selecionado');
          }
        }}
        onOk={() => {
          setVisible(false);
          if (isArrayEmpty(field.input.value)) {
            message.error('Nenhum público foi selecionado');
          }
        }}
        cancelButtonProps={{
          style: { display: 'none' }
        }}
        okButtonProps={{
          disabled: props?.disabled
        }}
      >
        <RegionalProfilesSelection
          initialSelection={initialValue}
          defaultChecked={props.defaultChecked}
          disabled={props.disabled}
          readonly={props?.readonly}
          onChange={(selected) => {
            field.input.onChange(selected);
          }}
        />
      </Modal>
    </>
  );
};

// export default RegionalProfilesField;

export type TRegionalProfilesFieldProps = {
  name?: string;
  readonly?: boolean;
  disabled?: boolean;
  defaultChecked?: string[];
  fieldConfig?: UseFieldConfig<any>;
};
