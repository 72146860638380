import React, { useState } from 'react';
import { usePaginatedClient } from 'hooks/usePaginatedClient';
import { Modal } from 'antd';
import styled from 'styled-components';
import { LoadingContainer } from 'components/LoadingContainer/LoadingContainer';
import { PaginationStyled } from 'components/TablePagination/TablePagination';

type ImgCard = { title: string; url: string; downloadUrl: string };
interface IImageFolderView {
  fileIds: string[];
}

export const ImageFolderView = (props: IImageFolderView) => {
  const { parsed, loading, pagination } = usePaginatedClient('FilePagination', {
    initial: {
      page: 1,
      perPage: 20,
      filter: { _ids: props.fileIds }
    },
    itemsFragment: `
      _id
      title
      url
      signedUrl
      downloadUrl
    `,
    fetchOnMount: true,
    parseResult: (files) =>
      files.map((el) => ({
        _id: el._id,
        title: el.title || '',
        url: el.signedUrl || el.url || '',
        downloadUrl: el.signedUrl || ''
      }))
  });

  const rowCount = parsed.length <= 5 ? 1 : parsed.length / 5;

  const [selected, setSelected] = useState<ImgCard>();

  return (
    <>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <FolderContainer>
            <ImageViewArea rows={rowCount}>
              {Boolean(parsed.length) &&
                parsed.map((card) => (
                  <ImageCard
                    key={card._id}
                    url={card.url}
                    onClick={() => setSelected(card)}
                  />
                ))}
            </ImageViewArea>
          </FolderContainer>
          <PaginationStyled {...pagination} />
          {selected && (
            <Modal
              width={500}
              title={selected.title}
              visible={Boolean(selected)}
              cancelButtonProps={{ hidden: true }}
              onCancel={() => setSelected(undefined)}
              onOk={() => setSelected(undefined)}
            >
              <ImageCard url={selected.url} view={true} />
              <a href={selected.url} download={selected.title}>
                {selected.title}
              </a>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

const FolderContainer = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .ant-pagination {
    margin-left: auto;
  }
`;

const ImageViewArea = styled.div<{ rows: number }>`
  min-height: 210px;
  display: flex;
  width: max-content;
`;

const ImageCard = styled.div<{ url: string; view?: boolean }>`
  width: ${(props) => (props?.view ? '450px' : '200px')};
  height: ${(props) => (props?.view ? '450px' : '200px')};
  cursor: pointer;
  border-radius: 5px;
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  ${(props) => props?.view && 'margin: 0px auto;'}

  @media (max-width: 400px) {
    width: 300px !important;
    height: 250px !important;
  }

  @media (max-width: 700px) {
    width: 350px;
    height: 300px;
  }
`;
