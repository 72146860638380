import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';
import { formatDate } from '@digi-tim-19/utils/build';
import {
  Breadcrumb,
  TitleDescription
} from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { FilterContainer } from '../../../components/PageFilter';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { Button, ListWrapper } from './styles';
import { columns } from './columns';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { SortFindManyCategoryInput } from '@digi-tim-19/components/build/main/DataProviders/types';

const fragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  
  items {
    _id
    name
    createdAt
    updatedAt
  }
`;

const configPage = {
  pageName: 'Gerenciamento de Playlists',
  icon: 'play-square',
  description: 'Cadastramento de playlists.',
  messageDeletedSuccess: 'Playlist removida com sucesso.',
  textButtonNew: 'Cadastrar Playlist',
  routeNew: routes.videosPlaylistCadastrar.mount(),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Playlists', link: routes.videosPlaylistListar.mount() }
  ],
  perPage: 10
};

export const PlaylistsListar = () => {
  const history = useHistory();
  const getCategories = useClient('CategoryPagination');
  const CategoryRemoveById = useClient('CategoryRemoveById');
  const [resultCategories, setResultCategories] = useState<any>([]);
  const [page, setPage] = useState(1);
  const total = getCategories?.result?.pageInfo.itemCount;

  useEffect(() => {
    getCategories
      .fetch({
        appendToFragment: fragment,
        variables: {
          page: page,
          perPage: configPage.perPage,
          filter: {
            parentId: "video_playlist"
          },
          sort: SortFindManyCategoryInput.SortAsc
        }
      })
      .then((ctx) => {
        if (ctx.result && ctx.result.items) {
          setResultCategories(ctx.result.items);
        }
      });
  }, [page]); //eslint-disable-line

  const data = resultCategories.map((el: any) => {
    return {
      key: el?._id,
      id: el?._id,
      name: el?.name,
      createdAt: formatDate(el?.createdAt, 'DD/MM/YY'),
      updatedAt: formatDate(el?.updatedAt, 'DD/MM/YY'),
      hideOnRead: true,
      onEdit: () =>
        history.push(
          routes.videosPlaylistEditar.mount(el?._id)
        ),
      onDelete: () => {
        CategoryRemoveById.fetch({
          variables: { _id: el?._id },
          afterMutate: /^Category/
        }).then(() => {
          message.success(configPage.messageDeletedSuccess);
          setPage(1);
        });
      }
    };
  });

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={configPage.breadcrumb}
        />
      }
    >
      <ListWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <FilterContainer>
          <Button to={configPage.routeNew}>{configPage.textButtonNew}</Button>
        </FilterContainer>
        <TablePagination
          loading={getCategories.loading}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: configPage.perPage,
            current: page,
            total: total!,
            onChange: (e: any) => setPage(e)
          }}
        />
      </ListWrapper>
    </PageTemplate>
  );
};
