import React from 'react';
import { Button, EnumFileKind, useUpload } from '@digi-tim-19/components';
import { Modal as AntdModal, Icon, message, Upload } from 'antd';
import styled from 'styled-components';
import { fileToBuffer } from 'utils/file';
import * as XLSX from 'xlsx';

const Modal = styled(AntdModal)`
  .ant-modal-body {
    max-height: 350px;
    padding: 8px 24px !important;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      font-style: italic;
      font-size: 11pt;
      margin: 0;
    }
  }

  .ant-upload-select-text {
    width: 100%;
  }
`;

interface Props {
  file?: {
    _id: string;
    title: string;
    signedUrl?: string;
  };
  onChange: (_id: string) => void;
}

export const UploadMailing = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const execute = useUpload(EnumFileKind.Mailing);

  const uploadFileWithValidation = async (e: any) => {
    if (!e?.file) return;
    let errorsList: string[] = [];

    const file = await fileToBuffer(e.file);

    const data = XLSX.read(file);

    if (data?.SheetNames?.length > 1) {
      errorsList.push(
        `Mais de uma planilha/pasta de trabalho: ${data.SheetNames.join(', ')}`
      );
    }

    if (
      data?.SheetNames?.length &&
      data?.Sheets &&
      Object.keys(data?.Sheets).length
    ) {
      const invalidColumn = Boolean(
        Object.keys(data.Sheets[data.SheetNames[0]]).find(
          (el) => !['!', 'A'].includes(el[0])
        )
      );
      if (invalidColumn)
        errorsList.push(
          'Mais de uma coluna (Obrigatório ter somente a primeira coluna com os e-mails)'
        );

      const keys = Object.keys(data.Sheets[data.SheetNames[0]]).filter(
        (el) => el[0] === 'A'
      );
      const values = keys.map((el: any) => {
        return {
          cell: el,
          value: data.Sheets[data.SheetNames[0]][el]?.v
        };
      });
      const emails = values.filter((el: any) => {
        const isValid = Boolean(
          /^[a-z][a-z0-9\-\_\.]*@[a-z0-9\-\_]+\.[a-z]+(\.[a-z]+)?$/i.test(
            el.value
          )
        );
        if (!isValid)
          errorsList.push(`Célula: ${el.cell}, e-mail: "${el.value}" inválido`);
        return isValid;
      });

      if (!emails.length) errorsList.push('Planilha sem e-mails válidos');
    }

    if (errorsList.length) {
      setErrors(errorsList);
      return;
    }

    setLoading(true);

    await execute(e, (_id: any) => {
      setFileList([e.file]);
      props.onChange(_id);
      message.success('Planilha enviada com sucesso');
      setOpen(false);
    })
      .catch(() => {
        message.error('Não foi possível fazer o upload da planilha');
      })
      .finally(() => setLoading(false));
  };

  const getUrlDownloadFile = (file?: any, fileList: any[] = []) => {
    const exists =
      file?._id && fileList.find((el) => el.uid === `rc-upload-${file._id}`);
    if (exists && file?.signedUrl) {
      return file.signedUrl;
    }
    return URL.createObjectURL(fileList[0]);
  };

  React.useEffect(() => {
    if (props?.file) {
      setFileList([
        {
          name: props.file.title,
          uid: `rc-upload-${props.file._id}`
        }
      ]);
    }
  }, [props?.file]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Button onClick={() => setOpen(true)} disabled={Boolean(props?.file)}>
          IMPORTAR MAILING
        </Button>
        {Boolean(fileList.length) && (
          <a
            style={{
              pointerEvents: 'auto',
              padding: '10px 0',
              textAlign: 'center'
            }}
            href={getUrlDownloadFile(props?.file, fileList)}
            target="_blank"
          >
            Baixar mailing
          </a>
        )}
      </div>
      <Modal
        title="IMPORTAR MAILING"
        visible={open}
        okText="FINALIZAR"
        cancelText="FECHAR"
        onOk={() => {
          if (!loading) setOpen(false);
        }}
        onCancel={() => {
          if (!loading) setOpen(false);
        }}
        okButtonProps={{
          loading: loading,
          disabled: loading,
          hidden: true
        }}
        cancelButtonProps={{
          loading: loading,
          disabled: loading
        }}
      >
        <p>Faça o upload da planilha e aguarde a validação.</p>
        <p>
          O arquivo deverá conter apenas uma planilha/pasta, uma coluna sem
          título/cabeçalho, sem espaço, sem linhas vazias e sem colunas ocultas,
          apenas com os e-mails preenchidos.
        </p>
        <Upload
          style={{ width: '100%', marginTop: '10px' }}
          name="file"
          listType="text"
          accept={'.xlsx'}
          multiple={false}
          fileList={fileList}
          beforeUpload={() => true}
          customRequest={async (e: any) => {
            setErrors([]);
            setFileList([]);
            await uploadFileWithValidation(e);
          }}
          showUploadList={false}
          disabled={Boolean(props?.file)}
        >
          <Button
            disabled={Boolean(props?.file)}
            style={{ width: '100%', marginTop: '10px' }}
          >
            {loading && <Icon type="loading" />}
            UPLOAD DA PLANILHA
          </Button>
        </Upload>
        {Boolean(fileList.length) && (
          <span
            style={{ marginTop: '5px', fontSize: '11pt', fontWeight: 'bold' }}
          >
            Arquivo: {fileList[0].name}
          </span>
        )}
        {Boolean(errors?.length) && (
          <>
            {errors.map((err) => {
              return (
                <span
                  style={{ color: 'red', marginTop: '5px', fontSize: '11pt' }}
                >
                  - {err}
                </span>
              );
            })}
          </>
        )}
      </Modal>
    </>
  );
};
