import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { FilterContainer } from 'components/PageFilter';
import { FormContainer } from 'components/Former';
import { colors } from '@digi-tim-19/theme';

export const PageWrapper = styled.div`
  width: 100%;
`;

export const FilterWrapper = styled(FilterContainer as any)`
  .ant-calendar-range-picker-input {
    text-align: start;
  }
`;

export const NewButton = styled(Link)`
  border: none;
  background: red;
  display: flex;
  justify-content: center;

  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-family: TimBold;
  width: 100%;
  cursor: pointer;
  outline: none;
  margin-right: 15px;
  &:hover {
    background: white;
    color: red;
    outline: none !important;
  }
`;

export const Container = styled(FormContainer as any)`
  .ant-upload-list {
    position: absolute;
    bottom: -25px;
  }
  .ant-select-selection__placeholder {
    left: 9px;
  }

  .Footer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    .FieldWrapper {
      max-width: 210px !important;
      margin-right: 15px;
      margin-bottom: 25px;
    }

    span {
      margin-top: 0 !important;
    }
  }

  .FieldWrapper:nth-of-type(1) {
    align-items: center !important;
    justify-content: flex-start;
    flex-wrap: wrap;
    .name-categories {
      margin-left: 15px;
    }
  }

  .name-status {
    margin-left: 15px;
  }
  .name-documento,
  .name-thumbnail {
    margin: 0 10px;
    > span {
      display: flex;

      .anticon-loading {
        display: none;
      }
      .ant-upload-list-item-progress {
        display: none;
      }
      .anticon-close {
        right: -2px;
      }
    }
  }
  .name-description {
    .rdw-editor-wrapper {
      height: 300px !important;
    }
  }
`;

export const StyledContent = styled.section`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
`;

export const OrderWrapper = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
  & .ant-calendar-range-picker-separator {
    display: none !important;
  }
`;

export const CardsVisual = styled.div`
  width: 100%;
`;

export const ChooseVisualWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  i {
    padding-right: 20px;
  }
`;

export const ContentTitle = styled.div`
  color: ${colors.blue};
  font-family: TimBold;
  font-size: 18px;
`;

export const ContentDate = styled.div`
  color: ${colors.gray1};
  font-size: 12px;
  margin-bottom: 10px;
`;

export const ContentDescription = styled.div`
  margin: 10px 0;
`;
