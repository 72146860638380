import { Category } from 'autogenerated/client/types';
import { routes } from 'config/routes';

type TBreadcrumbs = {
  title: string;
  link?: any;
};

export function useBreadcrumb(title: string, category: Category) {
  let breadcrumb: TBreadcrumbs[] = title ? [{ title }] : [];

  function getBreadcrumbItems(category: Category): any {
    if (!category || !category.name) return;
    const { name: title, routeName, parentCategory } = category;
    const isDocumentRoute = routeName?.includes('documentos');

    if (isDocumentRoute && category._id) {
      const catId: string[] = category._id.split(':').slice(1);

      const categoryRoute: any = () => {
        switch (routeName) {
          case 'documentos':
            return routes.documentos;
          case 'documentosMenu':
            return routes.documentosMenu;
          case 'documentosCategoria':
            return routes.documentosCategoria;
          case 'documentosSubCategoria':
            return routes.documentosSubCategoria;
          case 'documentosSubSubCategoria':
            return routes.documentosSubSubCategoria;
        }
      };

      breadcrumb.unshift({
        title,
        link: categoryRoute().mount.apply(null, catId)
      });
    } else {
      breadcrumb.unshift({
        title
      });
    }

    if (!!parentCategory && parentCategory.name !== 'Menu')
      return getBreadcrumbItems(parentCategory);

    return breadcrumb.unshift({ title: 'Home', link: routes.home.mount() });
  }
  getBreadcrumbItems(category!);

  return breadcrumb;
}
