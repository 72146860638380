export const handleCPFInput = (cpfValue: string) => {
  const numeric = cpfValue.replace(/[^0-9]+/g, '');
  const cpfLength = numeric.length;

  const partOne = numeric.slice(0, 3) + '.';
  const partTwo = numeric.slice(3, 6) + '.';
  const partThree = numeric.slice(6, 9) + '-';

  const cpfInput: any = document.getElementById('cpfInput');

  if (cpfInput) {
    if (cpfLength < 4) {
      cpfInput.value = numeric;
    } else if (cpfLength >= 4 && cpfLength < 7) {
      const formatCPF = partOne + numeric.slice(3);
      cpfInput.value = formatCPF;
    } else if (cpfLength >= 7 && cpfLength < 10) {
      const formatCPF = partOne + partTwo + numeric.slice(6);
      cpfInput.value = formatCPF;
    } else if (cpfLength >= 10 && cpfLength < 12) {
      const formatCPF = partOne + partTwo + partThree + numeric.slice(9);
      cpfInput.value = formatCPF;
    } else if (cpfLength >= 12) {
      const formatCPF = partOne + partTwo + partThree + numeric.slice(9, 11);
      cpfInput.value = formatCPF;
    }
  }
};

export const handleCNPJInput = (value: string) => {
  const cnpjInput: any = document.getElementById('cnpjInput');
  const formatted = value
    .replace(/\D/g, '')
    .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);

  if (formatted) {
    if (!formatted[3]) {
      return (cnpjInput.value = !formatted[2]
        ? formatted[1]
        : formatted[1] + '.' + formatted[2] + '.');
    }

    if (!formatted[5]) {
      return (cnpjInput.value = !formatted[2]
        ? formatted[1]
        : formatted[1] +
          '.' +
          formatted[2] +
          '.' +
          formatted[3] +
          '/' +
          formatted[4]);
    }

    return (cnpjInput.value = !formatted[2]
      ? formatted[1]
      : formatted[1] +
        '.' +
        formatted[2] +
        '.' +
        formatted[3] +
        '/' +
        formatted[4] +
        (formatted[5] ? '-' + formatted[5] : ''));
  }
};
