import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { Icon, Pagination } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  CardInterno,
  LoadingIndicator,
  ListInterno,
  useCurrentUser
} from '@digi-tim-19/components';

import { getAccessButton } from '../../utils/material';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumFileKind,
  EnumMaterialKind,
  EnumMaterialStatus,
  Material,
  SortFindManyMaterialInput
} from 'autogenerated/client/types';
import { routes } from '../../config/routes';
import { useBreadcrumb } from 'hooks/useBreadcrumb';

import * as S from './styles';
import * as F from './fragments';
import { LoadingContainer } from 'components/LoadingContainer/LoadingContainer';
import { EmptyContainer } from 'components/TablePagination/EmptyContainer';
import { userIsResidential } from 'utils/validations';

enum ListMode {
  card,
  list
}

export const Documentos = () => {
  const user = useCurrentUser()?.result;
  const [visualMode, setVisualMode] = useState<ListMode>(ListMode.card);
  const [category, setCategory] = useState<any>({});
  const [categoryMaterials, setCategoryMaterials] = useState<any>([]);
  const [page, setPage] = useState(1);
  const breadcrumb = useBreadcrumb('', category!);
  const categoryFind = useClient('CategoryFindMany');
  const materialPagination = useClient('MaterialPagination');
  const apiAnalyticsDownload = useClient('AnalyticsDownloads');
  const { params }: any = useRouteMatch();
  const joinParamsValues = Object.values(params).join(':');
  const routeCategory = `menu:${joinParamsValues}`;
  const categoryId = routeCategory.replace(/-/g, '_');
  const perPage = 15;
  const isResidential = userIsResidential(user);

  const handleTrackDownload = (fileId: string | undefined) =>
    apiAnalyticsDownload.fetch({ variables: { fileId } });

  useEffect(() => {
    setCategoryMaterials([]);
    categoryFind
      .fetch({
        variables: {
          filter: { _id: categoryId },
          limit: 1
        },
        fetchOnMount: true,
        appendToFragment: F.CategoryFragment
      })
      .then((ctx) => {
        if (ctx.result) {
          return setCategory(ctx.result?.find((item: any) => item._id));
        }
      });
  }, [params]);

  useEffect(() => {
    materialPagination
      .fetch({
        variables: {
          filter: {
            kind: EnumMaterialKind.Document,
            andCategories: {
              in: [categoryId],
              nin: [
                'menu:para_voce:comunicacao:informativos',
                'menu:informativos'
              ]
            },
            OR: [
              { status: EnumMaterialStatus.Approved },
              { status: EnumMaterialStatus.Published }
            ]
          },
          sort: SortFindManyMaterialInput.UpdatedatDesc,
          perPage,
          page
        },
        fetchOnMount: true,
        appendToFragment: F.DocumentFragment
      })
      .then((ctx) => {
        if (ctx.result) {
          setCategoryMaterials(
            ctx?.result?.items?.map((material) => {
              return { ...material, publishedAt: material?.updatedAt };
            }) || []
          );
        }
      });
  }, [category, page]);

  const MaterialsCount = materialPagination.result?.count || 0;
  const isMatLoading = materialPagination.loading;
  const isMatEmpty = categoryMaterials?.length === 0;

  const configPage = {
    pageName: category?.name,
    icon: 'inbox',
    description: category?.description || '',
    breadcrumb
  };

  if (categoryFind.loading) {
    return (
      <PageTemplate>
        <LoadingIndicator />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {isMatLoading && <LoadingContainer />}
        {!isMatLoading && !!isMatEmpty && <EmptyContainer />}

        {!isMatLoading && !isMatEmpty && (
          <S.DocumentosWrapper>
            <S.ChooseVisualWrapper>
              <Icon
                onClick={() => setVisualMode(ListMode.card)}
                type="appstore"
                style={{ fontSize: '35px' }}
              />
              <Icon
                onClick={() => setVisualMode(ListMode.list)}
                type="unordered-list"
                style={{ fontSize: '35px' }}
              />
            </S.ChooseVisualWrapper>

            <InnerPageData
              mode={visualMode}
              materials={categoryMaterials}
              isResidential={isResidential}
              handleTrackDownload={handleTrackDownload}
            />

            <S.PaginationWrapper>
              <Pagination
                pageSize={perPage}
                current={page}
                total={MaterialsCount}
                onChange={(page) => setPage(page)}
              />
            </S.PaginationWrapper>
          </S.DocumentosWrapper>
        )}
      </>
    </PageTemplate>
  );
};

const InnerPageData = (props: {
  mode: ListMode;
  materials: any;
  isResidential?: boolean;
  handleTrackDownload?: (fileId: string) => void;
}) => {
  const history = useHistory();
  const { mode, materials, isResidential, handleTrackDownload } = props;

  const action = (material: Material) => ({
    pin: true,
    share: true,
    accessButton: getAccessButton(material, history, routes, {
      access: !isResidential,
      callback: (item) => {
        const fileDownload = item.files?.find(
          (el) => el?.kind !== EnumFileKind.CardThumb
        );
        if (handleTrackDownload && fileDownload) {
          handleTrackDownload(fileDownload._id);
        }
      }
    })
  });

  const defaultImage = '/defaultImages/default-card-thumb.png';

  if (mode === ListMode.card) {
    return (
      <CardInterno
        items={materials}
        defaultImage={defaultImage}
        actions={action}
      />
    );
  } else {
    return materials.map((item: any) => {
      return (
        <ListInterno
          key={item?._id}
          material={item}
          defaultImage={defaultImage}
          actionsMaterial={action}
        />
      );
    });
  }
};
