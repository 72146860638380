import { useUpload } from '@digi-tim-19/components';
import { UploadFile } from 'antd/lib/upload/interface';
import { useClient } from 'autogenerated/client/client';
import { EnumFileKind } from 'autogenerated/client/types';
import { CreatePostInterface } from '../CreatePost';

interface PostProps {
  eventId?: string;
  variables: CreatePostInterface;
}

interface UploadProps {
  file: UploadFile;
  callback: (e: any, onChange: any) => Promise<string | undefined>;
}

export const useCreatePost = () => {
  const client = useClient('FeedCreateOne');
  const uploadImage = useUpload(EnumFileKind.SocialFeedImage, false);
  const uploadVideo = useUpload(EnumFileKind.SocialFeedVideo, false);

  const create = async ({ eventId, variables }: PostProps) => {
    let images = [];
    let videos = [];
    const record: any = {
      eventId,
      text: variables?.text
    };

    if (variables?.imageFiles) {
      for (const file of variables.imageFiles) {
        const id = await updload({
          file,
          callback: uploadImage
        });
        images.push(id);
      }
    }

    if (variables?.videoFiles) {
      for (const file of variables.videoFiles) {
        const id = await updload({
          file,
          callback: uploadVideo
        });
        videos.push(id);
      }
    }

    await client.fetch({
      variables: { record: { ...record, images, videos } }
    });
  };

  return { create };
};

const updload = async ({ file, callback }: UploadProps) => {
  let id = undefined;

  await callback(
    {
      file: file.originFileObj,
      onSuccess: () => {}
    },
    (recordId: string) => {
      id = recordId;
    }
  );

  return id;
};
