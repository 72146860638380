import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const PageWrapper = styled.div`
  width: 100%;
`;

export const Button = styled(Link)`
  border: none;
  background: red;
  display: flex;
  justify-content: center;

  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-family: TimBold;
  width: 100%;
  cursor: pointer;
  outline: none;
  margin-right: 15px;
  &:hover {
    background: white;
    color: red;
    outline: none !important;
  }
`;
