import React from 'react';
import styled from 'styled-components';
import { notification } from 'antd';
import { useHistory } from 'react-router';
import { Former } from '@digi-tim-19/components';

import { useClient } from '../../autogenerated/client/client';
import { routes } from '../../config/routes';

const AlterarSenhaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & form {
    margin: 50px;
    padding: 20px;
    width: 50%;

    .FormFooter {
      button {
        font-size: 14px;
        font-weight: 600;
      }
    }

    @media screen and (max-width: 768px) {
      width: 100%;

      .FormFooter {
        button {
          margin: 5px 0;
          width: 100%;
        }
      }
    }
  }
`;

const PasswordValidateWrapper = styled.div`
  color: #6c757d;
  margin: 0;
  width: 100%;

  h3 {
    color: #6c757d;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
`;

const ValidateItem = styled.li<{ isValid?: boolean }>`
  color: ${(props) => (props.isValid ? props.theme.green : '')};
  font-size: 14px;
`;

const PasswordValidateList = ({ formProps }: any) => {
  const { values, dirty, touched } = formProps;
  const newPass = values.newPass || '';

  const passIsEqual = values.newPass === values.confirmPassword;
  const hasEight = newPass.length >= 8;
  const hasNumber = /\D/.test(newPass);
  const hasUpperChar = /[A-Z]/.test(newPass);
  const hasSpecialCharacter = /[^A-Za-z0-9]/.test(newPass);
  const isConfirmPass = touched.confirmPassword && dirty;

  return (
    <PasswordValidateWrapper>
      <h3>Sua senha deve ter:</h3>
      <ValidateItem isValid={hasUpperChar}>
        Ao menos uma letra maiúscula
      </ValidateItem>
      <ValidateItem isValid={hasNumber}>Ao menos um número</ValidateItem>
      <ValidateItem isValid={hasEight}>Mais de 8 caracteres</ValidateItem>
      <ValidateItem isValid={hasSpecialCharacter}>
        Caractere especial
      </ValidateItem>
      {isConfirmPass && (
        <ValidateItem isValid={passIsEqual}>
          Sua nova senha e a confirmação devem ser iguais
        </ValidateItem>
      )}
    </PasswordValidateWrapper>
  );
};

export const AlterarSenha = () => {
  const history = useHistory();
  const UserChangePassword = useClient('UserChangePassword');

  return (
    <AlterarSenhaWrapper>
      <Former
        config={{
          fields: [
            {
              name: 'newPass',
              label: 'Nova senha',
              type: 'password',
              required: true
            },
            {
              name: 'confirmPassword',
              label: 'Confirmar nova senha',
              type: 'password',
              required: true
            },
            {
              custom: {
                render: (props) => <PasswordValidateList formProps={props} />
              }
            }
          ],
          submitButton: { label: 'ALTERAR SENHA' },
          cancelButton: {
            label: 'Ir para login',
            onClick: () => {
              history.push(routes.loginCPF.mount());
            }
          }
        }}
        onSubmit={async ({ data }) => {
          if (data.newPass !== data.confirmPassword) {
            notification.info({
              message: 'A nova senha e a confirmação da senha são diferentes'
            });
          } else {
            UserChangePassword.fetch({
              variables: { newPassword: data.newPass },
              middleware: async function (ctx) {
                const token = window.location.hash.split('#token=')[1];

                if (ctx.requestConfig && token) {
                  ctx.requestConfig.headers = {
                    ...(ctx.requestConfig.headers || {}),

                    Authorization: `Bearer ${token}`
                  };
                }
                return ctx;
              }
            }).then((ctx) => {
              if (ctx.result) {
                notification.success({ message: 'Nova senha cadastrada' });
                history.push(routes.loginCPF.mount());
              }
            });
          }
        }}
      />
    </AlterarSenhaWrapper>
  );
};

export default AlterarSenha;
