"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var verdade_1 = require("../utils/verdade");

var __1 = require(".."); // import { ValidityEnum } from './DataProviders/types';


var S = __importStar(require("./StylesCardCampaign"));

var CardCampaignItem_1 = require("./CardCampaignItem");

exports.CardIncentiveCampaign = function (props) {
  var _a;

  var items = parseCardsDataIncentiveCampaign(props.items);
  return React.createElement(S.Wrapper, null, props.count && props.onLoadMore ? React.createElement(S.WrapperCard, null, React.createElement(S.CardScrollWrapper, null, React.createElement(__1.InfiniteScroll, {
    count: props.count,
    onLoadMore: props.onLoadMore
  }, items.map(function (_ref, index) {
    var incentiveCampaign = _ref.incentiveCampaign,
        cardData = _ref.cardData;
    return React.createElement(S.CardItemWrapper, {
      key: "campaign-card-".concat(index)
    }, React.createElement(CardCampaignItem_1.CardCampaignItem, {
      incentiveCampaign: incentiveCampaign,
      title: cardData.tituloHeader,
      description: incentiveCampaign.description,
      imgBody: cardData.imgBody,
      defaultImage: props.defaultImage,
      actions: props.actions
    }));
  })))) : React.createElement("div", {
    style: {
      display: "contents"
    }
  }, ((_a = props) === null || _a === void 0 ? void 0 : _a.tour) && props.tour, items.map(function (_ref2, index) {
    var incentiveCampaign = _ref2.incentiveCampaign,
        cardData = _ref2.cardData;
    return React.createElement(S.CardItemWrapper, {
      key: "campaign-card-".concat(index),
      className: "tour-campaign-card-".concat(index)
    }, React.createElement(CardCampaignItem_1.CardCampaignItem, {
      incentiveCampaign: incentiveCampaign,
      title: cardData.tituloHeader,
      description: incentiveCampaign.description,
      imgBody: cardData.imgBody,
      defaultImage: props.defaultImage,
      actions: props.actions
    }));
  })));
};

function parseCardsDataIncentiveCampaign(incentiveCampaign, mountActions) {
  return verdade_1.verdade(incentiveCampaign).map(function (camp) {
    var _a;

    var imageFile = camp.cardImageFile;
    var imgBody = ((_a = imageFile) === null || _a === void 0 ? void 0 : _a.signedUrl) || '';
    var title = String(camp.title) || '';
    var limitCharacteres = 80;
    return {
      incentiveCampaign: camp,
      cardData: {
        tituloHeader: title.length > limitCharacteres ? "".concat(title.substring(0, limitCharacteres), "...") : title,
        imgBody: imgBody,
        actions: mountActions && mountActions(camp) || {}
      }
    };
  });
}

exports.parseCardsDataIncentiveCampaign = parseCardsDataIncentiveCampaign;