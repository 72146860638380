import { colors } from '@digi-tim-19/theme';
import styled from 'styled-components';

export const Divider = styled.hr`
  border: none;
  background: #ccc;
  height: 1px;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  margin-bottom: 25px;

  .FormHeader {
    > div {
      justify-content: center !important;
    }
  }

  .form-section-container {
    background: #f5f5f5;
    border-radius: 4px;
    padding: 25px 20px;

    .header {
      flex-wrap: wrap !important;

      > .FieldWrapper {
        margin-left: 20px;
        margin-right: 0 !important;
        max-width: 250px;
        &:first-of-type {
          margin: 0;
        }
      }
    }

    .Footer {
      > div {
        justify-content: flex-start;
      }
    }
  }

  .ant-upload-list {
    position: absolute;
    bottom: -25px;
  }
  .ant-select-selection__placeholder {
    left: 9px;
  }

  .name-imageDesktop,
  .name-imageMobile {
    margin: 0 1%;

    > span {
      display: flex;

      .anticon-loading {
        display: none;
      }
      .ant-upload-list-item-progress {
        display: none;
      }
      .anticon-close {
        right: -2px;
      }
    }
  }

  .Footer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    .FieldWrapper:nth-of-type(1) {
      max-width: 210px;
      margin-right: 15px;
    }
    .FieldWrapper:nth-of-type(2) {
      max-width: 210px;
      margin-right: 15px;
      position: relative;
    }
  }
  .FieldWrapper:nth-of-type(1) {
    align-items: center !important;
    justify-content: flex-start;
    flex-wrap: wrap;
    .name-categories {
      margin-left: 15px;
    }
  }
  .name-status {
    margin-left: 15px;
  }
  .name-documento,
  .name-thumbnail {
    max-width: 30% !important;
    > span {
      display: flex;

      .anticon-loading {
        display: none;
      }
      .ant-upload-list-item-progress {
        display: none;
      }
      .anticon-close {
        right: -2px;
      }
    }
  }
  .name-mailing {
    max-width: 210px !important;
    width: 100% !important;
    span,
    button,
    div {
      width: 100%;
    }
    svg {
      display: none;
    }
    button.ant-btn {
      background: ${colors.blue};
      color: white;
      max-width: 210px !important;
      width: 100% !important;
    }
  }
  .name-mailing {
    max-width: 210px !important;
    width: 100% !important;
    span,
    button,
    div {
      width: 100%;
    }
    svg {
      display: none;
    }
    button.ant-btn {
      background: ${colors.blue};
      color: white;
      max-width: 210px !important;
      width: 100% !important;
    }
  }

  .FormFooter {
    margin: 30px 0 20px;

    > button {
      border-radius: 5px;
      border: 1px solid ${(props) => props.theme.green};
      font-size: 14px;
      font-weight: bold;
    }

    .Former__cancel-button {
      background: transparent;
      color: ${(props) => props.theme.green};
    }

    .Former__submit-button {
      background: ${(props) => props.theme.green};
      color: ${(props) => props.theme.white};
    }
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
`;
