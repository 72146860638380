import styled from 'styled-components';
import { Modal } from 'antd';
import { colors } from '@digi-tim-19/theme';

export const IncentiveCampaignWrapper = styled.div`
  width: 100%;
`;

export const CategoriaMenu = styled.div`
  width: 100%;
  text-align: left;
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;

  .contestation-period {
    min-width: 220px;
  }

  .grid-row {
    display: flex !important;
    .component-upload {
      margin: 0;
    }

    .switch {
      display: flex;
    }
    @media (max-width: 1270px) {
      flex-direction: column !important;
      .component-upload {
        width: 100%;
      }
      .switch {
        min-height: 86px;
      }
    }
    @media (max-width: 750px) {
      .component-upload {
        margin: 0;
        button {
          margin-top: 5px;
          width: 100%;
        }
      }
    }
  }
  .form-section-container {
    background: #f5f5f5;
    border-radius: 4px;
    padding: 25px 20px;

    .header {
      &.header-file-card-banner {
        > div {
          margin-right: 2%;
        }

        .component-upload {
          margin-top: 0;
        }
      }

      &.header-file-regulation-presentation {
        > div {
          max-width: 215px;
          margin-right: 2%;
        }
      }

      &.header-file-origin-wallet {
        margin-top: 1em;
        width: 100%;

        > div {
          width: 100%;
          max-width: 400px;
          margin-right: 2%;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;

        &.header-file-card-banner,
        &.header-file-regulation-presentation {
          > div {
            max-width: 100%;
            width: 100%;
            margin-right: 0;

            .component-upload {
              width: 100%;

              .ant-upload {
                width: 100%;

                .ant-btn {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .Footer {
      > div {
        justify-content: flex-start;
      }
    }
  }

  .FormFooter {
    margin: 30px 0 20px;

    > button {
      border-radius: 5px;
      border: 1px solid ${(props) => props.theme.green};
      font-size: 14px;
      font-weight: bold;
    }

    .Former__cancel-button {
      background: transparent;
      color: ${(props) => props.theme.green};
    }

    .Former__submit-button {
      background: ${(props) => props.theme.green};
      color: ${(props) => props.theme.white};
    }
  }
`;

export const ErrorsModal = styled(Modal)`
  max-width: 900px !important;
  width: 100% !important;
  .ant-modal-body,
  .ant-modal-footer {
    background: #ededed;
  }
  .ant-modal-close-x {
    svg {
      fill: #fff;
    }
  }
  .ant-modal-header {
    background: ${colors.danger};
    .ant-modal-title {
      color: #fff;
    }
  }
  input:read-only {
    background: #f8f8f8;
  }
`;

export const Divider = styled.hr`
  border: none;
  background: #ccc;
  height: 1px;
  width: 100%;
  margin: 20px 0;
`;
