import { createXLSX, download, sheetParser } from '@digi-tim-19/utils';

export const downloadXLSX = (fileName: string, schema: any, data: any[]) => {
  const { rows } = sheetParser<any>()
    .attachSchema(schema ?? {})
    .toSheetData(data ?? []);

  const s3Url = 'https://assets-tim-dev.s3-accelerate.amazonaws.com';

  const file = createXLSX(
    [
      {
        name: 'modelo',
        json: rows,
        transformSheet: (ws) => {
          Object.keys(ws).map((key) => {
            if (
              ws[key].v &&
              String(ws[key].v).substring(0, s3Url.length) === s3Url
            ) {
              ws[key].l = { Target: ws[key].v, Tooltip: 'Acessar link' };
            }
          });
          return ws;
        }
      }
    ],
    {}
  ).write('base64');

  download(
    `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file}`,
    fileName
  );
};
