import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { CreateOneEventInput } from '../../../autogenerated/client/types';
import { message } from 'antd';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Form } from '../FormManage';

const DocumentsWrapper = styled.div`
  width: 100%;
`;

export const EventosCadastrar = () => {
  const history = useHistory();
  const client = useClient('EventCreateOne', { afterMutate: /^Event/ });

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            { title: 'Eventos', link: routes.eventosGerenciar.mount() },
            { title: 'Cadastrar' }
          ]}
        />
      }
    >
      <DocumentsWrapper>
        <TitleDescription
          iconType="inbox"
          title="Cadastrar Evento"
          description={'Consulta e cadastramento de eventos.'}
        />

        <ProviderSelecionarPublico>
          <Form
            onCancel={() => history.push(routes.eventosGerenciar.mount())}
            onSubmit={(record) => {
              client
                .fetch({ variables: { record: record as CreateOneEventInput } })
                .then((ctx) => {
                  if (ctx?.result) {
                    message.success('Evento cadastrado com sucesso');
                    history.push(routes.eventosGerenciar.mount());
                  } else {
                    message.error('Erro ao cadastrar evento');
                  }
                });
            }}
          />
        </ProviderSelecionarPublico>
      </DocumentsWrapper>
    </PageTemplate>
  );
};
