import styled from 'styled-components';

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;
  cursor: pointer;
`;

export const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;
  width: 100%;

  .ant-calendar-picker {
    max-width: 100% !important;
    width: 100% !important;

    .ant-calendar-range-picker-separator {
      display: none !important;
    }
  }
`;
