import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

export const SearchWrapper = styled.div``;

export const WordCloudWrapper = styled.div`
  max-height: 260px;
`;

export const ResultSearchWrapper = styled.div`
  width: 100%;
`;

export const AmountResultsFound = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.gray0};
`;

export const AmountResults = styled.span`
  text-transform: uppercase;
  color: ${(props) => props.theme.blue};
  font-weight: bold;
  margin-left: 3px;
`;

export const PaginationWrapper = styled.div`
  margin-top: 30px;
`;

export const OptionsListResultWrapper = styled.div`
  margin: 30px 0;
`;

export const Modal = styled(AntdModal)`
  .ant-modal-body {
    max-height: 350px;
    padding: 16px 24px !important;
    overflow: auto;
  }

  .ant-modal-body .ant-divider-horizontal {
    margin: 12px 0px !important;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: max-content;
`;
