import { useClient } from 'autogenerated/client/client';

export const useUpdatePost = () => {
  const client = useClient('FeedUpdateById');
  const updatePost = async (_id: string, record: any = {}) => {
    return await client.fetch({
      variables: {
        record: { _id, ...record }
      }
    });
  };
  return { updatePost, loading: client.loading };
};
