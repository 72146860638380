import React, { useEffect, useState } from 'react';
import { Button, Modal, Tag } from 'antd';
import { clickDownload, formatDate } from '@digi-tim-19/utils/build';

import { useClient } from 'autogenerated/client/client';
import { SortFindManyReconheceTransactionInput } from 'autogenerated/client/types';
import { TablePagination } from 'components/TablePagination/TablePagination';

type ModalHistoryProps = {
  campaignId: string;
  onCancel: () => void;
};

export const ModalHistory = ({ campaignId, onCancel }: ModalHistoryProps) => {
  const [page, setPage] = useState(1);
  const perPage = 5;

  const transactions = useClient('ReconheceTransactionPagination');
  const downloadExtraCredit = useClient('DownloadExtraCredit');
  const downloadReversalPoints = useClient('DownloadReversalPoints');

  function handleFetch(ctx: any) {
    if (ctx.result && ctx.result.url) {
      onCancel();
      return clickDownload(ctx.result.url, true);
    }
    return console.error(ctx.errors);
  }

  function handleClick(transactionId: string, kind: string) {
    if (kind === 'reversal') {
      downloadReversalPoints
        .fetch({ variables: { campaignId, transactionId } })
        .then(handleFetch);
    }

    if (kind === 'creditExtra') {
      downloadExtraCredit
        .fetch({ variables: { campaignId, transactionId } })
        .then(handleFetch);
    }
  }

  useEffect(() => {
    !!campaignId &&
      transactions.fetch({
        variables: {
          page,
          perPage,
          filter: { campaignId },
          sort: SortFindManyReconheceTransactionInput.CreatedatDesc
        },
        fetchOnMount: true,
        appendToFragment: `
        items {
          _id
          createdAt
          value
          kind
          user { name }
        }
      `
      });
  }, [campaignId, page]);

  if (!campaignId) return null;

  const columns = [
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    {
      title: 'Solicitante',
      dataIndex: 'owner',
      key: 'owner'
    },
    {
      title: 'Tipo',
      dataIndex: 'kind',
      key: 'kind',
      render: (kind: string) => {
        if (kind === 'reversal') {
          return <Tag color="red">Estorno</Tag>;
        }

        return <Tag color="green">Upload extra</Tag>;
      }
    },
    {
      title: 'Total de TIM Coins',
      dataIndex: 'points',
      key: 'points'
    },
    {
      title: 'Relatório',
      dataIndex: '_id',
      key: '_id',
      render: (_id: string, data: any) => (
        <Button type="link" onClick={() => handleClick(_id, data.kind)}>
          Download
        </Button>
      )
    }
  ];

  const dataSource: any = (transactions?.result?.items || []).map(
    (item: any) => ({
      _id: item._id,
      createdAt: formatDate(item.createdAt, 'DD/MM/YYYY HH:mm'),
      points: item.value,
      kind: item.kind,
      owner: item.user.name
    })
  );

  return (
    <Modal
      visible={true}
      closable={true}
      onCancel={onCancel}
      footer={[]}
      width="75%"
    >
      <TablePagination
        loading={transactions.loading}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: perPage,
          current: page,
          total: transactions.data?.count!,
          onChange: (e: any) => setPage(e)
        }}
      />
    </Modal>
  );
};
