import { EnumFileKind } from 'autogenerated/client/types';

export default (isMobile: boolean) => [
  {
    list: [
      {
        inline: true,
        list: [
          {
            name: 'title',
            label: 'Título da Campanha',
            type: 'text',
            required: true,
            extraProps: {
              readOnly: true
            }
          }
        ]
      },
      {
        inline: !isMobile,
        list: [
          {
            name: 'name',
            label: 'Nome',
            type: 'text',
            required: true,
            extraProps: {
              readOnly: true
            }
          },

          {
            name: 'channel',
            label: 'Canal',
            type: 'text',
            required: true,
            extraProps: {
              readOnly: true
            }
          },

          {
            name: 'region',
            label: 'Região',
            type: 'text',
            required: true,
            extraProps: {
              readOnly: true
            }
          },
          {
            name: 'role',
            label: 'Cargo',
            type: 'text',
            required: true,
            extraProps: {
              readOnly: true
            }
          }
        ]
      },
      {
        inline: true,
        list: [
          {
            name: 'subscription',
            label: 'Matrícula',
            type: 'text',
            required: true,
            extraProps: {
              readOnly: true
            }
          },
          {
            name: 'evidence',
            label: 'Evidência',
            type: 'upload',
            required: true,
            extraProps: {
              kind: EnumFileKind.AnyDoc,
              CTA: 'ENVIAR ARQUIVO'
            }
          }
        ]
      },
      {
        name: 'indicator',
        label: 'Indicador',
        type: 'textarea',
        required: true,
        extraProps: {
          rows: 3
        }
      }
    ]
  }
];
