import { Select } from 'antd';
import React from 'react';
import { FieldInterface, FieldSelectOptionInterface } from '.';
import useDebounce from '../../hooks/useDebounce';

const { Option } = Select;

export const MultiSelectField = ({
  dataKey,
  placeholder,
  value,
  defaultValue,
  options = [],
  debounce,
  regexSearch = false,
  onChangeSearch,
  onChangeInput
}: FieldInterface): React.ReactElement => {
  const [fieldValue, setFieldValue] = React.useState<any>(undefined);
  const [fieldValueDebounce, setFieldValueDebounce] = React.useState<any>(
    undefined
  );
  const debounceValue = useDebounce(fieldValueDebounce, 500);

  const handleFieldValue = (value: any) => {
    setFieldValue(value);
  };

  React.useEffect(() => {
    handleFieldValue(value);
  }, [value]);

  React.useEffect(() => {
    if (onChangeSearch) {
      onChangeSearch(dataKey, debounceValue, regexSearch);
    }
  }, [debounceValue]);

  const multiOptions = [
    { label: 'Marcar Todos', value: 'all' },
    { label: 'Desmarcar Todos', value: 'none' },
    ...options
  ];

  return (
    <Select
      mode="multiple"
      placeholder={placeholder || ''}
      defaultValue={defaultValue || []}
      value={fieldValue}
      maxTagCount={5}
      filterOption={(input: any, option: any) => {
        return (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      onChange={(value: any) => {
        let data = value;

        if (value.length <= 0 || value.includes('none')) {
          data = undefined;
        }

        if (value.includes('all')) {
          data = multiOptions
            .map((el) => el.value)
            .filter((el) => !['none', 'all'].includes(el));
        }

        if (debounce) {
          setFieldValueDebounce(data);
        } else {
          if (onChangeSearch) {
            onChangeSearch(dataKey, data, regexSearch);
          }
        }

        if (onChangeInput) {
          onChangeInput(dataKey, data);
        }
      }}
    >
      {multiOptions.map((option: FieldSelectOptionInterface, index: number) => {
        return (
          <Option
            key={`option-${option.label}-${option.value}-${index}`}
            value={option.value}
          >
            {option.label}
          </Option>
        );
      })}
    </Select>
  );
};
