import styled from 'styled-components';

export const SurveyWrapper = styled.div`
  width: 100%;
`;

export const FormContainer = styled.div`
  width: 100%;

  .FormHeader {
    background: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 30px !important;
    padding: 25px 20px;

    &_footer {
      align-items: center !important;
      justify-content: space-between;

      > div {
        flex-basis: auto !important;
        width: auto !important;
      }
    }
  }

  .FormQuestions {
    .FieldWrapper:last-child {
      justify-content: flex-start;
    }

    .question {
      align-items: center;
      margin: 0 20px;

      .FieldWrapper {
        margin-right: 0 !important;

        &:first-child {
          justify-content: flex-start;
          max-width: 100px;
          margin-bottom: 0;

          h2 {
            color: ${(props) => props.theme.blue};
            font-size: 16px;
            font-weight: bold;
            margin: 0;
          }

          @media (min-width: 1024px) {
            margin-bottom: 30px !important;
          }
        }
      }

      .FieldWrapper + .FieldWrapper {
        margin-left: 2%;
      }
    }
  }
`;

export const Divider = styled.hr`
  border: none;
  background: #ccc;
  height: 1px;
  width: 100%;
  margin: 0;
`;
