import React, { useState } from 'react';
import { Icon } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  SelectFilter,
  useCurrentUser
} from '@digi-tim-19/components';

import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { SortFindManyPinInput } from '../../autogenerated/client/types';
import {
  InnerPageData,
  ListMode,
  KindFavoritos
} from './InnerPageDataFavoritos';
import {
  StyledContent,
  ChooseVisualWrapper,
  CardsVisual,
  OrderWrapper
} from './styles';
import { useSelectsOrders } from './useFavoritos';

export const Favoritos = () => {
  const [kind, setKind] = React.useState<KindFavoritos>(null);
  const [sort, setSort] = React.useState<SortFindManyPinInput>(
    SortFindManyPinInput.IdDesc
  );
  const [visualMode, setVisualMode] = useState<ListMode>(ListMode.card);
  const userId = useCurrentUser().result?._id;

  const selectsOrders = useSelectsOrders(setKind, setSort);

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Meus Favoritos', link: routes.favoritos.mount() }
          ]}
        />
      }
    >
      <React.Fragment>
        <TitleDescription
          iconType={'star'}
          title={'Meus Favoritos'}
          description={'Encontre seus favoritos.'}
        />
        <StyledContent>
          <OrderWrapper>
            <SelectFilter selects={selectsOrders} />
            <ChooseVisualWrapper>
              <Icon
                onClick={() => setVisualMode(ListMode.card)}
                type="appstore"
                style={{ fontSize: '35px' }}
              />
              <Icon
                onClick={() => setVisualMode(ListMode.list)}
                type="unordered-list"
                style={{ fontSize: '35px' }}
              />
            </ChooseVisualWrapper>
          </OrderWrapper>

          <CardsVisual>
            {userId && (
              <InnerPageData
                mode={visualMode}
                userId={userId}
                sort={sort}
                kind={kind}
              />
            )}
          </CardsVisual>
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};
