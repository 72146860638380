import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { DatePicker } from 'antd';
import { Breadcrumb, TitleDescription } from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { FilterContainer, CleanButton } from '../../../components/PageFilter';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumIncentiveCampaignStatus,
  SortFindManyIncentiveCampaignInput,
  ValidityEnum
} from '../../../autogenerated/client/types';

import { ModalHistory } from '../ModalHistory';
import { columns } from './columns';
import { TablePagination } from 'components/TablePagination/TablePagination';

const { RangePicker } = DatePicker;

export const CampanhaEstornoUploadExtraListar = () => {
  const history = useHistory();
  const perPage = 20;

  const [page, setPage] = useState(1);
  const [filterValidity, setFilterValidity] = useState<any>();
  const [rangeValue, setRangeValue] = useState<any>();
  const [campaignId, setCampaignId] = useState<string | undefined>(undefined);

  const IncentiveCampaign = useClient('IncentiveCampaignPagination');

  useEffect(() => {
    IncentiveCampaign.fetch({
      fetchOnMount: true,
      variables: {
        sort: SortFindManyIncentiveCampaignInput.CreatedatDesc,
        filter: {
          validityEnum: ValidityEnum.Any,
          status: EnumIncentiveCampaignStatus.Closed,
          createdAtRange: filterValidity
        },
        perPage,
        page
      },
      appendToFragment: `
        items {
          _id
          title
          status {label}
          createdAt
        }`
    });
  }, [filterValidity, page]);

  const dataSource = IncentiveCampaign.result?.items?.map((campaign) => {
    return {
      title: campaign?.title,
      status: campaign?.status?.label,
      createdAt: formatDate(campaign?.createdAt, 'DD/MM/YYYY'),
      hideOnRead: true,
      onEditFile: () => setCampaignId(campaign?._id),
      onEdit: () =>
        history.push(
          routes.campanhasIncentivoEstornoUploadExtra.mount(campaign?._id)
        )
    };
  });

  const resetFilters = () => {
    setPage(1);
    setRangeValue(undefined);
    setFilterValidity(undefined);
  };

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            {
              title: 'Incentivo',
              link: routes.campanhasIncentivoListar.mount()
            },
            { title: 'Estorno/Upload de TIM Coins extras' }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType=""
          title="Estorno/Upload de TIM Coins extras"
          description=""
        />

        <FilterContainer>
          <RangePicker
            format={'DD/MM/YYYY'}
            placeholder={['FILTRAR POR', 'VIGÊNCIA']}
            allowClear={false}
            onChange={(e) => {
              setRangeValue(e);
              setFilterValidity({
                start: moment(e[0]!)?.toISOString(),
                end: moment(e[1]!)?.toISOString()
              });
            }}
            value={rangeValue}
          />
          <CleanButton onClick={resetFilters}>Limpar filtros</CleanButton>
        </FilterContainer>

        <TablePagination
          loadingTable={IncentiveCampaign.loading}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            pageSize: perPage,
            current: page,
            total: IncentiveCampaign.result?.count!,
            onChange: (e: any) => setPage(e)
          }}
        />

        {!!campaignId && (
          <ModalHistory
            campaignId={campaignId}
            onCancel={() => setCampaignId(undefined)}
          />
        )}
      </>
    </PageTemplate>
  );
};
