import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Empty, Pagination, Icon } from 'antd';
import {
  TitleDescription,
  CardInterno,
  LoadingIndicator,
  ListInterno
} from '@digi-tim-19/components';

import { getAccessButton } from '../../utils/event';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { InputFilters } from '../../components/InputFilters/InputFilters';
import { Material } from 'autogenerated/client/types';
import { routes } from '../../config/routes';

import * as S from './styles';
import { formatDate } from '@digi-tim-19/utils/build';
import { usePaginatedEvents } from './hooks/usePaginatedEvents';

export const Eventos = () => {
  const { events, loading, pagination, filterUpdate } = usePaginatedEvents({});

  return (
    <PageTemplate>
      <>
        <TitleDescription
          title={'Eventos'}
          iconType={'usergroup-add'}
          description={''}
        />

        <InputFilters
          config={[
            {
              key: 'status',
              type: 'select',
              placeHolder: 'STATUS',
              options: [
                { label: 'PROGRAMADO', value: 'programming' },
                { label: 'EM ANDAMENTO', value: 'in_progress' },
                { label: 'ENCERRADO', value: 'closed' }
              ]
            },
            {
              key: 'validity',
              type: 'dateRange',
              placeHolder: ['INÍCIO', 'FINAL']
            }
          ]}
          onChange={filterUpdate}
        />

        {loading && <LoadingIndicator />}

        {!Boolean(events.length) && <Empty />}

        {Boolean(events.length) && (
          <EventCardView events={events} pagination={pagination} />
        )}
      </>
    </PageTemplate>
  );
};

interface IEventCardView {
  events: any;
  pagination: any;
}

const EventCardView = (props: IEventCardView) => {
  const history = useHistory();
  const [visualMode, setVisualMode] = useState<'card' | 'list'>('card');

  const toogleVisual = () =>
    setVisualMode((state) => (state === 'card' ? 'list' : 'card'));

  const action = (event: Material) => ({
    share: true,
    accessButton: getAccessButton(event, history, routes),
    pin: true
  });

  const defaultImage = '/defaultImages/default-card-thumb.png';

  return (
    <>
      <S.ChooseVisualWrapper>
        <Icon
          type="appstore"
          onClick={toogleVisual}
          style={{ fontSize: '35px' }}
        />
        <Icon
          type="unordered-list"
          onClick={toogleVisual}
          style={{ fontSize: '35px' }}
        />
      </S.ChooseVisualWrapper>

      {visualMode === 'card' ? (
        <CardInterno
          items={props.events}
          defaultImage={defaultImage}
          actions={action}
          extraInfo={(index) => {
            const event = props.events[index];
            const validity = `${formatDate(
              event?.validity?.start,
              'DD/MM/YY'
            )} até ${formatDate(event?.validity?.end, 'DD/MM/YY')}`;
            return (
              <>
                <p style={{ padding: '0 20px' }}>{event?.status.label}</p>
                <p style={{ padding: '0 20px' }}>{validity}</p>
              </>
            );
          }}
        />
      ) : (
        props.events.map((item: any) => {
          return (
            <ListInterno
              key={item?._id}
              material={item}
              defaultImage={defaultImage}
              actionsMaterial={action}
            />
          );
        })
      )}

      <S.PaginationWrapper>
        <Pagination {...props.pagination} />
      </S.PaginationWrapper>
    </>
  );
};
