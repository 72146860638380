import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Input, Modal } from 'antd';
import { CorpUpload } from '@digi-tim-19/components';
import moment from 'moment';
import { ImageFolderView } from 'components/ImageFolderView/ImageFolderView';
import { useInMemoPagination } from 'hooks/useInMemoPagination';
import { TablePagination } from 'components/TablePagination/TablePagination';

type GalleryFolder = {
  _id: string;
  title: string;
  imageIds: string[];
  user: string;
  date: string;
};

interface IEventGalleries {
  eventId: string;
  user: string;
  initial?: GalleryFolder[];
  onChange: (data: GalleryFolder[]) => void;
}

export const EventGalleries = (props: IEventGalleries) => {
  const [data, setData] = useState<GalleryFolder[]>(() => props.initial || []);
  const [current, setCurrent] = useState<Partial<GalleryFolder>>();
  const [view, setView] = useState<GalleryFolder>();
  const { dataToRender, pagination } = useInMemoPagination({
    data: data || [],
    perPage: 10
  });

  useEffect(() => props.onChange(data), [data]);

  const openModal = (gallery?: GalleryFolder) => {
    if (gallery) {
      setCurrent(gallery);
    } else {
      setCurrent({ imageIds: [] });
    }
  };

  const closeModal = () => {
    setCurrent(undefined);
  };

  const closeView = () => {
    setView(undefined);
  };

  const handlleAdd = (data: GalleryFolder) => {
    setData((state) => state.concat(data));
  };

  const handlleEdit = (data: GalleryFolder) => {
    setData((state) => state.map((el) => (el._id === data._id ? data : el)));
  };

  const handlleDelete = (data: GalleryFolder) => {
    setData((state) => state.filter((el) => el._id !== data._id));
  };

  return (
    <GalleryContainer>
      <Button
        type="primary"
        style={{ width: '200px' }}
        onClick={() => openModal()}
      >
        Nova Pasta
      </Button>

      {Boolean(current) && (
        <GalleryModal
          initial={current}
          visible={Boolean(current)}
          onClickClose={closeModal}
          onClickOk={(data) => {
            if (Boolean(data._id)) {
              handlleEdit(data as GalleryFolder);
            } else {
              handlleAdd({
                ...data,
                _id: `gallery_${props.eventId}_${Date.now()}`,
                user: props.user,
                date: moment().toISOString()
              } as GalleryFolder);
            }
            closeModal();
          }}
        />
      )}

      {Boolean(data.length) && (
        <>
          <TablePagination
            dataSource={dataToRender}
            pagination={pagination}
            columns={[
              {
                title: 'Título',
                dataIndex: 'title',
                key: 'title'
              },
              {
                title: 'Solicitado Por',
                dataIndex: 'user',
                key: 'user'
              },
              {
                title: 'Data de criação',
                dataIndex: 'date',
                key: 'date',
                render: (date: any) => moment(date).format('DD/MM/YYYY')
              },
              {
                title: '',
                dataIndex: 'title',
                key: 'actions',
                render: (_: any, record: any) => (
                  <ActionContainer>
                    <Button icon="eye" onClick={() => setView(record)} />
                    <Button icon="edit" onClick={() => openModal(record)} />
                    <Button
                      icon="delete"
                      onClick={() => handlleDelete(record)}
                    />
                  </ActionContainer>
                )
              }
            ]}
          />
        </>
      )}

      {view && (
        <Modal
          width={'80%'}
          title={view.title}
          visible={Boolean(view)}
          cancelButtonProps={{ hidden: true }}
          onCancel={closeView}
          onOk={closeView}
        >
          <ImageFolderView fileIds={view.imageIds || []} />
        </Modal>
      )}
    </GalleryContainer>
  );
};

interface IGalleryModal {
  initial?: Partial<GalleryFolder>;
  visible?: boolean;
  onClickOk: (data: Partial<GalleryFolder>) => void;
  onClickClose: () => void;
}

const GalleryModal = (props: IGalleryModal) => {
  const [data, setData] = useState<Partial<GalleryFolder>>(
    () => props.initial || {}
  );
  const isEnabled = Boolean(data.title) && Boolean(data.imageIds?.length);

  return (
    <Modal
      width={'60%'}
      title={'Upload'}
      visible={props.visible}
      onCancel={props.onClickClose}
      okButtonProps={{ disabled: !isEnabled }}
      onOk={() => {
        if (isEnabled) {
          props.onClickOk(data as GalleryFolder);
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          gap: '10px'
        }}
      >
        <CorpUpload
          initialValues={data.imageIds}
          onChange={(files) =>
            setData((state) => ({ ...state, imageIds: files ?? [] }))
          }
        >
          Upload
        </CorpUpload>
        <Input
          placeholder="Título da pasta"
          defaultValue={data.title}
          onChange={({ target }) =>
            setData((state) => ({ ...state, title: target.value }))
          }
        />
      </div>
    </Modal>
  );
};

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100px;
  height: 50px;
  align-items: center;

  .ant-btn {
    border: none;
    background-color: transparent;
    font-size: 24px;
  }
`;
