import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Icon } from 'antd';
import {
  Breadcrumb,
  CardInternoFragment,
  TitleDescription,
  SelectFilter,
  CardRecentActivity,
  ListInterno
} from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { getAccessButton } from '../../utils/material';
import { useClient } from '../../autogenerated/client/client';
import { MenuCategoriesOptions } from '../../config/menuCategories';
import { routes } from '../../config/routes';
import { SortFindManyRecentsActivitiesInput } from '../../autogenerated/client/types';
import * as S from './StylesAtividadesRecentes';
import { fragmentRecentActivities } from './fragmentAtividadesRecentes';
import { EmptyContainer } from 'components/TablePagination/EmptyContainer';
import { LoadingContainer } from 'components/LoadingContainer/LoadingContainer';

enum ListMode {
  card,
  list
}

function sortDate(array: any[], sort: SortFindManyRecentsActivitiesInput) {
  const sortIndice =
    sort === SortFindManyRecentsActivitiesInput.UpdatedatAsc ? -1 : 1;

  return array?.sort((a, b) =>
    a.createdAt < b.createdAt ? sortIndice : a.createdAt > b.createdAt ? 1 : 0
  );
}

export const AtividadesRecentes = () => {
  const [visualMode, setVisualMode] = useState<ListMode>(ListMode.card);
  const [category, setCategory] = React.useState<any>();
  const [sort, setSort] = React.useState(
    SortFindManyRecentsActivitiesInput.UpdatedatDesc
  );

  const [recentsActivities, setRecentsActivities] = useState<any[]>([]);

  const RecentsActivitiesPagination = useClient('RecentsActivitiesPagination');
  const getMaterials = useClient('MaterialFindMany', {
    appendToFragment: CardInternoFragment
  });
  const isLoading = getMaterials.loading || RecentsActivitiesPagination.loading;

  useEffect(() => {
    setRecentsActivities([]);
    RecentsActivitiesPagination.fetch({
      appendToFragment: fragmentRecentActivities(category),
      variables: {
        page: 1,
        perPage: 10,
        sort: SortFindManyRecentsActivitiesInput.UpdatedatDesc
      }
    }).then((ctx) => {
      if (ctx.result?.items) {
        setRecentsActivities(
          sortDate(
            ctx.result.items.filter((el: any) => el.campaign || el.material),
            sort
          ) || []
        );
      }
    });
  }, [category, sort]);

  const selectsOrders = useMemo(() => {
    return [
      {
        defaultValue: 'Ordenar Por',
        action: (value: SortFindManyRecentsActivitiesInput) => {
          setSort(value);
        },
        options: [
          {
            name: 'Mais antigo',
            value: SortFindManyRecentsActivitiesInput.UpdatedatAsc
          },
          {
            name: 'Mais recente',
            value: SortFindManyRecentsActivitiesInput.UpdatedatDesc
          }
        ]
      },
      {
        defaultValue: 'Categoria do menu',
        action: (value: string) => {
          setCategory(value);
        },
        options: MenuCategoriesOptions
      }
    ];
  }, []);

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            {
              title: 'Atividades Recentes',
              link: routes.atividadesRecentes.mount()
            }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType={'clock-circle'}
          title={'Atividades Recentes'}
          description={
            'Encontre aqui as suas últimas atividades realizadas dentro da plataforma.'
          }
        />

        <S.StyledContent>
          <S.OrderWrapper>
            <SelectFilter selects={selectsOrders} />
            <S.ChooseVisualWrapper>
              <Icon
                onClick={() => setVisualMode(ListMode.card)}
                type="appstore"
                style={{
                  fontSize: '35px',
                  color:
                    visualMode === ListMode.card ? colors.blue : colors.gray
                }}
              />
              <Icon
                onClick={() => setVisualMode(ListMode.list)}
                type="unordered-list"
                style={{
                  fontSize: '35px',
                  color:
                    visualMode === ListMode.list ? colors.blue : colors.gray
                }}
              />
            </S.ChooseVisualWrapper>
          </S.OrderWrapper>

          {isLoading && <LoadingContainer />}
          {!isLoading && !recentsActivities?.length && (
            <EmptyContainer message="Não há atividades recentes para exibir" />
          )}

          <S.CardsVisual>
            <InnerPageData mode={visualMode} items={recentsActivities} />
          </S.CardsVisual>
        </S.StyledContent>
      </>
    </PageTemplate>
  );
};

const InnerPageData = (props: { mode: ListMode; items: any }) => {
  const history = useHistory();

  if (props.mode === ListMode.card) {
    return props.items.map((item: any) => {
      if (!item.material && !item.campaign) {
        return null;
      }

      return (
        <CardRecentActivity
          key={item._id}
          material={item?.material}
          campaign={item?.campaign}
          defaultImage={'/defaultImages/default-card-thumb.png'}
          actionsMaterial={(material) => {
            return {
              pin: true,
              share: true,
              accessButton: getAccessButton(material, history, routes)
            };
          }}
          actionsCampaign={{
            accessButton: {
              label: 'Acessar',
              action: (campaign) => {
                history.push(routes.campanhasIncentivo.mount(campaign?._id));
              },
              extraProps: {
                disabled: !item?.campaign || !item?.campaign?._id
              }
            },
            share: true,
            pin: true
          }}
        />
      );
    });
  } else {
    return props.items.map((item: any) => (
      <ListInterno
        key={item._id}
        material={item.material}
        campaign={item.campaign}
        defaultImage={'/defaultImages/default-card-thumb.png'}
        actionsMaterial={(material) => {
          return {
            pin: true,
            share: true,
            accessButton: getAccessButton(material, history, routes)
          };
        }}
        actionsCampaign={{
          accessButton: {
            label: 'Acessar',
            action: (campaign) => {
              history.push(routes.campanhasIncentivo.mount(campaign?._id));
            }
          },
          share: true,
          pin: true
        }}
      />
    ));
  }
};
