import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  Select,
  useCurrentUser
} from '@digi-tim-19/components';
import { routes } from 'config/routes';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { FilterContainer, CleanButton } from 'components/PageFilter';
import { useClient } from 'autogenerated/client/client';
import {
  SortFindManyCampaignContestationInput,
  ContestationStatusFilterEnum,
  EnumCampaignContestationResponseStatus,
  User
} from 'autogenerated/client/types';
import { Wrapper, Search } from './styles';
import { columns } from './columns';
import { TablePagination } from 'components/TablePagination/TablePagination';

const configPage = {
  pageName: 'Minhas Contestações',
  icon: 'audit',
  description: 'Consultar as minhas contestações',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Para você', link: routes.home.mount() },
    { title: 'Campanhas', link: routes.campanhasIncentivoVigentes.mount() },
    { title: 'Minhas Contestações' }
  ]
};

const appendToFragment = `
  pageInfo {
    itemCount
  }
  items {
    protocol
    _id
    indicator
    owner
    parentId
    isResultContest
    campaign {
      title
    }
    _id
    user {
      name
      channel
      region
      roleGroup
      matricula
    }
    response {
      status
    }
  }
`;

export const MyContestations = () => {
  const history = useHistory();
  const user = useCurrentUser().result as User;
  const CampaignContestationPagination = useClient(
    'CampaignContestationPagination'
  );
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<number>();
  const [status, setStatus] = useState<
    ContestationStatusFilterEnum | undefined
  >();

  const statusOption = [
    {
      label: 'Procedente',
      value: ContestationStatusFilterEnum.Founded
    },
    {
      label: 'Improcedente',
      value: ContestationStatusFilterEnum.Unfounded
    },
    {
      label: 'Em Contestação',
      value: ContestationStatusFilterEnum.Empty
    }
  ];

  useEffect(() => {
    CampaignContestationPagination.fetch({
      appendToFragment,
      variables: {
        filter: {
          status,
          protocolSearch: search ? String(search) : undefined,
          user: {
            _id: user._id
          }
        },
        sort: SortFindManyCampaignContestationInput.IdDesc,
        perPage: 10,
        page
      }
    });
  }, [page, status, search]);

  const contestations = CampaignContestationPagination?.result?.items;
  const total = CampaignContestationPagination?.result?.pageInfo.itemCount;

  const getTranslation = (
    word: EnumCampaignContestationResponseStatus | null | undefined
  ) => {
    switch (word) {
      case 'unfounded':
        return 'Improcedente';
      case 'founded':
        return 'Procedente';
      default:
        return 'Em contestação';
    }
  };

  const data = contestations?.map((contestation) => {
    return {
      key: contestation?._id,
      title: contestation?.campaign?.title,
      tableStatus: contestation?.isResultContest ? 'Resultado' : 'Prévia',
      status: getTranslation(contestation?.response?.status),
      owner: contestation?.user?.name,
      region:
        contestation?.user?.region === 'THQ'
          ? 'BR'
          : contestation?.user?.region,
      channel: contestation?.user?.channel,
      role: contestation?.user?.roleGroup,
      protocol: contestation?.protocol,
      onRead: () =>
        history.push(routes.minhaContestacaoVisualizar.mount(contestation?._id))
    };
  });

  const resetFilters = () => {
    setSearch(undefined);
    setStatus(undefined);
    setPage(1);
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <Wrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <FilterContainer>
          <Search
            placeholder="Buscar pelo protocolo"
            value={search}
            onChange={(e) => {
              setPage(1);
              setSearch(
                Boolean(Number(e.target.value))
                  ? Number(e.target.value)
                  : undefined
              );
            }}
          />
          <Select
            placeholder="status"
            options={statusOption}
            value={status}
            onChange={(e) => {
              setPage(1);
              setStatus(e);
            }}
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        <TablePagination
          loading={CampaignContestationPagination?.loading}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: 10,
            current: page,
            total: total!,
            onChange: (e: any) => setPage(e)
          }}
        />
      </Wrapper>
    </PageTemplate>
  );
};
