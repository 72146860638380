import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  VideoPlayer,
  MaterialSample,
  CardPlaylistVideo,
  useAnalytics
} from '@digi-tim-19/components';
import { EnumAnalyticsParentKind } from '@digi-tim-19/components/build/main/DataProviders/types';
import { orderBy } from 'lodash';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';

const OnTubeViewerWrapper = styled.div`
  max-width: 1920px;
`;

const OnTubePlaylistWrapper = styled.div`
  display: grid;
  row-gap: 2em;
  grid-template-columns: repeat(1, 1fr);
  margin: 2em 0;
  position: relative;

  &:after {
    content: '';
    height: 2px;
    background: #ccc;
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
  }

  & > * {
    padding-right: 1.5em;
    padding-left: 1.5em;
    border-bottom: 2px solid #ccc;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const OnTubePlayer = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const analytics = useAnalytics();
  const trackedAnalytics = useRef(false);

  const videoSearch = useClient('MaterialFindOne', {
    appendToFragment
  });
  const user = useClient('User', { fetchOnMount: true });
  const userId = user.result?._id;

  useEffect(() => {
    if (!id) return;

    videoSearch.fetch({ variables: { filter: { _id: id! } } });
  }, [id]);

  const orderItems = (material: any, configs: { _id: string, field: string, order: "asc" | "desc" }[]) => {
    const data = material?.sameCategoryItems?.items || [];

    if(material?.categories?.length) {
      if(material?.categories[0]?._id) {
        const config = configs.find(el => el._id === material.categories[0]._id);
        if(config) {
          return orderBy(data, config.field, config.order)
        }
      }
    }

    return data;
  }

  const loading = user.loading || videoSearch.loading;
  const result = videoSearch.result;
  const sameCategoryItems = orderItems(result, [{ _id: "video_playlist:integra", field: "numericId", order: "asc" }]) as MaterialSample[];

  function trackVideo() {
    if (!result?._id) return;
    if (trackedAnalytics.current === result._id) return;
    trackedAnalytics.current = result._id;

    analytics.track({
      event: 'view',
      kind: EnumAnalyticsParentKind.Video,
      recordId: result._id,
      entityName: 'Material',
      singleDoc: true
    });
  }

  if (!loading && !userId) return <h1>401</h1>;

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Vídeos', link: routes.videos.mount() },
            { title: 'Video', link: '#' }
          ]}
        />
      }
    >
      <OnTubeViewerWrapper>
        <TitleDescription
          iconType="play-square"
          title="Vídeos"
          description={
            'Seu canal de vídeos com todas as informações que você precisa. \n Encontre o que deseja com filtros de busca, comente, curta, compartilhe e muito mais.'
          }
        />

        {loading && <LoadingIndicator />}
        {!loading && !result && <p>Material não encontrado</p>}

        {!loading && !!result && (
          <>
            <VideoPlayer
              material={result as MaterialSample}
              videoRef={function (ref) {
                ref.onplay = function () {
                  trackVideo();
                };
              }}
              videosPlaylist={sameCategoryItems}
              onClickVideo={(_id) =>
                history.push(routes.videosPlayer.mount(_id))
              }
            />

            <OnTubePlaylistWrapper>
              {sameCategoryItems.map((video) => (
                <CardPlaylistVideo
                  material={video}
                  platform="corp"
                  onClickVideo={(_id) =>
                    history.push(routes.videosPlayer.mount(_id))
                  }
                />
              ))}
            </OnTubePlaylistWrapper>
          </>
        )}
      </OnTubeViewerWrapper>
    </PageTemplate>
  );
};

const appendToFragment = `
  _id
  title
  description
  publishedAt

  categories {
    parentId
    name
    _id
  }

  likes {
    liked
    count
  }

  views {
    count
  }

  files {
    title
    kind
    signedUrl
  }

  sameCategoryItems(sort: CREATEDAT_DESC) {
    items {
      _id
      numericId
      createdAt
      title
      description

      likes {
        liked
        count
      }

      views {
        count
      }

      files {
        _id
        kind
        signedUrl
      }

      categories {
        name
      }
    }
  }
`;
