import React from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  LoadingIndicator,
  ProviderSelecionarPublico,
  TitleDescription,
  useClient
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { FormRemuneracoes } from '../FormRemuneracoes';
import { useRouteMatch } from 'react-router';

const DocumentsWrapper = styled.div`
  width: 100%;
`;

export const RemuneracoesEditar = () => {
  const { params }: any = useRouteMatch();
  const remuneration = useClient('RemunerationFindById', {
    variables: { _id: params?.id! },
    fragment: `
      availableAtRegionalProfiles
      availableAtRegions
      availableAtChannels
      availableAtRoleGroups
      availableForUsers
      title
      videoLink
      enableSocialInteraction
      cardImage
      bannerContent
      regulation
      presentation
      file
      faq
      validity {
        start
        end
      }
      remunerationValidity {
        start
        end
      }
      communicationItemsConfig {
        kind
        templateName
        templateProps {
          html
          TITLE_1
          TITLE_2
          TITLE_3
          TEXT_1
          TEXT_2
          TEXT_3
          TEXT_4
          BUTTON_TITLE
          BUTTON_LINK
          IMAGE_SRC_1
          IMAGE_SRC_2
          HIDE_BUTTON
        }
      }
      mailingFile {
        _id
        signedUrl
      }
    `,
    fetchOnMount: true
  });

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            {
              title: 'Incentivo',
              link: routes.remuneracaoVariavelMetasListar.mount()
            },
            {
              title: 'Remuneração variável e metas',
              link: routes.remuneracaoVariavelMetasListar.mount()
            },
            { title: 'Editar' }
          ]}
        />
      }
    >
      <DocumentsWrapper>
        <TitleDescription
          iconType="inbox"
          title="Remuneração variável e metas"
          description={'Edição de remuneração variável e metas'}
        />

        <ProviderSelecionarPublico>
          {remuneration?.result ? (
            <FormRemuneracoes values={remuneration.result} />
          ) : (
            <LoadingIndicator />
          )}
        </ProviderSelecionarPublico>
      </DocumentsWrapper>
    </PageTemplate>
  );
};
