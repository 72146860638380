import { Input } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const { Search: AntdSearch } = Input;

export const OnTubeListWrapper = styled.div`
  width: 100%;
`;

export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

export const Button = styled(Link)`
  background: ${(props) => props.theme.red};
  display: flex;
  justify-content: center;

  color: ${(props) => props.theme.white};
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-family: TimBold;
  width: 100%;
  cursor: pointer;
  outline: none;
  margin-right: 15px;
  transition: all 0.1s ease-in;

  &:hover {
    background: ${(props) => props.theme.red0};
    color: ${(props) => props.theme.white};
    outline: none !important;
  }
`;
