import casal from './casal.png';
import logoTim from './tim_logo.png';
import logoCorporativo from './logo-corporativo2.png';
import logoCorporativoWhite from './logo-corporativo2-white.png';
import logoCoporativoOriginal from './logo-corporativo2-original.png';
import logoTimTemplateEmail from './logo-tim-template-email.png';
import bgTemplateEmail600x900 from './bg-template-email600x900.png';
import bgTemplateEmail640x300 from './bg-template-email640x300.jpg';
import bgTemplateEmail640x200 from './bg-template-email640x200.jpg';
import bgTemplateEmail640x250White from './bg-template-email640x250-white.jpg';
import bgTemplateEmail640x250 from './bg-template-email640x250.jpg';
import bgTemplateEmail1000x420 from './bg-template-email1000x420.jpg';
import tim_rodape from './img_tim_marca_rodape.png';

export const siteImages = {
  casal,
  logoTim,
  logoCorporativo,
  logoCorporativoWhite,
  logoCoporativoOriginal,
  tim_rodape,
  bgTemplateEmail600x900,
  bgTemplateEmail640x300,
  bgTemplateEmail640x200,
  bgTemplateEmail640x250White,
  bgTemplateEmail640x250,
  bgTemplateEmail1000x420,
  logoTimTemplateEmail
};
