import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { DatePicker, message } from 'antd';
import { useHistory } from 'react-router';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { Breadcrumb, TitleDescription, Select } from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { FilterContainer, CleanButton } from '../../../components/PageFilter';
import { useMaterialCategoriesSelect } from '../../../hooks/useMaterialCategories';
import useDebounce from '../../../hooks/useDebounce';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumMaterialKind,
  SortFindManyMaterialInput,
  EnumMaterialStatus,
  ValidityEnum
} from '../../../autogenerated/client/types';

import { OnTubeListWrapper, Button, Search } from './stylesListar';
import { columns } from './columns';
import { TablePagination } from 'components/TablePagination/TablePagination';

export const OnTubeListar = () => {
  const { RangePicker } = DatePicker;

  const categoriesOptions: any = useMaterialCategoriesSelect('video_playlist');
  const history = useHistory();

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const getMaterials = useClient('MaterialPagination');

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  const [createdAt, setCreatedAt] = useState();
  const [publishedAt, setPublishedAt] = useState();
  const [filterValidityStart, setFilterValidityStart] = useState([]);
  const [filterValidityEnd, setFilterValidityEnd] = useState([]);
  const [filterSearch, setFilterSearch] = useState();
  const [filterStatus, setFilterStatus] = useState<EnumMaterialStatus>();
  const [filterDetached, setFilterDetached] = useState<any>();
  const [filterCategory, setFilterCategory] = useState();
  const [filterValidityEnum, setFilterValidityEnum] = useState(
    ValidityEnum.Any
  );

  const debouncedSearchTerm = useDebounce(filterSearch, 1000);

  useEffect(() => {
    getMaterials.fetch({
      appendToFragment,
      variables: {
        page: page,
        perPage: 10,
        filter: {
          kind: EnumMaterialKind.Video,
          detachedVideo: filterDetached,
          validityEnum: filterValidityEnum,
          inCategories: filterCategory,
          status: filterStatus,
          createdAtRange: createdAt,
          publishedAtRange: publishedAt,
          includeDrafts: true,
          search: debouncedSearchTerm
        },
        sort: sort
      }
    });
  }, [
    debouncedSearchTerm,
    filterCategory,
    filterStatus,
    filterValidityEnum,
    createdAt,
    publishedAt,
    page,
    sort,
    filterDetached
  ]); //eslint-disable-line

  const total = getMaterials?.result?.pageInfo.itemCount;
  const resultMaterial = getMaterials?.result?.items;

  const data = resultMaterial?.map((material) => {
    const playlist = material?.categories?.find((item: any) => item?.name)
      ?.name;

    const validity = Object.values(material?.validity || {})
      .map((date: any) => formatDate(date, 'DD/MM/YY'))
      .join(' até ');

    return {
      key: material?._id,
      id: material?.numericId,
      user: material?.author?.name || 'backup',
      title: material?.title,
      validity: validity || '-',
      status: material?.status?.label,
      playlist: playlist || '-',
      onEdit: () => history.push(routes.videosEditar.mount(material?._id)),
      onDelete: () => {
        const record = { status: EnumMaterialStatus.Cancelled };

        MaterialUpdateOne.fetch({
          variables: { record, filter: { _id: material?._id } },
          afterMutate: /^Material/
        }).then(() => {
          message.success('Vídeo cancelado com sucesso');
        });
      },
      onRead: () => history.push(routes.videosPlayer.mount(material?._id))
    };
  });

  const resetFilters = () => {
    setPage(1);
    setSort(SortFindManyMaterialInput.IdDesc);
    setFilterCategory(undefined);
    setFilterStatus(undefined);
    setFilterSearch(undefined);
    setCreatedAt(undefined);
    setPublishedAt(undefined);
    setFilterDetached(undefined);
    setFilterValidityEnd([]);
    setFilterValidityStart([]);
  };

  const onSearch = useCallback((e) => setFilterSearch(e), [data]);

  const handleFilter = (
    e: any,
    setState: React.Dispatch<React.SetStateAction<any>>
  ) => {
    setPage(1);
    setState(e);
  };

  const handleValidityFilter = (
    dates: RangePickerValue,
    setState: React.Dispatch<React.SetStateAction<any>>,
    setFilter: React.Dispatch<React.SetStateAction<any>>
  ) => {
    handleFilter(dates, setState);

    if (!dates.length) {
      return setFilter(undefined);
    }

    setFilter({
      start: moment(dates[0]).toISOString(),
      end: moment(dates[1]).toISOString()
    });
  };

  const statusValue = () => {
    if (!filterStatus && filterDetached) {
      return 'detached';
    }
    if (filterValidityEnum !== ValidityEnum.Any) {
      return filterValidityEnum;
    }
    return filterStatus;
  };

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo', link: routes.videosListar.mount() },
            { title: 'Vídeos', link: routes.videosListar.mount() },
            { title: 'Listar', link: routes.videosListar.mount() }
          ]}
        />
      }
    >
      <OnTubeListWrapper>
        <TitleDescription
          iconType="play-square"
          title="Vídeos"
          description="Listar e consultar vídeos cadastrados"
        />

        <FilterContainer>
          <Button to={routes.videosCadastrar.mount()}>Cadastrar vídeo</Button>
          <Select
            placeholder="Ordenar por:"
            options={orderOptions}
            value={sort}
            onChange={(e) => handleFilter(e, setSort)}
          />
          <Select
            placeholder="status"
            options={statusOptions}
            value={statusValue()}
            onChange={(e) => {
              setPage(1);
              switch (e) {
                case ValidityEnum.Current:
                  setFilterValidityEnum(e);
                  setFilterStatus(EnumMaterialStatus.Approved);
                  setFilterDetached(undefined);
                  break;
                case ValidityEnum.Expired:
                  setFilterValidityEnum(e);
                  setFilterStatus(undefined);
                  setFilterDetached(undefined);
                  break;
                case 'detached':
                  setFilterValidityEnum(ValidityEnum.Any);
                  setFilterStatus(undefined);
                  setFilterDetached(true);
                  break;
                default:
                  setFilterValidityEnum(ValidityEnum.Any);
                  setFilterStatus(e);
                  setFilterDetached(undefined);
              }
            }}
          />
          <Select
            placeholder="Playlist"
            options={categoriesOptions}
            value={filterCategory}
            onChange={(e) => handleFilter(e, setFilterCategory)}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format="DD/MM/YYYY"
            placeholder={['INÍCIO DE ', 'VIGÊNCIA']}
            value={filterValidityStart}
            onChange={(dates) =>
              handleValidityFilter(dates, setFilterValidityStart, setCreatedAt)
            }
          />
          <RangePicker
            format="DD/MM/YYYY"
            placeholder={['FIM DE', 'VIGÊNCIA']}
            value={filterValidityEnd}
            onChange={(dates) =>
              handleValidityFilter(dates, setFilterValidityEnd, setPublishedAt)
            }
          />
          <Search
            placeholder="Buscar título, solicitante"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => onSearch(e.target.value)}
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        <TablePagination
          loading={getMaterials.loading}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: 10,
            current: page,
            total: total!,
            onChange: (e: any) => setPage(e)
          }}
          emptyMessage={'Não há links para exibir'}
        />
      </OnTubeListWrapper>
    </PageTemplate>
  );
};

const orderOptions = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];

const statusOptions = [
  { label: 'VIDEO EM DESTAQUE', value: 'detached' },
  { label: 'EM EDIÇÃO', value: EnumMaterialStatus.Editing },
  { label: 'PUBLICADO', value: ValidityEnum.Current },
  { label: 'CANCELADO', value: EnumMaterialStatus.Cancelled },
  { label: 'EXPIRADO', value: ValidityEnum.Expired }
];

const appendToFragment = `#graphql
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }

  items {
    _id
    numericId
    title
    validityEnum
    createdAt
    validity {
      start
      end
    }
    categories {
      _id
      name
      parentId
      parentCategory {
        name
        _id
      }
    }
    author {
      name
    }
    status {
      label
    }
  }
`;
