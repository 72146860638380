import { colors } from '@digi-tim-19/theme';
import styled from 'styled-components';

export const RedButton = styled.button`
  width: 100%;
  white-space: nowrap;
  display: flex;
  height: 38px;
  padding: 10px 14px;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  font-style: normal;
  text-align: center;
  line-height: normal;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  background: ${colors.red};
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: TimBold;

  &:hover {
    background-color: #d90025;
    color: #fff;
  }

  @media (max-width: 768px) {
    width: 135%;
    height: 35px;
    font-weight: 600;
    padding: 0;
    font-size: 16px;
    padding: 7px;
    font-family: TimBold;
    background-color: ${colors.red};
  }
`;

export const IconCoins = styled.img`
  width: 24px;
  height: 24px;
`;
