import {
  Category
} from 'autogenerated/client/types';
import React from 'react';
import {
  Former,
  useCurrentUser
} from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { routes } from 'config/routes';
import { message } from 'antd';
import { useClient } from 'autogenerated/client/client';
import { FormContainer } from 'components/Former';
import styled from 'styled-components';

interface Props {
  values?: Partial<Category>;
}

const configPage = {
  routeList: routes.videosPlaylistListar.mount(),
  messageSuccess: 'Playlist salva com sucesso.',
  messageError: 'Falha ao salvar playlist.'
};

const FormWrapper = styled(FormContainer as any)``;

export const FormPlaylist = ({ values }: Props) => {
  const user = useCurrentUser().result;
  const isUpdate = !!values;
  const [data, setData] = React.useState<any>(values);
  const history = useHistory();
  const categoryCreateOne = useClient('CategoryCreateOne');
  const categoryUpdateOne = useClient('CategoryUpdateOne');
  const isResidential = user?.roleGroup?._id?.includes("residencial") || user?.channels?.includes("residencial") || (user?.channels?.length && user?.channels[0]?.includes("residencial"));

  const initialValues: any = React.useMemo(() => {
    return { ...data };
  }, [data]);

  return (
    <FormWrapper>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              inline: true,
              name: 'name',
              label: 'Nome (até 40 caracteres)',
              required: true,
              validate: (value: any) => {
                const format = /[#%&;]/;
                if (format.test(value))
                  return 'Os caracteres #%&; não são permitidos';

                if (value) {
                  return !(value.length > 40)
                    ? undefined
                    : 'Máximo de 40 caracteres';
                }

                return undefined;
              }
            }
          ],
          submitButton: {
            label: isUpdate ? 'Atualizar' : 'Salvar',
          },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(configPage.routeList)
          }
        }}
        onSubmit={async ({ data: formData }: any) => {
          
          const record: any = {
            name: formData.name,
            parentId: "video_playlist",
            availableAtChannels: isResidential ? ["residencial"] : ["corporate"],
            availableAtRegions: [],
            availableAtRoles: []
          };

          if (!formData?.name) {
            setData({ ...record });
            return message.error('Adicione um nome para a playlist');
          }

          if (isUpdate && data?._id) {
            await categoryUpdateOne
              .fetch({
                variables: { record, filter: { _id: data._id } },
                afterMutate: /^Category/
              })
              .then((ctx) => {
                if (!ctx.errors) {
                  message.success(configPage.messageSuccess);
                  history.push(configPage.routeList);
                } else {
                  setData({ ...record });
                  message.error(configPage.messageError);
                }
              });
          } else {
            await categoryCreateOne
              .fetch({
                variables: {
                  record: { ...record }
                }
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success(configPage.messageSuccess);
                  history.push(configPage.routeList);
                } else {
                  setData({ ...record });
                  message.error(configPage.messageError);
                }
              });
          }
        }}
      />
    </FormWrapper>
  );
};
