import React from 'react';
import {
  Breadcrumb,
  TitleDescription,
  Select,
  useCurrentUser
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { FilterWrapper, Button } from './styleListar';
import { routes } from '../../../config/routes';
import { dateFormatList } from '../../../utils';
import moment from 'moment';
import { DatePicker, message } from 'antd';
import { useInvoiceOriginOptions } from '../../../hooks/incentiveCampaign/useInvoiceOriginOptions';

import { formatDate } from '@digi-tim-19/utils';
import { Link } from 'react-router-dom';
import { usePaginationClient } from './usePagination';
import { EnumReconheceWalletStatus } from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';
import { TablePagination } from 'components/TablePagination/TablePagination';

const { RangePicker } = DatePicker;

const configPage = {
  pageName: 'Ordens de pagamento',
  icon: 'dollar',
  description: 'Listagem de ordens de pagamento.',
  messageDeletedSuccess: 'Ordem removida com sucesso',
  textButtonNew: 'CADASTRAR NOVA',
  routeNew: routes.ordemPagamentoCadastrar.mount(),
  routeView: routes.ordemPagamentoEditar,
  routeEdit: routes.ordemPagamentoEditar,
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciamento', link: '' },
    { title: 'Ordens de pagamento', link: '' },
    { title: 'Listar', link: '' }
  ],
  limit: 10
};

export const OrdemPagamentoListar: React.FC<TListarFaturas> = () => {
  const user = useCurrentUser();
  const isFinanceiro = user.userIsInRole('financeiro');
  const columns = useColumns(isFinanceiro);

  const invoiceOrigins = useInvoiceOriginOptions();

  const client = usePaginationClient('ReconheceWalletPagination', {
    initial: {
      perPage: configPage.limit,
      page: 1
    },
    itemsFragment: `
      _id
      createdAt
      initialCredit
      usedCredit
      availableCredit
      status
      POIdentifier
      NFIdentifier
      paidAt
      # createdBy: String
      # POImages: [String]
      # NFImages: [String]
      # invoiceOrigin: String
      # NFImageUrls(...): [File]
      # POImageUrls(...): [File]
    `,
    parseResult(result) {
      return result.map((el) => {
        return {
          ...el,
          initialCredit: Math.trunc(el?.initialCredit || 0),
          availableCredit: Math.trunc(el?.availableCredit || 0),
          usedCredit: Math.trunc(el?.usedCredit || 0)
        };
      });
    }
  });

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo', link: routes.home.mount() },
            { title: 'Incentivo', link: '' },
            { title: 'Faturas', link: '' }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <FilterWrapper>
          {isFinanceiro && (
            <Button to={configPage.routeNew}>{configPage.textButtonNew}</Button>
          )}

          <Select
            placeholder="TIPO DE FATURA"
            options={invoiceOrigins}
            onChange={(value) => {
              client.updateFetch({ invoiceOrigin: value });
            }}
            value={client.state.invoiceOrigin || 'tim'}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={dateFormatList}
            placeholder={['PERÍODO', '']}
            onChange={(e: any) => {
              client.updateFetch({
                start: e[0]
                  ? moment(e[0]).startOf('day')?.toISOString()
                  : undefined,

                end: e[1] ? moment(e[1]).endOf('day')?.toISOString() : undefined
              });
            }}
          />
        </FilterWrapper>
        <TablePagination
          loadingTable={client.loading}
          columns={columns}
          dataSource={client.parsed}
          pagination={client.pagination}
        />
      </>
    </PageTemplate>
  );
};

export type TListarFaturas = {};

const useColumns = (isFinanceiro?: boolean) => {
  const PaymentOrderRemoveById = useClient('ReconheceWalletRemoveById');

  return [
    {
      title: 'NÚMERO DA P.O',
      dataIndex: 'POIdentifier',
      key: 'POIdentifier',
      render: (text: string) => <p>{text}</p>
    },
    {
      title: 'DATA DE EMISSÃO',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <p>{formatDate(text, 'DD/MM/YYYY')}</p>
    },
    {
      title: 'DATA DE PAGAMENTO',
      dataIndex: 'paidAt',
      key: 'paidAt',
      render: (text: string) => <p>{formatDate(text, 'DD/MM/YYYY')}</p>
    },
    {
      title: 'VALOR',
      dataIndex: 'initialCredit',
      key: 'initialCredit'
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => <p>{statusMap[text]}</p>
    },
    {
      title: 'SALDO',
      children: [
        {
          title: 'Usado',
          dataIndex: 'usedCredit',
          key: 'usedCredit'
        },
        {
          title: 'Disponível',
          dataIndex: 'availableCredit',
          key: 'availableCredit'
        }
      ]
    },
    isFinanceiro && {
      title: 'Editar',
      children: [
        {
          dataIndex: 'editar',
          key: 'editar',
          render: (text: string, doc: any) =>
            doc.status === EnumReconheceWalletStatus.Draft && (
              <Link to={configPage.routeEdit.mount(doc._id)}>
                <a>Editar</a>
              </Link>
            )
        },
        {
          dataIndex: 'delete',
          key: 'delete',
          render: (_: any, doc: any) =>
            doc.status === EnumReconheceWalletStatus.Draft && (
              <a
                onClick={(event) => {
                  event.preventDefault();
                  PaymentOrderRemoveById.fetch({
                    variables: { _id: doc._id },
                    afterMutate: /^ReconheceWallet/
                  }).then((ctx) => {
                    message.success(configPage.messageDeletedSuccess);
                  });
                }}
              >
                Apagar
              </a>
            )
        }
      ]
    }
  ].filter(Boolean) as any[];
};

const statusMap: any = {
  [EnumReconheceWalletStatus.Available]: 'Disponível',
  [EnumReconheceWalletStatus.Draft]: 'Não confirmado'
};
