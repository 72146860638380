import React from 'react';
import { Button, Select } from '@digi-tim-19/components';
import { Modal, message } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { downloadXLSX } from '../../utils/downloadXlsx';
import { useClient } from 'autogenerated/client/client';

const fragment = `
  name
  email
  cpf
  matricula
  roleGroup
  channel
  region
  tour
  disabled
  disabledWithPoints
  disabledAt
`;

export const ModalReportTourUser = () => {
  const [open, setOpen] = React.useState(false);
  const [dateView, setDateView] = React.useState<any>();
  const { loading, fetch } = useClient('ReportTourUser', { fragment });

  const handleGenerateYears = () => {
    let options: any = [];

    for (let year = 2024; year <= moment().year(); year++) {
      options.push({
        label: `Ano/${year}`,
        value: `${year}-01-01`
      });
    }

    return options;
  };

  const handleDownloadReport = async (dateView: string) => {
    const { result } = await fetch({
      variables: { date: dateView }
    });
    if (result && result?.length > 0) {
      const schema: any = {
        name: { label: 'Nome' },
        email: { label: 'E-mail' },
        cpf: { label: 'CPF' },
        matricula: { label: 'Matrícula' },
        roleGroup: { label: 'Perfil' },
        channel: { label: 'Canal' },
        region: { label: 'Regional' },
        tour: { label: 'Tour concluído em:' },
        disabled: { label: 'Usuário desabilitado?' },
        disabledWithPoints: { label: 'Usuário desabilitado com pontos?' },
        disabledAt: { label: 'Usuário desabilitado em:' }
      };

      downloadXLSX(
        `${moment().format('DD-MM-YYYY')}-relatorio-tour-usuarios`,
        schema,
        result
      );
      setOpen(false);
    } else {
      message.info('Não há registros para download da planilha.');
    }
  };

  return (
    <React.Fragment>
      <Button
        icon="file-excel"
        loading={loading}
        disabled={loading}
        onClick={() => setOpen(true)}
      >
        Extrair relatório tour
      </Button>
      <Modal
        title="Relatório tour"
        visible={open}
        okText="DOWNLOAD"
        cancelText="FECHAR"
        onOk={async () => await handleDownloadReport(dateView)}
        onCancel={() => {
          if (!loading) setOpen(false);
        }}
        okButtonProps={{
          disabled: loading || !dateView,
          loading: loading
        }}
      >
        <Message>
          Este relatório pode demorar alguns segundos para finalizar o
          processamento. Por favor, permanecer na tela até a conclusão do
          download.
        </Message>
        <Select
          style={{ marginTop: '10px' }}
          placeholder="ANO"
          options={handleGenerateYears()}
          value={dateView}
          onChange={(e) => {
            setDateView(e);
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

const Message = styled.div`
  width: 100%;
  font-size: 11pt;
  font-style: italic;
  margin: -10px 0px 10px 0px;
`;
