import React from 'react';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select
} from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';

import { routes } from '../../../config/routes';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { FilterContainer } from '../../../components/PageFilter';

import { SurveyListWrapper, Button } from './styles';
import { columns } from './columns';

const configPage = {
  pageName: 'Pesquisa',
  icon: 'smile',
  description: 'Lista das pesquisas cadastradas',
  textButtonNew: 'Criar pesquisa',
  routeNew: routes.pesquisaCadastrar.mount(),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Pesquisa', link: routes.pesquisaListar.mount() }
  ]
};

export const PesquisaListar = () => {
  const data = [
    {
      key: 1,
      id: '01',
      title: 'asdfuihdasdufuhisdahufasdhuuihfasd',
      updatedAt: formatDate('02/02/02', 'DD/MM/YY'),
      validity: ['2020-04-22T17:21:26.501Z', '2020-05-31T17:21:26.501Z'],
      status: 'Vigente',
      onRead: () => {
        console.log('read survey');
      }
    }
  ];

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <SurveyListWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <FilterContainer>
          <Button to={configPage.routeNew}>{configPage.textButtonNew}</Button>

          <Select placeholder="Status" />
        </FilterContainer>

        <Table columns={columns} dataSource={data} />
      </SurveyListWrapper>
    </PageTemplate>
  );
};
