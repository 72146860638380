import React, { useState } from 'react';
import { Input, Select, Switch, Icon, message } from 'antd';
import { isEmpty } from 'lodash';
import {
  Button,
  InputFieldConfig,
  useInputLabel,
  useForm,
  useField
} from '@digi-tim-19/components';

import * as S from './StylesQuestion';
const { Option } = Select;

type ItemValue = {
  question: string;
  type: string | undefined;
  required: boolean;
  answerOptions?: string[];
};

const Item = (props: {
  index: number;
  item: ItemValue;
  removeItem: (index: number) => any;
  changeQuestion: (index: number, value: string) => any;
  changeType: (index: number, value: string) => any;
  changeRequired: (index: number, value: any) => any;
  changeAnswerOptions: (index: number, value: any) => any;
}) => {
  const {
    index,
    item,
    removeItem,
    changeQuestion,
    changeType,
    changeRequired,
    changeAnswerOptions
  } = props;
  const [answerOptionValue, setAnswerOptionValue] = useState('');
  const [answerOption, setAnswerOption] = useState<any>(
    item.answerOptions || []
  );

  React.useEffect(() => {
    changeAnswerOptions(index, answerOption);
  }, [answerOption]);

  const addAnswerOption = () => {
    if (answerOption.includes(answerOptionValue)) {
      return message.warning('Essa opção já foi cadastrada nesta pergunta');
    }

    setAnswerOption([...answerOption, answerOptionValue]);
    setAnswerOptionValue('');
  };

  return (
    <S.QuestionWrapper>
      <h3>Pergunta {index + 1}</h3>

      <div className="question">
        <S.QuestionInput
          placeholder="Digite sua pergunta"
          defaultValue={item.question}
          onBlur={(e) => {
            changeQuestion(index, e.target.value);
          }}
        />

        <S.QuestionType
          placeholder="Tipo de pergunta"
          defaultValue={item.type}
          onChange={(value) => {
            changeType(index, value as string);
          }}
        >
          <Option value="writtenAnswer">Resposta escrita</Option>
          <Option value="dateAnswer">Data</Option>
          <Option value="scheduleAnswer">Horário</Option>
          <Option value="selectAnswer">Caixas de seleção</Option>
          <Option value="choicesAnswer">Múltipla escolha</Option>
        </S.QuestionType>

        <S.QuestionOptions>
          <Switch
            checked={item.required}
            style={{ marginRight: 20 }}
            onChange={(value) => changeRequired(index, value)}
          />

          <Icon
            type="delete"
            style={{ fontSize: 24 }}
            onClick={() => {
              removeItem(index);
            }}
          />
        </S.QuestionOptions>
      </div>

      <S.QuestionAnswerOptions>
        {(() => {
          switch (item.type) {
            case 'writtenAnswer':
              return <Input placeholder="Texto de resposta escrita" disabled />;
            case 'dateAnswer':
              return <Input placeholder="Data (dd/mm/aaaa)" disabled />;
            case 'scheduleAnswer':
              return <Input placeholder="Horário (hh:mm)" disabled />;
            case 'selectAnswer':
            case 'choicesAnswer':
              return (
                <>
                  <ul>
                    {answerOption?.map((item: any) => (
                      <li>
                        <span>
                          <div className="circle" />
                          {item}
                        </span>
                        <Icon
                          type="close"
                          style={{ fontSize: 22 }}
                          onClick={() =>
                            setAnswerOption(
                              answerOption.filter(
                                (question: any) => question !== item
                              )
                            )
                          }
                        />
                      </li>
                    ))}
                  </ul>
                  <div>
                    <Input
                      placeholder="Insira a opção de resposta"
                      value={answerOptionValue}
                      onChange={(e) => setAnswerOptionValue(e.target.value)}
                      onPressEnter={addAnswerOption}
                    />
                    <Icon
                      type="plus-circle"
                      style={{ fontSize: 22 }}
                      onClick={addAnswerOption}
                    />
                  </div>
                </>
              );
            default:
              return;
          }
        })()}
      </S.QuestionAnswerOptions>
    </S.QuestionWrapper>
  );
};

export const Questions = (props: InputFieldConfig) => {
  const { required } = props;
  const { wrapChildProps, renderError } = useInputLabel();

  const field = useField(props.name, {
    validate: (value: ItemValue[]) => {
      if (required && (!Array.isArray(value) || !value.length))
        return 'Campo obrigatório';
    }
  });

  const formApi = useForm();

  const [items, setItems] = useState<ItemValue[]>(() => {
    const questions = formApi.getState().values.questions;

    if (isEmpty(questions)) return [];
    else return questions;
  });

  function changeItemProp(key: keyof ItemValue) {
    return (index: number, value: any) => {
      const newValue = items.map((el, elIndex) => {
        if (elIndex !== index) return el;
        return { ...el, [key]: value };
      });
      setItems(newValue);
    };
  }

  function addItem() {
    const newItem: ItemValue = {
      question: '',
      type: undefined,
      required: false
    };

    setItems([...items, newItem]);
  }

  function removeItem(index: number) {
    const array = [...items];
    array.splice(index, 1);
    setItems(array);
  }

  React.useEffect(() => {
    formApi.change(props.name, items);
  }, [items]);

  wrapChildProps(field, props);

  return (
    <S.SurveyQuestionWrapper>
      {items.map((item, index) => {
        return (
          <Item
            index={index}
            key={item.question}
            item={item}
            removeItem={removeItem}
            changeQuestion={changeItemProp('question')}
            changeType={changeItemProp('type')}
            changeRequired={changeItemProp('required')}
            changeAnswerOptions={changeItemProp('answerOptions')}
          />
        );
      })}

      {renderError()}

      <Button onClick={addItem}>Adicionar pergunta</Button>
    </S.SurveyQuestionWrapper>
  );
};
