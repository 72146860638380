import { Input, TreeSelect as AntdTreeSelect } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const { Search: AntdSearch } = Input;

export const DocumentsListWrapper = styled.div`
  width: 100%;
`;

export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

export const Button = styled(Link)`
  border: none;
  background: red;
  display: flex;
  justify-content: center;

  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-family: TimBold;
  width: 100%;
  cursor: pointer;
  outline: none;
  margin-right: 15px;
  &:hover {
    background: white;
    color: red;
    outline: none !important;
  }
`;

export const TreeSelect = styled(AntdTreeSelect)`
  color: ${(props) => props.theme.blue};
  width: 100%;
  margin-right: 15px;

  .ant-select-selection__placeholder,
  .ant-select-selection__rendered {
    text-transform: uppercase;
  }

  i {
    color: ${(props) => props.theme.warning};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: none;
    }
    &::after {
      content: '';
      margin: 0px 5px;
      width: 0;
      height: 0;
      border: solid ${(props) => props.theme.blue};
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform-origin: center center;
      transition: all 0.3s;
      transform: rotate(45deg);
    }
  }

  &.ant-select-open {
    text-transform: uppercase;
    i {
      &::after {
        transform: rotate(225deg);
        margin: -2px 5px;
      }
    }
  }
`;
