"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
var base = {
  // cores extraidas de
  // https://www.tim.com.br/Portal_Conteudo/_staticfiles/para-voce/home/css/main.css?1568394532523
  blue: '#004691',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#e83e8c',
  red: '#EB0028',
  orange: '#fd7e14',
  yellow: '#ffc107',
  green: '#28a745',
  teal: '#20c997',
  cyan: '#17a2b8',
  white: '#fff',
  gray: '#6c757d',
  grayDark: '#343a40',
  primary: '#136fe0',
  secondary: '#6c757d',
  success: '#28a745',
  info: '#17a2b8',
  warning: '#ffc107',
  danger: '#dc3545',
  light: '#f8f9fa',
  dark: '#343a40',
  black: '#000',
  softBlue: '#536da5',
  // adicional:
  grayLight: '#e8e8e8',
  grayLight2: '#f5f5f5',
  gray0: '#eeeeee',
  gray1: '#d3ccc3',
  grayMedium: '#ddd9d3',
  grayMedium2: '#9f968e',
  red0: '#fe0326',
  red2: '#c2001c',
  blue2: '#00508c'
};
exports.colors = __assign(__assign({}, base), {
  lightBorder: base.grayLight
});
exports.default = exports.colors;