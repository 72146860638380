import styled from 'styled-components';

import { FormContainer } from 'components/Former';
import { colors } from '@digi-tim-19/theme';

export const Container = styled(FormContainer as any)`
  .form__footer {
    & > .selectPublic {
      max-width: 200px;
    }

    .component-upload {
      margin-top: 0;
    }

    .name-mailingApprovers {
      .ant-select-selection {
        max-height: 85px;
        overflow: auto;
      }
    }

    .name-availableForUsers {
      max-width: 33vw;

      .ant-select-selection {
        max-height: 120px;
        overflow: auto;
      }
    }
  }

  .FieldWrapper.form__approvalFlow {
    display: flex;
    justify-content: flex-end !important;
    margin-bottom: 20px !important;
  }

  .form-header .type-switch {
    margin-bottom: 30px;
    width: 75%;
  }

  .ant-upload-list {
    position: absolute;
    bottom: -25px;
  }
  .ant-select-selection__placeholder {
    left: 9px;
  }

  .name-undefined {
    justify-content: flex-start !important;
  }

  .FieldWrapper:nth-of-type(1) {
    align-items: center !important;
    justify-content: flex-start;
    flex-wrap: wrap;

    .name-categories {
      margin-left: 15px;
    }
  }
  .name-status {
    margin-left: 15px;
  }

  .name-templateName {
    .ant-radio-group {
      height: auto;
      img {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
        margin-top: 15px;
      }
      p {
        display: none;
      }
    }
    .ant-radio-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .name-documento {
    max-width: 30% !important;
    justify-content: flex-end !important;
    > span {
      display: flex;

      .anticon-loading {
        display: none;
      }
      .ant-upload-list-item-progress {
        display: none;
      }
      .anticon-close {
        right: -2px;
      }
    }
  }
  .name-notifyEmailsBeforeExpire {
    margin-bottom: 30px !important;
  }
  .name-mailing {
    max-width: 270px !important;
    span,
    button,
    div {
      width: 100%;
    }
    button.ant-btn {
      background: ${colors.blue};
      color: white;
      max-width: 210px !important;
      width: 100% !important;
    }
  }

  .search-parent-id {
    margin-bottom: 15px;
  }
`;

export const InformativosWrapper = styled.div`
  width: 100%;
`;
