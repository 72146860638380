import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;

  .form-section-container {
    background: #f5f5f5;
    border-radius: 4px;
    padding: 25px 20px;
  }

  .FormFooter {
    margin: 30px 0 20px;

    > button {
      border-radius: 5px;
      border: 1px solid ${(props) => props.theme.green};
      box-shadow: 2px 2px 5px #0002;
      font-size: 14px;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        margin-bottom: 10px;
      }
    }

    .Former__cancel-button {
      background: transparent;
      color: ${(props) => props.theme.green};
      pointer-events: all;
    }

    .Former__submit-button {
      background: ${(props) => props.theme.green};
      color: ${(props) => props.theme.white};

      &:disabled {
        background: ${(props) => props.theme.gray0} !important;
        border: 1px solid ${(props) => props.theme.gray0};
        box-shadow: 2px 2px 5px #0002;
      }
    }
  }
`;
