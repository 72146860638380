"use strict";

var _slicedToArray = require("/home/jenkins/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var isBrowser_1 = require("../isBrowser");

exports.useScroll = function () {
  var _React$useState = React.useState({
    direction: 'down',
    position: 0
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      state = _React$useState2[0],
      setState = _React$useState2[1];

  React.useEffect(function () {
    if (!isBrowser_1.isBrowser()) return undefined;
    var lastPos = 0;
    var lastDirection = 'down';
    var timeout;

    function onScroll() {
      clearTimeout(timeout);
      var position = window.pageYOffset;
      var direction = lastPos < position ? 'down' : 'up';

      function update() {
        setState({
          direction: direction,
          position: position
        });
      }

      if (direction !== lastDirection) {
        update();
      }

      timeout = setTimeout(update, 200);
      lastPos = position;
      lastDirection = direction;
    }

    window.addEventListener('scroll', onScroll);
    return function () {
      return window.removeEventListener('scroll', onScroll);
    };
  }, []);
  return state;
};