import {
  EnumFeedStatus,
  Feed,
  FilterFindManyFeedInput,
  SortFindManyFeedInput
} from 'autogenerated/client/types';
import { usePaginatedClient } from '../../../../hooks/usePaginatedClient';

export const usePaginatedFeed = () => {
  const OR = [
    { status: EnumFeedStatus.Moderation },
    { status: EnumFeedStatus.Disapproved }
  ];

  const { parsed, loading, updateFetch, pagination } = usePaginatedClient(
    'FeedPagination',
    {
      initial: {
        page: 1,
        perPage: 15,
        filter: { isDeleted: false, OR },
        sort: SortFindManyFeedInput.CreatedatDesc
      },
      itemsFragment: `
          _id
          event {
            _id
            title
          }
          text
          images {
            title
            signedUrl
          }
          videos {
            title
            signedUrl
          }
          owner {
            _id
            name
            CPF
            region
            channel
            roleGroup
            matricula
          }
          status
        `,
      fetchOnMount: true,
      parseResult: (result) =>
        result.map((el) => ({
          ...el,
          category: parsedCategory(el)
        }))
    }
  );

  const filterUpdate = (filter: FilterFindManyFeedInput) => {
    updateFetch((state) => {
      return {
        ...state,
        filter: {
          ...state.filter,
          eventId: filter?.eventId,
          category: filter?.category,
          status: filter?.status,
          subscription: filter?.subscription,
          OR: !filter?.status ? OR : undefined
        }
      };
    });
  };

  return {
    posts: parsed,
    loading,
    pagination,
    filterUpdate
  };
};

const parsedCategory = (data: Feed) => {
  let categories = [];

  if (data?.text && String(data?.text).trim() !== '') {
    categories.push('Texto');
  }

  if (data?.images && data?.images?.length > 0) {
    categories.push('Imagem');
  }

  if (data?.videos && data?.videos?.length > 0) {
    categories.push('Video');
  }

  return categories.join(', ');
};
