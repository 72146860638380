import React, { useState, useEffect } from 'react';
import { Pagination, Tag } from 'antd';
import { useRouteMatch, useHistory } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  SelectFilter,
  LoadingIndicator,
  ListInterno
} from '@digi-tim-19/components';
import { EnumMaterialKind, SortFindManyMaterialInput } from 'autogenerated/client/types';

import { useClient } from 'autogenerated/client/client';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { useCategoryCore } from 'hooks/categories/useCategoryCore';
import { getAccessButton } from 'utils/material';
import { routes } from 'config/routes';

import { SearchItemFragment } from './fragments';
import * as S from './BuscaStyles';

export const Busca = () => {
  const history = useHistory();
  const { params }: any = useRouteMatch();
  const categoryOptions = useCategoryCore();
  const search = params?.search;
  const perPage = 10;

  const getMateriais = useClient('MaterialSearch');

  const [itemCount, setItemCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [category, setCategory] = useState();
  const [kind, setKind] = useState();
  const [sort, setSort] = useState<SortFindManyMaterialInput | undefined>(
    SortFindManyMaterialInput.UpdatedatDesc
  );
  const [tags, setTags] = useState<any>();
  const [files, setFiles] = useState<any>();

  const selectsOrders = React.useMemo(
    () => [
      {
        defaultValue: sort || 'Ordenar por data',
        action: (value: SortFindManyMaterialInput) => {
          setSort(value);
        },
        options: [
          {
            name: 'Mais antigo',
            value: SortFindManyMaterialInput.UpdatedatAsc
          },
          {
            name: 'Mais recente',
            value: SortFindManyMaterialInput.UpdatedatDesc
          }
        ]
      },
      {
        defaultValue: category || 'Categoria: todas',
        action: (value: any) => {
          setCategory(value);
        },
        options: categoryOptions || []
      },
      {
        defaultValue: kind || 'Tipo: todos',
        action: (value: any) => {
          setKind(value);
        },
        options: [
          {
            name: 'Documentos',
            value: EnumMaterialKind.Document
          },
          {
            name: 'Informativos',
            value: EnumMaterialKind.Communication
          },
          {
            name: 'Vídeos',
            value: EnumMaterialKind.Video
          }
        ]
      }
    ],
    [categoryOptions, sort, category, kind]
  );

  useEffect(() => {
    getMateriais
      .fetch({
        appendToFragment: SearchItemFragment,
        variables: {
          perPage,
          page: pageNumber,
          search: decodeURIComponent(search),
          sort,
          category,
          kind
        }
      })
      .then((ctx) => {
        if (ctx.result) {
          setItemCount(ctx.result.count || 0);
        }
      });
  }, [search, sort, pageNumber, category, kind]);

  const isLoading = getMateriais.loading;
  const searchItems = getMateriais.result?.items;

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Busca', link: 'busca' }
          ]}
        />
      }
    >
      <S.SearchWrapper>
        <TitleDescription
          iconType={'search'}
          title={'Busca'}
          description={
            'Busque por tipos de conteúdo, palavras-chave, nome do documento, título e descrição'
          }
        />

        {/* <S.WordCloudWrapper>
          <WordCloudCorp />
        </S.WordCloudWrapper> */}

        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <S.ResultSearchWrapper>
            <S.Modal
              title={"Tópicos nesse documento"}
              visible={Boolean(tags?.length)}
              onCancel={() => setTags(undefined)}
              cancelText="Fechar"
              okButtonProps={{
                style: {
                  display: 'none'
                }
              }}
            >
              <S.ModalContainer>
                {
                  tags?.map((el: any) => {
                    return <Tag style={{ whiteSpace: "break-spaces", marginBottom: "5px" }}>{el}</Tag>
                  })
                }
              </S.ModalContainer>
            </S.Modal>
            
            <S.Modal
              title={"Anexos/arquivos"}
              visible={Boolean(files?.length)}
              onCancel={() => setFiles(undefined)}
              cancelText="Fechar"
              okButtonProps={{
                style: {
                  display: 'none'
                }
              }}
            >
              <S.ModalContainer>
                {
                  files?.map((el: any) => {
                    return <a href={el.signedUrl} target='_blank'>{el.title}</a>;
                  })
                }
              </S.ModalContainer>
            </S.Modal>

            <S.AmountResultsFound>
              Foram encontrados
              <S.AmountResults>{itemCount} resultados</S.AmountResults>
            </S.AmountResultsFound>

            <S.OptionsListResultWrapper>
              <SelectFilter selects={selectsOrders} />
            </S.OptionsListResultWrapper>

            {!!searchItems && (
              <>
                <S.ResultSearchWrapper>
                  {searchItems?.map((material) => {
                    if (!material) return;

                    const materialWithPublishedDate = {
                      ...material,
                      createdAt: material.updatedAt
                    };

                    return (
                      <ListInterno
                        key={material._id}
                        material={materialWithPublishedDate as any}
                        isSearchPage={true}
                        actionShowTermsForMaterialSearch={() => setTags(material?.termsForSearch)}
                        actionShowFiles={() => setFiles(material?.files)}
                        actionsMaterial={{
                          accessButton: getAccessButton(
                            material,
                            history,
                            routes
                          )
                        }}
                      />
                    );
                  })}

                  <Pagination
                    current={pageNumber}
                    total={itemCount}
                    pageSize={perPage}
                    onChange={(page) => setPageNumber(page)}
                    style={{ marginTop: 40, textAlign: 'center' }}
                  />
                </S.ResultSearchWrapper>

                <S.PaginationWrapper></S.PaginationWrapper>
              </>
            )}
          </S.ResultSearchWrapper>
        )}
      </S.SearchWrapper>
    </PageTemplate>
  );
};
