import { useMemo } from 'react';
import { useClient } from '../../autogenerated/client/client';
import { EnumMaterialKind, Maybe } from 'autogenerated/client/types';

type SimpleUser = {
  _id: string;
  name: string;
};

export const useMaterialOwners = (kind?: EnumMaterialKind) => {
  const MaterialSearchOwners = useClient('MaterialSearchOwners');

  useMemo(() => {
    MaterialSearchOwners.fetch({
      variables: {
        kind
      },
      fetchOnMount: true,
      appendToFragment: `_id name`
    });
  }, []);

  const result = MaterialSearchOwners.result?.map(
    (user: Maybe<SimpleUser>) => ({
      label: user?.name,
      value: user?._id
    })
  );

  return result;
};
