import React from 'react';
import { Button } from '@digi-tim-19/components';
import { Divider, Input, Modal as AntdModal, Select, Icon } from 'antd';
import styled from 'styled-components';

const { Option } = Select;
const { TextArea } = Input;

export interface Questions {
  id?: string;
  description?: string;
  type?: string;
  upload?: boolean;
  alternatives?: {
    id: string;
    ramificationQuestionId?: string;
    description: string;
  }[];
}

interface Props {
  isRamification?: boolean;
  values?: Questions[];
  disabled?: boolean;
  onChange?: (data: Questions[]) => void;
}

export const Wrapper = styled.div`
  width: 100%;
`;

export const FieldContainer = styled.div`
  width: 98%;
  height: 50px;
  display: flex;
  gap: 10px;

  #question-field {
    padding: 4px 11px 4px !important;
  }
`;

export const QuestionsContainer = styled.div`
  width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 20px;
`;

export const Description = styled.div`
  flex: 1;
  padding: 0 10px;
`;

const QuestionType = styled.div`
  padding: 0px 20px;
`;

const Modal = styled(AntdModal)`
  .ant-modal-body {
    padding: 16px 24px !important;
    overflow: auto;
  }

  .ant-modal-body .ant-divider-horizontal {
    margin: 12px 0px !important;
  }
`;

const ModalContainer = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const RangeContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 20px 0px 10px 0px;
`;

const RangeOption = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #dddddd;
  font-weight: bold;
`;

const AlternativeOption = styled.div`
  width: 100%;
  margin: 4px 0;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #ededed;
    border-radius: 10px;
  }
`;

const AlternativeText = styled.div`
  flex: 1;
  padding: 0 10px;
`;

export const QuestionsField = ({
  isRamification = false,
  values = [],
  onChange,
  disabled = false
}: Props) => {
  const [question, setQuestion] = React.useState<
    Partial<Questions> | undefined
  >();
  const [questions, setQuestions] = React.useState<Questions[]>([]);
  const [alternativeText, setAlternativeText] = React.useState<
    string | undefined
  >();
  const [open, setOpen] = React.useState(false);

  const handleAddAlternative = (description: string) => {
    setQuestion((prevState) => {
      return {
        ...prevState,
        alternatives: prevState?.alternatives
          ? [
              ...prevState.alternatives,
              {
                id: String(new Date().valueOf()),
                description,
                ramificationQuestionId: 'finish'
              }
            ]
          : [
              {
                id: String(new Date().valueOf()),
                description,
                ramificationQuestionId: 'finish'
              }
            ]
      };
    });
    setAlternativeText(undefined);
  };

  const handleRemoveAlternative = (id: string) => {
    setQuestion((prevState) => {
      return {
        ...prevState,
        alternatives:
          prevState?.alternatives?.filter((el: any) => el.id !== id) || []
      };
    });
  };

  const handleAddQuestion = (question: Partial<Questions> | undefined) => {
    if (!question) return;
    setQuestions((prevState) => {
      const data = [...prevState, { ...question }];
      if (onChange) onChange([...data]);
      return [...data];
    });
    setQuestion(undefined);
  };

  const handleEditQuestion = (question: Questions) => {
    setQuestions((prevState) => {
      const data = [...prevState].map((el) => {
        if (el.id === question.id) {
          return {
            ...el,
            ...question
          };
        }
        return { ...el };
      });
      if (onChange) onChange([...data]);
      return [...data];
    });
    setQuestion(undefined);
  };

  const handleRemoveQuestion = (id: string) => {
    setQuestions((prevState) => {
      const data = [...prevState].filter((el: any) => el.id !== id);
      if (onChange) onChange([...data]);
      return [...data];
    });
  };

  const getQuestionType = (type: string | undefined) => {
    switch (type) {
      case 'range':
        return '0 a 10';
      case 'multiple-select':
        return 'Múltipla escolha';
      case 'select':
        return 'Apenas uma escolha';
      default:
        return 'Texto';
    }
  };

  const handleCheckIsRamificationDisabled = (question: any) => {
    if (!question?.id) return false;
    let isRamificationDisabled = Boolean(
      questions.find((quest) => {
        const exists = quest?.alternatives?.find(
          (item: any) => item?.ramificationQuestionId === question.id
        );
        return Boolean(exists);
      })
    );
    if (!isRamificationDisabled) {
      return Boolean(
        question?.alternatives?.find(
          (item: any) => item?.ramificationQuestionId
        )
      );
    }
    return isRamificationDisabled;
  };

  React.useEffect(() => {
    if (values?.length > 0) setQuestions([...values]);
  }, [values]);

  return (
    <Wrapper>
      <Modal
        title={
          disabled || handleCheckIsRamificationDisabled(question)
            ? 'Visualizar questão'
            : 'Cadastrar/editar questão'
        }
        visible={open}
        okText="SALVAR"
        okButtonProps={{
          disabled:
            disabled ||
            handleCheckIsRamificationDisabled(question) ||
            !question?.description ||
            ((question?.type === 'select' ||
              question?.type === 'multiple-select') &&
              (!question?.alternatives || question?.alternatives?.length === 0))
        }}
        onOk={() => {
          if (question?.id) {
            handleEditQuestion(question);
          } else {
            handleAddQuestion({
              ...question,
              id: String(new Date().valueOf())
            });
          }
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
      >
        <ModalContainer>
          <TextArea
            disabled={disabled || handleCheckIsRamificationDisabled(question)}
            style={{
              width: '100%',
              marginBottom: '10px'
            }}
            placeholder="Descrição da pergunta"
            value={question?.description}
            onChange={(e: any) =>
              setQuestion({ ...question, description: e.target.value })
            }
          />
          <Select
            disabled={disabled || handleCheckIsRamificationDisabled(question)}
            value={question?.upload ? 'yes' : 'no'}
            style={{ width: '100%', marginBottom: '10px' }}
            onChange={(value: string) => {
              setQuestion({
                ...question,
                upload: value === 'yes' ? true : false
              });
            }}
          >
            <Option value="no">Sem upload</Option>
            <Option value="yes">Com upload</Option>
          </Select>
          <Select
            disabled={disabled || handleCheckIsRamificationDisabled(question)}
            value={question?.type || 'text'}
            style={{ width: '100%' }}
            onChange={(value: string) => {
              if (value === 'text' || value === 'range') {
                setQuestion({ ...question, type: value, alternatives: [] });
              } else {
                setQuestion({ ...question, type: value });
              }
            }}
          >
            <Option value="text">Texto</Option>
            <Option value="select">Apenas uma escolha</Option>
            <Option value="multiple-select">Múltipla escolha</Option>
            <Option value="range">0 a 10</Option>
          </Select>
          {(question?.type === 'select' ||
            question?.type === 'multiple-select') && (
            <React.Fragment>
              {!disabled && !handleCheckIsRamificationDisabled(question) && (
                <React.Fragment>
                  <Divider />
                  <Input
                    style={{ width: '100%', marginBottom: '10px' }}
                    placeholder="Descrição da alternativa"
                    value={alternativeText}
                    onChange={(e) => setAlternativeText(e.target.value)}
                  />
                  <Button
                    style={{ margin: 'auto' }}
                    disabled={!alternativeText || alternativeText === ''}
                    onClick={() => {
                      if (alternativeText) {
                        handleAddAlternative(alternativeText);
                      }
                    }}
                  >
                    Incluir alternativa
                  </Button>
                </React.Fragment>
              )}
              {question?.alternatives && question?.alternatives?.length > 0 && (
                <React.Fragment>
                  <Divider />
                  {question?.alternatives?.map((el: any) => {
                    return (
                      <AlternativeOption key={`alternative-${el.id}`}>
                        <AlternativeText>{el.description}</AlternativeText>
                        <Button
                          disabled={
                            disabled ||
                            handleCheckIsRamificationDisabled(question)
                          }
                          onClick={() => handleRemoveAlternative(el.id)}
                        >
                          <Icon type="delete" />
                        </Button>
                      </AlternativeOption>
                    );
                  })}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {question?.type === 'range' && (
            <RangeContainer>
              {[...Array(11).keys()].map((el: any, index: number) => {
                return <RangeOption key={`range-${index}`}>{el}</RangeOption>;
              })}
            </RangeContainer>
          )}
        </ModalContainer>
      </Modal>
      {!disabled && (
        <React.Fragment>
          <FieldContainer>
            <Button
              onClick={() => {
                setQuestion({ type: 'text' });
                setOpen(true);
              }}
            >
              CADASTRAR QUESTÃO
            </Button>
          </FieldContainer>
          <Divider />
        </React.Fragment>
      )}
      <QuestionsContainer>
        {questions.length === 0 ? (
          <p style={{ fontSize: '11pt' }}>Nenhuma questão cadastrada</p>
        ) : (
          <React.Fragment>
            {questions.map((el: any, index: number) => {
              const isRamificationDisabled =
                handleCheckIsRamificationDisabled(el);
              return (
                <QuestionWrapper key={`question-${index}`}>
                  <Description>{el.description}</Description>
                  <QuestionType>{getQuestionType(el?.type)}</QuestionType>
                  <Button
                    onClick={() => {
                      setQuestion({ ...el });
                      setOpen(true);
                    }}
                  >
                    <Icon
                      type={disabled || isRamificationDisabled ? 'eye' : 'edit'}
                    />
                  </Button>
                  <Button
                    disabled={isRamification}
                    onClick={() => handleRemoveQuestion(el.id)}
                  >
                    <Icon type="delete" />
                  </Button>
                </QuestionWrapper>
              );
            })}
          </React.Fragment>
        )}
      </QuestionsContainer>
    </Wrapper>
  );
};
