import React, { useState, useEffect } from 'react';
import { UserEditProfile } from '../../../../../components/UserEditProfile/UserEditProfile';
import UserProfileImage from '../../Desktop/images/user.png';
import {
  ProfileContainer,
  DropdownMenu,
  MenuItem,
  DivUser,
  CustomModal
} from './styles';
import { useCurrentUser } from '@digi-tim-19/components';
import { User } from 'autogenerated/client/types';
import { UserEmailSecondary } from 'components/UserEmailSecondary/UserEmailSecondary';
import { Icon } from 'antd';
import { useHistory } from 'react-router';
import { ProfileSimulatorMobile } from 'components/ProfileSimulator/ProfileSimulatorMobile';
import { TourSection, useTour } from 'providers/TourProvider';

interface ImgUserProfileProps {
  img?: string;
  alt?: string;
  online?: boolean;
}

export const ImgUserMobile = ({
  img,
  alt,
  online = false
}: ImgUserProfileProps) => {
  const { currentSelector } = useTour();
  const [modalEditProfile, setModalEditProfile] = useState(false);
  const [imgUser, setImgUser] = useState(UserProfileImage);
  const [showDropDown, setShowDropDown] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const history = useHistory();
  const { result: user } = useCurrentUser() as { result: User };

  useEffect(() => {
    if (img) {
      setImgUser(img || UserProfileImage);
    }
  }, [img]);

  const toggleEditProfileModal = () => {
    setModalEditProfile((prevState) => !prevState);
    setShowDropDown(false);
  };

  const toggleDropDown = () => {
    setShowDropDown((prevState) => !prevState);
  };

  const openModal = () => {
    setModalVisible(true);
    setShowDropDown(false);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleImageEdit = (editedImage: string) => {
    setImgUser(editedImage);
  };

  const handleOpenCloseDropDownTour = (value: boolean) => {
    setShowDropDown(value);
  };

  React.useEffect(() => {
    handleOpenCloseDropDownTour(currentSelector === '.tour-profile-options');
  }, [currentSelector]);

  return (
    <ProfileContainer>
      <img
        className="imgProfile"
        src={imgUser}
        alt={alt}
        style={{ height: '50px', width: '50px' }}
        onClick={toggleDropDown}
        onError={() => setImgUser(UserProfileImage)}
      />
      {online && <div className="online-indicator"></div>}

      {!user?.emailSecondary && (
        <UserEmailSecondary hasEmailSecondary={!!user?.emailSecondary} />
      )}

      {modalEditProfile && user && (
        <UserEditProfile
          modalVisible={modalEditProfile}
          user={user}
          setVisible={toggleEditProfileModal}
          onImageEdit={handleImageEdit}
        />
      )}

      {showDropDown && (
        <div>
          <TourSection selector="tour-profile-options" />
          <DropdownMenu className="tour-profile-options">
            <DivUser>
              <span>{user.name}</span>
              <span>{user?.roleGroup?.name as string}</span>
            </DivUser>
            <MenuItem onClick={openModal}>
              <Icon type="eye" />
              Ver como
            </MenuItem>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push('/informacoes-extrato');
              }}
            >
              <MenuItem>
                <Icon type="file-text" />
                Extrato Completo
              </MenuItem>
            </a>
            <MenuItem onClick={toggleEditProfileModal}>
              <Icon type="edit" />
              Editar Perfil
            </MenuItem>
          </DropdownMenu>
        </div>
      )}

      {
        <CustomModal
          className="CostumModal"
          footer={null}
          closable={false}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
        >
          <div>
            <ProfileSimulatorMobile closeModal={closeModal} />
          </div>
        </CustomModal>
      }
    </ProfileContainer>
  );
};
