"use strict";

var _slicedToArray = require("/home/jenkins/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var antd_1 = require("antd");

var ProviderSelecionarAbrangencia_1 = require("./ProviderSelecionarAbrangencia");

var TreeNode = antd_1.Tree.TreeNode;

exports.Tree = function (_ref) {
  var data = _ref.data,
      initialValues = _ref.initialValues,
      onSelectRegion = _ref.onSelectRegion;
  var SelecionarAbrangencia = ProviderSelecionarAbrangencia_1.useSelecionarAbrangencia();

  var _react_1$default$useS = react_1.default.useState({
    expandedKeys: ['Selecionar Todos'],
    autoExpandParent: true,
    checkedKeys: [],
    selectedKeys: []
  }),
      _react_1$default$useS2 = _slicedToArray(_react_1$default$useS, 2),
      state = _react_1$default$useS2[0],
      setState = _react_1$default$useS2[1];

  react_1.useEffect(function () {
    if (initialValues) {
      SelecionarAbrangencia.setState('regions', initialValues);
      setState(Object.assign(Object.assign({}, state), {
        checkedKeys: initialValues,
        selectedKeys: initialValues
      }));
    }
  }, [initialValues]);

  var onExpand = function onExpand(expandedKeys) {
    setState(Object.assign(Object.assign({}, state), {
      expandedKeys: expandedKeys,
      autoExpandParent: false
    }));
  };

  var onCheck = function onCheck(checkedKeys) {
    SelecionarAbrangencia.setStateEdit('regions', checkedKeys);
    setState(Object.assign(Object.assign({}, state), {
      checkedKeys: checkedKeys
    }));
    onSelectRegion(checkedKeys);
  };

  var onSelect = function onSelect(selectedKeys) {
    setState(Object.assign(Object.assign({}, state), {
      selectedKeys: selectedKeys
    }));
  };

  var renderTreeNodes = function renderTreeNodes(items) {
    return items.map(function (item) {
      if (item.children) {
        return react_1.default.createElement(TreeNode, {
          title: item.title,
          key: item.key,
          dataRef: item
        }, renderTreeNodes(item.children));
      }

      return react_1.default.createElement(TreeNode, Object.assign({
        key: item.key
      }, item));
    });
  };

  return react_1.default.createElement(antd_1.Tree, {
    checkable: true,
    onExpand: onExpand,
    expandedKeys: state.expandedKeys,
    autoExpandParent: state.autoExpandParent,
    onCheck: onCheck,
    checkedKeys: state.checkedKeys,
    onSelect: onSelect,
    selectedKeys: state.selectedKeys
  }, renderTreeNodes(data));
};