export function validateSelectPublic(formerRef: any) {
  let validate = false;

  if (formerRef.current.Form.getFieldState) {
    const availableAtRegionalProfiles = formerRef.current.Form.getFieldState(
      'availableAtRegionalProfiles'
    );
    const mailing = formerRef.current.Form.getFieldState('mailing');
    const availableForUsers =
      formerRef.current.Form.getFieldState('availableForUsers');

    if (!availableAtRegionalProfiles.value && !mailing && !availableForUsers) {
      return (validate = true);
    } else if (
      !availableAtRegionalProfiles?.value &&
      !mailing?.value &&
      !availableForUsers?.value
    ) {
      return (validate = true);
    } else {
      return (validate = false);
    }
  }

  return validate;
}
