import { useClient } from '../../../autogenerated/client/client';

export const useEvent = (id: string) => {
  const { result, loading } = useClient('EventFindById', {
    variables: { _id: id as any },
    appendToFragment: `
      thumbnail
      thumbnailContent {
        _id
        kind
        extension
        signedUrl
        downloadUrl
      }
      banner
      bannerContent {
        _id
        kind
        extension
        signedUrl
        downloadUrl
      }
      notifications {
        title
        body
        iconUrl
        redirectionLink
        sentAt
      }
    `,
    fetchOnMount: Boolean(id)
  });

  return { event: result, loading };
};
