import React from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';
import { formatDate } from '@digi-tim-19/utils';
import { Breadcrumb, TitleDescription } from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { EnumEventStatus } from '../../../autogenerated/client/types';
import { usePaginatedEvents } from '../hooks/usePaginatedEvents';
import { DocumentsListWrapper, Button } from './styles';
import { columns } from './columns';
import { uniqBy } from 'lodash';
import { InputFilters } from 'components/InputFilters/InputFilters';
import { TablePagination } from 'components/TablePagination/TablePagination';

const configPage = {
  pageName: 'Eventos',
  icon: 'inbox',
  description: 'Consulta e cadastramento de eventos.',
  messageDeletedSuccess: 'Evento cancelado com sucesso',
  routeNew: routes.documentosCadastrar.mount(),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Eventos', link: routes.eventosGerenciar.mount() }
  ],
  perPage: 70
};

export const EventosListar = () => {
  const history = useHistory();
  const updateClient = useClient('EventUpdateById', { afterMutate: /^Event/ });
  const { events, loading, pagination, filterUpdate } = usePaginatedEvents({
    showAll: true
  });

  const data = events.map((event) => {
    const validity = `${formatDate(event?.validity?.start, 'DD/MM/YY')}
      até ${formatDate(event?.validity?.end, 'DD/MM/YY')}`;
    const status = event?.status ? event?.status.label : 'Programado';

    return {
      key: event?._id,
      id: event?.eventId,
      title: event?.title,
      updatedAt: formatDate(event?.updatedAt, 'DD/MM/YY'),
      validity,
      status,
      author: event?.author?.name,
      onRead: () => history.push(routes.eventViewer.mount(event?._id)),
      onEdit: () => history.push(routes.eventosEditar.mount(event?._id)),
      onDelete: () => {
        const record = { _id: event._id, status: EnumEventStatus.Cancelled };
        updateClient.fetch({ variables: { record } }).then((ctx) => {
          if (!ctx.errors) message.success(configPage.messageDeletedSuccess);
        });
      }
    };
  });

  const userOption = () => {
    const data = events.map((event) => {
      return {
        label: `${event?.author?.name}`,
        value: `${event?.author?._id}`
      };
    });

    return uniqBy(data, 'value');
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <DocumentsListWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <InputFilters
          actionButtons={
            <Button to={routes.eventosCadastrar.mount()}>
              Cadastrar evento
            </Button>
          }
          config={[
            {
              type: 'select',
              key: 'status',
              placeHolder: 'STATUS',
              options: [
                { label: 'EM EDIÇÃO', value: 'editing' },
                { label: 'PROGRAMADO', value: 'programming' },
                { label: 'EM ANDAMENTO', value: 'in_progress' },
                { label: 'ENCERRADO', value: 'closed' },
                { label: 'CANCELADO', value: 'cancelled' }
              ]
            },
            {
              type: 'select',
              key: 'createdBy',
              placeHolder: 'BUSCAR POR SOLICITANTE',
              options: userOption()
            },
            {
              type: 'search',
              key: 'titleOReventID',
              placeHolder: 'BUSCAR POR TÍTULO OU ID'
            }
          ]}
          onChange={filterUpdate}
        />

        <TablePagination
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={pagination}
          emptyMessage={'Não há eventos para exibir'}
        />
      </DocumentsListWrapper>
    </PageTemplate>
  );
};
