import { Button } from '@digi-tim-19/components';
import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { CarouselForm } from './CarouselForm';

interface Attachment {
  title?: string;
  signedUrl: any;
}

export const VideoModal = ({ videos }: { videos?: [Attachment] }) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Button onClick={() => setShowModal(true)}>VISUALIZAR VÍDEO</Button>

      <Modal
        visible={showModal}
        title="Visualização de Vídeo"
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="close" onClick={() => setShowModal(false)}>
            FECHAR
          </Button>
        ]}
      >
        {videos && videos.length === 1 ? (
          <VideoContainerModal>
            <video controls>
              <source src={videos[0].signedUrl!} />
            </video>
          </VideoContainerModal>
        ) : (
          <CarouselForm isModal={true} videos={videos} />
        )}
      </Modal>
    </React.Fragment>
  );
};

const VideoContainerModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  video {
    width: 100%;
    max-width: 400px;
  }
`;
