import React, { useState } from 'react';
import { useDimensions } from '@digi-tim-19/utils';
import { Former } from '@digi-tim-19/components';

import { Event, EnumFileKind } from '../../autogenerated/client/types';
import { RegionalProfilesField } from '../../components/RegionalProfilesSelection/RegionalProfilesField';
import {
  ModalEventMailing,
  defaultMails
} from './Visualizar/ModalEventMailing';
import { Divider } from '../../components/Former';
import { FormWrapper } from './styles';

export type TFormEventEditProps = {
  initialValues?: Event;
  onCancel: () => void;
  onSubmit: (data: Event) => void;
};

export const Form = (props: TFormEventEditProps) => {
  const { isMobile } = useDimensions();
  const isUpdate = !!props.initialValues;

  const [emails, setEmails] = useState<Record<string, string>>({});

  return (
    <FormWrapper>
      <Former
        initialValues={props.initialValues}
        config={{
          fields: [
            {
              className: 'header',
              inline: !isMobile,
              list: [
                {
                  name: 'publishDate',
                  label: 'PROGRAMAR PUBLICAÇÃO',
                  type: 'datePicker',
                  required: true,
                  maxWidth: isMobile ? '100%' : '40%',
                  enableDatePriorToCurrent: true,
                  extraProps: {
                    format: 'DD/MM/YYYY',
                    setStartDay: true
                  }
                },
                {
                  name: 'validity',
                  label: 'Data de Vigência',
                  type: 'rangePicker',
                  required: true,
                  maxWidth: isMobile ? '100%' : '40%',
                  extraProps: {
                    format: 'DD/MM/YYYY HH:mm',
                    showTime: { format: 'HH:mm' }
                  }
                },
                {
                  name: 'thumbnail',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: EnumFileKind.CardThumb,
                    CTA: 'IMAGEM CARD',
                    allowedExtensions: ['image/png', 'image/jpeg']
                  }
                }
              ]
            },
            {
              name: 'banner',
              label: '',
              type: 'upload',
              required: true,
              extraProps: {
                kind: EnumFileKind.BannerImage,
                CTA: 'CONTEÚDO DO BANNER',
                allowedExtensions: ['image/png', 'image/jpeg']
              }
            },
            {
              custom: <Divider />
            },
            {
              name: 'title',
              label: 'TÍTULO (até 110 caracteres)',
              required: true,
              validate: (value: any) => {
                if (value)
                  return !(value.length > 110)
                    ? undefined
                    : 'Máximo de 110 caracteres';

                return undefined;
              }
            },
            {
              custom: <Divider />
            },
            {
              className: 'Footer',
              list: [
                {
                  name: 'availableAtRegionalProfiles',
                  custom: (
                    <RegionalProfilesField
                      fieldConfig={{
                        initialValue:
                          props.initialValues?.availableAtRegionalProfiles
                      }}
                      defaultChecked={
                        isUpdate
                          ? undefined
                          : [
                              'THQ#residencial:adm_trade_residencial',
                              'THQ#corporate:adm_trade_corporate'
                            ]
                      }
                    />
                  )
                },
                {
                  custom: <Divider />
                },
                {
                  name: 'description',
                  label: 'DESCRIÇÃO',
                  type: 'texteditor',
                  required: true
                }
              ]
            },
            {
              custom: <Divider />
            },
            {
              name: 'isDraft',
              label: 'Salvar como rascunho',
              type: 'switch'
            },
            {
              custom: <Divider />
            },
            {
              custom: (
                <ModalEventMailing
                  initialValue={props.initialValues}
                  onConfirm={setEmails}
                />
              )
            }
          ],
          submitButton: {
            label: isUpdate ? 'Atualizar' : 'Salvar'
          },
          cancelButton: {
            label: 'CANCELAR',
            onClick: props.onCancel
          }
        }}
        onSubmit={async ({ data }: any) => {
          const [start, end] = data.validity;
          const availableAtRegionalProfiles = [
            'THQ#residencial:adm_trade_residencial',
            'THQ#corporate:adm_trade_corporate'
          ];

          const record = {
            _id: data._id,
            publishDate: data.publishDate,
            validity: { start, end },
            thumbnail: data.thumbnail,
            banner: data.banner,
            title: data.title,
            description: data.description,
            isDraft: data.isDraft,
            onPublishMail:
              data.onPublishMail ||
              emails.onPublishMail ||
              defaultMails.onPublishMail,
            onUpdateMail:
              data.onUpdateMail ||
              emails.onUpdateMail ||
              defaultMails.onUpdateMail,
            prePublishMail:
              data.prePublishMail ||
              emails.prePublishMail ||
              defaultMails.prePublishMail,
            availableAtRegionalProfiles:
              data.availableAtRegionalProfiles || availableAtRegionalProfiles
          };

          props.onSubmit(record as any);
        }}
      />
    </FormWrapper>
  );
};
