import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Icon, message } from 'antd';
import { useDimensions } from '@digi-tim-19/utils';
import { Button, Former, useCurrentUser } from '@digi-tim-19/components';

import { useProfileSimulatorOptions } from './useProfileSimulatorData';
import { colors } from '@digi-tim-19/theme';

const SimulatorWrapper = styled.div`
  width: 100%;

  @media screen and (max-width: 767px) {
    margin-left: 0;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
    flex-basis: 100%;
    max-width: 100%;

    .fWlYDS.fWlYDS.fWlYDS.maxChildWidth > .FieldWrapper {
      margin-right: 0;
    }

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;
      .FieldWrapper {
        .ant-select-arrow .ant-select-arrow-icon svg {
          transition: transform 0.3s;
          color: #535353;
          width: 15.2px;
        }

        display: flex;
        flex-direction: column;
        gap: 16px;
        font-family: TimBold;
        margin-bottom: 0 !important;
        justify-content: flex-end !important;
        > .FieldWrapper {
          display: flex;
          flex-direction: row;
          margin-bottom: 5px;
          width: 100%;
        }
        & .name-region {
          max-width: 50%;
          margin: 0;
        }
        & .name-UF {
          max-width: 50%;
        }
      }
    }

    .FormFooter {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;
      > button {
      }
    }

    .FormFooters {
      margin-top: 24px;
    }

    .ant-select-arrow .ant-select-arrow-icon svg {
      transition: transform 0.3s;
      color: #535353;
      width: 15.2px;
    }

    .ant-select-selection--single {
      border-radius: 12px;
      border: 1px solid #afafaf;
      background: #fff;
      text-align: center;
    }

    .fsVXrM {
      top: 18px;
      position: absolute;
    }
  }
`;

const Divheaderv = styled.div`
  width: 100%;
  margin-bottom: 22.38px;
  border-bottom: 1px solid #e4e4e4;
`;

const Title = styled.h1`
  color: #535353;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 47.62px;
  font-family: TimBold;
`;

const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  font-family: TimBold;
  align-items: center;
  height: 36px;
  width: 96px;
  padding: 10px 16px;
  gap: 8px;
  cursor: pointer;
  border-radius: 56px;
  color: #535353;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  border: 1px solid #535353;
  background: #fff;

  @media screen and (max-width: 767px) {
    width: calc(50% - 8px);
  }
`;

const SubmitButton = styled(Button)`
  display: flex;
  font-family: TimBold;
  height: 40px;
  padding: 12px 20px 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 80px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #1565c0;
  background: #e3f2fd;

  &:hover {
    font-family: TimBold;
    background: #e3f2fd;
    color: #1565c0;
  }

  @media screen and (max-width: 767px) {
    width: calc(50% - 8px);
  }
`;

const StyledIcon = styled(Icon)`
  width: 10.02px;
  height: 15px;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${colors.blue};
  border: 1px;
`;

export const ProfileSimulator = (props: TSimulatorProps) => {
  const { closeModal } = props;
  const { className } = props;
  const { isCorp } = props;
  const simulatorData = useProfileSimulatorOptions(isCorp);

  const handleCancel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    closeModal && closeModal();
  };

  const dimensions = useDimensions();

  const { isAdmin, result } = useCurrentUser();
  const roleGroup = result?.roleGroup?._id;
  const isCorpAdmin =
    roleGroup?.includes('adm_trade') ||
    roleGroup?.includes('adm_sac') ||
    roleGroup?.includes('internos_') ||
    roleGroup?.includes('operacao_residencial') ||
    roleGroup?.includes('operacao_corporate') ||
    roleGroup?.includes('areas_parceiras_corporativo');

  const isUserIntern = result?.channels?.map((item) => {
    if (item === 'residencial' || item === 'corporate') {
      return true;
    } else {
      return false;
    }
  });

  if (!isAdmin && !isUserIntern) return null;
  if (!isCorpAdmin) return null;

  return (
    <SimulatorWrapper
      className={cx(className, 'ui-flex Simulator Simulator_Wrapper')}
    >
      <Former
        initialValues={simulatorData.initialValues}
        config={{
          fields: [
            {
              inline: false,
              custom: {
                render: () => {
                  return (
                    <Divheaderv>
                      <Icon
                        onClick={handleCancel}
                        style={{ marginBottom: '30px' }}
                        type="double-right"
                      />

                      <Title>Ver como outro usuário</Title>
                    </Divheaderv>
                  );
                }
              }
            },
            {
              inline: true,
              list: simulatorData.fields
            }
          ],
          customRenderFooter: (renderProps) => {
            const { form } = renderProps;
            return (
              <>
                <CancelButton onClick={handleCancel}>Cancelar</CancelButton>
                <SubmitButton onClick={() => form.submit()}>
                  <StyledIcon type="eye" style={{ marginTop: 4 }} />
                  Simular visualização
                </SubmitButton>
              </>
            );
          }
          /*submitButton: { label: 'Simular' }*/
        }}
        onSubmit={async ({ data }) => {
          const { type } = simulatorData;

          if (type === 'region' && !data.region) {
            return message.error('Preencha a Regional');
          }
          if (type === 'uf' && !data.UF) {
            return message.error('Preencha a UF');
          }

          return simulatorData.onSubmit(data);
        }}
      />
    </SimulatorWrapper>
  );
};

// export default Simulator;

export type TSimulatorProps = {
  className?: string;
  children?: React.ReactNode;
  isCorp?: boolean;
  closeModal?: (() => void) | undefined;
};
