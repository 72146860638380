import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import {
  Breadcrumb,
  Comments,
  Icon,
  ShareModal,
  useMaterialActions,
  MaterialProvider
} from '@digi-tim-19/components';
import { isImageExtension } from '@digi-tim-19/utils';
import { Icon as IconAction } from '@digi-tim-19/components/build/main/ActionButton/ActionButton';
import { colors } from '@digi-tim-19/theme';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { Material, EnumMaterialKind } from '../../autogenerated/client/types';
import { useBreadcrumb } from 'hooks/useBreadcrumb';
import { breadcrumbFragment } from 'utils/breadcrumbFragment';

const ImageButtons = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 30px;

  &,
  a {
    max-height: 30px;
  }
`;

const ImageDownload = styled.a`
  align-items: center;
  background: ${(props) => props.theme.blue};
  border-radius: 2px;
  color: ${(props) => props.theme.white} !important;
  display: flex;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
  margin-right: 10px;
`;

export const PageLoadImage = () => {
  const { id } = useParams<{ id: string }>();
  const getMaterials = useClient('AnalyticsMaterialView');
  const MaterialLikeToggle = useClient('MaterialLikeToggle');
  const actions = useMaterialActions();
  const [material, setMaterial] = useState<Material>();
  const [matCategory, setMatCategory] = useState<any>({});
  const isDocument = material?.kind === EnumMaterialKind.Document;
  const apiAnalyticsDownload = useClient('AnalyticsDownloads');
  const breadcrumb = useBreadcrumb(material?.title!, matCategory!);
  const download = typeof material?.download === "undefined" || material?.download === null || material?.download;
  
  const handleTrackDownload = (fileId: string | undefined) =>
    apiAnalyticsDownload.fetch({ variables: { fileId } });

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          _id: id
        }
      })
      .then((ctx) => {
        setMaterial(ctx.result!);
        setMatCategory(ctx.result?.categories.find((item: any) => item._id));
      });
  }, [id]);

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={breadcrumb} />}>
      <>
        {material?.files?.map((item) => {
          if (isImageExtension(item?.extension) && item?.kind !== 'card_thumb')
            return <img alt={item?.title || ''} src={item?.signedUrl || ''} />;
        })}
        {material && isDocument && (
          <MaterialProvider material={material as any}>
            <ImageButtons>
              {material.enableSocialInteraction && (
                <IconAction
                  isFilled={material.likes?.liked}
                  name={'heart'}
                  amount={material.likes?.count}
                  onClick={() => {
                    MaterialLikeToggle.fetch({
                      variables: { recordId: material._id }
                    });
                  }}
                />
              )}
              <ShareModal />
              <IconAction
                isFilled={material.pinned}
                name={'star'}
                onClick={() =>
                  material.pinned
                    ? actions.deletePin({ recordId: material._id })
                    : actions.pin({ recordId: material._id })
                }
              />
              {
                Boolean(download)
                &&
                <ImageDownload
                  href={
                    material.files?.find((el) => el?.kind !== 'card_thumb')
                      ?.signedUrl || ''
                  }
                  className="download"
                  onClick={() =>
                    handleTrackDownload(
                      material.files?.find((el) => el?.kind !== 'card_thumb')
                        ?._id || ''
                    )
                  }
                >
                  <Icon
                    type="download"
                    style={{ color: colors.white, marginRight: 5 }}
                  />
                  Download
                </ImageDownload>
              }
              {material.link && (
                <ImageDownload href={material?.link || ''} className="download">
                  <Icon
                    type="link"
                    style={{ color: colors.white, marginRight: 5 }}
                  />
                  Link
                </ImageDownload>
              )}
            </ImageButtons>

            {material.enableSocialInteraction && (
              <Comments parentId={material._id} />
            )}
          </MaterialProvider>
        )}
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  _id
  likes {
   liked
    count
  }
  files {
    _id
    kind
    signedUrl
    downloadUrl
    extension
  }

  categories {
    ${breadcrumbFragment}
  }
`;
