import React from 'react';
import { useRouteMatch } from 'react-router';
import { Empty } from 'antd';
import {
  Breadcrumb,
  LoadingIndicator,
  TitleDescription
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { useIncentiveCampaign } from 'hooks/incentiveCampaign/useIncentiveCampaign';

import { FormUploadExtra } from './FormUploadExtra';

export const CampanhaEstornoUploadExtra = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const { loading, result, error } = useIncentiveCampaign(
    params.id,
    '_id title walletId'
  );

  const Result = () => {
    if (loading) return <LoadingIndicator />;
    if (error) return <Empty />;
    return <FormUploadExtra campaign={result!} />;
  };

  return (
    <PageTemplate>
      <>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            {
              title: 'Incentivo',
              link: routes.campanhasIncentivoListar.mount()
            },
            {
              title: 'Estorno/Upload de TIM Coins extras',
              link: routes.campanhasIncentivoEstornoUploadExtraListar.mount()
            },
            { title: 'Campanha' }
          ]}
        />
        <TitleDescription
          iconType=""
          title="Estorno/Upload de TIM Coins extras"
          description=""
        />

        <Result />
      </>
    </PageTemplate>
  );
};
