"use strict";

var _defineProperty = require("/home/jenkins/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.sortByStringArray = function (_ref) {
  var data = _ref.data,
      sortBy = _ref.sortBy;
  var indexValues = sortBy.reduce(function (obj, item, index) {
    return Object.assign(Object.assign({}, obj), _defineProperty({}, item, index));
  }, {});
  return data.sort(function (a, b) {
    return indexValues[a] - indexValues[b];
  });
};