import styled from 'styled-components';

import { FormContainer } from 'components/Former';
import { colors } from '@digi-tim-19/theme';

export const NotificationsWrapper = styled.div`
  width: 100%;
`;

export const Container = styled(FormContainer as any)`
  .form-section-container {
    .header {
      flex-wrap: wrap !important;
      justify-content: center !important;

      @media screen and (min-width: 1024px) {
        justify-content: flex-start !important;
      }

      > .FieldWrapper {
        margin-left: 0 !important;
        margin-right: 20px !important;
        max-width: 300px;

        @media screen and (max-width: 1024px) {
          margin: 0 0 30px !important;
          max-width: 100% !important;
        }
      }
    }
  }

  .ant-upload-list {
    position: absolute;
    bottom: -25px;
  }
  .ant-select-selection__placeholder {
    left: 9px;
  }

  .form__footer {
    & > .selectPublic {
      max-width: 200px;
    }

    .name-mailingApprovers {
      .ant-select-selection {
        max-height: 85px;
        overflow: auto;
      }
    }

    .name-availableForUsers {
      max-width: 33vw;

      .ant-select-selection {
        max-height: 120px;
        overflow: auto;
      }
    }
  }

  .FieldWrapper.form__approvalFlow {
    display: flex;
    justify-content: flex-end !important;
    margin-bottom: 20px !important;
  }

  .FieldWrapper:nth-of-type(1) {
    align-items: center !important;
    justify-content: flex-start;
    flex-wrap: wrap;
    .name-categories {
      margin-left: 15px;
    }
  }

  .name-status {
    margin-left: 15px;
  }
  .name-thumbnail {
    max-width: 30% !important;
    > span {
      display: flex;

      .anticon-loading {
        display: none;
      }
      .ant-upload-list-item-progress {
        display: none;
      }
      .anticon-close {
        right: -2px;
      }
    }
  }
  .name-mailing {
    max-width: 210px !important;
    span,
    button,
    div {
      width: 100%;
    }

    & > span {
      margin-top: 0 !important;
    }

    button.ant-btn {
      background: ${colors.blue};
      color: white;
      max-width: 210px !important;
      width: 100% !important;
    }
  }
`;
