import React from 'react';
import { TableActions } from '@digi-tim-19/components';

export const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Aprovador',
    key: 'action',
    render: (text: DataKey, record: any) => <TableActions data={record} />
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
