import { Material, EnumMaterialStatus } from 'autogenerated/client/types';

export const communicationCanEdit = (material?: Partial<Material>) => {
  if (!material) {
    return true;
  }

  const isPublished = material?.status?.value === EnumMaterialStatus.Published;
  const isAwaiting =
    material?.status?.value === EnumMaterialStatus.WaitingApproval;
  const isDisapproved =
    material?.status?.value === EnumMaterialStatus.Disapproved;

  if (isDisapproved && !material?.isOwner) return false;
  if (isPublished) return false;
  if (isAwaiting) return false;

  return true;
};
