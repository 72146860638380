import { useClient } from '../autogenerated/client/client';

export const useMaterialCategories = (parentId: string) => {
  const getCategories = useClient('CategoryFindMany', {
    fetchOnMount: true,
    variables: { limit: 123, filter: { parentId } }
  });

  return getCategories;
};

export const useMaterialCategoriesSelect = (parentId: string) => {
  const getCategories = useClient('CategoryFindMany', {
    fetchOnMount: true,
    variables: { limit: 123, filter: { parentId } }
  });

  const category = getCategories?.result?.map((item) => ({
    label: item?.name,
    value: item?._id
  }));

  return category;
};
