import styled from 'styled-components';

export const DownloadSensitiveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ItemDownload = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  span {
    margin-left: 10px;
    font-size: 14px;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
