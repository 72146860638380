import React from 'react';
import { Carousel, Icon, Popconfirm, Tooltip } from 'antd';
import { useCurrentUser } from 'hooks/user/useCurrentUser';
import styled from 'styled-components';
import { formatDate } from '@digi-tim-19/utils/build';
import { useUpdateLike } from './hooks/useUpdateLike';
import { colors } from '@digi-tim-19/theme';

interface Attachment {
  title?: string;
  signedUrl: any;
}

export interface PostInterface {
  _id: string;
  text: string;
  likes?: string[];
  createdAt?: string;
  images?: [Attachment];
  videos?: [Attachment];
  owner?: {
    _id?: string;
    name?: string;
    region?: string;
    roleGroup?: string;
  };
}

interface PostProps {
  data: PostInterface;
  onDelete?: (id?: string) => void;
}

export const Post = ({ data, onDelete }: PostProps) => {
  const { userId } = useCurrentUser();
  const [post, setPost] = React.useState<PostInterface>({ ...data });
  const [likes, setLikes] = React.useState<string[]>([]);
  const { likeUpdate, loading } = useUpdateLike();
  const ref = React.createRef<any>();

  const handlePostData = (post: PostInterface) => {
    setPost({ ...post });
    setLikes(post?.likes || []);
  };

  React.useEffect(() => {
    handlePostData({ ...data });
  }, [data]);

  const handleLike = async (_id: string) => {
    const likes = await likeUpdate(_id);
    setLikes([...likes]);
  };

  const handleNext = () => {
    if (ref?.current?.next) {
      ref?.current?.next();
    }
  };

  const handlePrevious = () => {
    if (ref?.current?.prev) {
      ref?.current?.prev();
    }
  };

  return (
    <PostWrapper>
      <Autor>
        <AutorName>Por: {post?.owner?.name}</AutorName>
        <AutorInfo>
          {post?.owner?.roleGroup}
          {post?.owner?.region && ` | ${post?.owner?.region}`}
        </AutorInfo>
        <PostDate>{post?.createdAt && formatDate(post?.createdAt)}</PostDate>
      </Autor>
      <PostText>
        <TextStyled>{post?.text}</TextStyled>
        {((post?.images && post?.images.length > 0) ||
          (post?.videos && post?.videos?.length > 0)) && (
          <>
            {((!post?.images || post?.images?.length < 1) &&
              post?.videos &&
              post?.videos?.length === 1) ||
            ((!post?.videos || post?.videos?.length < 1) &&
              post?.images &&
              post?.images?.length === 1) ? (
              <>
                {post?.images?.map((image: Attachment, index: number) => {
                  return (
                    <AttachmentImage key={`attachment-image-${index}`}>
                      <img src={image.signedUrl} alt={image.title} />
                    </AttachmentImage>
                  );
                })}
                {post?.videos?.map((video: Attachment, index: number) => {
                  return (
                    <AttachmentVideo key={`attachment-video-${index}`}>
                      <video controls>
                        <source src={video.signedUrl} />
                      </video>
                    </AttachmentVideo>
                  );
                })}
              </>
            ) : (
              <CarouselContainer>
                <ControlContainer>
                  <Icon
                    className="control-buttons"
                    type="left"
                    onClick={() => handlePrevious()}
                  />
                </ControlContainer>
                <CarouselAttachment>
                  <Carousel ref={ref} dots={false}>
                    {post?.images?.map((image: Attachment, index: number) => {
                      return (
                        <AttachmentImage key={`attachment-image-${index}`}>
                          <img src={image.signedUrl} alt={image.title} />
                        </AttachmentImage>
                      );
                    })}
                    {post?.videos?.map((video: Attachment, index: number) => {
                      return (
                        <AttachmentVideo key={`attachment-video-${index}`}>
                          <video controls>
                            <source src={video.signedUrl} />
                          </video>
                        </AttachmentVideo>
                      );
                    })}
                  </Carousel>
                </CarouselAttachment>
                <ControlContainer>
                  <Icon
                    className="control-buttons"
                    type="right"
                    onClick={() => handleNext()}
                  />
                </ControlContainer>
              </CarouselContainer>
            )}
          </>
        )}
      </PostText>
      <PostOptions>
        {post?.owner?._id === userId && (
          <Popconfirm
            placement={'top'}
            title={`Deseja excluir esta postagem?`}
            onConfirm={() => {
              if (onDelete) onDelete(post?._id);
            }}
            okText={'Sim'}
            cancelText={'Não'}
          >
            <Tooltip title="Excluir">
              <Icon type="delete" />
            </Tooltip>
          </Popconfirm>
        )}
        <div>
          {!loading && likes.length > 0 && <Likes>{likes.length}</Likes>}
          <Tooltip title={likes.includes(userId) ? 'Descurtir' : 'Curtir'}>
            {!loading && likes.includes(userId) ? (
              <Icon
                type={'heart'}
                theme="filled"
                className="fill-color-red"
                onClick={() => handleLike(post._id)}
              />
            ) : (
              <Icon
                type={loading ? 'loading' : 'heart'}
                onClick={() => handleLike(post._id)}
              />
            )}
          </Tooltip>
        </div>
      </PostOptions>
    </PostWrapper>
  );
};

const PostWrapper = styled.div`
  width: 100%;
`;

const Autor = styled.div`
  width: 100%;
  margin: 10px 0px;

  p {
    margin: 0px !important;
  }
`;

const AutorName = styled.p`
  font-size: 11pt;
  color: ${(props) => props.theme.blue};
`;

const AutorInfo = styled.p`
  font-size: 10pt;
  color: ${(props) => props.theme.blue};
`;

const PostDate = styled.p`
  font-size: 9pt;
  color: ${(props) => props.theme.gray1};
`;

const PostText = styled.div`
  width: 100%;
  padding: 8px;
  background: white;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  font-size: 11pt;
`;

const TextStyled = styled.div`
  width: 100%;
  word-break: break-word;
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;

  .control-buttons:hover {
    color: ${colors.red};
  }
`;

const ControlContainer = styled.div`
  width: 50px;
  height: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30pt;
`;

const CarouselAttachment = styled.div`
  width: 75%;
  max-width: 500px;

  .ant-carousel .slick-slider {
    height: 370px !important;
  }

  .ant-carousel .slick-slider .slick-track {
    height: 370px !important;
  }

  .slick-slide {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  @media (max-width: 900px) {
    .ant-carousel .slick-slider {
      height: 250px !important;
    }

    .ant-carousel .slick-slider .slick-track {
      height: 250px !important;
    }
  }
`;

const AttachmentImage = styled.div`
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 370px;
  margin: 15px auto;

  img {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 370px;
  }
`;

const AttachmentVideo = styled.div`
  margin: 15px auto;
  width: 100%;
  max-width: 500px;

  video {
    width: 100%;
    height: 100%;
  }
`;

const PostOptions = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
  padding: 10px;

  .fill-color-red {
    color: ${(props) => props.theme.red};
  }
`;

const Likes = styled.span`
  padding-right: 5px;
`;
