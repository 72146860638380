import React from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  LoadingIndicator,
  TitleDescription
} from '@digi-tim-19/components';

import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useCurrentUser } from 'hooks/user/useCurrentUser';

const RelatorioViewer = styled.iframe`
  border-radius: 5px;
  height: 430px;
  margin: 25px 0;
  overflow: auto;
  width: 100%;

  @media screen and (min-width: 768px) {
    height: 573px;
  }

  @media screen and (min-width: 1024px) {
    height: 860px;
  }
`;

export const Dashboard = () => {
  const { result: user, loading } = useCurrentUser();

  const userID = user?._id;
  const relatorioLink = `https://powerbi.digi.ag/TimCorporate/?i=${userID}`;

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Relatórios' },
            { title: 'Dashboard' }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType="line-chart"
          title="Dashboard"
          description=""
        />

        {loading ? (
          <LoadingIndicator />
        ) : (
          <RelatorioViewer src={relatorioLink} frameBorder={0} />
        )}
      </>
    </PageTemplate>
  );
};
