import React from 'react';
import { LoadingIndicator, Table } from '@digi-tim-19/components';
import { Pagination } from 'antd';
import styled from 'styled-components';
import { EmptyContainer } from './EmptyContainer';

interface Props {
  dataSource: any[];
  columns: any[];
  pagination: any;
  loading: boolean;
  loadingTable: boolean;
  emptyMessage: string;
}

export const TablePagination = ({
  dataSource = [],
  columns = [],
  loading = false,
  loadingTable = false,
  pagination = {},
  emptyMessage = undefined
}: Partial<Props>) => {
  return (
    <React.Fragment>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <React.Fragment>
          {dataSource.length === 0 ? (
            <EmptyContainer message={emptyMessage} />
          ) : (
            <React.Fragment>
              <Table
                loading={loadingTable}
                columns={columns}
                dataSource={dataSource}
                pagination={Object.keys(pagination).length > 0 ? false : {}}
              />
              {Object.keys(pagination).length > 0 && (
                <PaginationStyled {...pagination} />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const PaginationStyled = styled(Pagination)`
  width: 100%;
  margin: 20px auto 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
