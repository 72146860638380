import React from 'react';
import { useParams, Redirect } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator
} from '@digi-tim-19/components';

import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { routes } from '../../config/routes';

export const OnTubePlaylist = () => {
  const { id }: any = useParams();

  let filterId = id;
  if (id === 'pre-pago') {
    filterId = 'video_playlist:tim_pre';
  }
  if (id === 'controle') {
    filterId = 'video_playlist:tim_controle';
  }
  if (id === 'pos-pago') {
    filterId = 'video_playlist:tim_black';
  }
  if (id === 'fixo') {
    filterId = 'video_playlist:residencial';
  }

  const playlist = useClient('CategoryFindById', {
    variables: {
      _id: filterId
    },
    fetchOnMount: true,
    appendToFragment: `
      materials(
        kind: video,
        sort: _ID_DESC
        perPage: 1
      ) {
        items {
          _id
          title
        }
      }
    `
  });

  const playlistVideos = playlist.result?.materials?.items;
  const videoID = (playlistVideos || []).find((el) => el)?._id;

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Vídeos', link: routes.videos.mount() },
            { title: 'Vídeo', link: '#' }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType="play-square"
          title="Vídeos"
          description={
            'Seu canal de vídeos com todas as informações que você precisa. \n Encontre o que deseja com filtros de busca, comente, curta, compartilhe e muito mais.'
          }
        />

        {playlist.loading ? (
          <LoadingIndicator />
        ) : videoID ? (
          <Redirect to={routes.videosPlaylist.mount(videoID)} />
        ) : (
          <Redirect to={routes.videos.mount()} />
        )}
      </>
    </PageTemplate>
  );
};
