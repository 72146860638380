import React from 'react';
import { colors } from '@digi-tim-19/theme';
import { Carousel, Icon } from 'antd';
import styled from 'styled-components';

interface Attachment {
  title?: string;
  signedUrl: any;
}

interface CarouselFormProps {
  images?: [Attachment];
  videos?: [Attachment];
  isModal?: boolean;
}

export const CarouselForm = (props: CarouselFormProps) => {
  const { images, videos } = props;
  const ref = React.createRef<any>();

  console.log({ images, videos });

  const handleNext = () => {
    if (ref?.current?.next) {
      ref?.current?.next();
    }
  };

  const handlePrevious = () => {
    if (ref?.current?.prev) {
      ref?.current?.prev();
    }
  };

  return (
    <CarouselContainer>
      <ControlContainer>
        <Icon
          className="control-buttons"
          type="left"
          onClick={() => handlePrevious()}
        />
      </ControlContainer>
      <CarouselAttachment isModal={props.isModal}>
        <Carousel ref={ref} dots={false}>
          {images?.map((image: Attachment, index: number) => {
            return (
              <AttachmentImage key={`attachment-image-${index}`}>
                <img src={image.signedUrl} alt={image.title} />
              </AttachmentImage>
            );
          })}
          {videos?.map((video: Attachment, index: number) => {
            return (
              <AttachmentVideo key={`attachment-video-${index}`}>
                <video controls>
                  <source src={video.signedUrl} />
                </video>
              </AttachmentVideo>
            );
          })}
        </Carousel>
      </CarouselAttachment>
      <ControlContainer>
        <Icon
          className="control-buttons"
          type="right"
          onClick={() => handleNext()}
        />
      </ControlContainer>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;

  .control-buttons:hover {
    color: ${colors.red};
  }
`;

const ControlContainer = styled.div`
  width: 50px;
  height: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30pt;
`;

const CarouselAttachment = styled.div<{ isModal?: boolean }>`
  width: ${(props) => (props?.isModal ? '85%' : '75%')};
  max-width: 500px;

  .ant-carousel .slick-slider {
    height: 250px !important;
  }

  .ant-carousel .slick-slider .slick-track {
    height: 250px !important;
  }

  .slick-slide {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  @media (max-width: 900px) {
    .ant-carousel .slick-slider {
      height: 250px !important;
    }

    .ant-carousel .slick-slider .slick-track {
      height: 250px !important;
    }
  }
`;

const AttachmentImage = styled.div`
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 250px;
  margin: 15px auto;

  img {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 250px;
  }
`;

const AttachmentVideo = styled.div`
  margin: 15px auto;
  width: 100%;
  max-width: 500px;

  video {
    width: 100%;
    height: 100%;
  }
`;
