import React, { useState } from 'react';
import styled from 'styled-components';
import { Context } from 'graphql-clientgen';
import { message, Modal } from 'antd';
import { Former } from '@digi-tim-19/components';

import { useClient } from '../../autogenerated/client/client';

const FormWrapper = styled.div``;

type TUserEditProfileProps = {
  hasEmailSecondary: boolean;
};

export const UserEmailSecondary = (props: TUserEditProfileProps) => {
  const UserFillEmailSecondary = useClient('UserFillEmailSecondary');

  const [visible, setVisible] = useState(!props.hasEmailSecondary);

  return (
    <Modal
      title="Cadastre um email alternativo"
      visible={visible}
      footer={[]}
      closable={false}
    >
      <FormWrapper>
        <Former
          config={{
            fields: [
              {
                name: 'emailSecondary',
                label: 'Email alternativo',
                type: 'email'
              }
            ],
            submitButton: {
              label: 'Salvar'
            }
          }}
          onSubmit={async ({ data }) => {
            const { emailSecondary } = data;

            const handleFetch = (ctx: Context<any>) => {
              if (ctx.result) {
                message.success('Email alternativo atualizado com sucesso');
                setVisible(false);
              } else {
                message.error('Ocorreu um erro ao cadastrar seu email');
                console.error('ctx', ctx.errors);
              }
            };

            UserFillEmailSecondary.fetch({
              variables: { emailSecondary },
              afterMutate: /^User/
            }).then(handleFetch);
          }}
        />
      </FormWrapper>
    </Modal>
  );
};
