import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';
import {
  Former,
  usePublico,
  LabelSelecionarPublico
} from '@digi-tim-19/components';
import { EnumFileKind } from '@digi-tim-19/components/build/main/DataProviders/types';
import { useDimensions } from '@digi-tim-19/utils';

import { EnumMaterialKind, Material } from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';
import { routes } from '../../config/routes';
import { RegionalProfilesField } from '../../components/RegionalProfilesSelection/RegionalProfilesField';
import { validateSelectPublic } from '../../utils/validateSelectPublic';

import { Container } from './styles';

const configPage = {
  kind: EnumMaterialKind.News,
  kindFile: EnumFileKind.AnyImage,
  kindThumb: EnumFileKind.CardThumb,
  mailing: EnumFileKind.Mailing,
  routeList: routes.noticiasListar.mount(),
  messageSuccess: 'Notícia cadastrada com sucesso',
  messageError: 'Notícia não cadastrada'
};

export const Form = (props: TFormMaterialEditProps) => {
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } =
    usePublico();
  const history = useHistory();
  const { isMobile } = useDimensions();
  const isUpdate = !!props.initialValues;

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  const [publicSelected, setPublicSelected] = useState(false);

  const initialValues = useMemo(() => {
    const files = props.initialValues?.files || [];

    const thumbnail = files.find(
      (el) => el?.kind === configPage.kindThumb
    )?._id;
    const documento = files?.find(
      (el) => el?.kind === configPage.kindFile
    )?._id;
    const fileMailing = files.find(
      (el) => el?.kind === configPage.mailing
    )?._id;
    const attachment = files.find(
      (el) => el?.kind === EnumFileKind.AnyDoc
    )?._id;

    return {
      ...props.initialValues,
      documento,
      thumbnail,
      attachment,
      validityStart: props.initialValues?.validity?.start,
      mailing: fileMailing
    };
  }, []);

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              //primeira linha de campos
              inline: !isMobile,
              list: [
                {
                  name: 'validityStart',
                  label: 'Programar publicação',
                  type: 'datePicker',
                  required: true,
                  extraProps: {
                    format: 'DD/MM/YYYY HH:mm',
                    showTime: { format: 'HH:mm' },
                    noBeforeDate: true
                  },
                  maxWidth: isMobile ? '100%' : '250px'
                },
                {
                  name: 'thumbnail',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: configPage.kindThumb,
                    CTA: 'Imagem do card',
                    allowedExtensions: ['image/png', 'image/jpeg']
                  }
                },
                {
                  name: 'documento',
                  label: '',
                  type: 'upload',
                  required: false,
                  extraProps: {
                    kind: configPage.kindFile,
                    CTA: 'Imagem da página'
                  }
                },
                {
                  name: 'attachment',
                  label: '',
                  type: 'upload',
                  required: false,
                  extraProps: {
                    kind: EnumFileKind.AnyDoc,
                    CTA: 'PDF, imagem ou vídeo',
                    allowedExtensions: [
                      'image/png',
                      'image/jpeg',
                      'application/pdf',
                      'video/mp4'
                    ]
                  }
                }
              ]
            },
            {
              //quarta linha de campos
              list: [
                {
                  name: 'title',
                  label: 'Título',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value))
                      return 'Os caracteres #%&; não são permitidos';

                    if (value) {
                      return !(value.length > 110)
                        ? undefined
                        : 'Máximo de 110 caracteres';
                    }

                    return undefined;
                  }
                },
                {
                  name: 'description',
                  label: 'Descrição',
                  type: 'texteditor',
                  extraProps: {
                    rows: 5
                  },
                  validate: (value: any) => {
                    const format = /[#%&]/;
                    if (format.test(value))
                      return 'Os caracteres #%& não são permitidos';

                    if (value) {
                      return !(value.length > 1200)
                        ? undefined
                        : 'Máximo de 1200 caracteres';
                    }

                    return undefined;
                  }
                }
              ]
            },
            {
              //quarta linha de campos
              list: [
                {
                  name: 'tags',
                  label: 'Palavra-chave',
                  type: 'tags'
                }
              ]
            },
            {
              //quinta linha de campos
              className: 'Footer',
              inline: true,
              list: [
                {
                  custom: (
                    <RegionalProfilesField
                      fieldConfig={{
                        initialValue:
                          props.initialValues?.availableAtRegionalProfiles
                      }}
                    />
                  )
                }
              ]
            },
            publicSelected && {
              custom: <LabelSelecionarPublico />
            }
          ],
          submitButton: {
            label: isUpdate ? 'Atualizar' : 'Cadastrar',
            onClick: (formerRef: any) => {
              setPublicSelected(validateSelectPublic(formerRef));
            }
          },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(configPage.routeList)
          }
        }}
        onSubmit={async ({ data }: any) => {
          if (!data.availableAtRegionalProfiles) {
            return setPublicSelected(true);
          }
          const fileIds = [
            data?.documento,
            data.thumbnail,
            data.mailing,
            data.attachment
          ]
            .filter((item) => item !== undefined)
            .filter((item) => item !== '');

          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            availableAtRegionalProfiles: data.availableAtRegionalProfiles,
            validity: {
              start: data.validityStart
            },
            fileIds: fileIds,
            title: data.title,
            description: data.description,
            tags: data.tags,
            kind: configPage.kind,
            categories: ['menu:para_voce:comunicacao:noticias'].filter(Boolean)
          };

          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(configPage.routeList);
              } else {
                console.error('Erro ao atualizar', ctx.errors);
              }
            });
          } else {
            await createMaterial
              .fetch({ variables: { record } })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success(configPage.messageSuccess);
                  history.push(configPage.routeList);
                } else {
                  console.error('errors', ctx.errors);
                  message.error(configPage.messageError);
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
