import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { FormInformativos } from '../FormInformativos/FormInformativos';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { InformativosWrapper } from './stylesCadastrar';
import { routes } from '../../../config/routes';

export const InformativosCadastrar = () => {
  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: 'home' },
            { title: 'Gerenciar conteúdo', link: '#' },
            {
              title: 'Informativos',
              link: routes.informativosListar.mount()
            },
            { title: 'Cadastrar', link: routes.informativosCadastrar.mount() }
          ]}
        />
      }
    >
      <>
        <InformativosWrapper>
          <TitleDescription
            iconType="info-circle"
            title="Informativos"
            description="Cadastramento e consulta de Informativos"
          />
        </InformativosWrapper>

        <ProviderSelecionarPublico>
          <FormInformativos />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
