import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  Table
} from '@digi-tim-19/components';

import { PageTemplate } from 'components/Layout/PageTemplate';
import { Image } from 'components/Image/Image';
import { useEvent } from '../hooks/useEvent';
import { routes } from 'config/routes';
import moment from 'moment';
import { Tabs } from 'antd';

import { SectionTitle, CampaignBanner } from '../styles';
import { ImageFolderView } from 'components/ImageFolderView/ImageFolderView';
import styled from 'styled-components';
import { verdade } from '@digi-tim-19/utils';
import { groupBy } from 'lodash';
import imageCard from '../../../assets/images/image_card.png';
import { EmptyContainer } from 'components/TablePagination/EmptyContainer';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { Feed } from '../Feed';

const configPage = {
  pageName: 'Evento',
  icon: 'usergroup-add',
  description: '',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Eventos', link: routes.eventos.mount() },
    { title: 'Evento', link: '' }
  ]
};

const generalInfo = [
  { key: 'restaurantes', label: 'Restaurantes' },
  { key: 'dicas', label: 'Dicas' },
  { key: 'pontos', label: 'Pontos Turísticos' },
  { key: 'hospedagem', label: 'Hospedagem' },
  { key: 'telefones', label: 'Telefones Úteis' }
];

export const PageLoadEventView = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const { event, loading } = useEvent(params.id);

  const notificationData = useMemo(() => {
    const items = verdade(event?.notifications)
      .map((el: any) => ({
        label: el.sentAt
          ? moment(el.sentAt).format('DD/MM/YYYY')
          : 'não enviada',
        tituloCard: `${el.title}`,
        descricao: `${el.body}`,
        imgBody: `${el?.iconUrl || imageCard}`
      }))
      .filter((el) => el.label !== 'não enviada');

    return groupBy(items, 'label');
  }, [event]);

  const isEmptyDescription = (description: any) => {
    if (!description) return true;
    return (
      String(description)
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/(\r\n|\n|\r)/gm, '')
        .trim() !== ''
    );
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {loading && <LoadingIndicator />}

        {event && (
          <>
            <SectionTitle id={'INICIO'}>
              {`Evento: ${event?.title}`}
              <div style={{ fontSize: '16px' }}>
                {`Período: ${moment(event?.validity?.start).format(
                  'LLL'
                )} até ${moment(event?.validity?.end).format('LLL')}`}
              </div>
            </SectionTitle>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginBottom: '20px',
                flexWrap: 'wrap',
                gap: '15px'
              }}
            >
              <a href="#INICIO">INÍCIO</a>
              <a href="#DESTINO">DESTINO</a>
              <a href="#DESCRICAO">DESCRIÇÃO</a>
              <a href="#AGENDA">AGENDA</a>
              <a href="#NOTIFICACAO">NOTIFICAÇÃO</a>
              <a href="#MURAL">MURAL</a>
              <a href="#GALERIA">GALERIA</a>
              {/* <a href="#PESQUISA">PESQUISA</a> */}
            </div>

            {event?.bannerContent && (
              <CampaignBanner>
                <Image
                  href={event.bannerContent.signedUrl || undefined}
                  src={event.bannerContent.signedUrl || ''}
                  alt={event.title || ''}
                />
              </CampaignBanner>
            )}

            <SectionTitle id={'DESCRICAO'}>Descrição</SectionTitle>
            {isEmptyDescription(event?.description) ? (
              <div dangerouslySetInnerHTML={{ __html: event.description! }} />
            ) : (
              <EmptyContainer message="Não há descrição para exibir" />
            )}

            <SectionTitle id={'DESTINO'}>Guia de destino</SectionTitle>

            {event?.guides?.generaldata || event?.guides?.utilData ? (
              <Tabs>
                <Tabs.TabPane key={'general_info'} tab={'Informações Gerais'}>
                  <Tabs>
                    {generalInfo.map((el) => (
                      <Tabs.TabPane key={el.key} tab={el.label}>
                        <TablePagination
                          columns={[
                            {
                              title: 'Título',
                              dataIndex: 'title',
                              key: 'title',
                              width: '20%'
                            },
                            {
                              title: 'Descrição',
                              dataIndex: 'description',
                              key: 'description'
                            }
                          ]}
                          dataSource={
                            event?.guides?.generaldata[el.key]?.filter(
                              (el: any) => el.isActive
                            ) || []
                          }
                        />
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </Tabs.TabPane>

                <Tabs.TabPane key={'util_info'} tab={'Informações Úteis'}>
                  <TablePagination
                    columns={[
                      {
                        title: 'Título',
                        dataIndex: 'title',
                        key: 'title',
                        width: '20%'
                      },
                      {
                        title: 'Descrição',
                        dataIndex: 'description',
                        key: 'description'
                      }
                    ]}
                    dataSource={
                      event?.guides?.utilData?.filter(
                        (el: any) => el.isActive
                      ) || []
                    }
                  />
                </Tabs.TabPane>
              </Tabs>
            ) : (
              <EmptyContainer />
            )}

            <SectionTitle id={'AGENDA'}>Agenda</SectionTitle>

            {event.schedulles && Array.isArray(event.schedulles) ? (
              event.schedulles.map((schedulle: any) => {
                const dataSource =
                  schedulle?.data?.filter((el: any) => el.time !== '') || [];

                return (
                  <Tabs
                    style={{
                      marginBottom: '15px'
                    }}
                  >
                    <Tabs.TabPane key={schedulle._id} tab={schedulle?.date}>
                      <TablePagination
                        columns={[
                          {
                            title: 'Horário',
                            dataIndex: 'time',
                            key: 'time',
                            width: '20%'
                          },
                          {
                            title: 'Programação',
                            dataIndex: 'description',
                            key: 'description'
                          }
                        ]}
                        dataSource={dataSource}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                );
              })
            ) : (
              <EmptyContainer />
            )}

            <SectionTitle id={'NOTIFICACAO'}>Notificações</SectionTitle>

            {Boolean(Object.keys(notificationData).length) ? (
              <NotificationContainer>
                {Object.keys(notificationData).map((el: string) => (
                  <EventNotificationGroup key={el}>
                    <span>{el}</span>
                    <div
                      style={{ width: '100%', overflowY: 'scroll', gap: '4px' }}
                    >
                      {notificationData[el].map((el) => {
                        return <NotificationItem key={el.tituloCard} {...el} />;
                      })}
                    </div>
                  </EventNotificationGroup>
                ))}
              </NotificationContainer>
            ) : (
              <EmptyContainer message="Não há notificações para exibir" />
            )}

            <SectionTitle id={'MURAL'}>Mural - Feed</SectionTitle>

            <Feed eventId={event?._id} />

            <SectionTitle id={'GALERIA'}>Galeria</SectionTitle>

            {event.gallery ? (
              <Tabs>
                {event.gallery.map((el: any) => (
                  <Tabs.TabPane key={el._id} tab={el.title}>
                    <ImageFolderView fileIds={el.imageIds || []} />
                  </Tabs.TabPane>
                ))}
              </Tabs>
            ) : (
              <EmptyContainer />
            )}

            {/* <SectionTitle id={'PESQUISA'}>Pesquisas</SectionTitle> */}
          </>
        )}
      </>
    </PageTemplate>
  );
};

interface INotificationItem {
  label: string;
  tituloCard: string;
  descricao: string;
  imgBody: string;
}

const NotificationItem = (props: INotificationItem) => {
  return (
    <NotificationCard>
      <img src={props.imgBody} style={{ width: '25%' }} />
      <div className="info">
        <NotificationLbl>{props.tituloCard}</NotificationLbl>
        <NotificationTxt>{props.descricao}</NotificationTxt>
      </div>
    </NotificationCard>
  );
};

const NotificationCard = styled.div`
  padding: 8px;
  border-radius: 8px;
  display: flex;
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.gray1};

  .info {
    width: 75%;
    padding-left: 4px;
    max-height: 80px;
    overflow: scroll;
  }
`;

const NotificationLbl = styled.span`
  color: red;
`;

const NotificationTxt = styled.p`
  overflow-wrap: break-word;
`;

const NotificationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  height: 400px;
  overflow-y: scroll;
`;

const EventNotificationGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  max-width: 30%;
  min-width: 320px;
  height: 360px;
  background-color: ${({ theme }) => theme.gray0};

  & > span {
    padding: 2px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.blue};
  }
`;
