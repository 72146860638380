import React, { useState } from 'react';
import { Modal, Button, Tabs } from 'antd';
import { Former } from '@digi-tim-19/components';
import { Event } from 'autogenerated/client/types';
import styled from 'styled-components';

export const defaultMails: Record<string, string> = {
  prePublishMail: `
    Olá Parceiro!\nVocê foi convidado a participar do Evento Convenção de Venda.\nClique abaixo, e acesse no Pra Conectar todas as informações sobre o Evento.\n\nAtenciosamente,\nEquipe Pra Conectar
  `,
  onUpdateMail: `
    Olá Parceiro!\nHá novidades publicadas em Galeria do evento Convenção de Venda.\nClique abaixo, e acesse o Pra Conectar para consultar as atualizações do evento.\n\nAtenciosamente,\nEquipe Pra Conectar
    `,
  onPublishMail: `
    Olá Parceiro!\nNão esqueça, o evento Convenção de Venda começa no próximo dia DD/MM/AAAA!\nMarque em sua agenda, e clique abaixo, para consultar no  Pra Conectar toda a programação do evento.\n\nAtenciosamente,\nEquipe Pra Conectar
    `
};

interface IModalEventMailing {
  initialValue?: Event;
  onConfirm: (data: Record<string, string>) => void;
}

export const ModalEventMailing = (props: IModalEventMailing) => {
  const [visible, setVisible] = React.useState(false);
  const toogleVisible = () => setVisible((state) => !state);

  const { initialValue } = props;
  const [mails, setMails] = useState<Record<string, string>>(() =>
    initialValue
      ? {
          onUpdateMail: `${initialValue?.onUpdateMail}`,
          onPublishMail: `${initialValue?.onPublishMail}`,
          prePublishMail: `${initialValue?.prePublishMail}`
        }
      : defaultMails
  );

  const former = (tab: string) => {
    return (
      <Former
        initialValues={initialValue}
        config={{
          fields: [
            {
              name: tab,
              defaultValue: defaultMails[tab],
              label: '',
              type: 'texteditor',
              extraProps: {
                onChange: (mail: string) =>
                  setMails((mails) => ({ ...mails, [tab]: mail }))
              }
            }
          ],
          submitButton: { label: 'Ok' }
        }}
        onSubmit={async () => {
          props.onConfirm(mails);
          toogleVisible();
        }}
      />
    );
  };

  return (
    <>
      <ButtonContainer>
        <Button type={'primary'} onClick={toogleVisible}>
          Cadastro de Comunicados
        </Button>
      </ButtonContainer>
      <Modal
        width={'82vw'}
        closable={false}
        visible={visible}
        onOk={toogleVisible}
        onCancel={toogleVisible}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Tabs defaultActiveKey="prePublishMail">
          <Tabs.TabPane tab="Evento Programado" key="prePublishMail">
            {former('prePublishMail')}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Atualização Componente" key="onUpdateMail">
            {former('onUpdateMail')}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Início Evento" key="onPublishMail">
            {former('onPublishMail')}
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

const ButtonContainer = styled.div`
  width: 100%;
`;
