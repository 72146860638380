import React from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  LoadingIndicator,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { UpdateByIdEventInput } from '../../../autogenerated/client/types';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { useEvent } from '../hooks/useEvent';
import { Form } from '../FormManage';
import { message } from 'antd';

const configPage = {
  pageName: 'Eventos',
  icon: 'inbox',
  description: 'Consulta e cadastramento de eventos.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Eventos', link: routes.eventosGerenciar.mount() },
    { title: 'Editar', link: '' }
  ],
  routeList: routes.documentosGerenciar.mount()
};

export const EventosEditar = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string }>();
  const { event, loading } = useEvent(params.id);
  const client = useClient('EventUpdateById', { afterMutate: /^Event/ });

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {loading && <LoadingIndicator />}

        {event && (
          <>
            <ProviderSelecionarPublico>
              <Form
                initialValues={event}
                onCancel={() => history.push(routes.eventosGerenciar.mount())}
                onSubmit={(record) => {
                  client
                    .fetch({
                      variables: { record: record as UpdateByIdEventInput }
                    })
                    .then((ctx) => {
                      if (!ctx.errors) {
                        message.success('Evento editado com sucesso');
                        history.push(routes.eventosGerenciar.mount());
                      } else {
                        message.success('Erro ao atualizar evento');
                      }
                    });
                }}
              />
            </ProviderSelecionarPublico>
          </>
        )}
      </>
    </PageTemplate>
  );
};
