import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Former,
  usePublico,
  LabelSelecionarPublico
} from '@digi-tim-19/components';

import {
  EnumFileKind,
  EnumMaterialKind
} from '../../autogenerated/client/types';
import { message } from 'antd';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';
import { Container, Divider } from './Cadastrar/stylesCadastrar';
import { useClient } from '../../autogenerated/client/client';
import { Material } from '../../autogenerated/client/types';
import { RegionalProfilesField } from '../../components/RegionalProfilesSelection/RegionalProfilesField';
import { validateSelectPublic } from '../../utils/validateSelectPublic';

const WrapperLabel = styled.div`
  margin-top: -30px;
  display: flex;
  flex: auto;
`;

const ImageKind = EnumFileKind.CardThumb;

export const Form = (props: TFormMaterialEditProps) => {
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } =
    usePublico();
  const history = useHistory();

  const [publicSelected, setPublicSelected] = useState(false);

  const isUpdate = !!props.initialValues;

  const initialValues = {
    ...props.initialValues,
    thumbnail: (props.initialValues?.files || []).find(
      (el) => el && el.kind === ImageKind
    )?._id,
    category: (props.initialValues?.categories || []).find((el) => el && el._id)
      ?._id
  };

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  // const getCategoriesLinksUteis = useClient('CategoryFindMany', {
  //   fetchOnMount: true,
  //   variables: { limit: 123, filter: { parentId: "links_uteis" } }
  // });

  // const categoryLinksUteis: any = getCategoriesLinksUteis?.result?.map(item => ({
  //   label: item?.name?.toUpperCase(),
  //   value: item?._id
  // }));

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              list: [
                {
                  name: 'thumbnail',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: ImageKind,
                    CTA: 'IMPORTAR THUMB',
                    allowedExtensions: ['image/png', 'image/jpeg']
                  }
                }
              ]
            },
            // {
            //   list: [
            //     {
            //       name: 'category',
            //       label: 'CATEGORIA',
            //       options: categoryLinksUteis,
            //       required: true
            //     }
            //   ]
            // },
            {
              list: [
                {
                  name: 'title',
                  label: 'TÍTULO',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value))
                      return 'Os caracteres #%&; não são permitidos';

                    if (value) {
                      return !(value.length > 110)
                        ? undefined
                        : 'Máximo de 110 caracteres';
                    }

                    return undefined;
                  }
                },
                {
                  name: 'description',
                  label: 'LINK',
                  validate: (value: any) => {
                    if (value) {
                      return !(value.length > 1200)
                        ? undefined
                        : 'Máximo de 1200 caracteres';
                    }

                    return undefined;
                  }
                }
              ]
            },
            {
              name: 'tags',
              label: 'Palavra-chave',
              type: 'tags'
            },
            {
              custom: <Divider />
            },
            {
              className: 'Footer',
              inline: true,
              list: [
                {
                  custom: (
                    <RegionalProfilesField
                      fieldConfig={{
                        initialValue:
                          props.initialValues?.availableAtRegionalProfiles
                      }}
                    />
                  )
                }
              ]
            },
            publicSelected && {
              custom: (
                <WrapperLabel>
                  <LabelSelecionarPublico />
                </WrapperLabel>
              )
            }
          ],
          submitButton: {
            label: isUpdate ? 'Atualizar' : 'Cadastrar',
            onClick: (formerRef: any) => {
              setPublicSelected(validateSelectPublic(formerRef));
            }
          },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.linksUteisListar.path)
          }
        }}
        onSubmit={async function ({ data }) {
          if (!data.availableAtRegionalProfiles) {
            return setPublicSelected(true);
          }

          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            availableAtRegionalProfiles: data.availableAtRegionalProfiles,
            // categories: [data.category],
            kind: EnumMaterialKind.LinksUteis,
            fileIds: [data.thumbnail].filter(Boolean),
            title: data.title,
            tags: data.tags,
            description: data.description
          };
          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(routes.linksUteisListar.mount());
              }
            });
          } else {
            await createMaterial
              .fetch({
                variables: { record }
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success('Link cadastrado com sucesso');
                  history.push(routes.linksUteisListar.path);
                } else {
                  message.error('Link não cadastrado');
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
