import styled from 'styled-components';
import { ShareModal } from '@digi-tim-19/components';

export const ShareModalStyle = styled(ShareModal)`
  height: auto;
`;

export const StyledContent = styled.section`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
`;

export const OrderWrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const ChooseVisualWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  i {
    padding-right: 20px;
  }
`;

export const CardsVisual = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .card-header {
    height: 80px;
  }

  & > div {
    align-items: flex-end;
  }

  @media screen and (min-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;
