import React from 'react';
import { Search } from './stylesGerenciar';

const SearchEngine: React.FC<TSeachProps> = ({
  setValue,
  placeholder = 'Busca título ou ID'
}) => {
  let time: number = 0;
  const handleSearch = (value: string) => {
    clearTimeout(time);
    //@ts-ignore
    time = setTimeout(() => {
      setValue(value);
    }, 1000);
  };
  return (
    <Search
      placeholder={placeholder}
      style={{ width: 200 }}
      onChange={(e) => handleSearch(e.target.value)}
    />
  );
};

export default React.memo(SearchEngine);

type TSeachProps = {
  setValue: (a: string) => void;
  placeholder?: string;
};
