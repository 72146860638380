import { useClient } from 'autogenerated/client/client';

export const useUpdateLike = () => {
  const client = useClient('FeedUpdateLike');
  const likeUpdate = async (_id: string): Promise<string[]> => {
    const { result } = await client.fetch({ variables: { _id } });
    return result?.likes || [];
  };
  return { likeUpdate, loading: client.loading };
};
