import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { useRegionalProfiles } from './useRegionalProfiles';
import { Tabs, Tree } from 'antd';
import { LoadingIndicator } from '@digi-tim-19/components';

const { TabPane } = Tabs;

const AudienceSelectionWrapper = styled.div`
  width: 100%;
  &.error {
    border: solid 1px red;
  }
`;

export const RegionalProfilesSelection = (props: TAudienceSelectionProps) => {
  const { className } = props;
  const defaultChecked = props?.defaultChecked || [];
  const initialSelection = props?.initialSelection || [];

  const { tabs, channelIds, loading } = useRegionalProfiles(
    defaultChecked,
    Boolean(props?.readonly)
  );
  const [expandedKeys, setExpandedKeys] = React.useState<any[]>([
    'selecionarTodos'
  ]);

  const controller = useController(
    [...initialSelection, ...defaultChecked],
    channelIds,
    props.onChange
  );

  return (
    <AudienceSelectionWrapper
      className={cx(
        className,
        'ui-flex AudienceSelection AudienceSelection_Wrapper'
      )}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Tabs type="card">
          {tabs.map((tab) => {
            const selected = controller.get(tab.channelId);
            //const count = selected.length || 0;
            const title = tab.title;

            const defaultSelectedRole: string[] = defaultChecked
              .filter((el) => selected.includes(el))
              .map((el) => {
                const [, roleGroup] = el.split('#');
                return roleGroup;
              });

            return (
              <TabPane tab={title} key={tab.channelId}>
                <Tree
                  disabled={props?.disabled || props?.readonly}
                  checkable
                  treeData={tab.tree}
                  onCheck={function (value) {
                    if (Array.isArray(value)) {
                      setExpandedKeys(addExpandedKey(expandedKeys, value));
                      controller.set(value, tab.channelId);
                    }
                  }}
                  checkedKeys={[...selected, ...defaultSelectedRole]}
                  expandedKeys={expandedKeys}
                  onExpand={(keys, { expanded, node }) => {
                    if (expanded) {
                      setExpandedKeys(addExpandedKey(expandedKeys, keys));
                    } else {
                      const key = node.props.eventKey || '';
                      setExpandedKeys(removeExpandedKey(expandedKeys, key));
                    }
                  }}
                />
              </TabPane>
            );
          })}
        </Tabs>
      )}
    </AudienceSelectionWrapper>
  );
};

export type TAudienceSelectionProps = {
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
  initialSelection?: string[];
  defaultChecked?: string[];
  onChange?: (selected: string[]) => any;
};

function valid(el: any) {
  return typeof el === 'string' && el.indexOf('#') !== -1;
}

function useController(
  defaultSelected: string[] = [],
  channelIds: string[],
  onChange?: (items: string[]) => any
) {
  const [items, _setItems] = React.useState(
    () => defaultSelected?.filter?.((el) => typeof el === 'string') || []
  );

  function setItems(items: string[]) {
    const filtered = items.filter(valid);
    _setItems(filtered);
    onChange?.(filtered);
  }

  const byChannel = React.useMemo(() => {
    const items: { [key: string]: Set<string> } = {};

    if (!channelIds.length) {
      // quando channelIds ainda esta fetching
      return items;
    }

    channelIds.forEach(function (item) {
      items[item] = new Set();
    });

    const filteredItems = defaultSelected.filter((el) => {
      const [, roleGroup] = el.split('#');
      const parts = roleGroup.split(':');
      const isDirectChild = parts.length === 2; // filho direto do superCanal, nao tem sub channel
      const parent = isDirectChild ? parts[0] : parts.slice(0, 2).join(':');
      items[parent]?.add(el);

      if (items[parent]) {
        return el;
      }
    });

    setItems(filteredItems);
    return items;
  }, [defaultSelected.length, channelIds.length]);

  function set(items: string[], channelId: string) {
    if (!byChannel[channelId]) {
      // quando channelIds ainda esta fetching
      return;
    }

    byChannel[channelId].clear();

    items.forEach((item) => {
      byChannel[channelId].add(item);
    });

    const all: string[] = [];
    channelIds.forEach(function (id) {
      all.push(...byChannel[id].values());
    });

    setItems(all);
  }

  function get(channelId: string) {
    if (!byChannel[channelId]) return [];
    const values = byChannel[channelId].values();
    return [...values].filter(valid);
  }

  return {
    set,
    get,
    byChannel,
    items
  };
}

/**
 * Método para adicionar itens no array `expandedKeys` para abrir a caixinha do seleção
 * @param arrayKeys array do estado a ser alterado
 * @param key item ou items a serem inseridos
 */
function addExpandedKey(arrayKeys: string[], key: string | string[]) {
  return arrayKeys.concat(key);
}

/**
 * Método para remover itens no array `expandedKeys` para fechar a caixinha do seleção
 * @param arrayKeys array do estado a ser alterado
 * @param key item a ser removido do array
 */
function removeExpandedKey(arrayKeys: string[], key: string) {
  const result = arrayKeys.filter((x) => x !== key);

  return result;
}
