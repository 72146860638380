import styled from 'styled-components';

import { FormContainer } from '../../components/Former';
import { Button } from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';

export const DocumentosWrapper = styled.div``;

export const ChooseVisualWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 40px;
  width: 100%;

  @media screen and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  i {
    padding-right: 20px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  width: 100%;
`;

export const FormWrapper = styled(FormContainer as any)`
  .form-section-container {
    padding: 25px 20px 0px 20px;

    .header {
      flex-wrap: wrap !important;

      .component-upload {
        display: flex;
        flex-direction: column;
      }

      .name-validity {
        .ant-calendar-picker {
          width: 100% !important;
        }
      }

      > .FieldWrapper {
        margin-left: 20px;
        margin-right: 0 !important;
        &:first-of-type {
          margin: 0;
        }

        @media screen and (max-width: 768px) {
          margin: 0 0 30px !important;
        }
      }
    }

    .name-mailing .ant-btn {
      color: #fff;
    }

    .Footer {
      > div {
        justify-content: flex-start;
      }
    }
  }

  .content-buttons {
    flex-basis: 0 !important;
  }

  .name-documento,
  .name-thumbnail {
    > span {
      display: flex;

      .anticon-loading {
        display: none;
      }
      .ant-upload-list-item-progress {
        display: none;
      }
      .anticon-close {
        right: -2px;
      }
    }
  }

  .type-switch {
    margin-bottom: 20px;
  }
`;

export const WrapperLabel = styled.div`
  margin-top: -30px;
  display: flex;
  flex: auto;
  color: #fff;
`;

export const NotFound = styled.p`
  color: #6c757d;
  font-size: 18px;
  text-align: center;
  width: 100%;
`;

export const CampaignSortSelect = styled.div`
  margin-bottom: 60px;
  max-width: 200px;

  @media (max-width: 1125px) {
    max-width: 100%;
  }
`;

export const SectionTitle = styled.h3`
  color: ${colors.blue};
  font-size: 24px;
  border-bottom: 1px solid ${colors.blue};
`;

export const CampaignBanner = styled.div`
  align-items: center;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  img {
    height: auto;
    max-height: 400px;
    max-width: 1145px;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
  margin: 20px auto;
  justify-content: center;

  @media (min-width: 768px) {
    max-width: 1145px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const SocialButtons = styled.div`
  margin: 10px 0;

  > div {
    justify-content: flex-start;
  }
`;

export const CampaignFAQ = styled.div`
  height: auto;
  padding: 15px;
  margin: 20px 0 30px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
`;

export const ContestButton = styled(Button)`
  background: #fe0326;
  font-size: 15px;
  font-weight: bold;
  margin: 1em 0;

  :hover,
  :focus {
    background: #fe0326;
    border: 2px solid ${(props) => props.theme.blue};
  }
`;
