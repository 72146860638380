import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { RegulamentoWrapper } from './stylesCadastrar';

export const LinksUteisCadastrar = () => {
  return (
    <PageTemplate>
      <>
        <RegulamentoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: 'home' },
              { title: 'Gerenciar conteúdo', link: '#' },
              { title: 'Links Úteis', link: '#' },
              { title: 'Cadastrar', link: '#' }
            ]}
          />
          <TitleDescription
            iconType="link"
            title="Links Úteis"
            description="Cadastramento e consulta de links úteis."
          />
        </RegulamentoWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
