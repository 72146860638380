import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { PageWrapper } from './styles';
import { FormAnswer } from './FormAnswer';

export const ResearchAnswer = () => {
  const configPage = {
    pageName: 'Responder pesquisa',
    icon: 'schedule',
    description: 'Responder e/ou consultar pesquisa',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Pesquisas', link: routes.researchListar.mount() },
      { title: 'Responder' }
    ]
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <React.Fragment>
        <PageWrapper>
          <TitleDescription
            iconType={'schedule'}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        <ProviderSelecionarPublico>
          <FormAnswer />
        </ProviderSelecionarPublico>
      </React.Fragment>
    </PageTemplate>
  );
};
