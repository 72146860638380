import React from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  ProviderSelecionarPublico,
  LoadingIndicator
} from '@digi-tim-19/components';

import { Form } from '../FormManage';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { OnTubeCadastroWrapper } from '../Cadastrar/stylesCadastrar';
import { useClient } from '../../../autogenerated/client/client';
import { ValidityEnum } from '../../../autogenerated/client/types';
import { routes } from '../../../config/routes';

export const OnTubeEditar = () => {
  const { params } = useRouteMatch();
  const { id } = params as any;
  const appendToFragment = `
    _id
    title
    description
    enableSocialInteraction
    categories {
      _id
      name
    }

    fileIds
    files {
      _id
      title
      kind
    }
    
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups

    validity {
      start
      end
    }
  `;

  const OnTubeItem = useClient('MaterialFindById', {
    fetchOnMount: true,
    variables: {
      _id: id,
      filter: { validityEnum: ValidityEnum.Any, includeDrafts: true }
    },
    appendToFragment
  });

  const OnTubeNotification = useClient('NotificationFindOne', {
    fetchOnMount: true,
    variables: {
      filter: { parentId: id }
    }
  });

  const validity = OnTubeItem.result?.validity;
  const initialValues: any = {
    ...OnTubeItem.result,
    validity: [
      validity ? new Date(validity.start) : null,
      validity && validity.end ? new Date(validity.end) : null
    ]
  };

  return (
    <PageTemplate>
      <OnTubeCadastroWrapper>
        <Breadcrumb
          items={[
            { title: 'Home', link: '/' },
            { title: 'Gerenciar conteúdo', link: '#' },
            { title: 'Vídeos', link: routes.videosListar.mount() },
            { title: 'Editar', link: '#' }
          ]}
        />
        <TitleDescription
          iconType="play-square"
          title="Videos"
          description="Editar vídeo"
        />
        {OnTubeItem.result && !OnTubeNotification.loading ? (
          <ProviderSelecionarPublico>
            <Form
              initialValues={initialValues}
              notification={OnTubeNotification.result || undefined}
            />
          </ProviderSelecionarPublico>
        ) : (
          <LoadingIndicator />
        )}
      </OnTubeCadastroWrapper>
    </PageTemplate>
  );
};
