import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { message } from 'antd';
import {
  Former,
  SearchUser,
  LabelSelecionarPublico,
  Button,
  useCurrentUser
} from '@digi-tim-19/components';
import { EnumEmailTemplateName } from '@digi-tim-19/components/build/main/DataProviders/types';
import { EmailProps } from '@digi-tim-19/components/build/main/TemplatesEmail/TemplatesEmailProps';
import { useDimensions, clickDownload } from '@digi-tim-19/utils';
import {
  CreateOneMaterialInput,
  EnumFileKind,
  EnumMaterialCommunicationItemsConfigKind,
  EnumMaterialKind,
  Material,
  MaterialCommunicationItemsConfig,
  EnumMaterialStatus
} from '../../../autogenerated/client/types';
import { RegionalProfilesField } from '../../../components/RegionalProfilesSelection/RegionalProfilesField';
import { Divider } from '../../../components/Former';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { communicationCanEdit } from 'utils/communicationCanEdit';
import { validateSelectPublic } from '../../../utils/validateSelectPublic';
import { useCategoryCoreVideo, useChannelsCategory } from 'hooks/categories';
import { Container } from '../Cadastrar/stylesCadastrar';
import { TemplateFields } from './TemplateFields';
import { HtmlEditor } from './HtmlEditor';
import { siteImages } from 'assets/images';
import { escapeStringRegexp } from 'utils/formatters';
import { isObjectId, userIsResidential } from 'utils/validations';
import { MaterialsSelectedField } from './MaterialsSelectedField';
import moment from 'moment';
import { UploadMailing } from 'components/UploadMailing';
import { ModalHeaderFooter } from './ModalHeaderFooter';

const WrapperLabel = styled.div`
  margin-top: -45px;
`;

const DocKind = EnumFileKind.Pdf;

enum Mode {
  html,
  template
}

type Options = { label: string; value: string; material: Partial<Material> }[];

export const fragmentBulletinMaterials = `
  _id
  title
  link
  kind
  html
  files {
    _id
    signedUrl
    downloadUrl
    extension
    kind
  }
`;

export const FormInformativos = (props: TFormMaterialEditProps) => {
  const user = useCurrentUser()?.result;
  const history = useHistory();
  const channelsOptions = useChannelsCategory();
  const categoriesOptions = useCategoryCoreVideo();
  const { isMobile } = useDimensions();
  const canEdit = communicationCanEdit(props.initialValues);
  const createMaterialCommunication = useClient('CommunicationCreateOne');
  const updateMaterialCommunication = useClient('CommunicationUpdateOne');
  const getMaterials = useClient('MaterialFindMany');
  const [publicSelected, setPublicSelected] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [mode, setMode] = useState<Mode>(Mode.template);
  const [downloadUrlAttachment, setDownloadUrlAttachment] = useState<any[]>();
  const isUpdate = !!props.initialValues;
  const defaultCategories = [
    'menu:para_voce:comunicacao:informativos',
    'menu:informativos'
  ];
  const isResidential = userIsResidential(user);

  const generateOptions = (values: any): Options => {
    return (
      values?.map((material: any) => {
        return { label: material.title, value: material._id, material };
      }) || []
    );
  };

  const getMaterialContent = async (value: any): Promise<Options> => {
    const { result } = await getMaterials.fetch({
      variables: {
        limit: 100,
        filter: {
          title:
            value === '' ? undefined : `regex(${escapeStringRegexp(value)})`,
          OR: [
            { status: EnumMaterialStatus.Approved },
            { status: EnumMaterialStatus.Published }
          ]
        }
      },
      fragment: fragmentBulletinMaterials
    });

    return generateOptions(result);
  };

  const initialValues = useMemo(() => {
    let email = props.initialValues?.email;

    const communicationItemsConfig =
      props.initialValues?.communicationItemsConfig;
    const html = email?.templateProps?.html;

    if (email?.templateName === EnumEmailTemplateName.PlainHtml) {
      setMode(Mode.html);
    }

    let IMAGE_SRC_1: any = null;
    const IMAGE_SRC_2 =
      email?.images?.IMAGE_SRC_2 || siteImages.bgTemplateEmail640x300;

    if (isUpdate) {
      if (
        email?.templateName &&
        [
          'templateCorp06',
          'templateCorp07',
          'templateCorp08',
          'templateCorp09',
          'templateCorp10'
        ].includes(email?.templateName)
      ) {
        IMAGE_SRC_1 =
          email?.images?.IMAGE_SRC_1 || siteImages.bgTemplateEmail600x900;
      } else {
        IMAGE_SRC_1 = email?.images?.IMAGE_SRC_1;
      }
    }

    const mailing =
      props.initialValues?.files?.find(
        (item) => item?.kind === EnumFileKind.Mailing
      )?._id || '';

    const templateProps = {
      ...email?.templateProps,
      IMAGE_SRC_1,
      IMAGE_SRC_2
    };

    let attachmentIds: any;

    if (email?.attachments && email?.attachments.length > 0) {
      attachmentIds = email?.attachments?.map((item) => item?._id);

      const fileItems = email?.attachments?.map((item) => {
        return {
          _id: item?._id,
          downloadUrl: item?.signedUrl,
          title: item?.title
        };
      });

      setDownloadUrlAttachment(fileItems);
    }

    let category = (props.initialValues?.categories || []).find(
      (el) => el && !defaultCategories.includes(el?._id)
    )?._id;

    if (
      (!category || defaultCategories.includes(category)) &&
      props.initialValues?.additionalCategories &&
      props?.initialValues?.additionalCategories?.length > 0
    ) {
      category = props.initialValues.additionalCategories[0] || undefined;
    }

    return {
      ...props.initialValues,
      documento: (props.initialValues?.files || []).find(
        (el) => el && el.kind === DocKind
      )?._id,

      category,

      kind: (communicationItemsConfig || []).find((el) => el)?.kind,

      templateName:
        !isUpdate && !isResidential ? 'templateCorp05' : email?.templateName,

      schedule: props.initialValues?.validity?.start,
      status: props.initialValues?.status,
      templateProps,
      html,
      mailing,
      attachmentIds,
      mailingApprovers: props.initialValues?.mailingApprovers || []
    };
  }, [props.initialValues?.email]);

  const isAwaiting =
    initialValues.status?.value === EnumMaterialStatus.WaitingApproval;

  return (
    <Container style={{ pointerEvents: canEdit ? 'auto' : 'none' }}>
      <Former
        initialValues={initialValues}
        config={({ form }) => {
          const kind = form.getFieldState('kind')?.value;
          form.registerField('bulletinMaterials', () => {}, {});
          form.registerField('mailing', () => {}, {});
          form.registerField('templateProps.HEADER', () => {}, {});
          form.registerField('templateProps.FOOTER', () => {}, {});
          const publishNow = form.getFieldState('publishNow')?.value
            ? true
            : false;

          return {
            fields: [
              {
                inline: !isMobile,
                list: [
                  {
                    name: 'publishNow',
                    label: 'Agendamento de publicação',
                    type: 'select',
                    required: true,
                    options: [
                      {
                        label: 'Agendar publicação',
                        value: false
                      },
                      {
                        label: 'Publicar agora',
                        value: true
                      }
                    ]
                  },
                  !publishNow && {
                    name: 'schedule',
                    label: 'Programar publicação',
                    type: 'datePicker',
                    required: true,
                    extraProps: {
                      showTime: { format: 'HH:mm' },
                      format: 'DD/MM/YYYY HH:mm',
                      noBeforeDate: !publishNow
                    },
                    maxWidth: publishNow ? '100%' : 'auto'
                  }
                ]
              },
              {
                inline: !isMobile,
                className: 'form-header',
                list: [
                  {
                    name: 'category',
                    label: 'Categoria do Menu',
                    options: categoriesOptions
                      ? [...categoriesOptions, ...additionalCategoriesOptions]
                      : [],
                    type: 'treeSelect',
                    required: true
                  },
                  {
                    name: 'kind',
                    label: 'Tipo ',
                    treeOptions: typeOptions,
                    type: 'treeSelect',
                    required: true
                  },
                  kind &&
                    kind ===
                      EnumMaterialCommunicationItemsConfigKind.Bulletin && {
                      name: 'frequency',
                      label: 'Tipo ',
                      type: 'select',
                      required: true,
                      options: [
                        {
                          label: 'Diário',
                          value: 'daily'
                        },
                        {
                          label: 'Semanal',
                          value: 'weekly'
                        },
                        {
                          label: 'Quinzenal',
                          value: 'fortnightly'
                        }
                      ]
                    }
                ]
              },
              {
                custom: <Divider />
              },
              {
                list: [
                  {
                    name: 'title',
                    label: 'Título',
                    required: true,
                    validate: (value: any) => {
                      const format = /[#%&;]/;
                      if (format.test(value))
                        return 'Os caracteres #%&; não são permitidos';

                      if (value) {
                        return !(value.length > 110)
                          ? undefined
                          : 'Máximo de 110 caracteres';
                      }

                      return undefined;
                    }
                  },
                  kind &&
                    kind ===
                      EnumMaterialCommunicationItemsConfigKind.Bulletin && {
                      name: 'descriptionBulletin',
                      label: 'Descrição do Boletim',
                      type: 'textarea',
                      validate: (value: any) => {
                        const format = /[#%&;]/;
                        if (format.test(value))
                          return 'Os caracteres #%&; não são permitidos';

                        if (value) {
                          return !(value.length > 200)
                            ? undefined
                            : 'Máximo de 200 caracteres';
                        }

                        return undefined;
                      }
                    },
                  mode === Mode.template && {
                    custom: {
                      render: () => (
                        <>
                          {Boolean(!isUpdate && !isResidential) && (
                            <ModalHeaderFooter
                              user={user}
                              onSelect={(data) => {
                                form.change(
                                  'templateProps.HEADER',
                                  data.header
                                );
                                form.change(
                                  'templateProps.FOOTER',
                                  data.footer
                                );
                              }}
                            />
                          )}
                          <TemplateFields isResidential={isResidential} />
                        </>
                      )
                    }
                  },
                  mode === Mode.html && {
                    custom: {
                      render: () => <HtmlEditor />
                    }
                  }
                ]
              },
              {
                list: [
                  {
                    name: 'attachmentIds',
                    label: '',
                    type: 'upload',
                    extraProps: {
                      kind: 'any_doc',
                      CTA: 'Anexar arquivos',
                      multiple: true,
                      listType: 'text',
                      onRemove: (file: any) => {
                        if (
                          downloadUrlAttachment &&
                          downloadUrlAttachment.length > 0
                        ) {
                          const downloadUrlAttachmentFiltered =
                            downloadUrlAttachment.filter(
                              (x: any) => x._id !== file.id
                            );
                          setDownloadUrlAttachment(
                            downloadUrlAttachmentFiltered
                          );
                        }
                      }
                    }
                  },
                  !!downloadUrlAttachment && {
                    list: [
                      {
                        custom: downloadUrlAttachment.map((item: any) => (
                          <a
                            className="download-attachment"
                            onClick={() => {
                              clickDownload(item.signedUrl);
                            }}
                          >
                            Baixar {item.title}
                          </a>
                        ))
                      }
                    ]
                  }
                ]
              },
              kind &&
                kind === EnumMaterialCommunicationItemsConfigKind.Bulletin && {
                  custom: <Divider />
                },
              kind &&
                kind === EnumMaterialCommunicationItemsConfigKind.Bulletin && {
                  inline: true,
                  list: [
                    {
                      className: 'search-parent-id',
                      name: 'recordId',
                      required: false,
                      label: 'Documentos',
                      placeholder: 'Digite o título de um documento',
                      options: options,
                      onFetch: async (
                        value: string
                      ): Promise<{ label: string; value: string }[]> => {
                        const data = await getMaterialContent(value);
                        setOptions([...data]);
                        return data;
                      },
                      onChange: () => {},
                      type: 'select-search',
                      extraProps: {
                        searchSize: 2
                      }
                    }
                  ]
                },
              kind &&
                kind === EnumMaterialCommunicationItemsConfigKind.Bulletin && {
                  custom: (
                    <Button
                      disabled={
                        !isObjectId(form.getFieldState('recordId')?.value)
                      }
                      onClick={() => {
                        const item = options.find(
                          (option: any) =>
                            String(option.value) ===
                            String(form.getFieldState('recordId')?.value)
                        );
                        if (item) {
                          const data =
                            form.getFieldState('bulletinMaterials')?.value ||
                            [];
                          form.change('bulletinMaterials', [
                            ...data.filter(
                              (el: any) => String(el._id) !== String(item.value)
                            ),
                            {
                              _id: item.value,
                              title: item.label,
                              material: item.material
                            }
                          ]);
                        }
                      }}
                    >
                      Adicionar
                    </Button>
                  )
                },
              kind &&
                kind === EnumMaterialCommunicationItemsConfigKind.Bulletin && {
                  custom: (
                    <MaterialsSelectedField
                      data={
                        form.getFieldState('bulletinMaterials')?.value || []
                      }
                      onRemove={(id) => {
                        const data =
                          form.getFieldState('bulletinMaterials')?.value || [];
                        form.change('bulletinMaterials', [
                          ...data.filter(
                            (el: any) => String(el._id) !== String(id)
                          )
                        ]);
                      }}
                    />
                  )
                },
              {
                custom: <Divider />
              },
              {
                name: 'tags',
                label: 'Palavra-chave',
                type: 'tags'
              },
              {
                custom: <Divider />
              },
              {
                className: 'form__footer',
                inline: !isMobile,
                list: [
                  {
                    className: 'selectPublic',
                    custom: (
                      <RegionalProfilesField
                        disabled={!canEdit}
                        readonly={isUpdate}
                        fieldConfig={{
                          initialValue:
                            props.initialValues?.availableAtRegionalProfiles
                        }}
                        defaultChecked={
                          isUpdate
                            ? undefined
                            : [
                                'THQ#residencial:adm_trade_residencial',
                                'THQ#corporate:adm_trade_corporate'
                              ]
                        }
                      />
                    )
                  },
                  {
                    list: [
                      {
                        custom: (
                          <UploadMailing
                            file={
                              props.initialValues?.files?.find(
                                (item) => item?.kind === EnumFileKind.Mailing
                              ) as any
                            }
                            onChange={(_id) => form.change('mailing', _id)}
                          />
                        )
                      },
                      !publishNow && {
                        name: 'mailingApprovers',
                        label: 'Aprovadores mailing',
                        options: channelsOptions,
                        extraProps: {
                          mode: 'multiple'
                        }
                      }
                    ]
                  },
                  {
                    name: 'availableForUsers',
                    label: 'Responsável',
                    placeholder: 'BUSCAR NOME OU E-MAIL',
                    component: SearchUser
                  }
                ]
              },
              publicSelected && {
                custom: (
                  <WrapperLabel>
                    <LabelSelecionarPublico />
                  </WrapperLabel>
                )
              },
              {
                custom: <Divider />
              },
              !publishNow && {
                className: 'form__approvalFlow',
                list: [
                  {
                    name: 'approvalFlow',
                    label: 'Fluxo de Aprovação',
                    type: 'switch'
                  }
                ]
              }
            ],
            submitButton: {
              label: 'Enviar',
              onClick: (formerRef: any) => {
                setPublicSelected(validateSelectPublic(formerRef));
              }
            },
            cancelButton: {
              label: 'CANCELAR',
              onClick: () => history.push(routes.informativosListar.path)
            }
          };
        }}
        onSubmit={async function ({ data }) {
          const initialStatus = initialValues.status?.value;
          const {
            mailing,
            mailingApprovers,
            availableAtRegionalProfiles,
            availableForUsers,
            approvalFlow
          } = data;
          const onlyMailing =
            !availableAtRegionalProfiles && !availableForUsers && !!mailing;
          const withoutPublic =
            !availableAtRegionalProfiles && !mailing && !availableForUsers;
          const templateProps: EmailProps = data.templateProps;
          let emailConfig: MaterialCommunicationItemsConfig;

          // Verificações:
          if (initialStatus === EnumMaterialStatus.Published) {
            return message.error(
              'Não é possível editar! Informativo já publicado!'
            );
          }

          if (isAwaiting) {
            return message.error(
              'Não é possível editar! Informativo aguardando aprovação!'
            );
          }

          if (withoutPublic) {
            return message.error('Nenhum público selecionado!');
          }

          if (approvalFlow && onlyMailing && !mailingApprovers.length) {
            return message.error('Selecione os canais aprovadores do mailing!');
          }

          // Define emailConfig
          if (mode === Mode.html) {
            if (!data.html) {
              message.error('Html vázio.');
            }
            emailConfig = {
              kind: data.kind,
              templateName: 'plainHTML',
              templateProps: { html: data.html },
              attachmentIds: data.attachmentIds
            };
          } else {
            if (
              data.templateName !== 'templateCorp05' &&
              data.templateName !== 'templateCorp06'
            ) {
              const validation = [
                { name: 'Link do botão', value: templateProps.BUTTON_LINK },
                { name: 'Texto do botão', value: templateProps.BUTTON_TITLE },
                { name: 'Descrição do template', value: templateProps.TEXT_1 }
              ];

              if (
                [
                  'templateCorp01',
                  'templateCorp02',
                  'templateCorp03',
                  'templateCorp04'
                ].includes(data.templateName)
              ) {
                validation.push({
                  name: 'Título do template',
                  value: templateProps.TITLE_1
                });
              }

              if (
                data.templateName !== 'templateCorp04' &&
                data.templateName !== 'templateCorp07'
              ) {
                validation.push({
                  name: 'Imagem',
                  value: templateProps.IMAGE_SRC_1
                });
              }

              const hasValidTemplateConfig = notifyEmptyFields(validation);

              if (hasValidTemplateConfig) {
                const { html, ...cleared } = data.templateProps;
                emailConfig = {
                  kind: data.kind,
                  templateName: data.templateName,
                  templateProps: cleared,
                  attachmentIds: data.attachmentIds
                };
              } else {
                return;
              }
            } else {
              const hasValidTemplateConfig = notifyEmptyFields([
                { name: 'Imagem 1', value: templateProps.IMAGE_SRC_1 },
                { name: 'Título do template', value: data.title }
              ]);

              if (hasValidTemplateConfig) {
                const { html, ...cleared } = data.templateProps;

                const hideButton =
                  cleared.HIDE_BUTTON === undefined ||
                  cleared.HIDE_BUTTON === null
                    ? true
                    : cleared.HIDE_BUTTON;

                emailConfig = {
                  kind: data.kind,
                  templateName: data.templateName,
                  templateProps: {
                    ...cleared,
                    TEXT_1: ' ',
                    TITLE_1: data.title,
                    BUTTON_LINK: cleared.BUTTON_LINK || 'SEM LINK',
                    BUTTON_TITLE: cleared.BUTTON_TITLE || 'SEM BOTÃO',
                    HIDE_BUTTON: hideButton
                  },
                  attachmentIds: data.attachmentIds
                };
              } else {
                return;
              }
            }
          }

          const categories = [data.category, ...defaultCategories].filter(
            (category: any) => {
              if (Boolean(category) && category.includes('menu')) {
                return true;
              }
              return false;
            }
          );

          const additionalCategories = [
            data.category,
            ...defaultCategories
          ].filter((category: any) => {
            if (Boolean(category) && !category.includes('menu')) {
              return true;
            }
            return false;
          });

          const record: CreateOneMaterialInput = {
            availableAtChannels: [],
            availableAtRegions: [],
            availableAtRoleGroups: [],
            availableForUsers: Array.from(new Set(data.availableForUsers)),
            availableAtRegionalProfiles: Array.from(
              new Set(data.availableAtRegionalProfiles)
            ),
            approvalFlow: data?.publishNow ? false : data.approvalFlow,
            mailingApprovers: data?.publishNow ? [] : data.mailingApprovers,
            validity: {
              start: data?.publishNow
                ? moment().subtract(1, 'minute').toISOString()
                : data.schedule
            },
            kind: EnumMaterialKind.Communication,
            categories,
            additionalCategories,
            title: data.title,
            description: data.description,
            fileIds: [data.mailing].filter(Boolean),
            tags: data.tags,
            communicationItemsConfig: [emailConfig],
            frequency: data?.frequency,
            descriptionBulletin: data?.descriptionBulletin
          };

          if (data?.bulletinMaterials && data?.bulletinMaterials?.length) {
            record.bulletinMaterialsId = data.bulletinMaterials.map(
              (el: any) => el._id
            );
          }

          if (data?.publishNow) {
            record.status = EnumMaterialStatus.PublishNow;
            record.publishNow = true;
            record.isDraft = false;
          }

          const callback = (ctx: { result?: any }) => {
            const dinamicText = isUpdate ? 'atualizado' : 'cadastrado';

            if (ctx?.result) {
              message.success(`Informativo ${dinamicText} com sucesso`);
              history.push(routes.informativosListar.mount());
            } else {
              message.error(`Informativo não ${dinamicText}`);
            }
          };

          if (isUpdate) {
            await updateMaterialCommunication
              .fetch({
                variables: {
                  communicationId: data._id,
                  record
                }
              })
              .then(callback);
          } else {
            await createMaterialCommunication
              .fetch({
                variables: { record }
              })
              .then(callback);
          }
        }}
      />
    </Container>
  );
};

export const additionalCategoriesOptions = [
  {
    value: 'incentive',
    label: 'INCENTIVO'
  },
  {
    value: 'marketing',
    label: 'MARKETING'
  },
  {
    value: 'offers',
    label: 'OFERTAS'
  },
  {
    value: 'channels',
    label: 'CANAIS'
  },
  {
    value: 'argumentation',
    label: 'ARGUMENTAÇÃO'
  },
  {
    value: 'support',
    label: 'SUPORTE'
  }
];

const typeOptions = [
  {
    id: EnumMaterialCommunicationItemsConfigKind.Bulletin,
    value: EnumMaterialCommunicationItemsConfigKind.Bulletin,
    title: 'Boletim'
  },
  {
    id: EnumMaterialCommunicationItemsConfigKind.Email,
    value: EnumMaterialCommunicationItemsConfigKind.Email,
    title: 'E-mail Marketing'
  }
];

// retorna false se um dos values for undefined e notifica um erro
export function notifyEmptyFields(fields: { value: any; name: string }[]) {
  let isValid = true;

  Object.keys(fields).forEach((key) => {
    const item = (fields as any)[key];
    if (!item.value) {
      message.error(`Campo "${item.name}" em branco`);
      isValid = false;
    }
  });

  return isValid;
}

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
