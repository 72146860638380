import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';
import { colors } from '@digi-tim-19/theme';

export const ProfileContainer = styled.div`
  position: relative;
  img {
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    max-width: none;
    margin-right: 16px;
  }

  .online-indicator {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 3px;
    bottom: 5px;
    border-radius: 5.5px;
    border: 1px solid #fff;
    background: #28c76f;
    margin-right: 16px;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  width: 338px;
  right: -55px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 12px 12px 12px 12px;
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.5);
  z-index: 1000;
`;

export const MenuItem = styled.div`
  height: 45.143px;
  padding: 14px 16px;
  font-family: TimRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #535353;
  cursor: pointer;

  &:hover {
    background-color: #e3f2fd;
    border-left: 1px solid #0d47a1;
    color: #0d47a1;
  }

  .anticon {
    margin-right: 8px;
  }
`;

export const DivUser = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  color: ${colors.blue};
  font-family: Montserrat;
  font-style: normal;

  & > span:first-child {
    font-size: 15px;
    font-weight: 700;
    color: ${colors.blue};
    font-family: TimBold;
  }

  & > span:nth-child(2) {
    font-size: 13px;
    margin-left: 49px;
    font-family: TimLight;
  }
`;

export const CustomModal = styled(AntdModal)`
  top: 0px;
  height: 100vh;
  margin-right: 0px;
  min-width: 40vw;

  .ant-modal-content {
    height: 100vh;
    width: 100%;
  }

  .ant-modal-body {
  }
`;
