import React from 'react';
import { useHistory } from 'react-router-dom';
import { Bellicon } from './styles';
import { Notifications } from 'autogenerated/client/types';
import { Icon } from 'antd';
import { colors } from '@digi-tim-19/theme';

interface BellIconProps {
  isResidential?: boolean;
  notifications?: Notifications | null;
}

export const BellIcon = ({ isResidential, notifications }: BellIconProps) => {
  const history = useHistory();

  const hasUnreadNotifications = () => {
    return notifications?.unreadIds && notifications.unreadIds.length > 0;
  };

  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        history.push('/notificacoes');
      }}
    >
      <Bellicon>
        <Icon
          type="bell"
          theme="filled"
          style={{
            fontSize: '22px',
            color: !isResidential ? 'white' : colors.blue
          }}
        />
        {hasUnreadNotifications() && <div className="red-dot" />}
      </Bellicon>
    </a>
  );
};
