import styled from 'styled-components';
import { Input } from 'antd';

const { Search: AntdSearch } = Input;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;
