import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Former,
  LoadingIndicator,
  Modal,
  useClient
} from '@digi-tim-19/components';
import * as S from './styles';
import styled from 'styled-components';
import MaskedInput from 'antd-mask-input';
import { uniqBy } from 'lodash';
import { useDimensions } from '@digi-tim-19/utils/build';
import { Radio } from 'antd';
import { FormApi, AnyObject } from 'final-form';
import { RadioChangeEvent } from 'antd/lib/radio';

export interface TableMeudiaPdv {
  _id: string;
  regional: string;
  uf: string;
  municipio: string;
  canal: string;
  bairro: string;
  loja: string;
  endereco: string;
  complemento: string;
  cep: string;
  tipo: string;
  createdAt: Date;
  __v: number;
  id: string;
  manha1011: number;
  manha1112: number;
  tarde1415: number;
  tarde1516: number;
  tarde1617: number;
}

export const Search = styled(MaskedInput)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const enderecoDefaultValues = {
  endereco: '',
  complemento: '',
  cep: '',
  tipo: ''
};

const valuesWatchConfig = {
  regional: '',
  canal: '',
  tipo: '',
  loja: '',
  cep: '',
  uf: '',
  municipio: '',
  bairro: '',
  endereco: ''
};

interface IPropsRadioButomCustom {
  options: {
    label: string;
    value: string;
    disabled: boolean;
    vagas: number;
  }[];
  form: FormApi<AnyObject>;
  setIsComplete: (value: boolean) => void;
  isSubmit: boolean;
}

export const RadioButtonCustomVaga = ({
  options,
  form,
  setIsComplete,
  isSubmit
}: IPropsRadioButomCustom) => {
  const [validate, setValidate] = useState<boolean>(true);

  const onChange = (e: RadioChangeEvent) => {
    form.change('vaga', e.target.value);
    if (form.getFieldState('vaga')?.value) setIsComplete(true);
  };

  useEffect(() => {
    if (isSubmit && !form.getFieldState('vaga')?.value) {
      setValidate(false);
    } else {
      setValidate(true);
    }
  }, [isSubmit, form.getFieldState('vaga')?.value]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
      }}
    >
      <p style={{ margin: 0 }}>Períodos: </p>
      <Radio.Group
        onChange={onChange}
        value={form.getFieldState('vaga')?.value}
        style={{
          ...(validate
            ? {}
            : {
                border: '1px solid #dc3545',
                boxShadow: '0 0 0 1px #dc3545',
                opacity: 0.5
              }),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          border: '2px solid #e8e8e8',
          borderRadius: '2px',
          padding: '2px 5px'
        }}
      >
        {options.map((option, idx) => (
          <Radio
            style={{ background: idx % 2 === 0 ? '#e6f4ff' : '#cce9ff' }}
            disabled={option.disabled}
            key={`radio-btn-${idx}`}
            value={option.value}
          >
            {`${option.label} ${
              option.vagas !== null ? `${option.vagas} Vaga(s)` : ''
            }`}
          </Radio>
        ))}
      </Radio.Group>
      {!validate && (
        <p
          style={{
            color: '#dc3545',
            alignSelf: 'end',
            fontSize: '11px',
            fontWeight: 'bold'
          }}
        >
          CAMPO OBRIGATÓRIO
        </p>
      )}
    </div>
  );
};

export const RadioButtonLojas = ({
  options,
  form,
  setValuesWatch,
  isSubmit,
  onChange: handleChange
}: {
  options: {
    label: string;
    value: string;
  }[];
  form: FormApi<AnyObject>;
  setValuesWatch: React.Dispatch<
    React.SetStateAction<typeof valuesWatchConfig>
  >;
  isSubmit: boolean;
  onChange: (value: string) => void;
}) => {
  const dimensions = useDimensions();
  const [validate, setValidate] = useState<boolean>(true);

  const onChange = (e: RadioChangeEvent) => {
    handleChange(e.target.value);
    form.change('loja', e.target.value);
    setValuesWatch((prev) => ({
      ...prev,
      loja: e.target.value
    }));
  };

  useEffect(() => {
    if (isSubmit && !form.getFieldState('loja')?.value) {
      setValidate(false);
    } else {
      setValidate(true);
    }
  }, [isSubmit, form.getFieldState('loja')?.value]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
      }}
    >
      <p style={{ margin: 0 }}>Lojas: </p>
      <Radio.Group
        name="loja"
        id="loja"
        onChange={onChange}
        value={form.getFieldState('loja')?.value}
        style={{
          ...(validate
            ? {}
            : {
                border: '1px solid #dc3545',
                boxShadow: '0 0 0 1px #dc3545',
                opacity: 0.5
              }),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          border: '2px solid #e8e8e8',
          borderRadius: '2px',
          padding: '2px 5px'
        }}
      >
        {options.map((option, idx) =>
          dimensions.isMobile ? (
            <div>
              <Radio
                style={{ background: idx % 2 === 0 ? '#e6f4ff' : '#cce9ff' }}
                key={`radio-btn-${idx}`}
                value={option.value}
              />
              <p style={{ background: idx % 2 === 0 ? '#e6f4ff' : '#cce9ff' }}>
                {option.label}
              </p>
            </div>
          ) : (
            <Radio
              style={{ background: idx % 2 === 0 ? '#e6f4ff' : '#cce9ff' }}
              key={`radio-btn-${idx}`}
              value={option.value}
            >
              {option.label}
            </Radio>
          )
        )}
      </Radio.Group>
      {!validate && (
        <p
          style={{
            color: '#dc3545',
            alignSelf: 'end',
            fontSize: '11px',
            fontWeight: 'bold'
          }}
        >
          CAMPO OBRIGATÓRIO
        </p>
      )}
    </div>
  );
};

export const MeuDiaNoPdv = () => {
  const dimensions = useDimensions();
  const meudiaPdvTableApi = useClient('MeuDiaPdvTableAll');
  const createVaga = useClient('MeuPdvParamsMutation');
  const [table, setTable] = useState<TableMeudiaPdv[]>([]);
  const [endereco, setEndereco] = useState<typeof enderecoDefaultValues>(
    enderecoDefaultValues
  );
  const [valuesWatch, setValuesWatch] = useState<typeof valuesWatchConfig>(
    valuesWatchConfig
  );
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isModalSuccess, setIsModalSucess] = useState<boolean>(false);
  const [result, setResult] = useState<Record<string, any>>({});
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const handleTable = async () => {
    await meudiaPdvTableApi
      .fetch({
        appendToFragment: tableFragment
      })
      .then((ctx) => {
        setTable(ctx.result.table);
      });
  };

  useEffect(() => {
    handleTable();
  }, []);

  useEffect(() => {
    const loja = table.find((c) => c.loja === valuesWatch.loja);

    setEndereco((prev) => ({
      ...prev,
      endereco: loja?.endereco as string,
      complemento: loja?.complemento as string,
      cep: loja?.cep as string,
      logradouro: loja?.endereco as string,
      tipo: loja?.tipo as string
    }));
  }, [valuesWatch.loja]);

  const regionalOptions = useMemo(
    () =>
      uniqBy(table, 'regional').map((c) => ({
        value: c.regional,
        label: c.regional.toUpperCase()
      })),
    [table]
  );

  const ufOptions = useMemo(() => {
    const ufFilter = table.filter((c) => c.regional === valuesWatch.regional);

    const ufUniq = uniqBy(ufFilter, 'uf').map((c) => ({
      value: c.uf,
      label: c.uf.toUpperCase()
    }));

    return ufUniq;
  }, [valuesWatch.regional]);

  const municipioOptions = useMemo(() => {
    const municipiosFilter = table.filter(
      (c) => c.regional === valuesWatch.regional && c.uf === valuesWatch.uf
    );

    const municipiosUniq = municipiosFilter.map((c) => ({
      value: c.municipio,
      label: c.municipio.toUpperCase()
    }));

    return uniqBy(municipiosUniq, 'label');
  }, [valuesWatch.uf]);

  const lojaOptions = useMemo(() => {
    const lojasFilter = table.filter(
      (c) =>
        c.municipio === valuesWatch.municipio &&
        (c.canal === valuesWatch.canal || !valuesWatch.canal) &&
        (c.bairro === valuesWatch.bairro || !valuesWatch.bairro)
    );

    const lojasUniq = uniqBy(lojasFilter, 'loja').map((c) => ({
      value: c.loja,
      label: `${c.canal} - ${c.bairro} - ${c.loja}`.toUpperCase()
    }));

    return lojasUniq;
  }, [valuesWatch.municipio, valuesWatch.bairro]);

  const vagasOptions = useMemo(() => {
    const mapped: Record<string, string> = {
      '10-11': 'manha1011',
      '11-12': 'manha1112',
      '14-15': 'tarde1415',
      '15-16': 'tarde1516',
      '16-17': 'tarde1617'
    };

    const options = [
      {
        label: 'MANHÃ 10h às 11h',
        value: '10-11',
        disabled: false,
        vagas: null
      },
      {
        label: 'MANHÃ 11h às 12h',
        value: '11-12',
        disabled: false,
        vagas: null
      },
      {
        label: 'TARDE 14h às 15h',
        value: '14-15',
        disabled: false,
        vagas: null
      },
      {
        label: 'TARDE 15 às 16h',
        value: '15-16',
        disabled: false,
        vagas: null
      },
      {
        label: 'TARDE 16 às 17h',
        value: '16-17',
        disabled: false,
        vagas: null
      }
    ];

    if (!valuesWatch.loja) return options;

    return options.map((l) => {
      const loja = table.find((t) => t.loja === valuesWatch.loja) as any;

      const vaga = mapped[l.value];
      const qtdVagaDisponiveis = loja[vaga];

      return {
        ...l,
        disabled: qtdVagaDisponiveis <= 0,
        vagas: qtdVagaDisponiveis
      };
    });
  }, [valuesWatch.loja]);

  const diretoriaOptions = useMemo(
    () => [
      { label: 'CFO', value: 'CFO' },
      { label: 'CRO', value: 'CRO' },
      { label: 'CTIO', value: 'CTIO' },
      { label: 'Ouvidoria', value: 'Ouvidoria' },
      {
        label: 'Investor Relations Office',
        value: 'Investor Relations Office'
      },
      {
        label: 'Regulator, Institut. Anda Press Relations',
        value: 'Regulator, Institut. Anda Press Relations'
      },
      {
        label: 'Strateg, Customer Experience & Data Governance',
        value: 'Strateg, Customer Experience & Data Governance'
      },
      { label: 'Legal & Corporate', value: 'Legal & Corporate' },
      {
        label: 'People, Culture & Organization',
        value: 'People, Culture & Organization'
      },
      { label: 'Business Support Officer', value: 'Business Support Officer' },
      { label: 'New Business & Innovation', value: 'New Business & Innovation' }
    ],
    []
  );

  return (
    <S.Wrapper isMobile={dimensions.isMobile}>
      <Modal
        title="Alerta"
        visible={isModalSuccess}
        cancelText="Fechar"
        onCancel={() => setIsModalSucess(false)}
        okText="Concluir"
        onOk={() => setIsModalSucess(false)}
      >
        <p>Sua vaga foi preenchida com sucesso!</p>
        {/* <p>{`Será o PDV [${result?.canal}, ${result?.endereco}, ${result?.complemento} - ${result?.municipio} - ${result?.uf}]`}</p> */}
        <p>{`Canal: ${result?.canal}`}</p>
        <p>{`Endereço: ${result?.endereco}, ${result?.complemento} - ${result?.municipio}`}</p>
        <p>Bom MEU DIA NO PDV!</p>
        <p>
          Se quiser cancelar e disponibilizar sua vaga, entre em contato com
          xxxxx@timbrasil.com.br”
        </p>
      </Modal>
      <S.Container>
        <S.MeuDiaPdvWrapper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <h1 style={{ fontWeight: 'bold', textAlign: 'center' }}>
              MEU DIA NO PDV
            </h1>
            <h2>Escolha seu PDV</h2>
          </div>

          {!table.length ? (
            <LoadingIndicator small />
          ) : (
            <Former
              config={({ form }) => {
                form.registerField('vaga', () => {}, {});
                form.registerField('loja', () => {}, {});
                form.registerField('canal', () => {}, {});
                form.registerField('bairro', () => {}, {});

                return {
                  fields: [
                    {
                      className: 'header',
                      inline: !dimensions.isMobile,
                      list: [
                        {
                          name: 'regional',
                          label: 'Regional',
                          type: 'select',
                          required: true,
                          options: regionalOptions,
                          afterChange(value) {
                            setValuesWatch((prev) => ({
                              ...prev,
                              regional: value
                            }));
                          }
                        },
                        {
                          name: 'uf',
                          label: 'UF',
                          type: 'select',
                          required: true,
                          options: ufOptions,
                          maxWidth: '80px',
                          afterChange(value) {
                            setValuesWatch((prev) => ({
                              ...prev,
                              uf: value
                            }));
                          }
                        },
                        {
                          name: 'municipio',
                          label: 'Municipio',
                          type: 'select',
                          required: true,
                          options: municipioOptions,
                          afterChange(value) {
                            setValuesWatch((prev) => ({
                              ...prev,
                              municipio: value
                            }));
                          }
                        }
                      ]
                    },
                    {
                      list: [
                        {
                          name: 'loja',
                          custom: {
                            render: () => (
                              <RadioButtonLojas
                                options={lojaOptions}
                                form={form as FormApi<AnyObject>}
                                setValuesWatch={setValuesWatch}
                                isSubmit={isSubmit}
                                onChange={(value) => {
                                  const loja = table.find(
                                    (t) => t.loja === value
                                  );
                                  form.change('canal', loja?.canal);
                                  form.change('bairro', loja?.bairro);
                                }}
                              />
                            )
                          }
                        }
                      ]
                    },
                    {
                      inline: !dimensions.isMobile,
                      list: [
                        {
                          name: 'endereco',
                          label: 'Endereço',
                          type: 'text',
                          required: true,
                          disabled: true,
                          defaultValue: endereco.endereco
                        },
                        {
                          name: 'complemento',
                          label: 'Complemento',
                          type: 'text',
                          defaultValue: endereco.complemento,
                          maxWidth: '350px',
                          disabled: true
                        }
                      ]
                    },
                    {
                      inline: !dimensions.isMobile,
                      list: [
                        {
                          name: 'cep',
                          label: 'Cep',
                          type: 'text',
                          defaultValue: endereco.cep,
                          required: true,
                          disabled: true
                        },
                        {
                          name: 'tipo',
                          label: 'Tipo',
                          type: 'text',
                          defaultValue: endereco.tipo,
                          required: true,
                          disabled: true
                        }
                      ]
                    },
                    {
                      inline: !dimensions.isMobile,
                      list: [
                        {
                          name: 'vaga',
                          custom: {
                            render: () => (
                              <RadioButtonCustomVaga
                                setIsComplete={setIsComplete}
                                isSubmit={isSubmit}
                                options={vagasOptions}
                                form={form as FormApi<AnyObject>}
                              />
                            )
                          }
                        }
                      ]
                    },
                    isComplete
                      ? {
                          inline: true,
                          list: [
                            {
                              name: 'nome',
                              label: 'Nome',
                              type: 'text',
                              required: true
                            }
                          ]
                        }
                      : null,
                    isComplete
                      ? {
                          inline: true,

                          list: [
                            {
                              name: 'matricula',
                              label: 'Matricula',
                              type: 'text',
                              required: true
                            }
                          ]
                        }
                      : null,
                    isComplete
                      ? {
                          inline: true,
                          list: [
                            {
                              name: 'celular',
                              label: 'Celular',
                              type: 'text',
                              required: true,
                              mask: '##-#####-####'
                            }
                          ]
                        }
                      : null,
                    isComplete
                      ? {
                          inline: true,
                          list: [
                            {
                              name: 'email',
                              label: 'E-mail',
                              type: 'text',
                              required: true
                            }
                          ]
                        }
                      : null,
                    isComplete
                      ? {
                          inline: true,
                          list: [
                            {
                              name: 'diretoria',
                              label: 'Diretoria',
                              type: 'select',
                              defaultValue: '',
                              required: true,
                              options: diretoriaOptions
                            }
                          ]
                        }
                      : null
                  ],
                  submitButton: {
                    label: 'PREENCHA SEUS DADOS',
                    onClick: () => setIsSubmit(true),
                    disabled: !isComplete
                  }
                };
              }}
              onSubmit={async ({ data }) => {
                console.log('data', data);
                const requiredValues = [data.loja, data.vaga];

                if (!requiredValues.every(Boolean)) {
                  return;
                }

                setResult(data);
                await createVaga.fetch({
                  variables: data,
                  appendToFragment: `
                  nome
                  celular
                `
                });
                await handleTable();
                setEndereco(enderecoDefaultValues);
                setValuesWatch(valuesWatchConfig);
                setIsModalSucess(true);
                setIsComplete(false);
                setIsSubmit(false);
              }}
            />
          )}
        </S.MeuDiaPdvWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

const tableFragment = `
MeuDiaPdvTableAll {
  table {
    uf
    regional
    municipio
    canal
    bairro
    loja
    endereco
    complemento
    cep
    tipo
    manha1011
    manha1112
    tarde1415
    tarde1516
    tarde1617
  }
}
`;
