import React from 'react';
import {
  TemplateCorp06Visualization,
  TemplateCorp07Visualization,
  TemplateCorp08Visualization,
  TemplateCorp09Visualization,
  TemplateCorp10Visualization
} from '@digi-tim-19/components';
import { TemplatesEmailItem } from '@digi-tim-19/components/build/main/TemplatesEmail/TemplatesEmailProps';

export const SwitchTemplateFeed = ({ templateContent }: any) => {
  const templateProps: TemplatesEmailItem = {
    defaultProps: templateContent?.templateProps
  };

  switch (templateContent?.templateName) {
    case 'templateCorp06':
      return (
        <TemplateCorp06Visualization {...templateProps} isResidential={true} />
      );
    case 'templateCorp07':
      return (
        <TemplateCorp07Visualization {...templateProps} isResidential={true} />
      );
    case 'templateCorp08':
      return (
        <TemplateCorp08Visualization {...templateProps} isResidential={true} />
      );
    case 'templateCorp09':
      return (
        <TemplateCorp09Visualization {...templateProps} isResidential={true} />
      );
    case 'templateCorp10':
      return (
        <TemplateCorp10Visualization {...templateProps} isResidential={true} />
      );
    default:
      return <React.Fragment></React.Fragment>;
  }
};
