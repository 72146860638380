import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Checkbox, Button, Pagination, message, Icon } from 'antd';
import {
  Breadcrumb,
  ListGrid,
  SelectFilter,
  TitleDescription
} from '@digi-tim-19/components';
import {
  formatDate,
  getRouterByMaterialCorp,
  linkWithHttp
} from '@digi-tim-19/utils';
import { ListGridItemProps } from '@digi-tim-19/components/build/main/ListGrid/PropsListGrid';

import imageCard from '../../assets/images/image_card.png';
import { accessMaterial } from '../../utils/material';
import { useClient } from '../../autogenerated/client/client';
import { SortFindManyNotificationInput } from '../../autogenerated/client/types';
import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { LoadingContainer } from 'components/LoadingContainer/LoadingContainer';
import { EmptyContainer } from 'components/TablePagination/EmptyContainer';

// Styles -----------------------------------------------------------------------------------------
const NotificationsWrapper = styled.div`
  .bcVnUu {
    margin-top: 0;
  }
  .actions {
    display: block;
    .ant-select {
      width: 200px;
      i {
        color: ${({ theme }) => theme.blue};
      }
    }
    .actions-group {
      @media screen and (min-width: 1024px) {
        display: flex;
        background-color: #f6f6f6;
        flex: 1;
        align-items: center;
        border-radius: 4px;
        margin-left: 40px;
      }
    }
    .action {
      margin: 8px 0;
    }
    @media screen and (min-width: 768px) {
      display: flex;
      & .action {
        margin: 0;
        margin-left: 20px;
      }
    }
    & .action {
      & button {
        outline: 0;
        border: none;
        background: transparent;
      }
    }
  }
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

// Config -----------------------------------------------------------------------------------------
const configPage = {
  pageName: '',

  description: '',
  breadcrumb: [
    {
      title: 'Home',
      link: routes.home.mount()
    },
    { title: 'Notificações' }
  ]
};

// Component -----------------------------------------------------------------------------------------
export const Notificacoes = () => {
  const history = useHistory();
  const getNotifications = useClient('NotificationPagination');
  const deleteNotification = useClient('UserDeleteNotification');
  const markMaterialAsRead = useClient('UserMarkNotificationAsRead');
  const user = useClient('User');

  const [sort, setSort] = useState(SortFindManyNotificationInput.IdDesc);

  const [selectItems, setSelectItems] = useState<string[]>([]);
  const [readsIds, setReadsIds] = useState<any>();
  const [page, setPage] = useState(1);
  const perPage = 10;

  const getUser = async () => {
    await user
      .fetch({
        appendToFragment: `
        notifications {
          readIds
          unreadIds
        }
        jobTitle {
          name
        }
      `
      })
      .then((ctx) => {
        setReadsIds(ctx.result?.notifications?.readIds);
      });
  };

  useEffect(() => {
    getUser();
    getNotifications.fetch({
      appendToFragment,
      variables: {
        perPage,
        page,
        sort
      }
    });
    return () => {};
  }, [sort, page]);

  const notificationResult = getNotifications.result;

  const notificationItems = notificationResult?.items;

  const total = notificationResult?.count;

  const data = React.useMemo(() => {
    return notificationItems?.map((notification) => {
      const notificationMaterial = notification?.material;
      const notificationLink = notificationMaterial?.notificationLink;
      const isSelected = selectItems.includes(notification?._id);

      const canAccess = () => {
        if (notificationMaterial) {
          const { routerAccess } = getRouterByMaterialCorp(
            notificationMaterial,
            routes
          );

          return !!notificationLink || !!routerAccess;
        }

        return !!notificationLink;
      };

      return {
        data: formatDate(notification!.sentAt, 'DD/MM/YYYY HH:mm'),
        tituloHeader: notification!.title,
        tituloCard: '',
        descricao: notification!.body,
        imgBody: notification!.iconUrl || imageCard,
        isRead: !readsIds?.includes(notification?._id) || false,
        isSelected,
        renderWithCheckbox: true,
        clickCheckbox: () => {
          if (isSelected) {
            return setSelectItems(
              selectItems.filter((item) => item !== notification?._id)
            );
          }
          return setSelectItems([...selectItems, notification?._id]);
        },
        actions: {
          access: canAccess(),
          pin: false,
          share: false,
          download: false,
          delete: true,
          onDelete: () => {
            deleteNotification
              .fetch({
                variables: { notificationId: notification?._id },
                afterMutate: /^Notification/
              })
              .then(() => {
                message.success('Notificação deletada com sucesso!');
              });
          },
          onAccess: () => {
            toggleRead(true, notification?._id);

            // Verifica se a notificação tem um link próprio
            if (notificationLink) {
              const httpLink = linkWithHttp(notificationLink);

              return window.open(httpLink);
            }

            return accessMaterial(notificationMaterial, history, routes);
          }
        }
      };
    });
  }, [notificationResult, selectItems, readsIds]);

  function selectAllItems() {
    const items = notificationItems?.map((item) => item?._id);

    if (items?.length === selectItems.length) {
      return setSelectItems([]);
    }

    return items && setSelectItems(items);
  }

  function toggleRead(isRead: boolean, id?: string) {
    let idNotification: any;
    if (id) {
      idNotification = id;
    } else {
      idNotification = selectItems;
    }

    markMaterialAsRead
      .fetch({
        variables: {
          isRead,
          notifications: idNotification
        }
      })
      .then(() => {
        setSelectItems([]);

        if (isRead === true) {
          return setReadsIds([...readsIds]);
        }

        return setReadsIds(
          readsIds?.filter((item: string) => !selectItems.includes(item))
        );
      });
  }

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <NotificationsWrapper>
        <TitleDescription
          iconType={''}
          title={configPage.pageName}
          description={configPage.description}
        />

        <div className="actions">
          <SelectFilter
            selects={selectsOrders}
            onChange={(e: any) => {
              setPage(1);
              setSelectItems([]);
              setSort(e);
            }}
          />

          <div className="actions-group">
            <div className="action">
              <Checkbox
                checked={selectItems.length === notificationItems?.length}
                onChange={selectAllItems}
              >
                selecionar todas{' '}
                {!!selectItems.length && `(${selectItems.length})`}
              </Checkbox>
            </div>
            {selectItems.length > 0 && (
              <>
                <div className="action">
                  <Button
                    type="link"
                    icon="read"
                    onClick={() => toggleRead(true)}
                  >
                    marcar como lida
                  </Button>
                </div>
                <div className="action">
                  <Button
                    type="link"
                    icon="mail"
                    onClick={() => toggleRead(false)}
                  >
                    marcar como não lida
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        {getNotifications.loading ? (
          <LoadingContainer />
        ) : (
          <>
            {!data || data.length === 0 ? (
              <EmptyContainer message="Não há notificações para exibir" />
            ) : (
              <>
                <ListGrid items={data as ListGridItemProps[]} />
                <PaginationWrapper>
                  <Pagination
                    current={page}
                    total={total!}
                    pageSize={perPage}
                    onChange={(page) => {
                      setSelectItems([]);
                      setPage(page);
                    }}
                  />
                </PaginationWrapper>
              </>
            )}
          </>
        )}
      </NotificationsWrapper>
    </PageTemplate>
  );
};

// Utils -----------------------------------------------------------------------------------------
const appendToFragment = `
  items {
    title
    body
    iconUrl
    sentAt
    _id
    parentId
    material {
      _id
      isOwner
      notificationLink
      kind
      html
      previousStatus
      email {
        _id
      }
      communicationItemsConfig {
        kind
      }
      categories {
        _id
        name
      }
      files {
        kind
        extension
        signedUrl
      }
    }
  }
`;

const selectsOrders = [
  {
    defaultValue: 'Ordenar Por',
    action: '',
    options: [
      {
        name: 'Mais recente',
        value: SortFindManyNotificationInput.IdDesc
      },
      {
        name: 'Mais antigo',
        value: SortFindManyNotificationInput.IdAsc
      }
    ]
  }
];
