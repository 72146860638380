import { useClient } from '../../autogenerated/client/client';

export const useCategoryCore = () => {
  const CategoryFindMany = useClient('CategoryFindMany', {
    fetchOnMount: true,
    variables: {
      limit: 1000,
      filter: {
        _ids: [
          'menu:residencial_suporte',
          'menu:compara_ai',
          'menu:ultrabook',
          'menu:ver_mais:canal',
          'menu:residencial_comunicacao_cliente',
          'menu:processos',
          'menu:suporte_e_processos',
          'menu:para_voce',
          'menu:para_o_cliente',
          'menu:eventos'
        ]
      }
    }
  });

  return CategoryFindMany?.result?.map((item) => ({
    name: item?.name?.toUpperCase() || '',
    value: item?._id || ''
  }));
};
