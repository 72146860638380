import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useClient, useCurrentUser } from '@digi-tim-19/components';
import { routes } from 'config/routes';
import { useHistory } from 'react-router';
import { MenuPrincipal } from './menu/MenuPrincipal';
import { MenuPrincipalMobile } from './menu/menuMobile/MenuPrincipalMobile';
import { useDimensions } from '@digi-tim-19/utils';
import { Header } from './NewHeader/Desktop/index';
import { HeaderMobile } from './NewHeader/Mobile';
import { useMenuNavigation } from 'hooks/useRedirecionarLink';
import { useTour } from 'providers/TourProvider';

export type TypeMenuItem = {
  text: string;
  routeName?: any;
  typeIcon?: string;
  children?: Array<TypeMenuItem>;
  nivel: number;
  _id: string;
};

export type GroupItemMenuProp = {
  id: string;
  items: Array<TypeMenuItem>;
};

const HeaderMobileWrapper = styled.div``;

const TemplateWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  overflow: hidden;
`;

const MenuWrapper = styled.div`
  display: flex;
  position: fixed;
  height: 100vh;
`;

const MainWrapper = styled.div<{ open: boolean; menuOpen: boolean }>`
  position: relative;
  display: ${({ open }) => (open ? 'block' : 'none')};
  left: ${({ menuOpen }) => (!menuOpen ? '296px' : '74px')};
  width: ${({ menuOpen }) =>
    menuOpen ? 'calc(100% - 74px)' : 'calc(100% - 296px)'};
  transition: all 0.25s ease;
  z-index: 1000;

  @media (max-width: 1024px) {
    left: ${({ menuOpen }) => (!menuOpen ? '74px' : '296px')};
    width: ${({ menuOpen }) =>
      !menuOpen ? 'calc(100% - 74px)' : 'calc(100% - 296px)'};
  }
`;

const StyledContent = styled.section<{ menuOpen?: boolean }>`
  padding: 20px;
  width: 100%;
  display: ${({ menuOpen }) => (menuOpen ? 'block' : 'none')};
`;

export const PageTemplate = ({
  breadcrumb,
  children
}: {
  breadcrumb?: any;
  children: React.ReactNode;
}) => {
  const user = useCurrentUser()?.result;
  const { result: dataSourceMenu } = useClient('MenuResolvers', {
    fetchOnMount: true
  });
  const { openMenu, openSubmenu, menuParentId } = useTour();
  const history = useHistory();
  const dimensions = useDimensions();
  const isMobile = dimensions.width < 768;
  const { redirecionarLink } = useMenuNavigation();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [subMenuIsOpen, setSubMenuIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setMenuIsOpen(openMenu && isMobile ? true : false);
    setSubMenuIsOpen(openSubmenu ? true : false);
  }, [openMenu, openSubmenu, isMobile]);

  return (
    <>
      {isMobile && (
        <HeaderMobileWrapper>
          <HeaderMobile
            menuExpand={() => setMenuIsOpen((prev) => !prev)}
            breadcrumb={breadcrumb}
            onSelect={(value: string) => {
              history.push(routes.search.mount(encodeURIComponent(value)));
            }}
            menuIsOpen={menuIsOpen}
          />
          <div>
            <MenuPrincipalMobile
              menuIsOpen={menuIsOpen}
              itemsMenuSettings={dataSourceMenu ?? []}
              onMenuExpand={() => setMenuIsOpen((prev) => !prev)}
              redirecionarLink={(itemClick: TypeMenuItem) =>
                redirecionarLink(
                  itemClick,
                  () => setSubMenuIsOpen(false),
                  () => setMenuIsOpen(false),
                  isMobile
                )
              }
            />
            <StyledContent menuOpen={!menuIsOpen}> {children} </StyledContent>
          </div>
        </HeaderMobileWrapper>
      )}

      {!isMobile && (
        <TemplateWrapper>
          <MenuWrapper>
            <MenuPrincipal
              menuIsOpen={menuIsOpen}
              itemsMenuSettings={dataSourceMenu ?? []}
              onMenuExpand={() => {
                setMenuIsOpen((prev) => !prev);
              }}
              redirecionarLink={(itemClick: TypeMenuItem) =>
                redirecionarLink(itemClick, () => setSubMenuIsOpen(false))
              }
              onSubMenuExpand={(value: boolean) =>
                setSubMenuIsOpen((_prev) => value)
              }
              subMenuIsOpen={subMenuIsOpen}
              dimensions={dimensions.width}
              tourMenuParentId={menuParentId}
              tourOpenSubmenu={openSubmenu}
            />
          </MenuWrapper>
          <MainWrapper open={!subMenuIsOpen} menuOpen={menuIsOpen}>
            <Header
              breadcrumb={breadcrumb}
              onSelect={(value: string) => {
                history.push(routes.search.mount(encodeURIComponent(value)));
              }}
            />
            <StyledContent menuOpen={!subMenuIsOpen}>{children}</StyledContent>
          </MainWrapper>
        </TemplateWrapper>
      )}
      {/* <SiteFooter /> */}
    </>
  );
};
