import React from 'react';
import { TableActions } from '@digi-tim-19/components';

export const columns = [
  {
    title: 'Título da campanha',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Criado em',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_: any, record: any) => <TableActions data={record} />
  }
];
