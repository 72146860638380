import moment from 'moment';

import { IncentiveCampaign } from 'autogenerated/client/types';
import 'moment/locale/pt-br';
moment.locale('pt-BR');

function useContestPeriod(campaign: IncentiveCampaign) {
  let isContestAllowed = false;
  let timeLeft = undefined;
  const today = moment();
  const startDate =
    campaign?.contestationPeriod?.start &&
    moment(campaign?.contestationPeriod?.start);
  const endDate =
    campaign?.contestationPeriod?.end &&
    moment(campaign?.contestationPeriod?.end);

  if (startDate && endDate) {
    isContestAllowed = today.isBetween(startDate, endDate);
    if (isContestAllowed) timeLeft = endDate.fromNow(true);
  } else {
    isContestAllowed = Boolean(
      campaign?.file?._id &&
        campaign?.fileContent &&
        campaign?.fileContent?.length > 0
    );
  }

  return { timeLeft, isContestAllowed };
}

export default useContestPeriod;
