import React from 'react';
import styled from 'styled-components';
import { Questions } from './QuestionsField';
import { Divider, Select as AntdSelect } from 'antd';
import { orderBy } from 'lodash';

const { Option } = AntdSelect;

interface Props {
  disabled?: boolean;
  questions?: Questions[];
  onChange?: (questions: Questions[]) => void;
  value?: boolean;
  onChangeRamificationField: (value: boolean) => void;
}

interface QuestionsAvaliable {
  questions: Questions[];
  currentQuestionId?: string;
  selectedQuestionId?: string;
}

export const RamificationField = ({
  disabled = false,
  questions = [],
  onChange,
  value = false,
  onChangeRamificationField
}: Props) => {
  const options = [
    {
      title: 'Não (A pesquisa será exibida de forma sequencial)',
      value: false
    },
    {
      title: 'Sim (A pesquisa será exibida de acordo com as respostas)',
      value: true
    }
  ];

  const handleGetTypeLabel = (type?: string) => {
    switch (type) {
      case 'multiple-select':
        return 'Múltipla escolha';
      case 'select':
        return 'Apenas uma escolha';
      case 'range':
        return '0 a 10';
      default:
        return 'Texto';
    }
  };

  const handleFilterShowQuestionsAvaliable = (data: QuestionsAvaliable) => {
    const ids = new Set();
    const filtered = data.questions.filter(
      (el) => el.id !== data.currentQuestionId
    );

    filtered.map((el) => {
      const exists = data.questions.find((item) =>
        item?.alternatives?.find((alt) => alt?.ramificationQuestionId === el.id)
      );
      if (!exists) ids.add(el.id);
    });

    const currentQuestion = data.questions.find(
      (el) => el.id === data.currentQuestionId
    );

    currentQuestion?.alternatives?.map((item) => {
      if (item?.ramificationQuestionId) ids.add(item?.ramificationQuestionId);
    });

    const opts = Array.from(ids.values()).map((opt) => {
      const question = data.questions.find((el) => el.id === opt);
      return {
        value: question?.id,
        title: question?.description
      };
    });

    return orderBy(opts, 'value', 'asc');
  };

  const handleChangeQuestion = (
    questions: Questions[],
    questionId: string,
    alternativeId: string,
    selectedQuestionId: string
  ) => {
    if (onChange) {
      onChange([
        ...questions.map((el) => {
          if (el.id === questionId) {
            const alternatives = el?.alternatives?.map((item) => {
              if (item.id === alternativeId) {
                return { ...item, ramificationQuestionId: selectedQuestionId };
              }
              return { ...item };
            });
            return { ...el, alternatives };
          }
          return { ...el };
        })
      ]);
    }
  };

  return (
    <Wrapper>
      <Title>Ramificação de questões</Title>
      <React.Fragment>
        <Divider />
        <Select
          disabled={disabled}
          placeholder="Haverá ramificação?"
          value={String(value)}
          onChange={(value: any) => {
            onChangeRamificationField(String(value) === 'false' ? false : true);
          }}
        >
          {options.map((el) => (
            <Option key={String(el.value)} value={String(el.value)}>
              {el.title}
            </Option>
          ))}
        </Select>
      </React.Fragment>
      {questions.length < 2 && value && (
        <Message>
          Cadastre ao menos 2 questões para selecionar a ramificação
        </Message>
      )}
      {questions.length > 1 && value && (
        <React.Fragment>
          {questions
            .filter((el) => el?.type && !['text', 'range'].includes(el.type))
            .map((question) => {
              return (
                <React.Fragment key={question.id}>
                  <QuestionDescription>
                    Questão ({handleGetTypeLabel(question?.type)}):{' '}
                    {question.description}
                  </QuestionDescription>
                  {question?.alternatives && (
                    <React.Fragment>
                      {question.alternatives?.map((alternative) => {
                        return (
                          <React.Fragment>
                            <AlternativeDescription>
                              Alternativa: {alternative.description}
                            </AlternativeDescription>
                            {questions.length > 1 && (
                              <Select
                                disabled={disabled}
                                allowClear
                                placeholder="Selecionar questão da sequência"
                                value={
                                  question?.alternatives?.find(
                                    (el) => el.id === alternative.id
                                  )?.ramificationQuestionId
                                }
                                onChange={(value: any) => {
                                  handleChangeQuestion(
                                    questions,
                                    question.id!,
                                    alternative.id!,
                                    value
                                  );
                                }}
                              >
                                <Option key={'option-finish'} value={'finish'}>
                                  *Finalizar a pesquisa
                                </Option>
                                {handleFilterShowQuestionsAvaliable({
                                  questions,
                                  currentQuestionId: question.id,
                                  selectedQuestionId:
                                    alternative?.ramificationQuestionId
                                }).map((el) => (
                                  <Option
                                    key={String(el.value)}
                                    value={el.value}
                                  >
                                    {el.title}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  )}
                  <Divider />
                </React.Fragment>
              );
            })}
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
`;

export const Title = styled.p`
  margin: 0;
`;

export const Message = styled.p`
  width: 100%;
  text-align: center;
  margin: 20px auto;
  font-size: 11pt;
`;

export const Select = styled(AntdSelect)`
  .ant-select-selection {
    padding: 8px 11px 4px !important;
  }
`;

export const QuestionDescription = styled.p`
  width: 100%;
  margin: 10px 0;
`;

export const AlternativeDescription = styled.p`
  width: 100%;
  margin: 5px 0;
`;
