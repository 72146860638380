import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Breadcrumb, TitleDescription, Select } from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { FilterContainer, CleanButton } from '../../../components/PageFilter';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumIncentiveCampaignStatus,
  SortFindManyIncentiveCampaignInput,
  ValidityEnum
} from '../../../autogenerated/client/types';

import { CampanhaIncentivoListWrapper, Button } from './styles';
import { columns } from './columns';
import { message } from 'antd';
import { formatDate } from '@digi-tim-19/utils/build';
import { TablePagination } from 'components/TablePagination/TablePagination';

const configPage = {
  pageName: 'Campanhas e resultados',
  icon: 'trophy',
  description: 'Cadastramento de campanhas de incentivo e resultados.',
  messageDeletedSuccess: 'Campanha excluida com sucesso',
  textButtonNew: 'Cadastrar campanha',
  routeNew: routes.campanhasIncentivoCadastrar.mount(),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Incentivo', link: routes.campanhasIncentivoListar.mount() },
    {
      title: 'Campanhas e resultados',
      link: routes.campanhasIncentivoListar.mount()
    }
  ],
  perPage: 10
};

export const CampanhaIncentivoListar = () => {
  const history = useHistory();

  const [filterStatus, setFilterStatus] = useState();
  const getIncentiveCampaign = useClient('IncentiveCampaignPagination');
  const IncentiveCampaignRemoveById = useClient('IncentiveCampaignRemoveById');

  const [updateTable, setUpdateTable] = useState('');

  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getIncentiveCampaign
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: configPage.perPage,
          sort: SortFindManyIncentiveCampaignInput.IdDesc,
          filter: {
            status: filterStatus,
            validityEnum: ValidityEnum.Any
          }
        }
      })
      .then((ctx) => {
        setItems(ctx.result?.items);
      });
  }, [filterStatus, page, updateTable]); //eslint-disable-line

  const total = getIncentiveCampaign?.result?.pageInfo.itemCount;

  const data = items?.map((campaign: any) => {
    return {
      key: campaign?._id,
      title: campaign?.title,
      status: campaign?.status?.label,
      cadastrada: formatDate(campaign?.campaignValidity?.start, 'DD/MM/YYYY'),
      publicada: formatDate(campaign?.campaignValidity?.end, 'DD/MM/YYYY'),
      onEdit: () =>
        history.push(routes.campanhasIncentivoEditar.mount(campaign?._id)),
      onDelete: () => {
        IncentiveCampaignRemoveById.fetch({
          variables: { _id: campaign._id },
          afterMutate: /^IncentiveCampaign/
        }).then((ctx) => {
          message.success(configPage.messageDeletedSuccess);
          setUpdateTable(ctx.result?.recordId);
        });
      },
      onRead: () => history.push(routes.campanhasIncentivo.mount(campaign?._id))
    };
  });

  const resetFilters = () => {
    setFilterStatus(undefined);
    setPage(1);
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <CampanhaIncentivoListWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <FilterContainer>
          <Button to={routes.campanhasIncentivoCadastrar.mount()}>
            {configPage.textButtonNew}
          </Button>
          <Select
            placeholder="status"
            options={statusOptions}
            value={filterStatus}
            onChange={(e) => {
              setFilterStatus(e);
              setPage(1);
            }}
          />

          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        <TablePagination
          loading={getIncentiveCampaign.loading}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: configPage.perPage,
            current: page,
            total: total!,
            onChange: (e: any) => {
              setPage(e);
            }
          }}
        />
      </CampanhaIncentivoListWrapper>
    </PageTemplate>
  );
};

const statusOptions = [
  { label: 'COM RESULTADOS', value: EnumIncentiveCampaignStatus.WithResults },
  {
    label: 'RESULTADO COM TIM COINS',
    value: EnumIncentiveCampaignStatus.ResultWithPoints
  },
  { label: 'EM ANDAMENTO', value: EnumIncentiveCampaignStatus.InProgress },
  { label: 'EM APURAÇÃO', value: EnumIncentiveCampaignStatus.InVerification },
  { label: 'ENCERRADA', value: EnumIncentiveCampaignStatus.Closed }
];

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  count
  items {
    _id
    title
    status {
      label
    }
    campaignValidity {
      start
      end
    }
  }
`;
