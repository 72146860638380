import React, { useState } from 'react';
import styled from 'styled-components';
import { Context } from 'graphql-clientgen';
import { message, Modal } from 'antd';
import { Former, getBase64 } from '@digi-tim-19/components';

import { EnumFileKind, User } from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';

const FormWrapper = styled.div`
  .form-section-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 2rem;

    .form-infos > .FieldWrapper {
      margin-bottom: 15px;
    }

    .ant-upload-picture-card-wrapper {
      margin: 0;
    }
  }
`;

type TUserEditProfileProps = {
  closable?: Boolean;
  modalVisible: Boolean;
  setVisible: Function;
  user: any;
  setImgUser?: Function;
  onImageEdit?: Function;
};

export const UserEditProfile = ({
  closable = true,
  ...props
}: TUserEditProfileProps) => {
  const UserFillAvatar = useClient('UserFillAvatarId');
  const UserFillEmailSecondary = useClient('UserFillEmailSecondary');

  const [urlImage, setUrlImage] = useState(undefined);

  const handleCancel = () => props.setVisible(false);

  const initialValues = {
    name: props.user.name,
    email: props.user.email,
    emailSecondary: props.user.emailSecondary,
    avatarId: props.user.avatarId
  };

  return (
    <Modal
      closable={Boolean(closable)}
      title="Editar perfil"
      visible={!!props.modalVisible}
      onCancel={handleCancel}
      footer={[]}
    >
      <FormWrapper>
        <Former
          initialValues={initialValues}
          config={(renderProps) => {
            renderProps?.form?.getFieldState('emailSecondary')?.blur();

            return {
              fields: [
                {
                  inline: false,
                  className: 'form-infos',
                  list: [
                    {
                      name: 'name',
                      label: 'Nome',
                      type: 'text',
                      disabled: true
                    },
                    {
                      name: 'email',
                      label: 'E-mail',
                      type: 'text',
                      disabled: true
                    },
                    {
                      name: 'emailSecondary',
                      label: 'E-mail Pessoal',
                      type: 'text',
                      required: true,
                      validate: (value: string) => {
                        if (value.includes('timbrasil')) {
                          return 'E-mail pessoal não pode ser @timbrasil';
                        }
                        if (value === initialValues.email) {
                          return 'E-mail pessoal igual ao profissional';
                        }
                      }
                    }
                  ]
                },
                {
                  inline: false,
                  className: 'avatar',
                  list: [
                    {
                      name: 'avatarId',
                      label: '',
                      type: 'upload',
                      extraProps: {
                        kind: EnumFileKind.ProfileImage,
                        listType: 'picture-card',
                        hasPreview: true,
                        onSelectFile: (info: any) => {
                          getBase64(info.file.originFileObj, (url: any) => {
                            setUrlImage(url);
                          });
                        }
                      }
                    },
                    {
                      custom: {
                        render: () => {
                          return (
                            <span
                              style={{
                                position: 'absolute',
                                marginTop: '-45px',
                                left: '1px',
                                fontSize: '9pt'
                              }}
                            >
                              Insira sua foto aqui
                            </span>
                          );
                        }
                      }
                    }
                  ]
                }
              ],
              submitButton: {
                label: 'Atualizar'
              }
            };
          }}
          onSubmit={async ({ data }) => {
            const { avatarId, emailSecondary } = data;

            const handleFetch = (ctx: Context<any>) => {
              if (ctx.result) {
                if (urlImage) {
                  if (props.onImageEdit) {
                    props.onImageEdit(urlImage);
                  }
                }

                message.success('Perfil atualizado com sucesso');
                handleCancel();
              } else {
                message.error('Ocorreu um erro ao atualizar o perfil');
                console.error('ctx', ctx.errors);
              }
            };

            if (avatarId !== initialValues.avatarId) {
              UserFillAvatar.fetch({
                variables: { avatarId },
                afterMutate: /^User/
              }).then(handleFetch);
            }

            const isValideEmailSecondary =
              Boolean(emailSecondary) &&
              emailSecondary !== initialValues.emailSecondary &&
              !emailSecondary.includes('timbrasil.com.br');

            if (isValideEmailSecondary) {
              UserFillEmailSecondary.fetch({
                variables: { emailSecondary },
                afterMutate: /^User/
              }).then(handleFetch);
            }
          }}
        />
      </FormWrapper>
    </Modal>
  );
};
