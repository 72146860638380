"use strict";

var _slicedToArray = require("/home/jenkins/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = require("react");

exports.useWindowSize = function () {
  var _react_1$useState = react_1.useState([0, 0]),
      _react_1$useState2 = _slicedToArray(_react_1$useState, 2),
      size = _react_1$useState2[0],
      setSize = _react_1$useState2[1];

  react_1.useLayoutEffect(function () {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return function () {
      return window.removeEventListener('resize', updateSize);
    };
  }, []);
  return size;
};