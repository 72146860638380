import React from 'react';
import { TableActions } from '@digi-tim-19/components';

const getStatusLabel = (value?: string) => {
  switch (value) {
    case 'created':
      return 'Criado';
    case 'published':
      return 'Publicado';
    case 'finished':
      return 'Finalizado';
    default:
      return '-';
  }
};

export const columns = [
  {
    title: 'Título',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Categoria',
    dataIndex: 'category',
    key: 'category'
  },
  {
    title: 'Vigência',
    dataIndex: 'validity',
    key: 'validity'
  },
  {
    title: 'Solicitado por',
    dataIndex: 'author',
    key: 'author'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => <p>{getStatusLabel(text)}</p>
  },
  {
    title: 'Atualizado em',
    dataIndex: 'updatedAt',
    key: 'updatedAt'
  },
  {
    title: 'Ações',
    key: 'action',
    render: (text: DataKey, record: any) => (
      <TableActions
        data={record}
        confirmationMessage="Deseja excluir este documento?"
      />
    )
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
