import React from 'react';
import { Breadcrumb, TitleDescription, Button } from '@digi-tim-19/components';
import { clickDownload } from '@digi-tim-19/utils/build';

import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { useCurrentUser } from 'hooks/user/useCurrentUser';

export const RelatorioPontosExpirados = () => {
  const { result: user } = useCurrentUser();

  const expirePoints = useClient('DownloadExpirePoints');
  const debitExpirePoints = useClient('DownloadDebitExpirePoints');

  const isUserAdmin = user?.roleGroup?._id.includes('adm_trade');

  function handleFetch(ctx: any) {
    if (ctx.result && ctx.result.url) {
      return clickDownload(ctx.result.url, true);
    }

    return console.error(ctx.errors);
  }

  function handleClickExpire() {
    expirePoints.fetch().then(handleFetch);
  }

  function handleClickDebit() {
    debitExpirePoints.fetch().then(handleFetch);
  }

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Relatórios' },
            { title: 'TIM Coins' }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType="line-chart"
          title="TIM Coins"
          description=""
        />

        {isUserAdmin && (
          <Button
            icon="download"
            loading={expirePoints.loading}
            onClick={handleClickExpire}
          >
            {expirePoints.loading ? 'Carregando...' : 'TIM Coins Expirados'}
          </Button>
        )}

        {isUserAdmin && (
          <Button
            icon="download"
            loading={debitExpirePoints.loading}
            onClick={handleClickDebit}
          >
            {debitExpirePoints.loading
              ? 'Carregando...'
              : 'Débitos de campanha'}
          </Button>
        )}
      </>
    </PageTemplate>
  );
};
