import { TypeCard } from '@digi-tim-19/components';
import React from 'react';
import styled, { ThemeConsumer } from 'styled-components';

const Wrapper = styled.div<PreviewProps>`
  background: #fff;
  border-radius: 5px;
  height: max-content;
  padding: 10px;
  width: 200px;

  div {
    border-radius: 5px;
  }

  .banner {
    width: 100%;
    height: 55px;
    background: ${({ position, theme }) =>
      position === '5' ? theme.blue : '#ccc'};
  }
  .banner_footer {
    width: 100%;
    height: 45px;
    background: ${({ position, theme }) =>
      position === '7' ? theme.blue : '#ccc'};
  }

  .cards {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;

    div {
      height: 40px;
    }
  }
  .card_large {
    width: 30%;
    background: ${({ position, theme }) =>
      position === '1' ? theme.blue : '#ccc'};
  }
  .card_medium {
    width: 20%;
  }

  .container_card_stories {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    display: flex;
    gap: 5px;
  }
  
  .card_stories {
    width: 25%;
    background: ${({ theme }) => theme.blue};
  }

  .card_medium1 {
    background: ${({ position, theme }) =>
      position === '2' ? theme.blue : '#ccc'};
  }
  .card_medium2 {
    background: ${({ position, theme }) =>
      position === '3' ? theme.blue : '#ccc'};
  }
  .cards_small {
    display: flex;
    flex-direction: column;
    width: 15%;
  }
  .card_small1 {
    background: ${({ position, theme }) =>
      position === '4' ? theme.blue : '#ccc'};
  }
  .card_small2 {
    background: ${({ position, theme }) =>
      position === '6' ? theme.blue : '#ccc'};
  }
  .card_small + .card_small {
    margin-top: 5px;
  }
`;

interface PreviewProps {
  position: string;
}

export const Preview = (props: PreviewProps) => {
  return (
    <Wrapper {...props}>
      <div className="banner"></div>
      <div className="cards">
        <div className="card_large"></div>
        <div className="card_medium card_medium1"></div>
        <div className="card_medium card_medium2"></div>
        <div className="cards_small">
          <div className="card_small card_small1"></div>
          <div className="card_small card_small2"></div>
        </div>
      </div>
      <div className="banner_footer"></div>
      {
        props?.position === String(TypeCard.STORIES)
        &&
        <div className='container_card_stories'>
          <div className="card_stories"></div>
          <div className="card_stories"></div>
          <div className="card_stories"></div>
          <div className="card_stories"></div>
        </div>
      }
    </Wrapper>
  );
};
