import styled from 'styled-components';

import { PageWrapper } from '../../components/Page/PageWrapper';
import { colors } from '@digi-tim-19/theme';

export const Wrapper = styled(PageWrapper)<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.blue};
  padding: ${(props) => (props.isMobile ? '' : '2em 4em')};
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const MeuDiaPdvWrapper = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  box-shadow: 5px 5px 25px #0003;
  padding: 2em 4em;
  margin: 0 2em;
  height: 100%;
  width: 100%;
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto 2em;
  width: 13em;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & button + button {
    background-color: white;
    color: ${colors.blue};
    border: 1px solid ${colors.blue};
    margin-top: 7rem;
    font-size: 0.8rem;
  }

  button {
    margin: 5px;
    font-weight: bold;
    padding: 10px;
    height: auto;
    width: 100%;
  }
`;
