import { useClient } from '../../autogenerated/client/client';
import * as React from 'react';

export function useIncentiveCampaign(id: string, appendToFragment: any) {
  const variables = { _id: id };

  const IncentiveCampaignOne = useClient('IncentiveCampaignFindById', {
    variables,
    appendToFragment
  });

  React.useEffect(() => {
    if (id) {
      IncentiveCampaignOne.fetch({ variables });
    }
  }, [id]); //eslint-disable-line

  return IncentiveCampaignOne;
}
