import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { message } from 'antd';
import {
  Former,
  SearchUser,
  LabelSelecionarPublico
} from '@digi-tim-19/components';
import { useDimensions } from '@digi-tim-19/utils/build';

import { useNotificationsCategory } from '../../hooks/categories/useNotificationsCategory';
import { useChannelsCategory } from '../../hooks/categories/useChannelsCategory';
import { useEventOptions } from '../../hooks/events/useEventOptions';
import { Divider } from '../../components/Former';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import { Material, EnumMaterialStatus } from '../../autogenerated/client/types';
import {
  EnumFileKind,
  EnumMaterialKind,
  EnumMaterialCommunicationItemsConfigKind
} from '../../autogenerated/client/types';
import { validateSelectPublic } from '../../utils/validateSelectPublic';
import { communicationCanEdit } from 'utils/communicationCanEdit';

import { Container } from './Cadastrar/stylesCadastrar';
import { RegionalProfilesField } from '../../components/RegionalProfilesSelection/RegionalProfilesField';
import { UploadMailing } from 'components/UploadMailing';

const WrapperLabel = styled.div`
  display: flex;
  flex: auto;
  margin-top: -30px;
`;

const MailingKind = EnumFileKind.Mailing;
// const DocKind = EnumFileKind.AnyDoc;
const ImageKind = EnumFileKind.NotificationImage;

export const Form = (props: TFormMaterialEditProps) => {
  const history = useHistory();
  const { isMobile } = useDimensions();
  const channelsOptions = useChannelsCategory();
  const categoryOptions = useNotificationsCategory();
  const { eventOptions, onSearch } = useEventOptions();
  const canEdit = communicationCanEdit(props.initialValues);

  const [publicSelected, setPublicSelected] = useState(false);
  const [categorySelected, setCategorySelected] = useState<string>();

  const isUpdate = !!props.initialValues;

  const createMaterialCommunication = useClient('CommunicationCreateOne');
  const updateMaterialCommunication = useClient('CommunicationUpdateOne');

  const initialValues = useMemo(() => {
    return {
      ...props.initialValues,
      mailing: (props.initialValues?.files || []).find(
        (el) => el && el.kind === MailingKind
      )?._id,
      thumbnail: (props.initialValues?.files || []).find(
        (el) => el && el.kind === ImageKind
      )?._id,
      categories: (props.initialValues?.categories || []).find(
        (el) => el && el.name
      )?._id,
      status: props.initialValues?.status?._id,
      validity: props.initialValues?.validity?.start,
      mailingApprovers: props.initialValues?.mailingApprovers || []
    };
  }, []);

  return (
    <Container style={{ pointerEvents: canEdit ? 'auto' : 'none' }}>
      <Former
        initialValues={initialValues}
        config={({ form }) => {
          form.registerField('mailing', () => {}, {});
          return {
            fields: [
              {
                className: 'header',
                inline: !isMobile,
                list: [
                  {
                    name: 'validity',
                    label: 'Programar publicação',
                    type: 'datePicker',
                    required: true,
                    extraProps: {
                      showTime: { format: 'HH:mm' },
                      format: 'DD/MM/YYYY HH:mm',
                      noBeforeDate: true
                    },
                    maxWidth: '250px'
                  },
                  {
                    name: 'categories',
                    label: 'Categoria do menu',
                    options: categoryOptions,
                    required: true,
                    maxWidth: '250px',
                    afterChange: setCategorySelected
                  },
                  {
                    name: 'thumbnail',
                    label: '',
                    type: 'upload',
                    extraProps: {
                      kind: 'notification_image',
                      CTA: 'IMAGEM DO CARD',
                      allowedExtensions: ['image/png', 'image/jpeg']
                    }
                  }
                ]
              },
              categorySelected === 'menu:eventos' && {
                name: 'associatedEvents',
                label: 'Eventos associados',
                type: 'multiSelect',
                options: eventOptions,
                extraProps: { onSearch },
                maxWidth: '100%'
              },
              {
                custom: <Divider />
              },
              {
                name: 'title',
                label: 'Título',
                required: true,
                validate: (value: any) => {
                  const format = /[#%&;]/;
                  if (format.test(value))
                    return 'Os caracteres #%&; não são permitidos';

                  if (value) {
                    return !(value.length > 110)
                      ? undefined
                      : 'Máximo de 110 caracteres';
                  }

                  return undefined;
                }
              },
              {
                name: 'description',
                label: 'Descrição',
                type: 'textarea',
                extraProps: {
                  rows: 5
                },
                validate: (value: any) => {
                  const format = /[#%&]/;
                  if (format.test(value))
                    return 'Os caracteres #%& não são permitidos';

                  if (value) {
                    return !(value.length > 1200)
                      ? undefined
                      : 'Máximo de 1200 caracteres';
                  }

                  return undefined;
                }
              },
              {
                custom: <Divider />
              },
              {
                name: 'notificationLink',
                label: 'Link para onde a notificação deve encaminhar o usuário',
                validate: (value: any) => {
                  if (value) {
                    return !(value.length > 1200)
                      ? undefined
                      : 'Máximo de 1200 caracteres';
                  }

                  return undefined;
                }
              },
              {
                list: [
                  {
                    label: 'Palavra-chave',
                    name: 'tags',
                    type: 'tags',
                    extraProps: {
                      maxTags: 10,
                      maxTagSize: 110
                    }
                  }
                ]
              },
              {
                custom: <Divider />
              },
              {
                className: 'form__footer',
                inline: !isMobile,
                list: [
                  {
                    className: 'selectPublic',
                    custom: (
                      <RegionalProfilesField
                        disabled={!canEdit}
                        fieldConfig={{
                          initialValue:
                            props.initialValues?.availableAtRegionalProfiles
                        }}
                      />
                    )
                  },
                  {
                    list: [
                      {
                        custom: (
                          <UploadMailing
                            file={
                              props.initialValues?.files?.find(
                                (item) => item?.kind === EnumFileKind.Mailing
                              ) as any
                            }
                            onChange={(_id) => form.change('mailing', _id)}
                          />
                        )
                      },
                      {
                        name: 'mailingApprovers',
                        label: 'Aprovadores mailing',
                        options: channelsOptions,
                        extraProps: {
                          mode: 'multiple'
                        }
                      }
                    ]
                  },
                  {
                    label: '',
                    name: 'availableForUsers',
                    placeholder: 'BUSCAR NOME OU E-MAIL',
                    component: SearchUser
                  }
                ]
              },
              publicSelected && {
                custom: (
                  <WrapperLabel>
                    <LabelSelecionarPublico />
                  </WrapperLabel>
                )
              },
              {
                custom: <Divider />
              },
              {
                className: 'form__approvalFlow',
                list: [
                  {
                    name: 'approvalFlow',
                    label: 'Fluxo de Aprovação',
                    type: 'switch'
                  }
                ]
              }
            ],
            submitButton: {
              label: 'Enviar',
              onClick: (formerRef: any) => {
                setPublicSelected(validateSelectPublic(formerRef));
              }
            },
            cancelButton: {
              label: 'CANCELAR',
              onClick: () => history.push(routes.notificacoesGerenciar.path)
            }
          };
        }}
        onSubmit={async function ({ data }) {
          const {
            mailing,
            mailingApprovers,
            availableAtRegionalProfiles,
            availableForUsers,
            approvalFlow
          } = data;

          const onlyMailing =
            !availableAtRegionalProfiles && !availableForUsers && mailing;
          const withoutPublic =
            !availableAtRegionalProfiles && !mailing && !availableForUsers;
          const isAwaiting =
            props.initialValues?.status?.value ===
            EnumMaterialStatus.WaitingApproval;

          // Verifications:
          if (withoutPublic) {
            return message.error('Nenhum público selecionado!');
          }

          if (approvalFlow && onlyMailing && !mailingApprovers.length) {
            return message.error('Selecione os canais aprovadores do mailing!');
          }

          if (isAwaiting) {
            return message.error(
              'Não é possível editar! Informativo aguardando aprovação!'
            );
          }

          // Record
          const record = {
            availableAtRegions: [],
            availableAtChannels: [],
            availableAtRoleGroups: [],
            availableAtRegionalProfiles: data.availableAtRegionalProfiles,
            availableForUsers: data.availableForUsers,
            approvalFlow: data.approvalFlow,
            mailingApprovers: data.mailingApprovers,
            categories: [data.categories],
            kind: EnumMaterialKind.Communication,
            validity: {
              start: data.validity
            },
            fileIds: [data.thumbnail, data.mailing].filter(Boolean),
            title: data.title,
            tags: data.tags,
            description: data.description,
            notificationLink: data.notificationLink,
            communicationItemsConfig: [
              {
                kind: EnumMaterialCommunicationItemsConfigKind.Notification
              }
            ],
            associatedEvents: data.associatedEvents
          };

          // Update/Create
          if (isUpdate && data._id) {
            await updateMaterialCommunication
              .fetch({
                variables: {
                  communicationId: data._id,
                  record
                }
              })
              .then((ctx) => {
                if (!ctx.errors) {
                  history.push(routes.notificacoesGerenciar.mount());
                }
              });
          } else {
            await createMaterialCommunication
              .fetch({
                variables: { record }
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success('Notificação cadastrada com sucesso');
                  history.push(routes.notificacoesGerenciar.path);
                } else {
                  message.error('Erro ao cadastrar a notificação');
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
