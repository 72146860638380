import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { message, Input } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';
import { cpf } from '@digi-tim-19/utils';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { CleanButton } from '../../../components/PageFilter';
import { usePlatformName } from 'hooks/user/usePlatform';
import { useChannelsCategory } from '../../../hooks/categories/useChannelsCategory';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';

import { columns } from './columns';

const { Search: AntdSearch } = Input;

// Styles
const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 350px));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;
`;

export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const configPage = {
  pageName: 'Aprovadores',
  icon: 'user',
  description: 'Gerenciar aprovadores',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Aprovadores', link: routes.home.mount() }
  ],
  perPage: 10
};

// Component
export const UsuariosAprovadores = () => {
  const { platformName } = usePlatformName();
  const channelsOptions = useChannelsCategory();

  const getSearchUser = useClient('UserSearch');
  const getUsers = useClient('UserPagination');
  const UserUpdate = useClient('UserUpdateOne');

  const [page, setPage] = useState(1);
  const [channelFilter, setChannelFilter] = useState();
  const [searchFilter, setSearchFilter] = useState<any>();
  const [userSearchIds, setUserSearchIds] = useState<any>();

  useEffect(() => {
    searchFilter &&
      getSearchUser
        .fetch({
          variables: {
            search: searchFilter || '',
            roleGroup: [
              `${platformName}:internos_${platformName}`,
              `${platformName}:adm_trade_${platformName}`
            ]
          }
        })
        .then((ctx) => {
          if (ctx.result) {
            const userIds = ctx.result.map((user) => user?._id);
            setUserSearchIds(userIds);
          }
        });
  }, [searchFilter]);

  useEffect(() => {
    getUsers.fetch({
      appendToFragment,
      variables: {
        page,
        perPage: configPage.perPage,
        filter: {
          _ids: userSearchIds,
          inApproverAtChannels: channelFilter,
          OR: [
            { roleGroup: `${platformName}:internos_${platformName}` },
            { roleGroup: `${platformName}:adm_trade_${platformName}` }
          ]
        }
      }
    });
  }, [page, channelFilter, userSearchIds, platformName]);
  const resultUsers = getUsers.result?.items || [];
  const total = getUsers?.result?.pageInfo.itemCount;

  const resetFilters = () => {
    setPage(1);
    setChannelFilter(undefined);
    setSearchFilter(undefined);
    setUserSearchIds(undefined);
  };

  const data = resultUsers.map((user) => {
    const isUserApprover = user?.isApprover;

    return {
      avatar: user?.avatar?.signedUrl,
      name: user?.name,
      CPF: cpf.format(user?.CPF as string),
      channel: user?.channels!.find((el) => el),
      roleGroup: user?.roleGroup?.name,
      switchValue: isUserApprover || false,
      onSwitch: () => {
        const record = {
          isApprover: !isUserApprover,
          approverAtChannels: []
        };

        UserUpdate.fetch({
          variables: { record, filter: { _id: user?._id } },
          afterMutate: /^User/
        }).then(() => {
          const msg = isUserApprover
            ? `${user?.name} não é mais um aprovador.`
            : `Você tornou ${user?.name} um aprovador.`;

          message.success(msg);
        });
      },
      select: {
        options: channelsOptions,
        value: user?.approverAtChannels,
        style: { margin: '0 15px', width: 300, height: 55 },
        onSelect: (channels: string[]) => {
          const isChannelEmpty = !!channels.length;
          const record = {
            isApprover: isChannelEmpty,
            approverAtChannels: channels
          };

          UserUpdate.fetch({
            variables: { record, filter: { _id: user?._id } },
            afterMutate: /^User/
          }).then(() => {
            message.success(
              `Você alterou canais de aprovação de ${user?.name}`
            );
          });
        }
      }
    };
  });

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <FilterContainer>
          <Select
            placeholder="Canais"
            options={channelsOptions}
            value={channelFilter}
            onChange={(e) => {
              setPage(1);
              setChannelFilter(e);
            }}
          />
          <Search
            placeholder="Busca pelo nome de usuário"
            loading={getSearchUser.loading || getUsers.loading}
            onSearch={(value) => setSearchFilter(value)}
            allowClear
          />

          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        {getUsers.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: configPage.perPage,
              current: page,
              total: total!,
              onChange: (e) => setPage(e)
            }}
          />
        )}
      </>
    </PageTemplate>
  );
};

// Extras
const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    name
    email
    CPF
    channels
    approverAtChannels
    isApprover
    userRoles
    roles
    roleGroup {
      name
    }
    avatar {
      signedUrl
    }
  }
`;
