import { Button } from '@digi-tim-19/components';
import { Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import styled from 'styled-components';
import { CarouselForm } from './components/CarouselForm';
import { VideoModal } from './components/VideoModal';

interface Attachment {
  title?: string;
  signedUrl: any;
}

interface PostProps {
  images?: [Attachment];
  videos?: [Attachment];
  text?: string;
}

export const Post = (props: PostProps) => {
  const { images, videos, text } = props;
  const hasImages = images && images.length > 0;
  const hasVideos = videos && videos?.length > 0;

  return (
    <React.Fragment>
      <PostContainer>
        {hasImages && (
          <React.Fragment>
            <ImageContainer resize={Boolean(hasVideos || text)}>
              <Title>Imagem publicada</Title>
              {images && images.length === 1 ? (
                <img src={images[0].signedUrl} alt="Imagem publicada" />
              ) : (
                <CarouselForm images={images} />
              )}
            </ImageContainer>
          </React.Fragment>
        )}
        {(text || hasVideos) && (
          <SectionContainer
            resize={Boolean(hasImages && (text || hasVideos))}
            direction={Boolean(text && hasVideos && !hasImages)}
          >
            {text && (
              <TextContainer resize={Boolean(text && hasVideos && hasImages)}>
                <Title>Texto publicado</Title>
                <TextArea
                  rows={Boolean(hasImages && text && !hasVideos) ? 12 : 6}
                  disabled={true}
                  value={text}
                />
              </TextContainer>
            )}
            {hasVideos && (
              <VideoContainer resize={Boolean(text && hasVideos && hasImages)}>
                <Title>Vídeo publicado</Title>
                <ButtonContainer>
                  <VideoModal videos={videos} />
                </ButtonContainer>
              </VideoContainer>
            )}
          </SectionContainer>
        )}
      </PostContainer>
    </React.Fragment>
  );
};

const PostContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 350px;
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (max-width: 600px) {
    flex-direction: column;
    max-height: max-content;
  }
`;

const Title = styled.div`
  width: 100%;
  margin-bottom: 15px;
  color: ${(props) => props.theme.gray};
`;

const ImageContainer = styled.div<{ resize?: boolean }>`
  width: ${(props) => (props?.resize ? '50%' : '100%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    max-width: 300px;
    height: 300px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const SectionContainer = styled.div<{ resize?: boolean; direction?: boolean }>`
  width: ${(props) => (props?.resize ? '50%' : '100%')};
  height: 100%;
  display: flex;
  flex-direction: ${(props) => (props?.direction ? 'row' : 'column')};
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

const TextContainer = styled.div<{ resize?: boolean }>`
  width: 100%;
  height: ${(props) => (props?.resize ? '60%' : '100%')};

  textarea {
    resize: none;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const VideoContainer = styled.div<{ resize?: boolean }>`
  width: 100%;
  height: ${(props) => (props?.resize ? '40%' : '100%')};

  textarea {
    resize: none;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    height: 70px;
  }
`;
