import React from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';

import { Form } from '../FormManage';

const DocumentsWrapper = styled.div`
  width: 100%;
`;

export const DocumentosCadastrar = () => {
  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            { title: 'Documentos', link: routes.documentosGerenciar.mount() },
            { title: 'Cadastrar' }
          ]}
        />
      }
    >
      <DocumentsWrapper>
        <TitleDescription
          iconType="inbox"
          title="Documentos"
          description={
            'Consulta e cadastramento de documentos para as áreas: Para você, Processos e Para o cliente.'
          }
        />

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </DocumentsWrapper>
    </PageTemplate>
  );
};
