import React from 'react';
import { Breadcrumb, TitleDescription, Button } from '@digi-tim-19/components';
import { clickDownload } from '@digi-tim-19/utils/build';
import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { useCurrentUser } from 'hooks/user/useCurrentUser';
import { ModalReportTourUser } from 'components/ReportTour/ModalReportTourUser';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

export const RelatorioUsuarios = () => {
  const { result: user } = useCurrentUser();
  const { loading, fetch } = useClient('DownloadUsersBase');

  const isUserAdmin = user?.roleGroup?._id.includes('adm_trade');

  function handleFetch(ctx: any) {
    if (ctx.result && ctx.result.url) {
      return clickDownload(ctx.result.url, true);
    }

    return console.error(ctx.errors);
  }

  function handleClick() {
    fetch().then(handleFetch);
  }

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Relatórios' },
            { title: 'Extração de usuários' }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType="line-chart"
          title="Extração de usuários"
          description=""
        />
        {isUserAdmin && (
          <ButtonContainer>
            <Button icon="download" loading={loading} onClick={handleClick}>
              {loading ? 'Extraindo...' : 'Extrair base de usuários'}
            </Button>
            <ModalReportTourUser />
          </ButtonContainer>
        )}
      </>
    </PageTemplate>
  );
};
