import { Button, useQueryParam } from '@digi-tim-19/components';
import { Input, message } from 'antd';
import { useClient } from 'autogenerated/client/client';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 50%;
  min-width: 300px;
  margin: 100px auto 10px auto;
  padding: 40px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  gap: 20px;

  body {
    height: auto !imporant;
  }
`;

export const Unsubscribe = () => {
  const [id] = useQueryParam<any>('id');
  const [email, setEmail] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const client = useClient('EmailUnsubscribeAction');

  const handleSave = async (id: string | undefined, email: string) => {
    if (id) {
      setLoading(true);
      await client.fetch({
        variables: {
          id,
          email
        }
      });
      message.success('Alteração efetuada com sucesso!');
      setLoading((prevState) => !prevState);
    }
  };

  return (
    <Container>
      <span>Digite o seu e-mail</span>
      <Input
        disabled={loading}
        placeholder="exemplo@exemplo.com"
        value={email}
        onChange={(e: any) => {
          setEmail(e.target.value);
        }}
      />
      <Button
        disabled={!Boolean(email) || loading}
        loading={loading}
        onClick={async () => {
          await handleSave(id, email);
        }}
      >
        CANCELAR ASSINATURA
      </Button>
    </Container>
  );
};
