import { useClient } from 'autogenerated/client/client';
import { SortFindManyCategoryInput } from 'autogenerated/client/types';
import { usePlatformName } from 'hooks/user/usePlatform';

export const useDocumentCategories = () => {
  const { isResidencial } = usePlatformName();

  const categoryFind = useClient('CategoryFindMany', {
    variables: {
      limit: 1000,
      sort: SortFindManyCategoryInput.SortAsc,
      filter: {
        _ids: [
          'menu:argumentacao',
          'menu:residencial_suporte',
          'menu:compara_ai',
          'menu:ultrabook',
          'menu:ver_mais',
          'menu:residencial_comunicacao_cliente',
          'menu:para_voce',
          'menu:processos',
          'menu:suporte_e_processos',
          'menu:para_o_cliente',
          'menu:iot'
        ]
      }
    },
    fetchOnMount: true,
    cache: true,
    appendToFragment: `
      _id
      parentId
      name
      subCategories {
        _id
        parentId
        name

        subCategories {
          _id
          parentId
          name

          subCategories {
            _id
            parentId
            name

            subCategories {
              _id
              parentId
              name
            }
          }
        }
      }
    `
  });

  if (categoryFind.loading) return [];

  const restrictIds = [
    'menu:para_voce:campanhas',
    'menu:para_voce:comunicacao:informativos',
    'menu:para_voce:comunicacao:noticias',
    'menu:informativos'
  ];

  if (isResidencial) {
    restrictIds.push('menu:para_voce:comunicacao');
  }

  let menuCategory: any = [];

  function addCategoryInArray(category: any) {
    const selectable = () => {
      if (category?.subCategories?.length === 0 || !category.subCategories) {
        return true;
      }
      return false;
    };

    menuCategory.push({
      id: category?._id,
      value: category?._id,
      pId: category?.parentId,
      title: category?.name,
      selectable: selectable()
    });
  }

  function getCategories(categories: any) {
    categories.map((category: any) => {
      if (restrictIds.includes(category._id)) {
        return;
      }

      if (
        String(category._id).includes('menu:ver_mais') &&
        category?.subCategories?.length
      ) {
        category.subCategories = category.subCategories.filter((el: any) => {
          return ['menu:ver_mais:canal', 'menu:ver_mais:trade_news'].includes(
            String(el._id)
          );
        });
      }

      addCategoryInArray(category);

      if (category?.subCategories?.length) {
        return getCategories(category.subCategories);
      }
    });
  }

  getCategories(categoryFind.result);

  return menuCategory;
};
