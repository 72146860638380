import React from 'react';
import { useHistory } from 'react-router';
import { Modal, message } from 'antd';
import { useClient } from '@digi-tim-19/components';
import { routes } from 'config/routes';

interface Props {
  remuneration: any;
  onClose: () => void;
}

export const RemunerationRegulation = ({ remuneration, onClose }: Props) => {
  const history = useHistory();

  const mutationAcceptTerm = useClient('UserAcceptTerm');

  return (
    <Modal
      title="Regulamento"
      visible={true}
      onOk={() => {
        mutationAcceptTerm
          .fetch({
            variables: {
              term: `RemunerationRegulation-${remuneration?._id}`
            },
            afterMutate: /User/
          })
          .then(() => {
            message.success('Documento aceito com sucesso!');
            history.push(
              routes.remuneracaoVariavelMetasParticipanteVisualizar.mount(
                remuneration?._id
              ),
              { accepted: true }
            );
            onClose();
          })
          .catch(() =>
            message.error('Não foi possível aceitar este documento.')
          );
      }}
      onCancel={() => onClose()}
      cancelText="Não"
      okText="Sim"
      okButtonProps={{
        loading: mutationAcceptTerm.loading
      }}
      cancelButtonProps={{
        disabled: mutationAcceptTerm.loading
      }}
    >
      <p style={{ margin: 0 }}>
        Participante, precisamos do seu aceite no documento para ficar elegível.
      </p>
      <p style={{ margin: 0 }}>
        Confirmo que li o documento com as metas do período e concordo com os
        termos e condições da RO.
      </p>
    </Modal>
  );
};
