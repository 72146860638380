import * as React from 'react';
import { useHistory } from 'react-router';
import {
  VideoBanner,
  LastVideoSection,
  LoadingIndicator,
  PlaylistSection
} from '@digi-tim-19/components';
import { orderBy } from 'lodash';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumMaterialKind,
  SortFindManyMaterialInput
  // SortFindManyEventLiveInput
} from '../../autogenerated/client/types';

export const OnTube = () => {
  const history = useHistory();

  // const eventFind = useClient('EventLivePagination', {
  //   variables: {
  //     perPage: 1,
  //     sort: SortFindManyEventLiveInput.EventdateAsc,
  //     filter: {
  //       endTime: null
  //     }
  //   },
  //   fetchOnMount: true,
  //   appendToFragment: bannerFragment
  // });

  const lastDetachedVideo: any = useClient('MaterialFindMany', {
    variables: {
      limit: 1,
      sort: SortFindManyMaterialInput.UpdatedatDesc,
      filter: {
        kind: EnumMaterialKind.Video,
        inCategories: ['regex(^video_playlist)'],
        detachedVideo: true
      }
    },
    fetchOnMount: true,
    appendToFragment: lastVideosFragment
  });

  const lastVideosFind: any = useClient('MaterialFindMany', {
    variables: {
      limit: 3,
      sort: SortFindManyMaterialInput.IdDesc,
      filter: {
        kind: EnumMaterialKind.Video,
        inCategories: ['regex(^video_playlist)']
      }
    },
    fetchOnMount: true,
    appendToFragment: lastVideosFragment
  });

  const playlistsFind: any = useClient('CategoryFindMany', {
    variables: {
      limit: 0,
      filter: {
        parentId: 'video_playlist'
      }
    },
    fetchOnMount: true,
    appendToFragment: playlistFragment
  });

  // const eventResult = (eventFind.result?.items || []).find(
  //   (item: any) => item._id
  // ) || !eventFind.result?.items?.length;

  const detachedVideoResult = lastDetachedVideo?.result ?? [];

  const videoResult =
    (lastVideosFind?.result || []).find((item: any) => item._id) ||
    !lastVideosFind?.result?.length;

  const banner = detachedVideoResult.length
    ? detachedVideoResult[0]
    : videoResult;

  const bannerImg =
    videoResult?.files
      ?.filter((banner: any) => banner.kind === 'video_banner')
      .find((item: any) => item === item)?.signedUrl ??
    '/defaultImages/tim.jpg';
  const isCorpDetach = videoResult?.files?.some(
    (banner: any) => banner.kind === 'video_banner'
  );
  const bannerLoading = lastVideosFind.loading;

  const playlistSort = (playlistsFind?.result || []).sort((a: any, b: any) => {
    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
  });

  const orderPlaylist = (data: any[], configs: { _id: string, field: string, order: "asc" | "desc" }[]) => {
    return data.map((playlist: any) => {
      const config = configs.find(el => el._id === playlist._id);
      if(config && playlist?.materials?.items) {
        return {
          ...playlist,
          materials: {
            ...playlist.materials,
            items: orderBy(playlist.materials.items, config.field, config.order)
          }
        }
      }
      return playlist;
    });
  }

  return (
    <PageTemplate>
      <>
        {!bannerLoading && !!banner ? (
          <VideoBanner
            videoBanner={{
              defaultImageOntubeMobile: bannerImg,
              defaultImageOntubeDesktop: bannerImg,
              defaultImageLiveMobile: bannerImg,
              defaultImageLiveDesktop: bannerImg,
              _id: banner._id || '',
              createdAt: banner.createdAt || '',
              eventDate: banner.eventDate || '',
              kind: banner.kind || '',
              title: banner.title || '',
              description: banner.description || '',
              categories: banner.categories || [],
              files: banner.files || [],
              isCorpDetach
            }}
            onClickBanner={(_id) =>
              history.push(routes.videosPlayer.mount(_id))
            }
          />
        ) : (
          <LoadingIndicator large />
        )}

        {!!lastVideosFind?.result && lastVideosFind.result.length > 0 && (
          <LastVideoSection
            sectionTitle="Últimas publicações"
            lastVideos={lastVideosFind.result}
            loading={lastVideosFind.loading}
            onClickLastVideos={(_id) =>
              history.push(routes.videosPlayer.mount(_id))
            }
            actions={{
              pin: true,
              share: true
            }}
          />
        )}

        <PlaylistSection
          sectionTitle="Playlists"
          playlists={orderPlaylist(playlistSort, [{ _id: "video_playlist:integra", field: "numericId", order: "asc" }])}
          loading={playlistsFind.loading}
          onClickPlaylist={(videoID) =>
            history.push(routes.videosPlayer.mount(videoID))
          }
        />
      </>
    </PageTemplate>
  );
};

// const bannerFragment = `
//   items {
//     _id
//     createdAt
//     eventDate
//     kind
//     title
//     description

//     categories {
//       name
//     }

//     files {
//       kind
//       signedUrl
//     }
//   }
// `;

const lastVideosFragment = `
  _id
  title
  description
  createdAt

  views {
    count
  }

  likes {
    liked
    count
  }

  categories {
    _id
    name
  }

  files {
    kind
    signedUrl
  }
`;

const playlistFragment = `
  _id
  name
  parentId
  description

  materials(
    kind: video
    sort: ${SortFindManyMaterialInput.CreatedatDesc}
  ) {
    count
    items {
      _id
      numericId
      createdAt
      files {
        _id
        kind
        signedUrl
      }
    }
  }
`;
