import React from 'react';
import { Button } from '@digi-tim-19/components';
import { Col, Icon, Input, message } from 'antd';
import styled from 'styled-components';
import { AttachmentProps, UploadAttachment } from './UploadAttachment';
import { UploadFile } from 'antd/lib/upload/interface';
import { videoMimetypes, imageMimetypes } from './UploadAttachment';
import { Emoji } from 'components/Emoji';
import { useCreatePost } from './hooks/useCreatePost';

interface CreatePostProps {
  eventId?: string;
}

export interface CreatePostInterface {
  text?: string;
  imageFiles?: UploadFile[];
  videoFiles?: UploadFile[];
}

export const CreatePost = ({ eventId }: CreatePostProps) => {
  const [text, setText] = React.useState<string>('');
  const [images, setImages] = React.useState<UploadFile[]>([]);
  const [videos, setVideos] = React.useState<UploadFile[]>([]);
  const [files, setFiles] = React.useState<UploadFile[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { create } = useCreatePost();

  const handleCreatePost = async (variables: CreatePostInterface) => {
    setLoading(true);
    await create({ eventId, variables });
    message.success(
      'Publicação em análise, você receberá a notificação caso seja aprovada/reprovada',
      10
    );
    setLoading(false);
  };

  const handleChangeText = (value: string | undefined) => {
    setText(value || '');
  };

  const handleChangeFiles = ({
    imagesList = [],
    videosList = []
  }: AttachmentProps) => {
    let files: UploadFile[] = [];

    if (images.length + imagesList.length > 3) {
      message.error(
        `Por favor, envie no máximo 3 arquivos de imagem por postagem`
      );
    } else {
      files = [...files, ...imagesList];
      setImages((prevState: UploadFile[]) => [...prevState, ...imagesList]);
    }

    if (videos.length + videosList.length > 2) {
      message.error(
        `Por favor, envie no máximo 2 arquivos de vídeo por postagem`
      );
    } else {
      files = [...files, ...videosList];
      setVideos((prevState: UploadFile[]) => [...prevState, ...videosList]);
    }

    setFiles((prevState: UploadFile[]) => [...prevState, ...files]);
  };

  const handleRemoveFile = (file: UploadFile) => {
    if (imageMimetypes.includes(file.type)) {
      const imagesFiltered = images.filter((el) => el.uid !== file.uid);
      setImages([...imagesFiltered]);
    } else if (videoMimetypes.includes(file.type)) {
      const videosFiltered = videos.filter((el) => el.uid !== file.uid);
      setVideos([...videosFiltered]);
    }

    const filtered = files.filter((el) => el.uid !== file.uid);
    setFiles([...filtered]);
  };

  return (
    <>
      <Container>
        <InputContainer>
          <Input.Group className="input-container-text">
            <Col className="input-container-text-area">
              <Input.TextArea
                disabled={loading}
                rows={1}
                placeholder="Digite uma mensagem"
                value={text}
                onChange={(e: any) => handleChangeText(e.target.value)}
              />
            </Col>
            <Col xs={1} className="emoji-width">
              <Emoji
                disabled={loading}
                className="emoji-adjustment"
                textInput={text}
                setTextInput={handleChangeText}
              />
            </Col>
          </Input.Group>
          <UploadAttachment disabled={loading} onChange={handleChangeFiles} />
        </InputContainer>
        <ButtonContainer>
          <Button
            className="button-send"
            disabled={
              (text === '' && (!files || files.length === 0)) || loading
            }
            loading={loading}
            onClick={async () => {
              await handleCreatePost({
                text: text && String(text).trim(),
                imageFiles: images,
                videoFiles: videos
              });
              setText('');
              setFiles([]);
              setImages([]);
              setVideos([]);
            }}
          >
            Enviar
          </Button>
        </ButtonContainer>
      </Container>
      {files && files.length > 0 && (
        <PreviewUploadContainer>
          <PreviewAttachmentContainer>
            {files.map((file, index: number) => {
              return (
                <React.Fragment key={`attachment-${index}`}>
                  {videoMimetypes.includes(file.type) && (
                    <PreviewAttachment>
                      <Icon
                        type="close-circle"
                        className="close-icon"
                        onClick={() => handleRemoveFile(file)}
                      />
                      <PreviewVideo>
                        <Icon type="play-circle" />
                      </PreviewVideo>
                    </PreviewAttachment>
                  )}
                  {imageMimetypes.includes(file.type) && (
                    <PreviewAttachment>
                      <Icon
                        type="close-circle"
                        className="close-icon"
                        onClick={() => handleRemoveFile(file)}
                      />
                      <img
                        src={
                          file?.originFileObj &&
                          URL.createObjectURL(file.originFileObj)
                        }
                        alt={file.name}
                      />
                    </PreviewAttachment>
                  )}
                </React.Fragment>
              );
            })}
          </PreviewAttachmentContainer>
        </PreviewUploadContainer>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;

  .input-container-text {
    display: flex;
  }

  .input-container-text-area {
    width: 100%;
  }

  .emoji-width {
    width: max-content;
  }

  .emoji-adjustment {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14pt;
    margin: auto 10px;
  }

  .button-send {
    margin: auto;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: 500px) {
    display: flex;
    padding-top: 10px;
  }
`;

const PreviewUploadContainer = styled.div`
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c7c7c7;
  }
`;

const PreviewAttachmentContainer = styled.div`
  width: max-content;
  display: flex;
  padding: 10px 0;
  gap: 10px;
`;

const PreviewAttachment = styled.div`
  width: 150px;
  height: 130px;
  position: relative;

  .close-icon {
    color: ${(props) => props.theme.red};
    cursor: pointer;
    position: absolute;
    font-size: 14pt;
    right: 5px;
    top: 5px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const PreviewVideo = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30pt;
`;
