import React, { useMemo, useRef, useState } from 'react';
import { useClient } from '../../autogenerated/client/client';
import {
  SectionField,
  verdade,
  profileSimulationSession,
  ProfileSimulatorFields,
  OptionType
} from '@digi-tim-19/components';
import { RoleGroupIdm } from '../../autogenerated/client/types';
import { TreeNode } from 'antd/es/tree-select';

export function useProfileSimulatorOptions(_isCorp?: boolean) {
  const { channels, roleGroups, loading } = useServerData();

  const setFieldValue = useRef(
    (_name: string, _value: string | undefined) => {}
  );

  const localData = useLocalData();

  const options = useOptions({
    channels,
    roleGroups,
    channel: localData.channel,
    roleGroup: localData.roleGroup,
    region: localData.region,
    UF: localData.UF,
    setFieldValue: function (field, value) {
      setFieldValue.current(field, value);
      field === 'roleGroup' && localData.setRoleGroup(value || '');
      field === 'channel' && localData.setChannel(value || '');
      field === 'region' && localData.setRegion(value || '');
      field === 'UF' && localData.setUF(value || '');
    }
  });

  const fields: SectionField[] = [
    {
      custom: {
        render(rp) {
          // usamos este campo apenas para pegar o objeto da api do form 🌝
          setFieldValue.current = rp.form.change;
          return null;
        }
      }
    },
    {
      inline: true,
      name: 'channel',
      label: 'Canal',
      treeOptions: options.channels,
      required: true,
      afterChange(value) {
        localData.setChannel(value);
      },
      type: 'treeSelect',
      extraProps: {
        loading
      }
    },
    {
      inline: true,
      name: 'roleGroup',
      label: 'Cargo',
      options: options.roleGroups,
      required: true,
      afterChange(value) {
        localData.setRoleGroup(value);
      }
    },
    {
      inline: true,
      list: [
        {
          inline: false,
          name: 'region',
          label: 'Regional',
          options: options.regionals,
          required: options.type === 'region',
          afterChange(value) {
            localData.setRegion(value);
          },
          extraProps: {
            loading,
            disabled: options.type !== 'region'
          }
        },
        {
          inline: false,
          name: 'UF',
          label: 'UF',
          options: options.UFs,
          required: options.type === 'uf',

          extraProps: {
            disabled: options.type !== 'uf'
          }
        }
      ]
    }
  ];

  return {
    fields,
    initialValues: localData.initialValues,
    type: options.type,
    onSubmit: (data: any) => {
      profileSimulationSession.set(data);
      window.location.reload();
    }
  };
}

type Item = {
  _id: string;
  name: string;
  parentId?: string;
  idm?: RoleGroupIdm;
};

function useOptions({
  channels = [] as Item[],
  roleGroups = [] as Item[],
  channel = '',
  roleGroup = '',
  region = '',
  UF = '',
  setFieldValue = (_name: string, _value: string | undefined) => {}
}) {
  const stateRef = React.useRef({
    channels: [] as TreeNode[],
    roleGroups: [] as OptionType[],
    regionals: [] as OptionType[],
    UFs: [] as OptionType[],
    type: undefined as undefined | 'uf' | 'region',
    handledInitial: false
  });

  const [state, _setState] = useState(stateRef.current);

  function setState(key: keyof typeof state, value: any) {
    stateRef.current = { ...stateRef.current, [key]: value };
    _setState(stateRef.current);
  }

  // quando o form inicia mas os canais ainda nao foram carregados do banco
  // o valor inicial fica com o id, este hook coorige isso
  React.useEffect(() => {
    if (!channels.length || stateRef.current.handledInitial) return;

    const cache = {
      channel,
      roleGroup,
      region,
      UF
    };

    setFieldValue('channel', undefined);
    setFieldValue('roleGroup', undefined);
    setFieldValue('region', undefined);
    setFieldValue('UF', undefined);

    setTimeout(() => {
      setFieldValue('channel', cache.channel);
      setFieldValue('roleGroup', cache.roleGroup);
      setFieldValue('region', cache.region);
      setFieldValue('UF', cache.UF);
    }, 300);

    setTimeout(() => {
      stateRef.current.handledInitial = true;
    }, 1000);
  }, [channels.length]);

  // mount and set channels options
  React.useEffect(() => {
    setState('channels', buildTreeOptions(channels));
  }, [channels.length]);

  // mount and set roleGroup options - clear child values
  React.useEffect(() => {
    if (stateRef.current.handledInitial) {
      setFieldValue('roleGroup', undefined);
      setFieldValue('region', undefined);
      setFieldValue('UF', undefined);
    }
    const roleGroupOptions = roleGroups.filter(
      (el) =>
        el.parentId && el.parentId === channel && !el._id.includes('adm_trade')
    );

    setState('roleGroups', buildOptions(roleGroupOptions));
  }, [channel, channels.length]);

  // mount and set region or UF options
  React.useEffect(() => {
    if (stateRef.current.handledInitial) {
      setFieldValue('region', undefined);
      setFieldValue('UF', undefined);
    }

    const rg = roleGroups.find((el) => el._id === roleGroup);

    const idm = rg?.idm;
    const idmItems = rg?.idm?.items;
    const idmType = rg?.idm?.type;

    stateRef.current.regionals = [];
    stateRef.current.UFs = [];
    stateRef.current.type = idmType || undefined;

    if (!idm || !idmItems || !idmType) {
      setState('regionals', []);
      setState('UFs', []);
      return;
    }

    const items = buildOptions(verdade(idmItems));

    if (idmType === 'uf') {
      setState('UFs', items);
    } else if (idmType === 'region') {
      setState('regionals', items);
    }
  }, [roleGroup, channels.length]);

  return state;
}

function useLocalData() {
  const initialValues = useMemo<null | ProfileSimulatorFields>(() => {
    return profileSimulationSession.get();
  }, []);

  const [channel, setChannel] = useState<string>(() => {
    if (initialValues?.channel && typeof initialValues.channel === 'string') {
      return initialValues.channel;
    }
    return '';
  });

  const [roleGroup, setRoleGroup] = useState<string>(() => {
    if (
      initialValues?.roleGroup &&
      typeof initialValues.roleGroup === 'string'
    ) {
      return initialValues.roleGroup;
    }
    return '';
  });

  const [region, setRegion] = useState<string>(() => {
    if (initialValues?.region && typeof initialValues.region === 'string') {
      return initialValues.region;
    }
    return '';
  });

  const [UF, setUF] = useState<string>(() => {
    if (initialValues?.UF && typeof initialValues.UF === 'string') {
      return initialValues.UF;
    }
    return '';
  });

  return {
    initialValues,
    channel,
    setChannel,
    roleGroup,
    setRoleGroup,
    region,
    setRegion,
    UF,
    setUF
  };
}

function useServerData() {
  const _channels = useClient('ChannelFindMany', {
    cache: true,
    fetchOnMount: true,
    fragment: `_id name`
  });

  const _roleGroups = useClient('RoleGroupFindMany', {
    cache: true,
    fetchOnMount: true,
    fragment: `_id name parentId idm { type items {_id name } }`
  });

  const channels = verdade(_channels.result || []) as Item[];
  const roleGroups = verdade(_roleGroups.result || []) as Item[];

  return {
    channels,
    roleGroups,
    loading: _channels.loading || _roleGroups.loading
  };
}

function buildOptions(items?: null | any[]) {
  return verdade(items).map((el) => ({
    label: el.name || el._id,
    value: el._id
  }));
}

function buildTreeOptions(items?: null | any[]) {
  return verdade(items).map((el) => ({
    id: el?._id,
    label: el.name || el._id,
    value: el._id,
    pId: el?.parentId ? el?.parentId : 0
  }));
}
