import { fragmentMaterialCard, fragmentCampaignCard } from 'utils/fragmentCard';

export const fragmentRecentActivities = (category: string | undefined) => {
  return category
    ? `
      items {
        createdAt
        material(filter: {allCategories: ["regex(^${category})"]}) {
          ${fragmentMaterialCard}
        }
        ${
          category === 'menu:para_voce'
            ? `campaign {${fragmentCampaignCard}}`
            : ''
        }
      }
    `
    : `
      items {
        createdAt
        material {
          ${fragmentMaterialCard}
        }
        campaign {
          ${fragmentCampaignCard}
        }
      }
    `;
};
