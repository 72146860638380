import React from 'react';

const columns = [
  {
    title: 'Matrícula',
    dataIndex: 'matricula',
    key: 'matricula'
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Canal',
    dataIndex: 'channel',
    key: 'channel'
  },
  {
    title: 'Perfil',
    dataIndex: 'roleGroup',
    key: 'roleGroup'
  },
  {
    title: 'Regional',
    dataIndex: 'region',
    key: 'region'
  }
];

export default columns;
