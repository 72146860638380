import React from 'react';
import {
  Modal as AntdModal,
  Divider,
  Icon,
  message,
  Upload,
  Button as AntdButton,
  Select,
  Input
} from 'antd';
import { Button, LoadingIndicator, useUpload } from '@digi-tim-19/components';
import styled from 'styled-components';
import { isValidSize } from 'utils/file';
import { useClient } from '../../../autogenerated/client/client';
import { userIsTrade } from 'utils/validations';
import {
  EnumFileKind,
  SortFindManyEmailTemplateInput
} from 'autogenerated/client/types';

const { Option } = Select;

interface DataSelected {
  header: string;
  footer: string;
}

interface Props {
  user?: any;
  onSelect: (data: DataSelected) => void;
}

const Modal = styled(AntdModal)`
  .ant-modal-body {
    max-height: 350px;
    padding: 8px 24px !important;
    overflow: auto;
  }
`;

const FieldContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  gap: 10px;

  .ant-select-selection {
    padding: 10px 11px 4px !important;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  margin: 20px auto;
`;

const Container = styled.div`
  width: 100%;
`;

const UploadButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .ant-upload-select-text {
    width: 100%;
    button {
      width: 100%;
    }
  }
`;

const FileDescription = styled.div`
  width: 100%;
  margin: 5px 0;
  font-size: 9pt;
`;

const DetailContainer = styled.div`
  width: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  position: relative;

  > button {
    position: absolute;
    right: 0;
    width: max-content;
  }
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Title = styled.p`
  width: 100%;
  text-align: center;
`;

const fragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }

  items {
    _id
    title
    headerFileId
    footerFileId
    headerFile {
      title
      signedUrl
    }
    footerFile {
      title
      signedUrl
    }
  }
`;

export const ModalHeaderFooter = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState('default');
  const [title, setTitle] = React.useState('');
  const [headerFileList, setHeaderFileList] = React.useState<any>([]);
  const [footerFileList, setFooterFileList] = React.useState<any>([]);
  const [headerFile, setHeaderFile] = React.useState<any>(undefined);
  const [footerFile, setFooterFile] = React.useState<any>(undefined);
  const [loading, setLoading] = React.useState(false);
  const execute = useUpload(EnumFileKind.EmailAsset, true);
  const result = useClient('EmailTemplatePagination', {
    fragment,
    fetchOnMount: true,
    variables: {
      page: 1,
      perPage: 100,
      sort: SortFindManyEmailTemplateInput.CreatedatAsc
    }
  });
  const create = useClient('EmailTemplateCreateOne');
  const remove = useClient('EmailTemplateRemoveById');
  const imageWidth = 600;
  const imageHeight = 150;
  const limit = 2;
  const isTrade = userIsTrade(props?.user);

  const uploadFileWithValidation = async (
    e: any,
    callback: (e: any) => any
  ) => {
    if (!isValidSize(e.file, limit)) {
      message.error(`A imagem deve ter no máximo ${limit}mb`);
      return false;
    }

    if (!['image/jpeg', 'image/png', 'image/gif'].includes(e.file.type)) {
      message.error('Arquivo de imagem precisa ser jpg, jpeg, png ou gif');
      return false;
    }

    const image = new Image();
    const url = URL.createObjectURL(e.file);
    image.src = url;
    image.onload = () => {
      if (
        image.naturalHeight !== imageHeight ||
        image.naturalWidth !== imageWidth
      ) {
        message.error(
          `A imagem precisa ter ${imageHeight}px de altura e ${imageWidth}px de largura`
        );
        URL.revokeObjectURL(image.src);
        return false;
      }
      callback(e);
      return true;
    };
    return true;
  };

  const handleSave = async (
    title: string,
    headerFileData: any,
    footerFileData: any
  ) => {
    if (!headerFileData || !footerFileData) {
      message.error('Obrigatório ter header e footer');
      return;
    }

    let record: any = { title };
    setLoading(true);
    await execute(headerFileData, (headerFileId: string) => {
      record.headerFileId = headerFileId;
    });
    await execute(footerFileData, (footerFileId: string) => {
      record.footerFileId = footerFileId;
    });

    if (!Object.keys(record).length) {
      setLoading(false);
      return message.error('Falha ao salvar header e/ou footer');
    }

    await create
      .fetch({
        variables: { record },
        afterMutate: /^EmailTemplate/
      })
      .then(() => {
        message.success('Header e footer cadastrados com sucesso.');
        setHeaderFileList([]);
        setFooterFileList([]);
        setHeaderFile(undefined);
        setFooterFile(undefined);
        setTitle('');
      })
      .catch(() => {
        message.error('Falha ao salvar!');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <FieldContainer>
        <Select
          loading={result.loading}
          disabled={result.loading}
          value={selected}
          onChange={(value: string) => {
            const item = result?.data?.items?.find(
              (el) => String(el?._id) === String(value)
            );
            props.onSelect({
              header: item?.headerFile?.signedUrl || '',
              footer: item?.footerFile?.signedUrl || ''
            });
            setSelected(String(value));
          }}
        >
          <Option value="default" key={'option-default'}>
            Sem header e sem footer
          </Option>
          {result?.data?.items?.map((option, index: number) => {
            return (
              <Option value={String(option?._id)} key={`option-${index}`}>
                {option?.title}
              </Option>
            );
          })}
        </Select>
        {Boolean(isTrade) && (
          <AntdButton onClick={() => setOpen(true)}>
            <Icon type="form" />
          </AntdButton>
        )}
      </FieldContainer>
      <Modal
        title="Gestão de header e footer"
        visible={open}
        onCancel={() => setOpen(false)}
        cancelText="Fechar"
        okButtonProps={{
          hidden: true
        }}
      >
        <Container>
          <UploadButtonContainer>
            <Input
              style={{ width: '100%' }}
              placeholder="Título"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Upload
              name="imageHeader"
              listType="text"
              accept={'.jpg, .jpeg, .png, .gif'}
              multiple={false}
              fileList={headerFileList}
              beforeUpload={() => true}
              customRequest={async (e: any) => {
                await uploadFileWithValidation(e, (data: any) => {
                  if (!data || !data?.file) return;
                  setHeaderFileList([data.file]);
                  setHeaderFile(data);
                });
              }}
              showUploadList={false}
            >
              <AntdButton>
                <Icon type="upload" /> HEADER (600x150)
              </AntdButton>
            </Upload>
            {headerFile?.file && (
              <FileDescription>
                Header: {headerFile?.file?.name}
              </FileDescription>
            )}
            <Upload
              name="imageFooter"
              listType="text"
              accept={'.jpg, .jpeg, .png, .gif'}
              multiple={false}
              fileList={footerFileList}
              beforeUpload={() => true}
              customRequest={async (e: any) => {
                await uploadFileWithValidation(e, (data: any) => {
                  if (!data || !data?.file) return;
                  setFooterFileList([data.file]);
                  setFooterFile(data);
                });
              }}
              showUploadList={false}
            >
              <AntdButton>
                <Icon type="upload" /> FOOTER (600x150)
              </AntdButton>
            </Upload>
            {footerFile?.file && (
              <FileDescription>
                Footer: {footerFile?.file?.name}
              </FileDescription>
            )}
            <Button
              loading={loading}
              disabled={
                loading || !Boolean(title) || !headerFile || !footerFile
              }
              onClick={async () =>
                await handleSave(title, headerFile, footerFile)
              }
            >
              Adicionar
            </Button>
          </UploadButtonContainer>
        </Container>
        <Divider style={{ margin: '10px 0' }} />
        <Title>
          Lista de headers e footers (Total: {result?.data?.items?.length || 0})
        </Title>
        <Divider style={{ margin: '10px 0' }} />
        {result?.loading ? (
          <LoadingContainer>
            <LoadingIndicator />
          </LoadingContainer>
        ) : (
          <>
            {result?.data?.items?.length ? (
              <>
                {result.data.items.map((item: any, index: number) => {
                  return (
                    <>
                      {index > 0 && <Divider />}
                      <DetailContainer>
                        <span>{item.title}</span>
                        <AntdButton
                          onClick={async () => {
                            await remove
                              .fetch({
                                variables: { _id: item._id },
                                afterMutate: /^EmailTemplate/
                              })
                              .then(() => {
                                message.success('Removido com sucesso!');
                              })
                              .catch(() => {
                                message.error('Falha ao remover');
                              });
                          }}
                        >
                          <Icon type={remove.loading ? 'loading' : 'delete'} />
                        </AntdButton>
                      </DetailContainer>
                      <Item>
                        <img
                          src={item?.headerFile?.signedUrl}
                          alt="header template e-mail"
                        />
                        <img
                          src={item?.footerFile?.signedUrl}
                          alt="footer template e-mail"
                        />
                      </Item>
                    </>
                  );
                })}
              </>
            ) : (
              <p
                style={{
                  margin: '20px auto',
                  textAlign: 'center',
                  fontStyle: 'italic',
                  fontSize: '11pt'
                }}
              >
                Não há itens para exibir.
              </p>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
