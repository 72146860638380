import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
  background: #ededed;
  padding: 20px;
  input:read-only {
    background: #f8f8f8;
  }

  .justification-upload {
    justify-content: start !important;
  }
`;

export const FieldContainer = styled.div`
  width: 100%;
  padding-left: 5px;
`;

export const FieldTitle = styled.h3`
  color: #565656;
  font-size: initial;
`;

export const FieldDownload = styled.a`
  display: flex;

  &:hover {
    background-color: #b8c7cc;
  }

  i {
    display: flex;
    align-items: center;
    padding-right: 10px;
    color: #565656;
  }
`;

export const LoadingMyContestationContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
