import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  LoadingIndicator,
  Table,
  TableActions,
  TitleDescription
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { CleanButton, FilterContainer, Wrapper, Search } from './styles';
import { useClient } from '../../../autogenerated/client/client';
import { useHistory } from 'react-router';
import moment from 'moment';

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    title
    questions
    validity {
      start
      end
    }
    answers {
      userId
      researchId
      answers
    }
    createdAt
    updatedAt
  }
`;

const columns = [
  {
    title: 'Pesquisa',
    dataIndex: 'title',
    key: 'title',
    render: (title: any) => <p>{title || ''}</p>
  },
  {
    title: 'Questões',
    dataIndex: 'questions',
    key: 'questions',
    render: (data: any) => <p>{data?.length || ''}</p>
  },
  {
    title: 'Validade',
    dataIndex: 'validity',
    key: 'validity',
    render: (data: any) => {
      return (
        <p>
          {data && data?.start && data?.end
            ? `${moment(data.start).format('DD/MM/YYYY HH:mm')} até ${moment(
                data.end
              ).format('DD/MM/YYYY HH:mm')}`
            : '-'}
        </p>
      );
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: any) => (
      <p style={{ color: status ? 'green' : 'red' }}>
        {status ? 'Respondida' : 'Não respondida'}
      </p>
    )
  },
  {
    title: 'Data de criação',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => (
      <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>
    )
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_: any, record: any) => <TableActions data={record} />
  }
];

export const ResearchList = () => {
  const history = useHistory();
  const getData = useClient('ResearchPagination');
  const [page, setPage] = React.useState(1);
  const [filterTitle, setFilterTitle] = React.useState();
  const [data, setData] = React.useState<any>([]);
  const perPage = 10;
  const total = getData?.result?.pageInfo?.itemCount || 0;

  const resetFilters = () => {
    setFilterTitle(undefined);
    setPage(1);
  };

  const handleGetData = (filterTitle: any) => {
    getData
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            title:
              !filterTitle || filterTitle === ''
                ? undefined
                : `regex(${filterTitle})`
          }
        }
      })
      .then((response: any) => {
        const items = response?.result?.items?.map((el: any) => {
          const status = Boolean(el?.answers);
          const data = { ...el };

          if (!status) {
            data.hideOnRead = true;
            data.onEdit = () => {
              history.push(routes.researchResponder.mount(el?._id));
            };
          } else {
            data.onRead = () => {
              history.push(routes.researchResponder.mount(el?._id));
            };
          }

          return { ...data, status };
        });

        setData([...items]);
      });
  };

  React.useEffect(() => {
    handleGetData(filterTitle);
  }, [filterTitle, page]);

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Pesquisas' },
            { title: 'Listar' }
          ]}
        />
      }
    >
      <React.Fragment>
        <Wrapper>
          <TitleDescription
            iconType={'table'}
            title="Pesquisas"
            description="Listar e consultar pesquisas"
          />
        </Wrapper>
        <FilterContainer>
          <Search
            placeholder="Busca por título da pesquisa"
            style={{ width: 150 }}
            value={filterTitle}
            onChange={(e: any) => {
              setFilterTitle(e.target.value);
              setPage(1);
            }}
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        {getData.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e: any) => setPage(e)
            }}
          />
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
