import styled from 'styled-components';
import { FormContainer } from 'components/Former';
import { colors } from '@digi-tim-19/theme';

export const FormWrapper = styled(FormContainer as any)`
  .form-section-container {
    .header {
      flex-wrap: wrap !important;

      .component-upload {
        display: flex;
        flex-direction: column;
      }

      .name-validity {
        .ant-calendar-picker {
          width: 100% !important;
        }
      }

      > .FieldWrapper {
        margin-left: 20px;
        margin-right: 0 !important;
        &:first-of-type {
          margin: 0;
        }

        @media screen and (max-width: 768px) {
          margin: 0 0 30px !important;
        }
      }
    }

    .Footer {
      > div {
        justify-content: flex-start;
      }
      
      .component-upload {
        margin-top: 0;
      }

      .ant-upload-select-text span button {
        color: white !important;
      }
    }
  }

  .content-buttons {
    flex-basis: 0 !important;
  }

  .name-documento,
  .name-thumbnail {
    > span {
      display: flex;

      .anticon-loading {
        display: none;
      }
      .ant-upload-list-item-progress {
        display: none;
      }
      .anticon-close {
        right: -2px;
      }
    }
  }
`;

export const WrapperLabel = styled.div`
  margin-top: -30px;
  display: flex;
  flex: auto;
`;

export const NotFound = styled.p`
  color: #6c757d;
  font-size: 18px;
  text-align: center;
  width: 100%;
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CardItemWrapper = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1420px) {
    width: 25%;
  }

  @media (max-width: 1200px) {
    width: calc(100% / 3);
  }

  @media (max-width: 700px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const CardItem = styled.article`
  margin-bottom: 40px;
  width: 210px;

  .card-date {
    padding: 20px 20px 0px 20px;
    font-size: 8pt;
    color: ${({ theme }) => theme.blue};
  }

  .card-date span {
    font-weight: bold;
  }

  button {
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    width: 115px;
  }
`;

export const CardItemContent = styled.div`
  .card-content-wrapper {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.grayLight2};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 165px;
    word-break: break-all;

    @media (max-width: 940px) {
      height: 190px;
    }

    @media (max-width: 768px) {
      height: 165px;
    }

    & h2 {
      font-weight: bold;
      word-break: break-word;
      -webkit-line-clamp: 2;
      color: ${({ theme }) => theme.blue};
      font-size: 18px;
      padding: 10px 20px 0 20px;

      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }
  }

  img {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 150px;
    width: 100%;
  }
`;

export const CardButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 10px 0;
`;

export const SectionTitle = styled.h3`
  color: ${colors.blue};
  font-size: 22px;
`;

export const RemunerationBanner = styled.div`
  align-items: center;
  border-radius: 3px;
  display: flex;
  justify-content: center;

  img {
    height: auto;
    max-height: 400px;
    max-width: 1145px;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-gap: 5px;
  margin: 15px auto;

  @media (min-width: 768px) {
    max-width: 1145px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const RemunerationFAQ = styled.div`
  height: auto;
  padding: 15px;
  margin: 20px 0 30px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
`;

export const PDFButtons = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 30px;

  &,
  a {
    max-height: 30px;
  }
`;

export const PDFDownload = styled.a`
  align-items: center;
  background: ${(props) => props.theme.blue};
  border-radius: 2px;
  color: ${(props) => props.theme.white};
  display: block;
  margin-right: 20px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
`;

export const PDFViewer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-basis: 100%;
  max-width: 100%;
  max-height: max-content;
  margin: 20px auto;

  iframe {
    width: 100%;
    min-height: 100vh;
    border: none;
    overflow: auto;
  }
`;
