import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { makeField } from '@digi-tim-19/components';

const HtmlEditorWrapper = styled.div`
  width: 100%;
`;

export const HtmlEditor = (props: THtmlEditorProps) => {
  const { className } = props;

  return (
    <HtmlEditorWrapper
      className={cx(className, 'ui-flex HtmlEditor HtmlEditor_Wrapper')}
    >
      {makeField({
        key: 'html',
        field: {
          name: 'html',
          label: 'HTML',
          type: 'previewhtml',
          required: true,
          extraProps: {
            rows: 10
          }
        }
      })}
    </HtmlEditorWrapper>
  );
};

// export default HtmlEditor;

export type THtmlEditorProps = {
  className?: string;
  children?: React.ReactNode;
};
