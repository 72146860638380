import React, { useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Icon, message } from 'antd';
import { CorpUpload, Former } from '@digi-tim-19/components';
import { formatDate, useDimensions } from '@digi-tim-19/utils/build';
import { EnumCampaignContestationResponseStatus } from 'autogenerated/client/types';
import { useClient } from 'autogenerated/client/client';
import { routes } from 'config/routes';
import { Divider } from 'components/Former';
import Download from './Download';
import { FormContainer } from './styles';

const options = [
  {
    label: 'Procedente',
    value: EnumCampaignContestationResponseStatus.Founded
  },

  {
    label: 'Não Procedente',
    value: EnumCampaignContestationResponseStatus.Unfounded
  }
];

const appendToFragment = `
  campaign {
    title
    status {
      label
    }
  }
  response {
    status
    body
    responseFileIds
  }
  user {
    name
    channel
    region
    roleGroup
    matricula
  }
  files {
    downloadUrl
    signedUrl
    title
  }
`;

export const Form = () => {
  const { isMobile } = useDimensions();
  const { id }: any = useParams();
  const history = useHistory();

  const getContestation = useClient('CampaignContestationFindOne');
  const CampaignContestationResolve = useClient('CampaignContestationResolve');

  useEffect(() => {
    getContestation.fetch({
      variables: {
        filter: {
          _id: id
        }
      },
      appendToFragment
    });
  }, []);

  const contestation = getContestation?.result;

  const initValues = {
    campaignTitle: contestation?.campaign?.title,
    status: contestation?.response?.status,
    body: contestation?.response?.body,
    protocol: contestation?.protocol,
    name: contestation?.user?.name,
    channel: contestation?.user?.channel,
    region:
      contestation?.user?.region === 'THQ' ? 'BR' : contestation?.user?.region,
    role: contestation?.user?.roleGroup,
    subscription: contestation?.user?.matricula,
    indicator: contestation?.indicator,
    createdAt: formatDate(contestation?.createdAt, 'DD [de] MMMM [de] YYYY'),
    evidence: contestation?.files?.find((file) => file)?.title,
    title: `Portal Pra Conectar – Retorno da Contestação – Protocolo ${contestation?.protocol}`,
    responseFileIds: contestation?.response?.responseFileIds || null
  };

  return (
    <FormContainer>
      <Former
        initialValues={initValues}
        config={({ form }) => {
          form.registerField('responseFileIds', () => {}, {});
          return {
            fields: [
              {
                list: [
                  {
                    inline: !isMobile,
                    list: [
                      {
                        name: 'campaignTitle',
                        label: 'Título da Campanha',
                        type: 'text',
                        required: true,
                        extraProps: {
                          readOnly: true
                        }
                      },
                      {
                        name: 'protocol',
                        label: 'Protocolo',
                        type: 'text',
                        required: true,
                        maxWidth: isMobile ? '100%' : '20%',
                        extraProps: {
                          readOnly: true
                        }
                      }
                    ]
                  },
                  {
                    inline: !isMobile,
                    list: [
                      {
                        name: 'name',
                        label: 'Nome',
                        type: 'text',
                        required: true,
                        extraProps: {
                          readOnly: true
                        }
                      },

                      {
                        name: 'channel',
                        label: 'Canal',
                        type: 'text',
                        required: true,
                        extraProps: {
                          readOnly: true
                        }
                      },

                      {
                        name: 'region',
                        label: 'Região',
                        type: 'text',
                        required: true,
                        extraProps: {
                          readOnly: true
                        }
                      },
                      {
                        name: 'role',
                        label: 'Cargo',
                        type: 'text',
                        required: true,
                        extraProps: {
                          readOnly: true
                        }
                      }
                    ]
                  },
                  {
                    inline: !isMobile,
                    list: [
                      {
                        name: 'subscription',
                        label: 'Matrícula',
                        type: 'text',
                        required: true,
                        extraProps: {
                          readOnly: true
                        }
                      },
                      {
                        custom: (
                          <Download
                            downloadUrl={
                              contestation?.files?.find((file) => file)
                                ?.signedUrl
                            }
                          />
                        )
                      },
                      {
                        name: 'createdAt',
                        label: 'Data de envio',
                        type: 'text',
                        required: true,
                        extraProps: {
                          readOnly: true
                        }
                      }
                    ]
                  },
                  {
                    inline: !isMobile,
                    list: [
                      {
                        name: 'indicator',
                        label: 'Indicador',
                        type: 'textarea',
                        required: true,
                        extraProps: {
                          rows: 3,
                          readOnly: true
                        }
                      }
                    ]
                  }
                ]
              },
              {
                custom: <Divider />
              },
              {
                name: 'status',
                label: 'Status',
                type: 'select',
                options
              },
              {
                name: 'title',
                label: 'Título',
                type: 'text',
                required: true
              },
              {
                name: 'responseFileIds',
                className: 'justification-upload',
                custom: {
                  render: () => {
                    return (
                      <CorpUpload
                        children={
                          <>
                            <Icon type="upload" /> EVIDÊNCIAS DE CONTESTAÇÃO{' '}
                            {initValues?.responseFileIds?.length}
                          </>
                        }
                        initialValues={initValues?.responseFileIds || []}
                        onChange={(files: any) => {
                          if (!files || files.length === 0) {
                            form.change('responseFileIds', null);
                          } else {
                            form.change('responseFileIds', files);
                          }
                        }}
                      />
                    );
                  }
                }
              },
              {
                name: 'body',
                label: 'Justifique',
                type: 'textarea',
                extraProps: {
                  rows: 6
                },
                required: true
              }
            ],
            submitButton: {
              label: 'enviar'
            }
          };
        }}
        onSubmit={async ({ data }) => {
          const { title, status, body, responseFileIds } = data;

          await CampaignContestationResolve.fetch({
            variables: {
              _id: getContestation?.result?._id,
              title,
              status,
              body,
              responseFileIds
            }
          }).then((ctx) => {
            if (!ctx.result) {
              message.error('Não foi possível realizar essa ação');
            } else {
              message.success('Contestação solucionada com sucesso');
              history.push(routes.contestacaoListar.mount());
            }
          });
        }}
      />
    </FormContainer>
  );
};
