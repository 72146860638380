import React from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription,
  ProviderXlsxJson
} from '@digi-tim-19/components';
import { useReconheceWallet } from '../../../hooks/materials/useReconheceWallet';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { Form } from '../FormManage';

export const OrdemPagamentoEditar = () => {
  const configPage = {
    pageName: 'Ordem de Pagamentos',
    icon: 'trophy',
    description: 'Cadastramento de ordem de pagamentos.',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      {
        title: 'Ordem de Pagamentos',
        link: routes.ordemPagamentoListar.mount()
      }
    ]
  };

  const appendToFragment = `
    NFImageUrls {
     url
      _id
  }
  POImageUrls {
    _id
  }
  `;
  const { params } = useRouteMatch();
  const walletItem = useReconheceWallet(params?.id, appendToFragment);

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {walletItem.result && (
          <ProviderSelecionarPublico>
            <ProviderXlsxJson>
              <Form initialValues={walletItem?.result} />
            </ProviderXlsxJson>
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
