import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { PageTemplate } from '../../../components/Layout/PageTemplate';

import { PageWrapper } from '../styles';
import { Form } from '../Form';

const configPage = {
  pageName: 'Notícias',
  icon: 'read',
  description: 'Cadastro e consulta de notícias',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Notícias', link: routes.noticiasListar.mount() },
    { title: 'Listar', link: routes.noticiasListar.mount() },
    { title: 'Cadastrar', link: routes.noticiasCadastrar.mount() }
  ]
};

export const NoticiasCadastrar = () => {
  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <PageWrapper>
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
