import React from 'react';
import { Input } from 'antd';
import { DocsEditorForm, EditorItem } from './DocsEditorForm';
import styled from 'styled-components';

type CollapseHTML = {
  item: EditorItem;
  files: any;
  onChange: (data: EditorItem['data']) => void;
  previewMode?: boolean;
};

const CollapseHTML = (props: CollapseHTML) => {
  const { item, onChange } = props;

  if (item.data.kind !== 'link') return null;
  const { title, children } = item.data;

  if (props.previewMode) {
    return (
      <details style={{ width: '100%' }}>
        <summary>{title}</summary>
        <div style={{ width: '100%', paddingLeft: '1em' }}>
          <DocsEditorForm
            files={[]}
            previewMode={true}
            initialValues={children}
            onChange={() => {}}
          />
        </div>
      </details>
    );
  }

  return (
    <EditCollapse>
      <Input
        placeholder="Título do link"
        defaultValue={title}
        onChange={(e) =>
          onChange({ kind: 'link', children, title: e.target.value })
        }
      />
      <DocsEditorForm
        size="small"
        files={props.files}
        initialValues={children}
        previewMode={props.previewMode}
        onChange={(children) => {
          onChange({ kind: 'link', title, children });
        }}
      />
    </EditCollapse>
  );
};

export default CollapseHTML;

export const EditCollapse = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ccc;
  padding-left: 1em;
  gap: 8px;
  width: 100%;
`;
