import React from 'react';
import styled from 'styled-components';
import { Button } from '@digi-tim-19/components';
import { TextField } from './TextField';
import { SelectField } from './SelectField';
import { MultiSelectField } from './MultiSelectField';

export enum FieldTypeEnum {
  TEXT = 'text',
  SELECT = 'select',
  MULTI_SELECT = 'multi_select'
}

export interface FieldSelectOptionInterface {
  label: string;
  value: string;
}

export interface FieldInterface {
  typeField:
    | FieldTypeEnum.TEXT
    | FieldTypeEnum.SELECT
    | FieldTypeEnum.MULTI_SELECT;
  dataKey: string;
  regexSearch?: boolean;
  placeholder?: string;
  debounce?: boolean;
  value?: any;
  defaultValue?: string;
  options?: FieldSelectOptionInterface[];
  config?: {
    mask?: string;
  };
  onChangeSearch?: (...args: any) => void;
  onChangeInput?: (...args: any) => void;
}

export interface FilterSearchInterface {
  fields: FieldInterface[];
  center?: boolean;
  fieldInline?: boolean;
  loading?: boolean;
  disabled?: boolean;
  handleSearch?: (...args: any) => void;
}

const Field = (fieldProps: FieldInterface) => {
  switch (fieldProps.typeField) {
    case FieldTypeEnum.SELECT:
      return <SelectField {...fieldProps} />;
    case FieldTypeEnum.MULTI_SELECT:
      return <MultiSelectField {...fieldProps} />;
    default:
      return <TextField {...fieldProps} />;
  }
};

export const FilterSearch = ({
  fields,
  handleSearch,
  center = false,
  fieldInline = false,
  loading = false,
  disabled = false
}: FilterSearchInterface) => {
  const [start, setStart] = React.useState<boolean>(true);
  const [disabledButton, setDisabledButton] = React.useState<boolean>(true);
  const [fieldValues, setFieldValues] = React.useState<any>({});
  const [values, setValues] = React.useState<any>({});

  React.useEffect(() => {
    if (start) {
      handleStart();
    }
  }, [fieldValues]);

  const handleStart = () => {
    setStart(false);
  };

  const handleChangeValueSearch = (
    key: string,
    value: any,
    regexSearch: boolean
  ): void => {
    let newValue: any = {};
    let newValues: any = {};

    if (regexSearch && value) {
      const parsedValue = String(value).toUpperCase();
      newValue[key] = `regex(${parsedValue})`;
    } else {
      newValue[key] = value;
    }

    if (values && Object.keys(values).length > 0) {
      setValues({ ...values, ...newValue });
      newValues = { ...values, ...newValue };
    } else {
      setValues({ ...newValue });
      newValues = { ...newValue };
    }

    handleFilterSearch(newValues || {});
  };

  const handleChangeValueInput = (key: string, value: any): void => {
    let newValue: any = {};
    newValue[key] = value;

    if (fieldValues && Object.keys(fieldValues).length > 0) {
      setFieldValues({ ...fieldValues, ...newValue });
    } else {
      setFieldValues({ ...newValue });
    }
  };

  const handleResetFilters = (): void => {
    setFieldValues({});
    setValues({});

    handleFilterSearch({});
  };

  const handleFilterSearch = (values: any = {}) => {
    let newValues: any = {};
    for (const keyObject of Object.keys(values)) {
      if (values[keyObject] && values[keyObject] !== '') {
        newValues[keyObject] = values[keyObject];
      }
    }

    if (Object.keys(newValues).length > 0) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }

    if (handleSearch && !start) {
      handleSearch(newValues);
    }
  };

  return (
    <FilterContainer center={center}>
      <FieldContainer fieldInline={fieldInline}>
        {fields.map((field: FieldInterface, index: number) => {
          return (
            <Field
              {...field}
              key={`${field.dataKey}-${index}`}
              value={
                fieldValues && fieldValues[field.dataKey]
                  ? fieldValues[field.dataKey]
                  : undefined
              }
              onChangeSearch={handleChangeValueSearch}
              onChangeInput={handleChangeValueInput}
            />
          );
        })}
      </FieldContainer>
      <ButtonContainer>
        <Button
          loading={loading}
          disabled={disabledButton || disabled || loading}
          onClick={() => {
            handleResetFilters();
          }}
        >
          Limpar filtros
        </Button>
      </ButtonContainer>
    </FilterContainer>
  );
};

const FilterContainer = styled.div<{ center: boolean }>`
  margin: 10px 0px;
  display: flex;
  flex: auto;
  gap: 10px;
  flex-direction: ${({ center }) => (center ? 'column;' : 'row;')} @media only
    screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const FieldContainer = styled.div<{ fieldInline: boolean }>`
  display: flex;
  flex: auto;
  flex-wrap: ${({ fieldInline }) => (fieldInline ? 'wrap' : 'unset')};
  gap: 10px;

  & > * {
    flex: 1 1 25%;
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    & > * {
      flex: 1 1 45%;
    }
  }

  @media only screen and (max-width: 500px) {
    flex-wrap: wrap;
    & > * {
      flex: 1 1 100%;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
