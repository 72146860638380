import React from 'react';

import {
  CardInterno,
  CardIncentiveCampaign,
  LoadingIndicator
} from '@digi-tim-19/components';

import { useHistory } from 'react-router';
import { routes } from '../../config/routes';
import { EmptyData } from 'components/utils/Void';
import { getAccessButton } from 'utils/material';

type CardFavoritosProps = {
  itemsMaterials?: any[];
  itemsCampaigns?: any[];
  isLoading: boolean;
};

export const CardFavoritos = ({
  itemsMaterials,
  itemsCampaigns,
  isLoading
}: CardFavoritosProps) => {
  const history = useHistory();
  const isEmpty =
    (!itemsCampaigns || !itemsCampaigns.length) &&
    (!itemsMaterials || !itemsMaterials.length);
  const hasMaterial = !!itemsMaterials && !!itemsMaterials.length;
  const hasIncentiveCampaign = !!itemsCampaigns && !!itemsCampaigns.length;

  if (isLoading) return <LoadingIndicator />;

  if (isEmpty) return <EmptyData />;

  return (
    <>
      {hasMaterial && (
        <>
          {hasIncentiveCampaign && <h2>Meus Documentos</h2>}
          <CardInterno
            count={itemsMaterials?.length}
            items={itemsMaterials}
            defaultImage="/defaultImages/default-card-thumb.png"
            actions={(material) => {
              return {
                delete: true,
                share: true,
                accessButton: getAccessButton(material, history, routes)
              };
            }}
          />
        </>
      )}

      {hasIncentiveCampaign && (
        <>
          {hasMaterial && <h2>Minhas Campanhas de incentivo</h2>}
          <CardIncentiveCampaign
            defaultImage={'/defaultImages/default-card-thumb.png'}
            count={itemsCampaigns?.length}
            actions={{
              accessButton: {
                label: 'Acessar',
                action: (campaign) => {
                  history.push(routes.campanhasIncentivo.mount(campaign?._id));
                }
              },
              share: true,
              pin: true
            }}
            items={itemsCampaigns}
          />
        </>
      )}
    </>
  );
};
