import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Alert } from 'antd';
import { useProfileSimulationState } from '@digi-tim-19/components';

const ProfileSimulationAlertWrapper = styled.div`
  width: 300%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div.attrs({
  className: 'ProfileSimulationAlert_Container'
})`
  width: 100%;
  max-width: 300px;
`;

export const ProfileSimulationAlert = (props: TProfileSimulationAlertProps) => {
  const { className } = props;
  const info = useProfileSimulationState();

  if (!info.enabled) return null;

  return (
    <ProfileSimulationAlertWrapper
      className={cx(
        className,
        'ProfileSimulationAlert ProfileSimulationAlert_Wrapper'
      )}
    >
      <Container>
        <Alert
          message="Simulação habilitada."
          type="info"
          closeText="Cancelar"
          showIcon
          onClose={() => info.clear()}
        />
      </Container>
    </ProfileSimulationAlertWrapper>
  );
};

// export default ProfileSimulationAlert;

export type TProfileSimulationAlertProps = {
  className?: string;
  children?: React.ReactNode;
};
