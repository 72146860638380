import React from 'react';
import { TableActions } from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';

export const columns = [
  {
    title: 'Título',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Posição',
    dataIndex: 'position',
    key: 'position',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Ordem',
    dataIndex: 'sort',
    key: 'sort',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Vigência',
    dataIndex: 'validity',
    key: 'validity'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => (
      <p style={{ color: text === 'Ativo' ? colors.green : colors.red }}>
        {text}
      </p>
    )
  },
  {
    title: '',
    key: 'action',
    render: (text: DataKey, record: any) => (
      <TableActions
        data={record}
        confirmationMessage={'Deseja cancelar este banner?'}
      />
    )
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
