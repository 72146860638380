import React from 'react';
import styled from 'styled-components';

const Name = styled.div<TName>`
  display: flex;
  flex-direction: column;
  color: ${(props) => (props.active ? '#75C3F7' : '#707070')};
`;

const Position = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
`;

type TName = {
  active: boolean;
};

type ColumnsProps = {
  hasPartner: boolean;
};

export const columns: any = (props: ColumnsProps) => {
  const defaultTable = [
    {
      title: 'POSIÇÃO',
      dataIndex: 'position',
      key: 'position',
      render: (text: string) => <Position>{text}</Position>
    },
    {
      title: 'NOME',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => {
        return (
          <Name active={record.active}>
            <strong>{text}</strong>
            <small>{record.role}</small>
          </Name>
        );
      }
    },
    {
      title: 'TIM COINS/PRÊMIO',
      dataIndex: 'points',
      key: 'points',
      render: (text: string) => {
        return <p style={{ display: 'flex' }}>{text}</p>;
      }
    }
  ];

  const partnerItem = {
    title: 'PARCEIRO',
    dataIndex: 'partner',
    key: 'partner',
    render: (text: string) => {
      return <p>{text || '-'}</p>;
    }
  };

  if (props.hasPartner) {
    defaultTable.splice(2, 0, partnerItem);
  }

  return defaultTable;
};
