import React from 'react';

type TImageProps = {
  alt: string;
  src: string;
  href?: string;
};

export const Image = ({ href, alt, src }: TImageProps) =>
  !!href ? (
    <a href={href} target="_blank" rel="noreferrer noopener">
      <img alt={alt} src={src} />
    </a>
  ) : (
    <img alt={alt} src={src} />
  );
