import React from 'react';
import styled from 'styled-components';
import { CreatePost } from './CreatePost';
import { Collapse, Divider, message, Select } from 'antd';
import { Post, PostInterface } from './Post';
import { orderBy } from 'lodash';
import { useFeed } from './hooks/useFeed';
import { useUpdatePost } from './hooks/useUpdatePost';

const { Panel } = Collapse;

interface FeedProps {
  eventId: string;
}

export const Feed = ({ eventId }: FeedProps) => {
  const [posts, setPosts] = React.useState<PostInterface[]>([]);
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const { postUpdate } = useUpdatePost();
  const { result, refetch }: any = useFeed(eventId);
  const [show, setShow] = React.useState<boolean>(false);

  const handleSort = (data: PostInterface[], value: 'asc' | 'desc') => {
    const list = [...orderBy([...data], 'createdAt', value)];
    setPosts([...list]);
  };

  const handlePostUpdate = async (id?: string, record: any = {}) => {
    if (!id) return;
    await postUpdate(id, record);
    await refetch();
  };

  React.useEffect(() => {
    handleSort(posts, order);
  }, [order]);

  React.useEffect(() => {
    if (result) {
      setPosts([...result]);
    }
  }, [result]);

  const handleChangeCollapse = (key: string | string[]) => {
    if (key.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  return (
    <Collapse onChange={handleChangeCollapse}>
      <Panel header={!show ? 'Exibir Feed' : 'Esconder Feed'} key="1">
        {show && (
          <FeedContainer>
            <CreatePost eventId={eventId} />
            <PostList>
              {posts.length > 0 ? (
                <>
                  <OrderContainer>
                    <Select
                      style={{ width: '135px' }}
                      defaultValue={order}
                      value={order}
                      onChange={(value: any) => setOrder(value)}
                    >
                      <Select.Option key="order-asc" value="asc">
                        Mais antigo
                      </Select.Option>
                      <Select.Option key="order-desc" value="desc">
                        Mais recente
                      </Select.Option>
                    </Select>
                  </OrderContainer>
                  {posts.map((post: PostInterface, index: number) => {
                    return (
                      <>
                        <Post
                          key={`feed-post-${index}`}
                          data={{ ...post }}
                          onDelete={async (id?: string) => {
                            await handlePostUpdate(id, { isDeleted: true });
                            message.success('Publicação removida com sucesso');
                          }}
                        />
                        <CustomDivider />
                      </>
                    );
                  })}
                </>
              ) : (
                <NotFoundComments>Não há postagens no momento</NotFoundComments>
              )}
            </PostList>
          </FeedContainer>
        )}
      </Panel>
    </Collapse>
  );
};

const FeedContainer = styled.div`
  width: 100%;
  max-width: 760px;
  margin: 0px auto;
  background-color: ${(props) => props.theme.grayLight2};
  padding: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
`;

const OrderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`;

const PostList = styled.div`
  width: 100%;
  height: auto;
  max-height: 700px;
  padding: 5px 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c7c7c7;
  }
`;

const CustomDivider = styled(Divider)`
  margin: 0px;
`;

const NotFoundComments = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  color: ${(props) => props.theme.gray};
  font-size: 11pt;
`;
