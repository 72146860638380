import { useCurrentUser } from './useCurrentUser';

export const usePlatformName = () => {
  const me = useCurrentUser().result;
  const getChannel = me && me.channels?.find((e) => e);
  const channel = getChannel?.match(/(corporate|residencial)/g)?.[0];

  const platformName = (() => {
    switch (channel) {
      case 'corporate':
        return 'corporate';
      case 'residencial':
        return 'residencial';
      default:
        return undefined;
    }
  })();

  return {
    platformName: platformName,
    isCorporate: platformName === 'corporate',
    isResidencial: platformName === 'residencial'
  };
};
