export function isObjectId(value: any) {
  const regex = new RegExp('^[0-9a-fA-F]{24}$');
  return regex.test(value);
}

export function userIsResidential(user: any) {
  return Boolean(
    user?.roleGroup?._id?.includes('residencial') ||
      user?.channels?.includes('residencial') ||
      (user?.channels?.length && user?.channels[0]?.includes('residencial'))
  );
}

export function userIsTrade(user: any) {
  const adms = [
    'residencial:adm_trade_residencial',
    'corporate:adm_trade_corporate'
  ];
  return Boolean(user?.roleGroup?._id && adms.includes(user?.roleGroup._id));
}
