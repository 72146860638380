import React from 'react';
import { FieldInterface } from '.';
import useDebounce from '../../hooks/useDebounce';
import { Input } from 'antd';
import {
  handleCNPJInput,
  handleCPFInput
} from '../../vanilla-helpers/mask-validator';

const { Search } = Input;

export const TextField = ({
  dataKey,
  placeholder,
  value,
  defaultValue,
  debounce,
  regexSearch = false,
  config,
  onChangeSearch,
  onChangeInput
}: FieldInterface): React.ReactElement => {
  const [fieldValue, setFieldValue] = React.useState<string>();
  const [fieldValueDebounce, setFieldValueDebounce] = React.useState<string>();
  const debounceValue = useDebounce(fieldValueDebounce, 500);

  const handleFieldValue = (value: any) => {
    setFieldValue(value);
  };

  React.useEffect(() => {
    handleFieldValue(value);
  }, [value]);

  React.useEffect(() => {
    if (onChangeSearch) {
      onChangeSearch(dataKey, debounceValue, regexSearch);
    }
  }, [debounceValue]);

  if (config?.mask && config?.mask === 'cnpj') {
    return (
      <Input
        id="cnpjInput"
        placeholder={placeholder}
        value={fieldValue}
        onInput={({ currentTarget: { value } }: any) => {
          handleCNPJInput(value);
        }}
        onChange={({ target: { value } }: any) => {
          const parsedValue = value.replace(/[^0-9]+/g, '');

          if (debounce) {
            setFieldValueDebounce(parsedValue);
          } else {
            if (onChangeSearch) {
              onChangeSearch(dataKey, parsedValue, regexSearch);
            }
          }
          if (onChangeInput) {
            onChangeInput(dataKey, value);
          }
        }}
      />
    );
  }

  if (config?.mask && config?.mask === 'cpf') {
    return (
      <Input
        id="cpfInput"
        placeholder={placeholder}
        value={fieldValue}
        onInput={({ currentTarget: { value } }: any) => {
          handleCPFInput(value);
        }}
        onChange={({ target: { value } }: any) => {
          const parsedValue = value.replace(/[^0-9]+/g, '');

          if (debounce) {
            setFieldValueDebounce(parsedValue);
          } else {
            if (onChangeSearch) {
              onChangeSearch(dataKey, parsedValue, regexSearch);
            }
          }
          if (onChangeInput) {
            onChangeInput(dataKey, value);
          }
        }}
      />
    );
  }

  return (
    <Search
      defaultValue={defaultValue}
      placeholder={placeholder}
      value={fieldValue}
      onChange={(e: any) => {
        if (debounce) {
          setFieldValueDebounce(e.target.value);
        } else {
          if (onChangeSearch) {
            onChangeSearch(dataKey, value, regexSearch);
          }
        }
        if (onChangeInput) {
          onChangeInput(dataKey, e.target.value);
        }
      }}
    />
  );
};
