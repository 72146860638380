import {
  parseFragmentConfig,
  OpKind,
  useClientFactory
} from 'graphql-clientgen';
import { Methods } from './types';

export const query = {
  MenuResolvers: (config?: any) => `
        query MenuResolvers{
                ${parseFragmentConfig(`MenuResolvers`, config)}
        }`,

  EventFindById: (config?: any) => `
        query EventFindById($_id: MongoID!, $filter: filter, $sort: sort){
            EventFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        eventId
                        description
                        likes
                        publishDate
                        thumbnail
                        banner
                        isPublished
                        prePublishMail
                        onPublishMail
                        onUpdateMail
                        sentEmails
                        schedulles
                        gallery
                        guides
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        _id
                        updatedAt
                        createdAt
                        pinned`,
                  config
                )}
            }
        }`,

  EventFindMany: (config?: any) => `
        query EventFindMany($filter: FilterFindManyEventInput, $skip: Int, $limit: Int, $sort: SortFindManyEventInput){
            EventFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        eventId
                        description
                        likes
                        publishDate
                        thumbnail
                        banner
                        isPublished
                        prePublishMail
                        onPublishMail
                        onUpdateMail
                        sentEmails
                        schedulles
                        gallery
                        guides
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        _id
                        updatedAt
                        createdAt
                        pinned`,
                  config
                )}
            }
        }`,

  EventPagination: (config?: any) => `
        query EventPagination($page: Int, $perPage: Int, $filter: FilterFindManyEventInput, $sort: SortFindManyEventInput){
            EventPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  FeedFindById: (config?: any) => `
        query FeedFindById($_id: MongoID!, $filter: filter, $sort: sort){
            FeedFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `eventId
                        text
                        justification
                        status
                        approved
                        likes
                        isDeleted
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  FeedFindMany: (config?: any) => `
        query FeedFindMany($filter: FilterFindManyFeedInput, $skip: Int, $limit: Int, $sort: SortFindManyFeedInput){
            FeedFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `eventId
                        text
                        justification
                        status
                        approved
                        likes
                        isDeleted
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  FeedPagination: (config?: any) => `
        query FeedPagination($page: Int, $perPage: Int, $filter: FilterFindManyFeedInput, $sort: SortFindManyFeedInput){
            FeedPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  FeedUpdateLike: (config?: any) => `
        query FeedUpdateLike($_id: String!){
            FeedUpdateLike(_id: $_id){
                ${parseFragmentConfig(`likes`, config)}
            }
        }`,

  MeuDiaPdvTableFindOne: (config?: any) => `
        query MeuDiaPdvTableFindOne($filter: FilterFindOneMeuDiaPdvTableInput, $skip: Int, $sort: SortFindOneMeuDiaPdvTableInput){
            MeuDiaPdvTableFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `regional
                        uf
                        municipio
                        canal
                        bairro
                        loja
                        endereco
                        complemento
                        cep
                        tipo
                        manha1011
                        manha1112
                        tarde1415
                        tarde1516
                        tarde1617
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  MeuDiaPdvTableFindById: (config?: any) => `
        query MeuDiaPdvTableFindById($_id: MongoID!){
            MeuDiaPdvTableFindById(_id: $_id){
                ${parseFragmentConfig(
                  `regional
                        uf
                        municipio
                        canal
                        bairro
                        loja
                        endereco
                        complemento
                        cep
                        tipo
                        manha1011
                        manha1112
                        tarde1415
                        tarde1516
                        tarde1617
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  MeuDiaPdvTableFindMany: (config?: any) => `
        query MeuDiaPdvTableFindMany($filter: FilterFindManyMeuDiaPdvTableInput, $skip: Int, $limit: Int, $sort: SortFindManyMeuDiaPdvTableInput){
            MeuDiaPdvTableFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `regional
                        uf
                        municipio
                        canal
                        bairro
                        loja
                        endereco
                        complemento
                        cep
                        tipo
                        manha1011
                        manha1112
                        tarde1415
                        tarde1516
                        tarde1617
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  MeuDiaPdvTableFindByIds: (config?: any) => `
        query MeuDiaPdvTableFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsMeuDiaPdvTableInput){
            MeuDiaPdvTableFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `regional
                        uf
                        municipio
                        canal
                        bairro
                        loja
                        endereco
                        complemento
                        cep
                        tipo
                        manha1011
                        manha1112
                        tarde1415
                        tarde1516
                        tarde1617
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  IncentiveCampaignPagination: (config?: any) => `
        query IncentiveCampaignPagination($page: Int, $perPage: Int, $filter: FilterFindManyIncentiveCampaignInput, $sort: SortFindManyIncentiveCampaignInput){
            IncentiveCampaignPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  IncentiveCampaignFindMany: (config?: any) => `
        query IncentiveCampaignFindMany($filter: FilterFindManyIncentiveCampaignInput, $skip: Int, $limit: Int, $sort: SortFindManyIncentiveCampaignInput){
            IncentiveCampaignFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        title
                        category
                        kind
                        videoLink
                        enableSocialInteraction
                        cardImage
                        bannerContent
                        ranking
                        results
                        regulation
                        presentation
                        invoiceOrigin
                        walletId
                        reserveId
                        faq
                        _id
                        updatedAt
                        createdAt
                        validityEnum
                        pinned`,
                  config
                )}
            }
        }`,

  IncentiveCampaignFindOne: (config?: any) => `
        query IncentiveCampaignFindOne($filter: FilterFindOneIncentiveCampaignInput, $skip: Int, $sort: SortFindOneIncentiveCampaignInput){
            IncentiveCampaignFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        title
                        category
                        kind
                        videoLink
                        enableSocialInteraction
                        cardImage
                        bannerContent
                        ranking
                        results
                        regulation
                        presentation
                        invoiceOrigin
                        walletId
                        reserveId
                        faq
                        _id
                        updatedAt
                        createdAt
                        validityEnum
                        pinned`,
                  config
                )}
            }
        }`,

  IncentiveCampaignFindById: (config?: any) => `
        query IncentiveCampaignFindById($_id: MongoID!, $filter: filter, $sort: sort){
            IncentiveCampaignFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        title
                        category
                        kind
                        videoLink
                        enableSocialInteraction
                        cardImage
                        bannerContent
                        ranking
                        results
                        regulation
                        presentation
                        invoiceOrigin
                        walletId
                        reserveId
                        faq
                        _id
                        updatedAt
                        createdAt
                        validityEnum
                        pinned`,
                  config
                )}
            }
        }`,

  CampaignContestationFindOne: (config?: any) => `
        query CampaignContestationFindOne($filter: FilterFindOneCampaignContestationInput, $skip: Int, $sort: SortFindOneCampaignContestationInput){
            CampaignContestationFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        protocol
                        indicator
                        owner
                        parentId
                        fileIds
                        isResultContest
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CampaignContestationPagination: (config?: any) => `
        query CampaignContestationPagination($page: Int, $perPage: Int, $filter: FilterFindManyCampaignContestationInput, $sort: SortFindManyCampaignContestationInput){
            CampaignContestationPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  IncentiveCampaignStatusOptions: (config?: any) => `
        query IncentiveCampaignStatusOptions{
            IncentiveCampaignStatusOptions{
                ${parseFragmentConfig(
                  `label
                        _id
                        value
                        description`,
                  config
                )}
            }
        }`,

  ReconheceReverseUsersParse: (config?: any) => `
        query ReconheceReverseUsersParse($paymentFileId: String!, $campaignId: String!){
            ReconheceReverseUsersParse(paymentFileId: $paymentFileId, campaignId: $campaignId){
                ${parseFragmentConfig(
                  `name
                        CPF
                        userPoints
                        userCampaignPoints
                        reversalPoints
                        value`,
                  config
                )}
            }
        }`,

  AnalyticsIncentiveCampaign: (config?: any) => `
        query AnalyticsIncentiveCampaign($page: Int, $perPage: Int){
            AnalyticsIncentiveCampaign(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        title
                        status
                        availableAtRegionalProfiles
                        campaignValidity`,
                  config
                )}
            }
        }`,

  RegulationPresentation: (config?: any) => `
        query RegulationPresentation($startDate: String, $endDate: String){
            RegulationPresentation(startDate: $startDate, endDate: $endDate){
                ${parseFragmentConfig(
                  `campaignId
                        campaignTitle
                        campaignStatus`,
                  config
                )}
            }
        }`,

  ResearchFindById: (config?: any) => `
        query ResearchFindById($_id: MongoID!){
            ResearchFindById(_id: $_id){
                ${parseFragmentConfig(
                  `title
                        ramification
                        questions
                        availableAtRegionalProfiles
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ResearchFindMany: (config?: any) => `
        query ResearchFindMany($filter: FilterFindManyResearchInput, $skip: Int, $limit: Int, $sort: SortFindManyResearchInput){
            ResearchFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        ramification
                        questions
                        availableAtRegionalProfiles
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ResearchPagination: (config?: any) => `
        query ResearchPagination($page: Int, $perPage: Int, $filter: FilterFindManyResearchInput, $sort: SortFindManyResearchInput){
            ResearchPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  AnswerResearchFindById: (config?: any) => `
        query AnswerResearchFindById($_id: MongoID!){
            AnswerResearchFindById(_id: $_id){
                ${parseFragmentConfig(
                  `userId
                        researchId
                        answers
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  AnswerResearchFindMany: (config?: any) => `
        query AnswerResearchFindMany($filter: FilterFindManyAnswerResearchInput, $skip: Int, $limit: Int, $sort: SortFindManyAnswerResearchInput){
            AnswerResearchFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        researchId
                        answers
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  AnswerResearchPagination: (config?: any) => `
        query AnswerResearchPagination($page: Int, $perPage: Int, $filter: FilterFindManyAnswerResearchInput, $sort: SortFindManyAnswerResearchInput){
            AnswerResearchPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ResearchAnswersDownload: (config?: any) => `
        query ResearchAnswersDownload($researchId: String!){
            ResearchAnswersDownload(researchId: $researchId){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config
                )}
            }
        }`,

  EmailTemplateFindById: (config?: any) => `
        query EmailTemplateFindById($_id: MongoID!, $sort: sort){
            EmailTemplateFindById(_id: $_id, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        headerFileId
                        footerFileId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailTemplateFindMany: (config?: any) => `
        query EmailTemplateFindMany($filter: FilterFindManyEmailTemplateInput, $skip: Int, $limit: Int, $sort: SortFindManyEmailTemplateInput){
            EmailTemplateFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        headerFileId
                        footerFileId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailTemplatePagination: (config?: any) => `
        query EmailTemplatePagination($page: Int, $perPage: Int, $filter: FilterFindManyEmailTemplateInput, $sort: SortFindManyEmailTemplateInput){
            EmailTemplatePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RegionFindMany: (config?: any) => `
        query RegionFindMany($filter: FilterFindManyRegionInput, $skip: Int, $limit: Int, $sort: SortFindManyRegionInput){
            RegionFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        itemIds
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RegionFindOne: (config?: any) => `
        query RegionFindOne($filter: FilterFindOneRegionInput, $skip: Int, $sort: SortFindOneRegionInput){
            RegionFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        itemIds
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RegionPagination: (config?: any) => `
        query RegionPagination($page: Int, $perPage: Int, $filter: FilterFindManyRegionInput, $sort: SortFindManyRegionInput){
            RegionPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RegionItemFindMany: (config?: any) => `
        query RegionItemFindMany($filter: FilterFindManyRegionItemInput, $skip: Int, $limit: Int, $sort: SortFindManyRegionItemInput){
            RegionItemFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        abbreviation
                        level
                        UF
                        DDD
                        parentId
                        IBGE
                        region
                        capital
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RegionItemFindOne: (config?: any) => `
        query RegionItemFindOne($filter: FilterFindOneRegionItemInput, $skip: Int, $sort: SortFindOneRegionItemInput){
            RegionItemFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        abbreviation
                        level
                        UF
                        DDD
                        parentId
                        IBGE
                        region
                        capital
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RegionItemPagination: (config?: any) => `
        query RegionItemPagination($page: Int, $perPage: Int, $filter: FilterFindManyRegionItemInput, $sort: SortFindManyRegionItemInput){
            RegionItemPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RegionItemDistinctDDD: (config?: any) => `
        query RegionItemDistinctDDD{
            RegionItemDistinctDDD{
                ${parseFragmentConfig(`items`, config)}
            }
        }`,

  RegionItemDistinctRegionAndUF: (config?: any) => `
        query RegionItemDistinctRegionAndUF{
                ${parseFragmentConfig(``, config)}
        }`,

  JobTitleFindOne: (config?: any) => `
        query JobTitleFindOne($filter: FilterFindOneJobTitleInput, $skip: Int, $sort: SortFindOneJobTitleInput){
            JobTitleFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  JobTitleFindMany: (config?: any) => `
        query JobTitleFindMany($filter: FilterFindManyJobTitleInput, $skip: Int, $limit: Int, $sort: SortFindManyJobTitleInput){
            JobTitleFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RoleGroupFindOne: (config?: any) => `
        query RoleGroupFindOne($filter: FilterFindOneRoleGroupInput, $skip: Int, $sort: SortFindOneRoleGroupInput){
            RoleGroupFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        channelName
                        roles
                        sort
                        accessLevel
                        parentId
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RoleGroupFindMany: (config?: any) => `
        query RoleGroupFindMany($filter: FilterFindManyRoleGroupInput, $skip: Int, $limit: Int, $sort: SortFindManyRoleGroupInput){
            RoleGroupFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        channelName
                        roles
                        sort
                        accessLevel
                        parentId
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RoleGroupPagination: (config?: any) => `
        query RoleGroupPagination($page: Int, $perPage: Int, $filter: FilterFindManyRoleGroupInput, $sort: SortFindManyRoleGroupInput){
            RoleGroupPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ChannelFindMany: (config?: any) => `
        query ChannelFindMany($filter: FilterFindManyChannelInput, $skip: Int, $limit: Int, $sort: SortFindManyChannelInput){
            ChannelFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        parentId
                        parentName
                        name
                        availableAtChannels
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ChannelFindOne: (config?: any) => `
        query ChannelFindOne($filter: FilterFindOneChannelInput, $skip: Int, $sort: SortFindOneChannelInput){
            ChannelFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        parentId
                        parentName
                        name
                        availableAtChannels
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ChannelPagination: (config?: any) => `
        query ChannelPagination($page: Int, $perPage: Int, $filter: FilterFindManyChannelInput, $sort: SortFindManyChannelInput){
            ChannelPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserContentPermissionFindOne: (config?: any) => `
        query UserContentPermissionFindOne($filter: FilterFindOneUserContentPermissionInput, $skip: Int, $sort: SortFindOneUserContentPermissionInput){
            UserContentPermissionFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        roleGroups
                        regions
                        siteContentTypes
                        _id
                        updatedAt
                        createdAt
                        DDDs`,
                  config
                )}
            }
        }`,

  ContentPermissionsHelper: (config?: any) => `
        query ContentPermissionsHelper{
            ContentPermissionsHelper{
                ${parseFragmentConfig(
                  `id
                        label
                        value
                        pId
                        parentName
                        kind`,
                  config
                )}
            }
        }`,

  UserPagination: (config?: any) => `
        query UserPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserInput, $sort: SortFindManyUserInput){
            UserPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserFindByIds: (config?: any) => `
        query UserFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsUserInput){
            UserFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        tour
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserFindById: (config?: any) => `
        query UserFindById($_id: MongoID!){
            UserFindById(_id: $_id){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        tour
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserFindOne: (config?: any) => `
        query UserFindOne($filter: FilterFindOneUserInput, $skip: Int, $sort: SortFindOneUserInput){
            UserFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        tour
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserFindMany: (config?: any) => `
        query UserFindMany($filter: FilterFindManyUserInput, $skip: Int, $limit: Int, $sort: SortFindManyUserInput){
            UserFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        tour
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  User: (config?: any) => `
        query User{
            User{
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        tour
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserSearch: (config?: any) => `
        query UserSearch($search: String!, $roleGroup: [String]){
            UserSearch(search: $search, roleGroup: $roleGroup){
                ${parseFragmentConfig(
                  `_id
                        name
                        email`,
                  config
                )}
            }
        }`,

  UserSearchPagination: (config?: any) => `
        query UserSearchPagination($page: Int, $perPage: Int, $filter: UserSearchPaginationFilterInput, $sort: SortFindManyUserInput){
            UserSearchPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  TextFindBlackList: (config?: any) => `
        query TextFindBlackList($text: String!){
            TextFindBlackList(text: $text){
                ${parseFragmentConfig(`isOk`, config)}
            }
        }`,

  UserFindByEmails: (config?: any) => `
        query UserFindByEmails($emails: [String]!){
            UserFindByEmails(emails: $emails){
                ${parseFragmentConfig(
                  `_id
                        name
                        email`,
                  config
                )}
            }
        }`,

  UserFindByMatricula: (config?: any) => `
        query UserFindByMatricula($isMatriculaNull: Boolean, $email: String, $name: String, $page: Int, $perPage: Int){
            UserFindByMatricula(isMatriculaNull: $isMatriculaNull, email: $email, name: $name, page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        tour
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  FileFindByIds: (config?: any) => `
        query FileFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsFileInput){
            FileFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `origin
                        parentIds
                        kind
                        title
                        isPublic
                        uploaded
                        url
                        destinationKey
                        extension
                        size
                        sizeDescription
                        description
                        tags
                        owner
                        _id
                        updatedAt
                        createdAt
                        signedUrl
                        downloadUrl`,
                  config
                )}
            }
        }`,

  FileFindById: (config?: any) => `
        query FileFindById($_id: MongoID!){
            FileFindById(_id: $_id){
                ${parseFragmentConfig(
                  `origin
                        parentIds
                        kind
                        title
                        isPublic
                        uploaded
                        url
                        destinationKey
                        extension
                        size
                        sizeDescription
                        description
                        tags
                        owner
                        _id
                        updatedAt
                        createdAt
                        signedUrl
                        downloadUrl`,
                  config
                )}
            }
        }`,

  FilePagination: (config?: any) => `
        query FilePagination($page: Int, $perPage: Int, $filter: FilterFindManyFileInput, $sort: SortFindManyFileInput){
            FilePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  MaterialFindById: (config?: any) => `
        query MaterialFindById($_id: MongoID!, $filter: filter, $sort: sort){
            MaterialFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        download
                        pinnedForAll
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        additionalCategories
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        lastSentAt
                        spotlight
                        html
                        editor
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        frequency
                        descriptionBulletin
                        bulletinMaterialsId
                        publishNow
                        termsForSearch
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config
                )}
            }
        }`,

  MaterialFindMany: (config?: any) => `
        query MaterialFindMany($filter: FilterFindManyMaterialInput, $skip: Int, $limit: Int, $sort: SortFindManyMaterialInput){
            MaterialFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        download
                        pinnedForAll
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        additionalCategories
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        lastSentAt
                        spotlight
                        html
                        editor
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        frequency
                        descriptionBulletin
                        bulletinMaterialsId
                        publishNow
                        termsForSearch
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config
                )}
            }
        }`,

  MaterialFindOne: (config?: any) => `
        query MaterialFindOne($filter: FilterFindOneMaterialInput, $skip: Int, $sort: SortFindOneMaterialInput){
            MaterialFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        download
                        pinnedForAll
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        additionalCategories
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        lastSentAt
                        spotlight
                        html
                        editor
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        frequency
                        descriptionBulletin
                        bulletinMaterialsId
                        publishNow
                        termsForSearch
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config
                )}
            }
        }`,

  MaterialPagination: (config?: any) => `
        query MaterialPagination($page: Int, $perPage: Int, $filter: FilterFindManyMaterialInput, $sort: SortFindManyMaterialInput){
            MaterialPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  MaterialCount: (config?: any) => `
        query MaterialCount($filter: FilterMaterialInput, $sort: sort){
                ${parseFragmentConfig(
                  `MaterialCount(filter: $filter, sort: $sort)`,
                  config
                )}
        }`,

  MaterialStatusOptions: (config?: any) => `
        query MaterialStatusOptions{
            MaterialStatusOptions{
                ${parseFragmentConfig(
                  `label
                        _id
                        value
                        description`,
                  config
                )}
            }
        }`,

  MaterialSearchOwners: (config?: any) => `
        query MaterialSearchOwners($kind: EnumMaterialKind){
            MaterialSearchOwners(kind: $kind){
                ${parseFragmentConfig(
                  `_id
                        name`,
                  config
                )}
            }
        }`,

  EmailFindMany: (config?: any) => `
        query EmailFindMany($filter: FilterFindManyEmailInput, $skip: Int, $limit: Int, $sort: SortFindManyEmailInput){
            EmailFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        subject
                        from
                        templateProps
                        templateName
                        status
                        sent
                        sentAt
                        sentBody
                        schedule
                        attachmentIds
                        statsId
                        plataform
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailPagination: (config?: any) => `
        query EmailPagination($page: Int, $perPage: Int, $filter: FilterFindManyEmailInput, $sort: SortFindManyEmailInput){
            EmailPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  EmailFindOne: (config?: any) => `
        query EmailFindOne($filter: FilterFindOneEmailInput, $skip: Int, $sort: SortFindOneEmailInput){
            EmailFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        subject
                        from
                        templateProps
                        templateName
                        status
                        sent
                        sentAt
                        sentBody
                        schedule
                        attachmentIds
                        statsId
                        plataform
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailCheckRead: (config?: any) => `
        query EmailCheckRead($emailId: String!, $userEmail: String!){
                ${parseFragmentConfig(
                  `EmailCheckRead(emailId: $emailId, userEmail: $userEmail)`,
                  config
                )}
        }`,

  MaterialSearch: (config?: any) => `
        query MaterialSearch($search: String!, $page: Int, $perPage: Int, $sort: SortFindManyMaterialInput, $category: String, $kind: EnumMaterialKind){
            MaterialSearch(search: $search, page: $page, perPage: $perPage, sort: $sort, category: $category, kind: $kind){
                ${parseFragmentConfig(
                  `count
                        autocomplete`,
                  config
                )}
            }
        }`,

  MaterialFindPinned: (config?: any) => `
        query MaterialFindPinned($page: Int, $perPage: Int, $filter: FilterFindManyMaterialInput, $sort: SortFindManyMaterialInput){
            MaterialFindPinned(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  NotificationFindMany: (config?: any) => `
        query NotificationFindMany($filter: FilterFindManyNotificationInput, $skip: Int, $limit: Int, $sort: SortFindManyNotificationInput){
            NotificationFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        title
                        body
                        iconUrl
                        fileIds
                        redirectionLink
                        sent
                        sentAt
                        schedule
                        clientData
                        _id
                        createdAt
                        wasRead`,
                  config
                )}
            }
        }`,

  NotificationFindOne: (config?: any) => `
        query NotificationFindOne($filter: FilterFindOneNotificationInput, $skip: Int, $sort: SortFindOneNotificationInput){
            NotificationFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        title
                        body
                        iconUrl
                        fileIds
                        redirectionLink
                        sent
                        sentAt
                        schedule
                        clientData
                        _id
                        createdAt
                        wasRead`,
                  config
                )}
            }
        }`,

  NotificationPagination: (config?: any) => `
        query NotificationPagination($page: Int, $perPage: Int, $filter: FilterFindManyNotificationInput, $sort: SortFindManyNotificationInput){
            NotificationPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  HelperAddress: (config?: any) => `
        query HelperAddress($zipCode: String!){
            HelperAddress(zipCode: $zipCode){
                ${parseFragmentConfig(
                  `zipCode
                        neighborhood
                        address
                        complement
                        DDD
                        UF
                        IBGE`,
                  config
                )}
            }
        }`,

  CategoryFindOne: (config?: any) => `
        query CategoryFindOne($filter: FilterFindOneCategoryInput, $skip: Int, $sort: SortFindOneCategoryInput){
            CategoryFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CategoryFindById: (config?: any) => `
        query CategoryFindById($_id: MongoID!, $sort: SortMenuEnum, $filter: filter){
            CategoryFindById(_id: $_id, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CategoryFindMany: (config?: any) => `
        query CategoryFindMany($filter: FilterFindManyCategoryInput, $skip: Int, $limit: Int, $sort: SortFindManyCategoryInput){
            CategoryFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CategoryFindByIds: (config?: any) => `
        query CategoryFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsCategoryInput, $filter: filter){
            CategoryFindByIds(_ids: $_ids, limit: $limit, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CategoryPagination: (config?: any) => `
        query CategoryPagination($page: Int, $perPage: Int, $filter: FilterFindManyCategoryInput, $sort: SortFindManyCategoryInput){
            CategoryPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  WebPushPublicKey: (config?: any) => `
        query WebPushPublicKey{
                ${parseFragmentConfig(`WebPushPublicKey`, config)}
        }`,

  PinFindMany: (config?: any) => `
        query PinFindMany($filter: FilterFindManyPinInput, $skip: Int, $limit: Int, $sort: SortFindManyPinInput){
            PinFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        recordId
                        kind
                        url
                        description
                        folderId
                        otherData
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  PinPagination: (config?: any) => `
        query PinPagination($page: Int, $perPage: Int, $filter: FilterFindManyPinInput, $sort: SortFindManyPinInput){
            PinPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ShareFindMany: (config?: any) => `
        query ShareFindMany($filter: FilterFindManyShareInput, $skip: Int, $limit: Int, $sort: SortFindManyShareInput){
            ShareFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        kind
                        title
                        recordId
                        userIds
                        message
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  SharePagination: (config?: any) => `
        query SharePagination($page: Int, $perPage: Int, $filter: FilterFindManyShareInput, $sort: SortFindManyShareInput){
            SharePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  CommentPagination: (config?: any) => `
        query CommentPagination($page: Int, $perPage: Int, $filter: FilterFindManyCommentInput, $sort: SortFindManyCommentInput){
            CommentPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  CommentFindOne: (config?: any) => `
        query CommentFindOne($filter: FilterFindOneCommentInput, $skip: Int, $sort: SortFindOneCommentInput){
            CommentFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `parentId
                        roomId
                        owner
                        approved
                        content
                        replyRecipientId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CommentFindById: (config?: any) => `
        query CommentFindById($_id: MongoID!){
            CommentFindById(_id: $_id){
                ${parseFragmentConfig(
                  `parentId
                        roomId
                        owner
                        approved
                        content
                        replyRecipientId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CommentFindMany: (config?: any) => `
        query CommentFindMany($filter: FilterFindManyCommentInput, $skip: Int, $limit: Int, $sort: SortFindManyCommentInput){
            CommentFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `parentId
                        roomId
                        owner
                        approved
                        content
                        replyRecipientId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CommentSortPagination: (config?: any) => `
        query CommentSortPagination($page: Int, $perPage: Int, $filter: CommentSortPaginationFilterInput, $sort: SortFindManyCommentInput){
            CommentSortPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  EventLivePagination: (config?: any) => `
        query EventLivePagination($page: Int, $perPage: Int, $filter: FilterFindManyEventLiveInput, $sort: SortFindManyEventLiveInput){
            EventLivePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  EventLiveFindOne: (config?: any) => `
        query EventLiveFindOne($filter: FilterFindOneEventLiveInput, $skip: Int, $sort: SortFindOneEventLiveInput){
            EventLiveFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        owner
                        closedBy
                        isDraft
                        videoUrl
                        eventDate
                        endTime
                        title
                        kind
                        description
                        status
                        previousStatus
                        publishedAt
                        fileIds
                        _id
                        updatedAt
                        createdAt
                        pinned`,
                  config
                )}
            }
        }`,

  EventLiveFindById: (config?: any) => `
        query EventLiveFindById($_id: MongoID!, $filter: filter){
            EventLiveFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        owner
                        closedBy
                        isDraft
                        videoUrl
                        eventDate
                        endTime
                        title
                        kind
                        description
                        status
                        previousStatus
                        publishedAt
                        fileIds
                        _id
                        updatedAt
                        createdAt
                        pinned`,
                  config
                )}
            }
        }`,

  BannerFindMany: (config?: any) => `
        query BannerFindMany($filter: FilterFindManyBannerInput, $skip: Int, $limit: Int, $sort: SortFindManyBannerInput){
            BannerFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        position
                        title
                        link
                        status
                        textButton
                        sort
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  BannerFindOne: (config?: any) => `
        query BannerFindOne($filter: FilterFindOneBannerInput, $skip: Int, $sort: SortFindOneBannerInput){
            BannerFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        position
                        title
                        link
                        status
                        textButton
                        sort
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  AnalyticsCount: (config?: any) => `
        query AnalyticsCount($filter: FilterAnalyticsInput){
                ${parseFragmentConfig(
                  `AnalyticsCount(filter: $filter)`,
                  config
                )}
        }`,

  AnalyticsPagination: (config?: any) => `
        query AnalyticsPagination($page: Int, $perPage: Int, $filter: FilterFindManyAnalyticsInput, $sort: SortFindManyAnalyticsInput){
            AnalyticsPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  AnalyticsUsersReport: (config?: any) => `
        query AnalyticsUsersReport($page: Int, $perPage: Int){
            AnalyticsUsersReport(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        nome
                        cargo
                        canal
                        regional
                        uf
                        data_adesao
                        ativo
                        matricula
                        responsavelUOPDV
                        matriculaResponsavelUOPDV
                        cpf
                        email
                        data_criacao
                        data_atualizacao
                        data_ultimo_login`,
                  config
                )}
            }
        }`,

  AnalyticsFindReport: (config?: any) => `
        query AnalyticsFindReport($filter: AnalyticsReportFilterInput, $page: Int, $perPage: Int){
            AnalyticsFindReport(filter: $filter, page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `event
                        userId
                        userName
                        userChannel
                        userRoleGroup
                        userRegion
                        userGrupoEconomicoPDV
                        userRazaoSocialPDV
                        userResponsavelUOPDV
                        userIdArea
                        title
                        categories
                        date
                        materialCreatedAt
                        materialPublishedAt
                        status
                        day
                        kind
                        device
                        deviceKind
                        referer
                        route`,
                  config
                )}
            }
        }`,

  AnalyticsUserNotification: (config?: any) => `
        query AnalyticsUserNotification($page: Int, $perPage: Int){
            AnalyticsUserNotification(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        name
                        roleGroup
                        channels`,
                  config
                )}
            }
        }`,

  AnalyticsPin: (config?: any) => `
        query AnalyticsPin($page: Int, $perPage: Int){
            AnalyticsPin(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `userId
                        title
                        categories
                        createdAt`,
                  config
                )}
            }
        }`,

  AnalyticsComment: (config?: any) => `
        query AnalyticsComment($page: Int, $perPage: Int){
            AnalyticsComment(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        userId
                        materialTitle
                        content
                        createdAt
                        likesCount
                        subCommentsCount
                        commentId`,
                  config
                )}
            }
        }`,

  AnalyticsPoints: (config?: any) => `
        query AnalyticsPoints($page: Int, $perPage: Int){
            AnalyticsPoints(page: $page, perPage: $perPage){
                ${parseFragmentConfig(`userId`, config)}
            }
        }`,

  AnalyticsDownloads: (config?: any) => `
        query AnalyticsDownloads($fileId: String){
                ${parseFragmentConfig(
                  `AnalyticsDownloads(fileId: $fileId)`,
                  config
                )}
        }`,

  SearchCountFindMany: (config?: any) => `
        query SearchCountFindMany($filter: FilterFindManySearchCountInput, $skip: Int, $limit: Int, $sort: SortFindManySearchCountInput){
            SearchCountFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        term
                        count
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  SearchCountPagination: (config?: any) => `
        query SearchCountPagination($page: Int, $perPage: Int, $filter: FilterFindManySearchCountInput, $sort: SortFindManySearchCountInput){
            SearchCountPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  FolderFindMany: (config?: any) => `
        query FolderFindMany($filter: FilterFindManyFolderInput, $skip: Int, $limit: Int, $sort: SortFindManyFolderInput){
            FolderFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        kind
                        name
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  FolderFindById: (config?: any) => `
        query FolderFindById($_id: MongoID!){
            FolderFindById(_id: $_id){
                ${parseFragmentConfig(
                  `owner
                        kind
                        name
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  FolderPagination: (config?: any) => `
        query FolderPagination($page: Int, $perPage: Int, $filter: FilterFindManyFolderInput, $sort: SortFindManyFolderInput){
            FolderPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RecentsActivitiesPagination: (config?: any) => `
        query RecentsActivitiesPagination($page: Int, $perPage: Int, $filter: FilterFindManyRecentsActivitiesInput, $sort: SortFindManyRecentsActivitiesInput){
            RecentsActivitiesPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserWordsBlackListFindMany: (config?: any) => `
        query UserWordsBlackListFindMany($filter: FilterFindManyUserWordsBlackListInput, $skip: Int, $limit: Int, $sort: SortFindManyUserWordsBlackListInput){
            UserWordsBlackListFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        word
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserWordsBlackListFindById: (config?: any) => `
        query UserWordsBlackListFindById($_id: MongoID!){
            UserWordsBlackListFindById(_id: $_id){
                ${parseFragmentConfig(
                  `owner
                        word
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DownloadSensitiveFilesPagination: (config?: any) => `
        query DownloadSensitiveFilesPagination($page: Int, $perPage: Int, $filter: FilterFindManyDownloadSensitiveFilesInput, $sort: SortFindManyDownloadSensitiveFilesInput){
            DownloadSensitiveFilesPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  DownloadUsersInfo: (config?: any) => `
        query DownloadUsersInfo{
            DownloadUsersInfo{
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadAcceptedTerms: (config?: any) => `
        query DownloadAcceptedTerms($incentiveCampaignId: String!){
            DownloadAcceptedTerms(incentiveCampaignId: $incentiveCampaignId){
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadUsersAcceptedPoints: (config?: any) => `
        query DownloadUsersAcceptedPoints($incentiveCampaignId: String!){
            DownloadUsersAcceptedPoints(incentiveCampaignId: $incentiveCampaignId){
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadUsersWithPoints: (config?: any) => `
        query DownloadUsersWithPoints{
            DownloadUsersWithPoints{
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadUsersBase: (config?: any) => `
        query DownloadUsersBase{
            DownloadUsersBase{
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadExtraCredit: (config?: any) => `
        query DownloadExtraCredit($campaignId: String!, $transactionId: String){
            DownloadExtraCredit(campaignId: $campaignId, transactionId: $transactionId){
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadReversalPoints: (config?: any) => `
        query DownloadReversalPoints($campaignId: String!, $transactionId: String){
            DownloadReversalPoints(campaignId: $campaignId, transactionId: $transactionId){
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadExpirePoints: (config?: any) => `
        query DownloadExpirePoints{
            DownloadExpirePoints{
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadDebitExpirePoints: (config?: any) => `
        query DownloadDebitExpirePoints{
            DownloadDebitExpirePoints{
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  ImportUsersPlanFindMany: (config?: any) => `
        query ImportUsersPlanFindMany($filter: FilterFindManyImportUsersPlanInput, $skip: Int, $limit: Int, $sort: SortFindManyImportUsersPlanInput){
            ImportUsersPlanFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `fileId
                        statusImportUser
                        origin
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ImportUsersPlanFindById: (config?: any) => `
        query ImportUsersPlanFindById($_id: MongoID!, $filter: filter){
            ImportUsersPlanFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `fileId
                        statusImportUser
                        origin
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ImportUsersPlanFindByIds: (config?: any) => `
        query ImportUsersPlanFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsImportUsersPlanInput, $filter: filter){
            ImportUsersPlanFindByIds(_ids: $_ids, limit: $limit, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `fileId
                        statusImportUser
                        origin
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ImportUsersPlanPagination: (config?: any) => `
        query ImportUsersPlanPagination($page: Int, $perPage: Int, $filter: FilterFindManyImportUsersPlanInput, $sort: SortFindManyImportUsersPlanInput){
            ImportUsersPlanPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserDeletedFindByIds: (config?: any) => `
        query UserDeletedFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsUserDeletedInput){
            UserDeletedFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `name
                        CPF
                        matricula
                        email
                        roleGroup
                        channels
                        CNPJ
                        UF
                        allowedDDDs
                        region
                        regionPath
                        optInDate
                        optIn
                        userRoles
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserDeletedFindOne: (config?: any) => `
        query UserDeletedFindOne($filter: FilterFindOneUserDeletedInput, $skip: Int, $sort: SortFindOneUserDeletedInput){
            UserDeletedFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `name
                        CPF
                        matricula
                        email
                        roleGroup
                        channels
                        CNPJ
                        UF
                        allowedDDDs
                        region
                        regionPath
                        optInDate
                        optIn
                        userRoles
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserDeletedFindMany: (config?: any) => `
        query UserDeletedFindMany($filter: FilterFindManyUserDeletedInput, $skip: Int, $limit: Int, $sort: SortFindManyUserDeletedInput){
            UserDeletedFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `name
                        CPF
                        matricula
                        email
                        roleGroup
                        channels
                        CNPJ
                        UF
                        allowedDDDs
                        region
                        regionPath
                        optInDate
                        optIn
                        userRoles
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserHistoryBlockFindMany: (config?: any) => `
        query UserHistoryBlockFindMany($filter: FilterFindManyUserHistoryBlockInput, $skip: Int, $limit: Int, $sort: SortFindManyUserHistoryBlockInput){
            UserHistoryBlockFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        userId
                        parentId
                        isBlocked
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserHistoryBlockPagination: (config?: any) => `
        query UserHistoryBlockPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserHistoryBlockInput, $sort: SortFindManyUserHistoryBlockInput){
            UserHistoryBlockPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  FutureOperationFindById: (config?: any) => `
        query FutureOperationFindById($_id: MongoID!){
            FutureOperationFindById(_id: $_id){
                ${parseFragmentConfig(
                  `userCreditOperationId
                        userId
                        operation
                        pointsLeft
                        expiresAt
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailStatsFindOne: (config?: any) => `
        query EmailStatsFindOne($filter: FilterFindOneEmailStatsInput, $skip: Int, $sort: SortFindOneEmailStatsInput){
            EmailStatsFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `statsId
                        userEmail
                        status
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailStatsFindMany: (config?: any) => `
        query EmailStatsFindMany($filter: FilterFindManyEmailStatsInput, $skip: Int, $limit: Int, $sort: SortFindManyEmailStatsInput){
            EmailStatsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `statsId
                        userEmail
                        status
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailUnsubscribeFindOne: (config?: any) => `
        query EmailUnsubscribeFindOne($filter: FilterFindOneEmailUnsubscribeInput, $skip: Int, $sort: SortFindOneEmailUnsubscribeInput){
            EmailUnsubscribeFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailUnsubscribeFindMany: (config?: any) => `
        query EmailUnsubscribeFindMany($filter: FilterFindManyEmailUnsubscribeInput, $skip: Int, $limit: Int, $sort: SortFindManyEmailUnsubscribeInput){
            EmailUnsubscribeFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailUnsubscribeAction: (config?: any) => `
        query EmailUnsubscribeAction($id: String, $email: String){
            EmailUnsubscribeAction(id: $id, email: $email){
                ${parseFragmentConfig(
                  `id
                        email`,
                  config
                )}
            }
        }`,

  MeuDiaPdvTableAll: (config?: any) => `
        query MeuDiaPdvTableAll{
                ${parseFragmentConfig(``, config)}
        }`,

  UpdatedUsers: (config?: any) => `
        query UpdatedUsers{
            UpdatedUsers{
                ${parseFragmentConfig(
                  `stamp
                        count`,
                  config
                )}
            }
        }`,

  SelectRegionalProfiles: (config?: any) => `
        query SelectRegionalProfiles{
            SelectRegionalProfiles{
                ${parseFragmentConfig(
                  `roleGroupName
                        roleGroupId
                        channelId
                        channelName
                        sort`,
                  config
                )}
            }
        }`,

  EmailAnalytics: (config?: any) => `
        query EmailAnalytics($page: Int, $perPage: Int){
            EmailAnalytics(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        statsId
                        title
                        kind
                        sentAt
                        menu
                        materialId`,
                  config
                )}
            }
        }`,

  EmailStatsAnalytics: (config?: any) => `
        query EmailStatsAnalytics($day: String!, $month: String!, $year: String!){
            EmailStatsAnalytics(day: $day, month: $month, year: $year){
                ${parseFragmentConfig(
                  `id
                        subject
                        classification
                        menu
                        day
                        sent
                        error
                        open
                        delivered
                        undelivered
                        spam`,
                  config
                )}
            }
        }`,

  ReportMenuView: (config?: any) => `
        query ReportMenuView($date: String!, $menu: String!, $kind: String!){
            ReportMenuView(date: $date, menu: $menu, kind: $kind){
                ${parseFragmentConfig(
                  `userId
                        materialId
                        dateView
                        dateDownload`,
                  config
                )}
            }
        }`,

  RemunerationPagination: (config?: any) => `
        query RemunerationPagination($page: Int, $perPage: Int, $filter: FilterFindManyRemunerationInput, $sort: SortFindManyRemunerationInput){
            RemunerationPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RemunerationFindMany: (config?: any) => `
        query RemunerationFindMany($filter: FilterFindManyRemunerationInput, $skip: Int, $limit: Int, $sort: SortFindManyRemunerationInput){
            RemunerationFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        owner
                        title
                        category
                        kind
                        videoLink
                        enableSocialInteraction
                        cardImage
                        file
                        bannerContent
                        regulation
                        presentation
                        faq
                        status
                        mailing
                        _id
                        updatedAt
                        createdAt
                        remunerationId`,
                  config
                )}
            }
        }`,

  RemunerationFindOne: (config?: any) => `
        query RemunerationFindOne($filter: FilterFindOneRemunerationInput, $skip: Int, $sort: SortFindOneRemunerationInput){
            RemunerationFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        owner
                        title
                        category
                        kind
                        videoLink
                        enableSocialInteraction
                        cardImage
                        file
                        bannerContent
                        regulation
                        presentation
                        faq
                        status
                        mailing
                        _id
                        updatedAt
                        createdAt
                        remunerationId`,
                  config
                )}
            }
        }`,

  RemunerationFindById: (config?: any) => `
        query RemunerationFindById($_id: MongoID!, $filter: filter, $sort: sort){
            RemunerationFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        owner
                        title
                        category
                        kind
                        videoLink
                        enableSocialInteraction
                        cardImage
                        file
                        bannerContent
                        regulation
                        presentation
                        faq
                        status
                        mailing
                        _id
                        updatedAt
                        createdAt
                        remunerationId`,
                  config
                )}
            }
        }`,

  RemunerationCount: (config?: any) => `
        query RemunerationCount($filter: FilterRemunerationInput, $sort: sort){
                ${parseFragmentConfig(
                  `RemunerationCount(filter: $filter, sort: $sort)`,
                  config
                )}
        }`,

  ReportRemuneration: (config?: any) => `
        query ReportRemuneration{
            ReportRemuneration{
                ${parseFragmentConfig(
                  `name
                        region
                        roleGroup
                        channels
                        title
                        dateAccepted`,
                  config
                )}
            }
        }`,

  AnalyticsMaterialView: (config?: any) => `
        query AnalyticsMaterialView($_id: String!){
            AnalyticsMaterialView(_id: $_id){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        download
                        pinnedForAll
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        additionalCategories
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        lastSentAt
                        spotlight
                        html
                        editor
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        frequency
                        descriptionBulletin
                        bulletinMaterialsId
                        publishNow
                        termsForSearch
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config
                )}
            }
        }`,

  ReportTourUser: (config?: any) => `
        query ReportTourUser($date: String!){
            ReportTourUser(date: $date){
                ${parseFragmentConfig(
                  `name
                        email
                        cpf
                        matricula
                        roleGroup
                        channel
                        region
                        tour
                        disabled
                        disabledWithPoints
                        disabledAt`,
                  config
                )}
            }
        }`,

  ReconheceWalletPagination: (config?: any) => `
        query ReconheceWalletPagination($page: Int, $perPage: Int, $filter: FilterFindManyReconheceWalletInput, $sort: SortFindManyReconheceWalletInput){
            ReconheceWalletPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ReconheceWalletFindMany: (config?: any) => `
        query ReconheceWalletFindMany($filter: FilterFindManyReconheceWalletInput, $skip: Int, $limit: Int, $sort: SortFindManyReconheceWalletInput){
            ReconheceWalletFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `createdBy
                        paidAt
                        initialCredit
                        usedCredit
                        availableCredit
                        status
                        POIdentifier
                        POImages
                        NFIdentifier
                        NFImages
                        invoiceOrigin
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ReconheceWalletFindById: (config?: any) => `
        query ReconheceWalletFindById($_id: MongoID!){
            ReconheceWalletFindById(_id: $_id){
                ${parseFragmentConfig(
                  `createdBy
                        paidAt
                        initialCredit
                        usedCredit
                        availableCredit
                        status
                        POIdentifier
                        POImages
                        NFIdentifier
                        NFImages
                        invoiceOrigin
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ReconheceWalletSum: (config?: any) => `
        query ReconheceWalletSum($invoiceOrigin: String, $createdAtRange: ReconheceWalletCreatedAtRange){
            ReconheceWalletSum(invoiceOrigin: $invoiceOrigin, createdAtRange: $createdAtRange){
                ${parseFragmentConfig(
                  `initialCredit
                        usedCredit
                        availableCredit`,
                  config
                )}
            }
        }`,

  UserCreditOperationFindById: (config?: any) => `
        query UserCreditOperationFindById($_id: MongoID!){
            UserCreditOperationFindById(_id: $_id){
                ${parseFragmentConfig(
                  `userId
                        op
                        value
                        walletId
                        transactionId
                        reason
                        status
                        externalExtractId
                        externalRequestNumber
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserCreditOperationPagination: (config?: any) => `
        query UserCreditOperationPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserCreditOperationInput, $sort: SortFindManyUserCreditOperationInput){
            UserCreditOperationPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserCreditOperationFindByIncentiveCampaignId: (config?: any) => `
        query UserCreditOperationFindByIncentiveCampaignId($incentiveCampaignId: String!){
            UserCreditOperationFindByIncentiveCampaignId(incentiveCampaignId: $incentiveCampaignId){
                ${parseFragmentConfig(
                  `userId
                        op
                        value
                        walletId
                        transactionId
                        reason
                        status
                        externalExtractId
                        externalRequestNumber
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ExtractUserCreditPoints: (config?: any) => `
        query ExtractUserCreditPoints($incentiveCampaignId: String, $cpf: String, $matricula: String, $userName: String){
            ExtractUserCreditPoints(incentiveCampaignId: $incentiveCampaignId, cpf: $cpf, matricula: $matricula, userName: $userName){
                ${parseFragmentConfig(
                  `userId
                        op
                        value
                        walletId
                        transactionId
                        reason
                        status
                        externalExtractId
                        externalRequestNumber
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DownloadtUserCreditPoints: (config?: any) => `
        query DownloadtUserCreditPoints($incentiveCampaignIds: [String], $cpf: String, $matricula: String, $userName: String){
            DownloadtUserCreditPoints(incentiveCampaignIds: $incentiveCampaignIds, cpf: $cpf, matricula: $matricula, userName: $userName){
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  UserCreditOperationAnalytics: (config?: any) => `
        query UserCreditOperationAnalytics($page: Int, $perPage: Int){
            UserCreditOperationAnalytics(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `userId
                        op
                        value
                        walletId
                        transactionId
                        reason
                        status
                        externalExtractId
                        externalRequestNumber
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ReconheceTransactionPagination: (config?: any) => `
        query ReconheceTransactionPagination($page: Int, $perPage: Int, $filter: FilterFindManyReconheceTransactionInput, $sort: SortFindManyReconheceTransactionInput){
            ReconheceTransactionPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ReconheceInvoiceOriginFindMany: (config?: any) => `
        query ReconheceInvoiceOriginFindMany($filter: FilterFindManyReconheceInvoiceOriginInput, $skip: Int, $limit: Int, $sort: SortFindManyReconheceInvoiceOriginInput){
            ReconheceInvoiceOriginFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        availableAtChannels
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ReconheceUserBalance: (config?: any) => `
        query ReconheceUserBalance{
            ReconheceUserBalance{
                ${parseFragmentConfig(
                  `balance
                        expiredPoints
                        reservedPoints
                        usedPoints
                        sessionToken
                        tempToken`,
                  config
                )}
            }
        }`,

  EventCreateOne: (config?: any) => `
        mutation EventCreateOne($record: CreateOneEventInput!, $sort: sort){
            EventCreateOne(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventUpdateById: (config?: any) => `
        mutation EventUpdateById($record: UpdateByIdEventInput!, $sort: sort){
            EventUpdateById(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventRemoveById: (config?: any) => `
        mutation EventRemoveById($_id: MongoID!, $sort: sort){
            EventRemoveById(_id: $_id, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FeedCreateOne: (config?: any) => `
        mutation FeedCreateOne($record: CreateOneFeedInput!, $sort: sort){
            FeedCreateOne(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FeedUpdateById: (config?: any) => `
        mutation FeedUpdateById($record: UpdateByIdFeedInput!, $sort: sort){
            FeedUpdateById(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FeedRemoveById: (config?: any) => `
        mutation FeedRemoveById($_id: MongoID!, $sort: sort){
            FeedRemoveById(_id: $_id, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MeuDiaPdvTableCreateOne: (config?: any) => `
        mutation MeuDiaPdvTableCreateOne($record: CreateOneMeuDiaPdvTableInput!){
            MeuDiaPdvTableCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MeuDiaPdvTableUpdateOne: (config?: any) => `
        mutation MeuDiaPdvTableUpdateOne($record: UpdateOneMeuDiaPdvTableInput!, $filter: FilterUpdateOneMeuDiaPdvTableInput, $sort: SortUpdateOneMeuDiaPdvTableInput, $skip: Int){
            MeuDiaPdvTableUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MeuDiaPdvTableRemoveById: (config?: any) => `
        mutation MeuDiaPdvTableRemoveById($_id: MongoID!){
            MeuDiaPdvTableRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MeuDiaPdvTableCreateMany: (config?: any) => `
        mutation MeuDiaPdvTableCreateMany($records: [CreateManyMeuDiaPdvTableInput!]!){
            MeuDiaPdvTableCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  IncentiveCampaignCreateOne: (config?: any) => `
        mutation IncentiveCampaignCreateOne($record: CreateOneIncentiveCampaignInput!, $filter: filter, $sort: sort){
            IncentiveCampaignCreateOne(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  IncentiveCampaignUpdateOne: (config?: any) => `
        mutation IncentiveCampaignUpdateOne($record: UpdateOneIncentiveCampaignInput!, $filter: FilterUpdateOneIncentiveCampaignInput, $sort: SortUpdateOneIncentiveCampaignInput, $skip: Int){
            IncentiveCampaignUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  IncentiveCampaignRemoveById: (config?: any) => `
        mutation IncentiveCampaignRemoveById($_id: MongoID!, $filter: filter, $sort: sort){
            IncentiveCampaignRemoveById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CampaignContestationCreateOne: (config?: any) => `
        mutation CampaignContestationCreateOne($record: CreateOneCampaignContestationInput!, $filter: filter){
            CampaignContestationCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CampaignContestationResolve: (config?: any) => `
        mutation CampaignContestationResolve($_id: String!, $title: String!, $status: EnumCampaignContestationResponseStatus!, $body: String!, $responseFileIds: [String]){
            CampaignContestationResolve(_id: $_id, title: $title, status: $status, body: $body, responseFileIds: $responseFileIds){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        protocol
                        indicator
                        owner
                        parentId
                        fileIds
                        isResultContest
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  IncentiveCampaignPayPoints: (config?: any) => `
        mutation IncentiveCampaignPayPoints($campaignId: String!, $paymentFileId: String!){
                ${parseFragmentConfig(
                  `IncentiveCampaignPayPoints(campaignId: $campaignId, paymentFileId: $paymentFileId)`,
                  config
                )}
        }`,

  ReconheceReversePoints: (config?: any) => `
        mutation ReconheceReversePoints($paymentFileId: String!, $campaignId: String!, $reason: String!){
                ${parseFragmentConfig(
                  `ReconheceReversePoints(paymentFileId: $paymentFileId, campaignId: $campaignId, reason: $reason)`,
                  config
                )}
        }`,

  ReconhecePayExtraPoints: (config?: any) => `
        mutation ReconhecePayExtraPoints($paymentFileId: String!, $campaignId: String!){
                ${parseFragmentConfig(
                  `ReconhecePayExtraPoints(paymentFileId: $paymentFileId, campaignId: $campaignId)`,
                  config
                )}
        }`,

  IncentiveCampaignLikeToggle: (config?: any) => `
        mutation IncentiveCampaignLikeToggle($recordId: String!){
                ${parseFragmentConfig(
                  `IncentiveCampaignLikeToggle(recordId: $recordId)`,
                  config
                )}
        }`,

  ResearchCreateOne: (config?: any) => `
        mutation ResearchCreateOne($record: CreateOneResearchInput!){
            ResearchCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ResearchUpdateOne: (config?: any) => `
        mutation ResearchUpdateOne($record: UpdateOneResearchInput!, $filter: FilterUpdateOneResearchInput, $sort: SortUpdateOneResearchInput, $skip: Int){
            ResearchUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ResearchUpdateById: (config?: any) => `
        mutation ResearchUpdateById($record: UpdateByIdResearchInput!){
            ResearchUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ResearchRemoveById: (config?: any) => `
        mutation ResearchRemoveById($_id: MongoID!){
            ResearchRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AnswerResearchCreateOne: (config?: any) => `
        mutation AnswerResearchCreateOne($record: CreateOneAnswerResearchInput!){
            AnswerResearchCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AnswerResearchUpdateOne: (config?: any) => `
        mutation AnswerResearchUpdateOne($record: UpdateOneAnswerResearchInput!, $filter: FilterUpdateOneAnswerResearchInput, $sort: SortUpdateOneAnswerResearchInput, $skip: Int){
            AnswerResearchUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AnswerResearchUpdateById: (config?: any) => `
        mutation AnswerResearchUpdateById($record: UpdateByIdAnswerResearchInput!){
            AnswerResearchUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AnswerResearchRemoveById: (config?: any) => `
        mutation AnswerResearchRemoveById($_id: MongoID!){
            AnswerResearchRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EmailTemplateCreateOne: (config?: any) => `
        mutation EmailTemplateCreateOne($record: CreateOneEmailTemplateInput!, $sort: sort){
            EmailTemplateCreateOne(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EmailTemplateUpdateById: (config?: any) => `
        mutation EmailTemplateUpdateById($record: UpdateByIdEmailTemplateInput!, $sort: sort){
            EmailTemplateUpdateById(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EmailTemplateRemoveById: (config?: any) => `
        mutation EmailTemplateRemoveById($_id: MongoID!, $sort: sort){
            EmailTemplateRemoveById(_id: $_id, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  RegionRemoveOne: (config?: any) => `
        mutation RegionRemoveOne($filter: FilterRemoveOneRegionInput, $sort: SortRemoveOneRegionInput){
            RegionRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserContentPermissionToggle: (config?: any) => `
        mutation UserContentPermissionToggle($userId: String!, $roleGroups: [String]!, $regions: [String]!, $contentTypes: [String]!, $viewOtherUsersContent: Boolean){
                ${parseFragmentConfig(
                  `UserContentPermissionToggle(userId: $userId, roleGroups: $roleGroups, regions: $regions, contentTypes: $contentTypes, viewOtherUsersContent: $viewOtherUsersContent)`,
                  config
                )}
        }`,

  UserUpdateOne: (config?: any) => `
        mutation UserUpdateOne($record: UpdateOneUserInput!, $filter: FilterUpdateOneUserInput, $sort: SortUpdateOneUserInput, $skip: Int){
            UserUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserRemoveById: (config?: any) => `
        mutation UserRemoveById($_id: MongoID!){
            UserRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserAcceptTerm: (config?: any) => `
        mutation UserAcceptTerm($term: String!){
                ${parseFragmentConfig(`UserAcceptTerm(term: $term)`, config)}
        }`,

  UserLoginWithPassword: (config?: any) => `
        mutation UserLoginWithPassword($user: String!, $password: String!, $platformName: PlatformNamesEnum){
            UserLoginWithPassword(user: $user, password: $password, platformName: $platformName){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        tour
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserLoginWithToken: (config?: any) => `
        mutation UserLoginWithToken($token: String!, $platformName: PlatformNamesEnum){
            UserLoginWithToken(token: $token, platformName: $platformName){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        tour
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserLoginRenewToken: (config?: any) => `
        mutation UserLoginRenewToken($token: String!){
            UserLoginRenewToken(token: $token){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        tour
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserSaveLogoutAnalytics: (config?: any) => `
        mutation UserSaveLogoutAnalytics{
                ${parseFragmentConfig(`UserSaveLogoutAnalytics`, config)}
        }`,

  UserSaveLoginAnalyticsPCS: (config?: any) => `
        mutation UserSaveLoginAnalyticsPCS{
                ${parseFragmentConfig(`UserSaveLoginAnalyticsPCS`, config)}
        }`,

  UserFillEmail: (config?: any) => `
        mutation UserFillEmail($email: String!){
                ${parseFragmentConfig(`UserFillEmail(email: $email)`, config)}
        }`,

  UserFillAvatarId: (config?: any) => `
        mutation UserFillAvatarId($avatarId: String!){
                ${parseFragmentConfig(
                  `UserFillAvatarId(avatarId: $avatarId)`,
                  config
                )}
        }`,

  UserOptIn: (config?: any) => `
        mutation UserOptIn($accept: Boolean!){
                ${parseFragmentConfig(`UserOptIn(accept: $accept)`, config)}
        }`,

  UserMarkNotificationAsRead: (config?: any) => `
        mutation UserMarkNotificationAsRead($isRead: Boolean!, $notifications: [String]!){
                ${parseFragmentConfig(
                  `UserMarkNotificationAsRead(isRead: $isRead, notifications: $notifications)`,
                  config
                )}
        }`,

  UserDeleteNotification: (config?: any) => `
        mutation UserDeleteNotification($notificationId: String!){
                ${parseFragmentConfig(
                  `UserDeleteNotification(notificationId: $notificationId)`,
                  config
                )}
        }`,

  UserDeleteManyNotification: (config?: any) => `
        mutation UserDeleteManyNotification($notificationIds: [String!]){
                ${parseFragmentConfig(
                  `UserDeleteManyNotification(notificationIds: $notificationIds)`,
                  config
                )}
        }`,

  UserRegistration: (config?: any) => `
        mutation UserRegistration($record: UserRegistrationTypeInput!){
            UserRegistration(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserBlockComments: (config?: any) => `
        mutation UserBlockComments($userId: String!, $isBlocked: Boolean, $materialId: String){
                ${parseFragmentConfig(
                  `UserBlockComments(userId: $userId, isBlocked: $isBlocked, materialId: $materialId)`,
                  config
                )}
        }`,

  ChangeToUserTaNarede: (config?: any) => `
        mutation ChangeToUserTaNarede{
            ChangeToUserTaNarede{
                ${parseFragmentConfig(
                  `token
                        name`,
                  config
                )}
            }
        }`,

  UserChangePassword: (config?: any) => `
        mutation UserChangePassword($newPassword: String!){
                ${parseFragmentConfig(
                  `UserChangePassword(newPassword: $newPassword)`,
                  config
                )}
        }`,

  UserFillEmailSecondary: (config?: any) => `
        mutation UserFillEmailSecondary($emailSecondary: String!){
                ${parseFragmentConfig(
                  `UserFillEmailSecondary(emailSecondary: $emailSecondary)`,
                  config
                )}
        }`,

  UserConnectMovidesk: (config?: any) => `
        mutation UserConnectMovidesk{
                ${parseFragmentConfig(`UserConnectMovidesk`, config)}
        }`,

  UserDisable: (config?: any) => `
        mutation UserDisable($_id: String!){
                ${parseFragmentConfig(`UserDisable(_id: $_id)`, config)}
        }`,

  FileCreateOne: (config?: any) => `
        mutation FileCreateOne($record: CreateOneFileInput!){
            FileCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FileRemoveById: (config?: any) => `
        mutation FileRemoveById($_id: MongoID!){
            FileRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FileUpdateById: (config?: any) => `
        mutation FileUpdateById($record: UpdateByIdFileInput!){
            FileUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialCreateOne: (config?: any) => `
        mutation MaterialCreateOne($record: CreateOneMaterialInput!, $filter: filter, $sort: sort){
            MaterialCreateOne(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialUpdateOne: (config?: any) => `
        mutation MaterialUpdateOne($record: UpdateOneMaterialInput!, $filter: FilterUpdateOneMaterialInput, $sort: SortUpdateOneMaterialInput, $skip: Int){
            MaterialUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialUpdateById: (config?: any) => `
        mutation MaterialUpdateById($record: UpdateByIdMaterialInput!, $filter: filter, $sort: sort){
            MaterialUpdateById(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialRemoveOne: (config?: any) => `
        mutation MaterialRemoveOne($filter: FilterRemoveOneMaterialInput, $sort: SortRemoveOneMaterialInput){
            MaterialRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommunicationCreateOne: (config?: any) => `
        mutation CommunicationCreateOne($record: CreateOneMaterialInput!){
            CommunicationCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommunicationUpdateOne: (config?: any) => `
        mutation CommunicationUpdateOne($communicationId: String!, $record: UpdateOneMaterialInput){
            CommunicationUpdateOne(communicationId: $communicationId, record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommunicationApproveChannel: (config?: any) => `
        mutation CommunicationApproveChannel($recordId: String!){
                ${parseFragmentConfig(
                  `CommunicationApproveChannel(recordId: $recordId)`,
                  config
                )}
        }`,

  CommunicationDisapproveChannel: (config?: any) => `
        mutation CommunicationDisapproveChannel($recordId: String!, $message: String!){
                ${parseFragmentConfig(
                  `CommunicationDisapproveChannel(recordId: $recordId, message: $message)`,
                  config
                )}
        }`,

  CommunicationRemoveApprover: (config?: any) => `
        mutation CommunicationRemoveApprover($communicationId: String!, $userId: String!){
                ${parseFragmentConfig(
                  `CommunicationRemoveApprover(communicationId: $communicationId, userId: $userId)`,
                  config
                )}
        }`,

  EmailFillTemplate: (config?: any) => `
        mutation EmailFillTemplate($template: EmailTemplateNames, $props: MaterialCommunicationItemsConfigTemplatePropsInput){
                ${parseFragmentConfig(
                  `EmailFillTemplate(template: $template, props: $props)`,
                  config
                )}
        }`,

  EmailMarkAsRead: (config?: any) => `
        mutation EmailMarkAsRead($token: String!){
                ${parseFragmentConfig(`EmailMarkAsRead(token: $token)`, config)}
        }`,

  SendEmailFront: (config?: any) => `
        mutation SendEmailFront($subject: String, $text: String, $attach: [String], $categories: [String]){
                ${parseFragmentConfig(
                  `SendEmailFront(subject: $subject, text: $text, attach: $attach, categories: $categories)`,
                  config
                )}
        }`,

  MaterialSendTestEmail: (config?: any) => `
        mutation MaterialSendTestEmail($recordId: String!){
                ${parseFragmentConfig(
                  `MaterialSendTestEmail(recordId: $recordId)`,
                  config
                )}
        }`,

  MaterialLikeToggle: (config?: any) => `
        mutation MaterialLikeToggle($recordId: String!){
                ${parseFragmentConfig(
                  `MaterialLikeToggle(recordId: $recordId)`,
                  config
                )}
        }`,

  UpdateDeteachedAndLatestVideo: (config?: any) => `
        mutation UpdateDeteachedAndLatestVideo($idDetachedVideo: String, $idLatestVideo1: String, $idLatestVideo2: String, $idLatestVideo3: String){
                ${parseFragmentConfig(
                  `UpdateDeteachedAndLatestVideo(idDetachedVideo: $idDetachedVideo, idLatestVideo1: $idLatestVideo1, idLatestVideo2: $idLatestVideo2, idLatestVideo3: $idLatestVideo3)`,
                  config
                )}
        }`,

  NotificationCreateOne: (config?: any) => `
        mutation NotificationCreateOne($record: CreateOneNotificationInput!){
            NotificationCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  NotificationCreateMany: (config?: any) => `
        mutation NotificationCreateMany($records: [CreateManyNotificationInput!]!){
            NotificationCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  NotificationUpdateOne: (config?: any) => `
        mutation NotificationUpdateOne($record: UpdateOneNotificationInput!, $filter: FilterUpdateOneNotificationInput, $sort: SortUpdateOneNotificationInput, $skip: Int){
            NotificationUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  NotificationRemoveById: (config?: any) => `
        mutation NotificationRemoveById($_id: MongoID!){
            NotificationRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryCreateOne: (config?: any) => `
        mutation CategoryCreateOne($record: CreateOneCategoryInput!, $filter: filter){
            CategoryCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryUpdateOne: (config?: any) => `
        mutation CategoryUpdateOne($record: UpdateOneCategoryInput!, $filter: FilterUpdateOneCategoryInput, $sort: SortUpdateOneCategoryInput, $skip: Int){
            CategoryUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryRemoveById: (config?: any) => `
        mutation CategoryRemoveById($_id: MongoID!, $filter: filter){
            CategoryRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryCreateMany: (config?: any) => `
        mutation CategoryCreateMany($records: [CreateManyCategoryInput!]!, $filter: filter){
            CategoryCreateMany(records: $records, filter: $filter){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  WebPushSubscribe: (config?: any) => `
        mutation WebPushSubscribe($subscription: JSON!){
                ${parseFragmentConfig(
                  `WebPushSubscribe(subscription: $subscription)`,
                  config
                )}
        }`,

  WebPushSendNotification: (config?: any) => `
        mutation WebPushSendNotification($userIds: [String]!, $notificationOptions: JSON!, $sync: Boolean){
                ${parseFragmentConfig(
                  `WebPushSendNotification(userIds: $userIds, notificationOptions: $notificationOptions, sync: $sync)`,
                  config
                )}
        }`,

  PinCreateOne: (config?: any) => `
        mutation PinCreateOne($record: CreateOnePinInput!){
            PinCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PinRemoveOne: (config?: any) => `
        mutation PinRemoveOne($filter: FilterRemoveOnePinInput, $sort: SortRemoveOnePinInput){
            PinRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PinRemoveMany: (config?: any) => `
        mutation PinRemoveMany($filter: FilterRemoveManyPinInput!){
            PinRemoveMany(filter: $filter){
                ${parseFragmentConfig(`numAffected`, config)}
            }
        }`,

  ShareCreateOne: (config?: any) => `
        mutation ShareCreateOne($record: CreateOneShareInput!){
            ShareCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ShareRemoveOne: (config?: any) => `
        mutation ShareRemoveOne($filter: FilterRemoveOneShareInput, $sort: SortRemoveOneShareInput){
            ShareRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ShareRemoveMe: (config?: any) => `
        mutation ShareRemoveMe($shareId: String){
                ${parseFragmentConfig(
                  `ShareRemoveMe(shareId: $shareId)`,
                  config
                )}
        }`,

  CommentCreateOne: (config?: any) => `
        mutation CommentCreateOne($record: CreateOneCommentInput!){
            CommentCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommentUpdateOne: (config?: any) => `
        mutation CommentUpdateOne($record: UpdateOneCommentInput!, $filter: FilterUpdateOneCommentInput, $sort: SortUpdateOneCommentInput, $skip: Int){
            CommentUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommentRemoveById: (config?: any) => `
        mutation CommentRemoveById($_id: MongoID!){
            CommentRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommentLikeToggle: (config?: any) => `
        mutation CommentLikeToggle($recordId: String!){
                ${parseFragmentConfig(
                  `CommentLikeToggle(recordId: $recordId)`,
                  config
                )}
        }`,

  EventLiveCreateOne: (config?: any) => `
        mutation EventLiveCreateOne($record: CreateOneEventLiveInput!, $filter: filter){
            EventLiveCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventLiveRemoveById: (config?: any) => `
        mutation EventLiveRemoveById($_id: MongoID!, $filter: filter){
            EventLiveRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventLiveUpdateOne: (config?: any) => `
        mutation EventLiveUpdateOne($record: UpdateOneEventLiveInput!, $filter: FilterUpdateOneEventLiveInput, $sort: SortUpdateOneEventLiveInput, $skip: Int){
            EventLiveUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventLiveCloseRoom: (config?: any) => `
        mutation EventLiveCloseRoom($eventId: String!){
                ${parseFragmentConfig(
                  `EventLiveCloseRoom(eventId: $eventId)`,
                  config
                )}
        }`,

  EventLiveLikeToggle: (config?: any) => `
        mutation EventLiveLikeToggle($recordId: String!){
                ${parseFragmentConfig(
                  `EventLiveLikeToggle(recordId: $recordId)`,
                  config
                )}
        }`,

  BannerRemoveById: (config?: any) => `
        mutation BannerRemoveById($_id: MongoID!, $filter: filter){
            BannerRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  BannerCreateOne: (config?: any) => `
        mutation BannerCreateOne($record: CreateOneBannerInput!, $filter: filter){
            BannerCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  BannerUpdateOne: (config?: any) => `
        mutation BannerUpdateOne($record: UpdateOneBannerInput!, $filter: FilterUpdateOneBannerInput, $sort: SortUpdateOneBannerInput, $skip: Int){
            BannerUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AnalyticsPageView: (config?: any) => `
        mutation AnalyticsPageView($title: String!, $host: String, $route: String){
                ${parseFragmentConfig(
                  `AnalyticsPageView(title: $title, host: $host, route: $route)`,
                  config
                )}
        }`,

  AnalyticsTrackEvent: (config?: any) => `
        mutation AnalyticsTrackEvent($event: EnumAnalyticsEvent!, $kind: EnumAnalyticsParentKind!, $recordId: String, $entityName: String, $label: String){
                ${parseFragmentConfig(
                  `AnalyticsTrackEvent(event: $event, kind: $kind, recordId: $recordId, entityName: $entityName, label: $label)`,
                  config
                )}
        }`,

  FolderCreateOne: (config?: any) => `
        mutation FolderCreateOne($record: CreateOneFolderInput!){
            FolderCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FolderRemoveOne: (config?: any) => `
        mutation FolderRemoveOne($filter: FilterRemoveOneFolderInput, $sort: SortRemoveOneFolderInput){
            FolderRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FolderRemoveMany: (config?: any) => `
        mutation FolderRemoveMany($filter: FilterRemoveManyFolderInput!){
            FolderRemoveMany(filter: $filter){
                ${parseFragmentConfig(`numAffected`, config)}
            }
        }`,

  FolderUpdateOne: (config?: any) => `
        mutation FolderUpdateOne($record: UpdateOneFolderInput!, $filter: FilterUpdateOneFolderInput, $sort: SortUpdateOneFolderInput, $skip: Int){
            FolderUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  RecentsActivitiesCreateOne: (config?: any) => `
        mutation RecentsActivitiesCreateOne($record: CreateOneRecentsActivitiesInput!){
            RecentsActivitiesCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserWordsBlackListCreateOne: (config?: any) => `
        mutation UserWordsBlackListCreateOne($record: CreateOneUserWordsBlackListInput!){
            UserWordsBlackListCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ImportUsersPlanCreateOne: (config?: any) => `
        mutation ImportUsersPlanCreateOne($record: CreateOneImportUsersPlanInput!, $filter: filter){
            ImportUsersPlanCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ImportUsersPlanUpdateOne: (config?: any) => `
        mutation ImportUsersPlanUpdateOne($record: UpdateOneImportUsersPlanInput!, $filter: FilterUpdateOneImportUsersPlanInput, $sort: SortUpdateOneImportUsersPlanInput, $skip: Int){
            ImportUsersPlanUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserHistoryBlockCreateOne: (config?: any) => `
        mutation UserHistoryBlockCreateOne($record: CreateOneUserHistoryBlockInput!, $sort: sort){
            UserHistoryBlockCreateOne(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EmailStatsCreateMany: (config?: any) => `
        mutation EmailStatsCreateMany($records: [CreateManyEmailStatsInput!]!){
            EmailStatsCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  EmailStatsCreateOne: (config?: any) => `
        mutation EmailStatsCreateOne($record: CreateOneEmailStatsInput!){
            EmailStatsCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EmailUnsubscribeCreateMany: (config?: any) => `
        mutation EmailUnsubscribeCreateMany($records: [CreateManyEmailUnsubscribeInput!]!){
            EmailUnsubscribeCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  EmailUnsubscribeCreateOne: (config?: any) => `
        mutation EmailUnsubscribeCreateOne($record: CreateOneEmailUnsubscribeInput!){
            EmailUnsubscribeCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserCorpCheck: (config?: any) => `
        mutation UserCorpCheck($CPF: String!){
            UserCorpCheck(CPF: $CPF){
                ${parseFragmentConfig(
                  `CPF
                        isTempPassword
                        isSendRecoveryEmail`,
                  config
                )}
            }
        }`,

  MeuPdvParamsMutation: (config?: any) => `
        mutation MeuPdvParamsMutation($nome: String, $endereco: String, $complemento: String, $bairro: String, $uf: String, $municipio: String, $regional: String, $canal: String, $tipo: String, $vaga: String, $cep: String, $loja: String, $matricula: String, $celular: String, $diretoria: String, $email: String){
            MeuPdvParamsMutation(nome: $nome, endereco: $endereco, complemento: $complemento, bairro: $bairro, uf: $uf, municipio: $municipio, regional: $regional, canal: $canal, tipo: $tipo, vaga: $vaga, cep: $cep, loja: $loja, matricula: $matricula, celular: $celular, diretoria: $diretoria, email: $email){
                ${parseFragmentConfig(
                  `nome
                        endereco
                        complemento
                        bairro
                        uf
                        municipio
                        regional
                        canal
                        tipo
                        vaga
                        cep
                        loja
                        matricula
                        celular
                        email
                        diretoria
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RemunerationCreateOne: (config?: any) => `
        mutation RemunerationCreateOne($record: CreateOneRemunerationInput!, $filter: filter, $sort: sort){
            RemunerationCreateOne(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  RemunerationUpdateOne: (config?: any) => `
        mutation RemunerationUpdateOne($record: UpdateOneRemunerationInput!, $filter: FilterUpdateOneRemunerationInput, $sort: SortUpdateOneRemunerationInput, $skip: Int){
            RemunerationUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  RemunerationRemoveById: (config?: any) => `
        mutation RemunerationRemoveById($_id: MongoID!, $filter: filter, $sort: sort){
            RemunerationRemoveById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  RemunerationLikeToggle: (config?: any) => `
        mutation RemunerationLikeToggle($recordId: String!){
                ${parseFragmentConfig(
                  `RemunerationLikeToggle(recordId: $recordId)`,
                  config
                )}
        }`,

  UserTourMutation: (config?: any) => `
        mutation UserTourMutation($tour: String){
                ${parseFragmentConfig(`UserTourMutation(tour: $tour)`, config)}
        }`,

  ReconheceWalletRemoveById: (config?: any) => `
        mutation ReconheceWalletRemoveById($_id: MongoID!){
            ReconheceWalletRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ReconheceWalletCreateOne: (config?: any) => `
        mutation ReconheceWalletCreateOne($record: CreateOneReconheceWalletInput!){
            ReconheceWalletCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ReconheceWalletUpdateById: (config?: any) => `
        mutation ReconheceWalletUpdateById($record: UpdateByIdReconheceWalletInput!){
            ReconheceWalletUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  SetWarningAt: (config?: any) => `
        mutation SetWarningAt($idUserCreditOperation: String!){
            SetWarningAt(idUserCreditOperation: $idUserCreditOperation){
                ${parseFragmentConfig(`result`, config)}
            }
        }`,

  ReconheceCreateTempToken: (config?: any) => `
        mutation ReconheceCreateTempToken{
                ${parseFragmentConfig(`ReconheceCreateTempToken`, config)}
        }`
};

export const AppMethods: Methods = {
  MenuResolvers: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'JSON',
        schemaKey: 'MenuResolvers',
        query: query.MenuResolvers(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  EventFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Event',
      schemaKey: 'EventFindById',
      query: query.EventFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  EventFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Event',
      schemaKey: 'EventFindMany',
      query: query.EventFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  EventPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EventPagination',
      schemaKey: 'EventPagination',
      query: query.EventPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  FeedFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Feed',
      schemaKey: 'FeedFindById',
      query: query.FeedFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  FeedFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Feed',
      schemaKey: 'FeedFindMany',
      query: query.FeedFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  FeedPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FeedPagination',
      schemaKey: 'FeedPagination',
      query: query.FeedPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  FeedUpdateLike: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FeedUpdateLike',
      schemaKey: 'FeedUpdateLike',
      query: query.FeedUpdateLike(config),
      kind: OpKind.query,
      ...config
    });
  },

  MeuDiaPdvTableFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MeuDiaPdvTable',
      schemaKey: 'MeuDiaPdvTableFindOne',
      query: query.MeuDiaPdvTableFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  MeuDiaPdvTableFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MeuDiaPdvTable',
      schemaKey: 'MeuDiaPdvTableFindById',
      query: query.MeuDiaPdvTableFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  MeuDiaPdvTableFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MeuDiaPdvTable',
      schemaKey: 'MeuDiaPdvTableFindMany',
      query: query.MeuDiaPdvTableFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  MeuDiaPdvTableFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MeuDiaPdvTable',
      schemaKey: 'MeuDiaPdvTableFindByIds',
      query: query.MeuDiaPdvTableFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  IncentiveCampaignPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveCampaignPagination',
      schemaKey: 'IncentiveCampaignPagination',
      query: query.IncentiveCampaignPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  IncentiveCampaignFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveCampaign',
      schemaKey: 'IncentiveCampaignFindMany',
      query: query.IncentiveCampaignFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  IncentiveCampaignFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveCampaign',
      schemaKey: 'IncentiveCampaignFindOne',
      query: query.IncentiveCampaignFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  IncentiveCampaignFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'IncentiveCampaign',
      schemaKey: 'IncentiveCampaignFindById',
      query: query.IncentiveCampaignFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  CampaignContestationFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CampaignContestation',
      schemaKey: 'CampaignContestationFindOne',
      query: query.CampaignContestationFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  CampaignContestationPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CampaignContestationPagination',
      schemaKey: 'CampaignContestationPagination',
      query: query.CampaignContestationPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  IncentiveCampaignStatusOptions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'IncentiveCampaignStatusOption',
        schemaKey: 'IncentiveCampaignStatusOptions',
        query: query.IncentiveCampaignStatusOptions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  ReconheceReverseUsersParse: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceReverseUsersParsePayload',
      schemaKey: 'ReconheceReverseUsersParse',
      query: query.ReconheceReverseUsersParse(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsIncentiveCampaign: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsIncentiveCampaign',
      schemaKey: 'AnalyticsIncentiveCampaign',
      query: query.AnalyticsIncentiveCampaign(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegulationPresentation: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegulationPresentation',
      schemaKey: 'RegulationPresentation',
      query: query.RegulationPresentation(config),
      kind: OpKind.query,
      ...config
    });
  },

  ResearchFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Research',
      schemaKey: 'ResearchFindById',
      query: query.ResearchFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  ResearchFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Research',
      schemaKey: 'ResearchFindMany',
      query: query.ResearchFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  ResearchPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ResearchPagination',
      schemaKey: 'ResearchPagination',
      query: query.ResearchPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnswerResearchFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnswerResearch',
      schemaKey: 'AnswerResearchFindById',
      query: query.AnswerResearchFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnswerResearchFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnswerResearch',
      schemaKey: 'AnswerResearchFindMany',
      query: query.AnswerResearchFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnswerResearchPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnswerResearchPagination',
      schemaKey: 'AnswerResearchPagination',
      query: query.AnswerResearchPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ResearchAnswersDownload: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'ResearchAnswersDownload',
      query: query.ResearchAnswersDownload(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailTemplateFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailTemplate',
      schemaKey: 'EmailTemplateFindById',
      query: query.EmailTemplateFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailTemplateFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailTemplate',
      schemaKey: 'EmailTemplateFindMany',
      query: query.EmailTemplateFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailTemplatePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailTemplatePagination',
      schemaKey: 'EmailTemplatePagination',
      query: query.EmailTemplatePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Region',
      schemaKey: 'RegionFindMany',
      query: query.RegionFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Region',
      schemaKey: 'RegionFindOne',
      query: query.RegionFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionPagination',
      schemaKey: 'RegionPagination',
      query: query.RegionPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionItemFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionItem',
      schemaKey: 'RegionItemFindMany',
      query: query.RegionItemFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionItemFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionItem',
      schemaKey: 'RegionItemFindOne',
      query: query.RegionItemFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionItemPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionItemPagination',
      schemaKey: 'RegionItemPagination',
      query: query.RegionItemPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionItemDistinctDDD: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'RegionItemDistinctDDD',
        schemaKey: 'RegionItemDistinctDDD',
        query: query.RegionItemDistinctDDD(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  RegionItemDistinctRegionAndUF: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'RegionItemDistinctRegionAndUF',
        schemaKey: 'RegionItemDistinctRegionAndUF',
        query: query.RegionItemDistinctRegionAndUF(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  JobTitleFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JobTitle',
      schemaKey: 'JobTitleFindOne',
      query: query.JobTitleFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  JobTitleFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JobTitle',
      schemaKey: 'JobTitleFindMany',
      query: query.JobTitleFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  RoleGroupFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RoleGroup',
      schemaKey: 'RoleGroupFindOne',
      query: query.RoleGroupFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  RoleGroupFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RoleGroup',
      schemaKey: 'RoleGroupFindMany',
      query: query.RoleGroupFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  RoleGroupPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RoleGroupPagination',
      schemaKey: 'RoleGroupPagination',
      query: query.RoleGroupPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ChannelFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Channel',
      schemaKey: 'ChannelFindMany',
      query: query.ChannelFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  ChannelFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Channel',
      schemaKey: 'ChannelFindOne',
      query: query.ChannelFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  ChannelPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ChannelPagination',
      schemaKey: 'ChannelPagination',
      query: query.ChannelPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserContentPermissionFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserContentPermission',
      schemaKey: 'UserContentPermissionFindOne',
      query: query.UserContentPermissionFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  ContentPermissionsHelper: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'ContentPermissionsHelperOption',
        schemaKey: 'ContentPermissionsHelper',
        query: query.ContentPermissionsHelper(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  UserPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserPagination',
      schemaKey: 'UserPagination',
      query: query.UserPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindByIds',
      query: query.UserFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindById',
      query: query.UserFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindOne',
      query: query.UserFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindMany',
      query: query.UserFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  User: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'User',
        schemaKey: 'User',
        query: query.User(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  UserSearch: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserSearch',
      schemaKey: 'UserSearch',
      query: query.UserSearch(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserSearchPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserPagination',
      schemaKey: 'UserSearchPagination',
      query: query.UserSearchPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  TextFindBlackList: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TextFindBlackList',
      schemaKey: 'TextFindBlackList',
      query: query.TextFindBlackList(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindByEmails: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserSimpleResult',
      schemaKey: 'UserFindByEmails',
      query: query.UserFindByEmails(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindByMatricula: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindByMatricula',
      query: query.UserFindByMatricula(config),
      kind: OpKind.query,
      ...config
    });
  },

  FileFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'File',
      schemaKey: 'FileFindByIds',
      query: query.FileFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  FileFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'File',
      schemaKey: 'FileFindById',
      query: query.FileFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  FilePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FilePagination',
      schemaKey: 'FilePagination',
      query: query.FilePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'MaterialFindById',
      query: query.MaterialFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'MaterialFindMany',
      query: query.MaterialFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'MaterialFindOne',
      query: query.MaterialFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialPagination',
      schemaKey: 'MaterialPagination',
      query: query.MaterialPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'MaterialCount',
      query: query.MaterialCount(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialStatusOptions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'MaterialStatusOption',
        schemaKey: 'MaterialStatusOptions',
        query: query.MaterialStatusOptions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  MaterialSearchOwners: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialSearchOwners',
      schemaKey: 'MaterialSearchOwners',
      query: query.MaterialSearchOwners(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Email',
      schemaKey: 'EmailFindMany',
      query: query.EmailFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailPagination',
      schemaKey: 'EmailPagination',
      query: query.EmailPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Email',
      schemaKey: 'EmailFindOne',
      query: query.EmailFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailCheckRead: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EmailCheckRead',
      query: query.EmailCheckRead(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialSearch: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialSearch',
      schemaKey: 'MaterialSearch',
      query: query.MaterialSearch(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialFindPinned: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialPagination',
      schemaKey: 'MaterialFindPinned',
      query: query.MaterialFindPinned(config),
      kind: OpKind.query,
      ...config
    });
  },

  NotificationFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Notification',
      schemaKey: 'NotificationFindMany',
      query: query.NotificationFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  NotificationFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Notification',
      schemaKey: 'NotificationFindOne',
      query: query.NotificationFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  NotificationPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'NotificationPagination',
      schemaKey: 'NotificationPagination',
      query: query.NotificationPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  HelperAddress: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AddressHelperPayload',
      schemaKey: 'HelperAddress',
      query: query.HelperAddress(config),
      kind: OpKind.query,
      ...config
    });
  },

  CategoryFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindOne',
      query: query.CategoryFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  CategoryFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindById',
      query: query.CategoryFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  CategoryFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindMany',
      query: query.CategoryFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  CategoryFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindByIds',
      query: query.CategoryFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  CategoryPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CategoryPagination',
      schemaKey: 'CategoryPagination',
      query: query.CategoryPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  WebPushPublicKey: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'String',
        schemaKey: 'WebPushPublicKey',
        query: query.WebPushPublicKey(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  PinFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Pin',
      schemaKey: 'PinFindMany',
      query: query.PinFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  PinPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PinPagination',
      schemaKey: 'PinPagination',
      query: query.PinPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ShareFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Share',
      schemaKey: 'ShareFindMany',
      query: query.ShareFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  SharePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SharePagination',
      schemaKey: 'SharePagination',
      query: query.SharePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  CommentPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CommentPagination',
      schemaKey: 'CommentPagination',
      query: query.CommentPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  CommentFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Comment',
      schemaKey: 'CommentFindOne',
      query: query.CommentFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  CommentFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Comment',
      schemaKey: 'CommentFindById',
      query: query.CommentFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  CommentFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Comment',
      schemaKey: 'CommentFindMany',
      query: query.CommentFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  CommentSortPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CommentPagination',
      schemaKey: 'CommentSortPagination',
      query: query.CommentSortPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  EventLivePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EventLivePagination',
      schemaKey: 'EventLivePagination',
      query: query.EventLivePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  EventLiveFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EventLive',
      schemaKey: 'EventLiveFindOne',
      query: query.EventLiveFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  EventLiveFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EventLive',
      schemaKey: 'EventLiveFindById',
      query: query.EventLiveFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  BannerFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Banner',
      schemaKey: 'BannerFindMany',
      query: query.BannerFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  BannerFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Banner',
      schemaKey: 'BannerFindOne',
      query: query.BannerFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'AnalyticsCount',
      query: query.AnalyticsCount(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsPagination',
      schemaKey: 'AnalyticsPagination',
      query: query.AnalyticsPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsUsersReport: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsUser',
      schemaKey: 'AnalyticsUsersReport',
      query: query.AnalyticsUsersReport(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsFindReport: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsReportItem',
      schemaKey: 'AnalyticsFindReport',
      query: query.AnalyticsFindReport(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsUserNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsUserNotification',
      schemaKey: 'AnalyticsUserNotification',
      query: query.AnalyticsUserNotification(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsPin: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsPin',
      schemaKey: 'AnalyticsPin',
      query: query.AnalyticsPin(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsComment: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsComment',
      schemaKey: 'AnalyticsComment',
      query: query.AnalyticsComment(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsPoints',
      schemaKey: 'AnalyticsPoints',
      query: query.AnalyticsPoints(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsDownloads: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'AnalyticsDownloads',
      query: query.AnalyticsDownloads(config),
      kind: OpKind.query,
      ...config
    });
  },

  SearchCountFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SearchCount',
      schemaKey: 'SearchCountFindMany',
      query: query.SearchCountFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  SearchCountPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SearchCountPagination',
      schemaKey: 'SearchCountPagination',
      query: query.SearchCountPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  FolderFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Folder',
      schemaKey: 'FolderFindMany',
      query: query.FolderFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  FolderFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Folder',
      schemaKey: 'FolderFindById',
      query: query.FolderFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  FolderPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FolderPagination',
      schemaKey: 'FolderPagination',
      query: query.FolderPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  RecentsActivitiesPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RecentsActivitiesPagination',
      schemaKey: 'RecentsActivitiesPagination',
      query: query.RecentsActivitiesPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserWordsBlackListFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserWordsBlackList',
      schemaKey: 'UserWordsBlackListFindMany',
      query: query.UserWordsBlackListFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserWordsBlackListFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserWordsBlackList',
      schemaKey: 'UserWordsBlackListFindById',
      query: query.UserWordsBlackListFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadSensitiveFilesPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadSensitiveFilesPagination',
      schemaKey: 'DownloadSensitiveFilesPagination',
      query: query.DownloadSensitiveFilesPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadUsersInfo: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DownloadUsersInfo',
        schemaKey: 'DownloadUsersInfo',
        query: query.DownloadUsersInfo(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  DownloadAcceptedTerms: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadAcceptedTerms',
      schemaKey: 'DownloadAcceptedTerms',
      query: query.DownloadAcceptedTerms(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadUsersAcceptedPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadUsersAcceptedPoints',
      schemaKey: 'DownloadUsersAcceptedPoints',
      query: query.DownloadUsersAcceptedPoints(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadUsersWithPoints: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DownloadUsersWithPoints',
        schemaKey: 'DownloadUsersWithPoints',
        query: query.DownloadUsersWithPoints(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  DownloadUsersBase: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DownloadUsersInfo',
        schemaKey: 'DownloadUsersBase',
        query: query.DownloadUsersBase(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  DownloadExtraCredit: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadExtraCreditUrl',
      schemaKey: 'DownloadExtraCredit',
      query: query.DownloadExtraCredit(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadReversalPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadReversalUrl',
      schemaKey: 'DownloadReversalPoints',
      query: query.DownloadReversalPoints(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadExpirePoints: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DownloadExpirePointsUrl',
        schemaKey: 'DownloadExpirePoints',
        query: query.DownloadExpirePoints(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  DownloadDebitExpirePoints: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DownloadDebitExpirePointsUrl',
        schemaKey: 'DownloadDebitExpirePoints',
        query: query.DownloadDebitExpirePoints(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  ImportUsersPlanFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlan',
      schemaKey: 'ImportUsersPlanFindMany',
      query: query.ImportUsersPlanFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  ImportUsersPlanFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlan',
      schemaKey: 'ImportUsersPlanFindById',
      query: query.ImportUsersPlanFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  ImportUsersPlanFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlan',
      schemaKey: 'ImportUsersPlanFindByIds',
      query: query.ImportUsersPlanFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  ImportUsersPlanPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlanPagination',
      schemaKey: 'ImportUsersPlanPagination',
      query: query.ImportUsersPlanPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserDeletedFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserDeleted',
      schemaKey: 'UserDeletedFindByIds',
      query: query.UserDeletedFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserDeletedFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserDeleted',
      schemaKey: 'UserDeletedFindOne',
      query: query.UserDeletedFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserDeletedFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserDeleted',
      schemaKey: 'UserDeletedFindMany',
      query: query.UserDeletedFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserHistoryBlockFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserHistoryBlock',
      schemaKey: 'UserHistoryBlockFindMany',
      query: query.UserHistoryBlockFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserHistoryBlockPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserHistoryBlockPagination',
      schemaKey: 'UserHistoryBlockPagination',
      query: query.UserHistoryBlockPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  FutureOperationFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FutureOperation',
      schemaKey: 'FutureOperationFindById',
      query: query.FutureOperationFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailStatsFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailStats',
      schemaKey: 'EmailStatsFindOne',
      query: query.EmailStatsFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailStatsFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailStats',
      schemaKey: 'EmailStatsFindMany',
      query: query.EmailStatsFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailUnsubscribeFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailUnsubscribe',
      schemaKey: 'EmailUnsubscribeFindOne',
      query: query.EmailUnsubscribeFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailUnsubscribeFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailUnsubscribe',
      schemaKey: 'EmailUnsubscribeFindMany',
      query: query.EmailUnsubscribeFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailUnsubscribeAction: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailUnsubscribeActionPayload',
      schemaKey: 'EmailUnsubscribeAction',
      query: query.EmailUnsubscribeAction(config),
      kind: OpKind.query,
      ...config
    });
  },

  MeuDiaPdvTableAll: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'MeuPdvReturn',
        schemaKey: 'MeuDiaPdvTableAll',
        query: query.MeuDiaPdvTableAll(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  UpdatedUsers: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'UpdatedUsersPayload',
        schemaKey: 'UpdatedUsers',
        query: query.UpdatedUsers(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  SelectRegionalProfiles: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'RegionalProfiles',
        schemaKey: 'SelectRegionalProfiles',
        query: query.SelectRegionalProfiles(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  EmailAnalytics: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ResultEmailAnalytics',
      schemaKey: 'EmailAnalytics',
      query: query.EmailAnalytics(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailStatsAnalytics: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailStatsAnalyticsPayload',
      schemaKey: 'EmailStatsAnalytics',
      query: query.EmailStatsAnalytics(config),
      kind: OpKind.query,
      ...config
    });
  },

  ReportMenuView: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReportMenuView',
      schemaKey: 'ReportMenuView',
      query: query.ReportMenuView(config),
      kind: OpKind.query,
      ...config
    });
  },

  RemunerationPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemunerationPagination',
      schemaKey: 'RemunerationPagination',
      query: query.RemunerationPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  RemunerationFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Remuneration',
      schemaKey: 'RemunerationFindMany',
      query: query.RemunerationFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  RemunerationFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Remuneration',
      schemaKey: 'RemunerationFindOne',
      query: query.RemunerationFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  RemunerationFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Remuneration',
      schemaKey: 'RemunerationFindById',
      query: query.RemunerationFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  RemunerationCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'RemunerationCount',
      query: query.RemunerationCount(config),
      kind: OpKind.query,
      ...config
    });
  },

  ReportRemuneration: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'ReportRemuneration',
        schemaKey: 'ReportRemuneration',
        query: query.ReportRemuneration(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  AnalyticsMaterialView: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'AnalyticsMaterialView',
      query: query.AnalyticsMaterialView(config),
      kind: OpKind.query,
      ...config
    });
  },

  ReportTourUser: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReportTourUser',
      schemaKey: 'ReportTourUser',
      query: query.ReportTourUser(config),
      kind: OpKind.query,
      ...config
    });
  },

  ReconheceWalletPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceWalletPagination',
      schemaKey: 'ReconheceWalletPagination',
      query: query.ReconheceWalletPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ReconheceWalletFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceWallet',
      schemaKey: 'ReconheceWalletFindMany',
      query: query.ReconheceWalletFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  ReconheceWalletFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceWallet',
      schemaKey: 'ReconheceWalletFindById',
      query: query.ReconheceWalletFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  ReconheceWalletSum: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceWalletSum',
      schemaKey: 'ReconheceWalletSum',
      query: query.ReconheceWalletSum(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserCreditOperationFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCreditOperation',
      schemaKey: 'UserCreditOperationFindById',
      query: query.UserCreditOperationFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserCreditOperationPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCreditOperationPagination',
      schemaKey: 'UserCreditOperationPagination',
      query: query.UserCreditOperationPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserCreditOperationFindByIncentiveCampaignId: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCreditOperation',
      schemaKey: 'UserCreditOperationFindByIncentiveCampaignId',
      query: query.UserCreditOperationFindByIncentiveCampaignId(config),
      kind: OpKind.query,
      ...config
    });
  },

  ExtractUserCreditPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCreditOperation',
      schemaKey: 'ExtractUserCreditPoints',
      query: query.ExtractUserCreditPoints(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadtUserCreditPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadtUserCreditPoints',
      schemaKey: 'DownloadtUserCreditPoints',
      query: query.DownloadtUserCreditPoints(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserCreditOperationAnalytics: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCreditOperation',
      schemaKey: 'UserCreditOperationAnalytics',
      query: query.UserCreditOperationAnalytics(config),
      kind: OpKind.query,
      ...config
    });
  },

  ReconheceTransactionPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceTransactionPagination',
      schemaKey: 'ReconheceTransactionPagination',
      query: query.ReconheceTransactionPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ReconheceInvoiceOriginFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ReconheceInvoiceOrigin',
      schemaKey: 'ReconheceInvoiceOriginFindMany',
      query: query.ReconheceInvoiceOriginFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  ReconheceUserBalance: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'ReconheceUserBalancePayload',
        schemaKey: 'ReconheceUserBalance',
        query: query.ReconheceUserBalance(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  EventCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEventPayload',
      schemaKey: 'EventCreateOne',
      query: query.EventCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdEventPayload',
      schemaKey: 'EventUpdateById',
      query: query.EventUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdEventPayload',
      schemaKey: 'EventRemoveById',
      query: query.EventRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FeedCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneFeedPayload',
      schemaKey: 'FeedCreateOne',
      query: query.FeedCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FeedUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdFeedPayload',
      schemaKey: 'FeedUpdateById',
      query: query.FeedUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FeedRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdFeedPayload',
      schemaKey: 'FeedRemoveById',
      query: query.FeedRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MeuDiaPdvTableCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneMeuDiaPdvTablePayload',
      schemaKey: 'MeuDiaPdvTableCreateOne',
      query: query.MeuDiaPdvTableCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MeuDiaPdvTableUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneMeuDiaPdvTablePayload',
      schemaKey: 'MeuDiaPdvTableUpdateOne',
      query: query.MeuDiaPdvTableUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MeuDiaPdvTableRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdMeuDiaPdvTablePayload',
      schemaKey: 'MeuDiaPdvTableRemoveById',
      query: query.MeuDiaPdvTableRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MeuDiaPdvTableCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyMeuDiaPdvTablePayload',
      schemaKey: 'MeuDiaPdvTableCreateMany',
      query: query.MeuDiaPdvTableCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  IncentiveCampaignCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneIncentiveCampaignPayload',
      schemaKey: 'IncentiveCampaignCreateOne',
      query: query.IncentiveCampaignCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  IncentiveCampaignUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneIncentiveCampaignPayload',
      schemaKey: 'IncentiveCampaignUpdateOne',
      query: query.IncentiveCampaignUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  IncentiveCampaignRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdIncentiveCampaignPayload',
      schemaKey: 'IncentiveCampaignRemoveById',
      query: query.IncentiveCampaignRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CampaignContestationCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneCampaignContestationPayload',
      schemaKey: 'CampaignContestationCreateOne',
      query: query.CampaignContestationCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CampaignContestationResolve: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CampaignContestation',
      schemaKey: 'CampaignContestationResolve',
      query: query.CampaignContestationResolve(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  IncentiveCampaignPayPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'IncentiveCampaignPayPoints',
      query: query.IncentiveCampaignPayPoints(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ReconheceReversePoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'ReconheceReversePoints',
      query: query.ReconheceReversePoints(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ReconhecePayExtraPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'ReconhecePayExtraPoints',
      query: query.ReconhecePayExtraPoints(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  IncentiveCampaignLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'IncentiveCampaignLikeToggle',
      query: query.IncentiveCampaignLikeToggle(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ResearchCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneResearchPayload',
      schemaKey: 'ResearchCreateOne',
      query: query.ResearchCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ResearchUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneResearchPayload',
      schemaKey: 'ResearchUpdateOne',
      query: query.ResearchUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ResearchUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdResearchPayload',
      schemaKey: 'ResearchUpdateById',
      query: query.ResearchUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ResearchRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdResearchPayload',
      schemaKey: 'ResearchRemoveById',
      query: query.ResearchRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AnswerResearchCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneAnswerResearchPayload',
      schemaKey: 'AnswerResearchCreateOne',
      query: query.AnswerResearchCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AnswerResearchUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneAnswerResearchPayload',
      schemaKey: 'AnswerResearchUpdateOne',
      query: query.AnswerResearchUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AnswerResearchUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdAnswerResearchPayload',
      schemaKey: 'AnswerResearchUpdateById',
      query: query.AnswerResearchUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AnswerResearchRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdAnswerResearchPayload',
      schemaKey: 'AnswerResearchRemoveById',
      query: query.AnswerResearchRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailTemplateCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEmailTemplatePayload',
      schemaKey: 'EmailTemplateCreateOne',
      query: query.EmailTemplateCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailTemplateUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdEmailTemplatePayload',
      schemaKey: 'EmailTemplateUpdateById',
      query: query.EmailTemplateUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailTemplateRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdEmailTemplatePayload',
      schemaKey: 'EmailTemplateRemoveById',
      query: query.EmailTemplateRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RegionRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneRegionPayload',
      schemaKey: 'RegionRemoveOne',
      query: query.RegionRemoveOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserContentPermissionToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserContentPermissionToggle',
      query: query.UserContentPermissionToggle(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneUserPayload',
      schemaKey: 'UserUpdateOne',
      query: query.UserUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdUserPayload',
      schemaKey: 'UserRemoveById',
      query: query.UserRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserAcceptTerm: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserAcceptTerm',
      query: query.UserAcceptTerm(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserLoginWithPassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserLoginWithPassword',
      query: query.UserLoginWithPassword(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserLoginWithToken: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserLoginWithToken',
      query: query.UserLoginWithToken(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserLoginRenewToken: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserLoginRenewToken',
      query: query.UserLoginRenewToken(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserSaveLogoutAnalytics: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'Boolean',
        schemaKey: 'UserSaveLogoutAnalytics',
        query: query.UserSaveLogoutAnalytics(config),
        kind: OpKind.mutation,
        ...config
      }
    );
  },

  UserSaveLoginAnalyticsPCS: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'Boolean',
        schemaKey: 'UserSaveLoginAnalyticsPCS',
        query: query.UserSaveLoginAnalyticsPCS(config),
        kind: OpKind.mutation,
        ...config
      }
    );
  },

  UserFillEmail: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserFillEmail',
      query: query.UserFillEmail(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserFillAvatarId: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserFillAvatarId',
      query: query.UserFillAvatarId(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserOptIn: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserOptIn',
      query: query.UserOptIn(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserMarkNotificationAsRead: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserMarkNotificationAsRead',
      query: query.UserMarkNotificationAsRead(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserDeleteNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'UserDeleteNotification',
      query: query.UserDeleteNotification(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserDeleteManyNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'UserDeleteManyNotification',
      query: query.UserDeleteManyNotification(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserRegistration: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserRegistrationPayload',
      schemaKey: 'UserRegistration',
      query: query.UserRegistration(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserBlockComments: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserBlockComments',
      query: query.UserBlockComments(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ChangeToUserTaNarede: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'UserTaNaRede',
        schemaKey: 'ChangeToUserTaNarede',
        query: query.ChangeToUserTaNarede(config),
        kind: OpKind.mutation,
        ...config
      }
    );
  },

  UserChangePassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserChangePassword',
      query: query.UserChangePassword(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserFillEmailSecondary: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserFillEmailSecondary',
      query: query.UserFillEmailSecondary(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserConnectMovidesk: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'String',
        schemaKey: 'UserConnectMovidesk',
        query: query.UserConnectMovidesk(config),
        kind: OpKind.mutation,
        ...config
      }
    );
  },

  UserDisable: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserDisable',
      query: query.UserDisable(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FileCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneFilePayload',
      schemaKey: 'FileCreateOne',
      query: query.FileCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FileRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdFilePayload',
      schemaKey: 'FileRemoveById',
      query: query.FileRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FileUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdFilePayload',
      schemaKey: 'FileUpdateById',
      query: query.FileUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneMaterialPayload',
      schemaKey: 'MaterialCreateOne',
      query: query.MaterialCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneMaterialPayload',
      schemaKey: 'MaterialUpdateOne',
      query: query.MaterialUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdMaterialPayload',
      schemaKey: 'MaterialUpdateById',
      query: query.MaterialUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneMaterialPayload',
      schemaKey: 'MaterialRemoveOne',
      query: query.MaterialRemoveOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommunicationCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneMaterialPayload',
      schemaKey: 'CommunicationCreateOne',
      query: query.CommunicationCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommunicationUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneMaterialPayload',
      schemaKey: 'CommunicationUpdateOne',
      query: query.CommunicationUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommunicationApproveChannel: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommunicationApproveChannel',
      query: query.CommunicationApproveChannel(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommunicationDisapproveChannel: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommunicationDisapproveChannel',
      query: query.CommunicationDisapproveChannel(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommunicationRemoveApprover: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommunicationRemoveApprover',
      query: query.CommunicationRemoveApprover(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailFillTemplate: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'EmailFillTemplate',
      query: query.EmailFillTemplate(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailMarkAsRead: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EmailMarkAsRead',
      query: query.EmailMarkAsRead(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  SendEmailFront: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'SendEmailFront',
      query: query.SendEmailFront(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialSendTestEmail: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'MaterialSendTestEmail',
      query: query.MaterialSendTestEmail(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'MaterialLikeToggle',
      query: query.MaterialLikeToggle(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UpdateDeteachedAndLatestVideo: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UpdateDeteachedAndLatestVideo',
      query: query.UpdateDeteachedAndLatestVideo(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  NotificationCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneNotificationPayload',
      schemaKey: 'NotificationCreateOne',
      query: query.NotificationCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  NotificationCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyNotificationPayload',
      schemaKey: 'NotificationCreateMany',
      query: query.NotificationCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  NotificationUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneNotificationPayload',
      schemaKey: 'NotificationUpdateOne',
      query: query.NotificationUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  NotificationRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdNotificationPayload',
      schemaKey: 'NotificationRemoveById',
      query: query.NotificationRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CategoryCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneCategoryPayload',
      schemaKey: 'CategoryCreateOne',
      query: query.CategoryCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CategoryUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneCategoryPayload',
      schemaKey: 'CategoryUpdateOne',
      query: query.CategoryUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CategoryRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdCategoryPayload',
      schemaKey: 'CategoryRemoveById',
      query: query.CategoryRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CategoryCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyCategoryPayload',
      schemaKey: 'CategoryCreateMany',
      query: query.CategoryCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  WebPushSubscribe: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'WebPushSubscribe',
      query: query.WebPushSubscribe(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  WebPushSendNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'WebPushSendNotification',
      query: query.WebPushSendNotification(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PinCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOnePinPayload',
      schemaKey: 'PinCreateOne',
      query: query.PinCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PinRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOnePinPayload',
      schemaKey: 'PinRemoveOne',
      query: query.PinRemoveOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PinRemoveMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveManyPinPayload',
      schemaKey: 'PinRemoveMany',
      query: query.PinRemoveMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ShareCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneSharePayload',
      schemaKey: 'ShareCreateOne',
      query: query.ShareCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ShareRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneSharePayload',
      schemaKey: 'ShareRemoveOne',
      query: query.ShareRemoveOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ShareRemoveMe: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'ShareRemoveMe',
      query: query.ShareRemoveMe(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommentCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneCommentPayload',
      schemaKey: 'CommentCreateOne',
      query: query.CommentCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommentUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneCommentPayload',
      schemaKey: 'CommentUpdateOne',
      query: query.CommentUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommentRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdCommentPayload',
      schemaKey: 'CommentRemoveById',
      query: query.CommentRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommentLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommentLikeToggle',
      query: query.CommentLikeToggle(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventLiveCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEventLivePayload',
      schemaKey: 'EventLiveCreateOne',
      query: query.EventLiveCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventLiveRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdEventLivePayload',
      schemaKey: 'EventLiveRemoveById',
      query: query.EventLiveRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventLiveUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneEventLivePayload',
      schemaKey: 'EventLiveUpdateOne',
      query: query.EventLiveUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventLiveCloseRoom: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EventLiveCloseRoom',
      query: query.EventLiveCloseRoom(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventLiveLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EventLiveLikeToggle',
      query: query.EventLiveLikeToggle(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BannerRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdBannerPayload',
      schemaKey: 'BannerRemoveById',
      query: query.BannerRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BannerCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneBannerPayload',
      schemaKey: 'BannerCreateOne',
      query: query.BannerCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BannerUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneBannerPayload',
      schemaKey: 'BannerUpdateOne',
      query: query.BannerUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AnalyticsPageView: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'AnalyticsPageView',
      query: query.AnalyticsPageView(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AnalyticsTrackEvent: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'AnalyticsTrackEvent',
      query: query.AnalyticsTrackEvent(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FolderCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneFolderPayload',
      schemaKey: 'FolderCreateOne',
      query: query.FolderCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FolderRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneFolderPayload',
      schemaKey: 'FolderRemoveOne',
      query: query.FolderRemoveOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FolderRemoveMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveManyFolderPayload',
      schemaKey: 'FolderRemoveMany',
      query: query.FolderRemoveMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FolderUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneFolderPayload',
      schemaKey: 'FolderUpdateOne',
      query: query.FolderUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RecentsActivitiesCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneRecentsActivitiesPayload',
      schemaKey: 'RecentsActivitiesCreateOne',
      query: query.RecentsActivitiesCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserWordsBlackListCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneUserWordsBlackListPayload',
      schemaKey: 'UserWordsBlackListCreateOne',
      query: query.UserWordsBlackListCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ImportUsersPlanCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneImportUsersPlanPayload',
      schemaKey: 'ImportUsersPlanCreateOne',
      query: query.ImportUsersPlanCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ImportUsersPlanUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneImportUsersPlanPayload',
      schemaKey: 'ImportUsersPlanUpdateOne',
      query: query.ImportUsersPlanUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserHistoryBlockCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneUserHistoryBlockPayload',
      schemaKey: 'UserHistoryBlockCreateOne',
      query: query.UserHistoryBlockCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailStatsCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyEmailStatsPayload',
      schemaKey: 'EmailStatsCreateMany',
      query: query.EmailStatsCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailStatsCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEmailStatsPayload',
      schemaKey: 'EmailStatsCreateOne',
      query: query.EmailStatsCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailUnsubscribeCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyEmailUnsubscribePayload',
      schemaKey: 'EmailUnsubscribeCreateMany',
      query: query.EmailUnsubscribeCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailUnsubscribeCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEmailUnsubscribePayload',
      schemaKey: 'EmailUnsubscribeCreateOne',
      query: query.EmailUnsubscribeCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserCorpCheck: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserCorpCheckPayload',
      schemaKey: 'UserCorpCheck',
      query: query.UserCorpCheck(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MeuPdvParamsMutation: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MeuDiaPdv',
      schemaKey: 'MeuPdvParamsMutation',
      query: query.MeuPdvParamsMutation(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RemunerationCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneRemunerationPayload',
      schemaKey: 'RemunerationCreateOne',
      query: query.RemunerationCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RemunerationUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneRemunerationPayload',
      schemaKey: 'RemunerationUpdateOne',
      query: query.RemunerationUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RemunerationRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdRemunerationPayload',
      schemaKey: 'RemunerationRemoveById',
      query: query.RemunerationRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RemunerationLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'RemunerationLikeToggle',
      query: query.RemunerationLikeToggle(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserTourMutation: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserTourMutation',
      query: query.UserTourMutation(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ReconheceWalletRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdReconheceWalletPayload',
      schemaKey: 'ReconheceWalletRemoveById',
      query: query.ReconheceWalletRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ReconheceWalletCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneReconheceWalletPayload',
      schemaKey: 'ReconheceWalletCreateOne',
      query: query.ReconheceWalletCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ReconheceWalletUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdReconheceWalletPayload',
      schemaKey: 'ReconheceWalletUpdateById',
      query: query.ReconheceWalletUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  SetWarningAt: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'WarningAtResult',
      schemaKey: 'SetWarningAt',
      query: query.SetWarningAt(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ReconheceCreateTempToken: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'String',
        schemaKey: 'ReconheceCreateTempToken',
        query: query.ReconheceCreateTempToken(config),
        kind: OpKind.mutation,
        ...config
      }
    );
  }
};

export const AppMethodsInfo = {
  MenuResolvers: {
    type: 'JSON',
    schemaKey: 'MenuResolvers',
    entityName: 'JSON',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['MenuResolvers']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Essa query retorna uma lista de menus',
      deprecationReason: null,
      type: 'JSON',
      args: [],
      name: 'MenuResolvers',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EventFindById: {
    type: 'Event',
    schemaKey: 'EventFindById',
    entityName: 'Event',
    isList: false,
    argsTSName: 'QueryEventFindByIdArgs',
    returnTSName: "Query['EventFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Event',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'EventFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EventFindMany: {
    type: '[Event]',
    schemaKey: 'EventFindMany',
    entityName: 'Event',
    isList: true,
    argsTSName: 'QueryEventFindManyArgs',
    returnTSName: "Query['EventFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Event]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEventInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyEventInput' }
      ],
      name: 'EventFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EventPagination: {
    type: 'EventPagination',
    schemaKey: 'EventPagination',
    entityName: 'EventPagination',
    isList: false,
    argsTSName: 'QueryEventPaginationArgs',
    returnTSName: "Query['EventPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EventPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEventInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyEventInput' }
      ],
      name: 'EventPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FeedFindById: {
    type: 'Feed',
    schemaKey: 'FeedFindById',
    entityName: 'Feed',
    isList: false,
    argsTSName: 'QueryFeedFindByIdArgs',
    returnTSName: "Query['FeedFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Feed',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'FeedFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FeedFindMany: {
    type: '[Feed]',
    schemaKey: 'FeedFindMany',
    entityName: 'Feed',
    isList: true,
    argsTSName: 'QueryFeedFindManyArgs',
    returnTSName: "Query['FeedFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Feed]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyFeedInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyFeedInput' }
      ],
      name: 'FeedFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FeedPagination: {
    type: 'FeedPagination',
    schemaKey: 'FeedPagination',
    entityName: 'FeedPagination',
    isList: false,
    argsTSName: 'QueryFeedPaginationArgs',
    returnTSName: "Query['FeedPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FeedPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyFeedInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyFeedInput' }
      ],
      name: 'FeedPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FeedUpdateLike: {
    type: 'FeedUpdateLike',
    schemaKey: 'FeedUpdateLike',
    entityName: 'FeedUpdateLike',
    isList: false,
    argsTSName: 'QueryFeedUpdateLikeArgs',
    returnTSName: "Query['FeedUpdateLike']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'FeedUpdateLike',
      args: [{ name: '_id', description: 'Id da postagem', type: 'String!' }],
      name: 'FeedUpdateLike',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MeuDiaPdvTableFindOne: {
    type: 'MeuDiaPdvTable',
    schemaKey: 'MeuDiaPdvTableFindOne',
    entityName: 'MeuDiaPdvTable',
    isList: false,
    argsTSName: 'QueryMeuDiaPdvTableFindOneArgs',
    returnTSName: "Query['MeuDiaPdvTableFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MeuDiaPdvTable',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneMeuDiaPdvTableInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneMeuDiaPdvTableInput'
        }
      ],
      name: 'MeuDiaPdvTableFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MeuDiaPdvTableFindById: {
    type: 'MeuDiaPdvTable',
    schemaKey: 'MeuDiaPdvTableFindById',
    entityName: 'MeuDiaPdvTable',
    isList: false,
    argsTSName: 'QueryMeuDiaPdvTableFindByIdArgs',
    returnTSName: "Query['MeuDiaPdvTableFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MeuDiaPdvTable',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'MeuDiaPdvTableFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MeuDiaPdvTableFindMany: {
    type: '[MeuDiaPdvTable]',
    schemaKey: 'MeuDiaPdvTableFindMany',
    entityName: 'MeuDiaPdvTable',
    isList: true,
    argsTSName: 'QueryMeuDiaPdvTableFindManyArgs',
    returnTSName: "Query['MeuDiaPdvTableFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[MeuDiaPdvTable]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyMeuDiaPdvTableInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyMeuDiaPdvTableInput'
        }
      ],
      name: 'MeuDiaPdvTableFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MeuDiaPdvTableFindByIds: {
    type: '[MeuDiaPdvTable]',
    schemaKey: 'MeuDiaPdvTableFindByIds',
    entityName: 'MeuDiaPdvTable',
    isList: true,
    argsTSName: 'QueryMeuDiaPdvTableFindByIdsArgs',
    returnTSName: "Query['MeuDiaPdvTableFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[MeuDiaPdvTable]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindByIdsMeuDiaPdvTableInput'
        }
      ],
      name: 'MeuDiaPdvTableFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  IncentiveCampaignPagination: {
    type: 'IncentiveCampaignPagination',
    schemaKey: 'IncentiveCampaignPagination',
    entityName: 'IncentiveCampaignPagination',
    isList: false,
    argsTSName: 'QueryIncentiveCampaignPaginationArgs',
    returnTSName: "Query['IncentiveCampaignPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'IncentiveCampaignPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyIncentiveCampaignInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyIncentiveCampaignInput'
        }
      ],
      name: 'IncentiveCampaignPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  IncentiveCampaignFindMany: {
    type: '[IncentiveCampaign]',
    schemaKey: 'IncentiveCampaignFindMany',
    entityName: 'IncentiveCampaign',
    isList: true,
    argsTSName: 'QueryIncentiveCampaignFindManyArgs',
    returnTSName: "Query['IncentiveCampaignFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[IncentiveCampaign]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyIncentiveCampaignInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyIncentiveCampaignInput'
        }
      ],
      name: 'IncentiveCampaignFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  IncentiveCampaignFindOne: {
    type: 'IncentiveCampaign',
    schemaKey: 'IncentiveCampaignFindOne',
    entityName: 'IncentiveCampaign',
    isList: false,
    argsTSName: 'QueryIncentiveCampaignFindOneArgs',
    returnTSName: "Query['IncentiveCampaignFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'IncentiveCampaign',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneIncentiveCampaignInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneIncentiveCampaignInput'
        }
      ],
      name: 'IncentiveCampaignFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  IncentiveCampaignFindById: {
    type: 'IncentiveCampaign',
    schemaKey: 'IncentiveCampaignFindById',
    entityName: 'IncentiveCampaign',
    isList: false,
    argsTSName: 'QueryIncentiveCampaignFindByIdArgs',
    returnTSName: "Query['IncentiveCampaignFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'IncentiveCampaign',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'IncentiveCampaignFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CampaignContestationFindOne: {
    type: 'CampaignContestation',
    schemaKey: 'CampaignContestationFindOne',
    entityName: 'CampaignContestation',
    isList: false,
    argsTSName: 'QueryCampaignContestationFindOneArgs',
    returnTSName: "Query['CampaignContestationFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CampaignContestation',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneCampaignContestationInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneCampaignContestationInput'
        }
      ],
      name: 'CampaignContestationFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CampaignContestationPagination: {
    type: 'CampaignContestationPagination',
    schemaKey: 'CampaignContestationPagination',
    entityName: 'CampaignContestationPagination',
    isList: false,
    argsTSName: 'QueryCampaignContestationPaginationArgs',
    returnTSName: "Query['CampaignContestationPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CampaignContestationPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyCampaignContestationInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyCampaignContestationInput'
        }
      ],
      name: 'CampaignContestationPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  IncentiveCampaignStatusOptions: {
    type: '[IncentiveCampaignStatusOption]',
    schemaKey: 'IncentiveCampaignStatusOptions',
    entityName: 'IncentiveCampaignStatusOption',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['IncentiveCampaignStatusOptions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[IncentiveCampaignStatusOption]',
      args: [],
      name: 'IncentiveCampaignStatusOptions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReconheceReverseUsersParse: {
    type: '[ReconheceReverseUsersParsePayload]',
    schemaKey: 'ReconheceReverseUsersParse',
    entityName: 'ReconheceReverseUsersParsePayload',
    isList: true,
    argsTSName: 'QueryReconheceReverseUsersParseArgs',
    returnTSName: "Query['ReconheceReverseUsersParse']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ReconheceReverseUsersParsePayload]',
      args: [
        { name: 'paymentFileId', description: null, type: 'String!' },
        { name: 'campaignId', description: null, type: 'String!' }
      ],
      name: 'ReconheceReverseUsersParse',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsIncentiveCampaign: {
    type: '[AnalyticsIncentiveCampaign]',
    schemaKey: 'AnalyticsIncentiveCampaign',
    entityName: 'AnalyticsIncentiveCampaign',
    isList: true,
    argsTSName: 'QueryAnalyticsIncentiveCampaignArgs',
    returnTSName: "Query['AnalyticsIncentiveCampaign']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[AnalyticsIncentiveCampaign]',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int', defaultValue: 20 }
      ],
      name: 'AnalyticsIncentiveCampaign',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegulationPresentation: {
    type: '[RegulationPresentation]',
    schemaKey: 'RegulationPresentation',
    entityName: 'RegulationPresentation',
    isList: true,
    argsTSName: 'QueryRegulationPresentationArgs',
    returnTSName: "Query['RegulationPresentation']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[RegulationPresentation]',
      args: [
        { name: 'startDate', description: null, type: 'String' },
        { name: 'endDate', description: null, type: 'String' }
      ],
      name: 'RegulationPresentation',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ResearchFindById: {
    type: 'Research',
    schemaKey: 'ResearchFindById',
    entityName: 'Research',
    isList: false,
    argsTSName: 'QueryResearchFindByIdArgs',
    returnTSName: "Query['ResearchFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Research',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'ResearchFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ResearchFindMany: {
    type: '[Research]',
    schemaKey: 'ResearchFindMany',
    entityName: 'Research',
    isList: true,
    argsTSName: 'QueryResearchFindManyArgs',
    returnTSName: "Query['ResearchFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Research]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyResearchInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyResearchInput' }
      ],
      name: 'ResearchFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ResearchPagination: {
    type: 'ResearchPagination',
    schemaKey: 'ResearchPagination',
    entityName: 'ResearchPagination',
    isList: false,
    argsTSName: 'QueryResearchPaginationArgs',
    returnTSName: "Query['ResearchPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ResearchPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyResearchInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyResearchInput' }
      ],
      name: 'ResearchPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnswerResearchFindById: {
    type: 'AnswerResearch',
    schemaKey: 'AnswerResearchFindById',
    entityName: 'AnswerResearch',
    isList: false,
    argsTSName: 'QueryAnswerResearchFindByIdArgs',
    returnTSName: "Query['AnswerResearchFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'AnswerResearch',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'AnswerResearchFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnswerResearchFindMany: {
    type: '[AnswerResearch]',
    schemaKey: 'AnswerResearchFindMany',
    entityName: 'AnswerResearch',
    isList: true,
    argsTSName: 'QueryAnswerResearchFindManyArgs',
    returnTSName: "Query['AnswerResearchFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnswerResearch]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyAnswerResearchInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyAnswerResearchInput'
        }
      ],
      name: 'AnswerResearchFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnswerResearchPagination: {
    type: 'AnswerResearchPagination',
    schemaKey: 'AnswerResearchPagination',
    entityName: 'AnswerResearchPagination',
    isList: false,
    argsTSName: 'QueryAnswerResearchPaginationArgs',
    returnTSName: "Query['AnswerResearchPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'AnswerResearchPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyAnswerResearchInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyAnswerResearchInput'
        }
      ],
      name: 'AnswerResearchPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ResearchAnswersDownload: {
    type: 'BaseDownload',
    schemaKey: 'ResearchAnswersDownload',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryResearchAnswersDownloadArgs',
    returnTSName: "Query['ResearchAnswersDownload']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [{ name: 'researchId', description: null, type: 'String!' }],
      name: 'ResearchAnswersDownload',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailTemplateFindById: {
    type: 'EmailTemplate',
    schemaKey: 'EmailTemplateFindById',
    entityName: 'EmailTemplate',
    isList: false,
    argsTSName: 'QueryEmailTemplateFindByIdArgs',
    returnTSName: "Query['EmailTemplateFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailTemplate',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'EmailTemplateFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailTemplateFindMany: {
    type: '[EmailTemplate]',
    schemaKey: 'EmailTemplateFindMany',
    entityName: 'EmailTemplate',
    isList: true,
    argsTSName: 'QueryEmailTemplateFindManyArgs',
    returnTSName: "Query['EmailTemplateFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[EmailTemplate]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEmailTemplateInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyEmailTemplateInput'
        }
      ],
      name: 'EmailTemplateFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailTemplatePagination: {
    type: 'EmailTemplatePagination',
    schemaKey: 'EmailTemplatePagination',
    entityName: 'EmailTemplatePagination',
    isList: false,
    argsTSName: 'QueryEmailTemplatePaginationArgs',
    returnTSName: "Query['EmailTemplatePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailTemplatePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEmailTemplateInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyEmailTemplateInput'
        }
      ],
      name: 'EmailTemplatePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionFindMany: {
    type: '[Region]',
    schemaKey: 'RegionFindMany',
    entityName: 'Region',
    isList: true,
    argsTSName: 'QueryRegionFindManyArgs',
    returnTSName: "Query['RegionFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Region]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRegionInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyRegionInput' }
      ],
      name: 'RegionFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionFindOne: {
    type: 'Region',
    schemaKey: 'RegionFindOne',
    entityName: 'Region',
    isList: false,
    argsTSName: 'QueryRegionFindOneArgs',
    returnTSName: "Query['RegionFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Region',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneRegionInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneRegionInput' }
      ],
      name: 'RegionFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionPagination: {
    type: 'RegionPagination',
    schemaKey: 'RegionPagination',
    entityName: 'RegionPagination',
    isList: false,
    argsTSName: 'QueryRegionPaginationArgs',
    returnTSName: "Query['RegionPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRegionInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyRegionInput' }
      ],
      name: 'RegionPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionItemFindMany: {
    type: '[RegionItem]',
    schemaKey: 'RegionItemFindMany',
    entityName: 'RegionItem',
    isList: true,
    argsTSName: 'QueryRegionItemFindManyArgs',
    returnTSName: "Query['RegionItemFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[RegionItem]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRegionItemInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyRegionItemInput' }
      ],
      name: 'RegionItemFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionItemFindOne: {
    type: 'RegionItem',
    schemaKey: 'RegionItemFindOne',
    entityName: 'RegionItem',
    isList: false,
    argsTSName: 'QueryRegionItemFindOneArgs',
    returnTSName: "Query['RegionItemFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionItem',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneRegionItemInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneRegionItemInput' }
      ],
      name: 'RegionItemFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionItemPagination: {
    type: 'RegionItemPagination',
    schemaKey: 'RegionItemPagination',
    entityName: 'RegionItemPagination',
    isList: false,
    argsTSName: 'QueryRegionItemPaginationArgs',
    returnTSName: "Query['RegionItemPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionItemPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRegionItemInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyRegionItemInput' }
      ],
      name: 'RegionItemPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionItemDistinctDDD: {
    type: 'RegionItemDistinctDDD',
    schemaKey: 'RegionItemDistinctDDD',
    entityName: 'RegionItemDistinctDDD',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['RegionItemDistinctDDD']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca dos itens de região',
      deprecationReason: null,
      type: 'RegionItemDistinctDDD',
      args: [],
      name: 'RegionItemDistinctDDD',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionItemDistinctRegionAndUF: {
    type: 'RegionItemDistinctRegionAndUF',
    schemaKey: 'RegionItemDistinctRegionAndUF',
    entityName: 'RegionItemDistinctRegionAndUF',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['RegionItemDistinctRegionAndUF']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Executa a busca dos itens distintos por regional e UF (não inclui THQ para isso será necessário adicionar um filtro)',
      deprecationReason: null,
      type: 'RegionItemDistinctRegionAndUF',
      args: [],
      name: 'RegionItemDistinctRegionAndUF',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  JobTitleFindOne: {
    type: 'JobTitle',
    schemaKey: 'JobTitleFindOne',
    entityName: 'JobTitle',
    isList: false,
    argsTSName: 'QueryJobTitleFindOneArgs',
    returnTSName: "Query['JobTitleFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JobTitle',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneJobTitleInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneJobTitleInput' }
      ],
      name: 'JobTitleFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  JobTitleFindMany: {
    type: '[JobTitle]',
    schemaKey: 'JobTitleFindMany',
    entityName: 'JobTitle',
    isList: true,
    argsTSName: 'QueryJobTitleFindManyArgs',
    returnTSName: "Query['JobTitleFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[JobTitle]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyJobTitleInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyJobTitleInput' }
      ],
      name: 'JobTitleFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RoleGroupFindOne: {
    type: 'RoleGroup',
    schemaKey: 'RoleGroupFindOne',
    entityName: 'RoleGroup',
    isList: false,
    argsTSName: 'QueryRoleGroupFindOneArgs',
    returnTSName: "Query['RoleGroupFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RoleGroup',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneRoleGroupInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneRoleGroupInput' }
      ],
      name: 'RoleGroupFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RoleGroupFindMany: {
    type: '[RoleGroup]',
    schemaKey: 'RoleGroupFindMany',
    entityName: 'RoleGroup',
    isList: true,
    argsTSName: 'QueryRoleGroupFindManyArgs',
    returnTSName: "Query['RoleGroupFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[RoleGroup]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRoleGroupInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyRoleGroupInput' }
      ],
      name: 'RoleGroupFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RoleGroupPagination: {
    type: 'RoleGroupPagination',
    schemaKey: 'RoleGroupPagination',
    entityName: 'RoleGroupPagination',
    isList: false,
    argsTSName: 'QueryRoleGroupPaginationArgs',
    returnTSName: "Query['RoleGroupPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RoleGroupPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRoleGroupInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyRoleGroupInput' }
      ],
      name: 'RoleGroupPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ChannelFindMany: {
    type: '[Channel]',
    schemaKey: 'ChannelFindMany',
    entityName: 'Channel',
    isList: true,
    argsTSName: 'QueryChannelFindManyArgs',
    returnTSName: "Query['ChannelFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Channel]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyChannelInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyChannelInput' }
      ],
      name: 'ChannelFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ChannelFindOne: {
    type: 'Channel',
    schemaKey: 'ChannelFindOne',
    entityName: 'Channel',
    isList: false,
    argsTSName: 'QueryChannelFindOneArgs',
    returnTSName: "Query['ChannelFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Channel',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneChannelInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneChannelInput' }
      ],
      name: 'ChannelFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ChannelPagination: {
    type: 'ChannelPagination',
    schemaKey: 'ChannelPagination',
    entityName: 'ChannelPagination',
    isList: false,
    argsTSName: 'QueryChannelPaginationArgs',
    returnTSName: "Query['ChannelPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ChannelPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyChannelInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyChannelInput' }
      ],
      name: 'ChannelPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserContentPermissionFindOne: {
    type: 'UserContentPermission',
    schemaKey: 'UserContentPermissionFindOne',
    entityName: 'UserContentPermission',
    isList: false,
    argsTSName: 'QueryUserContentPermissionFindOneArgs',
    returnTSName: "Query['UserContentPermissionFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserContentPermission',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneUserContentPermissionInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneUserContentPermissionInput'
        }
      ],
      name: 'UserContentPermissionFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ContentPermissionsHelper: {
    type: '[ContentPermissionsHelperOption]',
    schemaKey: 'ContentPermissionsHelper',
    entityName: 'ContentPermissionsHelperOption',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['ContentPermissionsHelper']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Lista de possiveis content-permissions que os usuários podem ter.',
      deprecationReason: null,
      type: '[ContentPermissionsHelperOption]',
      args: [],
      name: 'ContentPermissionsHelper',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserPagination: {
    type: 'UserPagination',
    schemaKey: 'UserPagination',
    entityName: 'UserPagination',
    isList: false,
    argsTSName: 'QueryUserPaginationArgs',
    returnTSName: "Query['UserPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' }
      ],
      name: 'UserPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindByIds: {
    type: '[User]',
    schemaKey: 'UserFindByIds',
    entityName: 'User',
    isList: true,
    argsTSName: 'QueryUserFindByIdsArgs',
    returnTSName: "Query['UserFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[User]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsUserInput' }
      ],
      name: 'UserFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindById: {
    type: 'User',
    schemaKey: 'UserFindById',
    entityName: 'User',
    isList: false,
    argsTSName: 'QueryUserFindByIdArgs',
    returnTSName: "Query['UserFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'UserFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindOne: {
    type: 'User',
    schemaKey: 'UserFindOne',
    entityName: 'User',
    isList: false,
    argsTSName: 'QueryUserFindOneArgs',
    returnTSName: "Query['UserFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneUserInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneUserInput' }
      ],
      name: 'UserFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindMany: {
    type: '[User]',
    schemaKey: 'UserFindMany',
    entityName: 'User',
    isList: true,
    argsTSName: 'QueryUserFindManyArgs',
    returnTSName: "Query['UserFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[User]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' }
      ],
      name: 'UserFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  User: {
    type: 'User',
    schemaKey: 'User',
    entityName: 'User',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['User']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [],
      name: 'User',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserSearch: {
    type: '[UserSearch]',
    schemaKey: 'UserSearch',
    entityName: 'UserSearch',
    isList: true,
    argsTSName: 'QueryUserSearchArgs',
    returnTSName: "Query['UserSearch']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca do usuario',
      deprecationReason: null,
      type: '[UserSearch]',
      args: [
        {
          name: 'search',
          description: 'Nome parcial, email parcial ou ObjectId',
          type: 'String!'
        },
        { name: 'roleGroup', description: null, type: '[String]' }
      ],
      name: 'UserSearch',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserSearchPagination: {
    type: 'UserPagination',
    schemaKey: 'UserSearchPagination',
    entityName: 'UserPagination',
    isList: false,
    argsTSName: 'QueryUserSearchPaginationArgs',
    returnTSName: "Query['UserSearchPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'UserSearchPaginationFilterInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' }
      ],
      name: 'UserSearchPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TextFindBlackList: {
    type: 'TextFindBlackList',
    schemaKey: 'TextFindBlackList',
    entityName: 'TextFindBlackList',
    isList: false,
    argsTSName: 'QueryTextFindBlackListArgs',
    returnTSName: "Query['TextFindBlackList']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'TextFindBlackList',
      args: [{ name: 'text', description: null, type: 'String!' }],
      name: 'TextFindBlackList',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindByEmails: {
    type: '[UserSimpleResult]',
    schemaKey: 'UserFindByEmails',
    entityName: 'UserSimpleResult',
    isList: true,
    argsTSName: 'QueryUserFindByEmailsArgs',
    returnTSName: "Query['UserFindByEmails']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca do usuario',
      deprecationReason: null,
      type: '[UserSimpleResult]',
      args: [
        {
          name: 'emails',
          description: 'Lista de emails de usuários',
          type: '[String]!'
        }
      ],
      name: 'UserFindByEmails',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindByMatricula: {
    type: '[User]',
    schemaKey: 'UserFindByMatricula',
    entityName: 'User',
    isList: true,
    argsTSName: 'QueryUserFindByMatriculaArgs',
    returnTSName: "Query['UserFindByMatricula']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[User]',
      args: [
        { name: 'isMatriculaNull', description: null, type: 'Boolean' },
        { name: 'email', description: null, type: 'String' },
        { name: 'name', description: null, type: 'String' },
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'UserFindByMatricula',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FileFindByIds: {
    type: '[File]',
    schemaKey: 'FileFindByIds',
    entityName: 'File',
    isList: true,
    argsTSName: 'QueryFileFindByIdsArgs',
    returnTSName: "Query['FileFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[File]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsFileInput' }
      ],
      name: 'FileFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FileFindById: {
    type: 'File',
    schemaKey: 'FileFindById',
    entityName: 'File',
    isList: false,
    argsTSName: 'QueryFileFindByIdArgs',
    returnTSName: "Query['FileFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'File',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FileFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FilePagination: {
    type: 'FilePagination',
    schemaKey: 'FilePagination',
    entityName: 'FilePagination',
    isList: false,
    argsTSName: 'QueryFilePaginationArgs',
    returnTSName: "Query['FilePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FilePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyFileInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyFileInput' }
      ],
      name: 'FilePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialFindById: {
    type: 'Material',
    schemaKey: 'MaterialFindById',
    entityName: 'Material',
    isList: false,
    argsTSName: 'QueryMaterialFindByIdArgs',
    returnTSName: "Query['MaterialFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Material',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'MaterialFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialFindMany: {
    type: '[Material]',
    schemaKey: 'MaterialFindMany',
    entityName: 'Material',
    isList: true,
    argsTSName: 'QueryMaterialFindManyArgs',
    returnTSName: "Query['MaterialFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Material]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyMaterialInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' }
      ],
      name: 'MaterialFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialFindOne: {
    type: 'Material',
    schemaKey: 'MaterialFindOne',
    entityName: 'Material',
    isList: false,
    argsTSName: 'QueryMaterialFindOneArgs',
    returnTSName: "Query['MaterialFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Material',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneMaterialInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneMaterialInput' }
      ],
      name: 'MaterialFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialPagination: {
    type: 'MaterialPagination',
    schemaKey: 'MaterialPagination',
    entityName: 'MaterialPagination',
    isList: false,
    argsTSName: 'QueryMaterialPaginationArgs',
    returnTSName: "Query['MaterialPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MaterialPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyMaterialInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' }
      ],
      name: 'MaterialPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialCount: {
    type: 'Int',
    schemaKey: 'MaterialCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryMaterialCountArgs',
    returnTSName: "Query['MaterialCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterMaterialInput'
        },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'MaterialCount',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialStatusOptions: {
    type: '[MaterialStatusOption]',
    schemaKey: 'MaterialStatusOptions',
    entityName: 'MaterialStatusOption',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['MaterialStatusOptions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[MaterialStatusOption]',
      args: [],
      name: 'MaterialStatusOptions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialSearchOwners: {
    type: '[MaterialSearchOwners]',
    schemaKey: 'MaterialSearchOwners',
    entityName: 'MaterialSearchOwners',
    isList: true,
    argsTSName: 'QueryMaterialSearchOwnersArgs',
    returnTSName: "Query['MaterialSearchOwners']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[MaterialSearchOwners]',
      args: [{ name: 'kind', description: null, type: 'EnumMaterialKind' }],
      name: 'MaterialSearchOwners',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailFindMany: {
    type: '[Email]',
    schemaKey: 'EmailFindMany',
    entityName: 'Email',
    isList: true,
    argsTSName: 'QueryEmailFindManyArgs',
    returnTSName: "Query['EmailFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Email]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEmailInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyEmailInput' }
      ],
      name: 'EmailFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailPagination: {
    type: 'EmailPagination',
    schemaKey: 'EmailPagination',
    entityName: 'EmailPagination',
    isList: false,
    argsTSName: 'QueryEmailPaginationArgs',
    returnTSName: "Query['EmailPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEmailInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyEmailInput' }
      ],
      name: 'EmailPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailFindOne: {
    type: 'Email',
    schemaKey: 'EmailFindOne',
    entityName: 'Email',
    isList: false,
    argsTSName: 'QueryEmailFindOneArgs',
    returnTSName: "Query['EmailFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Email',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneEmailInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneEmailInput' }
      ],
      name: 'EmailFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailCheckRead: {
    type: 'Boolean',
    schemaKey: 'EmailCheckRead',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'QueryEmailCheckReadArgs',
    returnTSName: "Query['EmailCheckRead']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Retorna true se o email foi marcado como lido pelo destinatario informado',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'emailId', description: null, type: 'String!' },
        { name: 'userEmail', description: null, type: 'String!' }
      ],
      name: 'EmailCheckRead',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialSearch: {
    type: 'MaterialSearch',
    schemaKey: 'MaterialSearch',
    entityName: 'MaterialSearch',
    isList: false,
    argsTSName: 'QueryMaterialSearchArgs',
    returnTSName: "Query['MaterialSearch']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'MaterialSearch',
      args: [
        { name: 'search', description: null, type: 'String!' },
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int', defaultValue: 20 },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' },
        { name: 'category', description: null, type: 'String' },
        { name: 'kind', description: null, type: 'EnumMaterialKind' }
      ],
      name: 'MaterialSearch',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialFindPinned: {
    type: 'MaterialPagination',
    schemaKey: 'MaterialFindPinned',
    entityName: 'MaterialPagination',
    isList: false,
    argsTSName: 'QueryMaterialFindPinnedArgs',
    returnTSName: "Query['MaterialFindPinned']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MaterialPagination',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
        {
          name: 'filter',
          description: null,
          type: 'FilterFindManyMaterialInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' }
      ],
      name: 'MaterialFindPinned',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  NotificationFindMany: {
    type: '[Notification]',
    schemaKey: 'NotificationFindMany',
    entityName: 'Notification',
    isList: true,
    argsTSName: 'QueryNotificationFindManyArgs',
    returnTSName: "Query['NotificationFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Notification]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyNotificationInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyNotificationInput'
        }
      ],
      name: 'NotificationFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  NotificationFindOne: {
    type: 'Notification',
    schemaKey: 'NotificationFindOne',
    entityName: 'Notification',
    isList: false,
    argsTSName: 'QueryNotificationFindOneArgs',
    returnTSName: "Query['NotificationFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Notification',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneNotificationInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneNotificationInput'
        }
      ],
      name: 'NotificationFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  NotificationPagination: {
    type: 'NotificationPagination',
    schemaKey: 'NotificationPagination',
    entityName: 'NotificationPagination',
    isList: false,
    argsTSName: 'QueryNotificationPaginationArgs',
    returnTSName: "Query['NotificationPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'NotificationPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyNotificationInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyNotificationInput'
        }
      ],
      name: 'NotificationPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  HelperAddress: {
    type: 'AddressHelperPayload',
    schemaKey: 'HelperAddress',
    entityName: 'AddressHelperPayload',
    isList: false,
    argsTSName: 'QueryHelperAddressArgs',
    returnTSName: "Query['HelperAddress']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'AddressHelperPayload',
      args: [{ name: 'zipCode', description: null, type: 'String!' }],
      name: 'HelperAddress',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CategoryFindOne: {
    type: 'Category',
    schemaKey: 'CategoryFindOne',
    entityName: 'Category',
    isList: false,
    argsTSName: 'QueryCategoryFindOneArgs',
    returnTSName: "Query['CategoryFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Category',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneCategoryInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneCategoryInput' }
      ],
      name: 'CategoryFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CategoryFindById: {
    type: 'Category',
    schemaKey: 'CategoryFindById',
    entityName: 'Category',
    isList: false,
    argsTSName: 'QueryCategoryFindByIdArgs',
    returnTSName: "Query['CategoryFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Category',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'SortMenuEnum' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CategoryFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CategoryFindMany: {
    type: '[Category]',
    schemaKey: 'CategoryFindMany',
    entityName: 'Category',
    isList: true,
    argsTSName: 'QueryCategoryFindManyArgs',
    returnTSName: "Query['CategoryFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Category]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyCategoryInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyCategoryInput' }
      ],
      name: 'CategoryFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CategoryFindByIds: {
    type: '[Category]',
    schemaKey: 'CategoryFindByIds',
    entityName: 'Category',
    isList: true,
    argsTSName: 'QueryCategoryFindByIdsArgs',
    returnTSName: "Query['CategoryFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Category]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsCategoryInput' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CategoryFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CategoryPagination: {
    type: 'CategoryPagination',
    schemaKey: 'CategoryPagination',
    entityName: 'CategoryPagination',
    isList: false,
    argsTSName: 'QueryCategoryPaginationArgs',
    returnTSName: "Query['CategoryPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CategoryPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyCategoryInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyCategoryInput' }
      ],
      name: 'CategoryPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  WebPushPublicKey: {
    type: 'String!',
    schemaKey: 'WebPushPublicKey',
    entityName: 'String',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['WebPushPublicKey']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Retorna a chave publica (VAPID key) pra a subscription de web push notifications',
      deprecationReason: null,
      type: 'String!',
      args: [],
      name: 'WebPushPublicKey',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'query'
  },

  PinFindMany: {
    type: '[Pin]',
    schemaKey: 'PinFindMany',
    entityName: 'Pin',
    isList: true,
    argsTSName: 'QueryPinFindManyArgs',
    returnTSName: "Query['PinFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Pin]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPinInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyPinInput' }
      ],
      name: 'PinFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PinPagination: {
    type: 'PinPagination',
    schemaKey: 'PinPagination',
    entityName: 'PinPagination',
    isList: false,
    argsTSName: 'QueryPinPaginationArgs',
    returnTSName: "Query['PinPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PinPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPinInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyPinInput' }
      ],
      name: 'PinPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ShareFindMany: {
    type: '[Share]',
    schemaKey: 'ShareFindMany',
    entityName: 'Share',
    isList: true,
    argsTSName: 'QueryShareFindManyArgs',
    returnTSName: "Query['ShareFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Share]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyShareInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyShareInput' }
      ],
      name: 'ShareFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SharePagination: {
    type: 'SharePagination',
    schemaKey: 'SharePagination',
    entityName: 'SharePagination',
    isList: false,
    argsTSName: 'QuerySharePaginationArgs',
    returnTSName: "Query['SharePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'SharePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyShareInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyShareInput' }
      ],
      name: 'SharePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CommentPagination: {
    type: 'CommentPagination',
    schemaKey: 'CommentPagination',
    entityName: 'CommentPagination',
    isList: false,
    argsTSName: 'QueryCommentPaginationArgs',
    returnTSName: "Query['CommentPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CommentPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyCommentInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyCommentInput' }
      ],
      name: 'CommentPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CommentFindOne: {
    type: 'Comment',
    schemaKey: 'CommentFindOne',
    entityName: 'Comment',
    isList: false,
    argsTSName: 'QueryCommentFindOneArgs',
    returnTSName: "Query['CommentFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Comment',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneCommentInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneCommentInput' }
      ],
      name: 'CommentFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CommentFindById: {
    type: 'Comment',
    schemaKey: 'CommentFindById',
    entityName: 'Comment',
    isList: false,
    argsTSName: 'QueryCommentFindByIdArgs',
    returnTSName: "Query['CommentFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Comment',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'CommentFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CommentFindMany: {
    type: '[Comment]',
    schemaKey: 'CommentFindMany',
    entityName: 'Comment',
    isList: true,
    argsTSName: 'QueryCommentFindManyArgs',
    returnTSName: "Query['CommentFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Comment]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyCommentInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyCommentInput' }
      ],
      name: 'CommentFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CommentSortPagination: {
    type: 'CommentPagination',
    schemaKey: 'CommentSortPagination',
    entityName: 'CommentPagination',
    isList: false,
    argsTSName: 'QueryCommentSortPaginationArgs',
    returnTSName: "Query['CommentSortPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CommentPagination',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
        {
          name: 'filter',
          description: null,
          type: 'CommentSortPaginationFilterInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyCommentInput' }
      ],
      name: 'CommentSortPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EventLivePagination: {
    type: 'EventLivePagination',
    schemaKey: 'EventLivePagination',
    entityName: 'EventLivePagination',
    isList: false,
    argsTSName: 'QueryEventLivePaginationArgs',
    returnTSName: "Query['EventLivePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EventLivePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEventLiveInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyEventLiveInput' }
      ],
      name: 'EventLivePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EventLiveFindOne: {
    type: 'EventLive',
    schemaKey: 'EventLiveFindOne',
    entityName: 'EventLive',
    isList: false,
    argsTSName: 'QueryEventLiveFindOneArgs',
    returnTSName: "Query['EventLiveFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EventLive',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneEventLiveInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneEventLiveInput' }
      ],
      name: 'EventLiveFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EventLiveFindById: {
    type: 'EventLive',
    schemaKey: 'EventLiveFindById',
    entityName: 'EventLive',
    isList: false,
    argsTSName: 'QueryEventLiveFindByIdArgs',
    returnTSName: "Query['EventLiveFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EventLive',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'EventLiveFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  BannerFindMany: {
    type: '[Banner]',
    schemaKey: 'BannerFindMany',
    entityName: 'Banner',
    isList: true,
    argsTSName: 'QueryBannerFindManyArgs',
    returnTSName: "Query['BannerFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Banner]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyBannerInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyBannerInput' }
      ],
      name: 'BannerFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  BannerFindOne: {
    type: 'Banner',
    schemaKey: 'BannerFindOne',
    entityName: 'Banner',
    isList: false,
    argsTSName: 'QueryBannerFindOneArgs',
    returnTSName: "Query['BannerFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Banner',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneBannerInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneBannerInput' }
      ],
      name: 'BannerFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsCount: {
    type: 'Int',
    schemaKey: 'AnalyticsCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryAnalyticsCountArgs',
    returnTSName: "Query['AnalyticsCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterAnalyticsInput'
        }
      ],
      name: 'AnalyticsCount',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsPagination: {
    type: 'AnalyticsPagination',
    schemaKey: 'AnalyticsPagination',
    entityName: 'AnalyticsPagination',
    isList: false,
    argsTSName: 'QueryAnalyticsPaginationArgs',
    returnTSName: "Query['AnalyticsPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'AnalyticsPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyAnalyticsInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyAnalyticsInput' }
      ],
      name: 'AnalyticsPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsUsersReport: {
    type: '[AnalyticsUser]',
    schemaKey: 'AnalyticsUsersReport',
    entityName: 'AnalyticsUser',
    isList: true,
    argsTSName: 'QueryAnalyticsUsersReportArgs',
    returnTSName: "Query['AnalyticsUsersReport']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[AnalyticsUser]',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int', defaultValue: 20 }
      ],
      name: 'AnalyticsUsersReport',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsFindReport: {
    type: '[AnalyticsReportItem]',
    schemaKey: 'AnalyticsFindReport',
    entityName: 'AnalyticsReportItem',
    isList: true,
    argsTSName: 'QueryAnalyticsFindReportArgs',
    returnTSName: "Query['AnalyticsFindReport']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsReportItem]',
      args: [
        {
          name: 'filter',
          description: null,
          type: 'AnalyticsReportFilterInput'
        },
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'AnalyticsFindReport',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsUserNotification: {
    type: '[AnalyticsUserNotification]',
    schemaKey: 'AnalyticsUserNotification',
    entityName: 'AnalyticsUserNotification',
    isList: true,
    argsTSName: 'QueryAnalyticsUserNotificationArgs',
    returnTSName: "Query['AnalyticsUserNotification']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsUserNotification]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'AnalyticsUserNotification',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsPin: {
    type: '[AnalyticsPin]',
    schemaKey: 'AnalyticsPin',
    entityName: 'AnalyticsPin',
    isList: true,
    argsTSName: 'QueryAnalyticsPinArgs',
    returnTSName: "Query['AnalyticsPin']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsPin]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'AnalyticsPin',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsComment: {
    type: '[AnalyticsComment]',
    schemaKey: 'AnalyticsComment',
    entityName: 'AnalyticsComment',
    isList: true,
    argsTSName: 'QueryAnalyticsCommentArgs',
    returnTSName: "Query['AnalyticsComment']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsComment]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'AnalyticsComment',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsPoints: {
    type: '[AnalyticsPoints]',
    schemaKey: 'AnalyticsPoints',
    entityName: 'AnalyticsPoints',
    isList: true,
    argsTSName: 'QueryAnalyticsPointsArgs',
    returnTSName: "Query['AnalyticsPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsPoints]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'AnalyticsPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsDownloads: {
    type: 'Boolean',
    schemaKey: 'AnalyticsDownloads',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'QueryAnalyticsDownloadsArgs',
    returnTSName: "Query['AnalyticsDownloads']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'fileId', description: null, type: 'String' }],
      name: 'AnalyticsDownloads',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SearchCountFindMany: {
    type: '[SearchCount]',
    schemaKey: 'SearchCountFindMany',
    entityName: 'SearchCount',
    isList: true,
    argsTSName: 'QuerySearchCountFindManyArgs',
    returnTSName: "Query['SearchCountFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[SearchCount]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManySearchCountInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManySearchCountInput'
        }
      ],
      name: 'SearchCountFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SearchCountPagination: {
    type: 'SearchCountPagination',
    schemaKey: 'SearchCountPagination',
    entityName: 'SearchCountPagination',
    isList: false,
    argsTSName: 'QuerySearchCountPaginationArgs',
    returnTSName: "Query['SearchCountPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'SearchCountPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManySearchCountInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManySearchCountInput'
        }
      ],
      name: 'SearchCountPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FolderFindMany: {
    type: '[Folder]',
    schemaKey: 'FolderFindMany',
    entityName: 'Folder',
    isList: true,
    argsTSName: 'QueryFolderFindManyArgs',
    returnTSName: "Query['FolderFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Folder]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyFolderInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyFolderInput' }
      ],
      name: 'FolderFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FolderFindById: {
    type: 'Folder',
    schemaKey: 'FolderFindById',
    entityName: 'Folder',
    isList: false,
    argsTSName: 'QueryFolderFindByIdArgs',
    returnTSName: "Query['FolderFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Folder',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FolderFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FolderPagination: {
    type: 'FolderPagination',
    schemaKey: 'FolderPagination',
    entityName: 'FolderPagination',
    isList: false,
    argsTSName: 'QueryFolderPaginationArgs',
    returnTSName: "Query['FolderPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FolderPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyFolderInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyFolderInput' }
      ],
      name: 'FolderPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RecentsActivitiesPagination: {
    type: 'RecentsActivitiesPagination',
    schemaKey: 'RecentsActivitiesPagination',
    entityName: 'RecentsActivitiesPagination',
    isList: false,
    argsTSName: 'QueryRecentsActivitiesPaginationArgs',
    returnTSName: "Query['RecentsActivitiesPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RecentsActivitiesPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRecentsActivitiesInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyRecentsActivitiesInput'
        }
      ],
      name: 'RecentsActivitiesPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserWordsBlackListFindMany: {
    type: '[UserWordsBlackList]',
    schemaKey: 'UserWordsBlackListFindMany',
    entityName: 'UserWordsBlackList',
    isList: true,
    argsTSName: 'QueryUserWordsBlackListFindManyArgs',
    returnTSName: "Query['UserWordsBlackListFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserWordsBlackList]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserWordsBlackListInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyUserWordsBlackListInput'
        }
      ],
      name: 'UserWordsBlackListFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserWordsBlackListFindById: {
    type: 'UserWordsBlackList',
    schemaKey: 'UserWordsBlackListFindById',
    entityName: 'UserWordsBlackList',
    isList: false,
    argsTSName: 'QueryUserWordsBlackListFindByIdArgs',
    returnTSName: "Query['UserWordsBlackListFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserWordsBlackList',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'UserWordsBlackListFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadSensitiveFilesPagination: {
    type: 'DownloadSensitiveFilesPagination',
    schemaKey: 'DownloadSensitiveFilesPagination',
    entityName: 'DownloadSensitiveFilesPagination',
    isList: false,
    argsTSName: 'QueryDownloadSensitiveFilesPaginationArgs',
    returnTSName: "Query['DownloadSensitiveFilesPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DownloadSensitiveFilesPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyDownloadSensitiveFilesInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyDownloadSensitiveFilesInput'
        }
      ],
      name: 'DownloadSensitiveFilesPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadUsersInfo: {
    type: 'DownloadUsersInfo',
    schemaKey: 'DownloadUsersInfo',
    entityName: 'DownloadUsersInfo',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['DownloadUsersInfo']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadUsersInfo',
      args: [],
      name: 'DownloadUsersInfo',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadAcceptedTerms: {
    type: 'DownloadAcceptedTerms',
    schemaKey: 'DownloadAcceptedTerms',
    entityName: 'DownloadAcceptedTerms',
    isList: false,
    argsTSName: 'QueryDownloadAcceptedTermsArgs',
    returnTSName: "Query['DownloadAcceptedTerms']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadAcceptedTerms',
      args: [
        { name: 'incentiveCampaignId', description: null, type: 'String!' }
      ],
      name: 'DownloadAcceptedTerms',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadUsersAcceptedPoints: {
    type: 'DownloadUsersAcceptedPoints',
    schemaKey: 'DownloadUsersAcceptedPoints',
    entityName: 'DownloadUsersAcceptedPoints',
    isList: false,
    argsTSName: 'QueryDownloadUsersAcceptedPointsArgs',
    returnTSName: "Query['DownloadUsersAcceptedPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadUsersAcceptedPoints',
      args: [
        { name: 'incentiveCampaignId', description: null, type: 'String!' }
      ],
      name: 'DownloadUsersAcceptedPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadUsersWithPoints: {
    type: 'DownloadUsersWithPoints',
    schemaKey: 'DownloadUsersWithPoints',
    entityName: 'DownloadUsersWithPoints',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['DownloadUsersWithPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadUsersWithPoints',
      args: [],
      name: 'DownloadUsersWithPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadUsersBase: {
    type: 'DownloadUsersInfo',
    schemaKey: 'DownloadUsersBase',
    entityName: 'DownloadUsersInfo',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['DownloadUsersBase']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadUsersInfo',
      args: [],
      name: 'DownloadUsersBase',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadExtraCredit: {
    type: 'DownloadExtraCreditUrl',
    schemaKey: 'DownloadExtraCredit',
    entityName: 'DownloadExtraCreditUrl',
    isList: false,
    argsTSName: 'QueryDownloadExtraCreditArgs',
    returnTSName: "Query['DownloadExtraCredit']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadExtraCreditUrl',
      args: [
        { name: 'campaignId', description: null, type: 'String!' },
        { name: 'transactionId', description: null, type: 'String' }
      ],
      name: 'DownloadExtraCredit',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadReversalPoints: {
    type: 'DownloadReversalUrl',
    schemaKey: 'DownloadReversalPoints',
    entityName: 'DownloadReversalUrl',
    isList: false,
    argsTSName: 'QueryDownloadReversalPointsArgs',
    returnTSName: "Query['DownloadReversalPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadReversalUrl',
      args: [
        { name: 'campaignId', description: null, type: 'String!' },
        { name: 'transactionId', description: null, type: 'String' }
      ],
      name: 'DownloadReversalPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadExpirePoints: {
    type: 'DownloadExpirePointsUrl',
    schemaKey: 'DownloadExpirePoints',
    entityName: 'DownloadExpirePointsUrl',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['DownloadExpirePoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadExpirePointsUrl',
      args: [],
      name: 'DownloadExpirePoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadDebitExpirePoints: {
    type: 'DownloadDebitExpirePointsUrl',
    schemaKey: 'DownloadDebitExpirePoints',
    entityName: 'DownloadDebitExpirePointsUrl',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['DownloadDebitExpirePoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadDebitExpirePointsUrl',
      args: [],
      name: 'DownloadDebitExpirePoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ImportUsersPlanFindMany: {
    type: '[ImportUsersPlan]',
    schemaKey: 'ImportUsersPlanFindMany',
    entityName: 'ImportUsersPlan',
    isList: true,
    argsTSName: 'QueryImportUsersPlanFindManyArgs',
    returnTSName: "Query['ImportUsersPlanFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ImportUsersPlan]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyImportUsersPlanInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyImportUsersPlanInput'
        }
      ],
      name: 'ImportUsersPlanFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ImportUsersPlanFindById: {
    type: 'ImportUsersPlan',
    schemaKey: 'ImportUsersPlanFindById',
    entityName: 'ImportUsersPlan',
    isList: false,
    argsTSName: 'QueryImportUsersPlanFindByIdArgs',
    returnTSName: "Query['ImportUsersPlanFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ImportUsersPlan',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'ImportUsersPlanFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ImportUsersPlanFindByIds: {
    type: '[ImportUsersPlan]',
    schemaKey: 'ImportUsersPlanFindByIds',
    entityName: 'ImportUsersPlan',
    isList: true,
    argsTSName: 'QueryImportUsersPlanFindByIdsArgs',
    returnTSName: "Query['ImportUsersPlanFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ImportUsersPlan]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindByIdsImportUsersPlanInput'
        },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'ImportUsersPlanFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ImportUsersPlanPagination: {
    type: 'ImportUsersPlanPagination',
    schemaKey: 'ImportUsersPlanPagination',
    entityName: 'ImportUsersPlanPagination',
    isList: false,
    argsTSName: 'QueryImportUsersPlanPaginationArgs',
    returnTSName: "Query['ImportUsersPlanPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ImportUsersPlanPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyImportUsersPlanInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyImportUsersPlanInput'
        }
      ],
      name: 'ImportUsersPlanPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserDeletedFindByIds: {
    type: '[UserDeleted]',
    schemaKey: 'UserDeletedFindByIds',
    entityName: 'UserDeleted',
    isList: true,
    argsTSName: 'QueryUserDeletedFindByIdsArgs',
    returnTSName: "Query['UserDeletedFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserDeleted]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindByIdsUserDeletedInput'
        }
      ],
      name: 'UserDeletedFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserDeletedFindOne: {
    type: 'UserDeleted',
    schemaKey: 'UserDeletedFindOne',
    entityName: 'UserDeleted',
    isList: false,
    argsTSName: 'QueryUserDeletedFindOneArgs',
    returnTSName: "Query['UserDeletedFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserDeleted',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneUserDeletedInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneUserDeletedInput' }
      ],
      name: 'UserDeletedFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserDeletedFindMany: {
    type: '[UserDeleted]',
    schemaKey: 'UserDeletedFindMany',
    entityName: 'UserDeleted',
    isList: true,
    argsTSName: 'QueryUserDeletedFindManyArgs',
    returnTSName: "Query['UserDeletedFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserDeleted]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserDeletedInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyUserDeletedInput'
        }
      ],
      name: 'UserDeletedFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserHistoryBlockFindMany: {
    type: '[UserHistoryBlock]',
    schemaKey: 'UserHistoryBlockFindMany',
    entityName: 'UserHistoryBlock',
    isList: true,
    argsTSName: 'QueryUserHistoryBlockFindManyArgs',
    returnTSName: "Query['UserHistoryBlockFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserHistoryBlock]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserHistoryBlockInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyUserHistoryBlockInput'
        }
      ],
      name: 'UserHistoryBlockFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserHistoryBlockPagination: {
    type: 'UserHistoryBlockPagination',
    schemaKey: 'UserHistoryBlockPagination',
    entityName: 'UserHistoryBlockPagination',
    isList: false,
    argsTSName: 'QueryUserHistoryBlockPaginationArgs',
    returnTSName: "Query['UserHistoryBlockPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserHistoryBlockPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserHistoryBlockInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyUserHistoryBlockInput'
        }
      ],
      name: 'UserHistoryBlockPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FutureOperationFindById: {
    type: 'FutureOperation',
    schemaKey: 'FutureOperationFindById',
    entityName: 'FutureOperation',
    isList: false,
    argsTSName: 'QueryFutureOperationFindByIdArgs',
    returnTSName: "Query['FutureOperationFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FutureOperation',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FutureOperationFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailStatsFindOne: {
    type: 'EmailStats',
    schemaKey: 'EmailStatsFindOne',
    entityName: 'EmailStats',
    isList: false,
    argsTSName: 'QueryEmailStatsFindOneArgs',
    returnTSName: "Query['EmailStatsFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailStats',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneEmailStatsInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneEmailStatsInput' }
      ],
      name: 'EmailStatsFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailStatsFindMany: {
    type: '[EmailStats]',
    schemaKey: 'EmailStatsFindMany',
    entityName: 'EmailStats',
    isList: true,
    argsTSName: 'QueryEmailStatsFindManyArgs',
    returnTSName: "Query['EmailStatsFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[EmailStats]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEmailStatsInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyEmailStatsInput' }
      ],
      name: 'EmailStatsFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailUnsubscribeFindOne: {
    type: 'EmailUnsubscribe',
    schemaKey: 'EmailUnsubscribeFindOne',
    entityName: 'EmailUnsubscribe',
    isList: false,
    argsTSName: 'QueryEmailUnsubscribeFindOneArgs',
    returnTSName: "Query['EmailUnsubscribeFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailUnsubscribe',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneEmailUnsubscribeInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneEmailUnsubscribeInput'
        }
      ],
      name: 'EmailUnsubscribeFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailUnsubscribeFindMany: {
    type: '[EmailUnsubscribe]',
    schemaKey: 'EmailUnsubscribeFindMany',
    entityName: 'EmailUnsubscribe',
    isList: true,
    argsTSName: 'QueryEmailUnsubscribeFindManyArgs',
    returnTSName: "Query['EmailUnsubscribeFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[EmailUnsubscribe]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEmailUnsubscribeInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyEmailUnsubscribeInput'
        }
      ],
      name: 'EmailUnsubscribeFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailUnsubscribeAction: {
    type: 'EmailUnsubscribeActionPayload',
    schemaKey: 'EmailUnsubscribeAction',
    entityName: 'EmailUnsubscribeActionPayload',
    isList: false,
    argsTSName: 'QueryEmailUnsubscribeActionArgs',
    returnTSName: "Query['EmailUnsubscribeAction']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Descadastra o e-mail para recebimento futuro de e-mails',
      deprecationReason: null,
      type: 'EmailUnsubscribeActionPayload',
      args: [
        { name: 'id', description: null, type: 'String' },
        { name: 'email', description: null, type: 'String' }
      ],
      name: 'EmailUnsubscribeAction',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MeuDiaPdvTableAll: {
    type: 'MeuPdvReturn',
    schemaKey: 'MeuDiaPdvTableAll',
    entityName: 'MeuPdvReturn',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['MeuDiaPdvTableAll']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MeuPdvReturn',
      args: [],
      name: 'MeuDiaPdvTableAll',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UpdatedUsers: {
    type: 'UpdatedUsersPayload',
    schemaKey: 'UpdatedUsers',
    entityName: 'UpdatedUsersPayload',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['UpdatedUsers']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Esta query retorna uma lista com os usuários cadastrados no portal o total e a timestamp da ultima atualização do cache',
      deprecationReason: null,
      type: 'UpdatedUsersPayload',
      args: [],
      name: 'UpdatedUsers',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SelectRegionalProfiles: {
    type: '[RegionalProfiles]',
    schemaKey: 'SelectRegionalProfiles',
    entityName: 'RegionalProfiles',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['SelectRegionalProfiles']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[RegionalProfiles]',
      args: [],
      name: 'SelectRegionalProfiles',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailAnalytics: {
    type: '[ResultEmailAnalytics]',
    schemaKey: 'EmailAnalytics',
    entityName: 'ResultEmailAnalytics',
    isList: true,
    argsTSName: 'QueryEmailAnalyticsArgs',
    returnTSName: "Query['EmailAnalytics']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ResultEmailAnalytics]',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' }
      ],
      name: 'EmailAnalytics',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailStatsAnalytics: {
    type: '[EmailStatsAnalyticsPayload]',
    schemaKey: 'EmailStatsAnalytics',
    entityName: 'EmailStatsAnalyticsPayload',
    isList: true,
    argsTSName: 'QueryEmailStatsAnalyticsArgs',
    returnTSName: "Query['EmailStatsAnalytics']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Status de envio dos e-mails',
      deprecationReason: null,
      type: '[EmailStatsAnalyticsPayload]',
      args: [
        { name: 'day', description: null, type: 'String!' },
        { name: 'month', description: null, type: 'String!' },
        { name: 'year', description: null, type: 'String!' }
      ],
      name: 'EmailStatsAnalytics',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReportMenuView: {
    type: '[ReportMenuView]',
    schemaKey: 'ReportMenuView',
    entityName: 'ReportMenuView',
    isList: true,
    argsTSName: 'QueryReportMenuViewArgs',
    returnTSName: "Query['ReportMenuView']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[ReportMenuView]',
      args: [
        { name: 'date', description: null, type: 'String!' },
        { name: 'menu', description: null, type: 'String!' },
        { name: 'kind', description: null, type: 'String!' }
      ],
      name: 'ReportMenuView',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RemunerationPagination: {
    type: 'RemunerationPagination',
    schemaKey: 'RemunerationPagination',
    entityName: 'RemunerationPagination',
    isList: false,
    argsTSName: 'QueryRemunerationPaginationArgs',
    returnTSName: "Query['RemunerationPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RemunerationPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRemunerationInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyRemunerationInput'
        }
      ],
      name: 'RemunerationPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RemunerationFindMany: {
    type: '[Remuneration]',
    schemaKey: 'RemunerationFindMany',
    entityName: 'Remuneration',
    isList: true,
    argsTSName: 'QueryRemunerationFindManyArgs',
    returnTSName: "Query['RemunerationFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Remuneration]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRemunerationInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyRemunerationInput'
        }
      ],
      name: 'RemunerationFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RemunerationFindOne: {
    type: 'Remuneration',
    schemaKey: 'RemunerationFindOne',
    entityName: 'Remuneration',
    isList: false,
    argsTSName: 'QueryRemunerationFindOneArgs',
    returnTSName: "Query['RemunerationFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Remuneration',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneRemunerationInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneRemunerationInput'
        }
      ],
      name: 'RemunerationFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RemunerationFindById: {
    type: 'Remuneration',
    schemaKey: 'RemunerationFindById',
    entityName: 'Remuneration',
    isList: false,
    argsTSName: 'QueryRemunerationFindByIdArgs',
    returnTSName: "Query['RemunerationFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Remuneration',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'RemunerationFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RemunerationCount: {
    type: 'Int',
    schemaKey: 'RemunerationCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryRemunerationCountArgs',
    returnTSName: "Query['RemunerationCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemunerationInput'
        },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'RemunerationCount',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReportRemuneration: {
    type: '[ReportRemuneration]',
    schemaKey: 'ReportRemuneration',
    entityName: 'ReportRemuneration',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['ReportRemuneration']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[ReportRemuneration]',
      args: [],
      name: 'ReportRemuneration',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsMaterialView: {
    type: 'Material',
    schemaKey: 'AnalyticsMaterialView',
    entityName: 'Material',
    isList: false,
    argsTSName: 'QueryAnalyticsMaterialViewArgs',
    returnTSName: "Query['AnalyticsMaterialView']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Visualização de material por _id e registro de visualização no analytics',
      deprecationReason: null,
      type: 'Material',
      args: [{ name: '_id', description: null, type: 'String!' }],
      name: 'AnalyticsMaterialView',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReportTourUser: {
    type: '[ReportTourUser]',
    schemaKey: 'ReportTourUser',
    entityName: 'ReportTourUser',
    isList: true,
    argsTSName: 'QueryReportTourUserArgs',
    returnTSName: "Query['ReportTourUser']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[ReportTourUser]',
      args: [{ name: 'date', description: null, type: 'String!' }],
      name: 'ReportTourUser',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReconheceWalletPagination: {
    type: 'ReconheceWalletPagination',
    schemaKey: 'ReconheceWalletPagination',
    entityName: 'ReconheceWalletPagination',
    isList: false,
    argsTSName: 'QueryReconheceWalletPaginationArgs',
    returnTSName: "Query['ReconheceWalletPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ReconheceWalletPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyReconheceWalletInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyReconheceWalletInput'
        }
      ],
      name: 'ReconheceWalletPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReconheceWalletFindMany: {
    type: '[ReconheceWallet]',
    schemaKey: 'ReconheceWalletFindMany',
    entityName: 'ReconheceWallet',
    isList: true,
    argsTSName: 'QueryReconheceWalletFindManyArgs',
    returnTSName: "Query['ReconheceWalletFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ReconheceWallet]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyReconheceWalletInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyReconheceWalletInput'
        }
      ],
      name: 'ReconheceWalletFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReconheceWalletFindById: {
    type: 'ReconheceWallet',
    schemaKey: 'ReconheceWalletFindById',
    entityName: 'ReconheceWallet',
    isList: false,
    argsTSName: 'QueryReconheceWalletFindByIdArgs',
    returnTSName: "Query['ReconheceWalletFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ReconheceWallet',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'ReconheceWalletFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReconheceWalletSum: {
    type: 'ReconheceWalletSum',
    schemaKey: 'ReconheceWalletSum',
    entityName: 'ReconheceWalletSum',
    isList: false,
    argsTSName: 'QueryReconheceWalletSumArgs',
    returnTSName: "Query['ReconheceWalletSum']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ReconheceWalletSum',
      args: [
        { name: 'invoiceOrigin', description: null, type: 'String' },
        {
          name: 'createdAtRange',
          description: null,
          type: 'ReconheceWalletCreatedAtRange'
        }
      ],
      name: 'ReconheceWalletSum',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserCreditOperationFindById: {
    type: 'UserCreditOperation',
    schemaKey: 'UserCreditOperationFindById',
    entityName: 'UserCreditOperation',
    isList: false,
    argsTSName: 'QueryUserCreditOperationFindByIdArgs',
    returnTSName: "Query['UserCreditOperationFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserCreditOperation',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'UserCreditOperationFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserCreditOperationPagination: {
    type: 'UserCreditOperationPagination',
    schemaKey: 'UserCreditOperationPagination',
    entityName: 'UserCreditOperationPagination',
    isList: false,
    argsTSName: 'QueryUserCreditOperationPaginationArgs',
    returnTSName: "Query['UserCreditOperationPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserCreditOperationPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserCreditOperationInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyUserCreditOperationInput'
        }
      ],
      name: 'UserCreditOperationPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserCreditOperationFindByIncentiveCampaignId: {
    type: '[UserCreditOperation]',
    schemaKey: 'UserCreditOperationFindByIncentiveCampaignId',
    entityName: 'UserCreditOperation',
    isList: true,
    argsTSName: 'QueryUserCreditOperationFindByIncentiveCampaignIdArgs',
    returnTSName: "Query['UserCreditOperationFindByIncentiveCampaignId']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserCreditOperation]',
      args: [
        { name: 'incentiveCampaignId', description: null, type: 'String!' }
      ],
      name: 'UserCreditOperationFindByIncentiveCampaignId',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ExtractUserCreditPoints: {
    type: '[UserCreditOperation]',
    schemaKey: 'ExtractUserCreditPoints',
    entityName: 'UserCreditOperation',
    isList: true,
    argsTSName: 'QueryExtractUserCreditPointsArgs',
    returnTSName: "Query['ExtractUserCreditPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserCreditOperation]',
      args: [
        { name: 'incentiveCampaignId', description: null, type: 'String' },
        { name: 'cpf', description: null, type: 'String' },
        { name: 'matricula', description: null, type: 'String' },
        { name: 'userName', description: null, type: 'String' }
      ],
      name: 'ExtractUserCreditPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadtUserCreditPoints: {
    type: 'DownloadtUserCreditPoints',
    schemaKey: 'DownloadtUserCreditPoints',
    entityName: 'DownloadtUserCreditPoints',
    isList: false,
    argsTSName: 'QueryDownloadtUserCreditPointsArgs',
    returnTSName: "Query['DownloadtUserCreditPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DownloadtUserCreditPoints',
      args: [
        { name: 'incentiveCampaignIds', description: null, type: '[String]' },
        { name: 'cpf', description: null, type: 'String' },
        { name: 'matricula', description: null, type: 'String' },
        { name: 'userName', description: null, type: 'String' }
      ],
      name: 'DownloadtUserCreditPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserCreditOperationAnalytics: {
    type: '[UserCreditOperation]',
    schemaKey: 'UserCreditOperationAnalytics',
    entityName: 'UserCreditOperation',
    isList: true,
    argsTSName: 'QueryUserCreditOperationAnalyticsArgs',
    returnTSName: "Query['UserCreditOperationAnalytics']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserCreditOperation]',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' }
      ],
      name: 'UserCreditOperationAnalytics',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReconheceTransactionPagination: {
    type: 'ReconheceTransactionPagination',
    schemaKey: 'ReconheceTransactionPagination',
    entityName: 'ReconheceTransactionPagination',
    isList: false,
    argsTSName: 'QueryReconheceTransactionPaginationArgs',
    returnTSName: "Query['ReconheceTransactionPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ReconheceTransactionPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyReconheceTransactionInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyReconheceTransactionInput'
        }
      ],
      name: 'ReconheceTransactionPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReconheceInvoiceOriginFindMany: {
    type: '[ReconheceInvoiceOrigin]',
    schemaKey: 'ReconheceInvoiceOriginFindMany',
    entityName: 'ReconheceInvoiceOrigin',
    isList: true,
    argsTSName: 'QueryReconheceInvoiceOriginFindManyArgs',
    returnTSName: "Query['ReconheceInvoiceOriginFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ReconheceInvoiceOrigin]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyReconheceInvoiceOriginInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyReconheceInvoiceOriginInput'
        }
      ],
      name: 'ReconheceInvoiceOriginFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ReconheceUserBalance: {
    type: 'ReconheceUserBalancePayload',
    schemaKey: 'ReconheceUserBalance',
    entityName: 'ReconheceUserBalancePayload',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['ReconheceUserBalance']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ReconheceUserBalancePayload',
      args: [],
      name: 'ReconheceUserBalance',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EventCreateOne: {
    type: 'CreateOneEventPayload',
    schemaKey: 'EventCreateOne',
    entityName: 'CreateOneEventPayload',
    isList: false,
    argsTSName: 'MutationEventCreateOneArgs',
    returnTSName: "Mutation['EventCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEventPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneEventInput!' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'EventCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventUpdateById: {
    type: 'UpdateByIdEventPayload',
    schemaKey: 'EventUpdateById',
    entityName: 'UpdateByIdEventPayload',
    isList: false,
    argsTSName: 'MutationEventUpdateByIdArgs',
    returnTSName: "Mutation['EventUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdEventPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdEventInput!' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'EventUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventRemoveById: {
    type: 'RemoveByIdEventPayload',
    schemaKey: 'EventRemoveById',
    entityName: 'RemoveByIdEventPayload',
    isList: false,
    argsTSName: 'MutationEventRemoveByIdArgs',
    returnTSName: "Mutation['EventRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdEventPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'EventRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FeedCreateOne: {
    type: 'CreateOneFeedPayload',
    schemaKey: 'FeedCreateOne',
    entityName: 'CreateOneFeedPayload',
    isList: false,
    argsTSName: 'MutationFeedCreateOneArgs',
    returnTSName: "Mutation['FeedCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneFeedPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneFeedInput!' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'FeedCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FeedUpdateById: {
    type: 'UpdateByIdFeedPayload',
    schemaKey: 'FeedUpdateById',
    entityName: 'UpdateByIdFeedPayload',
    isList: false,
    argsTSName: 'MutationFeedUpdateByIdArgs',
    returnTSName: "Mutation['FeedUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdFeedPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdFeedInput!' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'FeedUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FeedRemoveById: {
    type: 'RemoveByIdFeedPayload',
    schemaKey: 'FeedRemoveById',
    entityName: 'RemoveByIdFeedPayload',
    isList: false,
    argsTSName: 'MutationFeedRemoveByIdArgs',
    returnTSName: "Mutation['FeedRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdFeedPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'FeedRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MeuDiaPdvTableCreateOne: {
    type: 'CreateOneMeuDiaPdvTablePayload',
    schemaKey: 'MeuDiaPdvTableCreateOne',
    entityName: 'CreateOneMeuDiaPdvTablePayload',
    isList: false,
    argsTSName: 'MutationMeuDiaPdvTableCreateOneArgs',
    returnTSName: "Mutation['MeuDiaPdvTableCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneMeuDiaPdvTablePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneMeuDiaPdvTableInput!'
        }
      ],
      name: 'MeuDiaPdvTableCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MeuDiaPdvTableUpdateOne: {
    type: 'UpdateOneMeuDiaPdvTablePayload',
    schemaKey: 'MeuDiaPdvTableUpdateOne',
    entityName: 'UpdateOneMeuDiaPdvTablePayload',
    isList: false,
    argsTSName: 'MutationMeuDiaPdvTableUpdateOneArgs',
    returnTSName: "Mutation['MeuDiaPdvTableUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneMeuDiaPdvTablePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneMeuDiaPdvTableInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneMeuDiaPdvTableInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneMeuDiaPdvTableInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'MeuDiaPdvTableUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MeuDiaPdvTableRemoveById: {
    type: 'RemoveByIdMeuDiaPdvTablePayload',
    schemaKey: 'MeuDiaPdvTableRemoveById',
    entityName: 'RemoveByIdMeuDiaPdvTablePayload',
    isList: false,
    argsTSName: 'MutationMeuDiaPdvTableRemoveByIdArgs',
    returnTSName: "Mutation['MeuDiaPdvTableRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdMeuDiaPdvTablePayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'MeuDiaPdvTableRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MeuDiaPdvTableCreateMany: {
    type: 'CreateManyMeuDiaPdvTablePayload',
    schemaKey: 'MeuDiaPdvTableCreateMany',
    entityName: 'CreateManyMeuDiaPdvTablePayload',
    isList: false,
    argsTSName: 'MutationMeuDiaPdvTableCreateManyArgs',
    returnTSName: "Mutation['MeuDiaPdvTableCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyMeuDiaPdvTablePayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyMeuDiaPdvTableInput!]!'
        }
      ],
      name: 'MeuDiaPdvTableCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  IncentiveCampaignCreateOne: {
    type: 'CreateOneIncentiveCampaignPayload',
    schemaKey: 'IncentiveCampaignCreateOne',
    entityName: 'CreateOneIncentiveCampaignPayload',
    isList: false,
    argsTSName: 'MutationIncentiveCampaignCreateOneArgs',
    returnTSName: "Mutation['IncentiveCampaignCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneIncentiveCampaignPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneIncentiveCampaignInput!'
        },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'IncentiveCampaignCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  IncentiveCampaignUpdateOne: {
    type: 'UpdateOneIncentiveCampaignPayload',
    schemaKey: 'IncentiveCampaignUpdateOne',
    entityName: 'UpdateOneIncentiveCampaignPayload',
    isList: false,
    argsTSName: 'MutationIncentiveCampaignUpdateOneArgs',
    returnTSName: "Mutation['IncentiveCampaignUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneIncentiveCampaignPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneIncentiveCampaignInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneIncentiveCampaignInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneIncentiveCampaignInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'IncentiveCampaignUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  IncentiveCampaignRemoveById: {
    type: 'RemoveByIdIncentiveCampaignPayload',
    schemaKey: 'IncentiveCampaignRemoveById',
    entityName: 'RemoveByIdIncentiveCampaignPayload',
    isList: false,
    argsTSName: 'MutationIncentiveCampaignRemoveByIdArgs',
    returnTSName: "Mutation['IncentiveCampaignRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdIncentiveCampaignPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'IncentiveCampaignRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CampaignContestationCreateOne: {
    type: 'CreateOneCampaignContestationPayload',
    schemaKey: 'CampaignContestationCreateOne',
    entityName: 'CreateOneCampaignContestationPayload',
    isList: false,
    argsTSName: 'MutationCampaignContestationCreateOneArgs',
    returnTSName: "Mutation['CampaignContestationCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneCampaignContestationPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneCampaignContestationInput!'
        },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CampaignContestationCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CampaignContestationResolve: {
    type: 'CampaignContestation',
    schemaKey: 'CampaignContestationResolve',
    entityName: 'CampaignContestation',
    isList: false,
    argsTSName: 'MutationCampaignContestationResolveArgs',
    returnTSName: "Mutation['CampaignContestationResolve']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CampaignContestation',
      args: [
        { name: '_id', description: null, type: 'String!' },
        { name: 'title', description: null, type: 'String!' },
        {
          name: 'status',
          description: null,
          type: 'EnumCampaignContestationResponseStatus!'
        },
        { name: 'body', description: null, type: 'String!' },
        { name: 'responseFileIds', description: null, type: '[String]' }
      ],
      name: 'CampaignContestationResolve',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  IncentiveCampaignPayPoints: {
    type: 'Boolean',
    schemaKey: 'IncentiveCampaignPayPoints',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationIncentiveCampaignPayPointsArgs',
    returnTSName: "Mutation['IncentiveCampaignPayPoints']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'campaignId', description: null, type: 'String!' },
        { name: 'paymentFileId', description: null, type: 'String!' }
      ],
      name: 'IncentiveCampaignPayPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ReconheceReversePoints: {
    type: 'Boolean',
    schemaKey: 'ReconheceReversePoints',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationReconheceReversePointsArgs',
    returnTSName: "Mutation['ReconheceReversePoints']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'paymentFileId', description: null, type: 'String!' },
        { name: 'campaignId', description: null, type: 'String!' },
        { name: 'reason', description: null, type: 'String!' }
      ],
      name: 'ReconheceReversePoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ReconhecePayExtraPoints: {
    type: 'Boolean',
    schemaKey: 'ReconhecePayExtraPoints',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationReconhecePayExtraPointsArgs',
    returnTSName: "Mutation['ReconhecePayExtraPoints']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'paymentFileId', description: null, type: 'String!' },
        { name: 'campaignId', description: null, type: 'String!' }
      ],
      name: 'ReconhecePayExtraPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  IncentiveCampaignLikeToggle: {
    type: 'Boolean',
    schemaKey: 'IncentiveCampaignLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationIncentiveCampaignLikeToggleArgs',
    returnTSName: "Mutation['IncentiveCampaignLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'IncentiveCampaignLikeToggle',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ResearchCreateOne: {
    type: 'CreateOneResearchPayload',
    schemaKey: 'ResearchCreateOne',
    entityName: 'CreateOneResearchPayload',
    isList: false,
    argsTSName: 'MutationResearchCreateOneArgs',
    returnTSName: "Mutation['ResearchCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneResearchPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneResearchInput!' }
      ],
      name: 'ResearchCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ResearchUpdateOne: {
    type: 'UpdateOneResearchPayload',
    schemaKey: 'ResearchUpdateOne',
    entityName: 'UpdateOneResearchPayload',
    isList: false,
    argsTSName: 'MutationResearchUpdateOneArgs',
    returnTSName: "Mutation['ResearchUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneResearchPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneResearchInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneResearchInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneResearchInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'ResearchUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ResearchUpdateById: {
    type: 'UpdateByIdResearchPayload',
    schemaKey: 'ResearchUpdateById',
    entityName: 'UpdateByIdResearchPayload',
    isList: false,
    argsTSName: 'MutationResearchUpdateByIdArgs',
    returnTSName: "Mutation['ResearchUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdResearchPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdResearchInput!' }
      ],
      name: 'ResearchUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ResearchRemoveById: {
    type: 'RemoveByIdResearchPayload',
    schemaKey: 'ResearchRemoveById',
    entityName: 'RemoveByIdResearchPayload',
    isList: false,
    argsTSName: 'MutationResearchRemoveByIdArgs',
    returnTSName: "Mutation['ResearchRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdResearchPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'ResearchRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AnswerResearchCreateOne: {
    type: 'CreateOneAnswerResearchPayload',
    schemaKey: 'AnswerResearchCreateOne',
    entityName: 'CreateOneAnswerResearchPayload',
    isList: false,
    argsTSName: 'MutationAnswerResearchCreateOneArgs',
    returnTSName: "Mutation['AnswerResearchCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneAnswerResearchPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneAnswerResearchInput!'
        }
      ],
      name: 'AnswerResearchCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AnswerResearchUpdateOne: {
    type: 'UpdateOneAnswerResearchPayload',
    schemaKey: 'AnswerResearchUpdateOne',
    entityName: 'UpdateOneAnswerResearchPayload',
    isList: false,
    argsTSName: 'MutationAnswerResearchUpdateOneArgs',
    returnTSName: "Mutation['AnswerResearchUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneAnswerResearchPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneAnswerResearchInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneAnswerResearchInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneAnswerResearchInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'AnswerResearchUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AnswerResearchUpdateById: {
    type: 'UpdateByIdAnswerResearchPayload',
    schemaKey: 'AnswerResearchUpdateById',
    entityName: 'UpdateByIdAnswerResearchPayload',
    isList: false,
    argsTSName: 'MutationAnswerResearchUpdateByIdArgs',
    returnTSName: "Mutation['AnswerResearchUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdAnswerResearchPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateByIdAnswerResearchInput!'
        }
      ],
      name: 'AnswerResearchUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AnswerResearchRemoveById: {
    type: 'RemoveByIdAnswerResearchPayload',
    schemaKey: 'AnswerResearchRemoveById',
    entityName: 'RemoveByIdAnswerResearchPayload',
    isList: false,
    argsTSName: 'MutationAnswerResearchRemoveByIdArgs',
    returnTSName: "Mutation['AnswerResearchRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdAnswerResearchPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'AnswerResearchRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailTemplateCreateOne: {
    type: 'CreateOneEmailTemplatePayload',
    schemaKey: 'EmailTemplateCreateOne',
    entityName: 'CreateOneEmailTemplatePayload',
    isList: false,
    argsTSName: 'MutationEmailTemplateCreateOneArgs',
    returnTSName: "Mutation['EmailTemplateCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEmailTemplatePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneEmailTemplateInput!'
        },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'EmailTemplateCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailTemplateUpdateById: {
    type: 'UpdateByIdEmailTemplatePayload',
    schemaKey: 'EmailTemplateUpdateById',
    entityName: 'UpdateByIdEmailTemplatePayload',
    isList: false,
    argsTSName: 'MutationEmailTemplateUpdateByIdArgs',
    returnTSName: "Mutation['EmailTemplateUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdEmailTemplatePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateByIdEmailTemplateInput!'
        },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'EmailTemplateUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailTemplateRemoveById: {
    type: 'RemoveByIdEmailTemplatePayload',
    schemaKey: 'EmailTemplateRemoveById',
    entityName: 'RemoveByIdEmailTemplatePayload',
    isList: false,
    argsTSName: 'MutationEmailTemplateRemoveByIdArgs',
    returnTSName: "Mutation['EmailTemplateRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdEmailTemplatePayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'EmailTemplateRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RegionRemoveOne: {
    type: 'RemoveOneRegionPayload',
    schemaKey: 'RegionRemoveOne',
    entityName: 'RemoveOneRegionPayload',
    isList: false,
    argsTSName: 'MutationRegionRemoveOneArgs',
    returnTSName: "Mutation['RegionRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneRegionPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveOneRegionInput'
        },
        { name: 'sort', description: null, type: 'SortRemoveOneRegionInput' }
      ],
      name: 'RegionRemoveOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserContentPermissionToggle: {
    type: 'Boolean',
    schemaKey: 'UserContentPermissionToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserContentPermissionToggleArgs',
    returnTSName: "Mutation['UserContentPermissionToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'userId', description: null, type: 'String!' },
        { name: 'roleGroups', description: null, type: '[String]!' },
        { name: 'regions', description: null, type: '[String]!' },
        { name: 'contentTypes', description: null, type: '[String]!' },
        { name: 'viewOtherUsersContent', description: null, type: 'Boolean' }
      ],
      name: 'UserContentPermissionToggle',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserUpdateOne: {
    type: 'UpdateOneUserPayload',
    schemaKey: 'UserUpdateOne',
    entityName: 'UpdateOneUserPayload',
    isList: false,
    argsTSName: 'MutationUserUpdateOneArgs',
    returnTSName: "Mutation['UserUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneUserPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneUserInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneUserInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneUserInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'UserUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserRemoveById: {
    type: 'RemoveByIdUserPayload',
    schemaKey: 'UserRemoveById',
    entityName: 'RemoveByIdUserPayload',
    isList: false,
    argsTSName: 'MutationUserRemoveByIdArgs',
    returnTSName: "Mutation['UserRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdUserPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'UserRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserAcceptTerm: {
    type: 'Boolean',
    schemaKey: 'UserAcceptTerm',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserAcceptTermArgs',
    returnTSName: "Mutation['UserAcceptTerm']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'term', description: null, type: 'String!' }],
      name: 'UserAcceptTerm',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserLoginWithPassword: {
    type: 'User',
    schemaKey: 'UserLoginWithPassword',
    entityName: 'User',
    isList: false,
    argsTSName: 'MutationUserLoginWithPasswordArgs',
    returnTSName: "Mutation['UserLoginWithPassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [
        {
          name: 'user',
          description: 'email, CPF, CNPJ ou matricula',
          type: 'String!'
        },
        { name: 'password', description: null, type: 'String!' },
        { name: 'platformName', description: null, type: 'PlatformNamesEnum' }
      ],
      name: 'UserLoginWithPassword',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserLoginWithToken: {
    type: 'User',
    schemaKey: 'UserLoginWithToken',
    entityName: 'User',
    isList: false,
    argsTSName: 'MutationUserLoginWithTokenArgs',
    returnTSName: "Mutation['UserLoginWithToken']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [
        { name: 'token', description: null, type: 'String!' },
        { name: 'platformName', description: null, type: 'PlatformNamesEnum' }
      ],
      name: 'UserLoginWithToken',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserLoginRenewToken: {
    type: 'User',
    schemaKey: 'UserLoginRenewToken',
    entityName: 'User',
    isList: false,
    argsTSName: 'MutationUserLoginRenewTokenArgs',
    returnTSName: "Mutation['UserLoginRenewToken']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [{ name: 'token', description: null, type: 'String!' }],
      name: 'UserLoginRenewToken',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserSaveLogoutAnalytics: {
    type: 'Boolean',
    schemaKey: 'UserSaveLogoutAnalytics',
    entityName: 'Boolean',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['UserSaveLogoutAnalytics']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [],
      name: 'UserSaveLogoutAnalytics',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserSaveLoginAnalyticsPCS: {
    type: 'Boolean',
    schemaKey: 'UserSaveLoginAnalyticsPCS',
    entityName: 'Boolean',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['UserSaveLoginAnalyticsPCS']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [],
      name: 'UserSaveLoginAnalyticsPCS',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserFillEmail: {
    type: 'Boolean',
    schemaKey: 'UserFillEmail',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserFillEmailArgs',
    returnTSName: "Mutation['UserFillEmail']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'email', description: null, type: 'String!' }],
      name: 'UserFillEmail',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserFillAvatarId: {
    type: 'Boolean',
    schemaKey: 'UserFillAvatarId',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserFillAvatarIdArgs',
    returnTSName: "Mutation['UserFillAvatarId']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'avatarId', description: null, type: 'String!' }],
      name: 'UserFillAvatarId',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserOptIn: {
    type: 'Boolean',
    schemaKey: 'UserOptIn',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserOptInArgs',
    returnTSName: "Mutation['UserOptIn']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'accept', description: null, type: 'Boolean!' }],
      name: 'UserOptIn',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserMarkNotificationAsRead: {
    type: 'Boolean',
    schemaKey: 'UserMarkNotificationAsRead',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserMarkNotificationAsReadArgs',
    returnTSName: "Mutation['UserMarkNotificationAsRead']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'isRead', description: null, type: 'Boolean!' },
        { name: 'notifications', description: null, type: '[String]!' }
      ],
      name: 'UserMarkNotificationAsRead',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserDeleteNotification: {
    type: 'String',
    schemaKey: 'UserDeleteNotification',
    entityName: 'String',
    isList: false,
    argsTSName: 'MutationUserDeleteNotificationArgs',
    returnTSName: "Mutation['UserDeleteNotification']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'String',
      args: [{ name: 'notificationId', description: null, type: 'String!' }],
      name: 'UserDeleteNotification',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserDeleteManyNotification: {
    type: '[String]',
    schemaKey: 'UserDeleteManyNotification',
    entityName: 'String',
    isList: true,
    argsTSName: 'MutationUserDeleteManyNotificationArgs',
    returnTSName: "Mutation['UserDeleteManyNotification']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[String]',
      args: [{ name: 'notificationIds', description: null, type: '[String!]' }],
      name: 'UserDeleteManyNotification',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserRegistration: {
    type: 'UserRegistrationPayload',
    schemaKey: 'UserRegistration',
    entityName: 'UserRegistrationPayload',
    isList: false,
    argsTSName: 'MutationUserRegistrationArgs',
    returnTSName: "Mutation['UserRegistration']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserRegistrationPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UserRegistrationTypeInput!'
        }
      ],
      name: 'UserRegistration',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserBlockComments: {
    type: 'Boolean',
    schemaKey: 'UserBlockComments',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserBlockCommentsArgs',
    returnTSName: "Mutation['UserBlockComments']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'userId', description: null, type: 'String!' },
        { name: 'isBlocked', description: null, type: 'Boolean' },
        { name: 'materialId', description: null, type: 'String' }
      ],
      name: 'UserBlockComments',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ChangeToUserTaNarede: {
    type: 'UserTaNaRede',
    schemaKey: 'ChangeToUserTaNarede',
    entityName: 'UserTaNaRede',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['ChangeToUserTaNarede']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'UserTaNaRede',
      args: [],
      name: 'ChangeToUserTaNarede',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserChangePassword: {
    type: 'Boolean',
    schemaKey: 'UserChangePassword',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserChangePasswordArgs',
    returnTSName: "Mutation['UserChangePassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'newPassword', description: null, type: 'String!' }],
      name: 'UserChangePassword',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserFillEmailSecondary: {
    type: 'Boolean',
    schemaKey: 'UserFillEmailSecondary',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserFillEmailSecondaryArgs',
    returnTSName: "Mutation['UserFillEmailSecondary']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'emailSecondary', description: null, type: 'String!' }],
      name: 'UserFillEmailSecondary',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserConnectMovidesk: {
    type: 'String',
    schemaKey: 'UserConnectMovidesk',
    entityName: 'String',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['UserConnectMovidesk']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'String',
      args: [],
      name: 'UserConnectMovidesk',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserDisable: {
    type: 'Boolean',
    schemaKey: 'UserDisable',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserDisableArgs',
    returnTSName: "Mutation['UserDisable']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: '_id', description: null, type: 'String!' }],
      name: 'UserDisable',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FileCreateOne: {
    type: 'CreateOneFilePayload',
    schemaKey: 'FileCreateOne',
    entityName: 'CreateOneFilePayload',
    isList: false,
    argsTSName: 'MutationFileCreateOneArgs',
    returnTSName: "Mutation['FileCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneFilePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneFileInput!' }
      ],
      name: 'FileCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FileRemoveById: {
    type: 'RemoveByIdFilePayload',
    schemaKey: 'FileRemoveById',
    entityName: 'RemoveByIdFilePayload',
    isList: false,
    argsTSName: 'MutationFileRemoveByIdArgs',
    returnTSName: "Mutation['FileRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdFilePayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FileRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FileUpdateById: {
    type: 'UpdateByIdFilePayload',
    schemaKey: 'FileUpdateById',
    entityName: 'UpdateByIdFilePayload',
    isList: false,
    argsTSName: 'MutationFileUpdateByIdArgs',
    returnTSName: "Mutation['FileUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdFilePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdFileInput!' }
      ],
      name: 'FileUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MaterialCreateOne: {
    type: 'CreateOneMaterialPayload',
    schemaKey: 'MaterialCreateOne',
    entityName: 'CreateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialCreateOneArgs',
    returnTSName: "Mutation['MaterialCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneMaterialInput!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'MaterialCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MaterialUpdateOne: {
    type: 'UpdateOneMaterialPayload',
    schemaKey: 'MaterialUpdateOne',
    entityName: 'UpdateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialUpdateOneArgs',
    returnTSName: "Mutation['MaterialUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneMaterialInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneMaterialInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneMaterialInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'MaterialUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MaterialUpdateById: {
    type: 'UpdateByIdMaterialPayload',
    schemaKey: 'MaterialUpdateById',
    entityName: 'UpdateByIdMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialUpdateByIdArgs',
    returnTSName: "Mutation['MaterialUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdMaterialInput!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'MaterialUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MaterialRemoveOne: {
    type: 'RemoveOneMaterialPayload',
    schemaKey: 'MaterialRemoveOne',
    entityName: 'RemoveOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialRemoveOneArgs',
    returnTSName: "Mutation['MaterialRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneMaterialPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveOneMaterialInput'
        },
        { name: 'sort', description: null, type: 'SortRemoveOneMaterialInput' }
      ],
      name: 'MaterialRemoveOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommunicationCreateOne: {
    type: 'CreateOneMaterialPayload',
    schemaKey: 'CommunicationCreateOne',
    entityName: 'CreateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationCommunicationCreateOneArgs',
    returnTSName: "Mutation['CommunicationCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CreateOneMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneMaterialInput!' }
      ],
      name: 'CommunicationCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommunicationUpdateOne: {
    type: 'UpdateOneMaterialPayload',
    schemaKey: 'CommunicationUpdateOne',
    entityName: 'UpdateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationCommunicationUpdateOneArgs',
    returnTSName: "Mutation['CommunicationUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UpdateOneMaterialPayload',
      args: [
        { name: 'communicationId', description: null, type: 'String!' },
        { name: 'record', description: null, type: 'UpdateOneMaterialInput' }
      ],
      name: 'CommunicationUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommunicationApproveChannel: {
    type: 'Boolean',
    schemaKey: 'CommunicationApproveChannel',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommunicationApproveChannelArgs',
    returnTSName: "Mutation['CommunicationApproveChannel']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'CommunicationApproveChannel',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommunicationDisapproveChannel: {
    type: 'Boolean',
    schemaKey: 'CommunicationDisapproveChannel',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommunicationDisapproveChannelArgs',
    returnTSName: "Mutation['CommunicationDisapproveChannel']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'recordId', description: null, type: 'String!' },
        { name: 'message', description: null, type: 'String!' }
      ],
      name: 'CommunicationDisapproveChannel',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommunicationRemoveApprover: {
    type: 'Boolean',
    schemaKey: 'CommunicationRemoveApprover',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommunicationRemoveApproverArgs',
    returnTSName: "Mutation['CommunicationRemoveApprover']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'communicationId', description: null, type: 'String!' },
        { name: 'userId', description: null, type: 'String!' }
      ],
      name: 'CommunicationRemoveApprover',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailFillTemplate: {
    type: 'String!',
    schemaKey: 'EmailFillTemplate',
    entityName: 'String',
    isList: false,
    argsTSName: 'MutationEmailFillTemplateArgs',
    returnTSName: "Mutation['EmailFillTemplate']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'String!',
      args: [
        { name: 'template', description: null, type: 'EmailTemplateNames' },
        {
          name: 'props',
          description: null,
          type: 'MaterialCommunicationItemsConfigTemplatePropsInput'
        }
      ],
      name: 'EmailFillTemplate',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'mutation'
  },

  EmailMarkAsRead: {
    type: 'Boolean',
    schemaKey: 'EmailMarkAsRead',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationEmailMarkAsReadArgs',
    returnTSName: "Mutation['EmailMarkAsRead']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Marca um email como lido pelo destinatario informado pelo token recebido no e-mail',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'token', description: null, type: 'String!' }],
      name: 'EmailMarkAsRead',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  SendEmailFront: {
    type: 'Boolean',
    schemaKey: 'SendEmailFront',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationSendEmailFrontArgs',
    returnTSName: "Mutation['SendEmailFront']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'subject', description: null, type: 'String' },
        { name: 'text', description: null, type: 'String' },
        { name: 'attach', description: null, type: '[String]' },
        { name: 'categories', description: null, type: '[String]' }
      ],
      name: 'SendEmailFront',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MaterialSendTestEmail: {
    type: 'Boolean!',
    schemaKey: 'MaterialSendTestEmail',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationMaterialSendTestEmailArgs',
    returnTSName: "Mutation['MaterialSendTestEmail']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean!',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'MaterialSendTestEmail',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'mutation'
  },

  MaterialLikeToggle: {
    type: 'Boolean',
    schemaKey: 'MaterialLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationMaterialLikeToggleArgs',
    returnTSName: "Mutation['MaterialLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'MaterialLikeToggle',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UpdateDeteachedAndLatestVideo: {
    type: 'Boolean',
    schemaKey: 'UpdateDeteachedAndLatestVideo',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUpdateDeteachedAndLatestVideoArgs',
    returnTSName: "Mutation['UpdateDeteachedAndLatestVideo']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'idDetachedVideo', description: null, type: 'String' },
        { name: 'idLatestVideo1', description: null, type: 'String' },
        { name: 'idLatestVideo2', description: null, type: 'String' },
        { name: 'idLatestVideo3', description: null, type: 'String' }
      ],
      name: 'UpdateDeteachedAndLatestVideo',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  NotificationCreateOne: {
    type: 'CreateOneNotificationPayload',
    schemaKey: 'NotificationCreateOne',
    entityName: 'CreateOneNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationCreateOneArgs',
    returnTSName: "Mutation['NotificationCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneNotificationPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneNotificationInput!'
        }
      ],
      name: 'NotificationCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  NotificationCreateMany: {
    type: 'CreateManyNotificationPayload',
    schemaKey: 'NotificationCreateMany',
    entityName: 'CreateManyNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationCreateManyArgs',
    returnTSName: "Mutation['NotificationCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyNotificationPayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyNotificationInput!]!'
        }
      ],
      name: 'NotificationCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  NotificationUpdateOne: {
    type: 'UpdateOneNotificationPayload',
    schemaKey: 'NotificationUpdateOne',
    entityName: 'UpdateOneNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationUpdateOneArgs',
    returnTSName: "Mutation['NotificationUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneNotificationPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneNotificationInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneNotificationInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneNotificationInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'NotificationUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  NotificationRemoveById: {
    type: 'RemoveByIdNotificationPayload',
    schemaKey: 'NotificationRemoveById',
    entityName: 'RemoveByIdNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationRemoveByIdArgs',
    returnTSName: "Mutation['NotificationRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdNotificationPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'NotificationRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CategoryCreateOne: {
    type: 'CreateOneCategoryPayload',
    schemaKey: 'CategoryCreateOne',
    entityName: 'CreateOneCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryCreateOneArgs',
    returnTSName: "Mutation['CategoryCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneCategoryPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneCategoryInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CategoryCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CategoryUpdateOne: {
    type: 'UpdateOneCategoryPayload',
    schemaKey: 'CategoryUpdateOne',
    entityName: 'UpdateOneCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryUpdateOneArgs',
    returnTSName: "Mutation['CategoryUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneCategoryPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneCategoryInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneCategoryInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneCategoryInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'CategoryUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CategoryRemoveById: {
    type: 'RemoveByIdCategoryPayload',
    schemaKey: 'CategoryRemoveById',
    entityName: 'RemoveByIdCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryRemoveByIdArgs',
    returnTSName: "Mutation['CategoryRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdCategoryPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CategoryRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CategoryCreateMany: {
    type: 'CreateManyCategoryPayload',
    schemaKey: 'CategoryCreateMany',
    entityName: 'CreateManyCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryCreateManyArgs',
    returnTSName: "Mutation['CategoryCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyCategoryPayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyCategoryInput!]!'
        },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CategoryCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  WebPushSubscribe: {
    type: 'Boolean',
    schemaKey: 'WebPushSubscribe',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationWebPushSubscribeArgs',
    returnTSName: "Mutation['WebPushSubscribe']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Adiciona uma "PushSubscriptionJSON" ao cadastro do usuario.',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'subscription', description: null, type: 'JSON!' }],
      name: 'WebPushSubscribe',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  WebPushSendNotification: {
    type: '[String]',
    schemaKey: 'WebPushSendNotification',
    entityName: 'String',
    isList: true,
    argsTSName: 'MutationWebPushSendNotificationArgs',
    returnTSName: "Mutation['WebPushSendNotification']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Envia uma web push notification para uma lista de userIds',
      deprecationReason: null,
      type: '[String]',
      args: [
        { name: 'userIds', description: null, type: '[String]!' },
        { name: 'notificationOptions', description: null, type: 'JSON!' },
        { name: 'sync', description: null, type: 'Boolean' }
      ],
      name: 'WebPushSendNotification',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PinCreateOne: {
    type: 'CreateOnePinPayload',
    schemaKey: 'PinCreateOne',
    entityName: 'CreateOnePinPayload',
    isList: false,
    argsTSName: 'MutationPinCreateOneArgs',
    returnTSName: "Mutation['PinCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOnePinPayload',
      args: [{ name: 'record', description: null, type: 'CreateOnePinInput!' }],
      name: 'PinCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PinRemoveOne: {
    type: 'RemoveOnePinPayload',
    schemaKey: 'PinRemoveOne',
    entityName: 'RemoveOnePinPayload',
    isList: false,
    argsTSName: 'MutationPinRemoveOneArgs',
    returnTSName: "Mutation['PinRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOnePinPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveOnePinInput'
        },
        { name: 'sort', description: null, type: 'SortRemoveOnePinInput' }
      ],
      name: 'PinRemoveOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PinRemoveMany: {
    type: 'RemoveManyPinPayload',
    schemaKey: 'PinRemoveMany',
    entityName: 'RemoveManyPinPayload',
    isList: false,
    argsTSName: 'MutationPinRemoveManyArgs',
    returnTSName: "Mutation['PinRemoveMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ',
      deprecationReason: null,
      type: 'RemoveManyPinPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveManyPinInput!'
        }
      ],
      name: 'PinRemoveMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ShareCreateOne: {
    type: 'CreateOneSharePayload',
    schemaKey: 'ShareCreateOne',
    entityName: 'CreateOneSharePayload',
    isList: false,
    argsTSName: 'MutationShareCreateOneArgs',
    returnTSName: "Mutation['ShareCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneSharePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneShareInput!' }
      ],
      name: 'ShareCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ShareRemoveOne: {
    type: 'RemoveOneSharePayload',
    schemaKey: 'ShareRemoveOne',
    entityName: 'RemoveOneSharePayload',
    isList: false,
    argsTSName: 'MutationShareRemoveOneArgs',
    returnTSName: "Mutation['ShareRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneSharePayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveOneShareInput'
        },
        { name: 'sort', description: null, type: 'SortRemoveOneShareInput' }
      ],
      name: 'ShareRemoveOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ShareRemoveMe: {
    type: 'Boolean!',
    schemaKey: 'ShareRemoveMe',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationShareRemoveMeArgs',
    returnTSName: "Mutation['ShareRemoveMe']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove o usuário atual de um compartilhamento.',
      deprecationReason: null,
      type: 'Boolean!',
      args: [{ name: 'shareId', description: null, type: 'String' }],
      name: 'ShareRemoveMe',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'mutation'
  },

  CommentCreateOne: {
    type: 'CreateOneCommentPayload',
    schemaKey: 'CommentCreateOne',
    entityName: 'CreateOneCommentPayload',
    isList: false,
    argsTSName: 'MutationCommentCreateOneArgs',
    returnTSName: "Mutation['CommentCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneCommentPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneCommentInput!' }
      ],
      name: 'CommentCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommentUpdateOne: {
    type: 'UpdateOneCommentPayload',
    schemaKey: 'CommentUpdateOne',
    entityName: 'UpdateOneCommentPayload',
    isList: false,
    argsTSName: 'MutationCommentUpdateOneArgs',
    returnTSName: "Mutation['CommentUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneCommentPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneCommentInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneCommentInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneCommentInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'CommentUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommentRemoveById: {
    type: 'RemoveByIdCommentPayload',
    schemaKey: 'CommentRemoveById',
    entityName: 'RemoveByIdCommentPayload',
    isList: false,
    argsTSName: 'MutationCommentRemoveByIdArgs',
    returnTSName: "Mutation['CommentRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdCommentPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'CommentRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommentLikeToggle: {
    type: 'Boolean',
    schemaKey: 'CommentLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommentLikeToggleArgs',
    returnTSName: "Mutation['CommentLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'CommentLikeToggle',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventLiveCreateOne: {
    type: 'CreateOneEventLivePayload',
    schemaKey: 'EventLiveCreateOne',
    entityName: 'CreateOneEventLivePayload',
    isList: false,
    argsTSName: 'MutationEventLiveCreateOneArgs',
    returnTSName: "Mutation['EventLiveCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEventLivePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneEventLiveInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'EventLiveCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventLiveRemoveById: {
    type: 'RemoveByIdEventLivePayload',
    schemaKey: 'EventLiveRemoveById',
    entityName: 'RemoveByIdEventLivePayload',
    isList: false,
    argsTSName: 'MutationEventLiveRemoveByIdArgs',
    returnTSName: "Mutation['EventLiveRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdEventLivePayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'EventLiveRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventLiveUpdateOne: {
    type: 'UpdateOneEventLivePayload',
    schemaKey: 'EventLiveUpdateOne',
    entityName: 'UpdateOneEventLivePayload',
    isList: false,
    argsTSName: 'MutationEventLiveUpdateOneArgs',
    returnTSName: "Mutation['EventLiveUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneEventLivePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneEventLiveInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneEventLiveInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneEventLiveInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'EventLiveUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventLiveCloseRoom: {
    type: 'Boolean',
    schemaKey: 'EventLiveCloseRoom',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationEventLiveCloseRoomArgs',
    returnTSName: "Mutation['EventLiveCloseRoom']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'eventId', description: null, type: 'String!' }],
      name: 'EventLiveCloseRoom',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventLiveLikeToggle: {
    type: 'Boolean',
    schemaKey: 'EventLiveLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationEventLiveLikeToggleArgs',
    returnTSName: "Mutation['EventLiveLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'EventLiveLikeToggle',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BannerRemoveById: {
    type: 'RemoveByIdBannerPayload',
    schemaKey: 'BannerRemoveById',
    entityName: 'RemoveByIdBannerPayload',
    isList: false,
    argsTSName: 'MutationBannerRemoveByIdArgs',
    returnTSName: "Mutation['BannerRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdBannerPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'BannerRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BannerCreateOne: {
    type: 'CreateOneBannerPayload',
    schemaKey: 'BannerCreateOne',
    entityName: 'CreateOneBannerPayload',
    isList: false,
    argsTSName: 'MutationBannerCreateOneArgs',
    returnTSName: "Mutation['BannerCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneBannerPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneBannerInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'BannerCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BannerUpdateOne: {
    type: 'UpdateOneBannerPayload',
    schemaKey: 'BannerUpdateOne',
    entityName: 'UpdateOneBannerPayload',
    isList: false,
    argsTSName: 'MutationBannerUpdateOneArgs',
    returnTSName: "Mutation['BannerUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneBannerPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneBannerInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneBannerInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneBannerInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'BannerUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AnalyticsPageView: {
    type: 'Boolean',
    schemaKey: 'AnalyticsPageView',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationAnalyticsPageViewArgs',
    returnTSName: "Mutation['AnalyticsPageView']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'title', description: null, type: 'String!' },
        { name: 'host', description: null, type: 'String' },
        { name: 'route', description: null, type: 'String' }
      ],
      name: 'AnalyticsPageView',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AnalyticsTrackEvent: {
    type: 'Boolean',
    schemaKey: 'AnalyticsTrackEvent',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationAnalyticsTrackEventArgs',
    returnTSName: "Mutation['AnalyticsTrackEvent']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'event', description: null, type: 'EnumAnalyticsEvent!' },
        { name: 'kind', description: null, type: 'EnumAnalyticsParentKind!' },
        { name: 'recordId', description: null, type: 'String' },
        { name: 'entityName', description: null, type: 'String' },
        { name: 'label', description: null, type: 'String' }
      ],
      name: 'AnalyticsTrackEvent',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FolderCreateOne: {
    type: 'CreateOneFolderPayload',
    schemaKey: 'FolderCreateOne',
    entityName: 'CreateOneFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderCreateOneArgs',
    returnTSName: "Mutation['FolderCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneFolderPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneFolderInput!' }
      ],
      name: 'FolderCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FolderRemoveOne: {
    type: 'RemoveOneFolderPayload',
    schemaKey: 'FolderRemoveOne',
    entityName: 'RemoveOneFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderRemoveOneArgs',
    returnTSName: "Mutation['FolderRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneFolderPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveOneFolderInput'
        },
        { name: 'sort', description: null, type: 'SortRemoveOneFolderInput' }
      ],
      name: 'FolderRemoveOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FolderRemoveMany: {
    type: 'RemoveManyFolderPayload',
    schemaKey: 'FolderRemoveMany',
    entityName: 'RemoveManyFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderRemoveManyArgs',
    returnTSName: "Mutation['FolderRemoveMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ',
      deprecationReason: null,
      type: 'RemoveManyFolderPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveManyFolderInput!'
        }
      ],
      name: 'FolderRemoveMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FolderUpdateOne: {
    type: 'UpdateOneFolderPayload',
    schemaKey: 'FolderUpdateOne',
    entityName: 'UpdateOneFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderUpdateOneArgs',
    returnTSName: "Mutation['FolderUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneFolderPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneFolderInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneFolderInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneFolderInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'FolderUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RecentsActivitiesCreateOne: {
    type: 'CreateOneRecentsActivitiesPayload',
    schemaKey: 'RecentsActivitiesCreateOne',
    entityName: 'CreateOneRecentsActivitiesPayload',
    isList: false,
    argsTSName: 'MutationRecentsActivitiesCreateOneArgs',
    returnTSName: "Mutation['RecentsActivitiesCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneRecentsActivitiesPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneRecentsActivitiesInput!'
        }
      ],
      name: 'RecentsActivitiesCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserWordsBlackListCreateOne: {
    type: 'CreateOneUserWordsBlackListPayload',
    schemaKey: 'UserWordsBlackListCreateOne',
    entityName: 'CreateOneUserWordsBlackListPayload',
    isList: false,
    argsTSName: 'MutationUserWordsBlackListCreateOneArgs',
    returnTSName: "Mutation['UserWordsBlackListCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneUserWordsBlackListPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneUserWordsBlackListInput!'
        }
      ],
      name: 'UserWordsBlackListCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ImportUsersPlanCreateOne: {
    type: 'CreateOneImportUsersPlanPayload',
    schemaKey: 'ImportUsersPlanCreateOne',
    entityName: 'CreateOneImportUsersPlanPayload',
    isList: false,
    argsTSName: 'MutationImportUsersPlanCreateOneArgs',
    returnTSName: "Mutation['ImportUsersPlanCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneImportUsersPlanPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneImportUsersPlanInput!'
        },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'ImportUsersPlanCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ImportUsersPlanUpdateOne: {
    type: 'UpdateOneImportUsersPlanPayload',
    schemaKey: 'ImportUsersPlanUpdateOne',
    entityName: 'UpdateOneImportUsersPlanPayload',
    isList: false,
    argsTSName: 'MutationImportUsersPlanUpdateOneArgs',
    returnTSName: "Mutation['ImportUsersPlanUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneImportUsersPlanPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneImportUsersPlanInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneImportUsersPlanInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneImportUsersPlanInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'ImportUsersPlanUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserHistoryBlockCreateOne: {
    type: 'CreateOneUserHistoryBlockPayload',
    schemaKey: 'UserHistoryBlockCreateOne',
    entityName: 'CreateOneUserHistoryBlockPayload',
    isList: false,
    argsTSName: 'MutationUserHistoryBlockCreateOneArgs',
    returnTSName: "Mutation['UserHistoryBlockCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneUserHistoryBlockPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneUserHistoryBlockInput!'
        },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'UserHistoryBlockCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailStatsCreateMany: {
    type: 'CreateManyEmailStatsPayload',
    schemaKey: 'EmailStatsCreateMany',
    entityName: 'CreateManyEmailStatsPayload',
    isList: false,
    argsTSName: 'MutationEmailStatsCreateManyArgs',
    returnTSName: "Mutation['EmailStatsCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyEmailStatsPayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyEmailStatsInput!]!'
        }
      ],
      name: 'EmailStatsCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailStatsCreateOne: {
    type: 'CreateOneEmailStatsPayload',
    schemaKey: 'EmailStatsCreateOne',
    entityName: 'CreateOneEmailStatsPayload',
    isList: false,
    argsTSName: 'MutationEmailStatsCreateOneArgs',
    returnTSName: "Mutation['EmailStatsCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEmailStatsPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneEmailStatsInput!' }
      ],
      name: 'EmailStatsCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailUnsubscribeCreateMany: {
    type: 'CreateManyEmailUnsubscribePayload',
    schemaKey: 'EmailUnsubscribeCreateMany',
    entityName: 'CreateManyEmailUnsubscribePayload',
    isList: false,
    argsTSName: 'MutationEmailUnsubscribeCreateManyArgs',
    returnTSName: "Mutation['EmailUnsubscribeCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyEmailUnsubscribePayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyEmailUnsubscribeInput!]!'
        }
      ],
      name: 'EmailUnsubscribeCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailUnsubscribeCreateOne: {
    type: 'CreateOneEmailUnsubscribePayload',
    schemaKey: 'EmailUnsubscribeCreateOne',
    entityName: 'CreateOneEmailUnsubscribePayload',
    isList: false,
    argsTSName: 'MutationEmailUnsubscribeCreateOneArgs',
    returnTSName: "Mutation['EmailUnsubscribeCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEmailUnsubscribePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneEmailUnsubscribeInput!'
        }
      ],
      name: 'EmailUnsubscribeCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserCorpCheck: {
    type: 'UserCorpCheckPayload',
    schemaKey: 'UserCorpCheck',
    entityName: 'UserCorpCheckPayload',
    isList: false,
    argsTSName: 'MutationUserCorpCheckArgs',
    returnTSName: "Mutation['UserCorpCheck']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserCorpCheckPayload',
      args: [{ name: 'CPF', description: null, type: 'String!' }],
      name: 'UserCorpCheck',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MeuPdvParamsMutation: {
    type: 'MeuDiaPdv',
    schemaKey: 'MeuPdvParamsMutation',
    entityName: 'MeuDiaPdv',
    isList: false,
    argsTSName: 'MutationMeuPdvParamsMutationArgs',
    returnTSName: "Mutation['MeuPdvParamsMutation']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MeuDiaPdv',
      args: [
        { name: 'nome', description: null, type: 'String' },
        { name: 'endereco', description: null, type: 'String' },
        { name: 'complemento', description: null, type: 'String' },
        { name: 'bairro', description: null, type: 'String' },
        { name: 'uf', description: null, type: 'String' },
        { name: 'municipio', description: null, type: 'String' },
        { name: 'regional', description: null, type: 'String' },
        { name: 'canal', description: null, type: 'String' },
        { name: 'tipo', description: null, type: 'String' },
        { name: 'vaga', description: null, type: 'String' },
        { name: 'cep', description: null, type: 'String' },
        { name: 'loja', description: null, type: 'String' },
        { name: 'matricula', description: null, type: 'String' },
        { name: 'celular', description: null, type: 'String' },
        { name: 'diretoria', description: null, type: 'String' },
        { name: 'email', description: null, type: 'String' }
      ],
      name: 'MeuPdvParamsMutation',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RemunerationCreateOne: {
    type: 'CreateOneRemunerationPayload',
    schemaKey: 'RemunerationCreateOne',
    entityName: 'CreateOneRemunerationPayload',
    isList: false,
    argsTSName: 'MutationRemunerationCreateOneArgs',
    returnTSName: "Mutation['RemunerationCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneRemunerationPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneRemunerationInput!'
        },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'RemunerationCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RemunerationUpdateOne: {
    type: 'UpdateOneRemunerationPayload',
    schemaKey: 'RemunerationUpdateOne',
    entityName: 'UpdateOneRemunerationPayload',
    isList: false,
    argsTSName: 'MutationRemunerationUpdateOneArgs',
    returnTSName: "Mutation['RemunerationUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneRemunerationPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneRemunerationInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneRemunerationInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneRemunerationInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'RemunerationUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RemunerationRemoveById: {
    type: 'RemoveByIdRemunerationPayload',
    schemaKey: 'RemunerationRemoveById',
    entityName: 'RemoveByIdRemunerationPayload',
    isList: false,
    argsTSName: 'MutationRemunerationRemoveByIdArgs',
    returnTSName: "Mutation['RemunerationRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdRemunerationPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'RemunerationRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RemunerationLikeToggle: {
    type: 'Boolean',
    schemaKey: 'RemunerationLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationRemunerationLikeToggleArgs',
    returnTSName: "Mutation['RemunerationLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'RemunerationLikeToggle',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserTourMutation: {
    type: 'Boolean',
    schemaKey: 'UserTourMutation',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserTourMutationArgs',
    returnTSName: "Mutation['UserTourMutation']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'tour', description: null, type: 'String' }],
      name: 'UserTourMutation',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ReconheceWalletRemoveById: {
    type: 'RemoveByIdReconheceWalletPayload',
    schemaKey: 'ReconheceWalletRemoveById',
    entityName: 'RemoveByIdReconheceWalletPayload',
    isList: false,
    argsTSName: 'MutationReconheceWalletRemoveByIdArgs',
    returnTSName: "Mutation['ReconheceWalletRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdReconheceWalletPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'ReconheceWalletRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ReconheceWalletCreateOne: {
    type: 'CreateOneReconheceWalletPayload',
    schemaKey: 'ReconheceWalletCreateOne',
    entityName: 'CreateOneReconheceWalletPayload',
    isList: false,
    argsTSName: 'MutationReconheceWalletCreateOneArgs',
    returnTSName: "Mutation['ReconheceWalletCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneReconheceWalletPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneReconheceWalletInput!'
        }
      ],
      name: 'ReconheceWalletCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ReconheceWalletUpdateById: {
    type: 'UpdateByIdReconheceWalletPayload',
    schemaKey: 'ReconheceWalletUpdateById',
    entityName: 'UpdateByIdReconheceWalletPayload',
    isList: false,
    argsTSName: 'MutationReconheceWalletUpdateByIdArgs',
    returnTSName: "Mutation['ReconheceWalletUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdReconheceWalletPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateByIdReconheceWalletInput!'
        }
      ],
      name: 'ReconheceWalletUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  SetWarningAt: {
    type: 'WarningAtResult',
    schemaKey: 'SetWarningAt',
    entityName: 'WarningAtResult',
    isList: false,
    argsTSName: 'MutationSetWarningAtArgs',
    returnTSName: "Mutation['SetWarningAt']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'WarningAtResult',
      args: [
        { name: 'idUserCreditOperation', description: null, type: 'String!' }
      ],
      name: 'SetWarningAt',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ReconheceCreateTempToken: {
    type: 'String',
    schemaKey: 'ReconheceCreateTempToken',
    entityName: 'String',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['ReconheceCreateTempToken']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Cria um "one time roken" que será enviado via url ao Reconhece',
      deprecationReason: null,
      type: 'String',
      args: [],
      name: 'ReconheceCreateTempToken',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  }
};

export const useClient = useClientFactory<any, Methods>();
