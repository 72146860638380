import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { Form } from '../FormManage';

export const ContestacaoEditar = () => {
  const configPage = {
    pageName: 'Contestação',
    icon: 'audit',
    description:
      'Solucionar as contestações solicitadas pelos usuários nas páginas internas das campanhas',
    routeNew: '',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Incentivo', link: routes.campanhasIncentivoListar.mount() },
      { title: 'Contestação', link: routes.contestacaoListar.mount() },
      { title: 'Visualizar', link: '' }
    ]
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </>
    </PageTemplate>
  );
};
