import React from 'react';
import { makeField, useXlsxJsonActions } from '@digi-tim-19/components';
import styled from 'styled-components';
import { EnumFileKind } from '../../../autogenerated/client/types';

const Wrapper = styled.div`
  width: 100%;

  .FieldWrapper {
    margin-bottom: 0 !important;
  }
`;
type TUpload = {
  CTA: string;
  initialValue?: string;
};

export const Upload: React.FC<TUpload> = ({ CTA, initialValue }) => {
  const xlsxJsonActions = useXlsxJsonActions();
  const name = 'upload';

  return (
    <Wrapper>
      {makeField({
        field: {
          name,
          type: 'upload',
          label: '',
          initialValue,
          extraProps: {
            kind: EnumFileKind.Xlsx,
            CTA,
            multiple: false,
            onSelectFile: (info: any) => {
              const file = info.file.originFileObj;
              if (file) {
                xlsxJsonActions.setState('file', file);
              }
            }
          }
        },
        key: name
      })}
    </Wrapper>
  );
};

export default Upload;
