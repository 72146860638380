import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DatePicker, message } from 'antd';
import moment from 'moment';
import { Breadcrumb, TitleDescription, Select } from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';

import { useNotificationsCategory } from 'hooks/categories/useNotificationsCategory';
import { routes } from 'config/routes';
import { statusFeminino, order, dateFormatList } from 'utils';
import { columns } from 'utils/notificacao';

import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput,
  EnumMaterialStatus,
  CommunicationKindFilter
} from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';
import { FilterContainer, CleanButton } from '../../../components/PageFilter';
import { Layout } from '../_layout';

import { NotificationsWrapper, Button } from './stylesGerenciar';
import Search from './Search';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { PageTemplate } from 'components/Layout/PageTemplate';

const { RangePicker } = DatePicker;

const appendToFragment = `
items {
  title
  _id
  numericId
  publishedAt
  validityEnum
  status {
    _id
    label
  }
  author {
    name
  }
  categories {
    _id
    name
    parentId
    parentCategory {
      name
      _id
    }
  }
}
`;

type RangePickerType = {
  start?: string;
  end?: string;
};

const configPage = {
  pageName: 'Notificação',
  icon: 'bell',
  description: 'Cadastro e consulta de notificações.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Comunicação', link: routes.home.mount() },
    { title: 'Notificação', link: routes.notificacoesGerenciar.mount() }
  ]
};

export const ListarNotificacoes: React.FC<TListarNotificacoes> = () => {
  const history = useHistory();
  const categoryOptions = useNotificationsCategory();

  const MaterialPagination = useClient('MaterialPagination');
  const MaterialUpdateOne = useClient('MaterialUpdateOne');

  const [page, setPage] = useState(1);
  const [createdAt, setCreatedAt] = useState<RangePickerType>();
  const [publishedAt, setPublishedAt] = useState<RangePickerType>();
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  const [filterValidityEnum, setFilterValidityEnum] = useState(
    ValidityEnum.Any
  );
  const [filterSearch, setFilterSearch] = useState<string>();
  const [filterCategory, setFilterCategory] = useState();
  const [filterStatus, setFilterStatus] = useState<EnumMaterialStatus>();

  useEffect(() => {
    MaterialPagination.fetch({
      appendToFragment,
      fetchOnMount: true,
      variables: {
        page,
        perPage: 10,
        filter: {
          kind: EnumMaterialKind.Communication,
          validityEnum: filterValidityEnum,
          inCategories: filterCategory,
          status: filterStatus,
          search: filterSearch,
          createdAtRange: createdAt,
          publishedAtRange: publishedAt,
          includeDrafts: true,
          communicationKind: CommunicationKindFilter.Notification
        },
        sort: sort
      }
    });
  }, [
    sort,
    filterCategory,
    filterStatus,
    filterSearch,
    createdAt,
    publishedAt,
    filterValidityEnum,
    page
  ]);

  const resetFilters = () => {
    setFilterValidityEnum(ValidityEnum.Any);
    setSort(SortFindManyMaterialInput.IdDesc);
    setFilterCategory(undefined);
    setFilterStatus(undefined);
    setFilterSearch(undefined);
  };

  const total = MaterialPagination.result?.count;
  const data = MaterialPagination.result?.items?.map((material: any) => {
    const category = material?.categories?.[0];
    const categoryName = category?.name || '';

    return {
      key: material?._id,
      id: material?.numericId,
      title: material?.title,
      cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
      user: material?.author?.name,
      categoryName: categoryName,
      publicada: material.publishedAt
        ? formatDate(material?.publishedAt, 'DD/MM/YYYY')
        : '-',
      status: material?.status?.label,
      onEdit: () =>
        history.push(routes.notificacoesEditar.mount(material?._id)),
      onDelete: () => {
        const record = { status: EnumMaterialStatus.Cancelled };

        MaterialUpdateOne.fetch({
          variables: { record, filter: { _id: material?._id } },
          afterMutate: /^Material/
        }).then(() => {
          message.success('Notificação cancelada com sucesso');
        });
      },
      onRead: () => history.push(routes.notificacoes.mount())
    };
  });

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <NotificationsWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />
      </NotificationsWrapper>
      <FilterContainer>
        <Button to={routes.notificacoesCadastrar.path}>
          Cadastrar notificação
        </Button>
        <Select
          placeholder="Ordenar por:"
          options={order}
          onChange={(e) => setSort(e)}
          value={sort}
        />
        <Select
          placeholder="status"
          options={statusFeminino}
          value={
            filterValidityEnum !== ValidityEnum.Any
              ? filterValidityEnum
              : filterStatus
          }
          onChange={(e) => {
            switch (e) {
              case ValidityEnum.Current:
                setFilterValidityEnum(e);
                setFilterStatus(EnumMaterialStatus.Approved);
                break;
              case ValidityEnum.Expired:
                setFilterValidityEnum(e);
                setFilterStatus(undefined);
                break;
              default:
                setFilterValidityEnum(ValidityEnum.Any);
                setFilterStatus(e);
            }
          }}
        />
        <Select
          placeholder="categoria do menu"
          options={categoryOptions}
          value={filterCategory}
          onChange={(e) => setFilterCategory(e)}
        />
        <RangePicker
          getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
          format={dateFormatList}
          placeholder={['DATA DE', 'CRIAÇÃO']}
          onChange={(e) =>
            setCreatedAt({
              start: moment(e[0]!)
                .startOf('day')
                ?.toISOString(),
              end: moment(e[1]!)
                .endOf('day')
                ?.toISOString()
            })
          }
        />
        <RangePicker
          placeholder={['DATA DE', 'DIVULGAÇÃO']}
          format={dateFormatList}
          onChange={(e) =>
            setPublishedAt({
              start: moment(e[0]!)
                .startOf('day')
                ?.toISOString(),
              end: moment(e[1]!)
                .endOf('day')
                ?.toISOString()
            })
          }
        />
        <Search setValue={setFilterSearch} />
        <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
      </FilterContainer>
      <TablePagination
        loadingTable={MaterialPagination.loading}
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 10,
          current: page,
          total: total!,
          onChange: (e: any) => setPage(e)
        }}
        emptyMessage="Não há notificações para exibir"
      />
    </PageTemplate>
  );
};

export type TListarNotificacoes = {};
