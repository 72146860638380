import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  IncentiveCampaignSample,
  RankingTable,
  useCurrentUser,
  useAnalytics
} from '@digi-tim-19/components';
import { ActionButtonsS2 } from '@digi-tim-19/components/build/main/CardIncentiveCampaign/ActionButton/IncentiveCampaignActionButton';
import { DataProviders } from '@digi-tim-19/components/build/main/CardIncentiveCampaign/DataProviders/DataProviders';

import {
  EnumAnalyticsParentKind,
  IncentiveCampaign
} from 'autogenerated/client/types';
import { PageTemplate } from 'components/Layout/PageTemplate';
import DownloadButton from 'components/DownloadButton';
import { Image } from 'components/Image/Image';
import { useIncentiveCampaign } from 'hooks/incentiveCampaign/useIncentiveCampaign';
import { routes } from 'config/routes';

import { columns } from './rankingColumns';
import { FIncentiveCampaign } from './fragments';
import { CampaignRegulation } from './Regulation/Regulation';
import { CampaignContestation } from './UserContestation/Contestation';
import useContestPeriod from './UserContestation/useContestPeriod';
import {
  SectionTitle,
  CampaignFAQ,
  ButtonContainer,
  SocialButtons,
  CampaignBanner,
  ContestButton
} from './styles';

const configPage = {
  pageName: 'Campanha',
  icon: 'trophy',
  description: 'Campanha de incentivo de resultados.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Para você', link: routes.home.mount() },
    {
      title: 'Campanhas de Incentivo',
      link: routes.campanhasIncentivoVigentes.mount()
    },
    { title: 'Campanha', link: '' }
  ]
};

export const CampanhaIncentivo = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const { result: userResult } = useCurrentUser();
  const analytics = useAnalytics();

  const userEmail = userResult?.email;
  const acceptedTerms = userResult?.acceptedTerms;
  const roleGroup = userResult?.roleGroup?._id || '';

  // Regulation
  const isAcceptedTerm = !acceptedTerms?.some(
    (item) => item?.term === `IncentiveCampaignRegulation-${params?.id}`
  );

  // Queries
  const incentiveCampaign = useIncentiveCampaign(
    params?.id,
    FIncentiveCampaign
  );

  // Modal state
  const [showModalRegulation, setShowModalRegulation] = useState(
    isAcceptedTerm
  );
  const [showModalContestation, setShowModalContestation] = useState<boolean>(
    false
  );

  // Result table
  const tableCampaignResult = incentiveCampaign?.result?.fileContent;
  const findUserInRows = incentiveCampaign?.result?.fileContent?.find(
    (el) => el?.email === userEmail
  );
  const rows = tableCampaignResult?.map((r) => ({
    ...r,
    partner: r?.partner || undefined,
    active: r === findUserInRows ? true : false
  }));
  const hasPartner = !!rows?.find((r) => r.partner);

  // Campaign data
  const campaign: IncentiveCampaign =
    incentiveCampaign.result || ({} as IncentiveCampaign);
  const campaignPresentationLink = campaign?.presentationFile?.signedUrl;
  const campaignVideoLink = campaign?.videoLink;

  // Contestation
  const { timeLeft, isContestAllowed } = useContestPeriod(campaign);

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {incentiveCampaign.loading && <LoadingIndicator />}
        {!incentiveCampaign.loading && (
          <>
            <CampaignRegulation
              campaign={campaign}
              roleGroup={roleGroup}
              visible={showModalRegulation}
              onClose={() => setShowModalRegulation(false)}
            />

            <SectionTitle>{campaign.title}</SectionTitle>

            {!!campaign?.bannerContentFile && (
              <CampaignBanner>
                <Image
                  href={campaignVideoLink || undefined}
                  src={campaign.bannerContentFile?.signedUrl || ''}
                  alt={campaign?.title || ''}
                />
              </CampaignBanner>
            )}

            <ButtonContainer>
              <DownloadButton
                href={campaign?.regulationFile?.signedUrl || '#'}
                download
                target="_blank"
                onClick={() => {
                  analytics.track({
                    event: 'download_regulation',
                    kind: EnumAnalyticsParentKind.IncentiveCampaign,
                    recordId: campaign?._id,
                    entityName: 'IncentiveCampaign',
                    singleDoc: true
                  });
                }}
              >
                BAIXAR REGULAMENTO
              </DownloadButton>

              {!!campaignPresentationLink && (
                <DownloadButton
                  href={campaignPresentationLink || '#'}
                  download
                  target="_blank"
                  onClick={() => {
                    analytics.track({
                      event: 'download_presentation',
                      kind: EnumAnalyticsParentKind.IncentiveCampaign,
                      recordId: campaign?._id,
                      entityName: 'IncentiveCampaign',
                      singleDoc: true
                    });
                  }}
                >
                  BAIXAR APRESENTAÇÃO
                </DownloadButton>
              )}
            </ButtonContainer>

            {!!campaign._id && campaign.enableSocialInteraction && (
              <SocialButtons>
                <DataProviders
                  incentiveCampaign={campaign as IncentiveCampaignSample}
                >
                  <ActionButtonsS2 view like share pin comment />
                </DataProviders>
              </SocialButtons>
            )}

            {incentiveCampaign.result?.faq !== '<p></p>\n' && (
              <>
                <SectionTitle>FAQ</SectionTitle>
                <CampaignFAQ
                  dangerouslySetInnerHTML={{ __html: campaign.faq || '' }}
                />
              </>
            )}

            {!!incentiveCampaign?.result?.fileContent?.length && (
              <>
                {!campaign?.results && (
                  <>
                    <SectionTitle>Prévia</SectionTitle>
                    <RankingTable
                      columns={columns({ hasPartner })}
                      dataSource={rows}
                      pagination={{
                        total: [].length,
                        pageSize: [].length,
                        hideOnSinglePage: true
                      }}
                    />
                  </>
                )}
                {isContestAllowed && (
                  <>
                    <SectionTitle>Resultado</SectionTitle>
                    {timeLeft && (
                      <ContestButton
                        onClick={() => setShowModalContestation(true)}
                      >
                        {timeLeft} para contestar
                      </ContestButton>
                    )}
                    <RankingTable
                      columns={columns({ hasPartner })}
                      dataSource={rows}
                      pagination={{
                        total: [].length,
                        pageSize: [].length,
                        hideOnSinglePage: true
                      }}
                    />
                  </>
                )}
              </>
            )}

            <CampaignContestation
              campaign={campaign}
              userResult={userResult}
              visible={showModalContestation}
              onCancel={() => setShowModalContestation(false)}
            />
          </>
        )}
      </>
    </PageTemplate>
  );
};
