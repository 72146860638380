import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';
import { Former } from '@digi-tim-19/components';

import { routes } from 'config/routes';
import {
  EnumFileKind,
  IncentiveCampaign,
  Maybe
} from 'autogenerated/client/types';
import { useClient } from 'autogenerated/client/client';
import { FormContainer } from 'components/Former';
import { UploadUsersTable } from 'components/Campaign/UploadUsersTable';

import { uploadColumns, reversalColumns } from './columns';
import { Context } from 'graphql-clientgen';

type TFormUploadExtraProps = {
  campaign: Maybe<IncentiveCampaign>;
};

type KindTypes = 'reversal' | 'creditExtra' | undefined;

export const FormUploadExtra = ({ campaign }: TFormUploadExtraProps) => {
  const history = useHistory();
  const [paymentFile, setPaymentFile] = useState<string | undefined>(undefined);
  const [kind, setKind] = useState<KindTypes>(undefined);

  const isReversal = kind === 'reversal';
  const canShowTable = !!paymentFile && !!campaign && !!kind;

  const ReconhecePayExtraPoints = useClient('ReconhecePayExtraPoints');
  const ReconheceReversePoints = useClient('ReconheceReversePoints');

  const columns = {
    reversal: reversalColumns,
    creditExtra: uploadColumns
  };

  return (
    <FormContainer>
      <Former
        config={{
          fields: [
            {
              custom: (
                <p style={{ width: '100%', textAlign: 'left', margin: 0 }}>
                  Campanha: {campaign?.title || '-'}
                </p>
              )
            },
            {
              name: 'kind',
              label: 'Selecione a operação',
              type: 'select',
              options: [
                { value: 'reversal', label: 'Estorno' },
                { value: 'creditExtra', label: 'Upload extra' }
              ],
              afterChange: setKind,
              required: true
            },
            {
              name: 'paymentFileId',
              label: '',
              type: 'upload',
              required: true,
              disabled: !kind,
              afterChange: setPaymentFile,
              extraProps: {
                kind: EnumFileKind.Xlsx,
                CTA: isReversal
                  ? 'UPLOAD DA PLANILHA DE ESTORNO'
                  : 'UPLOAD DA PLANILHA'
              }
            },
            canShowTable && {
              custom: (
                <UploadUsersTable
                  kind={kind!}
                  campaignId={campaign?._id}
                  paymentFileId={paymentFile!}
                  columns={columns[kind!]}
                />
              )
            },
            isReversal && {
              name: 'reversalReason',
              label: 'Motivo do estorno',
              type: 'textarea',
              required: true,
              extraProps: {
                rows: 4,
                maxLength: 140
              }
            }
          ],

          submitButton: { label: 'Salvar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () =>
              history.push(
                routes.campanhasIncentivoEstornoUploadExtraListar.mount()
              )
          }
        }}
        onChange={(field, value, formApi) => {
          if (field === 'kind' && !!value) {
            setPaymentFile(undefined);
            formApi.change('paymentFileId', undefined);
          }
        }}
        onSubmit={async function ({ data }) {
          if (!campaign) {
            return message.error('Campanha não encontrada');
          }

          if (data.kind === undefined) {
            return message.error('Selecione o tipo de operação');
          }

          const variables = {
            paymentFileId: data?.paymentFileId,
            campaignId: campaign._id
          };

          const handleSuccess = (ctx: Context) => {
            if (ctx.result) {
              const kindMessage = isReversal ? 'Estorno' : 'Upload extra';

              message.success(`${kindMessage} realizado com sucesso`);
              return history.push(
                routes.campanhasIncentivoEstornoUploadExtraListar.mount()
              );
            }
          };

          const handleError = (error: any) => {
            const kindMessage = isReversal
              ? 'estornar TIM Coins'
              : 'upload extra';

            console.error(error);
            message.error(`Ocorreu um erro em ${kindMessage}`);
          };

          if (data.kind === 'creditExtra') {
            ReconhecePayExtraPoints.fetch({ variables })
              .then(handleSuccess)
              .catch(handleError);
          }

          if (data.kind === 'reversal') {
            ReconheceReversePoints.fetch({
              variables: {
                ...variables,
                reason: data?.reversalReason
              }
            })
              .then(handleSuccess)
              .catch(handleError);
          }

          setKind(undefined);
          setPaymentFile(undefined);
        }}
      />
    </FormContainer>
  );
};
