"use strict";
/*
 eslint-disable react-hooks/rules-of-hooks
 */

var _slicedToArray = require("/home/jenkins/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var isBrowser_1 = require("../isBrowser");

exports.useTick = function () {
  var tick = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1000;
  var initialValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var sum = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var min = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var max = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : Infinity;
  if (!isBrowser_1.isBrowser()) return initialValue;
  var valueRef = React.useRef(initialValue);
  var timeout = React.useRef(null);

  var _React$useState = React.useState(function () {
    var run = function run() {
      timeout.current = setTimeout(function () {
        if (valueRef.current > min && valueRef.current < max) {
          setTime(sum ? ++valueRef.current : --valueRef.current);
          run();
        } else {
          clearTimeout(timeout.current);
        }
      }, tick);
    };

    run();
    return valueRef.current;
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      stateTime = _React$useState2[0],
      setTime = _React$useState2[1];

  React.useEffect(function () {
    return function () {
      return clearTimeout(timeout.current);
    };
  }, []);
  return stateTime;
};

exports.useTickCancelable = function () {
  var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!isBrowser_1.isBrowser()) return args.initialValue;
  var _args$tick = args.tick,
      tick = _args$tick === void 0 ? 1000 : _args$tick,
      _args$initialValue = args.initialValue,
      initialValue = _args$initialValue === void 0 ? 1 : _args$initialValue,
      _args$sum = args.sum,
      sum = _args$sum === void 0 ? true : _args$sum,
      _args$min = args.min,
      min = _args$min === void 0 ? 0 : _args$min,
      _args$max = args.max,
      max = _args$max === void 0 ? Infinity : _args$max;
  var valueRef = React.useRef(initialValue);
  var timeout = React.useRef(null);

  var _React$useState3 = React.useState(function () {
    var run = function run() {
      timeout.current = setTimeout(function () {
        if (valueRef.current > min && valueRef.current < max) {
          var newValue = sum ? ++valueRef.current : --valueRef.current;
          requestAnimationFrame(function () {
            return setTime(newValue);
          });
          run();
        } else {
          clearTimeout(timeout.current);
        }
      }, tick);
    };

    run();
    return valueRef.current;
  }),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      stateTime = _React$useState4[0],
      setTime = _React$useState4[1];

  React.useEffect(function () {
    return function () {
      return clearTimeout(timeout.current);
    };
  }, []);
  return [stateTime, function () {
    return clearTimeout(timeout.current);
  }];
};