import React from 'react';
import { useParams } from 'react-router';
import { message } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  History,
  ProviderSelecionarPublico,
  useCurrentUser
} from '@digi-tim-19/components';

import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { InformativosWrapper } from '../Cadastrar/stylesCadastrar';
import { FormInformativos, fragmentBulletinMaterials } from '../FormInformativos/FormInformativos';

export const InformativosEditar = () => {
  const { id }: any = useParams();
  const { result: currentUser } = useCurrentUser();

  const informativeFind = useMaterial(id!, appendToFragment);
  const informativeApprove = useClient('CommunicationApproveChannel');
  const informativeDisapprove = useClient('CommunicationDisapproveChannel');
  const informativeRemoveApprover = useClient('CommunicationRemoveApprover');

  const informativeResult = informativeFind.result;

  const isUserAdmin = () => {
    const admRoleGroup = [
      'corporate:adm_trade_corporate',
      'residencial:adm_trade_residencial'
    ];
    const currentUserRoleGroup = currentUser?.roleGroup?._id || '';

    return admRoleGroup.includes(currentUserRoleGroup);
  };

  const canUpdateStatus = () => {
    const { approvedBy, awaitingApprovers } = informativeResult!;

    const hasApproved = approvedBy?.includes(currentUser?._id);
    const userApprover = awaitingApprovers?.includes(currentUser?._id);
    const approverRole = currentUser?.roles.includes('update_status_material');

    if (!hasApproved && userApprover && approverRole) {
      return true;
    }

    return false;
  };

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo', link: routes.home.mount() },
            {
              title: 'Informativos',
              link: routes.informativosListar.mount()
            },
            { title: 'Editar', link: '#' }
          ]}
        />
      }
    >
      <>
        <InformativosWrapper>
          <TitleDescription
            iconType="info-circle"
            title="Informativos"
            description="Cadastramento e consulta de informativos"
          />
        </InformativosWrapper>

        {informativeResult && (
          <>
            <ProviderSelecionarPublico>
              <FormInformativos initialValues={informativeResult} />
            </ProviderSelecionarPublico>
            <History
              item={{
                status: informativeResult.status || '',
                history: informativeResult.history?.items || [],
                awaitingApprovers:
                  informativeResult.awaitingUsersApprover || [],
                currentUserId: currentUser?._id || ''
              }}
              isUserAdmin={isUserAdmin()}
              canUpdateStatus={canUpdateStatus()}
              onApproved={() => {
                const variables = { recordId: informativeResult?._id };

                informativeApprove
                  .fetch({
                    variables,
                    afterMutate: /^Material/
                  })
                  .then(() => {
                    message.success('Aprovado com sucesso');
                  });
              }}
              onDisapproved={(disapprovalMessage) => {
                const variables = {
                  recordId: informativeResult?._id,
                  message: disapprovalMessage || ''
                };

                informativeDisapprove
                  .fetch({
                    variables,
                    afterMutate: /^Material/
                  })
                  .then(() => {
                    message.success('Informativo reprovado');
                  });
              }}
              onRemoveUser={(userId) => {
                if (userId) {
                  const variables = {
                    communicationId: informativeResult?._id,
                    userId
                  };

                  informativeRemoveApprover
                    .fetch({
                      variables,
                      afterMutate: /^Material/
                    })
                    .then((ctx) => {
                      if (ctx.result)
                        message.success('Usuário removido do fluxo');
                    });
                }
              }}
            />
          </>
        )}
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  title
  fileIds
  status { value }
  author { name }
  files {
    _id
    title
    kind
    downloadUrl
    signedUrl
  }
  validity {
    start,
    end
  }
  categories {
    name
    parentId
    _id
  }
  additionalCategories
  communicationItemsConfig {
    kind
  }
  description
  availableAtRegions
  availableAtChannels
  availableAtRoleGroups
  approvedBy
  frequency
  descriptionBulletin
  publishNow
  email {
    templateName
    templateProps
    attachments{
      _id
      downloadUrl
      title
    }

    images {
      IMAGE_SRC_1
      IMAGE_SRC_2
    }
  }

  awaitingApprovers
  awaitingUsersApprover {
    _id
    name
  }

  history {
    items {
      author { name }
      date
      updates
    }
  }

  bulletinMaterials {
    ${fragmentBulletinMaterials}
  }
`;
