import React from 'react';
import styled from 'styled-components';

interface Props {
  message?: string;
}

const Container = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.p`
  margin: 0px !important;
`;

export const EmptyContainer = (props: Props) => {
  return (
    <Container>
      <Message>
        {`${props?.message ? props.message : 'Não há registros para exibir'}`}
      </Message>
    </Container>
  );
};
