import { breadcrumbFragment } from 'utils/breadcrumbFragment';

export const SearchItemFragment = `
  pageInfo {
    pageCount
    itemCount
  }
  items {
    _id
    createdAt
    updatedAt
    publishedAt
    title
    description
    kindName
    kind
    html
    tags
    files {
      _id
      title
      signedUrl
      downloadUrl
      extension
      kind
    }
    editor
    communicationItemsConfig {
      kind
      _id
    }
    categories {
      ${breadcrumbFragment}
    }
    termsForSearch
  }
`;
