import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Button as AntButton, Popover, Input, Radio, message } from 'antd';

import { TFilesLink } from './DocsEditor';
import { ImageExtensions } from './ImageControl';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 250px;
`;

const Section = styled.div`
  align-self: flex-start;
  margin-bottom: 1em;

  label {
    font-size: 14px;
  }
`;

const Button = styled(AntButton)`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FilesWrapper = styled.div`
  max-height: 120px;
  overflow-y: auto;
`;

type TLinkControlProps = {
  config: any;
  files: TFilesLink;
};

export const LinkControl = ({ config, files }: TLinkControlProps) => {
  const { currentState, onChange } = config;

  const [visible, setVisible] = useState(false);
  const [link, setLink] = useState<any>(undefined);
  const [linkTarget, setLinkTarget] = useState<string | undefined>(undefined);
  const [linkTitle, setLinkTitle] = useState<string | undefined>(undefined);
  const [mode, setMode] = useState<'doc' | 'link' | undefined>(undefined);

  const docFiles = files?.filter(
    (file) => !ImageExtensions.includes(file.extension)
  );

  useMemo(() => {
    if (currentState.link) {
      setLinkTitle(currentState.link.title);
      setMode(undefined);
      setLink(currentState.link);
      return;
    }

    if (currentState.selectionText) {
      setLinkTitle(currentState.selectionText);
    }

    return setLink(undefined);
  }, [config]);

  return (
    <Popover
      title="Adicione um link"
      placement="bottom"
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      content={
        <Wrapper>
          <p style={{ fontSize: 11 }}>Carregue os documentos em "Upload"</p>

          <Section>
            <label htmlFor="linkTitle">Título do link:</label>
            <Input
              id="linkTitle"
              value={linkTitle}
              disabled={!!link}
              onChange={(e) => setLinkTitle(e.target.value)}
            />
          </Section>

          <Radio.Group
            onChange={(e) => setMode(e.target.value as any)}
            value={mode}
            defaultValue={'doc'}
            disabled={!!link}
            style={{ marginBottom: 8 }}
          >
            <Radio.Button value="doc">Documento</Radio.Button>
            <Radio.Button value="link">Link</Radio.Button>
          </Radio.Group>

          <Section>
            {(!!link || mode === 'link') && (
              <>
                <label htmlFor="linkTarget">Link:</label>
                <Input
                  id="linkTarget"
                  value={link?.target || linkTarget}
                  disabled={!!link}
                  onChange={(e) => setLinkTarget(e.target.value)}
                />
              </>
            )}

            {!link && mode === 'doc' && (
              <div>
                <span style={{ fontSize: 14 }}>Selecione um documento:</span>
                <FilesWrapper>
                  {docFiles?.map((file, index) => (
                    <Button
                      key={`${file.title}-${index}`}
                      type="link"
                      onClick={() => {
                        if (!linkTitle) {
                          return message.error('Adicione o título do link');
                        }
                        onChange('link', linkTitle, file.downloadUrl, '_blank');
                        setLinkTitle(undefined);
                        setVisible(false);
                      }}
                      children={file.title}
                    />
                  ))}
                </FilesWrapper>
              </div>
            )}
          </Section>

          {mode === 'link' && (
            <Button
              type="link"
              onClick={() => {
                if (!linkTitle) {
                  return message.error('Adicione o título do link');
                }
                onChange('link', linkTitle, linkTarget, '_blank');
                setLinkTitle(undefined);
                setVisible(false);
              }}
              children={'Adicionar link'}
            />
          )}
          {!!link && (
            <Button
              type="link"
              style={{ color: 'red' }}
              onClick={() => {
                onChange('unlink');
                setLinkTitle(undefined);
              }}
              children={'Remover'}
            />
          )}
        </Wrapper>
      }
    >
      <Button icon="link" />
    </Popover>
  );
};
