import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { PageWrapper } from '../../components/Page/PageWrapper';
import { BlueTimHeader } from '../../components/Page/BlueTimHeader/BlueTimHeader';

const StyledPageWrapper = styled(PageWrapper)`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${require('../../assets/images')});

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  background: white;
  pointer-events: none;
`;

const Main = styled.main.attrs({ className: 'ui-flex Login_Main' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  text-align: center;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  pointer-events: all;
`;

const StepsImage = styled.img`
  height: auto;
  width: auto;

  @media (min-width: 768px) {
    height: 70vh;
  }
`;

type TPortalName = 'corporate' | 'residencial' | undefined;

export const Login = () => {
  const [portalName, setPortalName] = useState<TPortalName>(undefined);
  const isCorporate = portalName === 'corporate';
  const isResidencial = portalName === 'residencial';

  return (
    <StyledPageWrapper>
      <BlueTimHeader />

      <Main>
        <p>
          Estava logado no portal e perdeu a sessão? Atualize a página no
          navegador ou apertando F5.
        </p>
        <p>
          Ainda não tem acesso ao Pra Conectar? Clique abaixo e veja o que
          fazer:
        </p>

        <ButtonsWrapper>
          <Button
            type={isCorporate ? 'primary' : 'default'}
            style={{ borderRadius: '15px 0 0 15px' }}
            onClick={() => setPortalName('corporate')}
          >
            Corporate
          </Button>
          <Button
            type={isResidencial ? 'primary' : 'default'}
            style={{ borderRadius: '0 15px 15px 0' }}
            onClick={() => setPortalName('residencial')}
          >
            Residencial TIM ULTRAFIBRA
          </Button>
        </ButtonsWrapper>

        {isCorporate && (
          <StepsImage
            src="/steps_corporate.jpg"
            alt="Imagem ilustrando o passo a passo para acessar o portal"
          />
        )}

        {isResidencial && (
          <StepsImage
            src="/steps_ultra.png"
            alt="Imagem ilustrando o passo a passo para acessar o portal"
          />
        )}
      </Main>
    </StyledPageWrapper>
  );
};
