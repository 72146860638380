import React from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';

import { PageTemplate } from 'components/Layout/PageTemplate';
import { useMaterial } from 'hooks/materials/useMaterial';
import { routes } from 'config/routes';
import { EnumMaterialKind } from 'autogenerated/client/types';

import { Form } from '../Form';
import { FNoticiaEditar } from '../fragments';
import { PageWrapper } from '../styles';

const configPage = {
  pageName: 'Notícias',
  icon: 'schedule',
  kind: EnumMaterialKind.News,
  description: 'Edição de Notícias',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Notícias', link: routes.noticiasListar.mount() },
    { title: 'Listar', link: routes.noticiasListar.mount() },
    { title: 'Editar', link: '' }
  ],
  routeList: routes.noticiasListar.mount()
};

export const NoticiasEditar = () => {
  const { params }: any = useRouteMatch();

  const material = useMaterial(params?.id, FNoticiaEditar);

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <PageWrapper>
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>

        {material.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={material.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
