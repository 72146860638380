import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { message, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select
} from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils/build';

import { routes } from 'config/routes';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { CleanButton } from 'components/PageFilter';
import { useClient } from 'autogenerated/client/client';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput,
  EnumMaterialStatus,
  DateRange
} from 'autogenerated/client/types';

import { FNoticiaListar } from '../fragments';
import { PageWrapper, FilterWrapper, NewButton } from '../styles';

import { columns } from './columns';

const { RangePicker } = DatePicker;

const configPage = {
  pageName: 'Notícias',
  icon: 'read',
  description: 'Cadastramento e consulta de notícias',
  messageDeletedSuccess: 'Notícia cancelada com sucesso',
  kind: EnumMaterialKind.News,
  textButtonNew: 'Cadastrar notícia',
  routeNew: routes.noticiasCadastrar.mount(),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Notícias', link: routes.noticiasListar.mount() },
    { title: 'Listar', link: routes.noticiasListar.mount() }
  ],
  perPage: 10
};

export const NoticiasListar = () => {
  const history = useHistory();
  const getMaterials = useClient('MaterialPagination');
  const MaterialUpdateOne = useClient('MaterialUpdateOne');

  const [resultMaterial, setResultMaterial] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [updateTable, setUpdateTable] = useState('');
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  const [validityFilter, setValidityFilter] = useState<DateRange>();
  const [rangePickerShow, setRangePickerShow] = useState();

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment: FNoticiaListar,
        variables: {
          page: page,
          perPage: 10,
          filter: {
            kind: configPage.kind,
            validityEnum: ValidityEnum.Any,
            includeDrafts: true,
            publishedAtRange: validityFilter
          },
          sort: sort
        }
      })
      .then((ctx) => {
        if (ctx.result && ctx.result.items) {
          setResultMaterial(ctx.result.items);
        }
      });
  }, [sort, validityFilter, page, updateTable]); //eslint-disable-line
  const total = getMaterials?.result?.pageInfo.itemCount;

  const resetFilters = () => {
    setPage(1);
    setSort(SortFindManyMaterialInput.IdDesc);
    setValidityFilter(undefined);
    setRangePickerShow(undefined);
  };

  const data = resultMaterial.map((material: any) => {
    const isDeleted = material?.status?._id === 'disapproved';
    const iconDelete = isDeleted ? 'undo' : 'delete';

    return {
      key: material?._id,
      id: material?.numericId,
      title: material?.title,
      user: material?.author?.name || 'backup',
      cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY HH:mm'),
      publicada: material.publishedAt
        ? formatDate(material?.publishedAt, 'DD/MM/YYYY HH:mm')
        : '-',
      status:
        material?.validityEnum === ValidityEnum.Future
          ? 'Cadastrado'
          : material?.status?.label,
      onRead: () => history.push(routes.noticiasConteudo.mount(material?._id)),
      iconDelete: iconDelete,
      messageOnDelete: isDeleted
        ? 'Deseja voltar o status para "Aguardando aprovação"?'
        : 'Deseja cancelar essa notícia?',
      onEdit: () => history.push(routes.noticiasEditar.mount(material?._id)),
      onDelete: () => {
        const record = {
          status: isDeleted
            ? EnumMaterialStatus.WaitingApproval
            : EnumMaterialStatus.Cancelled
        };

        MaterialUpdateOne.fetch({
          variables: { record, filter: { _id: material._id } },
          afterMutate: /^Material/
        }).then((ctx) => {
          message.success(configPage.messageDeletedSuccess);
          setUpdateTable(ctx.result?.recordId);
        });
      }
    };
  });

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <PageWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />
        <FilterWrapper>
          <NewButton to={configPage.routeNew}>
            {configPage.textButtonNew}
          </NewButton>
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => {
              setPage(1);
              setSort(e);
            }}
            value={sort}
          />
          <RangePicker
            placeholder={['Filtrar período', '']}
            locale={locale}
            format={'DD/MM/YYYY'}
            value={rangePickerShow}
            onChange={(momentDateArr: any) => {
              const dateISOArr = momentDateArr.map((item: any) =>
                moment(item).toISOString()
              );
              setRangePickerShow(momentDateArr);
              setValidityFilter({ start: dateISOArr[0], end: dateISOArr[1] });
            }}
            showTime={{ format: 'HH:mm' }}
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterWrapper>

        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: 10,
            current: page,
            total: total!,
            onChange: (e) => setPage(e)
          }}
        />
      </PageWrapper>
    </PageTemplate>
  );
};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];
