import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';

import { routes } from '../../../config/routes';
import { PageTemplate } from '../../../components/Layout/PageTemplate';

import { Form } from '../FormManage';
import { SurveyWrapper } from './styles';

export const PesquisaCadastrar = () => {
  return (
    <PageTemplate>
      <SurveyWrapper>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            {
              title: 'Gerenciar conteúdo',
              link: routes.pesquisaListar.mount()
            },
            { title: 'Pesquisa', link: routes.pesquisaListar.mount() },
            { title: 'Cadastrar', link: routes.pesquisaCadastrar.mount() }
          ]}
        />
        <TitleDescription
          iconType="smile"
          title="Pesquisa"
          description={
            'Estimule o conhecimento elaborando pesquisas, enquetes, entre outros recursos.'
          }
        />

        <ProviderSelecionarPublico>
          <Form />
        </ProviderSelecionarPublico>
      </SurveyWrapper>
    </PageTemplate>
  );
};
