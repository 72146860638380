import { useClient } from '../../autogenerated/client/client';
import * as React from 'react';

export function useMaterial(id: string, appendToFragment: any) {
  const variables = { _id: id };

  const MaterialFindOne = useClient('MaterialFindById', {
    variables,
    appendToFragment
  });

  React.useEffect(() => {
    if (id) {
      MaterialFindOne.fetch({ variables });
    }
  }, [id]); //eslint-disable-line

  return MaterialFindOne;
}
