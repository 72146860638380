import { usePaginatedClient } from '../../../hooks/usePaginatedClient';
import {
  SortFindManyEventInput,
  EnumEventStatus,
  FilterFindManyEventInput
} from '../../../autogenerated/client/types';

interface IusePaginatedEvents {
  perPage?: number;
  showAll?: boolean;
}

export const usePaginatedEvents = (props: IusePaginatedEvents) => {
  const OR = props.showAll
    ? [
        { status: EnumEventStatus.InProgress },
        { status: EnumEventStatus.Programming },
        { status: EnumEventStatus.Cancelled },
        { status: EnumEventStatus.Editing },
        { status: EnumEventStatus.Closed }
      ]
    : [
        { status: EnumEventStatus.InProgress },
        { status: EnumEventStatus.Programming },
        { status: EnumEventStatus.Closed }
      ];

  const { parsed, loading, updateFetch, pagination } = usePaginatedClient(
    'EventPagination',
    {
      initial: {
        page: 1,
        perPage: props.perPage ?? 15,
        sort: SortFindManyEventInput.CreatedatDesc,
        filter: { OR }
      },
      itemsFragment: `
          _id
          eventId
          title
          author {
            _id
            name
          }
          status {
            label
          }
          thumbnailContent {
            _id
            kind
            extension
            signedUrl
            downloadUrl
          }
          bannerContent {
            _id
            kind
            extension
            signedUrl
            downloadUrl
          }
          validity {
            start
            end
          }
          createdAt
          publishDate
        `,
      fetchOnMount: true,
      parseResult: (result) =>
        result.map((el) => ({
          ...el,
          files: [el.thumbnailContent]
        }))
    }
  );

  const filterUpdate = (filter: FilterFindManyEventInput) => {
    updateFetch((state) => {
      return {
        ...state,
        filter: {
          ...state.filter,
          status: filter.status,
          validity: filter.validity,
          titleOReventID: filter.titleOReventID,
          createdBy: filter.createdBy
        }
      };
    });
  };

  return {
    events: parsed,
    loading,
    pagination,
    filterUpdate
  };
};
