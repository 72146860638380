import React, { useEffect } from 'react';
import { Alert, Table } from 'antd';
import { LoadingIndicator } from '@digi-tim-19/components';

import { useClient } from 'autogenerated/client/client';

type TUsersTableProps = {
  campaignId: string;
  paymentFileId: string;
  columns: any[];
  kind: 'reversal' | 'creditExtra';
};

export const UploadUsersTable = ({
  campaignId,
  paymentFileId,
  columns,
  kind
}: TUsersTableProps) => {
  const { result, loading, error, fetch } = useClient(
    'ReconheceReverseUsersParse',
    {
      appendToFragment: `
      name
      CPF
      userPoints
      userCampaignPoints
      reversalPoints
      value
    `
    }
  );

  useEffect(() => {
    fetch({ variables: { campaignId, paymentFileId } });
  }, [campaignId, paymentFileId]);

  const data = result?.map((item) => {
    if (!item) return null;

    if (kind === 'reversal') {
      return {
        ...item,
        campaignBalance: item?.userCampaignPoints! - item?.reversalPoints!,
        userBalance: item?.userPoints! - item?.reversalPoints!
      };
    }

    return {
      ...item,
      campaignBalance: item?.userCampaignPoints! + item?.value!
    };
  });

  if (loading) {
    return <LoadingIndicator small />;
  }

  if (error) {
    return (
      <Alert
        message="Erro"
        description={error.split('; ').map((item) => (
          <p key={item}>{item}</p>
        ))}
        type="error"
        showIcon
        style={{ width: '100%' }}
      />
    );
  }

  return (
    <Table
      style={{ backgroundColor: 'white', width: '100%', marginTop: '2rem' }}
      columns={columns}
      dataSource={data}
    />
  );
};
