import { Breadcrumb, TitleDescription } from '@digi-tim-19/components';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { routes } from 'config/routes';
import React from 'react';
import { Form } from './Form';

export const EventosModeracaoEditar = () => {
  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo', link: routes.home.mount() },
            { title: 'Moderação Feed', link: routes.eventosModeracao.mount() },
            { title: 'Editar', link: routes.eventosModeracao.mount() }
          ]}
        />
        <TitleDescription
          iconType="issues-close"
          title="Editar Moderação"
          description="Edição/moderação da publicação"
        />
        <Form />
      </React.Fragment>
    </PageTemplate>
  );
};
