import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, Switch, Tabs } from 'antd';
import styled from 'styled-components';

type GeneralMode =
  | 'restaurantes'
  | 'dicas'
  | 'pontos'
  | 'hospedagem'
  | 'telefones';

type GuideInfo = {
  title: string;
  description: string;
  isActive?: boolean;
};

type EventGuideData = {
  generaldata: Record<GeneralMode, GuideInfo[]>;
  utilData: GuideInfo[];
};

const generalInfo: { key: GeneralMode; label: string }[] = [
  { key: 'restaurantes', label: 'Restaurantes' },
  { key: 'dicas', label: 'Dicas' },
  { key: 'pontos', label: 'Pontos Turísticos' },
  { key: 'hospedagem', label: 'Hospedagem' },
  { key: 'telefones', label: 'Telefones Úteis' }
];

const baseUtilGideInfo: GuideInfo[] = [
  { title: 'Hotel', description: '' },
  { title: 'Endereço', description: '' },
  { title: 'Contato', description: '' },
  { title: 'Voltagem da tomada', description: '' },
  { title: 'Facilidade', description: '' },
  { title: 'Bombeiro', description: '' },
  { title: 'Polícia Militar', description: '' },
  { title: 'Defesa civil', description: '' },
  { title: 'Ambulância SAMU', description: '' },
  { title: 'Hospital público - emergência', description: '' },
  { title: 'Hospital particular - emergência', description: '' }
];

const getinBaseItial = (): EventGuideData => {
  const baseGeneral = { title: '', description: '' };
  const generalInit = [...Array(5).keys()].map(() => baseGeneral);

  return {
    generaldata: {
      restaurantes: generalInit,
      dicas: generalInit,
      pontos: generalInit,
      hospedagem: generalInit,
      telefones: generalInit
    },
    utilData: baseUtilGideInfo
  };
};

interface IEventGuide {
  eventId: string;
  initEventGuide: any;
  onChange: (eventGuide: EventGuideData) => void;
}

export const EventGuide = (props: IEventGuide) => {
  const [data, setData] = useState<EventGuideData>(() => {
    if (props.initEventGuide) return props.initEventGuide;
    return getinBaseItial();
  });

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      props.onChange(data);
    }
  }, [data]);

  const handleGeneral = (info: GuideInfo, mode: GeneralMode, index: number) => {
    setData((old) => {
      const generaldata = {
        ...old.generaldata,
        [mode]: old.generaldata[mode].map((el, i) => (i === index ? info : el))
      };
      return { ...old, generaldata };
    });
  };

  const handleUtil = (info: GuideInfo) => {
    setData((old) => {
      const utilData = old.utilData.map((el) =>
        info.title === el.title ? info : el
      );
      return { ...old, utilData };
    });
  };

  const [addUtilModal, setAddUtilModal] = useState(false);
  const [newUtil, setNewUtil] = useState<Partial<GuideInfo>>({
    description: ''
  });
  const toogleAddUtil = () => setAddUtilModal((state) => !state);

  return (
    <EventGuideContainer>
      <Tabs>
        <Tabs.TabPane key={'general_info'} tab={'Informações Gerais'}>
          <StyledTabsBtn type="card">
            {generalInfo.map((el) => (
              <Tabs.TabPane key={el.key} tab={el.label}>
                {data.generaldata[el.key].map((info, i) => {
                  const key = `${i}`;
                  return (
                    <GeneralInput
                      key={key}
                      initial={info}
                      onChange={(data) => handleGeneral(data, el.key, i)}
                    />
                  );
                })}
              </Tabs.TabPane>
            ))}
          </StyledTabsBtn>
        </Tabs.TabPane>

        <Tabs.TabPane key={'util_info'} tab={'Informações Úteis'}>
          {data.utilData.map((info, i) => {
            const key = `${i}`;
            return <UtilInput key={key} initial={info} onChange={handleUtil} />;
          })}

          <Button
            style={{ marginBottom: '15px' }}
            type="primary"
            onClick={toogleAddUtil}
          >
            Adicionar informação útil
          </Button>

          {addUtilModal && (
            <Modal
              visible={addUtilModal}
              onOk={() => {
                if (newUtil.title) {
                  setData((old) => {
                    const utilData = old.utilData.concat({
                      ...newUtil,
                      description: newUtil.description ?? ''
                    } as GuideInfo);
                    return { ...old, utilData };
                  });
                  toogleAddUtil();
                }
              }}
              okButtonProps={{ disabled: !Boolean(newUtil.title) }}
              onCancel={toogleAddUtil}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                margin: '10px 20px'
              }}
            >
              <Input
                size="large"
                placeholder="Título"
                onChange={({ target }) =>
                  setNewUtil((state) => ({
                    ...state,
                    title: target.value
                  }))
                }
              />
              <Input.TextArea
                placeholder="Descrição"
                onChange={({ target }) =>
                  setNewUtil((state) => ({
                    ...state,
                    description: target.value
                  }))
                }
              />
            </Modal>
          )}
        </Tabs.TabPane>
      </Tabs>
    </EventGuideContainer>
  );
};

interface IGeneralInput {
  initial: GuideInfo;
  onChange: (data: GuideInfo) => void;
}

const GeneralInput = (props: IGeneralInput) => {
  const [data, setData] = useState<Partial<GuideInfo>>({ ...props.initial });

  useEffect(() => {
    if (Boolean(data.title) && Boolean(data.description)) {
      props.onChange(data as GuideInfo);
    }
  }, [data]);

  return (
    <GeneralInfoContainer>
      <Input
        placeholder="Título"
        size="large"
        className="title"
        defaultValue={props.initial.title}
        onChange={({ target }) =>
          setData((state) => ({ ...state, title: target.value }))
        }
      />
      <Input.TextArea
        placeholder="Descrição"
        className="description"
        defaultValue={props.initial.description}
        onChange={({ target }) =>
          setData((state) => ({ ...state, description: target.value }))
        }
      />
      <Switch
        defaultChecked={props.initial.isActive}
        onChange={(isActive) => setData((state) => ({ ...state, isActive }))}
      />
    </GeneralInfoContainer>
  );
};

interface IUtilInput {
  initial: GuideInfo;
  onChange: (data: GuideInfo) => void;
}

const UtilInput = (props: IUtilInput) => {
  const [data, setData] = useState<Partial<GuideInfo>>({ ...props.initial });

  useEffect(() => {
    if (Boolean(data.title) && Boolean(data.description)) {
      props.onChange(data as GuideInfo);
    }
  }, [data]);

  return (
    <UtilInfoContainer>
      <span className="title">{props.initial.title}</span>
      <Input
        size="large"
        placeholder="Descrição"
        defaultValue={props.initial.description}
        onChange={({ target }) =>
          setData((state) => ({ ...state, description: target.value }))
        }
      />
      <Switch
        defaultChecked={props.initial.isActive}
        onChange={(isActive) => setData((state) => ({ ...state, isActive }))}
      />
    </UtilInfoContainer>
  );
};

const EventGuideContainer = styled.div`
  width: 100%;
  .ant-tabs-tabpane-active {
    overflow: auto;
  }
  .ant-tabs-tab {
    background-color: white;
  }
`;

const StyledTabsBtn = styled(Tabs)`
  .ant-tabs-tab {
    color: ${({ theme }) => theme.white} !important;
    background-color: ${({ theme }) => theme.blue} !important;
  }
`;

const GeneralInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 20px;
  width: 96%;
  min-width: 650px !important;

  .title {
    width: 30%;
  }

  .ant-switch-checked {
    background-color: ${({ theme }) => theme.success};
  }
`;

const UtilInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 20px;
  width: 96%;
  min-width: 650px !important;

  .title {
    width: 25%;
  }

  .ant-switch-checked {
    background-color: ${({ theme }) => theme.success};
  }
`;
