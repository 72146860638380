import React from 'react';
import { useHistory } from 'react-router';
import { Former } from '@digi-tim-19/components';

import { routes } from '../../config/routes';
import { RegionalProfilesField } from '../../components/RegionalProfilesSelection/RegionalProfilesField';

import { FormContainer } from './Cadastrar/styles';
import { Questions } from './Questions/Questions';

const configPage = {
  routeList: routes.pesquisaListar.mount(),
  messageSuccess: 'Pesquisa cadastrada com sucesso',
  messageError: 'Pesquisa não cadastrada'
};

export const Form = (props: TFormSurveyProps) => {
  const history = useHistory();

  const initialValues = {};

  return (
    <FormContainer>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              className: 'FormHeader',
              list: [
                {
                  name: 'title',
                  label: 'Título da sua pesquisa',
                  required: true
                },
                {
                  name: 'description',
                  label: 'Descrição da pesquisa'
                },
                {
                  className: 'FormHeader_footer',
                  list: [
                    {
                      name: 'validity',
                      label: 'Data de Vigência',
                      type: 'rangePicker',
                      required: true,
                      extraProps: {
                        format: 'DD/MM/YYYY'
                      }
                    },
                    {
                      custom: (
                        <RegionalProfilesField
                          fieldConfig={{
                            initialValue:
                              props.initialValues?.availableAtRegionalProfiles
                          }}
                        />
                      )
                    }
                  ]
                }
              ]
            },
            {
              name: 'questions',
              label: 'Perguntas',
              required: true,
              component: Questions
            }
          ],
          submitButton: {
            label: 'Concluído'
          },
          cancelButton: {
            label: 'Cancelar',
            onClick: () => history.push(configPage.routeList)
          }
        }}
        onSubmit={async ({ data }: any) => {
          console.log(data);
        }}
      />
    </FormContainer>
  );
};

export type TFormSurveyProps = {
  initialValues?: any;
};
