import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useHistory } from 'react-router';
import { formatDate } from '@digi-tim-19/utils/build';
import { Breadcrumb, TitleDescription, Select } from '@digi-tim-19/components';
import { columns } from './columns';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { FilterContainer, CleanButton } from '../../../components/PageFilter';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput,
  EnumMaterialStatus
} from '../../../autogenerated/client/types';
import { Button } from './stylesListar';
import { TablePagination } from 'components/TablePagination/TablePagination';

export const LinksUteisListar: React.FC<TlinksUteisListarProps> = () => {
  const [createdAt, setCreatedAt] = useState();
  const [filterStatus, setFilterStatus] = useState<EnumMaterialStatus>();
  const [filterData, setFilterData] = useState<any>([]);
  const [sort, setSort] = useState(SortFindManyMaterialInput.IdDesc);
  // const [validityFilter, setValidity] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [resultMaterial, setResultMaterial] = useState<any>([]);
  const [updateTable, setUpdateTable] = useState('');
  const history = useHistory();
  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const getMaterials = useClient('MaterialPagination');

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: 10,
          filter: {
            kind: EnumMaterialKind.LinksUteis,
            validityEnum: ValidityEnum.Any,
            status: filterStatus,
            includeDrafts: true,
            createdAtRange: createdAt
            // publishedAtRange: validityFilter.length > 0
            //   ? {
            //     start: moment(validityFilter[0]).toISOString(),
            //     end: moment(validityFilter[1]).toISOString()
            //   }
            //   : undefined
          },
          sort: sort
        }
      })
      .then((ctx) => {
        if (ctx.result && ctx.result.items) {
          setResultMaterial(ctx.result.items);
        }
      });
  }, [sort, createdAt, filterStatus, page, updateTable]); //eslint-disable-line

  const total = getMaterials?.result?.pageInfo.itemCount;

  const resetFilters = () => {
    setPage(1);
    setSort(SortFindManyMaterialInput.IdDesc);
    setCreatedAt(undefined);
    setFilterData([]);
    setFilterStatus(undefined);
  };

  const data = resultMaterial?.map((material: any) => ({
    key: material?._id,
    id: material?.numericId,
    title: material?.title,
    link: material?.description,
    category: material?.categories[0]?.name,
    // expired: material?.validityEnum === ValidityEnum.Expired ? "Sim" : "Não",
    status: material?.status?.label,
    user: material?.author?.name || 'backup',
    cadastrada: formatDate(material?.createdAt, 'DD/MM/YYYY'),
    onEdit: () => history.push(routes.linksUteisEditar.mount(material?._id)),
    onDelete: () => {
      const record = { status: EnumMaterialStatus.Cancelled };

      MaterialUpdateOne.fetch({
        variables: { record, filter: { _id: material?._id } },
        afterMutate: /^Material/
      }).then((ctx) => {
        message.success('Link cancelado com sucesso');
        setUpdateTable(ctx.result?.recordId);
      });
    },
    onRead: () => history.push(routes.linksUteis.mount())
  }));

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            {
              title: 'Gerenciar conteúdo',
              link: routes.linksUteisListar.mount()
            },
            { title: 'Links Úteis', link: routes.linksUteisListar.mount() },
            { title: 'Listar', link: routes.linksUteisListar.mount() }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType="link"
          title="Links Úteis"
          description="Listar e consultar os Links Úteis"
        />

        <FilterContainer>
          <Button to={routes.linksUteisCadastrar.path}>Cadastrar link</Button>
          <Select
            placeholder="Ordenar por:"
            options={order}
            onChange={(e) => {
              setSort(e);
              setPage(1);
            }}
            value={sort}
          />
          <Select
            placeholder="Status"
            options={statusOptions}
            value={filterStatus}
            onChange={(e) => {
              setPage(1);
              setFilterStatus(e);
            }}
          />
          {/* <DatePickerRange
            showTime={true}
            style={{
              width: '300px'
            }}
            placeholderStart="Filtrar período"
            placeholderEnd=""
            onChange={(dates: any) => {
              if (dates.length === 0) setValidity([]);
            }}
            onOk={(value: any) => {
              setValidity(value);
            }}
          /> */}
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        <TablePagination
          loading={getMaterials.loading}
          columns={columns}
          dataSource={filterData.length > 0 ? filterData : data}
          pagination={{
            pageSize: 10,
            current: page,
            total: total!,
            onChange: (e: any) => setPage(e)
          }}
          emptyMessage={'Não há links para exibir'}
        />
      </>
    </PageTemplate>
  );
};

export type TlinksUteisListarProps = {};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.IdDesc }
];

const statusOptions = [
  { label: 'EM EDIÇÃO', value: EnumMaterialStatus.Editing },
  { label: 'PUBLICADO', value: EnumMaterialStatus.Published },
  { label: 'CANCELADO', value: EnumMaterialStatus.Cancelled },
  { label: 'EXPIRADO', value: EnumMaterialStatus.Expired }
  // { label: 'REPROVADO', value: EnumMaterialStatus.Disapproved }
  // { label: 'APROVADO', value: EnumMaterialStatus.Approved },
  // { label: 'AGUARDANDO APROVAÇÃO', value: EnumMaterialStatus.WaitingApproval },
];

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  count
  items {
    title
    createdAt
    validityEnum
    author {
    name
    }
    categories {
      name
      parentId
      parentCategory {
        name
        _id
      }
    }
    status {
      label
    }
     _id
  }
`;
