import styled from 'styled-components';
import { FilterContainer, CleanButton } from 'components/PageFilter';

export const PageContent = styled.div``;

export const OrderWrapper = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
  & .ant-calendar-range-picker-separator {
    display: none !important;
  }

  .informative-filter {
    margin-left: 10px;
  }
`;

export const FilterWrapper = styled(FilterContainer as any)`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

  ${CleanButton} {
    width: 100% !important;
  }

  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;
