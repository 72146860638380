import React from 'react';
import { verdade } from '@digi-tim-19/components';
import { Unpacked } from 'graphql-clientgen';
import { Methods } from '../autogenerated/client/types';
import { useClient } from '../autogenerated/client/client';

export const usePaginatedClient = <Parsed, K extends keyof Methods = 'any'>(
  methodName: K,
  props: UsePaginationConfig<Parsed, K>
) => {
  const {
    itemsFragment = '_id',
    parseResult,
    fetchOnMount = true,
    cache = false
  } = props;

  const initialVariables = React.useMemo<ClientState<K>>(() => {
    return parseState(props.initial);
  }, []);

  const stateRef = React.useRef(initialVariables);

  const [state, setState] = React.useState(stateRef.current);

  function parseState(state: ClientState<K>) {
    return {
      perPage: state.perPage,
      page: state.page,
      ...state
    };
  }

  const client = useClient(methodName, {
    variables: initialVariables,
    fetchOnMount,
    cache,
    appendToFragment: `
      count
      pageInfo { hasNextPage, hasPreviousPage, perPage, pageCount, currentPage }
      items {
        ${itemsFragment}
      }
    `
  });

  const { loading, result } = client;
  const count = result?.count || 0;
  const { hasNextPage, hasPreviousPage, perPage, pageCount, currentPage } =
    result?.pageInfo || {};

  function updateFetch(
    getState?: (variables: ClientState<K>) => Partial<ClientState<K>>
  ) {
    stateRef.current = {
      ...stateRef.current,
      ...(getState ? getState(stateRef.current) : undefined)
    };

    setState(stateRef.current);

    return client
      .fetch({
        variables: parseState(stateRef.current)
      })
      .then((ctx) => {
        return parseResult(verdade(ctx.result?.items));
      });
  }

  function setPage(page: number) {
    if (!loading) {
      stateRef.current.page = page;
      updateFetch();
    }
  }

  function nextPage() {
    if (!loading && hasNextPage) {
      ++stateRef.current.page;
      updateFetch();
    }
  }

  function previousPage() {
    if (!loading && hasPreviousPage) {
      --stateRef.current.page;
      updateFetch();
    }
  }

  const parsed = React.useMemo<Parsed>(() => {
    return parseResult(verdade(client.result?.items));
  }, [result?.items]);

  const pagination = {
    count: count || 0,
    total: count || 0,
    current: currentPage || 1,
    pageSize: perPage || 20,
    onChange: (page: number) => {
      setPage(page);
    }
  };

  return {
    ...client,
    description: `Exibindo ${Math.min(
      pagination.pageSize,
      pagination.count
    )} itens de ${pagination.count} - página ${pagination.current}`,
    hasNextPage,
    hasPreviousPage,
    perPage,
    pageCount,
    currentPage,
    nextPage,
    previousPage,
    setPage,
    updateFetch,
    state,
    parsed,
    count,
    pagination
  };
};

type ClientState<K extends keyof Methods = 'any'> = Parameters<
  Methods[K]
>[0] & {
  page: number;
  perPage: number;
};

export type UsePaginationConfig<
  Parsed,
  K extends keyof Methods = 'any', // method key (name)
  R = NonNullable<Unpacked<Items<K>>>[] // return type without promise
> = {
  initial: ClientState<K>;
  fetchOnMount?: boolean;
  cache?: boolean;
  itemsFragment: string;
  parseResult: (result: R) => Parsed;
};

// retorna o result.items ignorando null ou undefined
type Items<K extends keyof Methods> = NonNullable<
  NonNullable<Unpacked<ReturnType<Methods[K]>>['result']>['items']
>;
