import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  TitleDescription,
  IconList,
  verdade,
  LoadingIndicator
} from '@digi-tim-19/components';
import { Pagination, Empty } from 'antd';

import { PageTemplate } from '../../components/Layout/PageTemplate';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumMaterialKind,
  EnumMaterialStatus
} from '../../autogenerated/client/types';

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 0;
  list-style-type: none;
  padding-bottom: 30px;
  /* border-bottom: 1px solid #ccc; */
  & li {
    display: flex;
    align-items: center;
    width: 100% !important;
    padding-bottom: 30px;
  }
  & img {
    width: 80px;
    height: auto;
    margin-right: 10px;
  }
  & a {
    color: ${(props) => props.theme.blue};
    text-transform: uppercase;
    font-family: TimBold;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    & li {
      padding-right: 20px;
    }
  }
`;

const PageContent = styled.div`
  display: block;
  width: 100%;
`;

// const TitleList = styled.h2`
//   color: ${props => props.theme.red};
//   font-family: TimBold;
//   margin: 5px;
//   padding-top: 10px;
//   text-transform: uppercase;
//   padding-bottom: 10px;
// `;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  width: 100%;
`;

export const LinksUteis = () => {
  const [page, setPage] = useState(1);
  const [itemsTraining, setitemsTraining] = useState<any>([]);
  const [itemsSystem, setItemsSystem] = useState<any>([]);

  const perPage = 15;

  const getMaterialsSistemas = useClient('MaterialPagination');

  const getMaterialsTreinamento = useClient('MaterialPagination');

  useEffect(() => {
    getMaterialsSistemas
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            kind: EnumMaterialKind.LinksUteis,
            OR: [
              { status: EnumMaterialStatus.Approved },
              { status: EnumMaterialStatus.Published }
            ]
          }
        }
      })
      .then((ctx) => {
        setItemsSystem(ctx.result?.items);
      });
  }, [page]); //eslint-disable-line

  useEffect(() => {
    getMaterialsTreinamento
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          filter: {
            kind: EnumMaterialKind.LinksUteis,
            inCategories: ['links_uteis:treinamento'],
            OR: [
              { status: EnumMaterialStatus.Approved },
              { status: EnumMaterialStatus.Published }
            ]
          }
        }
      })
      .then((ctx) => {
        setitemsTraining(ctx.result?.items);
      });
  }, [page]);

  if (getMaterialsTreinamento.loading || getMaterialsSistemas.loading) {
    return (
      <PageTemplate>
        <LoadingIndicator />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Links Úteis', link: routes.linksUteis.mount() }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType="link"
          title="Links Úteis"
          description="Links Úteis para auxiliar na navegação"
        />
        <PageContent>
          {/* <TitleList>TREINAMENTO</TitleList> */}

          {getMaterialsSistemas.result?.count === 0 &&
          getMaterialsTreinamento.result?.count === 0 ? (
            <Empty />
          ) : (
            <>
              <StyledList>
                {verdade(itemsTraining).map((item: any) => (
                  <IconList
                    defaultImage="/defaultImages/default-card-thumb.png"
                    list={item}
                  />
                ))}
                {verdade(itemsSystem).map((item: any) => (
                  <IconList
                    defaultImage="/defaultImages/default-card-thumb.png"
                    list={item}
                  />
                ))}
              </StyledList>

              <PaginationWrapper>
                <Pagination
                  pageSize={perPage}
                  current={page}
                  total={itemsSystem.length + itemsTraining.length}
                  onChange={(page) => setPage(page)}
                />
              </PaginationWrapper>
            </>
          )}
          {/* <TitleList>SISTEMAS</TitleList>
          <StyledList>
            {
              verdade(getMaterialsSistemas?.result).map((item:any) => (
                <IconList defaultImage="/defaultImages/default-card-thumb.png" list={item} />
              ))
            }
          </StyledList> */}
        </PageContent>
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  count
  items {
    title
      _id
      description
      pinned
      categories {
        _id
        name
      }
      files {
        signedUrl
        kind
      }
    }
`;
