import React from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  TitleDescription
} from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { FormPlaylist } from '../FormPlaylist';

const Container = styled.div`
  width: 100%;
`;

export const PlaylistCadastrar = () => {
  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo' },
            {
              title: 'Playlists',
              link: routes.videosPlaylistListar.mount()
            },
            { title: 'Cadastrar' }
          ]}
        />
      }
    >
      <Container>
        <TitleDescription
          iconType="play-square"
          title="Gerenciamento de Playlists"
          description={'Cadastramento de playlist.'}
        />
        <FormPlaylist />
      </Container>
    </PageTemplate>
  );
};
