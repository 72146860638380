import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Modal, message } from 'antd';
import { useDimensions } from '@digi-tim-19/utils';
import { Former } from '@digi-tim-19/components';

import { IncentiveCampaign, User } from 'autogenerated/client/types';
import { useClient } from 'autogenerated/client/client';

import fields from './fields';
import { colors } from '@digi-tim-19/theme';

const ContestationModal = styled(Modal)`
  max-width: 900px !important;
  width: 100% !important;
  .ant-modal-body,
  .ant-modal-footer {
    background: #ededed;
  }
  .ant-modal-close-x {
    svg {
      fill: #fff;
    }
  }
  .ant-modal-header {
    background: ${colors.blue};
    .ant-modal-title {
      color: #fff;
    }
  }
  input:read-only {
    background: #f8f8f8;
  }
`;

const contestationSuccess = (protocol: number | null | undefined) => {
  Modal.success({
    content: `Contestação realizada com sucesso, para acompanhá-la anote o protocolo de número: ${protocol}`
  });
};

type TCampaignContestationProps = {
  campaign: IncentiveCampaign;
  userResult: User | null | undefined;
  visible: boolean;
  onCancel: () => void;
};

export const CampaignContestation = ({
  campaign,
  userResult,
  visible,
  onCancel
}: TCampaignContestationProps) => {
  const { isMobile } = useDimensions();
  const mutationContestation = useClient('CampaignContestationCreateOne');

  const initValues = useMemo(
    () => ({
      title: campaign.title,
      name: userResult?.name,
      channel: userResult?.channels?.find((el) => el),
      region: userResult?.region === 'THQ' ? 'BR' : userResult?.region,
      role: userResult?.roleGroup?.name,
      subscription: userResult?.matricula
    }),
    [campaign]
  );

  return (
    <ContestationModal
      title="Contestação"
      visible={visible}
      onCancel={onCancel}
      footer={[]}
    >
      <Former
        initialValues={initValues}
        config={{
          fields: fields(isMobile),
          submitButton: {
            label: 'enviar'
          }
        }}
        onSubmit={async ({ data }: any) => {
          const { indicator, evidence } = data;

          const record = {
            indicator,
            fileIds: [evidence],
            isResultContest: !!campaign?.results,
            parentId: campaign?._id,
            availableAtRegions: campaign?.availableAtRegions || [],
            availableAtChannels: campaign?.availableAtChannels || [],
            availableAtRegionalProfiles:
              campaign?.availableAtRegionalProfiles || []
          };

          await mutationContestation
            .fetch({
              variables: { record },
              appendToFragment: `record { protocol }`
            })
            .then((ctx: any) => {
              if (!ctx.result?.record) {
                return message.error('Não foi possível realizar esta ação');
              }

              onCancel();
              contestationSuccess(ctx?.result?.record?.protocol);
            });
        }}
      />
    </ContestationModal>
  );
};
