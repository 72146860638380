import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { Form } from '../Form';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { RegulamentoWrapper } from '../Cadastrar/stylesCadastrar';
import { useRouteMatch } from 'react-router';
import { useMaterial } from '../../../hooks/materials/useMaterial';

export const LinksUteisEditar = () => {
  const { params } = useRouteMatch();
  const appendToFragment = `
    title
    description
    fileIds
    files {
      _id
      title
      kind
    }
    categories {
      name
      parentId
      _id
    }
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
`;
  const linksUteis = useMaterial(params?.id, appendToFragment);

  return (
    <PageTemplate>
      <>
        <RegulamentoWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: 'home' },
              { title: 'Gerenciar conteúdo', link: '#' },
              { title: 'Links Úteis', link: '#' },
              { title: 'Cadastrar', link: '#' }
            ]}
          />
          <TitleDescription
            iconType="link"
            title="Links Úteis"
            description="Cadastramento e consulta dos Links Úteis"
          />
        </RegulamentoWrapper>

        {linksUteis.result && (
          <ProviderSelecionarPublico>
            <Form initialValues={linksUteis.result} />
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};
