import { useEffect, useMemo, useState } from 'react';

import {
  EnumIncentiveCampaignStatus,
  IncentiveCampaign
} from '../../../autogenerated/client/types';

function mountSelectOptions(label: string, value: string) {
  return {
    label,
    value,
    id: value
  };
}

const defaultStatusOptions = [
  mountSelectOptions('Apuração', EnumIncentiveCampaignStatus.InVerification),
  mountSelectOptions('Resultado', EnumIncentiveCampaignStatus.WithResults),
  mountSelectOptions('Andamento', EnumIncentiveCampaignStatus.InProgress),
  mountSelectOptions(
    'DISTRIBUIR TIM COINS',
    EnumIncentiveCampaignStatus.WaitingPointsDistribution
  ),
  mountSelectOptions('ENCERRADA', EnumIncentiveCampaignStatus.Closed)
];

export function useCampaignStatusSelect(initialStatus?: string) {
  const [statusOptions, setStatusOptions] = useState<any>(defaultStatusOptions);

  useEffect(() => {
    const { ResultWithPoints } = EnumIncentiveCampaignStatus;

    if (initialStatus === ResultWithPoints) {
      setStatusOptions((prev: any) => [
        ...prev,
        mountSelectOptions(
          'RESULTADO COM TIM COINS',
          EnumIncentiveCampaignStatus.ResultWithPoints
        )
      ]);
    }
  }, [initialStatus]);

  return statusOptions;
}

export function useInitialValues(
  initialValues: Partial<IncentiveCampaign> | undefined
) {
  const validityStart = initialValues?.campaignValidity?.start;
  const validityEnd = initialValues?.campaignValidity?.end;

  const contestationStart = initialValues?.contestationPeriod?.start;
  const contestationEnd = initialValues?.contestationPeriod?.end;

  const result = useMemo(() => {
    const status =
      initialValues?.status?._id ?? EnumIncentiveCampaignStatus.InVerification;

    return {
      ...initialValues,
      status,
      previa: initialValues?.ranking ? initialValues?.file?._id : undefined,
      validity: [validityStart, validityEnd],
      schedule: initialValues?.validity?.start,
      availableAtRoleGroups: initialValues?.availableAtRoleGroups || [],
      availableAtChannels: initialValues?.availableAtChannels || [],
      contestationPeriod:
        contestationStart && contestationEnd
          ? [contestationStart, contestationEnd]
          : []
    };
  }, [initialValues]);

  return result;
}
