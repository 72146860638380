import React from 'react';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription,
  ProviderXlsxJson
} from '@digi-tim-19/components';

import { PageTemplate } from 'components/Layout/PageTemplate';
import { routes } from 'config/routes';

import { Form } from '../Form/FormManage';

import { IncentiveCampaignWrapper } from './styles';

export const CampanhaIncentivoCadastrar = () => {
  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo', link: '' },
            {
              title: 'Incentivo',
              link: routes.campanhasIncentivoListar.mount()
            },
            {
              title: 'Campanhas e resultados',
              link: routes.campanhasIncentivoListar.mount()
            },
            { title: 'Cadastrar', link: '#' }
          ]}
        />
      }
    >
      <IncentiveCampaignWrapper>
        <TitleDescription
          iconType="trophy"
          title="Campanhas e resultados"
          description={'Cadastramento de campanhas de incentivo e resultados.'}
        />

        <ProviderSelecionarPublico>
          <ProviderXlsxJson>
            <Form />
          </ProviderXlsxJson>
        </ProviderSelecionarPublico>
      </IncentiveCampaignWrapper>
    </PageTemplate>
  );
};
