import { getRouterByEventCorp, clickDownload } from '@digi-tim-19/utils/build';
import { Event, EnumFileKind, Material } from '../autogenerated/client/types';
import { ActionButtonOptions } from '@digi-tim-19/components/build/main/ActionButton/MaterialActionButton';

export function accessEvent(event: any, history: any, routes: any) {
  const routesEvent = getRouterByEventCorp(event, routes);
  if (routesEvent.routerAccess) {
    history.push(routesEvent.routerAccess);
  } else if (routesEvent.routerDownload) {
    clickDownload(routesEvent.routerDownload);
  }
  return routesEvent;
}

export function actionsCard(
  event: Event,
  routes: any,
  history: any,
  showButtonDownload?: boolean
): ActionButtonOptions {
  const routesEvent = getRouterByEventCorp(event, routes);
  const { routerAccess, routerDownload } = routesEvent;
  const accessBTN = {
    share: true,
    download: showButtonDownload,
    accessButton: getAccessButton(event, history, routes)
  };

  const downloadBTN = {
    share: true,
    download: showButtonDownload
  };

  return routerAccess ? accessBTN : downloadBTN;
}

export function obterImagemPadraoPorMaterial(item: any, isList?: boolean) {
  let thumb = '';

  const imageFile = item?.files.find((file: any) => {
    if (!file.signedUrl) return;
    return (
      file.kind === EnumFileKind.CardThumb ||
      file.kind === EnumFileKind.VideoThumb
    );
  });

  if (imageFile?.signedUrl) return imageFile.signedUrl;

  return thumb;
}

export function actionsButton(
  listFolders: any,
  addFolder: (
    name: string,
    cb: (error: null | string, result?: any) => any
  ) => any
): ActionButtonOptions {
  return {
    pinFolder: {
      listFolders: listFolders,
      actionAddFolder: addFolder
    },
    share: true,
    pin: false
  };
}

export function actionsButtonFavorites(
  event: Event,
  routes: any,
  history: any,
  listFolders: any,
  addFolder: (
    name: string,
    cb: (error: null | string, result?: any) => any
  ) => any
): ActionButtonOptions {
  return {
    pinFolder: {
      listFolders: listFolders,
      actionAddFolder: addFolder
    },
    folder: {
      listFolders: listFolders
    },
    accessButton: getAccessButton(event, history, routes),
    share: true,
    pin: false
  };
}

export function getAccessButton(event: any, history: any, routes: any) {
  const routesEvent = getRouterByEventCorp(event, routes);
  const { routerAccess, routerDownload } = routesEvent;

  return {
    label: !!routerAccess ? 'ACESSAR' : 'ACESSAR',
    action: () => {
      history.push('/evento/' + event._id);
    }
  };
}
