import { useChannelFindMany } from '../channels/channelFindMany';

export const useChannelsCategory = () => {
  const getChannelsCategories = useChannelFindMany();

  return getChannelsCategories?.result?.map((item) => ({
    label: item?.name?.toUpperCase(),
    value: item?._id
  }));
};
