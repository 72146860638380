import React from 'react';
import { TableActions } from '@digi-tim-19/components';

export const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    render: (text: string) => <p>{text}</p>
  },
  {
    title: 'Criado em',
    dataIndex: 'updatedAt',
    key: 'updatedAt'
  },
  {
    title: 'Atualizado em',
    dataIndex: 'updatedAt',
    key: 'updatedAt'
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_: DataKey, record: any) => (
      <TableActions
        data={record}
        confirmationMessage="Deseja excluir este documento?"
      />
    )
  }
];

export interface DataKey {
  key: string;
  name: string;
  cadastrada: string;
  status: string;
}
