import React from 'react';
import DataGrid, { TextEditor } from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';

import { EditorItem } from '../DocsEditorForm';
import styled from 'styled-components';
import { Button, Input } from 'antd';

export const Wrapper = styled.div`
  width: 100%;
`;

type TableHTMLProps = {
  item: EditorItem;
  onChange: any;
  previewMode?: boolean;
};

export const TableHTML = (props: TableHTMLProps) => {
  const [title, setTitle] = React.useState<string>();
  if (props.item.data.kind !== 'table') return null;
  const { rows = [], cols = [] } = props.item.data;

  const addCol = () => {
    props.onChange({
      kind: 'table',
      rows,
      cols: [...cols, { key: `${cols.length}`, name: title ?? '' }]
    });
    setTitle(undefined);
  };

  const delCol = () => {
    props.onChange({ kind: 'table', rows, cols: cols.slice(0, -1) });
  };

  const addLine = () => {
    props.onChange({ kind: 'table', rows: [...rows, []], cols });
  };

  const delLine = () => {
    props.onChange({ kind: 'table', rows: rows.slice(0, -1), cols });
  };

  return (
    <Wrapper>
      {!props.previewMode && (
        <div style={{ display: 'flex', gap: '4px', paddingBottom: '4px' }}>
          <Input
            size="small"
            placeholder="Escolha o título da coluna"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Button icon="plus" size="small" onClick={addCol}>
            Coluna
          </Button>
          <Button icon="minus" size="small" onClick={delCol}>
            Coluna
          </Button>
          <Button icon="plus" size="small" onClick={addLine}>
            Linha
          </Button>
          <Button icon="minus" size="small" onClick={delLine}>
            Linha
          </Button>
        </div>
      )}
      {cols.length > 0 && (
        <DataGrid
          className="rdg-light"
          style={{ height: `${(rows.length + 1) * 35}px` }}
          columns={
            props.previewMode
              ? cols
              : cols.map((e) => ({ ...e, editor: TextEditor, editable: true }))
          }
          rows={rows ?? []}
          onRowsChange={(data) => {
            props.onChange({
              kind: 'table',
              cols,
              rows: rows.map((r, i) => {
                if (Array.isArray(data[i])) return r;
                const keys = Object.keys(data[i]);
                return Array.from(
                  { length: cols.length },
                  (_, j) => data[i][`${j}`] ?? ''
                );
              })
            });
          }}
        />
      )}
    </Wrapper>
  );
};
