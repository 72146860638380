import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select
} from '@digi-tim-19/components';
import { onlyNumbers } from '@digi-tim-19/utils/build';

import { routes } from 'config/routes';
import { useChannelsCategory } from 'hooks/categories';
import { useClient } from 'autogenerated/client/client';
import { PageTemplate } from 'components/Layout/PageTemplate';

import columns from './columns';

const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fill, 200px);
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;
`;

export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;
  cursor: pointer;
`;

const configPage = {
  pageName: 'Consulta de Cadastro',
  icon: 'user',
  description: 'Listar usuários',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo' },
    { title: 'Usuários' }
  ],
  perPage: 10
};

export const UsuariosListar = () => {
  const channelsOptions = useChannelsCategory();
  const UserPagination = useClient('UserPagination');

  const [page, setPage] = useState(1);
  const [channelFilter, setChannelFilter] = useState();
  const [matriculaFilter, setMatriculaFilter] = useState<string | undefined>();
  const [cpfFilter, setCpfFilter] = useState<string>();

  useEffect(() => {
    UserPagination.fetch({
      fetchOnMount: true,
      appendToFragment,
      variables: {
        page,
        perPage: configPage.perPage,
        filter: {
          channels: channelFilter,
          ninChannels: ['account_provisioner'],
          matricula: matriculaFilter || undefined,
          CPF: cpfFilter || undefined
        }
      }
    });
  }, [page, channelFilter, matriculaFilter, cpfFilter]);
  const isLoading = UserPagination.loading;
  const resultUsers = UserPagination.result?.items || [];
  const total = UserPagination.result?.pageInfo.itemCount;

  const resetFilters = () => {
    setPage(1);
    setChannelFilter(undefined);
    setMatriculaFilter(undefined);
    setCpfFilter(undefined);
  };

  const data = resultUsers.map((user) => {
    return {
      matricula: user?.matricula || '-',
      name: user?.name || '-',
      channel: user?.channels || '-',
      roleGroup: user?.roleGroup?.name || '-',
      region: user?.region || '-'
    };
  });

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <FilterContainer>
          <Select
            placeholder="Canais"
            options={channelsOptions}
            value={channelFilter}
            onChange={(e) => {
              setPage(1);
              setChannelFilter(e);
            }}
          />
          <Input
            placeholder="Filtrar por Matrícula"
            value={matriculaFilter}
            onChange={(e) => {
              setPage(1);
              setMatriculaFilter(e.target.value);
            }}
            allowClear
          />
          <Input
            placeholder="Filtrar por CPF"
            value={cpfFilter}
            maxLength={11}
            onChange={(e) => {
              setPage(1);
              setCpfFilter(onlyNumbers(e.target.value));
            }}
            allowClear
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          pagination={{
            pageSize: configPage.perPage,
            current: page,
            total: total!,
            onChange: (e) => setPage(e)
          }}
        />
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    matricula
    name
    CPF
    channels
    region
    roleGroup {
      name
    }
  }
`;
