import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';
import { colors } from '@digi-tim-19/theme';

export const Container = styled.div`
  text-decoration: none;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  padding-left: 16px;

  &:hover {
    color: ${colors.blue};
  }

  p:first-of-type {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    color: ${colors.blue};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 170px;
    font-family: TimBold;
  }

  p {
    padding-top: 2px;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 168px;
    color: ${colors.blue};
    font-family: TimRegular;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    margin-top: 4px;
    line-height: 14px;
    letter-spacing: 0.14px;
    color: ${colors.blue};
    font-family: TimRegular;

    span p {
      margin: 0;
    }
  }

  img {
    max-width: none;
    cursor: pointer;
  }
`;

export const UserInfoContainer = styled.div<{ isResidential?: boolean }>`
  align-items: center;
  min-width: 130px;
  ${(props) =>
    !props?.isResidential &&
    `
  p {
    color: white!important;
  }
  span {
    color: white!important;
  }
  `}
`;

export const UserProfileContainer = styled.div`
  position: relative;
  padding-left: 16px;

  img {
    min-width: 50px;
    height: 50px;
    width: 40px;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
  }

  .online-indicator {
    position: absolute;
    width: 12px;
    height: 11px;
    right: 2px;
    bottom: 5px;
    border-radius: 5.5px;
    border: 1px solid #fff;
    background: #28c76f;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 0px);
  min-width: 292px;
  right: -41px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 12px 12px 12px 12px;
  box-shadow: 2px 2px 4px 0px rgba(180, 190, 210, 0.5);
  z-index: 1000;

  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);

  &.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

export const MenuItem = styled.div`
  height: 45.143px;
  padding: 14px 16px;
  font-family: TimRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #535353;
  cursor: pointer;
  border-radius: 12px;

  &:hover {
    background-color: #e3f2fd;
    border-left: 1px solid #0d47a1;
    color: #0d47a1;
    border-radius: 5px;
  }

  .anticon {
    margin-right: 8px;
  }
`;

export const CustomModalDesktop = styled(AntdModal)`
  display: flex;
  top: 0px;
  height: 100vh;
  margin-right: 0px;
  min-width: 40vw;

  .ant-modal-content {
    height: 100vh;
    width: 100%;
  }

  .ant-modal-body {
  }
`;
