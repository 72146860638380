import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Icon, message } from 'antd';
import styled from 'styled-components';
import { string, number, object, ValidationError } from 'yup';
import {
  Former,
  LabelSelecionarPublico,
  usePublico,
  useXlsxJsonActions
} from '@digi-tim-19/components';

import {
  IncentiveCampaign,
  EnumFileKind
} from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';
import { EnumIncentiveCampaignStatus } from '../../../autogenerated/client/types';
import { routes } from '../../../config/routes';
import { useInvoiceOriginOptions } from '../../../hooks/incentiveCampaign/useInvoiceOriginOptions';
import { useWalletOptions } from 'hooks/incentiveCampaign/useWalletOptions';
import { RegionalProfilesField } from '../../../components/RegionalProfilesSelection/RegionalProfilesField';
import { validateSelectPublic } from '../../../utils/validateSelectPublic';
import {
  Divider,
  FormContainer,
  CategoriaMenu,
  ErrorsModal
} from '../Cadastrar/styles';
import Upload from './Upload';
import { useInitialValues, useCampaignStatusSelect } from './utils';
import { useStatus, StatusOptions } from './useStatus';
import moment from 'moment';

const WrapperLabel = styled.div`
  display: flex;
  flex: auto;
  margin-top: -30px;
`;

const FieldContainer = styled.div`
  width: 100%;

  @media (max-width: 1270px) {
    margin-top: 25px;
  }
`;

const FieldTitle = styled.h3`
  color: #565656;
  font-size: initial;
`;

const FieldDownload = styled.a`
  display: flex;

  &:hover {
    background-color: #b8c7cc;
  }

  i {
    display: flex;
    align-items: center;
    padding-right: 10px;
    color: #565656;
  }
`;

const FieldDate = styled.span`
  width: 100%;
  font-size: 9pt;
`;

const configPage = {
  routeList: routes.campanhasIncentivoListar.mount(),
  messageSuccess: 'Campanha cadastrada com sucesso',
  messageError: 'Campanha não cadastrada'
};

export const Form = (props: TFormIncentiveCampaignEditProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [content, setContent] = useState<any>([]);
  const [publicSelected, setPublicSelected] = useState(false);
  const [invoiceOrigin, setInvoiceOrigin] = useState<any>();
  const [disabled, setDisabled] = useState<boolean>(false);

  const history = useHistory();
  const initialValues = useInitialValues(props.initialValues);
  const walletOptions = useWalletOptions(invoiceOrigin);
  const statusOptions = useCampaignStatusSelect(initialValues.status);
  const invoiceOriginsOptions = useInvoiceOriginOptions();
  const { status, execute } = useStatus();
  const { availableAtRegions, availableAtChannels } = usePublico();
  const xlsxJsonActions = useXlsxJsonActions();
  const json = xlsxJsonActions.state.json;

  const IncentiveCampaignUpdateOne = useClient('IncentiveCampaignUpdateOne');
  const createIncentiveCampaign = useClient('IncentiveCampaignCreateOne');
  const { _id: idCampaign }: any = initialValues;
  const isUpdate = !!idCampaign;

  const schema = object().shape({
    name: string().required('O nome é obrigatório'),
    position: number().required('A posição é obrigatória'),
    role: string().required('O cargo é obrigatório'),
    points: string().nullable(),
    partner: string().nullable(),
    region: string().required('A regional é obrigatória')
  });

  useEffect(() => {
    if (!props.initialValues) return;

    const { status, fileContent, invoiceOrigin } = props.initialValues;

    if (props?.initialValues?.status) execute(status?._id as StatusOptions);
    if (status?._id === EnumIncentiveCampaignStatus.ResultWithPoints)
      setDisabled(true);
    if (props?.initialValues?.fileContent) setContent(fileContent);
    if (invoiceOrigin) setInvoiceOrigin(invoiceOrigin);
  }, [props.initialValues]);

  useEffect(() => {
    if (xlsxJsonActions.state.file) {
      const arr = json?.map((item: any) => ({
        name: item.Nome,
        role: item.Cargo,
        position: item.Posição,
        region: item.Regional,
        points: item['TIM Coins']?.toString(),
        partner: item.Parceiro
      }));
      execute(EnumIncentiveCampaignStatus.WithResults);
      setContent(arr);
    }
  }, [xlsxJsonActions.state]);

  return (
    <FormContainer>
      <ErrorsModal
        okText="OK"
        cancelText="SAIR"
        title="Verifique os seguintes campos na planilha"
        visible={isOpen}
        onCancel={() => {
          setIsOpen(false);
          setErrors([]);
        }}
        onOk={() => {
          setIsOpen(false);
          setErrors([]);
        }}
      >
        <div>
          {errors.map((error) => (
            <p>{error}</p>
          ))}
        </div>
      </ErrorsModal>
      <Former
        initialValues={initialValues}
        onChange={(field: any, value: any, formApi: any) => {
          if (field === 'invoiceOrigin' && !!value) {
            formApi.change('walletId', undefined);
          }
        }}
        config={() => {
          return {
            fields: [
              {
                className: 'header',
                inline: true,
                list: [
                  {
                    disabled: disabled,
                    name: 'schedule',
                    label: 'PROGRAMAR PUBLICAÇÃO',
                    type: 'datePicker',
                    required: true,
                    enableDatePriorToCurrent: true,
                    extraProps: {
                      format: 'DD/MM/YYYY',
                      setStartDay: true
                    }
                  },
                  {
                    disabled: disabled,
                    name: 'validity',
                    label: 'Data de Vigência',
                    type: 'rangePicker',
                    required: true,
                    extraProps: {
                      startPlaceholder: 'Início da vigência',
                      endPlaceholder: 'Fim da vigência'
                    }
                  },
                  {
                    disabled: disabled,
                    name: 'videoLink',
                    label: 'LINK PARA O VÍDEO',
                    type: 'text'
                  }
                ]
              },
              {
                className: 'header header-file-card-banner',
                inline: true,
                list: [
                  {
                    disabled: disabled,
                    name: 'cardImage',
                    label: '',
                    type: 'upload',
                    extraProps: {
                      kind: EnumFileKind.CardThumb,
                      CTA: 'IMAGEM DO CARD (195x150)'
                    }
                  },
                  {
                    disabled: disabled,
                    name: 'bannerContent',
                    label: '',
                    type: 'upload',
                    extraProps: {
                      kind: EnumFileKind.AnyImage,
                      CTA: 'CONTEÚDO DO BANNER (970x340)'
                    }
                  },
                  {
                    disabled: disabled,
                    name: 'status',
                    label: 'Status',
                    type: 'select',
                    options: statusOptions,
                    maxWidth: '250px',
                    onChange: (e: any) => {
                      const opt: StatusOptions = e;
                      execute(opt);
                    },
                    extraProps: {
                      value: status.status
                    }
                  },
                  {
                    disabled: disabled,
                    name: 'enableSocialInteraction',
                    label: 'Permitir comentários e curtidas',
                    type: 'switch'
                  }
                ]
              },
              {
                custom: <Divider />
              },
              {
                custom: (
                  <CategoriaMenu>Categoria do menu: Campanha</CategoriaMenu>
                )
              },
              {
                disabled: disabled,
                name: 'title',
                label: 'TÍTULO DA CAMPANHA',
                type: 'text',
                required: true,
                // extraProps: {
                //   maxLength: 40
                // }
                validate: (value: any) => {
                  const format = /[#%&;]/;
                  if (format.test(value))
                    return 'Os caracteres #%&; não são permitidos';

                  if (value) {
                    return !(value.length > 110)
                      ? undefined
                      : 'Máximo de 110 caracteres';
                  }

                  return undefined;
                }
              },
              {
                custom: <Divider />
              },
              {
                className: 'grid-row',
                inline: true,
                list: [
                  {
                    disabled: disabled,
                    name: 'contestationPeriod',
                    label: 'Período para contestação',
                    type: 'rangePicker',
                    extraProps: {
                      className: 'contestation-period',
                      noBeforeDate: true
                    },
                    maxWidth: '230px'
                  },
                  {
                    className: 'switch',
                    list: [
                      {
                        disabled: disabled,
                        name: 'ranking',
                        label: 'Prévia',
                        type: 'switch',
                        afterChange: () => {
                          if (status.preview === status.results) {
                            execute('in_progress');
                          } else {
                            execute('toggle');
                          }
                        },
                        extraProps: {
                          checked: status.preview
                        }
                      },
                      status.preview && {
                        disabled: disabled,
                        name: 'previa',
                        label: '',
                        type: 'upload',
                        extraProps: {
                          kind: EnumFileKind.Xlsx,
                          CTA: 'PRÉVIA',
                          multiple: false,
                          onSelectFile: (info: any) => {
                            const file = info.file.originFileObj;
                            if (file) {
                              xlsxJsonActions.setState('file', file);
                            }
                          }
                        }
                      }
                    ]
                  },
                  disabled && {
                    custom: (
                      <>
                        {props.initialValues?.file?._id && (
                          <FieldContainer>
                            <FieldTitle>Resultados</FieldTitle>
                            <FieldDownload
                              href={
                                props.initialValues?.file?.signedUrl ||
                                undefined
                              }
                              download
                            >
                              <Icon type="paper-clip" />
                              {props.initialValues?.file?.title}
                            </FieldDownload>
                          </FieldContainer>
                        )}
                      </>
                    )
                  },
                  !disabled && {
                    className: 'switch',
                    list: [
                      {
                        name: 'results',
                        label: 'Resultados',
                        type: 'switch',
                        afterChange: () => {
                          if (status.results === status.preview)
                            execute('with_results');
                          else execute('toggle');
                        },
                        extraProps: {
                          checked: status.results
                        }
                      },
                      status.results && {
                        custom: (
                          <Upload
                            CTA="RESULTADOS"
                            initialValue={
                              props.initialValues?.results
                                ? props.initialValues?.file?._id
                                : undefined
                            }
                          />
                        )
                      }
                    ]
                  },
                  {
                    custom: (
                      <>
                        {props?.initialValues?.paymentFileId && (
                          <FieldContainer>
                            <FieldTitle>
                              Upload de TIM Coins Campanha
                            </FieldTitle>
                            <FieldDownload
                              href={
                                props?.initialValues?.paymentFileId
                                  ?.signedUrl || undefined
                              }
                              download
                            >
                              <Icon type="paper-clip" />
                              {props?.initialValues?.paymentFileId?.title}
                            </FieldDownload>
                            {props?.initialValues?.paymentFileId?.createdAt && (
                              <FieldDate>
                                Upload feito em:{' '}
                                {moment(
                                  props?.initialValues?.paymentFileId?.createdAt
                                ).format('DD/MM/YYYY HH:mm')}
                              </FieldDate>
                            )}
                          </FieldContainer>
                        )}
                      </>
                    )
                  }
                ]
              },
              {
                custom: <Divider />
              },
              {
                className: 'header header-file-regulation-presentation',
                inline: true,
                list: [
                  {
                    disabled: disabled,
                    name: 'regulation',
                    label: '',
                    type: 'upload',
                    required: true,
                    extraProps: {
                      kind: EnumFileKind.Pdf,
                      CTA: 'REGULAMENTO'
                    }
                  },
                  {
                    disabled: disabled,
                    name: 'presentation',
                    label: '',
                    type: 'upload',
                    extraProps: {
                      kind: EnumFileKind.Pdf,
                      CTA: 'APRESENTAÇÃO'
                    }
                  }
                ]
              },
              {
                className: 'header header-file-origin-wallet',
                inline: true,
                list: [
                  {
                    disabled: disabled,
                    name: 'invoiceOrigin',
                    label: 'ORIGEM DA FATURA',
                    type: 'select',
                    required: true,
                    options: invoiceOriginsOptions,
                    afterChange: (e: any) => {
                      setInvoiceOrigin(e);
                      walletOptions.fetch({
                        variables: {
                          filter: { allInvoiceOrigin: [e] },
                          limit: 100
                        }
                      });
                    }
                  },
                  {
                    name: 'walletId',
                    label: 'CARTEIRA',
                    type: 'select',
                    required: true,
                    disabled: !walletOptions?.options?.length || disabled,
                    options: walletOptions.options
                  }
                ]
              },
              {
                custom: <Divider />
              },
              {
                className: 'Footer',
                list: [
                  {
                    custom: (
                      <RegionalProfilesField
                        disabled={disabled}
                        fieldConfig={{
                          initialValue:
                            props.initialValues?.availableAtRegionalProfiles
                        }}
                      />
                    )
                  },
                  // suggestion: add this validation inside of RegionalProfilesField
                  publicSelected &&
                    !disabled && {
                      custom: (
                        <WrapperLabel>
                          <LabelSelecionarPublico />
                        </WrapperLabel>
                      )
                    }
                ]
              },
              {
                custom: <Divider />
              },
              {
                disabled: disabled,
                name: 'faq',
                label: 'CADASTRAR FAQ',
                type: 'texteditor',
                validate: (value: any) => {
                  const format = /[#%&]/;
                  if (format.test(value))
                    return 'Os caracteres #%& não são permitidos';

                  if (value) {
                    return !(value.length > 1200)
                      ? undefined
                      : 'Máximo de 1200 caracteres';
                  }

                  return undefined;
                }
              }
            ],
            submitButton: {
              disabled: disabled,
              label: isUpdate ? 'Atualizar' : 'Salvar',
              onClick: (formerRef: any) => {
                setPublicSelected(validateSelectPublic(formerRef));
              }
            },
            cancelButton: {
              label: 'CANCELAR',
              onClick: () => history.push(configPage.routeList)
            }
          };
        }}
        onSubmit={async ({ data }: any) => {
          const { Closed, ResultWithPoints } = EnumIncentiveCampaignStatus;

          if ([ResultWithPoints].includes(data.status)) {
            const statusError =
              data.status === Closed
                ? 'encerrada'
                : 'com TIM Coins distribuídos';

            message.error(`Não é possivel alterar a campanha ${statusError}.`);
            return;
          }

          if (!data.availableAtRegionalProfiles) {
            return setPublicSelected(true);
          }

          let startContestation = null;
          let endContestation = null;

          if (
            data?.contestationPeriod &&
            Object.keys(data.contestationPeriod).length > 0
          ) {
            startContestation =
              data.contestationPeriod[0] || data?.contestationPeriod?.start;
            endContestation =
              data.contestationPeriod[1] || data?.contestationPeriod?.end;
          }

          const startValidity = data.validity[0] || data.validity.start;
          const endValidity = data.validity[1] || data.validity.end;
          const resultTable = status.results ? data.upload : data.previa;

          if (!resultTable) {
            setContent([]);
          }

          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRegionalProfiles: data.availableAtRegionalProfiles,
            title: data.title,
            videoLink: data.videoLink,
            enableSocialInteraction: data.enableSocialInteraction,
            regulation: data.regulation,
            faq: data.faq,
            walletId: data.walletId,
            category: 'campaign',
            cardImage: data.cardImage || undefined,
            bannerContent: data.bannerContent || null,
            contestationPeriod: {
              start: startContestation,
              end: endContestation
            },
            file: resultTable || null,
            status: status.status,
            results: status.results,
            ranking: status.preview,
            fileContent: resultTable ? content : null,
            presentation: data.presentation || undefined,
            invoiceOrigin: data.invoiceOrigin || undefined,
            validity: { start: data.schedule },
            campaignValidity: { start: startValidity, end: endValidity }
          };

          let validationErrors = [] as any;

          Promise.all(
            content?.map(async (c: any, index: any) => {
              try {
                await schema.validate(c, {
                  abortEarly: false
                });
              } catch (err) {
                if (err instanceof ValidationError) {
                  err.inner.forEach((error) => {
                    validationErrors.push(`${index + 2}:  ${error.message}`);
                  });
                }
                setIsOpen(true);
              }
            })
          ).then(async () => {
            setErrors(validationErrors);

            if (validationErrors.length !== 0) return;

            if (isUpdate && data._id) {
              await IncentiveCampaignUpdateOne.fetch({
                variables: { record, filter: { _id: data._id } }
              }).then((ctx) => {
                if (!ctx.errors) {
                  history.push(configPage.routeList);
                } else {
                  console.error('Erro ao atualizar', ctx.errors);
                }
              });
            } else {
              await createIncentiveCampaign
                .fetch({ variables: { record } })
                .then((ctx) => {
                  if (ctx?.result) {
                    message.success(configPage.messageSuccess);
                    history.push(configPage.routeList);
                  } else {
                    console.error('errors', ctx.errors);
                    message.error(configPage.messageError);
                  }
                });
            }
          });
        }}
      />
    </FormContainer>
  );
};

export type TFormIncentiveCampaignEditProps = {
  initialValues?: Partial<IncentiveCampaign>;
};
