import React, { useState, useEffect } from 'react';
import { Button, Pagination } from 'antd';
import { useHistory } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  useCurrentUser
} from '@digi-tim-19/components';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { routes } from '../../config/routes';
import {
  CardButtonContainer,
  CardItem,
  CardItemContent,
  CardItemWrapper,
  NotFound,
  WrapperContainer
} from './styles';
import { formatDate } from '@digi-tim-19/utils';
import { RemunerationRegulation } from './Regulation/Regulation';
import { EnumRemunerationStatus } from 'autogenerated/client/types';

const configPage = {
  pageName: 'Remuneração variável e metas',
  icon: 'inbox',
  description: 'Documentos sobre remuneração variável e metas',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Para você', link: routes.home.mount() },
    {
      title: 'Remuneração variável e metas',
      link: routes.remuneracaoVariavelMetasParticipanteListar.mount()
    }
  ],
  pageSize: 20,
  empty: 'Não há documentos para exibir no momento'
};

const appendToFragment = `
  count
  pageInfo {
    perPage
    itemCount
    currentPage
  }
  items {
    _id
    title
    faq
    views { count }
    likes { count }
    cardImageFile { signedUrl }
    bannerContentFile { signedUrl }
    documentFile { signedUrl }
    regulationFile {
      signedUrl
      downloadUrl
    }
    presentationFile {
      signedUrl
      downloadUrl
    }
    updatedAt
  }
`;

export const Remuneracoes = () => {
  const history = useHistory();
  const remunerationPagination = useClient('RemunerationPagination');
  const [pageNumber, setPageNumber] = useState(1);
  const [items, setItems] = useState<any>([]);
  const category = 'Remuneração variável e metas';
  const defaultImage = '/defaultImages/default-card-thumb.png';
  const [remunerationSelected, setRemunerationSelected] =
    useState<any>(undefined);
  const user = useCurrentUser();

  useEffect(() => {
    remunerationPagination
      .fetch({
        appendToFragment,
        fetchOnMount: true,
        variables: {
          perPage: configPage.pageSize,
          page: pageNumber,
          filter: {
            status: EnumRemunerationStatus.Published
          }
        }
      })
      .then((ctx) => {
        setItems(ctx.result?.items);
      });
  }, [pageNumber]);

  const handleCheckAccepted = (remuneration: any) => {
    const hasAccepted = user?.result?.acceptedTerms?.find(
      (item) => item?.term === `RemunerationRegulation-${remuneration?._id}`
    );

    if (hasAccepted) {
      history.push(
        routes.remuneracaoVariavelMetasParticipanteVisualizar.mount(
          remuneration?._id
        ),
        { accepted: true }
      );
    } else {
      setRemunerationSelected(remuneration);
    }
  };

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={configPage.breadcrumb}
        />
      }
    >
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {remunerationSelected && (
          <RemunerationRegulation
            remuneration={remunerationSelected}
            onClose={() => setRemunerationSelected(undefined)}
          />
        )}

        {remunerationPagination.loading && <LoadingIndicator />}
        {!remunerationPagination.loading && (
          <>
            {!!items?.length ? (
              <>
                <WrapperContainer>
                  {items.map((item: any, index: number) => {
                    return (
                      <CardItemWrapper
                        key={`remuneration-${index}`}
                      >
                        <CardItem>
                          <CardItemContent>
                            <div className="card-content-wrapper">
                              <div className="card-date">
                                ATUALIZADO EM: <span>{formatDate(item?.updatedAt, 'DD/MM/YY')}</span>
                              </div>
                              <h2>{item?.title}</h2>
                            </div>
                          </CardItemContent>
                          <CardButtonContainer>
                            <Button
                              type="danger"
                              onClick={() => handleCheckAccepted(item)}
                            >
                              ACESSAR
                            </Button>
                          </CardButtonContainer>
                        </CardItem>
                      </CardItemWrapper>
                    );
                  })}
                </WrapperContainer>
                <Pagination
                  style={{ textAlign: 'center' }}
                  pageSize={configPage.pageSize}
                  current={pageNumber}
                  total={remunerationPagination?.result?.count || 0}
                  onChange={(page) => setPageNumber(page)}
                />
              </>
            ) : (
              <NotFound>{configPage.empty}</NotFound>
            )}
          </>
        )}
      </>
    </PageTemplate>
  );
};
