import styled from 'styled-components';
import { Input, Select } from 'antd';

export const SurveyQuestionWrapper = styled.div`
  padding: 0 25px 20px;
  width: 100%;
`;

export const QuestionWrapper = styled.div`
  border-top: 1px solid #ccc;
  padding: 30px 0;

  h3 {
    color: ${(props) => props.theme.blue};
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .question {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    .question {
      flex-direction: row;
    }
  }
`;

export const QuestionInput = styled(Input)`
  padding: 20px;

  @media (min-width: 768px) {
    margin-right: 20px;
  }
`;

export const QuestionType = styled(Select)`
  margin: 20px 0 30px;

  .ant-select-selection--single {
    padding: 5px;
    height: auto;
  }

  @media (min-width: 768px) {
    margin: 0 20px 0 0;
    max-width: 300px;
  }
`;

export const QuestionOptions = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 150px;
  width: 100%;
`;

export const QuestionAnswerOptions = styled.div`
  margin: 35px 0;
  max-width: 900px;

  input {
    padding: 20px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin: 10px 0;

      span {
        align-items: center;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        display: flex;
        margin-right: 10px;
        padding: 8px 20px;
        width: 100%;

        .circle {
          border: 2px solid #ccc;
          border-radius: 50%;
          margin: 0 15px;
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  div {
    align-items: center;
    display: flex;

    input {
      margin-right: 10px;
    }
  }
`;
