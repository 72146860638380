import { useHistory } from 'react-router';

import { useClient } from 'autogenerated/client/client';
import { routes } from './routes';

const getReconheceUrl = (token?: string) => {
  const urlReconhece = process.env.REACT_APP_URL_RECONHECE;
  return `${urlReconhece}${token}`;
};

export const useGoToReconhece = () => {
  const { fetch } = useClient('ReconheceCreateTempToken');
  const history = useHistory();

  return () => {
    fetch().then(({ result }) => {
      if (!result) return history.push(routes.login.mount());

      const url = getReconheceUrl(result);
      window.open(url, '_blank');
    });
  };
};
