import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { message, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import {
  Breadcrumb,
  TitleDescription,
  Table,
  Select,
  LoadingIndicator,
  useCurrentUser
} from '@digi-tim-19/components';
import { formatDate } from '@digi-tim-19/utils';

import { accessMaterial } from '../../../utils/material';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { FilterContainer, CleanButton } from '../../../components/PageFilter';
import { useCategoryCoreVideo } from 'hooks/categories';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput,
  EnumMaterialStatus,
  DateRange,
  Material
} from '../../../autogenerated/client/types';

import { Button } from './stylesListar';
import { columns } from './columns';
import { additionalCategoriesOptions } from '../FormInformativos/FormInformativos';
import { ModalReportMenuView } from 'components/ReportMenu/ModalReportMenuView';

const { RangePicker } = DatePicker;

export const InformativosListar: React.FC<TInformativosListarProps> = () => {
  const history = useHistory();
  const categoriesOptions = useCategoryCoreVideo();
  const getMaterials = useClient('MaterialPagination');
  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const [resultMaterial, setResultMaterial] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [updateTable, setUpdateTable] = useState('');
  const [sort, setSort] = useState(SortFindManyMaterialInput.CreatedatDesc);
  const [filterValidityEnum, setFilterValidityEnum] = useState(
    ValidityEnum.Any
  );
  const [filterStatus, setFilterStatus] = useState<EnumMaterialStatus>();
  const [filterCategory, setFilterCategory] = useState();
  const [validityFilter, setValidityFilter] = useState<DateRange>();
  const [rangePickerShow, setRangePickerShow] = useState();

  const getFilterCategories = (filterCategory: any) => {
    let filter: any = {
      inCategories: [
        'menu:para_voce:comunicacao:informativos',
        'menu:informativos'
      ],
      inAdditionalCategories: undefined
    };

    if (filterCategory) {
      if (filterCategory.includes('menu')) {
        filter.inCategories.push(filterCategory);
        filter.inAdditionalCategories = [];
      } else {
        filter.inAdditionalCategories = [filterCategory];
      }
    }

    return filter;
  };

  useEffect(() => {
    const filterCategories = getFilterCategories(filterCategory);

    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: 10,
          filter: {
            kind: EnumMaterialKind.Communication,
            validityEnum: filterValidityEnum,
            includeDrafts: true,
            status: filterStatus,
            publishedAtRange: validityFilter,
            ...filterCategories
          },
          sort: sort
        }
      })
      .then((ctx) => {
        if (ctx.result && ctx.result.items) {
          setResultMaterial(ctx.result.items);
        }
      });
  }, [
    page,
    sort,
    filterStatus,
    filterCategory,
    validityFilter,
    filterValidityEnum,
    updateTable
  ]);
  const total = getMaterials?.result?.pageInfo.itemCount;

  const resetFilters = () => {
    setFilterValidityEnum(ValidityEnum.Any);
    setPage(1);
    setSort(SortFindManyMaterialInput.CreatedatDesc);
    setFilterStatus(undefined);
    setFilterCategory(undefined);
    setValidityFilter(undefined);
    setRangePickerShow(undefined);
  };

  const data = resultMaterial?.map((material: Material) => {
    const {
      _id,
      numericId,
      title,
      categories,
      validity,
      author,
      createdAt,
      status
    } = material;

    let nameCategory = categories?.find(
      (e: any) =>
        ![
          'menu:para_voce:comunicacao:informativos',
          'menu:informativos'
        ].includes(e._id)
    )?.name;

    if (
      !nameCategory &&
      material?.additionalCategories &&
      material?.additionalCategories?.length > 0
    ) {
      const id = material?.additionalCategories[0];
      nameCategory = additionalCategoriesOptions.find(
        (el: any) => el.value === id
      )?.label;
    }

    return {
      key: _id,
      id: numericId,
      title,
      sendDate: material?.lastSentAt
        ? formatDate(material?.lastSentAt)
        : formatDate(validity?.start),
      category: nameCategory || '-',
      user: author?.name || 'backup',
      cadastrada: formatDate(createdAt, 'DD/MM/YYYY'),
      status: status?.label,
      onEdit: () =>
        history.push(routes.informativosEditar.mount(material?._id)),
      onDelete: () => {
        const record = { status: EnumMaterialStatus.Cancelled };

        MaterialUpdateOne.fetch({
          variables: { record, filter: { _id } },
          afterMutate: /^Material/
        }).then((ctx) => {
          message.success('Informativo cancelado com sucesso');
          setUpdateTable(ctx.result?.recordId);
        });
      },
      onRead: () => accessMaterial(material, history, routes)
    };
  });

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            {
              title: 'Gerenciar conteúdo',
              link: routes.informativosListar.mount()
            },
            {
              title: 'Informativos',
              link: routes.informativosListar.mount()
            },
            { title: 'Listar', link: routes.informativosListar.mount() }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType="info-circle"
          title="Informativos"
          description="Listar e consultar os informativos"
        />

        <FilterContainer>
          <Button to={routes.informativosCadastrar.path}>
            Cadastrar informativos
          </Button>
          <ModalReportMenuView
            kind={EnumMaterialKind.Communication}
            options={
              categoriesOptions
                ? [...categoriesOptions, ...additionalCategoriesOptions]
                : []
            }
          />
          <Select
            placeholder="Ordenar por:"
            options={order}
            value={sort}
            onChange={(e) => {
              setPage(1);
              setSort(e);
            }}
          />
          <Select
            placeholder="status"
            options={statusOptions}
            value={
              filterValidityEnum !== ValidityEnum.Any
                ? filterValidityEnum
                : filterStatus
            }
            onChange={(e) => {
              setPage(1);
              switch (e) {
                case ValidityEnum.Current:
                  setFilterValidityEnum(e);
                  setFilterStatus(EnumMaterialStatus.Approved);
                  break;
                case ValidityEnum.Expired:
                  setFilterValidityEnum(e);
                  setFilterStatus(undefined);
                  break;
                default:
                  setFilterValidityEnum(ValidityEnum.Any);
                  setFilterStatus(e);
              }
            }}
          />
          <Select
            placeholder="categoria do menu"
            options={
              categoriesOptions
                ? [...categoriesOptions, ...additionalCategoriesOptions]
                : []
            }
            value={filterCategory}
            onChange={(e) => {
              setPage(1);
              setFilterCategory(e);
            }}
          />
          <RangePicker
            placeholder={['FILTRAR', 'PERÍODO']}
            locale={locale}
            format={'DD/MM/YYYY'}
            value={rangePickerShow}
            onChange={(momentDateArr: any) => {
              const dateISOArr = momentDateArr.map((item: any) =>
                moment(item).toISOString()
              );
              setRangePickerShow(momentDateArr);
              setValidityFilter({ start: dateISOArr[0], end: dateISOArr[1] });
            }}
            showTime={{ format: 'HH:mm' }}
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        {getMaterials.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              current: page,
              total: total!,
              onChange: (e) => setPage(e)
            }}
          />
        )}
      </>
    </PageTemplate>
  );
};

export type TInformativosListarProps = {};

const order = [
  { label: 'MAIS ANTIGO', value: SortFindManyMaterialInput.CreatedatAsc },
  { label: 'MAIS RECENTE', value: SortFindManyMaterialInput.CreatedatDesc }
];

const statusOptions = [
  { label: 'EM EDIÇÃO', value: EnumMaterialStatus.Editing },
  { label: 'CANCELADO', value: EnumMaterialStatus.Cancelled },
  { label: 'PUBLICADO', value: EnumMaterialStatus.Published },
  { label: 'AGUARDANDO APROVAÇÃO', value: EnumMaterialStatus.WaitingApproval },
  { label: 'REPROVADO', value: EnumMaterialStatus.Disapproved }
];

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    numericId
    title
    createdAt
    kind
    validity {
      start
      end
    }
    categories {
      _id
      name
      parentId
      parentCategory {
        name
        _id
      }
    }
    additionalCategories
    communicationItemsConfig {
      kind
    }
    sentEmail { body }
    files { extension }
    author { name }
    status { label value }
    lastSentAt
  }
`;
