import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Breadcrumb,
  Former,
  LoadingIndicator,
  TitleDescription
} from '@digi-tim-19/components';
import { formatDate, useDimensions } from '@digi-tim-19/utils/build';
import { EnumCampaignContestationResponseStatus } from 'autogenerated/client/types';
import { useClient } from 'autogenerated/client/client';
import { routes } from 'config/routes';
import Download from './Download';
import {
  FieldContainer,
  FieldDownload,
  FieldTitle,
  FormContainer,
  LoadingMyContestationContainer
} from './styles';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { Icon } from 'antd';

const options = [
  {
    label: 'Procedente',
    value: EnumCampaignContestationResponseStatus.Founded
  },
  {
    label: 'Em contestação',
    value: 'in_progress'
  },
  {
    label: 'Não Procedente',
    value: EnumCampaignContestationResponseStatus.Unfounded
  }
];

const appendToFragment = `
  campaign {
    title
    status {
      label
    }
  }
  response {
    status
    body
    responseFileIds
  }
  user {
    name
    channel
    region
    roleGroup
    matricula
  }
  responseFiles {
    downloadUrl
    signedUrl
    title
  }
  files {
    downloadUrl
    signedUrl
    title
  }
`;

export const FormMyContestation = ({}) => {
  const { isMobile } = useDimensions();
  const { id }: any = useParams();
  const getContestation = useClient('CampaignContestationFindOne');
  const configPage = {
    pageName: 'Minha contestação',
    icon: 'audit',
    description: 'Detalhes da contestação',
    routeNew: '',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Para você', link: routes.home.mount() },
      { title: 'Campanhas', link: routes.campanhasIncentivoVigentes.mount() },
      {
        title: 'Minhas Contestações',
        link: routes.minhasContestacoesListar.mount()
      },
      { title: 'Contestação' }
    ]
  };

  useEffect(() => {
    getContestation.fetch({
      variables: {
        filter: {
          _id: id
        }
      },
      appendToFragment
    });
  }, []);

  const contestation: any = getContestation?.result;

  const initValues = {
    campaignTitle: contestation?.campaign?.title,
    status: contestation?.response?.status || 'in_progress',
    body: contestation?.response?.body,
    protocol: contestation?.protocol,
    name: contestation?.user?.name,
    channel: contestation?.user?.channel,
    region:
      contestation?.user?.region === 'THQ' ? 'BR' : contestation?.user?.region,
    role: contestation?.user?.roleGroup,
    subscription: contestation?.user?.matricula,
    indicator: contestation?.indicator,
    createdAt: formatDate(contestation?.createdAt, 'DD [de] MMMM [de] YYYY'),
    evidence: contestation?.files?.find((file: any) => file)?.title,
    title: `Portal Pra Conectar – Retorno da Contestação – Protocolo ${contestation?.protocol}`,
    responseFileIds: contestation?.response?.responseFileIds || null
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />
        {getContestation?.loading ? (
          <LoadingMyContestationContainer>
            <LoadingIndicator />
          </LoadingMyContestationContainer>
        ) : (
          <FormContainer>
            <Former
              initialValues={initValues}
              config={({ form }) => {
                form.registerField('responseFileIds', () => {}, {});
                return {
                  customRenderFooter: () => {
                    return <></>;
                  },
                  fields: [
                    {
                      list: [
                        {
                          inline: !isMobile,
                          list: [
                            {
                              name: 'campaignTitle',
                              label: 'Título da Campanha',
                              type: 'text',
                              required: true,
                              extraProps: {
                                readOnly: true
                              }
                            },
                            {
                              name: 'protocol',
                              label: 'Protocolo',
                              type: 'text',
                              required: true,
                              maxWidth: isMobile ? '100%' : '20%',
                              extraProps: {
                                readOnly: true
                              }
                            }
                          ]
                        },
                        {
                          inline: !isMobile,
                          list: [
                            {
                              name: 'name',
                              label: 'Nome',
                              type: 'text',
                              required: true,
                              extraProps: {
                                readOnly: true
                              }
                            },

                            {
                              name: 'channel',
                              label: 'Canal',
                              type: 'text',
                              required: true,
                              extraProps: {
                                readOnly: true
                              }
                            },

                            {
                              name: 'region',
                              label: 'Região',
                              type: 'text',
                              required: true,
                              extraProps: {
                                readOnly: true
                              }
                            },
                            {
                              name: 'role',
                              label: 'Cargo',
                              type: 'text',
                              required: true,
                              extraProps: {
                                readOnly: true
                              }
                            }
                          ]
                        },
                        {
                          inline: !isMobile,
                          list: [
                            {
                              name: 'subscription',
                              label: 'Matrícula',
                              type: 'text',
                              required: true,
                              extraProps: {
                                readOnly: true
                              }
                            },
                            {
                              custom: (
                                <Download
                                  downloadUrl={
                                    contestation?.files?.find(
                                      (file: any) => file
                                    )?.signedUrl
                                  }
                                />
                              )
                            },
                            {
                              name: 'createdAt',
                              label: 'Data de envio',
                              type: 'text',
                              required: true,
                              extraProps: {
                                readOnly: true
                              }
                            }
                          ]
                        },
                        {
                          inline: !isMobile,
                          list: [
                            {
                              name: 'indicator',
                              label: 'Indicador',
                              type: 'textarea',
                              required: true,
                              extraProps: {
                                rows: 3,
                                readOnly: true
                              }
                            }
                          ]
                        }
                      ]
                    },
                    initValues?.status && {
                      name: 'status',
                      label: 'Status',
                      type: 'select',
                      options,
                      disabled: true,
                      extraProps: {
                        readOnly: true
                      }
                    },
                    initValues?.status !== 'in_progress' && {
                      name: 'title',
                      label: 'Título',
                      type: 'text',
                      required: true,
                      extraProps: {
                        readOnly: true
                      }
                    },
                    initValues?.status !== 'in_progress' &&
                      contestation?.responseFiles && {
                        custom: (
                          <>
                            {contestation?.responseFiles?.find(
                              (file: any) => file
                            )?.signedUrl && (
                              <FieldContainer>
                                <FieldTitle>
                                  Evidências de Contestação
                                </FieldTitle>
                                <FieldDownload
                                  href={
                                    contestation?.responseFiles?.find(
                                      (file: any) => file
                                    )?.signedUrl || undefined
                                  }
                                  download
                                  target="_blank"
                                >
                                  <Icon type="paper-clip" />
                                  {
                                    contestation?.responseFiles?.find(
                                      (file: any) => file
                                    )?.title
                                  }
                                </FieldDownload>
                              </FieldContainer>
                            )}
                          </>
                        )
                      },
                    initValues?.status !== 'in_progress' && {
                      name: 'body',
                      label: 'Justificativa',
                      type: 'textarea',
                      extraProps: {
                        rows: 6,
                        readOnly: true
                      },
                      required: true
                    }
                  ]
                };
              }}
              onSubmit={(): any => {}}
            />
          </FormContainer>
        )}
      </>
    </PageTemplate>
  );
};
