import React from 'react';
import { RedButton, IconCoins } from './styles';
import coins from '../images/Coins.png';
import { useGoToReconhece } from 'config/reconhece';

export const RedeemPoints = () => {
  const onClickReconhece = useGoToReconhece();

  return (
    <RedButton
      onClick={() => {
        onClickReconhece();
      }}
    >
      <IconCoins src={coins} alt="iconCoins" />
      <span>Resgatar TIM Coins</span>
    </RedButton>
  );
};
