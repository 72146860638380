import React from 'react';
import { useHistory } from 'react-router';
import { Modal } from 'antd';

import {
  EnumAnalyticsParentKind,
  IncentiveCampaign
} from 'autogenerated/client/types';
import { useClient } from 'autogenerated/client/client';
import DownloadButton from 'components/DownloadButton';
import { routes } from 'config/routes';
import { useAnalytics } from '@digi-tim-19/components';

type TCampaignRegulationProps = {
  campaign: IncentiveCampaign;
  visible: boolean;
  roleGroup: string;
  onClose: () => void;
};

export const CampaignRegulation = ({
  campaign,
  visible,
  roleGroup,
  onClose
}: TCampaignRegulationProps) => {
  const history = useHistory();
  const analytics = useAnalytics();

  const mutationAcceptTerm = useClient('UserAcceptTerm');

  //regulation logic
  const profileList = [
    'adm_sac_residencial',
    'internos',
    'diretor_nacional',
    'operacao_residencial',
    'operacao_corporate',
    'areas_parceiras',
    'especialista_tim_televendas',
    'pre_e_pos-vendas'
  ];
  const shouldShowTerm = profileList.some(
    (profile) => !roleGroup?.includes(profile)
  );

  if (!shouldShowTerm) {
    return null;
  }

  return (
    <Modal
      title="Regulamento"
      visible={visible}
      onOk={() => {
        mutationAcceptTerm.fetch({
          variables: {
            term: `IncentiveCampaignRegulation-${campaign?._id}`
          }
        });
        onClose();
      }}
      onCancel={() => history.push(routes.home.mount())}
      cancelText="Não"
      okText="Sim"
    >
      <p style={{ margin: 0 }}>
        Participante, precisamos do seu aceite ao regulamento da Campanha para
        ficar elegivel.
      </p>

      <DownloadButton
        style={{ margin: '5px 0 20px' }}
        href={campaign?.regulationFile?.signedUrl || '#'}
        download
        target="_blank"
        onClick={() => {
          analytics.track({
            event: 'download_regulation',
            kind: EnumAnalyticsParentKind.IncentiveCampaign,
            recordId: campaign?._id,
            entityName: 'IncentiveCampaign',
            singleDoc: true
          });
        }}
      >
        Clique aqui para baixar o regulamento
      </DownloadButton>

      <p style={{ margin: '0 0 10px 0' }}>
        A pontuação conquistada tem validade de 12 meses a partir da
        disponibilização dos TIM Coins no extrato.
      </p>

      <p style={{ margin: 0 }}>
        Li e concordo com os termos e condições do regulamento
      </p>
    </Modal>
  );
};
