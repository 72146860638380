import { fragmentCampaignCard } from 'utils/fragmentCard';

export const FCampaignPagination = `
  count
  pageInfo {
    perPage
    itemCount
    currentPage
  }
  items {${fragmentCampaignCard}}
`;

export const FIncentiveCampaign = `
  title
  faq
  status { _id }
  views { count }
  likes { count }
  cardImageFile { signedUrl }
  bannerContentFile { signedUrl }
  contestationPeriod {
    start
    end
  }
  regulationFile {
    signedUrl
    downloadUrl
  }
  presentationFile {
    signedUrl
    downloadUrl
  }
  file {
    _id
    title
    createdAt
  }
  fileContent {
    name
    points
    email
    role
    partner
    position
  }
`;
