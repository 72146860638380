export const fragmentMaterialCard = `
  _id
  title
  createdAt
  pinned
  kind
  categories {
    _id
    name
    parentId
    parentCategory {
      _id
      name
      parentId
      parentCategory {
        _id
        name
        parentId
        parentCategory {
          _id
          name
        }
      }
    }
  }
  sentEmail {
    body
  }
  communicationItemsConfig{
    kind
    templateName
  }
  files {
    extension
    _id
    signedUrl
    kind
    downloadUrl
  }
`;

export const fragmentCampaignCard = `
  _id
  title
  pinned
  cardImageFile {
    signedUrl
  }
  campaignValidity {
    start
    end
  }
  status {
    _id
    label
    value
  }
`;
