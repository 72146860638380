import React from 'react';
import { cpf } from '@digi-tim-19/utils/build';

export const uploadColumns = [
  {
    title: 'Nome do Usuário',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'CPF',
    dataIndex: 'CPF',
    key: 'CPF',
    render: (text: string) => <span>{cpf.format(text)}</span>
  },
  {
    title: 'TIM Coins na campanha',
    dataIndex: 'userCampaignPoints',
    key: 'userCampaignPoints',
    width: '15%'
  },

  {
    title: 'Valor a creditar',
    dataIndex: 'value',
    key: 'value',
    width: '15%'
  },
  {
    title: 'Saldo na campanha',
    dataIndex: 'campaignBalance',
    key: 'campaignBalance',
    width: '15%'
  }
];

export const reversalColumns = [
  {
    title: 'Nome do Usuário',
    dataIndex: 'name',
    key: 'name',
    render: (text: string, { campaignBalance, reversalPoints }: any) => {
      const condition = campaignBalance < 0 || reversalPoints <= 0;
      return (
        <span style={condition ? { color: 'red' } : undefined}>{text}</span>
      );
    }
  },
  {
    title: 'CPF',
    dataIndex: 'CPF',
    key: 'CPF',
    render: (text: string, { campaignBalance, reversalPoints }: any) => {
      const condition = campaignBalance < 0 || reversalPoints <= 0;
      return (
        <span style={condition ? { color: 'red' } : undefined}>
          {cpf.format(text)}
        </span>
      );
    }
  },
  {
    title: 'TIM Coins na carteira',
    dataIndex: 'userPoints',
    key: 'userPoints',
    width: '12%',
    render: (num: number, { campaignBalance, reversalPoints }: any) => {
      const condition = campaignBalance < 0 || reversalPoints <= 0;
      return (
        <span style={condition ? { color: 'red' } : undefined}>{num}</span>
      );
    }
  },
  {
    title: 'Crédito campanha',
    dataIndex: 'userCampaignPoints',
    key: 'userCampaignPoints',
    width: '12%',
    render: (num: number, { campaignBalance, reversalPoints }: any) => {
      const condition = campaignBalance < 0 || reversalPoints <= 0;
      return (
        <span style={condition ? { color: 'red' } : undefined}>{num}</span>
      );
    }
  },
  {
    title: 'Valor a estornar',
    dataIndex: 'value',
    key: 'value',
    width: '12%',
    render: (num: number, { campaignBalance, reversalPoints }: any) => {
      const condition = campaignBalance < 0 || reversalPoints <= 0;
      return (
        <span style={condition ? { color: 'red' } : undefined}>{num}</span>
      );
    }
  },
  {
    title: 'Valor a debitar',
    dataIndex: 'reversalPoints',
    key: 'reversalPoints',
    width: '12%',
    render: (num: number, { campaignBalance, reversalPoints }: any) => {
      const condition = campaignBalance < 0 || reversalPoints <= 0;
      return (
        <span style={condition ? { color: 'red' } : undefined}>{num}</span>
      );
    }
  },
  {
    title: 'Saldo na carteira',
    dataIndex: 'userBalance',
    key: 'userBalance',
    width: '12%',
    render: (num: number, { campaignBalance, reversalPoints }: any) => {
      const condition = campaignBalance < 0 || reversalPoints <= 0;
      return (
        <span style={condition ? { color: 'red' } : undefined}>{num}</span>
      );
    }
  }
];
