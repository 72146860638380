import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription,
  Former,
  LoadingIndicator
} from '@digi-tim-19/components';

import { useClient } from '../../../autogenerated/client/client';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';

import { FormWrapper } from '../styles';
import { useCurrentUser } from 'hooks/user/useCurrentUser';
import { EventScheduleModal } from './EventScheduleModal';
import { EventGuide } from './EventGuides';
import { EventGalleries } from './EventGalleries';
import { useEvent } from '../hooks/useEvent';

type ComponentType = 'guide' | 'schedulle' | 'gallery';

const configPage = {
  pageName: 'Eventos',
  icon: 'inbox',
  description: 'Consulta e cadastramento de eventos.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Gerenciar Componentes', link: routes.eventosComponentes.mount() },
    { title: 'Editar' }
  ]
};

export const EventosEditarComponents = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const user = useCurrentUser();
  const EventUpdateOne = useClient('EventUpdateById');
  const { event, loading } = useEvent(params.id);

  const [kind, setKind] = useState<ComponentType>();
  const [schedules, setSchedules] = useState<any>();
  const [guides, setGuides] = useState<any>();
  const [gallery, setGallery] = useState<any>();

  useEffect(() => {
    setSchedules(event?.schedulles);
    setGuides(event?.guides);
    setGallery(event?.gallery);
  }, [event]);
  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {loading && <LoadingIndicator />}

        {event && (
          <>
            <ProviderSelecionarPublico>
              <FormWrapper>
                <Former
                  config={{
                    fields: [
                      {
                        name: 'kind',
                        label: 'Selecione o componente',
                        type: 'select',
                        defaultValue: kind,
                        options: [
                          { value: 'schedulle', label: 'Agenda' },
                          { value: 'guide', label: 'Guia de destino' },
                          { value: 'gallery', label: 'Galeria' }
                        ],
                        afterChange: setKind,
                        required: true
                      },
                      (loading || EventUpdateOne.loading) && {
                        custom: <LoadingIndicator />
                      },
                      kind === 'schedulle' && {
                        name: 'schedulle',
                        noWrapper: true,
                        custom: {
                          render: () => (
                            <EventScheduleModal
                              userId={`${user.result?._id}`}
                              user={`${user.result?.name}`}
                              eventId={`${event?._id}`}
                              initialSchedules={schedules}
                              onChange={(schedules) => setSchedules(schedules)}
                            />
                          )
                        }
                      },
                      kind === 'guide' && {
                        name: 'guide',
                        noWrapper: true,
                        custom: {
                          render: () => (
                            <EventGuide
                              eventId={`${event?._id}`}
                              initEventGuide={guides}
                              onChange={(guides) => setGuides(guides)}
                            />
                          )
                        }
                      },
                      kind === 'gallery' && {
                        name: 'gallery',
                        noWrapper: true,
                        custom: {
                          render: () => (
                            <EventGalleries
                              initial={gallery}
                              eventId={`${event?._id}`}
                              user={`${user.result?.name}`}
                              onChange={(gallery) => setGallery(gallery)}
                            />
                          )
                        }
                      }
                    ],
                    submitButton: { label: 'SALVAR' },
                    cancelButton: { label: 'CANCELAR' }
                  }}
                  onSubmit={async () => {
                    if (kind === 'schedulle' && Boolean(schedules)) {
                      await EventUpdateOne.fetch({
                        afterMutate: /^Event/,
                        variables: {
                          record: { _id: event?._id, schedulles: schedules }
                        }
                      });
                    }

                    if (kind === 'guide' && Boolean(guides)) {
                      await EventUpdateOne.fetch({
                        afterMutate: /^Event/,
                        variables: {
                          record: { _id: event?._id, guides }
                        }
                      });
                    }

                    if (kind === 'gallery' && Boolean(gallery)) {
                      await EventUpdateOne.fetch({
                        afterMutate: /^Event/,
                        variables: {
                          record: { _id: event?._id, gallery }
                        }
                      });
                    }

                    if (!EventUpdateOne.error) {
                      history.push(routes.eventosComponentes.mount());
                    }
                  }}
                />
              </FormWrapper>
            </ProviderSelecionarPublico>

            {/* {event.result && JSON.stringify(event.result)} */}
          </>
        )}
      </>
    </PageTemplate>
  );
};
