import { useClient } from '../../autogenerated/client/client';

export function useRegionFindMany() {
  const variables = { filter: { isVirtualRegion: null }, limit: 100 };

  const RegionFindMany = useClient('RegionFindMany', {
    variables,
    fetchOnMount: true,
    cache: true
  });
  return { ...RegionFindMany };
}

export function useUfData(region: string) {
  const variables = { filter: { region, level: 1 }, limit: 100 };

  const ufData = useClient('RegionItemFindMany', {
    variables,
    fetchOnMount: true
  });

  return { ...ufData };
}
