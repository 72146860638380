import styled from 'styled-components';

import { FormContainer } from '../../components/Former';

export const DocumentosWrapper = styled.div``;

export const ChooseVisualWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 40px;
  width: 100%;

  @media screen and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  i {
    padding-right: 20px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  width: 100%;
`;

export const FormWrapper = styled(FormContainer as any)`
  .form-section-container {
    .header {
      flex-wrap: wrap !important;

      .component-upload {
        display: flex;
        flex-direction: column;
      }

      .name-validity {
        .ant-calendar-picker {
          width: 100% !important;
        }
      }

      > .FieldWrapper {
        margin-left: 20px;
        margin-right: 0 !important;
        &:first-of-type {
          margin: 0;
        }

        @media screen and (max-width: 768px) {
          margin: 0 0 30px !important;
        }
      }
    }

    .Footer {
      > div {
        justify-content: flex-start;
      }
    }
  }

  .content-buttons {
    flex-basis: 0 !important;
  }

  .name-documento,
  .name-thumbnail {
    > span {
      display: flex;

      .anticon-loading {
        display: none;
      }
      .ant-upload-list-item-progress {
        display: none;
      }
      .anticon-close {
        right: -2px;
      }
    }
  }
`;

export const WrapperLabel = styled.div`
  margin-top: -30px;
  display: flex;
  flex: auto;
`;
