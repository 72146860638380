import { Select } from 'antd';
import React from 'react';
import { FieldInterface, FieldSelectOptionInterface } from '.';
import useDebounce from '../../hooks/useDebounce';

const { Option } = Select;

export const SelectField = ({
  dataKey,
  placeholder,
  value,
  defaultValue,
  options = [],
  debounce,
  regexSearch = false,
  onChangeSearch,
  onChangeInput
}: FieldInterface): React.ReactElement => {
  const [fieldValue, setFieldValue] = React.useState<any>(undefined);
  const [fieldValueDebounce, setFieldValueDebounce] = React.useState<any>(
    undefined
  );
  const debounceValue = useDebounce(fieldValueDebounce, 500);

  const handleFieldValue = (value: any) => {
    setFieldValue(value);
  };

  React.useEffect(() => {
    handleFieldValue(value);
  }, [value]);

  React.useEffect(() => {
    if (onChangeSearch) {
      onChangeSearch(dataKey, debounceValue, regexSearch);
    }
  }, [debounceValue]);

  return (
    <Select
      placeholder={placeholder || ''}
      defaultValue={defaultValue || ''}
      value={fieldValue}
      onChange={(value: any) => {
        if (debounce) {
          setFieldValueDebounce(value);
        } else {
          if (onChangeSearch) {
            onChangeSearch(dataKey, value, regexSearch);
          }
        }
        if (onChangeInput) {
          onChangeInput(dataKey, value);
        }
      }}
    >
      {options.map((option: FieldSelectOptionInterface, index: number) => {
        return (
          <Option
            key={`option-${option.label}-${option.value}-${index}`}
            value={option.value}
          >
            {option.label}
          </Option>
        );
      })}
    </Select>
  );
};
